import React from "react"
import { useTheme } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { useHistory } from "react-router-dom"
import { getConceptTypeIcon } from "../util/ConceptTypeStyles"
import { Concept } from "../__generated__/types"
const useStyles = makeStyles((theme) => ({
    summary: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    subIcon: {
        height: "0.65em",
        width: "0.65em",
    },
    greyedText: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    noWrapText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}))
interface ConceptListItemProps {
    item: Concept
    showSummary?: boolean
    disableClick?: boolean
    onClick?: () => void
    hideDivider?: boolean
    selected?: boolean
}

export default function ConceptListItem(props: ConceptListItemProps) {
    const { item, showSummary, disableClick, selected } = props
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const TypeIcon = getConceptTypeIcon(item.rootCategory?.name)

    return (
        <Box key={item.id}>
            <ListItem
                button={!disableClick ? true : undefined}
                onClick={(e) => {
                    e.stopPropagation()
                    if (props.onClick) {
                        props.onClick()
                    } else if (!disableClick) {
                        history.push(`/concept/${item.id}/home`)
                    }
                }}
                divider={!props.hideDivider}
                selected={!!selected}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    overflow="hidden"
                    width="100%"
                >
                    <ListItemAvatar style={{ display: "flex" }}>
                        <Avatar
                            style={{
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.primary.main,
                            }}
                            variant="rounded"
                            src={item.imageUrl}
                        >
                            <TypeIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                        {!!item.rootCategory && (
                            <Box className={classes.greyedText}>
                                {props.item.rootCategory.name}{" "}
                                {!!props.item.category &&
                                    !props.item.category?.isRoot &&
                                    " / " + props.item.category?.name}
                            </Box>
                        )}
                        <Box>
                            <Typography variant="body2">
                                {item.title}
                            </Typography>
                        </Box>
                        {showSummary && (
                            <Box className={classes.greyedText}>
                                {item.summary}
                            </Box>
                        )}
                    </ListItemText>
                </Box>
            </ListItem>
        </Box>
    )
}
