import { useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import {
    Box,
    Divider,
    useMediaQuery,
    IconButton,
    Typography,
    Paper,
    LinearProgress,
    Slide,
} from "@material-ui/core"
import { useQuery } from "@apollo/client"
import { Exercise, ExerciseSubmission } from "../../../__generated__/types"

import { CONCEPT_BY_ID } from "../../../graphql/queries"
import ConceptListItem from "../../ConceptListItem"
import RichTextEditor from "../../RichTextEditor"
import { Loading } from "../../Loading"
import Button from "@material-ui/core/Button"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../../graphql/__generated__/queries"
import FocusedCriteria from "./FocusedCriteria"
function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}
interface FocusedConceptSectionProps {
    conceptId: string
    exercise: Exercise
    submission: ExerciseSubmission
    position: number
    moveBack: () => void
    moveForward: () => void
    conceptsLength: number
    currentProgress: number
}
const FocusedConceptSection = (props: FocusedConceptSectionProps) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { data, loading } = useQuery<ConceptQuery, ConceptQueryVariables>(
        CONCEPT_BY_ID,
        {
            variables: {
                id: props.conceptId,
            },
        }
    )
    const [scoresOpen, setScoresOpen] = useState(true)
    const [activeStep, setActiveStep] = useState(0)

    const concept = data?.Concept?.[0]
    const focusedCriteria = props.exercise.criteria?.[activeStep] ?? null
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    return (
        <Box
            component={mobile ? Box : Paper}
            display="flex"
            flexDirection="column"
            height="100%"
            key={props.conceptId}
        >
            <Box display="flex" alignItems="flex-start" flexGrow={1}>
                <Box
                    style={{
                        height: "100%",
                        backgroundColor:
                            //@ts-ignore
                            theme.palette.background.level2,
                    }}
                >
                    <IconButton
                        onClick={() => props.moveBack()}
                        style={{
                            height: "100%",
                        }}
                        disabled={props.position === 0}
                        size="small"
                    >
                        <KeyboardArrowLeft />
                    </IconButton>
                </Box>

                <Divider orientation="vertical" flexItem />
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    flexGrow={1}
                >
                    {!concept || loading ? (
                        <Loading size={75} hideQuote={true} />
                    ) : (
                        <>
                            {mobile && (
                                <Box p={1} pb={0}>
                                    <LinearProgressWithLabel
                                        value={
                                            (props.currentProgress /
                                                props.exercise?.criteria
                                                    .length) *
                                            100
                                        }
                                    />
                                </Box>
                            )}
                            <ConceptListItem
                                item={concept}
                                disableClick={true}
                                showSummary={true}
                            />
                            <RichTextEditor
                                initialValue={concept?.description}
                                isReadOnly={true}
                            />
                        </>
                    )}
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box
                    style={{
                        height: "100%",
                        backgroundColor:
                            //@ts-ignore
                            theme.palette.background.level2,
                    }}
                >
                    <IconButton
                        disabled={props.position === props.conceptsLength - 1}
                        onClick={() => props.moveForward()}
                        style={{
                            height: "100%",
                        }}
                        size="small"
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                </Box>
            </Box>
            <Divider />
            <Box
                style={{
                    backgroundColor:
                        //@ts-ignore
                        theme.palette.background.level2,
                }}
            >
                <Button
                    style={{ width: "100%" }}
                    onClick={() => setScoresOpen(!scoresOpen)}
                    size="small"
                >
                    {scoresOpen ? "Hide" : "Show"}
                </Button>
            </Box>

            <Divider />
            <Slide in={scoresOpen} mountOnEnter unmountOnExit direction="up">
                <Box height="50%">
                    {!!focusedCriteria && (
                        <FocusedCriteria
                            submissionId={props.submission.id}
                            conceptId={props.conceptId}
                            criteria={focusedCriteria}
                            key={
                                props.submission.id +
                                props.conceptId +
                                focusedCriteria.id
                            }
                            exercise={props.exercise}
                            activeStep={activeStep}
                            handleNext={handleNext}
                            handleBack={handleBack}
                        />
                    )}
                </Box>
            </Slide>
        </Box>
    )
}

export default FocusedConceptSection
