import { useState, useEffect, useCallback } from "react"
import { useTheme } from "@material-ui/core/styles"
import { Box, Divider, Slider, Typography, TextField } from "@material-ui/core"
import { useQuery, useMutation } from "@apollo/client"
import { Exercise, Criteria } from "../../../__generated__/types"

import {
    CREATE_CRITERIA_SCORE,
    UPDATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_USER,
    ADD_CRITERIA_SCORE_CONCEPT,
    ADD_CRITERIA_SCORE_CRITERIA,
    ADD_CRITERIA_SCORE_EXERCISE_SUBMISSION,
    FOCUSED_EXERCISE_CRITERIA_SCORE,
} from "../../criteria/graphql"
import {
    CreateCriteriaScoreMutation,
    CreateCriteriaScoreMutationVariables,
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    AddCriteriaScoreConceptMutation,
    AddCriteriaScoreConceptMutationVariables,
    AddCriteriaScoreUserMutation,
    AddCriteriaScoreUserMutationVariables,
    AddCriteriaScoreCriteriaMutation,
    AddCriteriaScoreCriteriaMutationVariables,
    AddCriteriaScoreExerciseSubmissionMutation,
    AddCriteriaScoreExerciseSubmissionMutationVariables,
    FocusedExerciseCriteriaScoreQuery,
    FocusedExerciseCriteriaScoreQueryVariables,
} from "../../criteria/__generated__/graphql"
import debounce from "p-debounce"
import { useAuth } from "../../../providers/AuthProvider"
import MobileStepper from "@material-ui/core/MobileStepper"
import Button from "@material-ui/core/Button"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"

interface FocusedCriteriaProps {
    submissionId: string
    conceptId: string
    exercise: Exercise
    criteria: Criteria
    activeStep: number
    handleNext: () => void
    handleBack: () => void
}

const FocusedCriteria = (props: FocusedCriteriaProps) => {
    const { data, refetch } = useQuery<
        FocusedExerciseCriteriaScoreQuery,
        FocusedExerciseCriteriaScoreQueryVariables
    >(FOCUSED_EXERCISE_CRITERIA_SCORE, {
        variables: {
            submissionId: props.submissionId,
            conceptId: props.conceptId,
            criteriaId: props.criteria.id,
        },
    })
    const theme = useTheme()
    const { currentUser } = useAuth()
    const [valueObject, setValueObject] = useState(null)

    const [create] = useMutation<
        CreateCriteriaScoreMutation,
        CreateCriteriaScoreMutationVariables
    >(CREATE_CRITERIA_SCORE)
    const [update] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const [addConcept] = useMutation<
        AddCriteriaScoreConceptMutation,
        AddCriteriaScoreConceptMutationVariables
    >(ADD_CRITERIA_SCORE_CONCEPT)
    const [addCriteria] = useMutation<
        AddCriteriaScoreCriteriaMutation,
        AddCriteriaScoreCriteriaMutationVariables
    >(ADD_CRITERIA_SCORE_CRITERIA)
    const [addSubmission] = useMutation<
        AddCriteriaScoreExerciseSubmissionMutation,
        AddCriteriaScoreExerciseSubmissionMutationVariables
    >(ADD_CRITERIA_SCORE_EXERCISE_SUBMISSION)
    const [addUser] = useMutation<
        AddCriteriaScoreUserMutation,
        AddCriteriaScoreUserMutationVariables
    >(ADD_CRITERIA_SCORE_USER)
    const criteriaScore = data?.CriteriaScore?.[0] ?? null

    const updateCriteriaScore = debounce(
        useCallback(
            (value: string | number, field: string) => {
                if (!!criteriaScore?.id) {
                    let variableObject = {
                        id: criteriaScore.id,
                    }
                    variableObject[field] = value
                    update({
                        variables: {
                            ...variableObject,
                        },
                    })
                }
            },
            [criteriaScore, update]
        ),
        1000
    )
    const createCriteriaScore = useCallback(async () => {
        const {
            data: { CreateCriteriaScore: NewCriteriaScore },
        } = await create({
            variables: {
                value: 0,
                notes: "",
            },
        })
        await Promise.all([
            addSubmission({
                variables: {
                    submissionId: props.submissionId,
                    criteriaScoreId: NewCriteriaScore.id,
                },
            }),
            addConcept({
                variables: {
                    conceptId: props.conceptId,
                    criteriaScoreId: NewCriteriaScore.id,
                },
            }),
            addCriteria({
                variables: {
                    criteriaId: props.criteria.id,
                    criteriaScoreId: NewCriteriaScore.id,
                },
            }),
        ])
        refetch()
        addUser({
            variables: {
                userId: currentUser.userId,
                criteriaScoreId: NewCriteriaScore.id,
            },
        })
    }, [
        create,
        addConcept,
        addSubmission,
        addUser,
        addCriteria,
        currentUser.userId,
        props.conceptId,
        props.submissionId,
        props.criteria.id,
        refetch,
    ])
    useEffect(() => {
        if (!!data) {
            if (!!criteriaScore) {
                setValueObject({
                    value: criteriaScore.value,
                    notes: criteriaScore.notes,
                })
            } else {
                createCriteriaScore()
            }
        }
    }, [criteriaScore, createCriteriaScore, data])

    return (
        <>
            <MobileStepper
                variant="dots"
                steps={props.exercise.criteria?.length}
                position="static"
                style={{
                    backgroundColor:
                        //@ts-ignore
                        theme.palette.background.level2,
                }}
                activeStep={props.activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={props.handleNext}
                        disabled={
                            props.activeStep ===
                            props.exercise.criteria?.length - 1
                        }
                    >
                        Next
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={props.handleBack}
                        disabled={props.activeStep === 0}
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
            <Divider />

            <Box p={2} pb={0}>
                <Typography variant="body1">{props.criteria.name}</Typography>
            </Box>
            {!!valueObject && (
                <>
                    <Box
                        p={5}
                        display="flex"
                        alignItems="center"
                        key={
                            props.criteria.id +
                            props.conceptId +
                            props.submissionId
                        }
                    >
                        <Slider
                            min={0}
                            max={100}
                            value={valueObject.value}
                            step={1}
                            onChangeCommitted={(event, value: number) => {
                                setValueObject({
                                    ...valueObject,
                                    value: value,
                                })
                                updateCriteriaScore(value, "value")
                            }}
                        />

                        <Box p={1} ml={2}>
                            <TextField
                                size="small"
                                inputProps={{
                                    min: 0,
                                    max: 100,
                                }}
                                onBlur={(event) => {
                                    updateCriteriaScore(
                                        Number(event.target.value),
                                        "value"
                                    )
                                }}
                                value={valueObject.value}
                                onChange={(event) => {
                                    setValueObject({
                                        ...valueObject,
                                        value: Number(event.target.value),
                                    })
                                }}
                                type="number"
                            />
                        </Box>
                    </Box>
                    <Box p={3} pt={1}>
                        <TextField
                            onChange={(event) => {
                                setValueObject({
                                    ...valueObject,
                                    notes: event.target.value,
                                })
                            }}
                            value={valueObject.notes}
                            label="Notes"
                            multiline
                            placeholder="Enter score notes..."
                            variant="outlined"
                            fullWidth
                            rows={5}
                            onBlur={(e) => {
                                updateCriteriaScore(e.target.value, "notes")
                            }}
                        />
                    </Box>
                </>
            )}
        </>
    )
}

export default FocusedCriteria
