import { useQuery } from "@apollo/client"
import { CircularProgress, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { default as React, useState } from "react"
import { SEARCH_CONCEPT_CHECKLISTS } from "../../graphql/Checklist"
import {
    SearchConceptChecklistsQuery,
    SearchConceptChecklistsQueryVariables,
} from "../../graphql/__generated__/Checklist"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

export type ExerciseList = {
    title: string
    id: string
}

type Props = {
    checklist: ExerciseList | null
    onDone: (checklist: ExerciseList) => Promise<void>
    onCancel: () => void
}

export default function ExerciseSetupListPicker(props: Props) {
    const [isSaving, setSaving] = useState(false)
    const [parentConcept, setParentConcept] = useState<
        SearchConceptChecklistsQuery["fuzzyConceptSearch"][0] | null
    >(null)
    const [list, setList] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [searchValue, setSearchValue] = useState("a")
    const { t } = useAwaitTranslation("exercises")
    const { data: allConcepts, loading } = useQuery<
        SearchConceptChecklistsQuery,
        SearchConceptChecklistsQueryVariables
    >(SEARCH_CONCEPT_CHECKLISTS, {
        variables: {
            searchString: searchValue,
        },
    })

    let options: Array<ExerciseList> = []
    const handleDone = async () => {
        setSaving(true)
        await props.onDone(list)
    }
    // get child checklists from chosen concept
    if (parentConcept && parentConcept.checklists?.length > 0) {
        parentConcept.checklists.forEach((list) => {
            if (list.conceptBased) {
                let option = {
                    id: list.id,
                    title: list.title,
                }
                options.push(option)
            }
        })
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            open={!confirm}
            fullWidth
        >
            <DialogTitle>
                {t("selectExercise", "Select Exercise List")}
            </DialogTitle>
            <DialogContent style={{ height: "100%" }}>
                <Autocomplete
                    options={allConcepts?.fuzzyConceptSearch ?? []}
                    value={parentConcept}
                    getOptionSelected={(option, value) =>
                        option.id === value.id
                    }
                    onChange={(_: React.ChangeEvent<{}>, newValue) => {
                        if (typeof newValue !== "string")
                            setParentConcept(newValue)
                    }}
                    disableClearable
                    onInputChange={(_, newInputValue) => {
                        setSearchValue(newInputValue || "a")
                    }}
                    loading={loading}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("chooseParent", "Choose a parent concept")}
                            variant="outlined"
                            style={{ marginBottom: 10 }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
                <Autocomplete
                    options={options}
                    value={list}
                    getOptionSelected={(option, value) =>
                        option.id === value.id
                    }
                    onChange={(_: React.ChangeEvent<{}>, newValue) => {
                        setList(newValue)
                    }}
                    disableClearable
                    getOptionDisabled={(option) => option.id === "disabled"}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={"Choose a list"}
                            variant="outlined"
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onCancel}
                    disabled={isSaving}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button onClick={handleDone}>
                    {isSaving ? (
                        <CircularProgress size={20} color="secondary" />
                    ) : (
                        "Save"
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
