import {
    Box,
    IconButton,
    Paper,
    Divider,
    Typography,
    Slider,
    Slide,
    TextField,
} from "@material-ui/core"
import { useEffect, useCallback, useState } from "react"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import { useQuery, useMutation, ApolloQueryResult } from "@apollo/client"
import ConceptRadar from "../../charts/ConceptRadar"
import EditIcon from "@material-ui/icons/Edit"
import {
    CREATE_CRITERIA,
    ADD_CONCEPT_CRITERIA,
    CREATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_CRITERIA,
    CONCEPT_WITH_CRITERIA,
    UPDATE_CRITERIA_SCORE,
} from "../../criteria/graphql"
import {
    CreateCriteriaMutation,
    CreateCriteriaMutationVariables,
    CreateCriteriaScoreMutation,
    CreateCriteriaScoreMutationVariables,
    AddCriteriaScoreCriteriaMutation,
    AddCriteriaScoreCriteriaMutationVariables,
    ConceptWithCriteriaQuery,
    ConceptWithCriteriaQueryVariables,
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    AddCriteriaConceptMutation,
    AddCriteriaConceptMutationVariables,
} from "../../criteria/__generated__/graphql"
import { Loading } from "../../Loading"
import { Concept, Criteria } from "../../../__generated__/types"
import debounce from "p-debounce"
import { useParams } from "react-router-dom"
import ClearIcon from "@material-ui/icons/Clear"
import { RadarDataObject } from "../../charts/ConceptRadar"
type WidgetConfig = {
    label: string
    criteria: Pick<Criteria, "name" | "criteriaType">[]
    conceptIds: string[]
}

const CriteriaRadarWidget: WidgetTypeComponent = (props) => {
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const { conceptId } = useParams()
    const names = config?.criteria?.map((x) => x.name) ?? []
    const { data, refetch } = useQuery<
        ConceptWithCriteriaQuery,
        ConceptWithCriteriaQueryVariables
    >(CONCEPT_WITH_CRITERIA, {
        variables: {
            conceptFilter: {
                id_in: config?.conceptIds ?? [conceptId],
            },
            criteriaFilter: {
                name_in: names,
            },
        },
        skip: names.length === 0,
    })
    const [editing, setEditing] = useState(false)
    const concepts = config?.conceptIds
        ? data?.Concept
        : [data?.Concept?.[0]] ?? null
    const radarData = config?.criteria?.map((criteria) => {
        let criteriaObject: RadarDataObject = {
            criteriaName: criteria.name,
            values: [],
        }
        criteriaObject["values"] =
            concepts?.map((x) => {
                return {
                    id: x?.id as string,
                    label: x?.title as string,
                    value:
                        x?.criteria?.filter(
                            (x) => x.name === criteria.name
                        )?.[0]?.scores?.[0]?.value ?? (0 as number),
                }
            }) ?? []
        return criteriaObject
    })
    return (
        <Box
            component={Paper}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            pb={2}
            key={props.widget.id}
            overflow={"hidden"}
        >
            {!!isInitialized && !!concepts && !!data && (
                <>
                    <WidgetHeader
                        label={config?.label}
                        actions={[
                            <IconButton
                                key="edit"
                                onClick={() => setEditing(true)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>,
                        ]}
                        widget={props.widget}
                        editing={props.editing}
                    />
                    <Box height="100%" width="100%" display="flex">
                        <Slide
                            in={editing}
                            mountOnEnter
                            unmountOnExit
                            direction="right"
                        >
                            <Box width="100%" p={1}>
                                <Box display="flex" justifyContent="flex-end">
                                    <IconButton
                                        size="small"
                                        onClick={() => setEditing(false)}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                                {!!editing && config?.criteria?.length > 0 && (
                                    <ConceptCriteriaSliders
                                        concept={concepts?.[0]}
                                        criteria={config?.criteria}
                                        refetch={refetch}
                                    />
                                )}
                            </Box>
                        </Slide>
                        <Divider orientation="vertical" flexItem />
                        <Box width={editing ? "60%" : "100%"}>
                            <ConceptRadar data={radarData} />
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    )
}

interface ConceptCriteriaSlidersProps {
    concept: Concept
    criteria: Pick<Criteria, "name" | "criteriaType">[]
    refetch: () => Promise<ApolloQueryResult<ConceptWithCriteriaQuery>>
}
const ConceptCriteriaSliders = (props: ConceptCriteriaSlidersProps) => {
    const { refetch, concept, criteria } = props
    const [createCriteria] = useMutation<
        CreateCriteriaMutation,
        CreateCriteriaMutationVariables
    >(CREATE_CRITERIA)
    const [addToConcept] = useMutation<
        AddCriteriaConceptMutation,
        AddCriteriaConceptMutationVariables
    >(ADD_CONCEPT_CRITERIA)
    const [createCriteriaScore] = useMutation<
        CreateCriteriaScoreMutation,
        CreateCriteriaScoreMutationVariables
    >(CREATE_CRITERIA_SCORE)
    const [addCriteriaScore] = useMutation<
        AddCriteriaScoreCriteriaMutation,
        AddCriteriaScoreCriteriaMutationVariables
    >(ADD_CRITERIA_SCORE_CRITERIA)
    const [ran, setRan] = useState(false)
    const criteriaToCreate =
        criteria
            ?.map((item) => {
                if (
                    concept?.criteria?.filter((x) => x.name === item.name)
                        ?.length === 0
                ) {
                    return item
                } else {
                    return null
                }
            })
            .filter((x) => !!x) ?? null
    const createNeededCriteria = useCallback(async () => {
        if (criteriaToCreate?.length !== 0) {
            await Promise.all([
                ...criteriaToCreate.map(async (criteria) => {
                    const {
                        data: { CreateCriteria: NewCriteria },
                    } = await createCriteria({
                        variables: {
                            ...criteria,
                            isDefault: false,
                        },
                    })
                    const {
                        data: { CreateCriteriaScore: NewCriteriaScore },
                    } = await createCriteriaScore({
                        variables: {
                            value: 0,
                        },
                    })
                    await Promise.all([
                        addToConcept({
                            variables: {
                                criteriaId: NewCriteria.id,
                                conceptId: concept?.id,
                            },
                        }),
                        addCriteriaScore({
                            variables: {
                                criteriaId: NewCriteria.id,
                                criteriaScoreId: NewCriteriaScore.id,
                            },
                        }),
                    ])
                }),
            ])
        }
        await refetch()
    }, [
        addToConcept,
        createCriteriaScore,
        createCriteria,
        addCriteriaScore,
        criteriaToCreate,
        concept?.id,
        refetch,
    ])
    useEffect(() => {
        if (criteriaToCreate?.length !== 0 && !ran) {
            createNeededCriteria()
            setRan(true)
        }
    }, [criteriaToCreate, ran, createNeededCriteria])
    const scores =
        concept?.criteria?.map((item, index) => {
            return item?.scores[0]
        }) ?? []
    if (criteriaToCreate.length !== 0) {
        return <Loading size={75} hideQuote={true} />
    }

    return (
        <Box
            p={1}
            key={props.concept.id}
            display="flex"
            flexDirection="column"
            height="100%"
            overflow="auto"
        >
            {scores?.map((item, index) => {
                return <CriteriaSlider score={item} key={item.id} />
            })}
        </Box>
    )
}

const CriteriaSlider = (props) => {
    const [updateScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const [value, setValue] = useState(0)
    useEffect(() => {
        if (!!props.score.value) {
            setValue(props.score.value)
        }
    }, [props.score])
    const updateCriteriaScore = debounce(
        useCallback(
            (value: number) => {
                updateScore({
                    variables: {
                        id: props.score.id,
                        value: value,
                    },
                })
            },
            [props.score.id, updateScore]
        ),
        1000
    )

    return (
        <Box width="100%" mt={1}>
            <Typography variant="body2">
                {props.score.criteria?.name}
            </Typography>
            <Box display="flex" p={0.5} pt={0} alignItems="center">
                <Slider
                    onChangeCommitted={(event, value: number) => {
                        setValue(value)
                        updateCriteriaScore(value)
                    }}
                    value={value}
                />
                <Box p={1} ml={1}>
                    <TextField
                        size="small"
                        inputProps={{
                            min: 0,
                            max: 100,
                        }}
                        onBlur={(event) => {
                            updateCriteriaScore(Number(event.target.value))
                        }}
                        value={value}
                        onChange={(event) => {
                            setValue(Number(event.target.value))
                        }}
                        type="number"
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default CriteriaRadarWidget
