import { gql } from "@apollo/client"

export const UPLOAD_PROFILE_IMAGE = gql`
    mutation uploadProfileImage($file: Upload!, $imageName: String) {
        uploadProfileImage(file: $file, imageName: $imageName) {
            fileUrl
            filename
        }
    }
`

export const UPLOAD_CONCEPT_IMAGE = gql`
    mutation uploadConceptImage($file: Upload!, $imageName: String) {
        uploadConceptImage(file: $file, imageName: $imageName) {
            fileUrl
            filename
        }
    }
`
