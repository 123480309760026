import "tippy.js/animations/scale.css"
import "tippy.js/dist/tippy.css"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { CodeAlt } from "@styled-icons/boxicons-regular/CodeAlt"
import { Highlight } from "@styled-icons/boxicons-regular/Highlight"
import { BorderAll } from "@styled-icons/material/BorderAll"
import { BorderBottom } from "@styled-icons/material/BorderBottom"
import { BorderClear } from "@styled-icons/material/BorderClear"
import { BorderLeft } from "@styled-icons/material/BorderLeft"
import { BorderRight } from "@styled-icons/material/BorderRight"
import { BorderTop } from "@styled-icons/material/BorderTop"
import { FormatAlignCenter } from "@styled-icons/material/FormatAlignCenter"
import { FormatAlignJustify } from "@styled-icons/material/FormatAlignJustify"
import { FormatAlignLeft } from "@styled-icons/material/FormatAlignLeft"
import { FormatAlignRight } from "@styled-icons/material/FormatAlignRight"
import { FormatBold } from "@styled-icons/material/FormatBold"
import { FormatItalic } from "@styled-icons/material/FormatItalic"
import { FormatListBulleted } from "@styled-icons/material/FormatListBulleted"
import { FormatListNumbered } from "@styled-icons/material/FormatListNumbered"
import { FormatUnderlined } from "@styled-icons/material/FormatUnderlined"
import { Keyboard } from "@styled-icons/material/Keyboard"
import { Looks3 } from "@styled-icons/material/Looks3"
import { LooksOne } from "@styled-icons/material/LooksOne"
import { LooksTwo } from "@styled-icons/material/LooksTwo"
import { TippyProps } from "@tippyjs/react"
import {
    addColumn,
    addRow,
    BalloonToolbar,
    deleteColumn,
    deleteRow,
    deleteTable,
    ELEMENT_ALIGN_CENTER,
    ELEMENT_ALIGN_JUSTIFY,
    ELEMENT_ALIGN_RIGHT,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_OL,
    ELEMENT_UL,
    getPlatePluginType,
    insertTable,
    MARK_BOLD,
    MARK_CODE,
    MARK_ITALIC,
    MARK_KBD,
    MARK_UNDERLINE,
    ToolbarAlign,
    ToolbarElement,
    ToolbarList,
    ToolbarMark,
    ToolbarTable,
    useStoreEditorRef,
} from "@udecode/plate"
import { useEventEditorId } from "@udecode/plate-core"
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight"
const useStyles = makeStyles((theme) => ({
    active: {
        color: `${theme.palette.primary.main} !important`,
    },
    root: {
        "&:hover": {
            color: `${theme.palette.primary.main} !important`,
        },
    },
}))

export const ToolbarButtonsBasicElements = () => {
    const editor = useStoreEditorRef(useEventEditorId("focus"))
    const classes = useStyles()
    return (
        <>
            <ToolbarElement
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, ELEMENT_H1)}
                icon={<LooksOne />}
            />
            <ToolbarElement
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, ELEMENT_H2)}
                icon={<LooksTwo />}
            />
            <ToolbarElement
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, ELEMENT_H3)}
                icon={<Looks3 />}
            />
            {/* <ToolbarElement
                type={getPlatePluginType(editor, ELEMENT_H4)}
                icon={<Looks4 />}
            />
            <ToolbarElement
                type={getPlatePluginType(editor, ELEMENT_H5)}
                icon={<Looks5 />}
            />
            <ToolbarElement
                type={getPlatePluginType(editor, ELEMENT_H6)}
                icon={<Looks6 />}
            /> 
            <ToolbarElement
                type={getPlatePluginType(editor, ELEMENT_BLOCKQUOTE)}
                icon={<FormatQuote />}
            />
            <ToolbarCodeBlock
                type={getPlatePluginType(editor, ELEMENT_CODE_BLOCK)}
                icon={<CodeBlock />}
            /> */}
        </>
    )
}

export const ToolbarButtonsList = () => {
    const editor = useStoreEditorRef(useEventEditorId("focus"))
    const classes = useStyles()
    return (
        <>
            <ToolbarList
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, ELEMENT_UL)}
                icon={<FormatListBulleted />}
            />
            <ToolbarList
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, ELEMENT_OL)}
                icon={<FormatListNumbered />}
            />
        </>
    )
}

export const ToolbarButtonsAlign = () => {
    const editor = useStoreEditorRef(useEventEditorId("focus"))
    const classes = useStyles()
    return (
        <>
            <ToolbarAlign
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                icon={<FormatAlignLeft />}
            />
            <ToolbarAlign
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, ELEMENT_ALIGN_CENTER)}
                icon={<FormatAlignCenter />}
            />
            <ToolbarAlign
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, ELEMENT_ALIGN_RIGHT)}
                icon={<FormatAlignRight />}
            />
            <ToolbarAlign
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, ELEMENT_ALIGN_JUSTIFY)}
                icon={<FormatAlignJustify />}
            />
        </>
    )
}

export const ToolbarButtonsBasicMarks = () => {
    const editor = useStoreEditorRef(useEventEditorId("focus"))
    const classes = useStyles()
    return (
        <>
            <ToolbarMark
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, MARK_BOLD)}
                icon={<FormatBold />}
            />
            <ToolbarMark
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, MARK_ITALIC)}
                icon={<FormatItalic />}
            />
            <ToolbarMark
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, MARK_UNDERLINE)}
                icon={<FormatUnderlined />}
            />
            <ToolbarMark
                classNames={{
                    active: classes.active,
                    root: classes.root,
                }}
                type={getPlatePluginType(editor, MARK_CODE)}
                icon={<CodeAlt />}
            />
            {/* <ToolbarMark
                type={getPlatePluginType(editor, MARK_STRIKETHROUGH)}
                icon={<FormatStrikethrough />}
            />
            <ToolbarMark
                type={getPlatePluginType(editor, MARK_SUPERSCRIPT)}
                clear={getPlatePluginType(editor, MARK_SUBSCRIPT)}
                icon={<Superscript />}
            />
            <ToolbarMark
                type={getPlatePluginType(editor, MARK_SUBSCRIPT)}
                clear={getPlatePluginType(editor, MARK_SUPERSCRIPT)}
                icon={<Subscript />}
            /> */}
        </>
    )
}

export const ToolbarKbd = () => {
    const editor = useStoreEditorRef(useEventEditorId("focus"))

    return (
        <ToolbarMark
            type={getPlatePluginType(editor, MARK_KBD)}
            icon={<Keyboard />}
        />
    )
}

export const ToolbarHighlight = () => {
    const editor = useStoreEditorRef(useEventEditorId("focus"))

    return (
        <ToolbarMark
            type={getPlatePluginType(editor, MARK_HIGHLIGHT)}
            icon={<Highlight />}
        />
    )
}

export const ToolbarButtonsTable = () => (
    <>
        <ToolbarTable icon={<BorderAll />} transform={insertTable} />
        <ToolbarTable icon={<BorderClear />} transform={deleteTable} />
        <ToolbarTable icon={<BorderBottom />} transform={addRow} />
        <ToolbarTable icon={<BorderTop />} transform={deleteRow} />
        <ToolbarTable icon={<BorderLeft />} transform={addColumn} />
        <ToolbarTable icon={<BorderRight />} transform={deleteColumn} />
    </>
)

export const BallonToolbarMarks = () => {
    const editor = useStoreEditorRef(useEventEditorId("focus"))

    const theme = "dark"
    const direction = "top"
    const hiddenDelay = 0
    const tooltip: TippyProps = {
        arrow: true,
        delay: 0,
        duration: [200, 0],
        hideOnClick: false,
        offset: [0, 17],
        placement: "top",
    }

    return (
        <BalloonToolbar
            direction={direction}
            hiddenDelay={hiddenDelay}
            theme={theme}
            arrow={true}
        >
            <ToolbarMark
                type={getPlatePluginType(editor, MARK_BOLD)}
                icon={<FormatBold />}
                tooltip={{ content: "Bold (⌘B)", ...tooltip }}
            />
            <ToolbarMark
                type={getPlatePluginType(editor, MARK_ITALIC)}
                icon={<FormatItalic />}
                tooltip={{ content: "Italic (⌘I)", ...tooltip }}
            />
            <ToolbarMark
                type={getPlatePluginType(editor, MARK_UNDERLINE)}
                icon={<FormatUnderlined />}
                tooltip={{ content: "Underline (⌘U)", ...tooltip }}
            />
        </BalloonToolbar>
    )
}
