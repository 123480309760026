import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    CircularProgress,
    TextField,
    DialogActions,
    InputAdornment,
} from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { Hidden } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import AddIcon from "@material-ui/icons/Add"
import UserManagerLineItem from "./UserManagerLineItem"
import { FULL_USER_QUERY } from "./graphql"
import {
    FullUsersQueryVariables,
    FullUsersQuery,
} from "./__generated__/graphql"
import { useQuery } from "@apollo/client"
import IconButton from "@material-ui/core/IconButton"
import { Loading } from "../Loading"
import { useTranslation } from "react-i18next"
import useUserManagementTools from "./useUserManagementTools"
import { SystemRole, User } from "../../__generated__/types"
import UserModal from "./UserModal"
import SearchIcon from "@material-ui/icons/Search"
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down("sm")]: {
            padding: 0,
        },
        height: "100%",
        overflow: "hidden",
    },
    teamList: {
        flexGrow: 1,
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    flexCell: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    tableContainer: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.divider}`,
    },
    tableHeader: {
        //@ts-ignore
        backgroundColor: `${theme.palette.background.level2} !important`,
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    paperUserForm: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatarUserForm: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
    },

    progress: {
        position: "absolute",
    },
    errorMessage: {
        color: theme.palette.error.main,
    },
    field: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

export default function UserManager() {
    const classes = useStyles()
    const { data, loading, refetch } = useQuery<
        FullUsersQuery,
        FullUsersQueryVariables
    >(FULL_USER_QUERY)

    const [searchString, setSearchString] = useState("")
    const [userFormOpen, setUserFormOpen] = useState(false)
    const [focusedUser, setFocusedUser] = useState(null)
    const users =
        data?.User?.filter((item) =>
            searchString.length > 0
                ? (item.firstName + item.lastName).includes(searchString)
                : item
        ) ?? []

    if (loading) {
        return <Loading size={150} hideQuote={true} />
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.paper}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    py={2}
                >
                    <TextField
                        label="Search"
                        placeholder="Search users"
                        value={searchString}
                        onChange={(e) => {
                            setSearchString(e.target.value)
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginRight: "1rem" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Hidden xsDown>
                        <Button
                            style={{ flexShrink: 0 }}
                            variant="contained"
                            color="primary"
                            onClick={() => setUserFormOpen(true)}
                        >
                            Create User
                        </Button>
                    </Hidden>
                    <Hidden smUp>
                        <IconButton>
                            <AddIcon />
                        </IconButton>
                    </Hidden>
                </Box>

                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    id="avatar"
                                    size="small"
                                    className={classes.tableHeader}
                                    style={{ width: "50px" }}
                                />

                                <TableCell
                                    className={classes.tableHeader}
                                    size="small"
                                ></TableCell>

                                <TableCell
                                    className={classes.tableHeader}
                                ></TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    size="small"
                                >
                                    Role
                                </TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    size="small"
                                >
                                    Last Login
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user, index) => {
                                return (
                                    <UserManagerLineItem
                                        key={user.userId}
                                        user={user}
                                        focused={
                                            focusedUser?.userId === user.userId
                                        }
                                        onFocus={() => {
                                            setFocusedUser(user)
                                        }}
                                    />
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {!!userFormOpen && (
                <UserForm
                    onCreation={(newUser: User) => {
                        refetch()
                        setFocusedUser(newUser)
                        setUserFormOpen(false)
                    }}
                    onClose={() => setUserFormOpen(false)}
                />
            )}
            {!!focusedUser && (
                <UserModal
                    onClose={() => setFocusedUser(null)}
                    user={focusedUser}
                    open={!!focusedUser}
                />
            )}
        </Box>
    )
}

interface UserFormProps {
    onClose: () => void
    onCreation: (newUser: User) => void
}
function UserForm(props: UserFormProps) {
    const classes = useStyles()
    const { onCreateUser, errors } = useUserManagementTools()

    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: SystemRole.GUEST,
    })
    const handleSubmission = async () => {
        setLoading(true)
        const newUser = await onCreateUser({
            ...userData,
            username: userData.firstName + userData.lastName,
        })
        if (!!newUser) {
            props.onCreation(newUser)
        }
        setLoading(false)
    }

    const { t } = useTranslation("login")
    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>New User</DialogTitle>
            <DialogContent dividers>
                <Box p={1} mb={2}>
                    <Box className={classes.field}>
                        <TextField
                            variant="outlined"
                            required
                            size="small"
                            fullWidth
                            onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    firstName: e.target.value,
                                })
                            }}
                            id="firstName"
                            label={t("firstName", "First Name")}
                            name="firstName"
                            autoComplete="firstName"
                            disabled={loading}
                        />
                    </Box>
                    <Box className={classes.field}>
                        <TextField
                            variant="outlined"
                            required
                            size="small"
                            fullWidth
                            onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    lastName: e.target.value,
                                })
                            }}
                            id="lastName"
                            label={t("lastName", "Last Name")}
                            name="lastName"
                            autoComplete="lastName"
                            disabled={loading}
                        />
                    </Box>

                    <Box className={classes.field}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            onChange={(e) => {
                                setUserData({
                                    ...userData,
                                    email: e.target.value,
                                })
                            }}
                            size="small"
                            label={t("email", "Email Address")}
                            disabled={loading}
                            name="email"
                            autoComplete="email"
                        />
                    </Box>

                    <Box className={classes.field}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel>Role</InputLabel>
                            <Select
                                value={userData.role}
                                label="Role"
                                onChange={(e) => {
                                    setUserData({
                                        ...userData,
                                        role: e.target.value as any,
                                    })
                                }}
                            >
                                <MenuItem value={SystemRole.GUEST}>
                                    Guest
                                </MenuItem>
                                <MenuItem value={SystemRole.MEMBER}>
                                    Member
                                </MenuItem>
                                <MenuItem value={SystemRole.ADMIN}>
                                    Admin
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <Box display="flex">
                            {errors.length > 0 &&
                                errors.map((error) => (
                                    <Box className={classes.errorMessage}>
                                        {error}
                                    </Box>
                                ))}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()}>Cancel</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmission}
                    disabled={loading}
                >
                    Create
                    {loading && (
                        <CircularProgress
                            disableShrink
                            size={30}
                            className={classes.progress}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
