import React from "react"
import { makeStyles, Box, useTheme } from "@material-ui/core"
import { ReactComponent as ReactLogo } from "../logo.svg"
import { ReactComponent as ReactLogoLight } from "../logo-white.svg"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    box: {
        display: "flex",
        flexWrap: "nowrap",
        color: theme.palette.text.primary,
        alignItems: "center",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
        },
    },
    logoIcon: {
        height: "32px",
        padding: "0 8px",
    },
}))

export default function Logo() {
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const routeChange = () => {
        let path = `/feed`
        history.push(path)
    }
    return (
        <Box
            ml={1}
            className={classes.box}
            onClick={routeChange}
            style={{ cursor: "pointer" }}
        >
            {theme.palette.type === "dark" ? (
                <ReactLogoLight className={classes.logoIcon} />
            ) : (
                <ReactLogo
                    fill="currentColor"
                    className={classes.logoIcon}
                    alt="logo"
                />
            )}
            {/* <Typography variant="h6">Amble</Typography> */}
        </Box>
    )
}
