import { useQuery, useMutation } from "@apollo/client"
import {
    GET_WIDGET_TEMPLATES,
    CREATE_DASHBOARD_WIDGET,
    ASSIGN_DASHBOARD_WIDGET_TO_TYPE,
    DELETE_DASHBOARD_WIDGET,
} from "./graphql"
import {
    GetWidgetTemplatesQuery,
    GetWidgetTemplatesQueryVariables,
    CreateDashboardWidgetMutation,
    CreateDashboardWidgetMutationVariables,
    AddDashboardWidgetToTypeMutation,
    AddDashboardWidgetToTypeMutationVariables,
    DeleteDashboardWidgetMutation,
    DeleteDashboardWidgetMutationVariables,
} from "./__generated__/graphql"
import { useCallback } from "react"
import debounce from "p-debounce"

const nonTemplateFields = ["conceptListId", "conceptId", "exerciseId"]

export function useWidgetTemplates<T = {}>(typeId: string | null) {
    const { data, refetch: refetchTemplates } = useQuery<
        GetWidgetTemplatesQuery,
        GetWidgetTemplatesQueryVariables
    >(GET_WIDGET_TEMPLATES, {
        fetchPolicy: "cache-first",
    })
    const [createWidgetTemplate] = useMutation<
        CreateDashboardWidgetMutation,
        CreateDashboardWidgetMutationVariables
    >(CREATE_DASHBOARD_WIDGET)
    const [assignWidgetToType] = useMutation<
        AddDashboardWidgetToTypeMutation,
        AddDashboardWidgetToTypeMutationVariables
    >(ASSIGN_DASHBOARD_WIDGET_TO_TYPE)
    const [deleteWidget] = useMutation<
        DeleteDashboardWidgetMutation,
        DeleteDashboardWidgetMutationVariables
    >(DELETE_DASHBOARD_WIDGET)

    const widgetTemplates = typeId
        ? data?.Widget?.filter((item) => item.type?.id === typeId)
        : []

    const onDeleteTemplate = useCallback(
        async (widgetId: string) => {
            await deleteWidget({
                variables: {
                    id: widgetId,
                },
                update: (cache, { data }) => {
                    cache.evict({
                        id: cache.identify(data.DeleteWidget),
                    })
                },
            })
        },
        [deleteWidget]
    )
    const onTemplateCreation = debounce(
        useCallback(
            async (config: T, value: T) => {
                let validConfig = {}
                Object.keys(config).map((field) => {
                    if (!nonTemplateFields.includes(field)) {
                        validConfig[field] = config[field]
                    }
                    return validConfig
                })
                const {
                    data: { CreateWidget: newWidget },
                } = await createWidgetTemplate({
                    variables: {
                        config: JSON.stringify(validConfig),
                        value: JSON.stringify(value),
                        isTemplate: true,
                    },
                })
                await assignWidgetToType({
                    variables: {
                        widgetId: newWidget.id,
                        widgetTypeId: typeId,
                    },
                })
                refetchTemplates()
            },
            [createWidgetTemplate, assignWidgetToType, typeId, refetchTemplates]
        ),
        500
    )
    return {
        widgetTemplates,
        onTemplateCreation,
        onDeleteTemplate,
    }
}
