import { makeVar } from "@apollo/client"

export const globalFiltersVar = makeVar([])

// width of navigation bar that contains workspace
export const navWidth: number = 300
export const navWidthVar = makeVar(navWidth)

const widgetId: string = ""
export const expandWidgetVar = makeVar(widgetId)

const permissions = false
export const permissionsVar = makeVar(permissions)
