export const CurveData = [
    { x: 1, Sales: 0, Profit: 0, dataPoints: {} },
    { x: 2, Sales: 0, Profit: -5, dataPoints: {} }, //5
    { x: 3, Sales: 0, Profit: -12, dataPoints: {} }, //7
    { x: 4, Sales: 0, Profit: -19, dataPoints: {} }, //7
    { x: 5, Sales: 0, Profit: -27, dataPoints: {} }, //8
    { x: 6, Sales: 0, Profit: -40, dataPoints: {} }, //10
    { x: 7, Sales: 0, Profit: -55, dataPoints: {} }, //15
    { x: 8, Sales: 0, Profit: -70, dataPoints: {} }, //15
    { x: 9, Sales: 0, Profit: -90, dataPoints: {} }, //20
    { x: 10, Sales: 0, Profit: -115, dataPoints: {} }, //25
    { x: 11, Sales: 0, Profit: -140, dataPoints: {} }, //25
    { x: 12, Sales: 0, Profit: -165, dataPoints: {} }, //30
    { x: 13, Sales: 0, Profit: -195, dataPoints: {} }, //30
    { x: 14, Sales: 0, Profit: -235, dataPoints: {} }, //40
    { x: 15, Sales: 0, Profit: -275, dataPoints: {} }, //40
    { x: 16, Sales: 0, Profit: -320, dataPoints: {} }, //45
    { x: 17, Sales: 0, Profit: -365, dataPoints: {} }, //45
    { x: 18, Sales: 0, Profit: -415, dataPoints: {} }, //50
    { x: 19, Sales: 0, Profit: -465, dataPoints: {} }, //50
    { x: 20, Sales: 10, Profit: -515, dataPoints: {} }, // 10
    { x: 21, Sales: 20, Profit: -565, dataPoints: {} }, // 10
    { x: 22, Sales: 30, Profit: -620, dataPoints: {} }, //10
    { x: 23, Sales: 40, Profit: -670, dataPoints: {} }, //10
    { x: 24, Sales: 55, Profit: -715, dataPoints: {} }, //15
    { x: 25, Sales: 70, Profit: -750, dataPoints: {} }, //15
    { x: 26, Sales: 85, Profit: -780, dataPoints: {} }, //15
    { x: 27, Sales: 100, Profit: -810, dataPoints: {} }, //15
    { x: 28, Sales: 120, Profit: -835, dataPoints: {} }, //20
    { x: 29, Sales: 140, Profit: -855, dataPoints: {} }, //20
    { x: 30, Sales: 165, Profit: -870, dataPoints: {} }, // 25
    { x: 31, Sales: 190, Profit: -880, dataPoints: {} }, // 35
    { x: 32, Sales: 235, Profit: -880, dataPoints: {} }, // 45
    { x: 33, Sales: 280, Profit: -865, dataPoints: {} }, // 45
    { x: 34, Sales: 335, Profit: -830, dataPoints: {} }, // 55
    { x: 35, Sales: 390, Profit: -780, dataPoints: {} }, //55
    { x: 36, Sales: 455, Profit: -715, dataPoints: {} }, // 65
    { x: 37, Sales: 530, Profit: -635, dataPoints: {} }, //75
    { x: 38, Sales: 605, Profit: -535, dataPoints: {} }, // 85
    { x: 39, Sales: 700, Profit: -415, dataPoints: {} }, //95
    { x: 40, Sales: 800, Profit: -275, dataPoints: {} }, // 100
    { x: 41, Sales: 900, Profit: -115, dataPoints: {} }, // 100
    { x: 42, Sales: 1000, Profit: 25, dataPoints: {} }, // 140
    { x: 43, Sales: 1100, Profit: 145, dataPoints: {} }, // 120
    { x: 44, Sales: 1200, Profit: 255, dataPoints: {} }, // 110
    { x: 45, Sales: 1300, Profit: 355, dataPoints: {} }, // 100
    { x: 46, Sales: 1400, Profit: 445, dataPoints: {} }, // 90
    { x: 47, Sales: 1500, Profit: 525, dataPoints: {} }, // 80
    { x: 48, Sales: 1600, Profit: 595, dataPoints: {} }, // 70
    { x: 49, Sales: 1700, Profit: 655, dataPoints: {} }, // 60
    { x: 50, Sales: 1800, Profit: 705, dataPoints: {} }, // 50
    { x: 51, Sales: 1900, Profit: 745, dataPoints: {} }, // 40
    { x: 52, Sales: 2000, Profit: 775, dataPoints: {} }, //30
    { x: 53, Sales: 2100, Profit: 800, dataPoints: {} }, //25
    { x: 54, Sales: 2200, Profit: 820, dataPoints: {} }, // 20
    { x: 55, Sales: 2300, Profit: 840, dataPoints: {} }, //20
    { x: 56, Sales: 2400, Profit: 850, dataPoints: {} }, //10
    { x: 57, Sales: 2500, Profit: 860, dataPoints: {} }, //10
    { x: 58, Sales: 2600, Profit: 870, dataPoints: {} }, //10
    { x: 59, Sales: 2700, Profit: 875, dataPoints: {} }, // 5
    { x: 60, Sales: 2800, Profit: 880, dataPoints: {} }, //5
    { x: 61, Sales: 2900, Profit: 885, dataPoints: {} }, // 5
    { x: 62, Sales: 3000, Profit: 880, dataPoints: {} }, //4
    { x: 63, Sales: 3100, Profit: 875, dataPoints: {} }, //4
    { x: 64, Sales: 3180, Profit: 870, dataPoints: {} }, //4
    { x: 65, Sales: 3250, Profit: 860, dataPoints: {} }, //10
    { x: 66, Sales: 3310, Profit: 850, dataPoints: {} }, //10
    { x: 67, Sales: 3370, Profit: 835, dataPoints: {} }, //15
    { x: 68, Sales: 3400, Profit: 820, dataPoints: {} }, //15
    { x: 69, Sales: 3405, Profit: 800, dataPoints: {} }, //20
    { x: 70, Sales: 3400, Profit: 780, dataPoints: {} }, //20
    { x: 71, Sales: 3370, Profit: 755, dataPoints: {} }, // 25
    { x: 72, Sales: 3310, Profit: 730, dataPoints: {} }, // 25
    { x: 73, Sales: 3250, Profit: 700, dataPoints: {} }, //30
    { x: 74, Sales: 3180, Profit: 670, dataPoints: {} }, //30
    { x: 75, Sales: 3090, Profit: 635, dataPoints: {} }, //35
    { x: 76, Sales: 2980, Profit: 595, dataPoints: {} }, //40
    { x: 77, Sales: 2850, Profit: 555, dataPoints: {} }, //40
    { x: 78, Sales: 2700, Profit: 510, dataPoints: {} }, //45
    { x: 79, Sales: 2550, Profit: 460, dataPoints: {} }, //50
    { x: 80, Sales: 2400, Profit: 410, dataPoints: {} }, //50
    { x: 81, Sales: 2250, Profit: 360, dataPoints: {} }, //50
    { x: 82, Sales: 2100, Profit: 315, dataPoints: {} }, //45
    { x: 83, Sales: 1950, Profit: 275, dataPoints: {} }, //40
    { x: 84, Sales: 1800, Profit: 240, dataPoints: {} }, //35
    { x: 85, Sales: 1650, Profit: 210, dataPoints: {} }, //30
    { x: 86, Sales: 1500, Profit: 180, dataPoints: {} }, //25
    { x: 87, Sales: 1350, Profit: 155, dataPoints: {} }, //20
    { x: 88, Sales: 1200, Profit: 135, dataPoints: {} }, //15
    { x: 89, Sales: 1050, Profit: 115, dataPoints: {} }, //15
    { x: 90, Sales: 925, Profit: 100, dataPoints: {} }, //10
    { x: 91, Sales: 800, Profit: 90, dataPoints: {} }, //10
    { x: 92, Sales: 700, Profit: 80, dataPoints: {} }, //10
    { x: 93, Sales: 600, Profit: 70, dataPoints: {} }, //10
    { x: 94, Sales: 500, Profit: 60, dataPoints: {} }, //5
    { x: 95, Sales: 400, Profit: 50, dataPoints: {} }, //5
    { x: 96, Sales: 320, Profit: 40, dataPoints: {} }, //5
    { x: 97, Sales: 240, Profit: 30, dataPoints: {} }, //5
    { x: 98, Sales: 160, Profit: 20, dataPoints: {} }, //5
    { x: 99, Sales: 80, Profit: 10, dataPoints: {} },
    { x: 100, Sales: 0, Profit: 0, dataPoints: {} },
]
