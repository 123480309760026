import React, { useEffect } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { useQuery } from "@apollo/client"
import { SEARCH_TAGS } from "../tags/graphql"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

import {
    SearchTagsQuery,
    SearchTagsQueryVariables,
} from "../tags/__generated__/graphql"
import { getNewState } from "./util/getNewState"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        height: "100%",
    },
    listbox: {
        overflow: "auto",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    popper: {
        zIndex: theme.zIndex.modal + 2,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },
    header: {
        fontSize: theme.typography.h6.fontSize,
        padding: theme.spacing(1),
    },
    betweenFiltersText: {
        color: theme.palette.text.hint,
    },
}))

const filter = createFilterOptions()
export default function TagsFilter(props) {
    const classes = useStyles()
    const [value, setValue] = React.useState([])
    const [inputValue, setInputValue] = React.useState("")
    const { loading, data, refetch } = useQuery<
        SearchTagsQuery,
        SearchTagsQueryVariables
    >(SEARCH_TAGS, {
        variables: { term: "" },
    })
    const { t } = useAwaitTranslation("global")
    useEffect(() => {
        if (inputValue) {
            refetch({ term: inputValue })
        }
    }, [inputValue, refetch])
    useEffect(() => {
        if (data && data?.Tag?.length > 0) {
            props.filters.forEach((item) => {
                if (
                    item.field === props.item.field &&
                    item.secondLevelFilter === props.item.secondLevelFilter
                ) {
                    setValue(item.value)
                }
            })
        }
    }, [
        props.filters,
        props.item.field,
        props.item.secondLevelFilter,
        data,
        props.item,
    ])

    return (
        <Autocomplete
            multiple
            id="tag-input"
            size="small"
            options={data?.Tag?.map((t) => t.name) ?? []}
            value={value}
            loading={loading}
            disabled={!!props.item.disabled}
            disableClearable
            selectOnFocus
            classes={{
                popper: classes.popper,
                listbox: classes.listbox,
            }}
            handleHomeEndKeys
            freeSolo
            filterOptions={(options, params) => {
                const filtered = filter(options, params)
                return filtered
            }}
            renderOption={(option) => option.title || option}
            getOptionLabel={(option) =>
                typeof option === "string"
                    ? option
                    : option.inputValue
                    ? option.inputValue
                    : option.title
            }
            onChange={(event: any, newValue: any, reason: any) => {
                setValue(newValue)
                const newState = getNewState(
                    props.item,
                    newValue,
                    props.filters,
                    null
                )
                if (newState) {
                    props.setFilters(newState)
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t("tags", "Tags ")}
                    placeholder={t("enterTags", "Enter Tags")}
                />
            )}
            includeInputInList={true}
        />
    )
}
