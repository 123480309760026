export const FEATURE_SCOPING_STATUSES = {
    NOT_STARTED: "Not Started",
    IN_PROGRESS: "In Progress",
    COMPLETE: "Complete",
}
export const getScopingStatus = (status) => {
    const statusString = FEATURE_SCOPING_STATUSES[status]
    if (statusString) {
        return statusString
    }
    return ""
}
export const FEATURE_APPROVAL_VALUES = {
    true: "Approved",
    false: "Not Approved",
    null: "Not Approved",
}
export const getFeatureApproval = (approval) => {
    const approvalString = FEATURE_APPROVAL_VALUES[approval]
    if (approvalString) {
        return approvalString
    }
    return ""
}
export const PERMISSIONS_TYPE_VALUES = {
    NONE: "None",
    VIEWER: "Viewer",
    EDITOR: "Editor",
    OWNER: "Owner",
    undefined: "",
    null: "",
}

export const getPermissionsType = (type) => {
    const permissionsString = PERMISSIONS_TYPE_VALUES[type]
    if (permissionsString) {
        return permissionsString
    }
    return ""
}

export function parseEnum(str) {
    return str
        .toLowerCase()
        .replace(/_/g, " ")
        .replace(/(?: |\b)(\w)/g, function (key, p1) {
            return key.toUpperCase()
        })
}
