import { useQuery } from "@apollo/client"
import { Box, Grow } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import {
    createAlignPlugin,
    createBlockquotePlugin,
    createBoldPlugin,
    createCodeBlockPlugin,
    createCodePlugin,
    createDeserializeHTMLPlugin,
    createExitBreakPlugin,
    createHeadingPlugin,
    createHighlightPlugin,
    createHistoryPlugin,
    createImagePlugin,
    createItalicPlugin,
    createKbdPlugin,
    createLinkPlugin,
    createListPlugin,
    createMediaEmbedPlugin,
    createNodeIdPlugin,
    createParagraphPlugin,
    createReactPlugin,
    createAutoformatPlugin,
    createResetNodePlugin,
    createSelectOnBackspacePlugin,
    createPlateComponents,
    createPlateOptions,
    StyledElement,
    createSoftBreakPlugin,
    createStrikethroughPlugin,
    createSubscriptPlugin,
    createSuperscriptPlugin,
    createTablePlugin,
    createTodoListPlugin,
    createTrailingBlockPlugin,
    createUnderlinePlugin,
    ELEMENT_IMAGE,
    ELEMENT_MENTION,
    ELEMENT_PARAGRAPH,
    ELEMENT_H3,
    ELEMENT_H1,
    ELEMENT_H2,
    HeadingToolbar,
    MentionElement,
    MentionNodeData,
    MentionSelect,
    Plate,
    TNode,
    useFindReplacePlugin,
    useMentionPlugin,
    withProps,
    createDeserializeMDPlugin,
    createDeserializeCSVPlugin,
    createDeserializeAstPlugin,
} from "@udecode/plate"
import React, { useCallback, useMemo, useRef, useState, useEffect } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { EditableProps } from "slate-react/dist/components/editable"
import { ALL_USER_QUERY } from "./users/graphql"
import {
    AllUsersQuery,
    AllUsersQueryVariables,
} from "./users/__generated__/graphql"
import {
    // editableProps,
    optionsExitBreakPlugin,
    // optionsMentionPlugin,
    optionsAutoformat,
    optionsResetBlockTypePlugin,
    optionsSoftBreakPlugin,
} from "./slate-plugins/pluginOptions"
import {
    BallonToolbarMarks,
    ToolbarButtonsAlign,
    ToolbarButtonsBasicElements,
    ToolbarButtonsBasicMarks,
    ToolbarButtonsList,
} from "./slate-plugins/Toolbars"
import useConceptLink, { ELEMENT_CONCEPT } from "./slate-plugins/useConceptLink"
import { convertValues, SlateValue } from "./slate-plugins/utils"
import { withStyledDraggables } from "./slate-plugins/withStyledDraggables"
import { withStyledPlaceHolders } from "./slate-plugins/withStyledPlaceHolders"
import ConceptMention from "./slate-plugins/ConceptMention"
import UserMention from "./slate-plugins/UserMention"

const useStyles = makeStyles((theme) => ({
    RichTextEditor: {
        flexGrow: 1,
        padding: theme.spacing(0, 4),
        paddingTop: theme.spacing(1),
        overflow: "auto",
        margin: 0,
        marginTop: 0,
        height: "inherit",
        minHeight: "inherit",
    },
    BaseFunctionality: {
        flexGrow: 1,
        padding: theme.spacing(0, 1),
        overflow: "auto",
        margin: 0,
        marginTop: 0,
        height: "inherit",
        minHeight: "5em !important",
        maxHeight: "10em",
    },
    ReadOnly: {
        flexGrow: 1,
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(1),
        overflow: "auto",
        margin: 0,
        height: "inherit",
        minHeight: "inherit",
    },

    focused: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid",
        borderColor: theme.palette.primary.main,
    },

    base: {
        border: "1px solid transparent",
        "&:hover": {
            border: `1px solid ${theme.palette.text.primary}`,
        },
    },
    alwaysEditing: {
        border: `1px solid ${theme.palette.divider}`,
        "&:hover": {
            border: `1px solid ${theme.palette.text.primary}`,
        },
        borderRadius: theme.shape.borderRadius,
    },
    active: {
        color: `${theme.palette.primary.main} !important`,
    },
}))

interface RichTextProps {
    editorId?: string
    placeholderText?: string
    onChange?: (value: string) => void
    initialValue?: string
    isReadOnly?: boolean
    baseFunctionality?: boolean
    users?: any[]
    alwaysEditing?: boolean
    disableEffects?: boolean
}

const RichTextEditor = (props: RichTextProps) => {
    const { data } = useQuery<AllUsersQuery, AllUsersQueryVariables>(
        ALL_USER_QUERY
    )
    // RichTextEditor is an uncontrolled component that only uses the first initialValue and ignore the others for perfomance reason.
    // If you are trying to control the value of the editor, past the initial value, it won't work.
    // This is on-purpose, as the alternative is to handle every change and forcing Slate to reevaluate every config on change.
    let originalInitialValue = useRef(props.initialValue)
    let initialTransformedValue = useMemo(
        () => convertValues(originalInitialValue.current),
        []
    )

    const originalOnChange = props.onChange
    const onChange = useCallback(
        (data) => {
            if (!!originalOnChange) {
                originalOnChange(JSON.stringify(data))
            }
        },
        [originalOnChange]
    )

    if (!data?.users) {
        return null
    }

    return (
        <SlateEditor
            {...props}
            onChange={onChange}
            editorId={props.editorId}
            initialValue={initialTransformedValue}
            users={data?.users ?? []}
        />
    )
}

const SlateEditor = (
    props: Omit<RichTextProps, "initialValue" | "onChange"> & {
        initialValue?: SlateValue
        onChange: (value: TNode[]) => void
        editorId: string
    }
) => {
    const classes = useStyles()
    const [focused, setFocused] = useState(false)
    const [editorClass, setEditorClass] = useState("")
    useEffect(() => {
        if (!props.isReadOnly && !props.disableEffects) {
            if (!!props.alwaysEditing) {
                setEditorClass(classes.alwaysEditing)
            } else {
                setEditorClass(classes.base)
            }
        }
    }, [
        props.isReadOnly,
        props.alwaysEditing,
        classes.alwaysEditing,
        classes.base,
        props.disableEffects,
    ])
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))

    const readOnlyEditableProps: EditableProps = {
        className: classes.ReadOnly,
        readOnly: true,
    }

    const editableProps: EditableProps = {
        className: !!props.baseFunctionality
            ? classes.BaseFunctionality
            : classes.RichTextEditor,
        style: {
            marginTop: 0,
            height: "inherit",
            minHeight: "inherit",
        },
        placeholder: props.placeholderText ?? "",
        spellCheck: true,
        readOnly: props.isReadOnly,
    }

    let components = createPlateComponents({
        [ELEMENT_MENTION]: withProps(MentionElement, {
            // @ts-expect-error RenderLabel supports JSX
            renderLabel: (entry) => <UserMention user={entry} />,
            styles: {
                root: {
                    fontWeight: 600,
                    backgroundColor: "transparent",
                    padding: 0,
                },
            },
        }),
        [ELEMENT_CONCEPT]: withProps(MentionElement, {
            // @ts-expect-error RenderLabel supports JSX
            renderLabel: (entry) => <ConceptMention concept={entry} />,
            styles: {
                root: {
                    fontWeight: 600,
                    backgroundColor: "transparent",
                    padding: 0,
                },
            },
        }),

        // had to add it to override the absurd margins, color, etc.
        [ELEMENT_H1]: withProps(StyledElement, {
            as: "h1",
            styles: {
                root: {
                    fontWeight: 500,
                },
            },
        }),
        [ELEMENT_H2]: withProps(StyledElement, {
            as: "h2",
            styles: {
                root: {
                    fontWeight: 500,
                },
            },
        }),
        [ELEMENT_H3]: withProps(StyledElement, {
            as: "h3",
            styles: {
                root: {
                    fontWeight: 500,
                },
            },
        }),
    })
    components = withStyledPlaceHolders(components)
    if (!props.baseFunctionality) {
        components = withStyledDraggables(components, theme)
    }

    const options = createPlateOptions({})
    const optionsMentionPlugin = {
        mentionables: props.users,
        maxSuggestions: 10,
        trigger: "@",
        mentionableFilter: (s: string) => (mentionable: MentionNodeData) => {
            return (
                mentionable?.email?.toLowerCase().includes(s.toLowerCase()) ||
                mentionable?.firstName
                    ?.toLowerCase()
                    .includes(s.toLowerCase()) ||
                mentionable?.lastName?.toLowerCase().includes(s.toLowerCase())
            )
        },
        mentionableSearchPattern: "\\S*",
    }
    const { plugin: searchHighlightPlugin } = useFindReplacePlugin()
    const { getMentionSelectProps, plugin: mentionPlugin } = useMentionPlugin(
        optionsMentionPlugin
    )

    const { getConceptSelectProps, plugin: conceptsPlugin } = useConceptLink()

    const plugins: any[] = useMemo(() => {
        const p = [
            // editor
            createReactPlugin(),
            createHistoryPlugin(),

            // Elements
            createParagraphPlugin(),
            createBlockquotePlugin(),
            createTodoListPlugin(),
            createHeadingPlugin(),
            createImagePlugin(),
            createLinkPlugin(),
            createListPlugin(),
            createTablePlugin(),
            createMediaEmbedPlugin(),
            createCodeBlockPlugin(),
            createAlignPlugin(),

            // marks
            createBoldPlugin(),
            createCodePlugin(),
            createItalicPlugin(),
            createHighlightPlugin(),
            createUnderlinePlugin(),
            createStrikethroughPlugin(),
            createSubscriptPlugin(),
            createSuperscriptPlugin(),
            createKbdPlugin(),
            createNodeIdPlugin(),

            // formats
            createAutoformatPlugin(optionsAutoformat),
            createResetNodePlugin(optionsResetBlockTypePlugin),
            createSoftBreakPlugin(optionsSoftBreakPlugin),
            createExitBreakPlugin(optionsExitBreakPlugin),

            createTrailingBlockPlugin({
                type: ELEMENT_PARAGRAPH,
            }),
            createSelectOnBackspacePlugin({
                allow: ELEMENT_IMAGE,
            }),
            mentionPlugin,
            conceptsPlugin,
            searchHighlightPlugin,
        ]

        p.push(
            ...[
                createDeserializeMDPlugin({ plugins: p }),
                createDeserializeCSVPlugin({ plugins: p }),
                createDeserializeHTMLPlugin({ plugins: p }),
                createDeserializeAstPlugin({ plugins: p }),
            ]
        )
        return p
    }, [mentionPlugin, conceptsPlugin, searchHighlightPlugin])
    return (
        <Box
            display="flex"
            flexGrow={1}
            className={editorClass}
            flexDirection="column"
            overflow="hidden"
            key={props.editorId}
            onClick={() => {
                if (!props.isReadOnly) {
                    if (!props.disableEffects) {
                        setEditorClass(classes.focused)
                    }

                    setFocused(true)
                }
            }}
            onBlur={() => {
                if (!props.isReadOnly) {
                    if (!props.disableEffects) {
                        if (props.alwaysEditing) {
                            setEditorClass(classes.alwaysEditing)
                        } else {
                            setEditorClass(classes.base)
                        }
                    }

                    setFocused(false)
                }
            }}
            style={{
                borderTopLeftRadius: !!props.alwaysEditing
                    ? theme.shape.borderRadius
                    : 0,
                borderTopRightRadius: !!props.alwaysEditing
                    ? theme.shape.borderRadius
                    : 0,
            }}
        >
            <DndProvider backend={HTML5Backend}>
                <Plate
                    id={props.editorId}
                    plugins={plugins}
                    components={components}
                    options={options}
                    editableProps={
                        props.isReadOnly ? readOnlyEditableProps : editableProps
                    }
                    initialValue={props.initialValue}
                    onChange={props.onChange}
                >
                    {!props.isReadOnly && (
                        <Grow
                            in={!mobile && (focused || !!props.alwaysEditing)}
                        >
                            <HeadingToolbar
                                styles={{
                                    root: {
                                        color: theme.palette.text.primary,
                                        display: props.baseFunctionality
                                            ? "none"
                                            : "flex",
                                        padding: theme.spacing(1),
                                        margin: 0,
                                        minHeight: "auto",
                                        borderBottom: `1px solid ${
                                            !focused || !!props.disableEffects
                                                ? theme.palette.divider
                                                : theme.palette.primary.main
                                        }`,
                                    },
                                }}
                            >
                                {!props.baseFunctionality && (
                                    <>
                                        <ToolbarButtonsBasicElements />
                                        <ToolbarButtonsList />
                                        <ToolbarButtonsBasicMarks />
                                        <ToolbarButtonsAlign />
                                    </>
                                )}
                            </HeadingToolbar>
                        </Grow>
                    )}
                    <BallonToolbarMarks />
                    <MentionSelect
                        {...getMentionSelectProps()}
                        renderLabel={(entry) =>
                            `${entry.firstName} ${entry.lastName} (${entry.username})`
                        }
                        styles={{
                            root: {
                                background: theme.palette.grey[700],
                                color: theme.palette.text.primary,
                                padding: 0,
                                zIndex: theme.zIndex.tooltip,
                            },
                            mentionItemSelected: {
                                background: theme.palette.primary.light,
                            },
                        }}
                    />
                    <MentionSelect
                        {...getConceptSelectProps()}
                        renderLabel={(entry) =>
                            `${entry.title} (${entry.conceptType})`
                        }
                        styles={{
                            root: {
                                background: theme.palette.grey[700],
                                color: theme.palette.text.primary,
                                padding: 0,
                                zIndex: theme.zIndex.tooltip,
                            },
                            mentionItemSelected: {
                                background: theme.palette.primary.light,
                            },
                        }}
                    />
                </Plate>
            </DndProvider>
        </Box>
    )
}
export default React.memo(RichTextEditor)
