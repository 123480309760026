import { useMutation } from "@apollo/client"
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    Tooltip,
    Divider,
    IconButton,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import { Switches, TextField } from "mui-rff"
import { useState } from "react"
import { Form } from "react-final-form"
import { ADD_CONCEPT_WEB_LINK, CREATE_CONCEPT } from "../graphql/mutations"
import { GRANT_USER_ACCESS } from "./permissions/graphql"
import {
    GrantUserAccessMutation,
    GrantUserAccessMutationVariables,
} from "./permissions/__generated__/graphql"
import {
    CreateConceptCompleteMutation,
    CreateConceptCompleteMutationVariables,
} from "../graphql/__generated__/mutations"
import {
    AddConceptWebLinkMutation,
    AddConceptWebLinkMutationVariables,
} from "../graphql/__generated__/mutations"
import { ADD_CONCEPT_CATEGORY } from "./categories/graphql"

import {
    AddConceptCategoryMutation,
    AddConceptCategoryMutationVariables,
} from "./categories/__generated__/graphql"

import { useAuth } from "../providers/AuthProvider"
import { ConceptFieldSettings } from "../util/SystemSettings"
import FetchLink from "./FetchLink"
import TagInput from "./tags/TagInput"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { AccessType, Category, Link } from "../__generated__/types"
import { Loading } from "./Loading"
import { Concept } from "../__generated__/types"
import { ConceptInterface } from "./LinkConcept"
import { IDEAS_FEED_QUERY } from "../graphql/queries"
import CategoryTreeSelector from "./categories/CategoryTreeSelector"

const toTitleCase = (string) => {
    if (typeof string === "string") {
        return string.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
    } else {
        return string
    }
}

const useStyles = makeStyles((theme) => ({
    formTitle: {
        fontSize: "1.5rem",
        textAlign: "center",
    },
    formContainer: {
        padding: theme.spacing(1.5),
        paddingTop: 0,
        minWidth: "300px",
        width: "100%",
        maxWidth: "100vw",
        marginTop: theme.spacing(3),
    },
    formField: {
        margin: "1rem",
    },
}))

interface ConceptFormProps {
    concept?: ConceptInterface
    closeForm: () => void
    openSnackbar?: (id: string) => void
    creationCallback?: (concept: Concept) => void
    formTitle?: string
    goToLongForm?: () => void
}
export default function ConceptForm(props: ConceptFormProps) {
    const classes = useStyles()

    const { currentUser } = useAuth()
    const [item, setItem] = useState(props.concept)
    const [createFromUrl, setCreateFromUrl] = useState(false)

    const [addCategory] = useMutation<
        AddConceptCategoryMutation,
        AddConceptCategoryMutationVariables
    >(ADD_CONCEPT_CATEGORY)

    const [tags, setTags] = useState<Array<string>>([])
    const [category, setCategory] = useState<Category>(null)
    const [linkItem, setLinkItem] = useState<Link | null>(null)
    const [linkLoading, setLinkLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [grantUserAccess] = useMutation<
        GrantUserAccessMutation,
        GrantUserAccessMutationVariables
    >(GRANT_USER_ACCESS)
    const { t } = useAwaitTranslation("ideas")
    const [createConcept] = useMutation<
        CreateConceptCompleteMutation,
        CreateConceptCompleteMutationVariables
    >(CREATE_CONCEPT, {
        refetchQueries: [
            {
                query: IDEAS_FEED_QUERY,
                variables: {
                    offset: 0,
                    first: 5,
                    filter: {},
                },
            },
        ],
    })

    const [addWebLink] = useMutation<
        AddConceptWebLinkMutation,
        AddConceptWebLinkMutationVariables
    >(ADD_CONCEPT_WEB_LINK)
    const submitConcept = async (data) => {
        let {
            data: { CreateConcept: NewConcept },
        } = await createConcept({
            variables: {
                userId: currentUser?.userId,
                concept: {
                    title: data.title,
                    type: category?.isRoot
                        ? category.name
                        : category.parent.name,
                    summary: data.summary,
                    isPublic: data.isPublic,
                    isPublicAccessType: data.isPublic
                        ? AccessType.VIEWER
                        : AccessType.NONE,
                },
                tags: tags,
            },
        })

        if (category) {
            addCategory({
                variables: {
                    id: NewConcept?.id,
                    categoryId: category?.id,
                },
            })
        }

        if (linkItem) {
            addWebLink({
                variables: {
                    from: { id: NewConcept?.id },
                    to: { id: linkItem?.id },
                },
            })
        }
        await grantUserAccess({
            // so permissions are ready if the snackbar is clicked
            variables: {
                conceptId: NewConcept.id,
                userId: currentUser.userId,
                accessType: AccessType.OWNER,
            },
        })
        if (!!props.openSnackbar) props.openSnackbar(NewConcept.id)
        if (!!props.creationCallback) props.creationCallback(NewConcept)
        setSubmitting(false)
        props.closeForm()
    }

    const handleLink = (linkData: Link) => {
        setLinkItem(linkData)
        let formItem = {
            title: linkData.title,
            summary:
                linkData.type && linkData.provider
                    ? `${toTitleCase(linkData.type)} from ${toTitleCase(
                          linkData.provider
                      )}`
                    : null,
            description: linkData.description
                ? JSON.stringify([
                      {
                          type: "paragraph",
                          children: [{ text: linkData.description }],
                      },
                  ])
                : null,
            imageUrl: linkData.image,
        }
        setItem({ ...item, ...formItem })
        setLinkLoading(false)
    }

    if (submitting) {
        return <Loading size={75} hideQuote={true} />
    } else {
        return (
            <Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    p={1}
                    alignItems="center"
                >
                    <Box display="flex">
                        <Tooltip title="Go to detailed form">
                            <Button
                                onClick={() => {
                                    props.goToLongForm()
                                }}
                            >
                                Detailed Form
                            </Button>
                        </Tooltip>
                        <Divider flexItem orientation="vertical" />
                        <Tooltip title="Fill form with entered URL metadata">
                            <Button onClick={() => setCreateFromUrl(true)}>
                                Create from URL
                            </Button>
                        </Tooltip>
                    </Box>

                    <Tooltip title="Close form">
                        <IconButton
                            onClick={() => props.closeForm()}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider />
                <Box className={classes.formContainer}>
                    <Typography className={classes.formTitle} variant="h4">
                        {props.formTitle || "Quick Capture Form"}
                    </Typography>
                    <Form
                        onSubmit={(values, form) => {
                            setSubmitting(true)
                            submitConcept(values).catch((error) => {
                                console.log(error)
                                throw error
                            })
                        }}
                        initialValues={item || {}}
                        validate={(values) => {
                            const errors = {} as any
                            if (!values.title) {
                                errors.title = t(
                                    "enterTitleError",
                                    "You must enter a title"
                                )
                            }
                            return errors
                        }}
                        render={({ handleSubmit, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid
                                    container
                                    direction="column"
                                    alignContent="stretch"
                                >
                                    {createFromUrl && (
                                        <Grid
                                            item
                                            key="link"
                                            className={classes.formField}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <FetchLink
                                                    setLoading={setLinkLoading}
                                                    handleLink={(
                                                        link: Link
                                                    ) => {
                                                        handleLink(link)
                                                    }}
                                                    autoFocus={true}
                                                />
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setCreateFromUrl(false)
                                                        setLinkItem(null)
                                                    }}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </Box>

                                            {linkLoading && (
                                                <Box
                                                    m={1}
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <CircularProgress
                                                        disableShrink
                                                        size="1em"
                                                    />{" "}
                                                    {t(
                                                        "fetchingLinkDetails",
                                                        "Fetching Link Details..."
                                                    )}
                                                </Box>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        key="type"
                                        className={classes.formField}
                                    >
                                        <CategoryTreeSelector
                                            value={category}
                                            setValue={setCategory}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        key="title"
                                        className={classes.formField}
                                    >
                                        <TextField
                                            inputProps={{
                                                maxLength:
                                                    ConceptFieldSettings.Title
                                                        .maxLength,
                                                autoFocus: !props.concept,
                                            }}
                                            variant="outlined"
                                            name="title"
                                            label="Title"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        key="summary"
                                        className={classes.formField}
                                    >
                                        <TextField
                                            multiline
                                            rows={4}
                                            inputProps={{
                                                maxLength:
                                                    ConceptFieldSettings.Summary
                                                        .maxLength,
                                            }}
                                            variant="outlined"
                                            name="summary"
                                            label="Summary (Tagline)"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        key="tag"
                                        className={classes.formField}
                                    >
                                        <TagInput
                                            setTags={setTags}
                                            disabled={false}
                                            clickable={false}
                                        ></TagInput>
                                    </Grid>
                                </Grid>
                                <Box m="1rem">
                                    <Switches
                                        name="isPublic"
                                        data={{ label: "Public", value: false }}
                                    />
                                    <Button
                                        type="submit"
                                        style={{ float: "right" }}
                                    >
                                        {t("submit", "Submit")}
                                    </Button>
                                </Box>
                            </form>
                        )}
                    />
                </Box>
            </Box>
        )
    }
}
