import { ApolloQueryResult, useReactiveVar } from "@apollo/client"
import { useParams } from "react-router-dom"
import { DashboardConceptByIdQuery } from "../dashboard/__generated__/graphql"
import HomeIcon from "@material-ui/icons/Home"
import React, { useState } from "react"
import {
    Box,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    useTheme,
    Hidden,
    Button,
} from "@material-ui/core"
import { Concept } from "../../__generated__/types"
import { makeStyles, fade } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"
import DashboardManagerList from "./DashboardManagerList"
import DashboardManagerForm from "./DashboardManagerForm"
import { DashboardStore } from "./DashboardStore"
import AddIcon from "@material-ui/icons/Add"
import { useGlobalStyles } from "../../styles/globalStyles"
import { permissionsVar } from "../../providers/GlobalState"
const useStyles = makeStyles((theme) => ({
    listItem: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        padding: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
        borderBottom: "1px solid " + theme.palette.divider,
    },
    listItemIcon: {
        minWidth: theme.spacing(4),
    },
    selected: {
        backgroundColor: fade(theme.palette.primary.main, 0.08),
        padding: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
        borderBottom: "1px solid " + theme.palette.divider,
        borderLeft: "5px solid " + theme.palette.primary.main,
    },
    button: {
        backgroundColor: theme.palette.background.paper,
    },
}))

interface DashboardManagerProps {
    editing: boolean
    toggleEditing: () => void
    concept: Concept
    refetch: () => Promise<ApolloQueryResult<DashboardConceptByIdQuery>>
}
export default function DashboardManager(props: DashboardManagerProps) {
    const { dashboardId, username, conceptId, teamId } = useParams()
    const route = !!conceptId
        ? `/concept/${conceptId}`
        : !!teamId
        ? `/team/${teamId}`
        : `/user/${username}`
    const classes = useStyles()
    const globalClasses = useGlobalStyles()
    const editPermission = useReactiveVar(permissionsVar)
    const [dashboardFormOpen, setDashboardFormOpen] = useState(false)
    const history = useHistory()
    const dashboardList = {
        dashboardIds: props.concept.dashboardIds?.filter(
            (item) => item !== props.concept.homeDashboard?.id
        ),
        dashboards: props.concept.usesDashboard?.filter(
            (item) => item.id !== props.concept.homeDashboard?.id
        ),
    }
    const theme = useTheme()
    const selectedDashboard =
        dashboardId !== "home"
            ? props.concept.usesDashboard?.filter(
                  (x) => x.id === dashboardId
              )[0]
            : null
    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                overflow="hidden"
                style={{
                    //@ts-ignore
                    backgroundColor: theme.palette.background.level2,
                }}
            >
                <ListItem
                    onClick={() => {
                        history.replace(`${route}/home`)
                    }}
                    className={
                        !selectedDashboard ? classes.selected : classes.listItem
                    }
                >
                    <ListItemText primary="Home" />
                    <ListItemIcon className={globalClasses.smallMenuIcon}>
                        <HomeIcon fontSize="small" />
                    </ListItemIcon>
                </ListItem>
                <Box flexGrow={1} overflow="auto">
                    <DashboardManagerList
                        selectedDashboard={selectedDashboard}
                        dashboardList={dashboardList}
                        concept={props.concept}
                    />
                </Box>
                {!!editPermission && (
                    <Hidden smDown>
                        <>
                            <Divider />
                            <Box>
                                <DashboardStore refetch={props.refetch} />
                            </Box>
                            <Box
                                p={1.5}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => setDashboardFormOpen(true)}
                                    //className={classes.button}
                                    color="primary"
                                >
                                    <AddIcon />
                                    New Dashboard
                                </Button>
                            </Box>
                        </>
                    </Hidden>
                )}
            </Box>
            {dashboardFormOpen && (
                <DashboardManagerForm
                    onClose={() => setDashboardFormOpen(false)}
                    conceptId={props.concept.id}
                    refetch={props.refetch}
                />
            )}
        </>
    )
}
