import React from "react"
import Popper from "@material-ui/core/Popper"
import {
    IconButton,
    Paper,
    Box,
    Fade,
    ClickAwayListener,
} from "@material-ui/core"
import { ReactComponent as GlobalFilterIcon } from "../../../../styles/FilterIcon.svg"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { FilterItem } from "../../../filters/util/types"
import Filters from "../../../filters/Filters"
import { getNumFiltersEnabled } from "../../../filters/util/getNumFiltersEnabled"
import useFilters from "../../../filters/util/useFilters"
const useStyles = makeStyles((theme) => ({
    icon: {
        width: "0.8em",
        height: "0.8em",
        fill: "currentColor",
    },
    popper: {
        minWidth: "30em",
        zIndex: theme.zIndex.modal + 1,
    },
}))

interface WidgetFilterToggleProps {
    size?: "small" | "medium"
    filters: FilterItem[]
    setFilters: (filters: FilterItem[]) => void
    filterOptions?: FilterItem[]
    isDialog?: boolean
}

export default function WidgetFilterToggle(props: WidgetFilterToggleProps) {
    const classes = useStyles()
    const { defaultOptions } = useFilters()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const theme = useTheme()
    const open = Boolean(anchorEl)
    const filtersEnabled = getNumFiltersEnabled(props.filters) ?? 0
    return (
        <>
            <IconButton size={props.size ?? "medium"} onClick={handleClick}>
                <GlobalFilterIcon
                    className={classes.icon}
                    style={{
                        fill:
                            filtersEnabled > 0
                                ? theme.palette.primary.main
                                : "currentColor",
                    }}
                />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorEl}
                transition
                placement="top"
                disablePortal={!!props.isDialog}
                className={classes.popper}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper elevation={7}>
                                <Box p={1} width={"30em"}>
                                    <Filters
                                        setFilters={(filters: FilterItem[]) =>
                                            props.setFilters(filters)
                                        }
                                        filters={props.filters}
                                        filterOptions={
                                            props.filterOptions ??
                                            defaultOptions
                                        }
                                    />
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    )
}
