import { gql } from "@apollo/client"

export const GET_TAGS = gql`
    query AllTags {
        Tag(first: 50, orderBy: name_asc) {
            name
        }
    }
`

export const SEARCH_TAGS = gql`
    query SearchTags($term: ID!) {
        Tag(first: 25, filter: { name_contains: $term }) {
            name
        }
    }
`

export const TAG_CONCEPT = gql`
    mutation TagConcepts($conceptId: ID!, $tagName: ID!) {
        mergeTag: MergeTag(name: $tagName) {
            name
            tagged {
                id
                title
            }
        }
        tagConcept: MergeConceptTags(
            from: { id: $conceptId }
            to: { name: $tagName }
        ) {
            from {
                id
                tags(orderBy: name_asc) {
                    name
                }
            }
        }
    }
`

export const REMOVE_TAG = gql`
    mutation RemoveConceptTags($conceptId: ID!, $tagName: ID!) {
        RemoveConceptTags(from: { id: $conceptId }, to: { name: $tagName }) {
            from {
                id
                tags(orderBy: name_asc) {
                    name
                }
            }
        }
    }
`
