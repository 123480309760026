import { useMutation, ApolloQueryResult } from "@apollo/client"
import {
    CONNECT_DASHBOARD_TO_CONCEPT,
    CREATE_DASHBOARD,
    UPDATE_DASHBOARD_PROPERTIES,
} from "./graphql"

import {
    ConnectDashboardToConceptMutation,
    ConnectDashboardToConceptMutationVariables,
    DashboardConceptByIdQuery,
    CreateDashboardMutation,
    CreateDashboardMutationVariables,
    UpdateDashboardPropertiesMutation,
    UpdateDashboardPropertiesMutationVariables,
} from "./__generated__/graphql"

import React, { useEffect, useState } from "react"
import {
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    TextField,
    CircularProgress,
} from "@material-ui/core"
import { DashboardView } from "../../__generated__/types"
import { useHistory, useParams } from "react-router-dom"
interface Props {
    onClose: () => void
    conceptId?: string
    refetch?: () => Promise<ApolloQueryResult<DashboardConceptByIdQuery>>
    dashboard?: DashboardView
}
const DashboardManagerForm = (props: Props) => {
    const { conceptId, username, teamId } = useParams()
    const [saving, setSaving] = useState(false)
    const history = useHistory()
    const route = !!conceptId
        ? `/concept/${conceptId}`
        : !!teamId
        ? `/team/${teamId}`
        : `/user/${username}`
    const [dashboard, setDashboard] = useState({
        name: "",
        description: "",
    })

    useEffect(() => {
        if (props.dashboard) {
            setDashboard({
                name: props.dashboard.name,
                description: props.dashboard.description,
            })
        }
    }, [props.dashboard])
    const [createDashboard] = useMutation<
        CreateDashboardMutation,
        CreateDashboardMutationVariables
    >(CREATE_DASHBOARD)
    const [updateDashboard] = useMutation<
        UpdateDashboardPropertiesMutation,
        UpdateDashboardPropertiesMutationVariables
    >(UPDATE_DASHBOARD_PROPERTIES)
    const [connectDashboardToConcept] = useMutation<
        ConnectDashboardToConceptMutation,
        ConnectDashboardToConceptMutationVariables
    >(CONNECT_DASHBOARD_TO_CONCEPT)

    const onCreateDashboard = async () => {
        setSaving(true)
        const { data } = await createDashboard({
            variables: {
                ...dashboard,
            },
        })
        await connectDashboardToConcept({
            variables: {
                conceptId: props.conceptId,
                dashboardId: data?.CreateDashboardView?.id,
            },
        })
        await props.refetch()
        history.replace(`${route}/${data?.CreateDashboardView?.id}`)
        setSaving(false)
        props.onClose()
    }
    const onUpdateDashboard = async () => {
        setSaving(true)
        await updateDashboard({
            variables: {
                id: props.dashboard.id,
                ...dashboard,
            },
        })
        setSaving(false)
        props.onClose()
    }
    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>
                {props.dashboard ? "Edit" : "New"} Dashboard
            </DialogTitle>
            <DialogContent>
                <Box p={1} width="100%">
                    <TextField
                        autoFocus
                        fullWidth
                        value={dashboard.name}
                        onChange={(e) =>
                            setDashboard({
                                ...dashboard,
                                name: e.target.value,
                            })
                        }
                        label="Name"
                    />
                </Box>
                <Box p={1} width="100%">
                    <TextField
                        fullWidth
                        multiline
                        value={dashboard.description}
                        onChange={(e) =>
                            setDashboard({
                                ...dashboard,
                                description: e.target.value,
                            })
                        }
                        label="Description"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} disabled={saving}>
                    Discard
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={saving}
                    onClick={() => {
                        props.dashboard
                            ? onUpdateDashboard()
                            : onCreateDashboard()
                    }}
                >
                    {saving ? (
                        <CircularProgress size={25} disableShrink />
                    ) : (
                        "Save"
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DashboardManagerForm
