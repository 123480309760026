import React, { useState, useEffect } from "react"
import {
    Box,
    Popper,
    Paper,
    Button,
    ClickAwayListener,
    CircularProgress,
} from "@material-ui/core"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation } from "@apollo/client"
import { UPDATE_CONCEPT } from "../graphql/mutations"

import { useAuth } from "../providers/AuthProvider"
const useStyles = makeStyles((theme) => ({
    paperPopper: {
        padding: theme.spacing(1),
        width: "25em",
    },
    valueContainer: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
    },
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },
}))
var months: string[] = []
months[0] = "January"
months[1] = "February"
months[2] = "March"
months[3] = "April"
months[4] = "May"
months[5] = "June"
months[6] = "July"
months[7] = "August"
months[8] = "September"
months[9] = "October"
months[10] = "November"
months[11] = "December"

interface DatePickerPopperProps {
    item: any
    anchorEl: any
    onClose: () => void
}
export default function DatePickerPopper(props: DatePickerPopperProps) {
    const classes = useStyles()
    const { currentUser } = useAuth()
    const [startValue, setStartValue] = useState<Date>(new Date())
    const [endValue, setEndValue] = useState<Date>(new Date())
    const [saving, setSaving] = useState(false)
    const [updateConcept] = useMutation(UPDATE_CONCEPT)

    useEffect(() => {
        if (props.item.startDate.formatted) {
            setStartValue(new Date(props.item.startDate.formatted))
        }
        if (props.item.endDate.formatted) {
            setEndValue(new Date(props.item.endDate.formatted))
        }
    }, [props.item])

    const handleSave = async (start, end) => {
        setSaving(true)
        await updateConcept({
            variables: {
                userId: currentUser.userId,
                concept: {
                    id: props.item.id,
                    startDate: start,
                    endDate: end,
                },
            },
        })
        props.onClose()
    }
    const handleCancel = () => {
        if (props.item.startDate.formatted) {
            setStartValue(new Date(props.item.startDate.formatted))
        }
        if (props.item.endDate.formatted) {
            setEndValue(new Date(props.item.endDate.formatted))
        }
        props.onClose()
    }
    const open = Boolean(props.anchorEl)
    return (
        <Popper
            open={open}
            anchorEl={props.anchorEl}
            className={classes.popper}
        >
            <ClickAwayListener onClickAway={() => props.onClose()}>
                <Paper className={classes.paperPopper}>
                    <Box p={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                views={["month", "year"]}
                                size="small"
                                autoOk={true}
                                variant="inline"
                                fullWidth
                                openTo="month"
                                label="Start Date"
                                value={startValue}
                                onChange={setStartValue}
                                disabled={saving}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                    <Box p={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                views={["month", "year"]}
                                size="small"
                                autoOk={true}
                                fullWidth
                                minDate={startValue}
                                minDateMessage="End date must be after start date"
                                openTo="month"
                                variant="inline"
                                label="End Date"
                                value={endValue}
                                onChange={setEndValue}
                                disabled={saving}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                    <Box p={1} display="flex" justifyContent="flex-end">
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const start = {
                                    day: 2, // to avoid overlap
                                    month: startValue.getMonth() + 1, //neo 4j requires 1-12, not 0
                                    year: startValue.getFullYear(),
                                }
                                const end = {
                                    day: 2, // to avoid overlap
                                    month: endValue.getMonth() + 1, //neo 4j requires 1-12, not 0
                                    year: endValue.getFullYear(),
                                }
                                handleSave(start, end)
                            }}
                            disabled={
                                saving ||
                                startValue.getTime() > endValue.getTime()
                            }
                        >
                            {saving ? (
                                <CircularProgress
                                    size={25}
                                    disableShrink={true}
                                />
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Box>
                </Paper>
            </ClickAwayListener>
        </Popper>
    )
}
