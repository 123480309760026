import { default as React, useState, useEffect } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    TextField,
    Tooltip,
    Select,
    MenuItem,
    FormControl,
    Typography,
    InputLabel,
    useTheme,
} from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
import ClearIcon from "@material-ui/icons/Clear"
import ConceptListItem from "../ConceptListItem"
import LinkConcept from "../LinkConcept"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { CHECKLIST_BY_ID } from "../../graphql/Checklist"
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd"
import {
    ChecklistByIdQuery,
    ChecklistByIdQueryVariables,
} from "../../graphql/__generated__/Checklist"

import {
    ADD_EXERCISE_FACILITATOR,
    ADD_EXERCISE_PARTICIPANT,
    DELETE_EXERCISE,
    GET_EXERCISE_BY_ID,
    REMOVE_EXERCISE_FACILITATOR,
    REMOVE_EXERCISE_PARTICIPANT,
    UPDATE_EXERCISE,
    ADD_EXERCISE_CHECKLIST,
    REMOVE_EXERCISE_CHECKLIST,
    ADD_EXERCISE_TEAM,
    REMOVE_EXERCISE_TEAM,
    ADD_EXERCISE_INCLUDED_CONCEPT,
    REMOVE_EXERCISE_INCLUDED_CONCEPT,
} from "./graphql"
import {
    AddExerciseIncludedConceptMutation,
    AddExerciseIncludedConceptMutationVariables,
    RemoveExerciseIncludedConceptMutationVariables,
    AddTeamMutation,
    AddTeamMutationVariables,
    RemoveTeamMutation,
    RemoveTeamMutationVariables,
    AddFacilitatorMutation,
    AddFacilitatorMutationVariables,
    AddParticipantMutation,
    AddParticipantMutationVariables,
    DeleteExerciseMutation,
    DeleteExerciseMutationVariables,
    ExerciseFragment,
    GetExerciseByIdQuery,
    GetExerciseByIdQueryVariables,
    RemoveFacilitatorMutation,
    RemoveFacilitatorMutationVariables,
    RemoveParticipantMutation,
    RemoveParticipantMutationVariables,
    UpdateExerciseMutation,
    UpdateExerciseMutationVariables,
    AddChecklistToExerciseMutation,
    AddChecklistToExerciseMutationVariables,
    RemoveChecklistToExerciseMutation,
    RemoveChecklistToExerciseMutationVariables,
} from "./__generated__/graphql"
import Paper from "@material-ui/core/Paper"
import ExerciseSetupListPicker, {
    ExerciseList,
} from "./ExerciseSetupListPicker"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import GroupAddIcon from "@material-ui/icons/GroupAdd"
import ExerciseSetupUserPicker from "./ExerciseSetupUserPicker"
import ExerciseSetupTeamPicker from "./ExerciseSetupTeamPicker"
import { Loading } from "../Loading"
import {
    Checklist,
    ChecklistItem,
    Concept,
    ExerciseCategory,
} from "../../__generated__/types"
import { ExerciseFieldSettings } from "../../util/SystemSettings"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { parseEnum } from "../../util/EnumValues"
import ExerciseSetupCriteriaManager from "./ExerciseSetupCriteriaManager"
const useStyles = makeStyles((theme: Theme) => ({
    popper: {
        zIndex: 1500,
    },
    list: {
        overflowY: "auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: 0,
        paddingLeft: theme.spacing(1),
        flexGrow: 1,
    },
    conceptList: {
        overflowY: "auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: 0,
        flexGrow: 1,
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    label: {
        color: theme.palette.text.hint,
        fontSize: 12,
    },
    sidebarPaper: {
        flexBasis: "25%",
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        flexShrink: 0,
        minWidth: "250px",
        maxWidth: "100%",
        maxHeight: `calc(100% - ${theme.spacing(1) * 2}px)`,
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            flexGrow: 1,
        },
    },
    instructionPaper: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}))

type Props = {
    exerciseId: string
    onClose: () => void
}

export default function ExerciseSetup(props: Props) {
    const classes = useStyles()
    const { data, loading } = useQuery<
        GetExerciseByIdQuery,
        GetExerciseByIdQueryVariables
    >(GET_EXERCISE_BY_ID, {
        variables: {
            exerciseId: props.exerciseId,
        },
    })
    const exercise = data?.Exercise?.[0]
    const [addParticipant] = useMutation<
        AddParticipantMutation,
        AddParticipantMutationVariables
    >(ADD_EXERCISE_PARTICIPANT)
    const [removeParticipant] = useMutation<
        RemoveParticipantMutation,
        RemoveParticipantMutationVariables
    >(REMOVE_EXERCISE_PARTICIPANT)
    const [addFacilitator] = useMutation<
        AddFacilitatorMutation,
        AddFacilitatorMutationVariables
    >(ADD_EXERCISE_FACILITATOR)
    const [removeFacilitator] = useMutation<
        RemoveFacilitatorMutation,
        RemoveFacilitatorMutationVariables
    >(REMOVE_EXERCISE_FACILITATOR)
    const [addTeam] = useMutation<AddTeamMutation, AddTeamMutationVariables>(
        ADD_EXERCISE_TEAM
    )
    const [removeTeam] = useMutation<
        RemoveTeamMutation,
        RemoveTeamMutationVariables
    >(REMOVE_EXERCISE_TEAM)
    const [addConcept] = useMutation<
        AddExerciseIncludedConceptMutation,
        AddExerciseIncludedConceptMutationVariables
    >(ADD_EXERCISE_INCLUDED_CONCEPT)
    const [
        removeConcept,
    ] = useMutation<RemoveExerciseIncludedConceptMutationVariables>(
        REMOVE_EXERCISE_INCLUDED_CONCEPT
    )

    const [updateExercise] = useMutation<
        UpdateExerciseMutation,
        UpdateExerciseMutationVariables
    >(UPDATE_EXERCISE)

    const [deleteExercise] = useMutation<
        DeleteExerciseMutation,
        DeleteExerciseMutationVariables
    >(DELETE_EXERCISE, {
        update: (cache, { data }) => {
            cache.evict({
                id: cache.identify(data.DeleteExercise),
            })
        },
    })

    const [edited, setEdited] = useState(false)

    const [values, setValues] = useState<
        Pick<ExerciseFragment, "title" | "description" | "duration">
    >({
        title: exercise?.title,
        description: exercise?.description ?? "",
        duration: exercise?.duration ?? 0,
    })

    React.useEffect(() => {
        let data = {
            title: exercise?.title ?? "",
            description: exercise?.description ?? "",
            duration: exercise?.duration ?? 0,
        }
        setValues(data)
    }, [exercise])

    React.useEffect(() => {
        if (exercise) {
            setEdited(false)
            for (const field in values) {
                if (!!values[field] && values[field] !== exercise[field]) {
                    setEdited(true)
                }
            }
        }
    }, [exercise, values])

    const [isOpenParticipants, setOpenParticipants] = useState(false)
    const [isOpenFacilitators, setOpenFacilitators] = useState(false)
    const [isOpenTeams, setOpenTeams] = useState(false)
    const [isOpenLists, setOpenLists] = useState(false)

    const { t } = useAwaitTranslation("exercises")
    const [sourceValue, setSourceValue] = React.useState("individual")

    const handleDeletion = async () => {
        await deleteExercise({
            variables: {
                id: exercise.id,
            },
        })
        props.onClose()
    }

    const handleSave = async () => {
        const data = {
            ...values,
            id: exercise.id,
        }
        await updateExercise({
            variables: {
                ...data,
            },
        })
        setEdited(false)
    }
    const [
        selectedChecklist,
        setSelectedChecklist,
    ] = useState<ExerciseList | null>(null)

    const [addChecklist] = useMutation<
        AddChecklistToExerciseMutation,
        AddChecklistToExerciseMutationVariables
    >(ADD_EXERCISE_CHECKLIST)
    const [removeChecklist] = useMutation<
        RemoveChecklistToExerciseMutation,
        RemoveChecklistToExerciseMutationVariables
    >(REMOVE_EXERCISE_CHECKLIST)

    useEffect(() => {
        const selectedChecklist = exercise?.includedChecklist
        if (selectedChecklist) {
            setSourceValue("list")
            setSelectedChecklist({
                id: selectedChecklist.id,
                title: selectedChecklist.title,
            })
        }
    }, [exercise?.includedChecklist])

    const { data: checklistData, loading: loadingChecklist } = useQuery<
        ChecklistByIdQuery,
        ChecklistByIdQueryVariables
    >(CHECKLIST_BY_ID, {
        variables: {
            id: selectedChecklist?.id,
        },
        skip: !Boolean(selectedChecklist?.id),
    })
    const handleListChange = async (list: ExerciseList) => {
        if (!!list) {
            const checklistIncludedId = exercise.includedChecklist?.id
            if (checklistIncludedId && checklistIncludedId !== list.id) {
                await removeChecklist({
                    variables: {
                        exerciseId: exercise.id,
                        checklistId: exercise.includedChecklist?.id,
                    },
                })
            }
            await addChecklist({
                variables: {
                    exerciseId: exercise.id,
                    checklistId: list.id,
                },
            })
            if (exercise.includedConcepts?.length > 0) {
                await Promise.all([
                    exercise.includedConcepts.map((concept) => {
                        removeConcept({
                            variables: {
                                exerciseId: exercise.id,
                                conceptId: concept.id,
                            },
                        })
                    }),
                ])
            }
        }
        setOpenLists(false)
    }
    const handleListRemoval = async () => {
        setSelectedChecklist(null)
        await removeChecklist({
            variables: {
                exerciseId: exercise.id,
                checklistId: exercise.includedChecklist?.id,
            },
        })
    }

    const handleUserRemoval = async (
        type: "participant" | "facilitator" | "team",
        uId: string
    ) => {
        if (type === "participant") {
            await removeParticipant({
                variables: {
                    fromId: exercise?.id,
                    toId: uId,
                },
            })
        }

        if (type === "facilitator") {
            await removeFacilitator({
                variables: {
                    fromId: exercise?.id,
                    toId: uId,
                },
            })
        }

        if (type === "team") {
            await removeTeam({
                variables: {
                    fromId: exercise?.id,
                    toId: uId,
                },
            })
        }
    }

    const facilitators = exercise?.facilitators ?? []
    const participants = exercise?.participants ?? []
    const teams = exercise?.invitedTeams ?? []

    const handleAdd = (type: "participant" | "facilitator" | "team") => async (
        items: Array<string>
    ) => {
        if (type === "facilitator") {
            const existingFacilitatorsId = facilitators.map((f) => f.userId)

            const toAddFacilitatorsIds = items.filter(
                (uId) => !existingFacilitatorsId.includes(uId)
            )
            const toRemoveFacilitatorsIds = existingFacilitatorsId.filter(
                (uId) => !items.includes(uId)
            )

            await Promise.all([
                ...toAddFacilitatorsIds.map((userId) =>
                    addFacilitator({
                        variables: {
                            fromId: exercise?.id,
                            toId: userId,
                        },
                    })
                ),
                ...toRemoveFacilitatorsIds.map((userId) =>
                    removeFacilitator({
                        variables: {
                            fromId: exercise?.id,
                            toId: userId,
                        },
                    })
                ),
            ])

            setOpenFacilitators(false)
        }

        if (type === "participant") {
            const existingParticipantsId = participants.map((f) => f.userId)

            const toAddParticipantsIds = items.filter(
                (uId) => !existingParticipantsId.includes(uId)
            )
            const toRemoveParticipantsIds = existingParticipantsId.filter(
                (uId) => !items.includes(uId)
            )

            await Promise.all([
                ...toAddParticipantsIds.map((userId) =>
                    addParticipant({
                        variables: {
                            fromId: exercise?.id,
                            toId: userId,
                        },
                    })
                ),
                ...toRemoveParticipantsIds.map((userId) =>
                    removeParticipant({
                        variables: {
                            fromId: exercise?.id,
                            toId: userId,
                        },
                    })
                ),
            ])

            setOpenParticipants(false)
        }
        if (type === "team") {
            const existingTeamsIds = teams.map((f) => f.teamId)

            const toAddTeamsIds = items.filter(
                (uId) => !existingTeamsIds.includes(uId)
            )
            const toRemoveTeamsIds = existingTeamsIds.filter(
                (uId) => !items.includes(uId)
            )

            await Promise.all([
                ...toAddTeamsIds.map((teamId) =>
                    addTeam({
                        variables: {
                            fromId: exercise?.id,
                            toId: teamId,
                        },
                    })
                ),
                ...toRemoveTeamsIds.map((teamId) =>
                    removeTeam({
                        variables: {
                            fromId: exercise?.id,
                            toId: teamId,
                        },
                    })
                ),
            ])

            setOpenTeams(false)
        }
    }

    const handleConceptAddition = async (concept: Concept) => {
        addConcept({
            variables: {
                exerciseId: exercise.id,
                conceptId: concept.id,
            },
        })
        if (!!selectedChecklist) {
            setSelectedChecklist(null)
            removeChecklist({
                variables: {
                    exerciseId: exercise.id,
                    checklistId: selectedChecklist?.id,
                },
            })
        }
    }
    const handleConceptRemoval = (concept: Concept) => {
        removeConcept({
            variables: {
                exerciseId: exercise.id,
                conceptId: concept.id,
            },
        })
    }

    const toggleIsPublic = async (e) => {
        const data = {
            isPublic: e.target.checked,
            id: exercise.id,
        }
        updateExercise({
            variables: {
                ...data,
            },
        })
    }

    const theme = useTheme()
    const checklist: Checklist | null = checklistData?.Checklist[0] ?? null
    return (
        <Dialog
            open={true}
            onClose={props.onClose}
            fullScreen
            PaperProps={{
                style: { backgroundColor: theme.palette.background.default },
            }}
        >
            <DialogContent className={classes.dialogContent}>
                <Box mb={1}>
                    <Typography align="center" variant="h6">
                        {parseEnum(exercise?.type?.category ?? "")} Exercise
                        Configuration
                        {/* {t("exerciseConfiguration", "Exercise Configuration")} */}
                    </Typography>
                </Box>
                <Tooltip
                    title={
                        exercise?.title.length === 0
                            ? t("addTitle", "Your exercise must have a title")
                            : ""
                    }
                >
                    <IconButton
                        style={{ position: "absolute", right: 0, top: 0 }}
                        disabled={exercise?.title.length === 0}
                        onClick={() => {
                            props.onClose()
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {loading ? (
                    <Loading size={150} />
                ) : (
                    !!exercise && (
                        <>
                            <Box
                                display="flex"
                                height="100%"
                                overflow="auto"
                                padding={1}
                                flexWrap="wrap"
                            >
                                <Paper className={classes.sidebarPaper}>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box p={0.5}>
                                            <Typography variant="h6">
                                                {t(
                                                    "enterExerciseDetails",
                                                    "Enter Exercise Details"
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />

                                    <Box p={2}>
                                        <TextField
                                            fullWidth
                                            label={t("title", "Title")}
                                            inputProps={{
                                                maxLength:
                                                    ExerciseFieldSettings[
                                                        "title"
                                                    ].maxLength,
                                            }}
                                            size="small"
                                            required
                                            variant="outlined"
                                            value={values.title}
                                            onChange={(e) => {
                                                setValues({
                                                    ...values,
                                                    title: e.target.value,
                                                })
                                            }}
                                        />
                                    </Box>
                                    <Box p={2}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            size="small"
                                            label={t(
                                                "description",
                                                "Description"
                                            )}
                                            variant="outlined"
                                            inputProps={{
                                                maxLength:
                                                    ExerciseFieldSettings[
                                                        "description"
                                                    ].maxLength,
                                            }}
                                            value={values.description}
                                            onChange={(e) => {
                                                setValues({
                                                    ...values,
                                                    description: e.target.value,
                                                })
                                            }}
                                        />
                                    </Box>
                                    <Box p={2}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            variant="outlined"
                                            label={t(
                                                "timeLimit",
                                                "Time Limit ( minutes )"
                                            )}
                                            size="small"
                                            value={values.duration}
                                            onChange={(e) => {
                                                setValues({
                                                    ...values,
                                                    duration: Number(
                                                        e.target.value
                                                    ),
                                                })
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        mr={2}
                                    >
                                        {edited && (
                                            <Button
                                                variant="outlined"
                                                onClick={handleSave}
                                                color="primary"
                                            >
                                                {t(
                                                    "saveChanges",
                                                    "Save Changes"
                                                )}
                                            </Button>
                                        )}
                                    </Box>
                                    <Box
                                        p={2}
                                        width="100%"
                                        display="flex"
                                        flexDirection="column"
                                        overflow="hidden"
                                        height="100%"
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                            >
                                                {t(
                                                    "facilitators",
                                                    "Facilitators"
                                                )}
                                            </Typography>
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    setOpenFacilitators(true)
                                                }
                                            >
                                                <PersonAddIcon />
                                            </IconButton>
                                        </Box>
                                        {/* <Divider /> */}

                                        <List className={classes.list}>
                                            {facilitators.length < 1 && (
                                                <Tooltip title="Add Facilitators">
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        onClick={() =>
                                                            setOpenFacilitators(
                                                                true
                                                            )
                                                        }
                                                        style={{
                                                            padding: "6px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {t(
                                                            "noFacilitators",
                                                            "No facilitators selected..."
                                                        )}
                                                    </Typography>
                                                </Tooltip>
                                            )}
                                            {facilitators.map((item) => {
                                                return (
                                                    <Box key={item.userId}>
                                                        <ListItem
                                                            disableGutters
                                                        >
                                                            <ListItemAvatar>
                                                                <Avatar
                                                                    src={
                                                                        item.imageUrl
                                                                    }
                                                                />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={
                                                                    item.lastName +
                                                                    ", " +
                                                                    item.firstName
                                                                }
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() => {
                                                                        handleUserRemoval(
                                                                            "facilitator",
                                                                            item.userId
                                                                        )
                                                                    }}
                                                                    size="small"
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </Box>
                                                )
                                            })}
                                        </List>

                                        {isOpenFacilitators && (
                                            <ExerciseSetupUserPicker
                                                exercise={exercise}
                                                onCancel={() =>
                                                    setOpenFacilitators(false)
                                                }
                                                onDone={handleAdd(
                                                    "facilitator"
                                                )}
                                                selectedUsersIds={facilitators.map(
                                                    (f) => f.userId
                                                )}
                                            />
                                        )}
                                    </Box>
                                </Paper>
                                <Paper
                                    className={classes.sidebarPaper}
                                    style={{ flexGrow: 1 }}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="flex-end"
                                    >
                                        <Box p={0.5}>
                                            <Typography variant="h6">
                                                {t(
                                                    "selectExerciseContent",
                                                    "Select Exercise Content"
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box mt={1} mb={1} p={2}>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                        >
                                            <InputLabel>
                                                Selection Method
                                            </InputLabel>
                                            <Select
                                                value={sourceValue}
                                                onChange={(e) =>
                                                    setSourceValue(
                                                        e.target.value as string
                                                    )
                                                }
                                                label="Selection Method"
                                            >
                                                <MenuItem value="individual">
                                                    Add Individual
                                                </MenuItem>
                                                <MenuItem value={"list"}>
                                                    Select Existing List
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Divider />
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        height="100%"
                                        overflow="hidden"
                                        p={1}
                                    >
                                        <Box
                                            height={
                                                exercise?.type?.category ===
                                                ExerciseCategory.SCORING
                                                    ? "50%"
                                                    : "100%"
                                            }
                                            overflow="hidden"
                                            display="flex"
                                            flexDirection="column"
                                            p={1}
                                        >
                                            {sourceValue === "individual" ? (
                                                <>
                                                    <Box pt={2} pb={1.25}>
                                                        <LinkConcept
                                                            linkFunction={(
                                                                item
                                                            ) => {
                                                                handleConceptAddition(
                                                                    item
                                                                )
                                                            }}
                                                            variant="outlined"
                                                            inputAdornment={
                                                                <AddCircleIcon fontSize="small" />
                                                            }
                                                            placeholder={
                                                                "Type to search concepts..."
                                                            }
                                                        />
                                                    </Box>
                                                    <Box
                                                        className={
                                                            classes.conceptList
                                                        }
                                                    >
                                                        {exercise?.includedConcepts?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <Box
                                                                        key={
                                                                            index
                                                                        }
                                                                        position="relative"
                                                                    >
                                                                        <Box
                                                                            flexGrow={
                                                                                "1"
                                                                            }
                                                                        >
                                                                            <ConceptListItem
                                                                                item={
                                                                                    item
                                                                                }
                                                                                showSummary={
                                                                                    true
                                                                                }
                                                                                disableClick
                                                                            />
                                                                        </Box>
                                                                        <Box
                                                                            position="absolute"
                                                                            right="5px"
                                                                            top="5px"
                                                                        >
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    handleConceptRemoval(
                                                                                        item
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Box>
                                                                )
                                                            }
                                                        )}
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    {!checklist ? (
                                                        <>
                                                            <Box
                                                                mt={2}
                                                                display="flex"
                                                                justifyContent="flex-end"
                                                            >
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() =>
                                                                        setOpenLists(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <PlaylistAddIcon />
                                                                </IconButton>
                                                            </Box>
                                                            <Box
                                                                className={
                                                                    classes.conceptList
                                                                }
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    onClick={() =>
                                                                        setOpenLists(
                                                                            true
                                                                        )
                                                                    }
                                                                    style={{
                                                                        padding:
                                                                            "6px",
                                                                        cursor:
                                                                            "pointer",
                                                                        marginLeft: theme.spacing(
                                                                            1
                                                                        ),
                                                                    }}
                                                                >
                                                                    {t(
                                                                        "noListSelected",
                                                                        "No list selected..."
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Box
                                                                mt={1.5}
                                                                mb={1.5}
                                                            >
                                                                <Box
                                                                    p={0.5}
                                                                    display="flex"
                                                                    alignItems="center"
                                                                >
                                                                    <Box flexGrow="1">
                                                                        <Box
                                                                            className={
                                                                                classes.label
                                                                            }
                                                                        >
                                                                            {t(
                                                                                "title",
                                                                                "Title"
                                                                            )}
                                                                        </Box>
                                                                        <Typography variant="body1">
                                                                            {
                                                                                checklist?.title
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={
                                                                            handleListRemoval
                                                                        }
                                                                    >
                                                                        <ClearIcon />
                                                                    </IconButton>
                                                                </Box>
                                                                {checklist
                                                                    ?.description
                                                                    ?.length >
                                                                    0 && (
                                                                    <Box
                                                                        p={0.5}
                                                                    >
                                                                        <Box
                                                                            className={
                                                                                classes.label
                                                                            }
                                                                        >
                                                                            {t(
                                                                                "description",
                                                                                "Description"
                                                                            )}
                                                                        </Box>
                                                                        <Typography variant="body1">
                                                                            {checklist?.description ??
                                                                                "No"}
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            <List
                                                                disablePadding
                                                                className={
                                                                    classes.conceptList
                                                                }
                                                            >
                                                                {checklist &&
                                                                    checklist.items?.map(
                                                                        (
                                                                            item: ChecklistItem
                                                                        ) => {
                                                                            if (
                                                                                item.concept
                                                                            ) {
                                                                                return (
                                                                                    <ConceptListItem
                                                                                        item={
                                                                                            item.concept
                                                                                        }
                                                                                        showSummary={
                                                                                            true
                                                                                        }
                                                                                        disableClick
                                                                                        key={
                                                                                            item
                                                                                                .concept
                                                                                                .id
                                                                                        }
                                                                                    />
                                                                                )
                                                                            } else {
                                                                                return null
                                                                            }
                                                                        }
                                                                    )}
                                                            </List>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Box>
                                        {exercise?.type?.category ===
                                            ExerciseCategory.SCORING && (
                                            <Box height="50%">
                                                <ExerciseSetupCriteriaManager
                                                    exerciseId={exercise?.id}
                                                    criteria={
                                                        exercise?.criteria
                                                    }
                                                />
                                            </Box>
                                        )}
                                    </Box>

                                    {isOpenLists && (
                                        <ExerciseSetupListPicker
                                            checklist={selectedChecklist}
                                            onCancel={() => {
                                                setOpenLists(false)
                                            }}
                                            onDone={handleListChange}
                                        />
                                    )}
                                </Paper>
                                <Paper className={classes.sidebarPaper}>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box p={0.5}>
                                            <Typography variant="h6">
                                                {t(
                                                    "inviteParticipants",
                                                    "Invite Participants"
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box
                                        p={2}
                                        overflow="hidden"
                                        display="flex"
                                        flexDirection="column"
                                        height="100%"
                                    >
                                        {/* INVITE PUBLIC */}
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            pb={1.5}
                                        >
                                            <FormControlLabel
                                                label="Open to Public?"
                                                labelPlacement="start"
                                                color="textSecondary"
                                                style={{
                                                    width: "100%",
                                                    justifyContent:
                                                        "space-between",
                                                    color:
                                                        theme.palette.text
                                                            .secondary,
                                                    marginLeft: 0,
                                                }}
                                                control={
                                                    <Switch
                                                        checked={
                                                            exercise.isPublic
                                                        }
                                                        onChange={
                                                            toggleIsPublic
                                                        }
                                                        name="checkedA"
                                                        inputProps={{
                                                            "aria-label":
                                                                "secondary checkbox",
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        {/* INVITE TEAMS */}
                                        {/* TODO: THIS IS COPY OF INDIVIDUALS, NEED TO ADD LOGIC FOR TEAMS */}
                                        <Box
                                            width="100%"
                                            display="flex"
                                            flexDirection="column"
                                            overflow="hidden"
                                            py={2}
                                            height="100%"
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                >
                                                    {t(
                                                        "inviteTeams",
                                                        "Invite Teams"
                                                    )}
                                                </Typography>
                                                <Tooltip title="Add Teams">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setOpenTeams(true)
                                                        }
                                                    >
                                                        <GroupAddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>

                                            <List className={classes.list}>
                                                {teams.length < 1 && (
                                                    <Tooltip title="Add Teams">
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            onClick={() =>
                                                                setOpenTeams(
                                                                    true
                                                                )
                                                            }
                                                            style={{
                                                                padding: "6px",
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                        >
                                                            {t(
                                                                "noTeamsSelected",
                                                                "No teams selected"
                                                            )}
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                                {teams.map((item) => {
                                                    return (
                                                        <Box key={item.teamId}>
                                                            <ListItem
                                                                disableGutters
                                                            >
                                                                <ListItemAvatar>
                                                                    <Avatar
                                                                        src={
                                                                            item.imageUrl
                                                                        }
                                                                    />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={
                                                                        item.name
                                                                    }
                                                                />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton
                                                                        edge="end"
                                                                        onClick={() => {
                                                                            handleUserRemoval(
                                                                                "team",
                                                                                item.teamId
                                                                            )
                                                                        }}
                                                                        size="small"
                                                                    >
                                                                        <ClearIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </Box>
                                                    )
                                                })}
                                            </List>
                                            {isOpenTeams && (
                                                <ExerciseSetupTeamPicker
                                                    exercise={exercise}
                                                    onCancel={() =>
                                                        setOpenTeams(false)
                                                    }
                                                    onDone={handleAdd("team")}
                                                    selectedTeamIds={teams.map(
                                                        (f) => f.teamId
                                                    )}
                                                />
                                            )}
                                        </Box>

                                        {/* INVITE INDIVIDUALS */}
                                        <Box
                                            width="100%"
                                            display="flex"
                                            flexDirection="column"
                                            overflow="hidden"
                                            pt={2}
                                            height="100%"
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Typography
                                                    variant="subtitle1"
                                                    color="textSecondary"
                                                >
                                                    {t(
                                                        "inviteIndividuals",
                                                        "Invite Individuals"
                                                    )}
                                                </Typography>
                                                <Tooltip title="Add Individuals">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setOpenParticipants(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <PersonAddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>

                                            <List className={classes.list}>
                                                {participants.length < 1 && (
                                                    <Tooltip title="Add Individuals">
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                            onClick={() =>
                                                                setOpenParticipants(
                                                                    true
                                                                )
                                                            }
                                                            style={{
                                                                padding: "6px",
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                        >
                                                            {t(
                                                                "noParticipantsSelected",
                                                                "No participants selected ..."
                                                            )}
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                                {participants.map((item) => {
                                                    return (
                                                        <Box key={item.userId}>
                                                            <ListItem
                                                                disableGutters
                                                            >
                                                                <ListItemAvatar>
                                                                    <Avatar
                                                                        src={
                                                                            item.imageUrl
                                                                        }
                                                                    />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={
                                                                        item.lastName +
                                                                        ", " +
                                                                        item.firstName
                                                                    }
                                                                />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton
                                                                        edge="end"
                                                                        onClick={() => {
                                                                            handleUserRemoval(
                                                                                "participant",
                                                                                item.userId
                                                                            )
                                                                        }}
                                                                        size="small"
                                                                    >
                                                                        <ClearIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </Box>
                                                    )
                                                })}
                                            </List>
                                            {isOpenParticipants && (
                                                <ExerciseSetupUserPicker
                                                    exercise={exercise}
                                                    onCancel={() =>
                                                        setOpenParticipants(
                                                            false
                                                        )
                                                    }
                                                    onDone={handleAdd(
                                                        "participant"
                                                    )}
                                                    selectedUsersIds={participants.map(
                                                        (f) => f.userId
                                                    )}
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                        </>
                    )
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Box display="flex" justifyContent="flex-start" width="100%">
                    <Button variant="contained" onClick={handleDeletion}>
                        {t("delete", "Delete")}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
