import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Button, Box, IconButton, Divider } from "@material-ui/core"
import { useMutation } from "@apollo/client"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import PhotoSearch from "./PhotoSearch"
import CloseIcon from "@material-ui/icons/Close"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { UPDATE_CONCEPT } from "../../graphql/mutations"
import { UPLOAD_CONCEPT_IMAGE } from "./graphql"
import {
    UploadConceptImageMutation,
    UploadConceptImageMutationVariables,
} from "./__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import { Loading } from "../Loading"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../../graphql/__generated__/mutations"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import { Concept } from "../../__generated__/types"

const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            minHeight: "70vh",
        },
        padding: theme.spacing(0, 1),
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: theme.shape.borderRadius,
    },
    imageContainer: {
        [theme.breakpoints.up("sm")]: {
            height: "70vh",
        },
        padding: theme.spacing(2, 0),
    },
    loadingFallback: {
        position: "absolute",
        left: "calc(50% - 75px)",
        top: "calc(50% - 75px)",
    },
}))

interface ImageUploadProps {
    item?: Concept
    onClose: () => void
    editPermission: boolean
    onImageUploaded?: (imageUrl: string) => void
}
export default function ImageUploadDialog(props: ImageUploadProps) {
    const classes = useStyles()
    const { currentUser } = useAuth()
    const [renderingImage, setRenderingImage] = useState(true)
    const [loading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)

    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: {
                    id: props.item?.id,
                },
            },
        ],
    })
    const [uploadPhoto] = useMutation<
        UploadConceptImageMutation,
        UploadConceptImageMutationVariables
    >(UPLOAD_CONCEPT_IMAGE)
    useEffect(() => {
        if (props.item?.imageUrl) {
            setImageUrl(props.item?.imageUrl)
        } else if (props.editPermission) {
            setEditing(true)
        }
    }, [props.item?.imageUrl, props.editPermission])
    const { t } = useAwaitTranslation("images")
    const handlePixabayUpload = async (imageUrl, imageId) => {
        setLoading(true)
        const imageExtension = imageUrl.split(".")[
            imageUrl.split(".").length - 1
        ]
        const imageName = `pixabay-${imageId}.${imageExtension}`
        try {
            const response = await fetch(imageUrl)
            const blob = await response.blob()
            await uploadImage(blob, imageName)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
        setEditing(false)
    }

    const handleLocalImageUpload = async (event) => {
        setLoading(true)
        const imageName = props.item?.id + new Date().getTime()
        await uploadImage(event.target.files[0], imageName)
        setLoading(false)
        setEditing(false)
    }
    const uploadImage = async (file, imageName) => {
        try {
            const photoResponse = await uploadPhoto({
                variables: {
                    file: file,
                    imageName: imageName,
                },
            })
            if (photoResponse && photoResponse.data) {
                if (!!props.item) {
                    await updateConcept({
                        variables: {
                            userId: currentUser.userId,
                            concept: {
                                id: props.item?.id,
                                imageUrl:
                                    photoResponse.data.uploadConceptImage
                                        .fileUrl,
                            },
                        },
                    })
                    setRenderingImage(true)
                    setImageUrl(photoResponse.data.uploadConceptImage.fileUrl)
                } else {
                    props.onImageUploaded(
                        photoResponse.data.uploadConceptImage.fileUrl
                    )
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <Dialog
            open={true}
            onBackdropClick={() => {
                props.onClose()
            }}
            fullWidth
            maxWidth="lg"
        >
            <DialogContent
                className={classes.dialogContent}
                style={{ justifyContent: loading ? "center" : "flex-start" }}
            >
                {!editing ? (
                    <Box>
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="flex-end"
                        >
                            {props.editPermission && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setEditing(true)}
                                >
                                    Edit
                                </Button>
                            )}
                            <IconButton onClick={() => props.onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Divider />
                        <Box className={classes.imageContainer}>
                            <img
                                onLoad={() => {
                                    setRenderingImage(false)
                                }}
                                className={classes.image}
                                src={imageUrl}
                                alt={props.item?.title}
                            />
                            {renderingImage && (
                                <Box className={classes.loadingFallback}>
                                    <Loading size={150} hideQuote={true} />
                                </Box>
                            )}
                        </Box>
                    </Box>
                ) : loading ? (
                    <Loading size={150} hideQuote={true} />
                ) : (
                    <>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            p={2}
                        >
                            <Button
                                onClick={() => {
                                    setEditing(false)
                                    setRenderingImage(true)
                                    if (!props.item?.imageUrl) {
                                        props.onClose()
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                component="label"
                            >
                                <input
                                    accept="image/*"
                                    type="file"
                                    hidden
                                    onChange={handleLocalImageUpload}
                                />
                                <span>
                                    {t("uploadNewImage", "Upload New Image")}
                                </span>
                            </Button>
                        </Box>
                        <Divider />
                        <Box
                            p={2}
                            display="flex"
                            overflow="hidden"
                            flexDirection="column"
                        >
                            <PhotoSearch
                                handleImageClick={handlePixabayUpload}
                            />
                        </Box>
                    </>
                )}
            </DialogContent>
        </Dialog>
    )
}
