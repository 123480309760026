import { gql } from "@apollo/client"
import { CRITERIA_FRAGMENT } from "../criteria/graphql"

export const HYPE_CHART = gql`
    query HypeChart($conceptFilter: _ConceptFilter, $conceptId: ID!) {
        secondaryConcepts: Concept(filter: $conceptFilter) {
            id
            title
            imageUrl
            summary
            type
            rootCategory {
                id
                name
            }
            category {
                id
                name
            }
            criteria(filter: { name: "Hype Chart Position" }) {
                ...Criteria
            }
        }
        primaryConcept: Concept(id: $conceptId) {
            id
            title
            imageUrl
            summary
            type
            rootCategory {
                id
                name
            }
            category {
                id
                name
            }
            criteria(filter: { name: "Hype Chart Position" }) {
                ...Criteria
            }
        }
    }
    ${CRITERIA_FRAGMENT}
`
export const PRODUCT_CHART = gql`
    query ProductChart($conceptFilter: _ConceptFilter, $conceptId: ID!) {
        secondaryConcepts: Concept(filter: $conceptFilter) {
            id
            title
            imageUrl
            summary
            type
            rootCategory {
                id
                name
            }
            category {
                id
                name
            }
            criteria(filter: { name: "Product Chart Position" }) {
                ...Criteria
            }
        }
        primaryConcept: Concept(id: $conceptId) {
            id
            title
            imageUrl
            summary
            type
            rootCategory {
                id
                name
            }
            category {
                id
                name
            }
            criteria(filter: { name: "Product Chart Position" }) {
                ...Criteria
            }
        }
    }
    ${CRITERIA_FRAGMENT}
`
