import React, { useState } from "react"
import IconButton from "@material-ui/core/IconButton"
import {
    ListItemText,
    ListItemIcon,
    Popper,
    Paper,
    Fade,
    List,
    Checkbox,
    ListItemSecondaryAction,
    ListItem,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    ListSubheader,
    Box,
    ClickAwayListener,
    Divider,
    Tooltip,
} from "@material-ui/core"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import ImageUploadDialog from "./images/ImageUploadDialog"
import { useGlobalStyles } from "../styles/globalStyles"
import ImageIcon from "@material-ui/icons/Image"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import { Concept, ConceptUpdateInput } from "../__generated__/types"
import NotesIcon from "@material-ui/icons/Notes"
import { CONCEPT_BY_ID } from "../graphql/queries"
import { useMutation } from "@apollo/client"
import { UPDATE_CONCEPT } from "../graphql/mutations"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../graphql/__generated__/mutations"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth } from "../providers/AuthProvider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },
    paper: {
        width: "200px",
        height: "fit-content",
    },
    listItem: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))
interface ConceptOptionsProps {
    item: Concept
    ownerPermission: boolean
    editPermission: boolean
}

export default function ConceptOptions(props: ConceptOptionsProps) {
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: props.item.id },
            },
        ],
    })
    const { currentUser } = useAuth()
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const open = Boolean(anchorEl)
    const { t } = useAwaitTranslation("widgets")

    const handleConceptUpdate = async (data: ConceptUpdateInput) => {
        await updateConcept({
            variables: {
                userId: currentUser.userId,
                concept: {
                    ...data,
                },
            },
        })
    }
    return (
        <>
            <IconButton onClick={handleButtonClick}>
                <ExpandMoreIcon />
            </IconButton>

            <Popper
                open={open}
                anchorEl={anchorEl}
                className={classes.popper}
                placement="bottom-end"
                transition
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box>
                            <ClickAwayListener
                                onClickAway={() => setAnchorEl(null)}
                            >
                                <Paper className={classes.paper} elevation={7}>
                                    <List disablePadding>
                                        <ListSubheader>
                                            Additional Properties
                                        </ListSubheader>
                                        <Divider />
                                        <ImageOption
                                            item={props.item}
                                            onUpdate={handleConceptUpdate}
                                        />
                                        <BodyOption
                                            item={props.item}
                                            onUpdate={handleConceptUpdate}
                                        />
                                        <DatesOption
                                            item={props.item}
                                            onUpdate={handleConceptUpdate}
                                        />
                                    </List>
                                </Paper>
                            </ClickAwayListener>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </>
    )
}

interface OptionProps {
    item: Concept
    onUpdate: (data: ConceptUpdateInput) => void
}

const DatesOption = (props: OptionProps) => {
    const globalClasses = useGlobalStyles()
    const classes = useStyles()
    const today = new Date()
    const [saving, setSaving] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const handleChange = async (action: "add" | "remove") => {
        const start = {
            day: 2, // to avoid overlap
            month: Number(today.getMonth() + 1), //neo 4j requires 1-12, not 0
            year: Number(today.getFullYear()),
        }
        const end = {
            day: 2, // to avoid overlap
            month: Number(today.getMonth() + 2), //neo 4j requires 1-12, not 0
            year: Number(today.getFullYear()),
        }
        setSaving(true)
        await props.onUpdate({
            id: props.item.id,
            startDate: action === "add" ? start : null,
            endDate: action === "add" ? end : null,
        })
        setSaving(false)
    }
    const checked =
        !!props.item.startDate?.formatted && !!props.item.endDate?.formatted
    return (
        <>
            <Tooltip title="Date feature returning soon...">
                <ListItem className={classes.listItem}>
                    <ListItemIcon className={globalClasses.smallMenuIcon}>
                        <CalendarTodayIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            className: globalClasses.smallMenuListItemText,
                        }}
                        primary={"Dates"}
                    />

                    <ListItemSecondaryAction>
                        <Checkbox
                            disabled={true}
                            edge="end"
                            size="small"
                            checked={checked}
                            onChange={(e) => {
                                if (!!e.target.checked) {
                                    handleChange("add")
                                } else {
                                    setConfirmOpen(true)
                                }
                            }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </Tooltip>
            <Dialog open={confirmOpen}>
                <DialogContent>
                    Are you sure you want to delete the dates of this{" "}
                    {props.item.rootCategory?.name}?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)}>No</Button>
                    <Button
                        onClick={() => {
                            setConfirmOpen(false)
                            handleChange("remove")
                        }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const BodyOption = (props: OptionProps) => {
    const classes = useStyles()
    const globalClasses = useGlobalStyles()
    const initialText = JSON.stringify([
        {
            type: "p",
            children: [{ text: `${props.item.title ?? "New"} Body ` }],
        },
    ])
    const [saving, setSaving] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const checked = !!props.item.description
    const handleChange = async (action: "add" | "remove") => {
        setSaving(true)
        await props.onUpdate({
            id: props.item.id,
            description: action === "add" ? initialText : null,
        })
        setSaving(false)
    }

    return (
        <>
            <ListItem className={classes.listItem}>
                <ListItemIcon className={globalClasses.smallMenuIcon}>
                    <NotesIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        className: globalClasses.smallMenuListItemText,
                    }}
                    primary={"Body"}
                />

                <ListItemSecondaryAction>
                    <Checkbox
                        disabled={saving}
                        onChange={(e) => {
                            if (!!e.target.checked) {
                                handleChange("add")
                            } else {
                                setConfirmOpen(true)
                            }
                        }}
                        edge="end"
                        size="small"
                        checked={checked}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <Dialog open={confirmOpen}>
                <DialogContent>
                    Are you sure you want to delete the body of this{" "}
                    {props.item.rootCategory?.name}?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)}>No</Button>
                    <Button
                        onClick={() => {
                            setConfirmOpen(false)
                            handleChange("remove")
                        }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const ImageOption = (props: OptionProps) => {
    const globalClasses = useGlobalStyles()
    const classes = useStyles()
    const [saving, setSaving] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [openImage, setOpenImage] = useState(false)
    const checked = !!props.item.imageUrl
    const handleRemoval = async () => {
        setSaving(true)
        await props.onUpdate({
            id: props.item.id,
            imageUrl: null,
        })
        setSaving(false)
    }

    return (
        <>
            <ListItem className={classes.listItem}>
                <ListItemIcon className={globalClasses.smallMenuIcon}>
                    <ImageIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        className: globalClasses.smallMenuListItemText,
                    }}
                    primary={"Image"}
                />

                <ListItemSecondaryAction>
                    <Checkbox
                        disabled={saving}
                        onChange={(e) => {
                            if (!!e.target.checked) {
                                setOpenImage(true)
                            } else {
                                setConfirmOpen(true)
                            }
                        }}
                        edge="end"
                        size="small"
                        checked={checked}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <Dialog open={confirmOpen}>
                <DialogContent>
                    Are you sure you want to remove the image of this{" "}
                    {props.item.rootCategory?.name}?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)}>No</Button>
                    <Button
                        onClick={() => {
                            setConfirmOpen(false)
                            handleRemoval()
                        }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            {openImage && (
                <ImageUploadDialog
                    item={props.item}
                    onClose={() => setOpenImage(false)}
                    editPermission={true}
                />
            )}
        </>
    )
}
