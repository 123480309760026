import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Chip from "@material-ui/core/Chip"
import Typography from "@material-ui/core/Typography"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { getNewState } from "./util/getNewState"
import { FilterProps } from "./util/types"
import { useQuery } from "@apollo/client"
import { ROOT_CATEGORY_QUERY } from "../categories/graphql"
import { toCapitalizedWords } from "../../util/fns"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        height: "100%",
    },
    listbox: {
        overflow: "auto",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    popper: {
        zIndex: theme.zIndex.modal + 2,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },
    header: {
        fontSize: theme.typography.h6.fontSize,
        padding: theme.spacing(1),
    },
    betweenFiltersText: {
        color: theme.palette.text.hint,
    },
}))

export default function TypeFilter(props: FilterProps) {
    const classes = useStyles()
    const [value, setValue] = useState([])
    const { data, loading } = useQuery(ROOT_CATEGORY_QUERY)
    const { t } = useAwaitTranslation("global")
    useEffect(() => {
        if (props.item) {
            setValue(props.item.value)
        }
    }, [props.item])
    return (
        <Autocomplete
            options={data?.Category?.map((cat) => cat.name) ?? []}
            multiple
            classes={{
                popper: classes.popper,
                listbox: classes.listbox,
            }}
            getOptionLabel={(option) => toCapitalizedWords(option)}
            value={value}
            loadingText={"Loading types..."}
            loading={loading}
            size="small"
            noOptionsText={t("noTypesMatch", "No types that match...")}
            groupBy={(option: any) => option.conceptType}
            onChange={(event: any, newValue: any[], reason: string) => {
                setValue(newValue)
                const newState = getNewState(
                    props.item,
                    newValue,
                    props.filters,
                    null
                )
                if (newState) {
                    props.setFilters(newState)
                }
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={
                            <Typography
                                variant="body2"
                                style={{ whiteSpace: "normal" }}
                            >
                                {toCapitalizedWords(option)}
                            </Typography>
                        }
                        {...getTagProps({ index })}
                        style={{ height: "100%" }}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t("types", "Types ")}
                    placeholder={t("searchTypes", "Search Types")}
                />
            )}
        />
    )
}
