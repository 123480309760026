import React, { useState, useEffect } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useLazyQuery, useMutation } from "@apollo/client"
import CircularProgress from "@material-ui/core/CircularProgress"
import InputAdornment from "@material-ui/core/InputAdornment"
import Chip from "@material-ui/core/Chip"
import {
    ADD_CONCEPT_INDUSTRY,
    REMOVE_CONCEPT_INDUSTRY,
    ALL_INDUSTRY_QUERY,
} from "./graphql"
import {
    IndustryQuery,
    IndustryQueryVariables,
    AddConceptIndustriesMutation,
    AddConceptIndustriesMutationVariables,
    RemoveConceptIndustriesMutation,
    RemoveConceptIndustriesMutationVariables,
} from "./__generated__/graphql"
import { Concept, Industry } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import Tooltip from "@material-ui/core/Tooltip"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        height: "100%",
    },
    listbox: {
        overflow: "auto",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    clickable: {
        borderRadius: theme.shape.borderRadius,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
        padding: theme.spacing(1),
        height: "100%",
        width: "100%",
    },
    locked: {
        padding: theme.spacing(1),
        height: "100%",
        width: "100%",
    },
    input: {
        padding: theme.spacing(1),
    },
}))

interface IndustryListProps {
    concept: Concept
    editPermission: boolean
}

const GetSortedValue = (industries: Industry[]) => {
    return [...industries]?.sort(function (a, b) {
        var textA = a.name.toUpperCase()
        var textB = b.name.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
    })
}

export default function IndustryList(props: IndustryListProps) {
    const classes = useStyles()
    const [loadIndustries, { data, error, loading, called }] = useLazyQuery<
        IndustryQuery,
        IndustryQueryVariables
    >(ALL_INDUSTRY_QUERY)
    const { t } = useAwaitTranslation("industry")
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState<Industry[]>([])
    const [options, setOptions] = useState<Industry[] | null>(null)
    const [addIndustry] = useMutation<
        AddConceptIndustriesMutation,
        AddConceptIndustriesMutationVariables
    >(ADD_CONCEPT_INDUSTRY)
    const [removeIndustry] = useMutation<
        RemoveConceptIndustriesMutation,
        RemoveConceptIndustriesMutationVariables
    >(REMOVE_CONCEPT_INDUSTRY)

    useEffect(() => {
        setValue(GetSortedValue(props.concept.industries))
    }, [props.concept.industries])

    useEffect(() => {
        if (data) {
            setOptions(data.Industry)
        }
    }, [data, setOptions, value])

    if (error) {
        return (
            <Box>
                {t(
                    "thereHasBeenAnError",
                    "There has been an error retrieving industries..."
                )}
            </Box>
        )
    }
    return editing ? (
        <Autocomplete
            options={options ?? []}
            multiple
            fullWidth
            className={classes.input}
            onFocus={() => {
                if (!called) {
                    loadIndustries()
                }
            }}
            getOptionDisabled={() => (value.length >= 5 ? true : false)}
            onBlur={() => setEditing(false)}
            classes={{
                listbox: classes.listbox,
            }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option) =>
                value.some((x) => x.industryId === option.industryId)
            }
            value={value}
            ChipProps={{
                color: "secondary",
                size: "small",
            }}
            size="small"
            openOnFocus
            loading={loading}
            loadingText={t("loadingIndustries", "Loading industries...")}
            noOptionsText={t(
                "noIndustriesMatch",
                "No industries that match..."
            )}
            disableClearable
            onChange={(event: any, newValue: Industry[], reason: string) => {
                setValue(GetSortedValue(newValue))
                if (reason === "select-option") {
                    newValue.forEach((item) => {
                        if (props.concept.industries.indexOf(item) === -1) {
                            addIndustry({
                                variables: {
                                    id: props.concept.id,
                                    industryId: item.industryId,
                                },
                            }).catch((e) => {
                                setValue(
                                    GetSortedValue(props.concept.industries)
                                )
                            })
                        }
                    })
                } else if (reason === "remove-option") {
                    props.concept.industries.forEach((item) => {
                        if (newValue.indexOf(item) === -1) {
                            removeIndustry({
                                variables: {
                                    id: props.concept.id,
                                    industryId: item.industryId,
                                },
                            }).catch((e) => {
                                setValue(
                                    GetSortedValue(props.concept.industries)
                                )
                            })
                        }
                    })
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoFocus
                    multiline
                    variant="standard"
                    label="Industries"
                    placeholder="Search Industries"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: loading && (
                            <>
                                <InputAdornment position="end">
                                    <CircularProgress disableShrink size={25} />
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    ) : (
        <Box
            className={
                props.editPermission ? classes.clickable : classes.locked
            }
            onClick={() => {
                if (props.editPermission) {
                    setEditing(true)
                }
            }}
        >
            <Box className={classes.label}>Industries</Box>
            <Box>
                {value &&
                    value.length > 0 &&
                    value.map((item, idx) => {
                        return (
                            <Tooltip title={item.name} key={item.industryId}>
                                <Chip
                                    key={item.industryId + "-chip"}
                                    color="secondary"
                                    label={
                                        <span
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            {item.name}
                                        </span>
                                    }
                                    style={{
                                        margin: "2px",
                                        cursor: props.editPermission
                                            ? "pointer"
                                            : "default",
                                    }}
                                    size="small"
                                />
                            </Tooltip>
                        )
                    })}
            </Box>
        </Box>
    )
}
