import {
    Avatar,
    Divider,
    IconButton,
    List,
    Typography,
    LinearProgress,
    ListItem,
} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { useState } from "react"
import ConceptListItem from "../ConceptListItem"
import { SizeMe } from "react-sizeme"
import {
    CartesianGrid,
    Scatter,
    ScatterChart,
    Tooltip,
    DotProps,
    XAxis,
    YAxis,
    ZAxis,
    Cell,
} from "recharts"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { useEffect } from "react"
import ClearIcon from "@material-ui/icons/Clear"
import { Concept } from "../../__generated__/types"
const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    flex: {
        height: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
    },
    axisSelector: {
        width: "100%",
        margin: theme.spacing(1),
    },
    categoryLabel: {
        color: theme.palette.text.hint,
        fontSize: theme.typography.body2.fontSize,
    },
}))

type BubbleChartDataObjectValue = {
    criteriaName: string
    value: number
}

type BubbleChartDataObject = {
    item: Concept
    values: BubbleChartDataObjectValue[]
}
interface BubbleChartProps {
    axisOptions: string[]
    data: BubbleChartDataObject[]
}

export default function BubbleChart(props: BubbleChartProps) {
    const theme = useTheme()
    const classes = useStyles()
    const [focusedPoint, setFocusedPoint] = useState(null)
    const { t } = useAwaitTranslation("trends")
    const [x, setX] = useState(null)
    const [y, setY] = useState(null)
    const [z, setZ] = useState(null)
    const data =
        props.data?.map((x) => {
            let object = {
                ...x.item,
            }

            x.values.map((val) => {
                object[val.criteriaName] = val.value
                return object
            })
            return object
        }) ?? []
    useEffect(() => {
        if (props.axisOptions.length > 0) {
            setX(props.axisOptions[0])
            setY(props.axisOptions[1] ?? props.axisOptions[0])
            setZ(
                props.axisOptions[2] ??
                    props.axisOptions[1] ??
                    props.axisOptions[0]
            )
        }
    }, [props.axisOptions])
    return (
        <Box display="flex" height="100%">
            <div
                className={classes.container}
                style={{ width: !!focusedPoint ? "70%" : "100%" }}
            >
                <SizeMe monitorHeight monitorWidth>
                    {({ size }) => (
                        <div
                            style={{
                                height: "100%",
                                width: "100%",
                                flexGrow: 1,
                            }}
                        >
                            <ScatterChart
                                width={size?.width ?? 200}
                                height={size?.height ?? 200}
                                margin={{
                                    left: 0,
                                    right: 25,
                                    top: 25,
                                    bottom: 10,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip
                                    content={<CustomTooltip theme={theme} />}
                                />
                                <YAxis
                                    type="number"
                                    dataKey={y}
                                    name={y}
                                    domain={[0, 100]}
                                />
                                <XAxis
                                    type="number"
                                    dataKey={x}
                                    name={x}
                                    domain={[0, 100]}
                                />
                                <ZAxis dataKey={z} range={[25, 200]} name={z} />
                                <Scatter
                                    style={{ cursor: "pointer" }}
                                    data={data}
                                    onClick={(item: DotProps) => {
                                        setFocusedPoint(
                                            props.data?.filter(
                                                (i) => i.item?.id === item.id
                                            )?.[0] ?? null
                                        )
                                    }}
                                    fill={theme.palette.primary.main}
                                >
                                    {data.map((entry, index) => {
                                        return (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    entry.id ===
                                                    focusedPoint?.item?.id
                                                        ? theme.palette
                                                              .secondary.main
                                                        : theme.palette.primary
                                                              .main
                                                }
                                            />
                                        )
                                    })}
                                </Scatter>
                            </ScatterChart>
                        </div>
                    )}
                </SizeMe>
                <Box display="flex" padding={1} flexShrink={0}>
                    <Autocomplete<typeof props.axisOptions[0], false, boolean>
                        className={classes.axisSelector}
                        options={props.axisOptions}
                        getOptionLabel={(option) => option}
                        value={x}
                        size="small"
                        disableClearable
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: string
                        ) => {
                            setX(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("bubbleChart.xAxis", "X Axis")}
                                variant="outlined"
                            />
                        )}
                    />
                    <Autocomplete<typeof props.axisOptions[0], false, boolean>
                        className={classes.axisSelector}
                        options={props.axisOptions}
                        getOptionLabel={(option) => option}
                        value={y}
                        size="small"
                        disableClearable
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: string
                        ) => {
                            setY(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("bubbleChart.yAxis", "Y Axis")}
                                variant="outlined"
                            />
                        )}
                    />
                    <Autocomplete<typeof props.axisOptions[0], false, boolean>
                        className={classes.axisSelector}
                        options={props.axisOptions}
                        size="small"
                        getOptionLabel={(option) => option}
                        value={z}
                        disableClearable
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: string
                        ) => {
                            setZ(newValue)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("bubbleChart.size", "Size")}
                                variant="outlined"
                            />
                        )}
                    />
                </Box>
            </div>
            {!!focusedPoint && (
                <>
                    <Divider flexItem orientation="vertical" />
                    <Box width="30%">
                        <FocusedConceptDot
                            point={focusedPoint}
                            onClear={() => setFocusedPoint(null)}
                        />
                    </Box>
                </>
            )}
        </Box>
    )
}

//no typing due to unknown typing of tooltip
const CustomTooltip = (props: any) => {
    const classes = useStyles()
    const { active, payload, theme } = props
    if (active && payload) {
        return (
            <Card style={{ padding: "12px", maxWidth: "350px" }}>
                <Box
                    display="flex"
                    alignItems="center"
                    fontSize={theme.typography.body1.fontSize}
                    padding={0.5}
                >
                    <Avatar
                        variant="rounded"
                        src={payload?.[0]?.payload?.imageUrl}
                        style={{
                            marginRight: "5px",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.text.main,
                        }}
                    >
                        <TrendingUpIcon />
                    </Avatar>
                    <Box ml={1}>
                        <Box className={classes.categoryLabel}>
                            {payload?.[0]?.payload?.category?.name}
                        </Box>
                        <Box>{payload?.[0]?.payload?.title}</Box>
                    </Box>
                </Box>
            </Card>
        )
    } else {
        return null
    }
}
//no typing due to added criteria names on concept object
const FocusedConceptDot = (props) => {
    return (
        <Box>
            <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={() => props.onClear()}>
                    <ClearIcon />
                </IconButton>
            </Box>
            <Divider />
            <ConceptListItem item={props.point.item} showSummary={true} />
            <List style={{ overflowY: "auto" }} dense>
                {props.point?.values
                    ?.sort((a, b) => b.value - a.value)
                    ?.map((item, index) => {
                        return (
                            <ListItem divider key={index}>
                                <Box
                                    key={index}
                                    display="flex"
                                    flexDirection="column"
                                    width="100%"
                                >
                                    <Box>
                                        <Box p={0.5}>
                                            <Typography variant="body2">
                                                {item.criteriaName}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <Box p={0.5} flexGrow={1}>
                                                <LinearProgress
                                                    value={item.value}
                                                    variant="determinate"
                                                />
                                            </Box>
                                            <Box p={0.5}>{item.value}</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </ListItem>
                        )
                    })}
            </List>
        </Box>
    )
}
