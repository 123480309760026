import { gql } from "@apollo/client"

const CREATE_CHECKLIST = gql`
    mutation CreateChecklist(
        $conceptId: ID!
        $userId: ID!
        $checklist: ChecklistInput
    ) {
        CreateChecklist(
            conceptId: $conceptId
            checklist: $checklist
            userId: $userId
        ) {
            id
            title
            type {
                id
                title
            }
            itemPrefixType
        }
    }
`
const CREATE_CONCEPT_CHECKLIST = gql`
    mutation CreateConceptChecklist(
        $conceptId: ID!
        $userId: ID!
        $checklist: ChecklistInput
    ) {
        CreateChecklist(
            conceptId: $conceptId
            checklist: $checklist
            userId: $userId
        ) {
            id
            title
            type {
                id
                title
            }
            itemPrefixType
        }
    }
`
const ADD_CHECKLIST_TYPE = gql`
    mutation AddChecklistType($checklistId: ID!, $typeId: ID!) {
        AddChecklistType(from: { id: $checklistId }, to: { id: $typeId }) {
            from {
                id
                type {
                    id
                }
            }
            to {
                id
            }
        }
    }
`
const CHECKLIST_BY_ID = gql`
    query ChecklistById($id: ID!) {
        Checklist(id: $id) {
            id
            title
            description
            itemIds
            conceptBased
            itemPrefixType
            type {
                id
                title
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            items {
                id
                title
                checked
                createdAt {
                    formatted
                    second
                }
                createdBy {
                    userId
                    username
                    firstName
                    lastName
                    imageUrl
                }
                concept {
                    id
                    __typename
                    title
                    type
                    summary
                    description
                    isPublic
                    imageUrl
                    category {
                        id
                        name
                        isRoot
                    }
                    rootCategory {
                        id
                        name
                        isRoot
                    }
                    createdAt {
                        formatted
                    }
                }
            }
            concept {
                id
                title
                summary
                type
                category {
                    id
                    name
                }
                imageUrl
            }
        }
    }
`
const SEARCH_CONCEPT_CHECKLISTS = gql`
    query SearchConceptChecklists($searchString: String) {
        fuzzyConceptSearch(searchString: $searchString) {
            id
            __typename
            title
            type
            summary
            description
            checklists {
                id
                title
                conceptBased
                type {
                    id
                    conceptBased
                }
                concept {
                    id
                    title
                }
                items {
                    id
                    title
                    concept {
                        id
                        title
                    }
                }
            }
        }
    }
`
const UPDATE_CHECKLIST = gql`
    mutation UpdateChecklist($userId: ID!, $checklist: ChecklistUpdateInput) {
        UpdateChecklist(checklist: $checklist, userId: $userId) {
            id
            title
            itemIds
            itemPrefixType
            type {
                id
                title
            }
        }
    }
`
const DELETE_CHECKLIST = gql`
    mutation DeleteChecklist($id: ID!) {
        DeleteChecklist(id: $id) {
            id
            title
        }
    }
`

const ADD_CHECKLIST_ITEM_CONCEPT = gql`
    mutation AddChecklistItemConcept($fromId: ID!, $toId: ID!) {
        AddChecklistItemConcept(from: { id: $fromId }, to: { id: $toId }) {
            from {
                id
                concept {
                    id
                }
            }
            to {
                id
                checklistItemLink {
                    id
                }
            }
        }
    }
`
const REMOVE_CHECKLIST_ITEM_CONCEPT = gql`
    mutation RemoveChecklistItemConcept($fromId: ID!, $toId: ID!) {
        RemoveChecklistItemConcept(from: { id: $fromId }, to: { id: $toId }) {
            from {
                id
                concept {
                    id
                }
            }
            to {
                id
                checklistItemLink {
                    id
                }
            }
        }
    }
`

const CREATE_CHECKLIST_ITEM = gql`
    mutation CreateChecklistItem(
        $checklistId: ID!
        $userId: ID!
        $checked: Boolean
        $title: String
    ) {
        CreateChecklistItem(
            checklistId: $checklistId
            title: $title
            checked: $checked
            userId: $userId
        ) {
            id
            title
            checked
            checklist {
                id
                conceptBased
                itemIds
                items {
                    id
                    title
                    checked
                    createdAt {
                        formatted
                        second
                    }
                    createdBy {
                        userId
                        username
                        firstName
                        lastName
                        imageUrl
                    }
                    concept {
                        id
                        title
                        imageUrl
                        summary
                        type
                    }
                }
            }
        }
    }
`
const UPDATE_CHECKLIST_ITEM = gql`
    mutation UpdateChecklistItem(
        $userId: ID!
        $checklistItem: ChecklistItemUpdate
    ) {
        UpdateChecklistItem(userId: $userId, checklistItem: $checklistItem) {
            id
            title
            checked
        }
    }
`
const DELETE_CHECKLIST_ITEM = gql`
    mutation DeleteChecklistItem($id: ID!) {
        DeleteChecklistItem(id: $id) {
            id
            title
            checked
        }
    }
`
const CHECKLIST_TYPES = gql`
    query ChecklistTypes($conceptBased: Boolean) {
        ChecklistType(conceptBased: $conceptBased, orderBy: title_asc) {
            id
            title
            description
            checklists {
                id
                title
            }
        }
    }
`
const CHECKLIST_FILTERED = gql`
    query ChecklistFiltered($filter: _ChecklistFilter, $conceptId: ID!) {
        Checklist(filter: $filter, orderBy: title_asc) {
            id
            title
            description
            conceptBased
            itemIds
            type {
                id
                title
                conceptBased
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            itemPrefixType
            items {
                id
                title
                checked
                createdAt {
                    formatted
                    second
                }
                createdBy {
                    userId
                    username
                    firstName
                    lastName
                    imageUrl
                }
                concept {
                    id
                    title
                    type
                    summary
                    description
                    isPublic
                    imageUrl
                    createdAt {
                        formatted
                    }
                    startDate {
                        formatted
                    }
                    endDate {
                        formatted
                    }
                    imageUrl
                    category {
                        id
                        name
                        isRoot
                    }
                    rootCategory {
                        id
                        name
                        isRoot
                    }
                    createdBy {
                        userId
                        firstName
                        lastName
                        imageUrl
                        username
                    }
                    likedBy {
                        userId
                    }
                    connections {
                        id
                    }
                    parent {
                        id
                        title
                    }
                    webLink {
                        id
                        url
                        title
                        icon
                        provider
                    }
                    children {
                        id
                        title
                        type
                        summary
                        description
                        isPublic
                        imageUrl
                        createdAt {
                            formatted
                        }
                        connections {
                            id
                        }
                        startDate {
                            formatted
                        }
                        endDate {
                            formatted
                        }
                        imageUrl
                        category {
                            id
                            name
                        }
                        createdBy {
                            userId
                            firstName
                            lastName
                            imageUrl
                            username
                        }
                        likedBy {
                            userId
                        }
                        parent {
                            id
                            title
                        }
                        webLink {
                            id
                            url
                            title
                            icon
                            provider
                        }
                    }
                }
            }
            concept {
                id
                title
                checklistIds
            }
        }
        Concept(id: $conceptId) {
            id
            checklistIds
        }
    }
`

const CHECKLIST_ITEM = gql`
    query ChecklistItem {
        ChecklistItem {
            id
            title
            checked
            checklist {
                id
                title
            }
        }
    }
`

const UPDATE_CHECKLIST_TYPE = gql`
    mutation UpdateChecklistType(
        $id: ID!
        $title: String
        $description: String
    ) {
        UpdateChecklistType(id: $id, title: $title, description: $description) {
            id
            title
            description
        }
    }
`
const DELETE_CHECKLIST_TYPE = gql`
    mutation DeleteChecklistType($id: ID!) {
        DeleteChecklistType(id: $id) {
            id
        }
    }
`
const CREATE_CHECKLIST_TYPE = gql`
    mutation CreateChecklistType(
        $title: String
        $description: String
        $conceptBased: Boolean
    ) {
        CreateChecklistType(
            title: $title
            description: $description
            conceptBased: $conceptBased
        ) {
            id
            title
            description
            conceptBased
        }
    }
`
const UPDATE_CONCEPT_CHECKLIST_ORDER = gql`
    mutation UpdateConceptChecklistOrder(
        $userId: ID!
        $concept: ConceptUpdateInput
    ) {
        UpdateConcept(userId: $userId, concept: $concept)
    }
`

const ROADMAP_BY_ID = gql`
    query RoadmapById($id: ID!) {
        Checklist(id: $id) {
            id
            title
            description
            itemIds
            conceptBased
            type {
                id
                title
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            items {
                id
                title
                checked
                createdAt {
                    formatted
                    second
                }
                createdBy {
                    userId
                    username
                    firstName
                    lastName
                    imageUrl
                }
                concept {
                    id
                    title
                    type
                    summary
                    description
                    isPublic
                    imageUrl
                    createdAt {
                        formatted
                    }
                    startDate {
                        formatted
                    }
                    endDate {
                        formatted
                    }
                    imageUrl
                    category {
                        id
                        name
                    }
                    createdBy {
                        userId
                        firstName
                        lastName
                        imageUrl
                        username
                    }
                    likedBy {
                        userId
                    }
                    connections {
                        id
                    }
                    parent {
                        id
                        title
                    }
                    webLink {
                        id
                        url
                        title
                        icon
                        provider
                    }
                    children {
                        id
                        title
                        type
                        summary
                        description
                        isPublic
                        imageUrl
                        createdAt {
                            formatted
                        }
                        connections {
                            id
                        }
                        startDate {
                            formatted
                        }
                        endDate {
                            formatted
                        }
                        imageUrl
                        category {
                            id
                            name
                        }
                        createdBy {
                            userId
                            firstName
                            lastName
                            imageUrl
                            username
                        }
                        likedBy {
                            userId
                        }
                        parent {
                            id
                            title
                        }
                        webLink {
                            id
                            url
                            title
                            icon
                            provider
                        }
                    }
                }
            }
            concept {
                id
                title
                summary
                type
                category {
                    id
                    name
                }
                imageUrl
            }
        }
    }
`

export {
    CHECKLIST_ITEM,
    CHECKLIST_FILTERED,
    CREATE_CHECKLIST,
    CREATE_CONCEPT_CHECKLIST,
    CREATE_CHECKLIST_ITEM,
    UPDATE_CHECKLIST_ITEM,
    ADD_CHECKLIST_TYPE,
    UPDATE_CHECKLIST,
    DELETE_CHECKLIST,
    DELETE_CHECKLIST_ITEM,
    CHECKLIST_TYPES,
    CREATE_CHECKLIST_TYPE,
    DELETE_CHECKLIST_TYPE,
    UPDATE_CHECKLIST_TYPE,
    UPDATE_CONCEPT_CHECKLIST_ORDER,
    SEARCH_CONCEPT_CHECKLISTS,
    CHECKLIST_BY_ID,
    ADD_CHECKLIST_ITEM_CONCEPT,
    REMOVE_CHECKLIST_ITEM_CONCEPT,
    ROADMAP_BY_ID,
}
