import { Notification_Type } from "../../../__generated__/types"

export const NotificationTypes = [
    Notification_Type.CONCEPT_LIKED,
    Notification_Type.CONCEPT_UPDATED,
    //Notification_Type.CONCEPT_LINK_ADDED,
    //Notification_Type.CONCEPT_LINK_REMOVED,
    //Notification_Type.CONCEPT_ADDED_TO_WORKSPACE,
    //Notification_Type.CONCEPT_REMOVED_FROM_WORKSPACE,
    Notification_Type.COMMENT_ADDED,
    Notification_Type.COMMENT_REPLY_ADDED,
]
