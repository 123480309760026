import React, { useState } from "react"
import {
    Box,
    ListItem,
    ListItemText,
    Typography,
    IconButton,
    ListItemSecondaryAction,
    Menu,
    MenuItem,
    ListItemIcon,
} from "@material-ui/core"
import { useWidgetTemplates } from "./useWidgetTemplates"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import WidgetConfigModal from "./widgets/common/WidgetConfigModal"
import { Widget } from "../../__generated__/types"
import { WidgetTypeName } from "./useWidgetTypes"
import { useGlobalStyles } from "../../styles/globalStyles"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
interface TemplateOptionProps {
    template: Widget
    onAddNewWidget: () => void
}
export const TemplateOption = (props: TemplateOptionProps) => {
    const parsed = JSON.parse(props.template.config)
    const { onDeleteTemplate } = useWidgetTemplates(props.template?.type.id)
    const globalClasses = useGlobalStyles()
    const [editOpen, setEditOpen] = React.useState(false)
    const [hovered, setHovered] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    return (
        props.template && (
            <Box
                onMouseOver={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <ListItem button onClick={() => props.onAddNewWidget()}>
                    <ListItemText
                        primary={
                            <Typography variant="body2">
                                {parsed.label}
                            </Typography>
                        }
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            size="small"
                            style={{
                                visibility: hovered ? "visible" : "hidden",
                            }}
                            onClick={handleClick}
                        >
                            <MoreVertIcon fontSize="small" />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        component="li"
                        divider
                        onClick={() => {
                            setEditOpen(true)
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary="Edit Template"
                        />
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null)
                            onDeleteTemplate(props.template.id)
                        }}
                        component="li"
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary="Delete Template"
                        />
                    </MenuItem>
                </Menu>
                {editOpen && (
                    <WidgetConfigModal
                        widget={{
                            id: props.template.id,
                            name: props.template.name,
                            type: (props.template.type?.name ??
                                WidgetTypeName.Unknown) as WidgetTypeName,
                        }}
                        open={editOpen}
                        onClose={() => setEditOpen(false)}
                    />
                )}
            </Box>
        )
    )
}

export default TemplateOption
