import { useMutation } from "@apollo/client"
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    makeStyles,
    Toolbar,
    useTheme,
    fade,
} from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import MenuIcon from "@material-ui/icons/Menu"
import PostAddIcon from "@material-ui/icons/PostAdd"
import SearchIcon from "@material-ui/icons/Search"
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAuth } from "../providers/AuthProvider"
import { useLocation } from "react-router-dom"
import HeaderAvatarMenu from "./HeaderAvatarMenu"
import HeaderNotifications from "./notifications/HeaderNotifications"
import LanguagePicker from "./LanguagePicker"
import { UPDATE_USER } from "./users/graphql"
import Logo from "./Logo"
import Search from "./Search"
import { DispatchContext } from "./themes/ThemeContext"

const useStyles = makeStyles((theme) => ({
    appBar: {
        // zIndex: theme.zIndex.SwipeableDrawer + 101,
        background: theme.palette.background.paper,
    },
    toolbar: {
        width: "100vw",
        padding: "0 12px",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        backgroundColor: fade(theme.palette.primary.main, 0.15),
        height: theme.spacing(8),
    },
    settings: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
    },
    logo: {
        display: "flex",
        justifyContent: "flex-start",
    },
    button: {
        margin: 0,
    },
    menuPaper: {
        borderTopRightRadius: "0px",
        borderTopLeftRadius: "0px",
        zIndex: theme.zIndex.appBar - 1,
    },
    popper: {
        right: 15,
        width: "400px",
        top: "15px !important",
        zIndex: theme.zIndex.appBar - 1,
    },

    swipeableSearch: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: theme.spacing(1),
        height: theme.spacing(8),
    },
    menuItemContainer: {
        color: theme.palette.text.primary,
        "&:hover": {
            color: theme.palette.primary.main,
        },
        cursor: "pointer",
    },
    selectedMenuItemContainer: {
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.primary.main,
        },
        cursor: "pointer",
    },
}))

interface AppHeaderProps {
    toggleMenu: () => void
    showFormDrawer: () => void
    toggleWorkspaceDrawer: () => void
}
export default function AppHeader(props: AppHeaderProps) {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { currentUser, logout } = useAuth()
    const history = useHistory()
    const [updateThemeType] = useMutation(UPDATE_USER)
    const dispatch = useContext(DispatchContext)
    const [location, setLocation] = useState(null)
    const [mobileSearch, showMobileSearch] = React.useState(false)

    //TODO: Possibly refactor to const isLanguagePickerVisible = (currentUser?.language ?? null) === null to remove the state & effect
    const [languagePickerVisible, setLanguagePickerVisible] = React.useState(
        false
    )
    useEffect(() => {
        if (currentUser?.language) {
            setLanguagePickerVisible(false) //Don't show if user has chosen language
        } else if (currentUser?.language === null) {
            setLanguagePickerVisible(true) //Show if user language unknown
        }
    }, [currentUser?.language])
    const currentLocation = useLocation()
    useEffect(() => {
        if (currentLocation.pathname) {
            setLocation(currentLocation.pathname)
        }
    }, [currentLocation?.pathname])

    const darkModeToggle = (makeDarkMode) => {
        const flipTheme = (themeType) =>
            themeType === "dark" ? "light" : "dark"
        const type =
            makeDarkMode === undefined
                ? flipTheme(theme.palette.type)
                : makeDarkMode
                ? "dark"
                : "light"
        updateThemeType({
            variables: {
                userId: currentUser.userId,
                activeThemeType: type.toUpperCase(),
            },
        }).then((result) => {
            //@ts-ignore
            dispatch({
                type: "CHANGE",
                payload: { paletteType: type },
            })
        })
    }

    const handleDarkModeToggle = (event) => {
        event.target.value = !!event.target.checked
        darkModeToggle(event.target.checked)
    }
    async function handleLogout() {
        try {
            await logout()
            history.push("/login")
        } catch {
            console.log("Failed to log out")
        }
    }

    if (!!!currentUser) {
        return null
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Box className={classes.logo}>
                    <IconButton
                        style={{ minWidth: "fit-content" }}
                        onClick={props.toggleWorkspaceDrawer}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Hidden smDown>
                        <Logo />
                    </Hidden>
                </Box>

                <Box className={classes.settings}>
                    {mobile ? (
                        <IconButton
                            onClick={() => {
                                showMobileSearch(true)
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    ) : (
                        <Search autoFocus={false} />
                    )}
                    {mobile && mobileSearch && (
                        <SwipeableDrawer
                            anchor={"top"}
                            open={mobileSearch}
                            onClose={() => {
                                showMobileSearch(false)
                            }}
                            onOpen={() => {
                                if (!mobileSearch) {
                                    showMobileSearch(true)
                                }
                            }}
                            classes={{
                                paper: classes.swipeableSearch,
                            }}
                        >
                            <Search
                                autoFocus={true}
                                setOpen={showMobileSearch}
                            />
                        </SwipeableDrawer>
                    )}
                    {languagePickerVisible && (
                        <LanguagePicker mobile={mobile} user={currentUser} />
                    )}

                    <IconButton onClick={props.showFormDrawer}>
                        <PostAddIcon />
                    </IconButton>
                    <HeaderNotifications />
                    <HeaderAvatarMenu
                        theme={theme}
                        handleDarkModeToggle={handleDarkModeToggle}
                        handleLogout={handleLogout}
                        history={history}
                        currentUser={currentUser}
                        languagePickerVisible={languagePickerVisible}
                        setLanguagePickerVisible={setLanguagePickerVisible}
                    />
                </Box>
            </Toolbar>
        </AppBar>
    )
}
