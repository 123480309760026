import { useMutation, useQuery, useReactiveVar } from "@apollo/client"
import { useEffect, useState } from "react"
import GridLayout, { Layout } from "react-grid-layout"
import { Loading } from "../Loading"
import { GET_DASHBOARD_BY_ID, UPDATE_DASHBOARD_LAYOUT } from "./graphql"
import { useWidgets } from "./useWidgets"
import {
    DashboardByIdQuery,
    DashboardByIdQueryVariables,
    UpdateDashboardLayoutMutation,
    UpdateDashboardLayoutMutationVariables,
} from "./__generated__/graphql"
import {
    Box,
    useTheme,
    useMediaQuery,
    Dialog,
    makeStyles,
    DialogActions,
    Tooltip,
    MobileStepper,
    Button,
    Divider,
    DialogContent,
} from "@material-ui/core"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import { expandWidgetVar } from "../../providers/GlobalState"
import { toCapitalizedWords } from "../../util/fns"
import { ICONS_REPOSITORY } from "./useWidgetTypes"
import { CREATE_WIDGET_TYPE } from "./graphql"
type GridLayoutItem = Layout

const useStyles = makeStyles((theme) => ({
    root: {
        height: "75vh",
        minHeight: "75vh",
        overflowX: "hidden",
    },
    stepper: {
        flexGrow: 1,
        background: "none",
    },
    dialogContent: {
        padding: 0,
        "&:first-child": {
            paddingTop: 0,
        },
    },
    mini: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
    },
    ".react-grid-item.react-grid-placeholder": {
        backgroundColor: "black",
    },
}))

type Props = {
    dashboardId: string
    width: number
    editing?: boolean
    scaledDown?: boolean
}

export default function Dashboard(props: Props) {
    const classes = useStyles()
    const expandedWidgetId = useReactiveVar(expandWidgetVar)
    const { data } = useQuery<DashboardByIdQuery, DashboardByIdQueryVariables>(
        GET_DASHBOARD_BY_ID,
        {
            variables: {
                id: props.dashboardId,
            },
        }
    )
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [execute] = useMutation<
        UpdateDashboardLayoutMutation,
        UpdateDashboardLayoutMutationVariables
    >(UPDATE_DASHBOARD_LAYOUT)
    const [createType] = useMutation(CREATE_WIDGET_TYPE)

    const [isInitialized, setIsInitialized] = useState(false)
    const [layout, setLayout] = useState<Array<GridLayoutItem>>(null)
    const dashboard = data?.DashboardView?.[0] ?? null

    useEffect(() => {
        if (dashboard && dashboard.layout && !layout) {
            const parsedLayout = JSON.parse(dashboard.layout)
            if (mobile) {
                let mobileLayout: Array<GridLayoutItem> = []
                parsedLayout.forEach((widget, index) => {
                    let mobileWidgetLayout = { ...widget, w: 12, x: 0 }
                    if (mobileLayout[index - 1]) {
                        mobileWidgetLayout["y"] =
                            mobileLayout[index - 1].y +
                            mobileLayout[index - 1].h +
                            1
                    }
                    mobileLayout.push(mobileWidgetLayout)
                })
                setLayout(mobileLayout)
            } else if (!!props.scaledDown) {
                let scaledDownLayout: Array<GridLayoutItem> = []
                parsedLayout.forEach((widget, index) => {
                    let scaleDownWidgetLayout = {
                        ...widget,
                        h:
                            Math.floor(widget.h * (150 / 800)) !== 0
                                ? Math.floor(widget.h * (150 / 800))
                                : 1,
                    }
                    scaledDownLayout.push(scaleDownWidgetLayout)
                })
                setLayout(scaledDownLayout)
            } else {
                setLayout(parsedLayout)
            }

            setIsInitialized(true)
        }
    }, [dashboard, layout, mobile, props.scaledDown])

    const onLayoutChange = async (layout: Array<GridLayoutItem>) => {
        if (!mobile && !props.scaledDown && props.editing) {
            setLayout(layout)
            await execute({
                variables: {
                    id: props.dashboardId,
                    layout: JSON.stringify(layout),
                },
            })
        }
    }
    const handleTypeCreation = async () => {
        const object = {
            schema: "BubbleChartSchema",
            name: "BubbleChart",
            defaultConfig: JSON.stringify({
                label: "Bubble Chart",
                source: "connections",
                filters: [],
                criteria: [],
            }),
            component: "BubbleChartWidget",
        }
        const data = await createType({
            variables: {
                ...object,
            },
        })
    }
    const {
        widgets,
        widgetTypes,
        isInitialized: isWidgetInitialized,
    } = useWidgets(props.dashboardId)
    const validWidgets = widgets.filter(
        (widget) => !!widgetTypes.find((w) => w.name === widget.type)
    )
    return (
        <Box width="100%">
            {!isInitialized || !isWidgetInitialized ? (
                !props.scaledDown && <Loading size={150} hideQuote={true} />
            ) : (
                <GridLayout
                    layout={layout ?? []}
                    cols={12}
                    key={props.dashboardId}
                    rowHeight={30}
                    onLayoutChange={onLayoutChange}
                    isDraggable={props.scaledDown ? false : props.editing}
                    isResizable={props.scaledDown ? false : props.editing}
                    draggableHandle=".widget-drag-handle"
                    autoSize={true}
                    width={props.width ?? 150}
                >
                    {validWidgets.map((widget, idx) => {
                        const type = widgetTypes.find(
                            (w) => w.name === widget.type
                        )

                        const Component = type.component
                        const defaultLayoutConfig =
                            Component.defaultLayout ?? {}
                        const Icon = ICONS_REPOSITORY[type?.name]
                        return (
                            <Box
                                key={widget.id}
                                data-grid={{
                                    w: 4,
                                    h: 4,
                                    x: 0,
                                    y: 0,
                                    ...defaultLayoutConfig,
                                }}
                                display="flex"
                            >
                                {!!props.scaledDown ? (
                                    <Box width="100%" height="100%">
                                        <Tooltip
                                            title={toCapitalizedWords(
                                                type?.name
                                            )}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                width="100%"
                                                height="100%"
                                                className={classes.mini}
                                            >
                                                {Icon}
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                ) : (
                                    <Component
                                        widget={widget}
                                        editing={props.editing}
                                    />
                                )}

                                <Dialog
                                    fullWidth={true}
                                    maxWidth="lg"
                                    onClose={() => expandWidgetVar("")}
                                    open={expandedWidgetId === widget.id}
                                    PaperProps={{
                                        classes: {
                                            root: classes.root,
                                        },
                                    }}
                                >
                                    <DialogContent
                                        className={classes.dialogContent}
                                    >
                                        <Component
                                            widget={widget}
                                            editing={false}
                                            isDialog={true}
                                        />
                                    </DialogContent>
                                    <Divider />
                                    <DialogActions>
                                        <MobileStepper
                                            variant="dots"
                                            steps={validWidgets.length}
                                            position="static"
                                            activeStep={idx}
                                            className={classes.stepper}
                                            nextButton={
                                                <Button
                                                    size="small"
                                                    onClick={() =>
                                                        expandWidgetVar(
                                                            validWidgets[
                                                                idx + 1
                                                            ]?.id
                                                        )
                                                    }
                                                    disabled={
                                                        idx + 1 ===
                                                        validWidgets.length
                                                    }
                                                >
                                                    Next
                                                    <KeyboardArrowRightIcon />
                                                </Button>
                                            }
                                            backButton={
                                                <Button
                                                    size="small"
                                                    onClick={() =>
                                                        expandWidgetVar(
                                                            validWidgets[
                                                                idx - 1
                                                            ]?.id
                                                        )
                                                    }
                                                    disabled={idx === 0}
                                                >
                                                    <KeyboardArrowLeftIcon />
                                                    Back
                                                </Button>
                                            }
                                        />
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        )
                    })}
                </GridLayout>
            )}
        </Box>
    )
}
