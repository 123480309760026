import React, { useState, useEffect } from "react"
import {
    Box,
    TextField,
    Chip,
    Typography,
    Tooltip,
    Avatar,
} from "@material-ui/core"

import { useQuery } from "@apollo/client"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete"
import { BASE_CONCEPT_FILTERED } from "../../graphql/queries"
import {
    BaseConceptFilteredQuery,
    BaseConceptFilteredQueryVariables,
} from "../../graphql/__generated__/queries"
import { _ConceptOrdering } from "../../__generated__/types"
import { getConceptTypeIcon } from "../../util/ConceptTypeStyles"
import { FilterProps } from "./util/types"
import { getNewState } from "./util/getNewState"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            overflow: "auto",
            padding: theme.spacing(2),
        },

        label: {
            fontSize: 12,
            color: theme.palette.text.secondary,
            paddingBottom: theme.spacing(0.5),
        },
        icon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
        chipIcon: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
            width: "0.8rem",
            height: "0.8rem",
        },
        avatar: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
        },
        listbox: {
            overflow: "auto",
        },

        popper: {
            zIndex: theme.zIndex.modal + 2,
        },
    })
)

const filterOptions = createFilterOptions({
    limit: 15,
})
export default function ConceptFilter(props: FilterProps) {
    const classes = useStyles()
    let queryVariables = {
        orderBy: ["title_asc"] as [_ConceptOrdering],
    }
    if (!!props.item.optionsFilter) {
        queryVariables["filter"] = { ...props.item.optionsFilter }
    }
    const { data, loading } = useQuery<
        BaseConceptFilteredQuery,
        BaseConceptFilteredQueryVariables
    >(BASE_CONCEPT_FILTERED, {
        variables: {
            ...queryVariables,
        },
    })
    const [value, setValue] = useState([])
    useEffect(() => {
        if (data && props.item) {
            setValue(
                data.Concept.filter(
                    (x) => props.item.value.indexOf(x.id) !== -1
                )
            )
        }
    }, [data, props.item])

    return (
        <Autocomplete
            options={data?.Concept ?? []}
            value={value}
            multiple
            size="small"
            filterOptions={filterOptions}
            classes={{
                popper: classes.popper,
                listbox: classes.listbox,
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(_: React.ChangeEvent<{}>, newValue) => {
                if (typeof newValue !== "string") {
                    setValue(newValue)
                    const newState = getNewState(
                        props.item,
                        newValue,
                        props.filters,
                        "id"
                    )

                    if (newState) {
                        props.setFilters(newState)
                    }
                }
            }}
            loadingText="Loading concepts..."
            noOptionsText="No concepts that match..."
            loading={loading}
            getOptionLabel={(option) => option.title}
            renderOption={(option) => {
                const TypeIcon = getConceptTypeIcon(option.rootCategory?.name)

                return (
                    <Box alignItems="center" display="flex" key={option.id}>
                        {TypeIcon && <TypeIcon className={classes.icon} />}

                        <Box>
                            <Typography variant="body1">
                                {option.title}
                            </Typography>
                        </Box>
                    </Box>
                )
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const TypeIcon = getConceptTypeIcon(option.type)
                    return (
                        <Tooltip title={option.title} key={option.id}>
                            <Chip
                                key={option.id}
                                size="small"
                                avatar={
                                    <Avatar
                                        src={option.imageUrl}
                                        className={classes.avatar}
                                    >
                                        <TypeIcon
                                            className={classes.chipIcon}
                                        />
                                    </Avatar>
                                }
                                label={
                                    <Typography
                                        variant="body2"
                                        style={{
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {option.title}
                                    </Typography>
                                }
                                {...getTagProps({ index })}
                                style={{ height: "100%" }}
                            />
                        </Tooltip>
                    )
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={`${props.item.field}s`}
                    variant="outlined"
                />
            )}
        />
    )
}
