import { Box, IconButton } from "@material-ui/core"
import CommentsContainer from "./CommentsContainer"
import { makeStyles } from "@material-ui/core/styles"
import ConceptListItem from "../ConceptListItem"
import CloseIcon from "@material-ui/icons/Close"
import { Concept } from "../../__generated__/types"
const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
    },
}))

interface FeedCommentsContainerProps {
    concept: Concept
    closeComments: () => void
}
export default function FeedCommentsContainer(
    props: FeedCommentsContainerProps
) {
    const classes = useStyles()
    return (
        <Box className={classes.container}>
            <Box display="flex" justifyContent="flex-end">
                <IconButton size="small" onClick={() => props.closeComments()}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <ConceptListItem item={props.concept} showSummary={false} />

            <CommentsContainer conceptId={props.concept.id} />
        </Box>
    )
}
