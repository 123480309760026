import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import CircularProgress from "@material-ui/core/CircularProgress"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Divider from "@material-ui/core/Divider"
import Avatar from "@material-ui/core/Avatar"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { getPermissionsType } from "../../util/EnumValues"
import GroupIcon from "@material-ui/icons/Group"
import SearchIcon from "@material-ui/icons/Search"
import SendIcon from "@material-ui/icons/Send"
import {
    CONCEPT_PERMISSIONS,
    GRANT_USER_ACCESS,
    GRANT_TEAM_ACCESS,
    REMOVE_USER_ACCESS,
    REMOVE_TEAM_ACCESS,
} from "./graphql"
import {
    ConceptPermissionsQuery,
    ConceptPermissionsQueryVariables,
    GrantTeamAccessMutation,
    GrantTeamAccessMutationVariables,
    GrantUserAccessMutation,
    GrantUserAccessMutationVariables,
    RemoveTeamAccessMutation,
    RemoveTeamAccessMutationVariables,
    RemoveUserAccessMutation,
    RemoveUserAccessMutationVariables,
} from "./__generated__/graphql"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import { ALL_USER_QUERY } from "../users/graphql"
import { ALL_TEAM_QUERY } from "../teams/graphql"
import {
    AllUsersQuery,
    AllUsersQueryVariables,
} from "../users/__generated__/graphql"
import {
    AllTeamQuery,
    AllTeamQueryVariables,
} from "../teams/__generated__/graphql"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Tab from "@material-ui/core/Tab"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Tabs from "@material-ui/core/Tabs"
import { useAuth } from "../../providers/AuthProvider"
import Tooltip from "@material-ui/core/Tooltip"
import { useHistory } from "react-router-dom"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { UPDATE_CONCEPT } from "../../graphql/mutations"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../../graphql/__generated__/mutations"
import { Loading } from "../Loading"
import {
    AccessType,
    _ConceptAddedTeams,
    _ConceptAddedUsers,
    User,
    Team,
    Concept,
} from "../../__generated__/types"
import { IconButton, Slide, Link, Typography } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import InputAdornment from "@material-ui/core/InputAdornment"
import Chip from "@material-ui/core/Chip"
import BlockIcon from "@material-ui/icons/Block"
import VisibilityIcon from "@material-ui/icons/Visibility"
import EditIcon from "@material-ui/icons/Edit"
import { Link as LinkRouter } from "react-router-dom"
const useStyles = makeStyles((theme: Theme) => ({
    isPublic: {
        padding: theme.spacing(1),
    },
    tooltipListItem: {
        fontSize: theme.typography.body2.fontSize,
    },
    noBorder: {
        border: "none",
    },
    additionForm: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        width: "100%",
    },
    dialogContent: {
        padding: 0,
        overflowX: "hidden",
    },
    listItemBody: {
        display: "flex",
        flexBasis: "70%",
        overflow: "hidden",
        alignItems: "center",
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    listItemActionContainer: {
        flexBasis: "30%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    autoComplete: {
        minWidth: "8em",
    },
    sendIconContainer: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingLeft: theme.spacing(1),
        },
    },
    dialogTitleContainer: {
        display: "flex",
        alignItems: "center",
        paddingBottom: 0,
        justifyContent: "space-between",
    },
    publicMenuIcon: {
        height: "0.75em",
        width: "0.75em",
    },
}))

interface ConceptPermissionsProps {
    item: Concept
    onClose: () => void
}

export default function ConceptPermissions(props: ConceptPermissionsProps) {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { currentUser } = useAuth()
    const { loading, error, data } = useQuery<
        ConceptPermissionsQuery,
        ConceptPermissionsQueryVariables
    >(CONCEPT_PERMISSIONS, {
        variables: { id: props.item.id },
    })
    const [tab, setTab] = useState(0)
    const users = (data?.Concept[0]?.addedUsers ?? [])
        .slice()
        .sort((a, b) =>
            a.User.lastName
                .toUpperCase()
                .localeCompare(b.User.lastName.toUpperCase())
        )
    const teams = (data?.Concept[0].addedTeams ?? [])
        .slice()
        .sort((a, b) =>
            a.Team.name.toUpperCase().localeCompare(b.Team.name.toUpperCase())
        )
    let permission =
        users?.some(
            (item) =>
                item.User.userId === currentUser.userId && item.type === "OWNER"
        ) ?? false

    if (!permission) {
        // if permission is not found within users, then look inside teams
        permission =
            teams?.some((item) =>
                item.Team.members.some(
                    (member) =>
                        member.userId === currentUser.userId &&
                        item.type === "OWNER"
                )
            ) ?? false
    }
    permission = true /// for testing purposes
    return (
        <Dialog
            open={true}
            fullWidth={!mobile}
            fullScreen={mobile}
            PaperProps={{
                style: { height: mobile ? "auto" : 700 },
            }}
        >
            {loading ? (
                <Loading size={75} padding={3} hideQuote={true} />
            ) : (
                <>
                    <DialogTitle style={{ flexShrink: 1 }}>
                        {props.item.type} Permissions
                        <IconButton
                            style={{ position: "absolute", right: 0, top: 0 }}
                            onClick={() => props.onClose()}
                        >
                            <ClearIcon />
                        </IconButton>
                    </DialogTitle>

                    <Divider />

                    <Box p={3} display="flex" justifyContent="space-between">
                        <Typography variant="button">Public Access</Typography>
                        <PublicAccessEditor
                            concept={props.item}
                            permission={permission}
                        />
                    </Box>

                    <Divider />
                    <Tabs
                        variant="fullWidth"
                        value={tab}
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: number
                        ) => {
                            setTab(newValue)
                        }}
                        indicatorColor="primary"
                    >
                        <Tab label={`Users (${users?.length ?? 0})`} />
                        <Tab label={`Teams (${teams?.length ?? 0})`} />
                    </Tabs>
                    {tab === 0 && permission && (
                        <>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                padding={1}
                            >
                                <AddUserAccess concept={props.item} />
                            </Box>

                            <Divider />
                        </>
                    )}
                    {tab === 1 && permission && (
                        <>
                            <Box display="flex" justifyContent="flex-end" p={1}>
                                <AddTeamAccess concept={props.item} />
                            </Box>

                            <Divider />
                        </>
                    )}
                    <DialogContent className={classes.dialogContent}>
                        {tab === 0 && (
                            <List>
                                {users?.length > 0 &&
                                    users?.map((item, idx) => {
                                        return (
                                            <Box key={item.User.userId}>
                                                <UserItem
                                                    item={item}
                                                    concept={props.item}
                                                    permission={permission}
                                                />
                                            </Box>
                                        )
                                    })}
                            </List>
                        )}
                        {tab === 1 && (
                            <List>
                                {teams?.length > 0 &&
                                    teams?.map((item, idx) => {
                                        return (
                                            <Box key={item.Team.teamId}>
                                                <TeamItem
                                                    item={item}
                                                    concept={props.item}
                                                    permission={permission}
                                                />
                                            </Box>
                                        )
                                    })}
                            </List>
                        )}
                    </DialogContent>
                </>
            )}
        </Dialog>
    )
}
interface AddUserAccessProps {
    concept: Concept
}
const AddUserAccess = (props: AddUserAccessProps) => {
    const classes = useStyles()
    const { concept } = props
    const currentUserIds = concept?.addedUsers.map((item, idx) => {
        return item.User?.userId
    })
    const [users, setUsers] = useState<User[]>([])
    const [saving, setSaving] = useState(false)
    const [accessType, setAccessType] = useState<AccessType>(AccessType.VIEWER)
    const [grantUserAccess] = useMutation<
        GrantUserAccessMutation,
        GrantUserAccessMutationVariables
    >(GRANT_USER_ACCESS)
    const [loadUsers, { data, loading, called }] = useLazyQuery<
        AllUsersQuery,
        AllUsersQueryVariables
    >(ALL_USER_QUERY)
    const handleSave = async () => {
        setSaving(true)
        await Promise.all([
            users.map((user) => {
                grantUserAccess({
                    variables: {
                        conceptId: concept.id,
                        userId: user.userId,
                        accessType: accessType,
                    },
                })
            }),
        ])
        setUsers([])
        setSaving(false)
    }

    return (
        <Box className={classes.additionForm}>
            <Box p={1} flexGrow={1}>
                <Autocomplete
                    id="multiple-limit-tags"
                    options={
                        data?.users
                            ? data?.users.filter((x) => {
                                  return currentUserIds.indexOf(x.userId) === -1
                              })
                            : []
                    }
                    fullWidth
                    value={users}
                    onFocus={() => {
                        if (!called) {
                            loadUsers()
                        }
                    }}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                avatar={
                                    option.imageUrl ? (
                                        <Avatar src={option.imageUrl} />
                                    ) : (
                                        <Avatar>
                                            {option.firstName[0] +
                                                option.lastName[0]}
                                        </Avatar>
                                    )
                                }
                                label={option.firstName + " " + option.lastName}
                            />
                        ))
                    }}
                    size="small"
                    multiple
                    disabled={saving}
                    loading={loading}
                    onChange={(event, newValue: User[]) => {
                        setUsers(newValue)
                    }}
                    getOptionLabel={(option) => {
                        return option.lastName + ", " + option.firstName
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={"User"}
                            placeholder="Select User"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: loading ? (
                                    <>
                                        <InputAdornment position="start">
                                            <CircularProgress
                                                disableShrink
                                                size={25}
                                            />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ) : (
                                    <>
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Box>
            <Box className={classes.sendIconContainer}>
                <Autocomplete
                    options={[
                        AccessType.VIEWER,
                        AccessType.EDITOR,
                        AccessType.OWNER,
                    ]}
                    size="small"
                    fullWidth
                    value={accessType}
                    getOptionLabel={(type) => {
                        return getPermissionsType(type)
                    }}
                    className={classes.autoComplete}
                    disableClearable
                    openOnFocus
                    disabled={saving}
                    onChange={(event, value: AccessType) => {
                        setAccessType(value)
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} />
                    )}
                />

                <IconButton
                    color="primary"
                    disabled={users.length === 0}
                    onClick={handleSave}
                >
                    {saving ? <CircularProgress size={25} /> : <SendIcon />}
                </IconButton>
            </Box>
        </Box>
    )
}
interface AddTeamAccessProps {
    concept: Concept
}
const AddTeamAccess = (props: AddTeamAccessProps) => {
    const { concept } = props
    const classes = useStyles()
    const currentTeamIds = concept?.addedTeams.map((item, idx) => {
        return item.Team?.teamId
    })
    const [teams, setTeams] = useState<Team[]>([])
    const [saving, setSaving] = useState(false)
    const [accessType, setAccessType] = useState<AccessType>(AccessType.EDITOR)
    const [grantTeamAccess] = useMutation<
        GrantTeamAccessMutation,
        GrantTeamAccessMutationVariables
    >(GRANT_TEAM_ACCESS)
    const [loadTeams, { data, loading, called }] = useLazyQuery<
        AllTeamQuery,
        AllTeamQueryVariables
    >(ALL_TEAM_QUERY)
    const handleSave = async () => {
        setSaving(true)
        await Promise.all([
            teams.map((team) => {
                grantTeamAccess({
                    variables: {
                        conceptId: concept.id,
                        teamId: team.teamId,
                        accessType: accessType,
                    },
                })
            }),
        ])
        setTeams([])
        setSaving(false)
    }
    return (
        <Box className={classes.additionForm}>
            <Box p={1} flexGrow={1}>
                <Autocomplete
                    options={
                        data?.teams
                            ? data?.teams.filter((x) => {
                                  return currentTeamIds.indexOf(x.teamId) === -1
                              })
                            : []
                    }
                    value={teams}
                    multiple
                    onFocus={() => {
                        if (!called) {
                            loadTeams()
                        }
                    }}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                avatar={
                                    option.imageUrl ? (
                                        <Avatar src={option.imageUrl} />
                                    ) : (
                                        <Avatar>{option.name[0]}</Avatar>
                                    )
                                }
                                label={option.name}
                            />
                        ))
                    }}
                    disabled={saving}
                    loading={loading}
                    size="small"
                    onChange={(event, newValue: Team[]) => {
                        setTeams(newValue)
                    }}
                    getOptionLabel={(option) => {
                        return option.name
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={"Team"}
                            placeholder="Select Team"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: loading ? (
                                    <>
                                        <InputAdornment position="start">
                                            <CircularProgress
                                                disableShrink
                                                size={25}
                                            />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ) : (
                                    <>
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Box>
            <Box className={classes.sendIconContainer}>
                <Autocomplete
                    options={[
                        AccessType.VIEWER,
                        AccessType.EDITOR,
                        AccessType.OWNER,
                    ]}
                    fullWidth
                    value={accessType}
                    size="small"
                    getOptionLabel={(type) => {
                        return getPermissionsType(type)
                    }}
                    className={classes.autoComplete}
                    disableClearable
                    openOnFocus
                    disabled={saving}
                    onChange={(event, value: AccessType) => {
                        setAccessType(value)
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} />
                    )}
                />

                <IconButton
                    color="primary"
                    disabled={teams.length === 0}
                    onClick={handleSave}
                >
                    {saving ? <CircularProgress size={25} /> : <SendIcon />}
                </IconButton>
            </Box>
        </Box>
    )
}

interface PublicAccessEditorProps {
    concept: Concept
    permission: boolean
}
const PublicAccessEditor = (props: PublicAccessEditorProps) => {
    const { concept } = props
    const { currentUser } = useAuth()
    const classes = useStyles()
    const [saving, setSaving] = useState(false)
    const [value, setValue] = useState<AccessType>(AccessType.NONE)
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: props.concept.id },
            },
        ],
    })
    const handleEdit = async () => {
        setSaving(true)
        await updateConcept({
            variables: {
                userId: currentUser.userId,
                concept: {
                    id: concept.id,
                    isPublicAccessType: value,
                    isPublic: value !== AccessType.NONE ?? false,
                },
            },
        })
        setSaving(false)
    }

    useEffect(() => {
        if (concept.isPublic && !concept.isPublicAccessType) {
            setValue(AccessType.VIEWER)
        } else if (!concept.isPublic) {
            setValue(AccessType.NONE)
        } else {
            setValue(concept.isPublicAccessType)
        }
    }, [concept])
    const options = [
        {
            value: AccessType.NONE,
            html: (
                <Box display="flex" alignItems="center">
                    <BlockIcon className={classes.publicMenuIcon} />
                    <Box ml={1}>No public access</Box>
                </Box>
            ),
        },
        {
            value: AccessType.VIEWER,
            html: (
                <Box display="flex" alignItems="center">
                    <VisibilityIcon className={classes.publicMenuIcon} />
                    <Box ml={1}>Everyone can view</Box>
                </Box>
            ),
        },
        {
            value: AccessType.EDITOR,
            html: (
                <Box display="flex" alignItems="center">
                    <EditIcon className={classes.publicMenuIcon} />
                    <Box ml={1}>Everyone can edit</Box>
                </Box>
            ),
        },
    ]
    return (
        <Box display="flex" alignItems="center">
            <Select
                value={value}
                onChange={(event: React.ChangeEvent<{ value: AccessType }>) => {
                    setValue(event.target.value)
                }}
                disabled={saving || !props.permission}
                disableUnderline
            >
                {options.map((option, idx) => {
                    return (
                        <MenuItem key={"PUBLIC" + value} value={option.value}>
                            {option.html}
                        </MenuItem>
                    )
                })}
            </Select>
            <Slide
                in={value !== concept.isPublicAccessType}
                direction="left"
                timeout={120}
                unmountOnExit
            >
                <Box display="flex">
                    <Tooltip title="Save changes">
                        <IconButton disabled={saving} onClick={handleEdit}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Discard changes">
                        <IconButton
                            disabled={saving}
                            onClick={() => {
                                setValue(concept.isPublicAccessType)
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Slide>
        </Box>
    )
}

interface TeamItemProps {
    item: _ConceptAddedTeams
    concept: any
    permission: boolean
}
const TeamItem = (props: TeamItemProps) => {
    const { item, concept } = props
    const history = useHistory()
    const classes = useStyles()
    const [saving, setSaving] = useState(false)
    const [value, setValue] = useState<AccessType>(AccessType.NONE)
    const [grantTeamAccess] = useMutation<
        GrantTeamAccessMutation,
        GrantTeamAccessMutationVariables
    >(GRANT_TEAM_ACCESS)
    const [removeTeamAccess] = useMutation<
        RemoveTeamAccessMutation,
        RemoveTeamAccessMutationVariables
    >(REMOVE_TEAM_ACCESS)
    const handleEdit = async () => {
        setSaving(true)
        if (value === AccessType.NONE) {
            await removeTeamAccess({
                variables: {
                    conceptId: concept.id,
                    teamId: item.Team.teamId,
                },
            })
        } else {
            await grantTeamAccess({
                variables: {
                    conceptId: concept.id,
                    teamId: item.Team.teamId,
                    accessType: value,
                },
            })
        }

        setSaving(false)
    }

    useEffect(() => {
        setValue(item.type ?? AccessType.VIEWER)
    }, [item])
    const options = [
        AccessType.NONE,
        AccessType.VIEWER,
        AccessType.EDITOR,
        AccessType.OWNER,
    ]
    return (
        <>
            <ListItem key={item.Team.teamId} dense>
                <Box className={classes.listItemBody}>
                    <ListItemAvatar>
                        {item.Team && item.Team.imageUrl ? (
                            <Avatar src={item.Team.imageUrl} />
                        ) : (
                            <Avatar>
                                <GroupIcon />
                            </Avatar>
                        )}
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Link
                                className={classes.ellipsis}
                                color="inherit"
                                to={`/team/${item.Team.teamId}/home`}
                                component={LinkRouter}
                            >
                                {item.Team.name}
                            </Link>
                        }
                        secondary={
                            <Tooltip
                                title={
                                    <Box>
                                        {item.Team.members.map((x, idx) => {
                                            return (
                                                <Box
                                                    key={x.userId}
                                                    className={
                                                        classes.tooltipListItem
                                                    }
                                                    padding={0.5}
                                                >
                                                    {x.lastName +
                                                        ", " +
                                                        x.firstName}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                }
                            >
                                <Box className={classes.ellipsis}>
                                    {item.Team.members.length + " members"}
                                </Box>
                            </Tooltip>
                        }
                    />
                </Box>
                <ClickAwayListener
                    onClickAway={() => {
                        setValue(item.type)
                    }}
                >
                    <Box className={classes.listItemActionContainer}>
                        <Select
                            value={value}
                            onChange={(
                                event: React.ChangeEvent<{ value: AccessType }>
                            ) => {
                                setValue(event.target.value)
                            }}
                            disabled={saving || !props.permission}
                            disableUnderline
                        >
                            {options.map((item, idx) => {
                                const label = getPermissionsType(item)

                                return (
                                    <MenuItem key={"TEAM" + item} value={item}>
                                        {label}
                                    </MenuItem>
                                )
                            })}
                        </Select>

                        <Slide
                            in={value !== props.item.type}
                            direction="left"
                            timeout={120}
                            unmountOnExit
                        >
                            <Box display="flex">
                                <IconButton
                                    disabled={saving}
                                    onClick={handleEdit}
                                >
                                    <CheckIcon />
                                </IconButton>
                                <IconButton
                                    disabled={saving}
                                    onClick={() => {
                                        setValue(item.type)
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Box>
                        </Slide>
                    </Box>
                </ClickAwayListener>

                <Divider />
            </ListItem>
        </>
    )
}

interface UserItemProps {
    item: _ConceptAddedUsers
    concept: any
    permission: boolean
}
const UserItem = (props: UserItemProps) => {
    const { item, concept } = props
    const history = useHistory()
    const classes = useStyles()
    const [saving, setSaving] = useState(false)
    const [value, setValue] = useState<AccessType>(AccessType.NONE)
    const [grantUserAccess] = useMutation<
        GrantUserAccessMutation,
        GrantUserAccessMutationVariables
    >(GRANT_USER_ACCESS)
    const [removeUserAccess] = useMutation<
        RemoveUserAccessMutation,
        RemoveUserAccessMutationVariables
    >(REMOVE_USER_ACCESS)
    const handleEdit = async () => {
        setSaving(true)
        if (value === AccessType.NONE) {
            await removeUserAccess({
                variables: {
                    conceptId: concept.id,
                    userId: item.User.userId,
                },
            })
        } else {
            await grantUserAccess({
                variables: {
                    conceptId: concept.id,
                    userId: item.User.userId,
                    accessType: value,
                },
            })
        }
        setSaving(false)
    }
    useEffect(() => {
        setValue(item.type ?? AccessType.VIEWER)
    }, [item.type])
    const options = [
        AccessType.NONE,
        AccessType.VIEWER,
        AccessType.EDITOR,
        AccessType.OWNER,
    ]
    return (
        <>
            <ListItem key={item.User.userId}>
                <Box className={classes.listItemBody}>
                    <Box textAlign="center">
                        <ListItemAvatar>
                            {item.User.imageUrl ? (
                                <Avatar src={item.User.imageUrl} />
                            ) : (
                                <Avatar>
                                    {item.User.firstName[0] +
                                        item.User.lastName[0]}
                                </Avatar>
                            )}
                        </ListItemAvatar>
                    </Box>

                    <ListItemText
                        primary={
                            <Link
                                className={classes.ellipsis}
                                color="inherit"
                                component={LinkRouter}
                                to={`/user/${item.User.username}/home`}
                            >
                                {item.User.lastName +
                                    ", " +
                                    item.User.firstName}
                            </Link>
                        }
                        secondary={
                            <Box className={classes.ellipsis} component="span">
                                {item.User.email}
                            </Box>
                        }
                    />
                </Box>
                <ClickAwayListener
                    onClickAway={() => {
                        setValue(item.type)
                    }}
                >
                    <Box className={classes.listItemActionContainer}>
                        <Select
                            value={value}
                            onChange={(
                                event: React.ChangeEvent<{ value: AccessType }>
                            ) => {
                                setValue(event.target.value)
                            }}
                            disabled={saving || !props.permission}
                            disableUnderline
                        >
                            {options.map((item, idx) => {
                                const label = getPermissionsType(item)

                                return (
                                    <MenuItem key={"USER" + item} value={item}>
                                        {label}
                                    </MenuItem>
                                )
                            })}
                        </Select>

                        <Slide
                            in={value !== props.item.type}
                            direction="left"
                            timeout={120}
                            unmountOnExit
                        >
                            <Box display="flex">
                                <IconButton
                                    disabled={saving}
                                    onClick={handleEdit}
                                >
                                    <CheckIcon />
                                </IconButton>
                                <IconButton
                                    disabled={saving}
                                    onClick={() => {
                                        setValue(item.type)
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Box>
                        </Slide>
                    </Box>
                </ClickAwayListener>
            </ListItem>
        </>
    )
}
