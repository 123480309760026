import Box from "@material-ui/core/Box"
import { ExerciseFragment } from "./__generated__/graphql"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Divider,
    Paper,
    Avatar,
    IconButton,
    DialogTitle,
    DialogActions,
    Dialog,
    DialogContent,
    Button,
    Tooltip,
} from "@material-ui/core"
import {
    ExerciseCategory,
    ExerciseStatus,
    ExerciseSubmission,
} from "../../__generated__/types"
import { useState } from "react"
import ClearIcon from "@material-ui/icons/Clear"
import { InfoSection } from "./ExerciseConceptRanking"
import InfoIcon from "@material-ui/icons/Info"
import UserRankingSubmissionResults from "./UserRankingSubmissionResults"
import UserScoringSubmissionResults from "./UserScoringSubmissionResults"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    list: {
        overflowY: "auto",

        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    label: {
        color: theme.palette.text.hint,
    },
    conceptList: {
        overflowY: "auto",
        flexWrap: "wrap",
    },
    submissionResultsContainer: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        flex: 2,
        margin: theme.spacing(1),
    },
    submissionNotesContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    contentContainer: {
        display: "flex",
        overflow: "hidden",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            overflow: "auto",
        },
    },
    focusedSubmissionContainer: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        display: "flex",
        overflow: "auto",
        height: "100%",
    },
}))
interface ExerciseConceptRankingResultsProps {
    exercise: ExerciseFragment
    onClose: () => void
}
export default function ExerciseConceptRankingResults(
    props: ExerciseConceptRankingResultsProps
) {
    const { exercise } = props
    const [
        focusedSubmission,
        setFocusedSubmission,
    ] = useState<ExerciseSubmission | null>(null)
    const classes = useStyles()
    const theme = useTheme()
    const [openInfo, setOpenInfo] = useState(false)
    const submissions =
        exercise?.submissions?.filter(
            (sub) => sub.status === ExerciseStatus.COMPLETE
        ) ?? []

    return (
        !!exercise && (
            <>
                <Box
                    p={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h6">
                        {exercise.title} - Results
                    </Typography>
                    <Box display="flex" ml={2}>
                        <Tooltip title="View exercise information">
                            <IconButton
                                onClick={() => {
                                    setOpenInfo(true)
                                }}
                            >
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Close window">
                            <IconButton
                                onClick={() => {
                                    props.onClose()
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Box>
                    <Divider />
                </Box>
                <Box className={classes.contentContainer}>
                    <Box p={2} flex={1.5}>
                        <Box>
                            <Box p={1}>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Submissions ({submissions.length})
                                </Typography>
                            </Box>
                            {submissions?.length !== 0 && (
                                <Paper>
                                    <List className={classes.list}>
                                        {submissions.map(
                                            (item: ExerciseSubmission, idx) => {
                                                return (
                                                    <ListItem
                                                        divider
                                                        key={item.id}
                                                        button
                                                        component="a"
                                                        onClick={() => {
                                                            setFocusedSubmission(
                                                                item
                                                            )
                                                        }}
                                                        selected={
                                                            focusedSubmission?.id ===
                                                            item.id
                                                        }
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar
                                                                src={
                                                                    item.user
                                                                        ?.imageUrl
                                                                }
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                item.user
                                                                    ?.lastName +
                                                                ", " +
                                                                item.user
                                                                    ?.firstName
                                                            }
                                                            secondary={
                                                                item.notes
                                                            }
                                                        />
                                                    </ListItem>
                                                )
                                            }
                                        )}
                                    </List>
                                </Paper>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        <Divider orientation="vertical" />
                    </Box>
                    {!!focusedSubmission && (
                        <Box
                            flex={3}
                            p={2}
                            display="flex"
                            flexDirection="column"
                        >
                            <Box
                                display="flex"
                                p={1}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box display="flex" p={1} alignItems="center">
                                    <Box ml={1} mr={1}>
                                        <Avatar
                                            src={
                                                focusedSubmission.user?.imageUrl
                                            }
                                        />
                                    </Box>
                                    <Typography variant="h6">
                                        {focusedSubmission.user?.firstName}{" "}
                                        {focusedSubmission.user?.lastName}
                                    </Typography>
                                </Box>
                                <IconButton
                                    onClick={() => {
                                        setFocusedSubmission(null)
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Box>
                            <Box className={classes.focusedSubmissionContainer}>
                                <Paper
                                    className={classes.submissionNotesContainer}
                                >
                                    <Box p={1}>
                                        <Typography variant="subtitle1">
                                            Notes
                                        </Typography>
                                    </Box>
                                    <Divider />

                                    <Box p={1}>
                                        <Typography variant="body1">
                                            {focusedSubmission?.notes}
                                        </Typography>
                                    </Box>
                                </Paper>
                                <Box
                                    className={
                                        classes.submissionResultsContainer
                                    }
                                >
                                    {exercise.type?.category ===
                                    ExerciseCategory.RANKING ? (
                                        <UserRankingSubmissionResults
                                            submission={focusedSubmission}
                                            exercise={exercise}
                                        />
                                    ) : exercise.type?.category ===
                                      ExerciseCategory.SCORING ? (
                                        <UserScoringSubmissionResults
                                            submission={focusedSubmission}
                                            exercise={exercise}
                                        />
                                    ) : null}
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {openInfo && (
                        <Dialog open={true}>
                            <DialogTitle>Exercise Information</DialogTitle>
                            <DialogContent>
                                <InfoSection exercise={exercise} />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        setOpenInfo(false)
                                    }}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Box>
            </>
        )
    )
}
