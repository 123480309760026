import React, { useState, useCallback } from "react"
import { IDEAS_FEED_QUERY } from "../graphql/queries"
import { useQuery } from "@apollo/client"
import { Box } from "@material-ui/core"
import {
    IdeasFeedQuery,
    IdeasFeedQueryVariables,
} from "../graphql/__generated__/queries"
import ConceptCard from "./ConceptCard"
import VirtualInfiniteList from "./VirtualInfiniteList"
const PAGE_SIZE = 10

export default function ConceptVirtualList(props) {
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [itemsPerRow, setItemsPerRow] = useState(1)
    const { loading, error, data, fetchMore, refetch } = useQuery<
        IdeasFeedQuery,
        IdeasFeedQueryVariables
    >(IDEAS_FEED_QUERY, {
        variables: {
            filter: props.filter,
            offset: 0,
            first: limit,
        },
    })
    const listData = data?.Concept ?? []
    const alignment = itemsPerRow === 1 ? "column" : "grid"
    const size = Math.ceil(listData.length / itemsPerRow)
    const onFetchMore = useCallback(
        async (offset) => {
            setLimit(offset)
            if (!fetchMore) return
            const result: { data: IdeasFeedQuery } = await fetchMore({
                variables: {
                    offset,
                },
            })

            return result.data.Concept.length >= PAGE_SIZE
        },
        [fetchMore]
    )

    return (
        <Box key={JSON.stringify(props.filter)}>
            <Box pt={1} width="100%">
                <VirtualInfiniteList
                    key={JSON.stringify(props.filter)}
                    onFetchMore={onFetchMore}
                    startOffset={limit}
                    overscan={Math.ceil(10 / itemsPerRow)}
                    pageSize={PAGE_SIZE}
                    size={size}
                >
                    {(itemIndex) => (
                        <Box
                            display="grid"
                            gridTemplateColumns={`repeat(${itemsPerRow}, 1fr)`}
                            gridGap="15px"
                        >
                            {new Array(itemsPerRow).fill(1).map((_, index) => {
                                const item =
                                    listData[itemIndex * itemsPerRow + index]
                                return item ? (
                                    <ConceptCard
                                        key={item.id}
                                        item={item}
                                        grid={itemsPerRow > 1}
                                    />
                                ) : null
                            })}
                        </Box>
                    )}
                </VirtualInfiniteList>
            </Box>
        </Box>
    )
}
