import React from "react"
import Box from "@material-ui/core/Box"
import { useQuery } from "@apollo/client"
import { makeStyles, Theme } from "@material-ui/core/styles"

import SortableChecklist from "../SortableChecklist"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { Loading } from "../Loading"

import { Checklist } from "../../__generated__/types"

import { CHECKLIST_BY_ID } from "../../graphql/Checklist"
import {
    ChecklistByIdQuery,
    ChecklistByIdQueryVariables,
} from "../../graphql/__generated__/Checklist"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100%",
        padding: 0,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
    },
    textField: {
        paddingLeft: theme.spacing(1),
        fontSize: theme.typography.body2.fontSize,
    },
    listbox: {
        overflow: "auto",
    },
    listTitle: {
        fontSize: theme.typography.body1.fontSize,
    },
    listType: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
    },
    summary: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
    },
    avatar: {
        width: "1em",
        height: "1em",
    },
    clone: {
        zIndex: 100,
        backgroundColor: theme.palette.background.paper,
    },
    sortableList: {
        borderTop: "0.5px solid " + theme.palette.divider,
        borderBottom: "0.5px solid " + theme.palette.divider,
    },
    tabs: {
        borderBottom: "1px solid " + theme.palette.divider,
    },
    scroller: {
        overflowY: "hidden",
    },
    linkMenu: {
        padding: theme.spacing(1.5),
    },
}))

interface ConceptListProps {
    checklistId?: string
    checklist?: Checklist
    editPermission: boolean
    draggable: boolean
}

export default function ConceptList(props: ConceptListProps) {
    const classes = useStyles()
    const { data, loading, error } = useQuery<
        ChecklistByIdQuery,
        ChecklistByIdQueryVariables
    >(CHECKLIST_BY_ID, {
        variables: {
            id: props.checklistId,
        },
        skip: !!props.checklist && !props.checklistId,
    })

    const { t } = useAwaitTranslation("widgets")
    const list = props.checklist ?? data?.Checklist?.[0] ?? null
    if (error) {
        return (
            <Box>
                {t(
                    "listRetriveError",
                    "We were not able to retrieve these lists..."
                )}
            </Box>
        )
    }
    if (loading) {
        return <Loading size={75} hideQuote={true} />
    }
    if (data && data?.Checklist.length === 0) {
        return (
            <Box p={2} textAlign="center">
                The selected list was deleted...
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="column" overflow="hidden">
            <Box width="100%" className={classes.listbox}>
                {list && (
                    <SortableChecklist
                        editPermission={props.editPermission}
                        list={list}
                        draggable={props.draggable}
                    />
                )}
            </Box>
        </Box>
    )
}
