import React, { useState, useEffect } from "react"
import RichTextEditor from "../RichTextEditor"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import { gql, useMutation } from "@apollo/client"
import dayjs from "dayjs"
import { getConceptLikedIcon } from "../../util/ConceptLikedStyles"
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    ADD_USER_COMMENT_LIKE,
    REMOVE_USER_COMMENT_LIKE,
    CREATE_COMMENT_REPLY,
} from "./graphql"
import {
    Add_User_Comment_LikeMutation,
    Add_User_Comment_LikeMutationVariables,
    Remove_User_Comment_LikeMutation,
    Remove_User_Comment_LikeMutationVariables,
    CreateCommentReplyMutation,
    CreateCommentReplyMutationVariables,
} from "./__generated__/graphql"
import { Link as LinkRouter } from "react-router-dom"
import { Comment } from "../../__generated__/types"
import { useAuth } from "../../providers/AuthProvider"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1),
    },
    avatar: {
        height: theme.spacing(2),
        width: theme.spacing(2),
        marginRight: "7.5px",
    },
    authorContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: "1em",
        color: theme.palette.text.secondary,
        //fontSize: theme.typography.body2.fontSize
        fontSize: "0.5em",
    },
    replyContainer: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    date: {
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    likeButton: {
        width: "1.5em",
        minWidth: "fit-content",
    },
    replyButton: {
        width: "1.5em",
        minWidth: "fit-content",
    },
    labelText: {
        opacity: "0.6",
        fontSize: "0.75em",
    },
    labelStylesIcon: {
        opacity: "0.6",
        fontSize: "1.125em",
        verticalAlign: "middle",
        "&:first-child": {
            marginRight: "8px",
        },
    },
    commentContainer: {
        borderRadius: "4px",
        backdropFilter: "contrast(0.95)",
        padding: theme.spacing(0, 0.5),
    },
    reply: {
        borderLeft: "2px solid " + theme.palette.primary.main,
        paddingLeft: theme.spacing(0.5),
    },
}))
interface CommentProps {
    comment: Comment
    liked: boolean
    conceptId: string
}
const initialText = JSON.stringify([{ type: "p", children: [{ text: "" }] }])
export default function SingularComment(props: CommentProps) {
    const { comment, conceptId, liked } = props
    const { currentUser } = useAuth()

    const [addLike] = useMutation<
        Add_User_Comment_LikeMutation,
        Add_User_Comment_LikeMutationVariables
    >(ADD_USER_COMMENT_LIKE)
    const [removeLike] = useMutation<
        Remove_User_Comment_LikeMutation,
        Remove_User_Comment_LikeMutationVariables
    >(REMOVE_USER_COMMENT_LIKE)
    const [createCommentReply] = useMutation<
        CreateCommentReplyMutation,
        CreateCommentReplyMutationVariables
    >(CREATE_COMMENT_REPLY, {
        update(cache, { data: { CreateCommentReply } }) {
            cache.modify({
                id: cache.identify(comment),
                fields: {
                    replies(existingReplies = [], { readField }) {
                        const newCommentRef = cache.writeFragment({
                            data: CreateCommentReply,
                            fragment: gql`
                                fragment NewComment on Comment {
                                    commentId
                                }
                            `,
                        })
                        if (
                            existingReplies.some(
                                (ref) =>
                                    readField("commentId", ref) ===
                                    CreateCommentReply.commentId
                            )
                        ) {
                            return existingReplies
                        }
                        return [...existingReplies, newCommentRef]
                    },
                },
            })
        },
    })
    const classes = useStyles()
    const [likeState, setLikeState] = useState(false)
    const [likeLength, setLikeLength] = useState(0)
    const [replyTextValue, setReplyTextValue] = useState(initialText)
    const [replyOpen, setReplyOpen] = useState(false)
    const [viewReplies, setViewReplies] = useState(false)
    const { t } = useAwaitTranslation("comments")
    const LikeObject = {
        userId: currentUser.userId as any,
        commentId: comment.commentId as any,
    }
    async function submit(commentReply: any) {
        let response = await createCommentReply({
            variables: { ...commentReply },
        })
        if (response && response.data) {
            setReplyTextValue(initialText)
            setReplyOpen(false)
        }
    }
    useEffect(() => {
        setLikeState(liked)
        setLikeLength(comment.likedBy?.length)
    }, [liked, comment.likedBy?.length, setLikeState, setLikeLength])
    const handleLiked = () => {
        if (likeState === false) {
            setLikeState(true)
            setLikeLength(likeLength + 1)
            addLike({ variables: { ...LikeObject } })
        } else {
            setLikeState(false)
            setLikeLength(likeLength - 1)
            removeLike({ variables: { ...LikeObject } })
        }
    }
    const handleSubmission = () => {
        let object = {
            parentId: comment.commentId,
            body: replyTextValue,
            userId: currentUser.userId,
        }
        submit(object)
    }
    const handleDiscard = () => {
        setReplyOpen(false)
        setReplyTextValue(initialText)
    }

    const LikeIcon = getConceptLikedIcon(likeState)
    const clientSideReplies = comment.replies
        ? [...comment.replies].sort((a, b) => {
              const aTime: any = new Date(a.createdAt.formatted)
              const bTime: any = new Date(b.createdAt.formatted)
              return aTime - bTime
          })
        : []
    return (
        <Box>
            <Box className={classes.root}>
                <Box className={classes.authorContainer}>
                    <Avatar
                        className={classes.avatar}
                        alt={comment.user?.firstName}
                        src={comment.user?.imageUrl}
                    />
                    <Link
                        to={`/user/${comment.user?.username}/home`}
                        component={LinkRouter}
                        style={{ cursor: "pointer" }}
                    >
                        <Typography variant="body2">
                            {comment.user?.firstName} {comment.user?.lastName}
                        </Typography>
                    </Link>
                </Box>
                <Box className={classes.commentContainer}>
                    <RichTextEditor
                        editorId={`Comment_${comment.commentId}`}
                        isReadOnly={true}
                        baseFunctionality={true}
                        initialValue={comment.body}
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Box className={classes.date}>
                        {dayjs(comment.createdAt.formatted).fromNow() + " "}
                    </Box>
                    <Box>
                        <Button
                            style={{
                                minWidth: "fit-content",
                                marginLeft: "8px",
                            }}
                            onClick={handleLiked}
                            className={classes.likeButton}
                        >
                            <LikeIcon className={classes.labelStylesIcon} />{" "}
                            <span className={classes.labelText}>
                                {likeLength}
                            </span>
                        </Button>
                    </Box>
                </Box>
                <Divider />
                {comment.replies && comment.replies.length > 0 && (
                    <Box>
                        {viewReplies ? (
                            <Button
                                onClick={() => {
                                    setViewReplies(false)
                                }}
                            >
                                <SubdirectoryArrowRightIcon
                                    className={classes.labelStylesIcon}
                                />
                                <span className={classes.labelText}>
                                    {t("hide", "Hide")}{" "}
                                    {comment.replies?.length}{" "}
                                    {t("replies", "Replies")}
                                </span>
                            </Button>
                        ) : (
                            <Button
                                onClick={() => {
                                    setViewReplies(true)
                                }}
                            >
                                <SubdirectoryArrowRightIcon
                                    className={classes.labelStylesIcon}
                                />
                                <span className={classes.labelText}>
                                    {t("view", "View")}{" "}
                                    {comment.replies?.length}{" "}
                                    {t("replies", "Replies")}
                                </span>
                            </Button>
                        )}
                    </Box>
                )}
                {viewReplies &&
                    comment.replies?.length > 0 &&
                    clientSideReplies.map((comment) => {
                        return (
                            <Box
                                key={comment.commentId}
                                className={classes.reply}
                            >
                                <SingularComment
                                    liked={
                                        comment.likedBy.filter(
                                            (e) =>
                                                e.userId === currentUser.userId
                                        ).length > 0
                                    }
                                    comment={comment}
                                    conceptId={conceptId}
                                />
                            </Box>
                        )
                    })}
                {comment.replies && !replyOpen && (
                    <Box>
                        <Button
                            size="small"
                            onClick={() => {
                                setReplyOpen(true)
                            }}
                            color="primary"
                            className={classes.replyButton}
                        >
                            Reply
                        </Button>
                    </Box>
                )}
                {replyOpen && (
                    <>
                        <Box className={classes.replyContainer}>
                            <Box>
                                <RichTextEditor
                                    editorId={`New_Comment_Reply_${
                                        comment.commentId
                                    }_${comment.replies.length + 1}`}
                                    isReadOnly={false}
                                    placeholderText="Write a reply..."
                                    onChange={setReplyTextValue}
                                    baseFunctionality={true}
                                    initialValue={replyTextValue}
                                />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                marginTop={1}
                            >
                                <Button onClick={handleDiscard} size="small">
                                    {t("discard", "Discard")}
                                </Button>
                                {replyTextValue &&
                                    replyTextValue !== initialText && (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={handleSubmission}
                                            size="small"
                                        >
                                            {t("submit", "Submit")}
                                        </Button>
                                    )}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    )
}
