import React, { useState, useEffect } from "react"
import {
    Box,
    Divider,
    Paper,
    ClickAwayListener,
    Fade,
    ListItem,
    Popper,
    ListItemText,
    ListItemIcon,
    Typography,
    List,
    IconButton,
    TextField,
    Tooltip,
    useTheme,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import { useWidgets } from "./useWidgets"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { WidgetTypeName } from "./useWidgetTypes"
import { useWidgetTemplates } from "./useWidgetTemplates"
import TemplateOption from "./TemplateOption"
import { toCapitalizedWords } from "../../util/fns"
import ConceptListWidgetOptions from "./ConceptListWidgetOptions"
import ExerciseWidgetOptions from "./ExerciseWidgetOptions"
import ConceptWidgetOptions from "./ConceptWidgetOptions"
import { ICONS_REPOSITORY } from "./useWidgetTypes"
import CriteriaFieldWidgetOptions from "./CriteriaFieldWidgetOptions"
import WidgetConfigModal from "./widgets/common/WidgetConfigModal"
const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },
    listContainer: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid " + theme.palette.divider,
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
    selected: {
        backgroundColor: theme.palette.action.selected,
    },
    breadCrumbs: {
        color: theme.palette.text.secondary,
        fontSize: 12,
        padding: theme.spacing(0.5),
        paddingBottom: 0,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    paper: {
        width: "40em",
        padding: theme.spacing(1),
        maxHeight: "500px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
}))

type Props = {
    dashboardId: string | null
    enableEditing: () => void
}

export const WidgetStore = (props: Props) => {
    const classes = useStyles()
    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const theme = useTheme()
    const [selectedType, setSelectedType] = useState(null)
    const { widgetTemplates } = useWidgetTemplates(selectedType?.id)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { widgetTypes, onAddNewWidget } = useWidgets(props.dashboardId)

    const open = Boolean(anchorEl)

    const initialSelection = widgetTypes?.filter(
        (type) => type.component?.name === WidgetTypeName.BlankNotes
    )?.[0]
    useEffect(() => {
        if (initialSelection && !selectedType) {
            setSelectedType(initialSelection)
        }
    }, [initialSelection, selectedType])

    const selectedTypeConfig = JSON.parse(selectedType?.defaultConfig ?? "{}")

    const sourceDependent = !!selectedTypeConfig?.source
    return (
        <>
            <Tooltip title="Add Widgets">
                <IconButton onClick={handleButtonClick}>
                    <AddCircleIcon />
                </IconButton>
            </Tooltip>
            <Popper
                placement="left-start"
                anchorEl={anchorEl}
                open={open}
                transition
                className={classes.popper}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className={classes.paper} elevation={8}>
                                <Typography variant="body1">
                                    Widget Options
                                </Typography>

                                <Divider />
                                <Box display="flex" mt={1} overflow="hidden">
                                    <Box
                                        width="100%"
                                        display="flex"
                                        overflow="hidden"
                                    >
                                        <List
                                            className={classes.listContainer}
                                            disablePadding
                                            style={{ width: "100%" }}
                                        >
                                            {widgetTypes?.map((item, index) => {
                                                const parsedProperties = JSON.parse(
                                                    item.defaultConfig
                                                )
                                                const icon =
                                                    ICONS_REPOSITORY[item?.name]

                                                return (
                                                    <ListItem
                                                        className={
                                                            selectedType?.id ===
                                                            item.id
                                                                ? classes.selected
                                                                : ""
                                                        }
                                                        key={index}
                                                        button
                                                        onClick={() =>
                                                            setSelectedType(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            {icon}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    {
                                                                        parsedProperties.label
                                                                    }
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </Box>
                                    {!!selectedType &&
                                        (WidgetTypeName[selectedType?.name] ===
                                            WidgetTypeName.CriteriaRadar ||
                                        WidgetTypeName[selectedType?.name] ===
                                            WidgetTypeName.BubbleChart ? (
                                            <WidgetConfigModal
                                                open={true}
                                                widgetType={selectedType}
                                                onClose={() => {
                                                    setSelectedType(null)
                                                    setAnchorEl(null)
                                                }}
                                            />
                                        ) : (
                                            <Box
                                                width="100%"
                                                display="flex"
                                                flexDirection="column"
                                            >
                                                {sourceDependent && (
                                                    <Box p={1}>
                                                        <Autocomplete
                                                            value={
                                                                selectedTypeConfig?.source
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    theme
                                                                        .palette
                                                                        .background
                                                                        .paper,
                                                            }}
                                                            size="small"
                                                            onChange={(
                                                                event: any,
                                                                newValue: string,
                                                                reason: any
                                                            ) => {
                                                                let newConfig = {
                                                                    ...selectedTypeConfig,
                                                                    source: newValue,
                                                                }
                                                                setSelectedType(
                                                                    {
                                                                        ...selectedType,
                                                                        defaultConfig: JSON.stringify(
                                                                            newConfig
                                                                        ),
                                                                    }
                                                                )
                                                            }}
                                                            disableClearable
                                                            openOnFocus
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                toCapitalizedWords(
                                                                    option
                                                                )
                                                            }
                                                            options={
                                                                selectedType
                                                                    ?.schema
                                                                    ?.properties
                                                                    ?.source
                                                                    ?.enum ?? []
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    label="Widget Source"
                                                                    placeholder="Select Source"
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                )}
                                                {WidgetTypeName[
                                                    selectedType?.name
                                                ] ===
                                                    WidgetTypeName.RankingExercise ||
                                                WidgetTypeName[
                                                    selectedType?.name
                                                ] ===
                                                    WidgetTypeName.ScoringExercise ? (
                                                    <ExerciseWidgetOptions
                                                        onAddNewWidget={(
                                                            exerciseId: string
                                                        ) => {
                                                            const newConfig = JSON.stringify(
                                                                {
                                                                    ...selectedTypeConfig,
                                                                    exerciseId,
                                                                }
                                                            )

                                                            onAddNewWidget(
                                                                WidgetTypeName[
                                                                    selectedType
                                                                        .name
                                                                ],
                                                                newConfig,
                                                                selectedType.defaultValue
                                                            )
                                                        }}
                                                        selectedType={
                                                            selectedType
                                                        }
                                                    />
                                                ) : WidgetTypeName[
                                                      selectedType?.name
                                                  ] ===
                                                      WidgetTypeName.Roadmap ||
                                                  WidgetTypeName[
                                                      selectedType?.name
                                                  ] ===
                                                      WidgetTypeName.ConceptList ? (
                                                    <ConceptListWidgetOptions
                                                        onAddNewWidget={(
                                                            conceptListId: string
                                                        ) => {
                                                            const newConfig = JSON.stringify(
                                                                {
                                                                    ...selectedTypeConfig,
                                                                    conceptListId,
                                                                }
                                                            )

                                                            onAddNewWidget(
                                                                WidgetTypeName[
                                                                    selectedType
                                                                        .name
                                                                ],
                                                                newConfig,
                                                                selectedType.defaultValue
                                                            )
                                                        }}
                                                        selectedType={
                                                            selectedType
                                                        }
                                                    />
                                                ) : WidgetTypeName[
                                                      selectedType?.name
                                                  ] ===
                                                  WidgetTypeName.Concept ? (
                                                    <ConceptWidgetOptions
                                                        onAddNewWidget={(
                                                            conceptId,
                                                            view
                                                        ) => {
                                                            const newConfig = JSON.stringify(
                                                                {
                                                                    ...selectedTypeConfig,
                                                                    conceptId,
                                                                    view,
                                                                }
                                                            )
                                                            onAddNewWidget(
                                                                WidgetTypeName[
                                                                    selectedType
                                                                        .name
                                                                ],
                                                                newConfig,
                                                                selectedType.defaultValue
                                                            )
                                                        }}
                                                        selectedType={
                                                            selectedType
                                                        }
                                                    />
                                                ) : WidgetTypeName[
                                                      selectedType?.name
                                                  ] ===
                                                  WidgetTypeName.CriteriaField ? (
                                                    <CriteriaFieldWidgetOptions
                                                        onAddNewWidget={(
                                                            criteria,
                                                            label
                                                        ) => {
                                                            const newConfig = JSON.stringify(
                                                                {
                                                                    ...selectedTypeConfig,
                                                                    label,
                                                                    criteria,
                                                                }
                                                            )
                                                            onAddNewWidget(
                                                                WidgetTypeName[
                                                                    selectedType
                                                                        .name
                                                                ],
                                                                newConfig,
                                                                selectedType.defaultValue
                                                            )
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        <List
                                                            disablePadding
                                                            className={
                                                                classes.listContainer
                                                            }
                                                        >
                                                            <ListItem
                                                                button
                                                                onClick={() =>
                                                                    onAddNewWidget(
                                                                        WidgetTypeName[
                                                                            selectedType
                                                                                .name
                                                                        ],
                                                                        selectedType.defaultConfig,
                                                                        selectedType.defaultValue
                                                                    )
                                                                }
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography variant="body2">
                                                                            Default
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        <Box
                                                            ml={1.5}
                                                            mt={1}
                                                            mb={-0.5}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                            >
                                                                Saved Templates
                                                            </Typography>
                                                        </Box>
                                                        <List
                                                            className={
                                                                classes.listContainer
                                                            }
                                                            style={{
                                                                flexGrow: 1,
                                                            }}
                                                            disablePadding
                                                        >
                                                            {widgetTemplates?.length >
                                                            0 ? (
                                                                widgetTemplates.map(
                                                                    (
                                                                        template,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Box
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <TemplateOption
                                                                                    onAddNewWidget={() =>
                                                                                        onAddNewWidget(
                                                                                            WidgetTypeName[
                                                                                                selectedType
                                                                                                    .name
                                                                                            ],
                                                                                            template.config,
                                                                                            template.value
                                                                                        )
                                                                                    }
                                                                                    template={
                                                                                        template
                                                                                    }
                                                                                />
                                                                            </Box>
                                                                        )
                                                                    }
                                                                )
                                                            ) : (
                                                                <Box
                                                                    p={1}
                                                                    textAlign="center"
                                                                >
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="textSecondary"
                                                                    >
                                                                        No
                                                                        templates
                                                                        created
                                                                        yet...
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </List>
                                                    </>
                                                )}
                                            </Box>
                                        ))}
                                </Box>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    )
}
