import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { GET_WIDGET_TYPES } from "./graphql"
import { Widget } from "./useWidgets"
import ConceptListWidget from "./widgets/ConceptListWidget"
import UnknownWidget from "./widgets/UnknownWidget"
import HypeChartWidget from "./widgets/HypeChartWidget"
import ExerciseWidget from "./widgets/ExerciseWidget"
import RichTextEditorWidget from "./widgets/RichTextEditorWidget"
import HeadingWidget from "./widgets/HeadingWidget"
import { JSONSchema4Type } from "json-schema"
import ProductChartWidget from "./widgets/ProductChartWidget"
import ConceptFeedWidget from "./widgets/ConceptFeedWidget"
import RoadmapWidget from "./widgets/RoadmapWidget"
import ConceptWidget from "./widgets/ConceptWidget"
import BubbleChartWidget from "./widgets/BubbleChartWidget"
import CriteriaFieldWidget from "./widgets/CriteriaFieldWidget"
import CriteriaRadarWidget from "./widgets/CriteriaRadarWidget"
import {
    GetAllWidgetTypesQuery,
    GetAllWidgetTypesQueryVariables,
} from "./__generated__/graphql"
import {
    ConceptFeedSchema,
    UnknownSchema,
    ChartSchema,
    RichTextEditorSchema,
    HeadingSchema,
    ConceptListSchema,
    ExerciseSchema,
    ConceptSchema,
    CriteriaFieldSchema,
    CriteriaRadarSchema,
    BubbleChartSchema,
} from "./widgets/common/WidgetSchemas"
import DnsIcon from "@material-ui/icons/Dns"
import TimelineIcon from "@material-ui/icons/Timeline"
import ListIcon from "@material-ui/icons/List"
import MapIcon from "@material-ui/icons/Map"
import WidgetsIcon from "@material-ui/icons/Widgets"
import TextFieldsIcon from "@material-ui/icons/TextFields"
import { LooksOne } from "@styled-icons/material/LooksOne"
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter"
import ViewDayIcon from "@material-ui/icons/ViewDay"
import { ExerciseCategory } from "../../__generated__/types"
import TrackChangesIcon from "@material-ui/icons/TrackChanges"
import BubbleChartIcon from "@material-ui/icons/BubbleChart"
type WidgetBaseProps = {
    widget: Widget
    editing: boolean
    isDialog?: boolean
}

export type WidgetTypeComponent = {
    (props: WidgetBaseProps): JSX.Element
    defaultLayout?: {
        /**
         * Width in grid units.
         */
        w?: number

        /**
         * Height in grid units.
         */
        h?: number

        /**
         * Minimum width in grid units.
         */
        minW?: number

        /**
         * Maximum width in grid units.
         */
        maxW?: number

        /**
         * Minimum height in grid units.
         */
        minH?: number

        /**
         * Maximum height in grid units.
         */
        maxH?: number
    }
}

export enum WidgetTypeName {
    ConceptsFeed = "ConceptsFeed",
    HypeChart = "HypeChart",
    ProductChart = "ProductChart",
    BubbleChart = "BubbleChart",
    ConceptList = "ConceptList",
    RankingExercise = "RankingExercise",
    ScoringExercise = "ScoringExercise",
    Concept = "Concept",
    Roadmap = "Roadmap",
    Heading = "Heading",
    BlankNotes = "BlankNotes",
    CriteriaField = "CriteriaField",
    CriteriaRadar = "CriteriaRadar",
    Unknown = "Unknown",
}

export type WidgetType = {
    id: string
    name: WidgetTypeName
    schema?: WidgetTypeSchemaName
    defaultValue: string
    defaultConfig: string
    component: WidgetTypeComponentName
}

export enum WidgetTypeComponentName {
    ConceptListWidget = "ConceptListWidget",
    HypeChartWidget = "HypeChartWidget",
    ProductChartWidget = "ProductChartWidget",
    BubbleChartWidget = "BubbleChartWidget",
    RoadmapWidget = "RoadmapWidget",
    HeadingWidget = "HeadingWidget",
    ConceptFeedWidget = "ConceptFeedWidget",
    RichTextEditorWidget = "RichTextEditorWidget",
    ExerciseWidget = "ExerciseWidget",
    ConceptWidget = "ConceptWidget",
    CriteriaFieldWidget = "CriteriaFieldWidget",
    CriteriaRadarWidget = "CriteriaRadarWidget",
    Unknown = "Unknown",
}
export const EXERCISE_CATEGORIES = {
    [WidgetTypeName.RankingExercise]: ExerciseCategory.RANKING,
    [WidgetTypeName.ScoringExercise]: ExerciseCategory.SCORING,
}
export const WIDGETS_REPOSITORY: {
    [key in WidgetTypeComponentName]: WidgetTypeComponent
} = {
    [WidgetTypeComponentName.ConceptListWidget]: ConceptListWidget,
    [WidgetTypeComponentName.HypeChartWidget]: HypeChartWidget,
    [WidgetTypeComponentName.ProductChartWidget]: ProductChartWidget,
    [WidgetTypeComponentName.BubbleChartWidget]: BubbleChartWidget,
    [WidgetTypeComponentName.RoadmapWidget]: RoadmapWidget,
    [WidgetTypeComponentName.ConceptFeedWidget]: ConceptFeedWidget,
    [WidgetTypeComponentName.HeadingWidget]: HeadingWidget,
    [WidgetTypeComponentName.RichTextEditorWidget]: RichTextEditorWidget,
    [WidgetTypeComponentName.ExerciseWidget]: ExerciseWidget,
    [WidgetTypeComponentName.ConceptWidget]: ConceptWidget,
    [WidgetTypeComponentName.CriteriaFieldWidget]: CriteriaFieldWidget,
    [WidgetTypeComponentName.CriteriaRadarWidget]: CriteriaRadarWidget,
    [WidgetTypeComponentName.Unknown]: UnknownWidget,
}
export enum WidgetTypeSchemaName {
    ConceptFeedSchema = "ConceptFeedSchema",
    ChartSchema = "ChartSchema",
    BubbleChartSchema = "BubbleChartSchema",
    RichTextEditorSchema = "RichTextEditorSchema",
    ConceptListSchema = "ConceptListSchema",
    HeadingSchema = "HeadingSchema",
    ExerciseSchema = "ExerciseSchema",
    ConceptSchema = "ConceptSchema",
    CriteriaFieldSchema = "CriteriaFieldSchema",
    CriteriaRadarSchema = "CriteriaRadarSchema",
    UnknownSchema = "UnknownSchema",
}
export const SCHEMA_REPOSITORY: {
    [key in WidgetTypeSchemaName]: JSONSchema4Type
} = {
    [WidgetTypeSchemaName.ConceptFeedSchema]: ConceptFeedSchema,
    [WidgetTypeSchemaName.ChartSchema]: ChartSchema,
    [WidgetTypeSchemaName.BubbleChartSchema]: BubbleChartSchema,
    [WidgetTypeSchemaName.ConceptListSchema]: ConceptListSchema,
    [WidgetTypeSchemaName.RichTextEditorSchema]: RichTextEditorSchema,
    [WidgetTypeSchemaName.HeadingSchema]: HeadingSchema,
    [WidgetTypeSchemaName.ExerciseSchema]: ExerciseSchema,
    [WidgetTypeSchemaName.ConceptSchema]: ConceptSchema,
    [WidgetTypeSchemaName.CriteriaFieldSchema]: CriteriaFieldSchema,
    [WidgetTypeSchemaName.CriteriaRadarSchema]: CriteriaRadarSchema,
    [WidgetTypeSchemaName.UnknownSchema]: UnknownSchema,
}
export const ICONS_REPOSITORY: {
    [key in WidgetTypeName]: React.ReactNode
} = {
    [WidgetTypeName.ConceptsFeed]: <DnsIcon fontSize="small" />,
    [WidgetTypeName.HypeChart]: <TimelineIcon fontSize="small" />,
    [WidgetTypeName.ProductChart]: <TimelineIcon fontSize="small" />,
    [WidgetTypeName.BubbleChart]: <BubbleChartIcon fontSize="small" />,
    [WidgetTypeName.ConceptList]: <ListIcon fontSize="small" />,
    [WidgetTypeName.Roadmap]: <MapIcon fontSize="small" />,
    [WidgetTypeName.BlankNotes]: <TextFieldsIcon fontSize="small" />,
    [WidgetTypeName.RankingExercise]: <FitnessCenterIcon fontSize="small" />,
    [WidgetTypeName.ScoringExercise]: <FitnessCenterIcon fontSize="small" />,
    [WidgetTypeName.Heading]: (
        <LooksOne style={{ width: "1.5em", height: "1.5em" }} />
    ), // not sure of best way to size this
    [WidgetTypeName.Concept]: <ViewDayIcon fontSize="small" />,
    [WidgetTypeName.CriteriaField]: <WidgetsIcon fontSize="small" />,
    [WidgetTypeName.CriteriaRadar]: <TrackChangesIcon fontSize="small" />,
    [WidgetTypeName.Unknown]: <WidgetsIcon fontSize="small" />,
}
export function useWidgetTypes() {
    const { data: widgetTypesData } = useQuery<
        GetAllWidgetTypesQuery,
        GetAllWidgetTypesQueryVariables
    >(GET_WIDGET_TYPES, {
        fetchPolicy: "cache-first",
    })
    const [widgetTypes, setWidgetTypes] = useState([])
    const [isInitialized, setIsInitialized] = useState(false)
    const widgetTypesList = widgetTypesData?.WidgetType

    useEffect(() => {
        if (widgetTypesList) {
            const validWidgets = widgetTypesList
                .filter((widget) => {
                    if (
                        WIDGETS_REPOSITORY[
                            widget.component as WidgetTypeComponentName
                        ]
                    )
                        return true
                    if (
                        SCHEMA_REPOSITORY[widget.schema as WidgetTypeSchemaName]
                    )
                        return true
                    if (process.env.NODE_ENV !== "production")
                        console.warn("Widget not found", widget.name)
                    return false
                })
                .map((widget) => ({
                    id: widget.id,
                    name: widget.name as WidgetTypeName,
                    schema:
                        SCHEMA_REPOSITORY[
                            widget.schema as WidgetTypeSchemaName
                        ],
                    component:
                        WIDGETS_REPOSITORY[
                            widget.component as WidgetTypeComponentName
                        ],
                    defaultValue: widget.defaultValue,
                    defaultConfig: widget.defaultConfig,
                }))

            setWidgetTypes(validWidgets)
            setIsInitialized(true)
        }
    }, [widgetTypesList, widgetTypesData])

    return {
        isInitialized,
        widgetTypes,
    }
}
