import { gql } from "@apollo/client"

export const CREATE_CONCEPT = gql`
    mutation createConceptComplete(
        $concept: ConceptInput
        $userId: ID!
        $tags: [String]
    ) {
        CreateConcept(concept: $concept, userId: $userId, tags: $tags) {
            id
            __typename
            title
            type
            summary
            description
            isPublic
            imageUrl
            createdAt {
                formatted
            }
            likedBy {
                userId
                username
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
                conceptsCreated(
                    filter: { type_not_in: ["Requirement, Feature"] }
                    first: 10
                ) {
                    id
                    title
                    type
                    summary
                    createdAt {
                        formatted
                    }
                }
            }
            connections {
                id
                title
                type
            }

            tags(orderBy: name_asc) {
                name
            }
        }
    }
`

export const UPDATE_CONCEPT = gql`
    mutation updateConcept($concept: ConceptUpdateInput, $userId: ID!) {
        UpdateConcept(concept: $concept, userId: $userId)
    }
`
export const DELETE_CONCEPT = gql`
    mutation DeleteConcept($conceptId: ID!) {
        DeleteConcept(id: $conceptId) {
            id
        }
    }
`
/// CONNECT CONCEPTS
export const CONNECT_CONCEPTS = gql`
    mutation AddConceptConnections($fromId: ID!, $toId: ID!) {
        AddConceptConnections(from: { id: $fromId }, to: { id: $toId }) {
            from {
                id
                title
                summary
                type
                imageUrl
                connections {
                    id
                }
            }
            to {
                id
                type
                connections {
                    id
                }
            }
        }
    }
`
export const DISCONNECT_CONCEPTS = gql`
    mutation RemoveConceptConnections($fromId: ID!, $toId: ID!) {
        RemoveConceptConnections(from: { id: $fromId }, to: { id: $toId }) {
            from {
                id
                title
                summary
                type
                imageUrl
                connections {
                    id
                }
            }
            to {
                id
                title
                summary
                type
                imageUrl
                connections {
                    id
                }
            }
        }
    }
`

export const ADD_CONCEPT_WEB_LINK = gql`
    mutation addConceptWebLink($from: _ConceptInput!, $to: _LinkInput!) {
        AddConceptWebLink(from: $from, to: $to) {
            from {
                id
                title
            }
            to {
                id
                url
            }
        }
    }
`

export const ADD_LIST_PARENT_CONCEPT = gql`
    mutation AddConceptListParentConcept($fromId: ID!, $toId: ID!) {
        AddConceptListParentConcept(from: { id: $fromId }, to: { id: $toId }) {
            from {
                id
            }
            to {
                id
                parentConcept {
                    id
                }
            }
        }
    }
`
