import { Box, Paper } from "@material-ui/core"
import { WidgetTypeComponent } from "../useWidgetTypes"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import ConceptRoadmap from "../../ConceptRoadmap"

type WidgetConfig = {
    conceptListId: string
    description: string
    label: string
}

const RoadmapWidget: WidgetTypeComponent = (props) => {
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    return (
        <Box
            component={Paper}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            pb={2}
            key={props.widget.id}
        >
            <WidgetHeader
                label={config?.label}
                widget={props.widget}
                editing={props.editing}
            />
            {isInitialized && config?.conceptListId && (
                <ConceptRoadmap listId={config?.conceptListId} />
            )}
        </Box>
    )
}

export default RoadmapWidget
