import React, { ReactElement, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Theme, useTheme } from "@material-ui/core"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useMutation, useQuery } from "@apollo/client"
import {
    CONCEPT_LIST_UPDATE,
    REMOVE_CONCEPT_FROM_LIST,
} from "../graphql/conceptListQueries"
import {
    UpdateConceptListMutation,
    UpdateConceptListMutationVariables,
    RemoveConceptInListsMutation,
    RemoveConceptInListsMutationVariables,
} from "../graphql/__generated__/conceptListQueries"
import { CONCEPT_CHILDREN_BY_IDS } from "../graphql/queries"
import {
    ConceptChildrenByIdsQuery,
    ConceptChildrenByIdsQueryVariables,
} from "../graphql/__generated__/queries"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import ConceptWorkspaceItem from "./ConceptWorkspaceItem"
import { Loading } from "./Loading"
import { Concept, ConceptList, _ConceptFilter } from "../__generated__/types"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { useMediaQuery } from "@material-ui/core"
const useStyles = makeStyles((theme: Theme) => ({
    treeItem: {
        backgroundColor: "transparent !important",
        padding: 0,
        overflow: "hidden",
    },
    actionHover: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover + " !important",
        },
    },
    iconContainer: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover + " !important",
        },
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        alignItems: "center",
        margin: 0,
        width: "18px",
    },
    inactiveIconContainer: {
        cursor: "default",
        margin: 0,
        width: "18px",
    },
    content: {
        alignItems: "stretch",
    },
}))

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

interface Props {
    list?: ConceptList
    editable: boolean
    closeDrawer?: () => void
    conceptFilter?: _ConceptFilter
}

export default function SortableRecursiveList(props: Props): ReactElement {
    const { list } = props

    const [conceptList, setConceptList] = useState<ConceptList | null>(null)
    const [concepts, setConcepts] = useState<Concept[] | null>(null)
    const [listUpdate] = useMutation<
        UpdateConceptListMutation,
        UpdateConceptListMutationVariables
    >(CONCEPT_LIST_UPDATE)
    const [removeConceptFromList] = useMutation<
        RemoveConceptInListsMutation,
        RemoveConceptInListsMutationVariables
    >(REMOVE_CONCEPT_FROM_LIST)
    const setListData = (ConceptList) => {
        let orderedConcepts = []
        let unorderedConcepts = []
        let conceptsById = {}
        const { concepts, conceptIds } = ConceptList
        const ids = conceptIds || concepts.map((c) => c.id)
        concepts.forEach((c) => {
            conceptsById[c.id] = c
            let conceptIdx = ids.indexOf(c.id)
            if (conceptIdx === -1) {
                unorderedConcepts.push(c)
            } else {
                orderedConcepts[ids.indexOf(c.id)] = c
            }
        })
        let allConcepts = [...orderedConcepts, ...unorderedConcepts].filter(
            (c) => !!c
        )
        setConcepts(allConcepts)
        setConceptList({
            ...ConceptList,
            concepts: allConcepts,
        })
    }

    useEffect(() => {
        if (list) {
            setListData(list)
        }
    }, [list])

    const removeConcept = async (sourceIndex, draggableId) => {
        let position = sourceIndex
        let newArray = Array.from(concepts)
        newArray.splice(position, 1)
        const newList = {
            id: conceptList.id,
            conceptIds: newArray?.map((c) => c.id),
            concepts: newArray,
        }

        setListData({
            ...conceptList,
            ...newList,
        })
        await removeConceptFromList({
            variables: {
                from: { id: draggableId },
                to: { id: conceptList.id },
            },
        })
        await listUpdate({
            variables: {
                ...{
                    id: newList.id,
                    conceptIds: newList.conceptIds,
                },
            },
        })
    }

    const move = async (source, destination) => {
        if (
            source !== destination &&
            Math.sign(destination) !== -1 &&
            conceptList.concepts.length - destination >= 1
        ) {
            const conceptArray = reorder(
                conceptList.concepts,
                source,
                destination
            )
            const newList = {
                id: conceptList.id,
                conceptIds: conceptArray.map((c: any) => c.id),
            }

            setListData({
                ...conceptList,
                ...newList,
            })
            await listUpdate({
                variables: {
                    ...newList,
                },
            })
        }
    }

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return
        }

        const conceptArray = reorder(
            conceptList.concepts,
            result.source.index,
            result.destination.index
        )

        const newList = {
            id: conceptList.id,
            conceptIds: conceptArray.map((c: any) => c.id),
        }

        setListData({
            ...conceptList,
            ...newList,
        })
        await listUpdate({
            variables: {
                ...newList,
            },
        })
    }
    return (
        <>
            {conceptList && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={conceptList.id}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {concepts?.map((concept, index) => (
                                    <Draggable
                                        isDragDisabled={!props.editable}
                                        key={concept.id}
                                        draggableId={concept.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <TreeView
                                                    defaultCollapseIcon={
                                                        <ExpandMoreIcon fontSize="small" />
                                                    }
                                                    defaultExpandIcon={
                                                        <ChevronRightIcon fontSize="small" />
                                                    }
                                                >
                                                    <RecursiveTreeItem
                                                        key={concept.id}
                                                        node={concept}
                                                        dragHandleProps={
                                                            provided.dragHandleProps
                                                        }
                                                        isDragging={
                                                            snapshot.isDragging
                                                        }
                                                        editable={
                                                            props.editable
                                                        }
                                                        depth={0}
                                                        move={move}
                                                        removeConcept={
                                                            removeConcept
                                                        }
                                                        index={index}
                                                        closeDrawer={
                                                            props.closeDrawer
                                                        }
                                                        conceptFilter={
                                                            props.conceptFilter
                                                        }
                                                    />
                                                </TreeView>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </>
    )
}

interface RecursiveTreeItemProps {
    node: Concept
    depth: number
    dragHandleProps?: DraggableProvidedDragHandleProps
    isDragging?: boolean
    move?: (index: number, destination: number) => void
    removeConcept?: (index: number, id: string) => void
    index?: number
    editable: boolean
    closeDrawer?: () => void
    conceptFilter?: _ConceptFilter
}
const RecursiveTreeItem = (props: RecursiveTreeItemProps) => {
    const {
        node,
        depth = 0,
        isDragging,
        dragHandleProps,
        move,
        removeConcept,
        index,
        editable,
        closeDrawer,
    } = props
    const classes = useStyles()
    const childrenIds = node.children?.map((x) => {
        return x.id
    })
    let filter: _ConceptFilter = {
        id_in: childrenIds,
    }

    if (props.conceptFilter) {
        filter = {
            ...filter,
            ...props.conceptFilter,
        }
    }
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { loading, data } = useQuery<
        ConceptChildrenByIdsQuery,
        ConceptChildrenByIdsQueryVariables
    >(CONCEPT_CHILDREN_BY_IDS, {
        variables: {
            conceptFilter: filter,
        },
        skip: !childrenIds || childrenIds?.length === 0,
    })
    const children = data?.Concept ?? []
    return (
        <TreeItem
            nodeId={node.id + depth}
            classes={{
                label: classes.treeItem,
                iconContainer:
                    children.length > 0
                        ? classes.iconContainer
                        : classes.inactiveIconContainer,
                content: classes.content,
            }}
            onLabelClick={(e) => {
                e.preventDefault()
                if (mobile && !!closeDrawer) {
                    closeDrawer()
                }
            }}
            key={node.id + depth}
            label={
                <ConceptWorkspaceItem
                    item={node}
                    dragHandleProps={dragHandleProps}
                    isDragging={isDragging}
                    sortable={editable && depth === 0}
                    depth={depth}
                    move={move}
                    removeConcept={removeConcept}
                    index={index}
                />
            }
        >
            {loading ? (
                <Loading size={25} />
            ) : Array.isArray(children) ? (
                children.map((node) => (
                    <RecursiveTreeItem
                        key={node.id + depth}
                        node={node}
                        depth={depth + 1}
                        editable={editable}
                        closeDrawer={closeDrawer}
                        conceptFilter={props.conceptFilter}
                    />
                ))
            ) : null}
        </TreeItem>
    )
}
