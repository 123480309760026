import { gql } from "@apollo/client"
export const WORKSPACE_QUERY = gql`
    query UserWorkspaces($userId: ID!) {
        User(userId: $userId) {
            userId
            imageUrl
            firstName
            lastName
            username
            conceptLists(filter: { type: USER_WORKSPACE }) {
                id
                title
                type
            }
            teams {
                teamId
                name
                imageUrl
                conceptLists(filter: { type: TEAM_WORKSPACE }) {
                    id
                    title
                    type
                }
            }
        }
    }
`

export const ADD_CONCEPT_CHILD = gql`
    mutation AddConceptChild($childId: ID!, $parentId: ID!) {
        AddConceptChildren(from: { id: $childId }, to: { id: $parentId }) {
            from {
                id
                title
                summary
                imageUrl
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                parent {
                    id
                    title
                    summary
                    imageUrl
                    category {
                        id
                        name
                        isRoot
                    }
                    rootCategory {
                        id
                        name
                        isRoot
                    }
                }
            }
            to {
                id
                title
                summary
                imageUrl
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                children {
                    id
                    title
                    summary
                    imageUrl
                    category {
                        id
                        name
                        isRoot
                    }
                    rootCategory {
                        id
                        name
                        isRoot
                    }
                }
            }
        }
    }
`

export const REMOVE_CONCEPT_CHILD = gql`
    mutation RemoveConceptChild($childId: ID!, $parentId: ID!) {
        RemoveConceptChildren(from: { id: $childId }, to: { id: $parentId }) {
            from {
                id
                title
                summary
                imageUrl
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                parent {
                    id
                    title
                    summary
                    imageUrl
                    category {
                        id
                        name
                        isRoot
                    }
                    rootCategory {
                        id
                        name
                        isRoot
                    }
                }
            }
            to {
                id
                title
                summary
                imageUrl
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                children {
                    id
                    title
                    summary
                    imageUrl
                    category {
                        id
                        name
                        isRoot
                    }
                    rootCategory {
                        id
                        name
                        isRoot
                    }
                }
            }
        }
    }
`
