import React, { useState } from "react"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"
import ConceptForm from "./ConceptForm"
import { Quote } from "./Quote"
import LongIdeaForm from "./LongConceptForm"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
const entryFormWidth = 480
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: entryFormWidth,
        maxWidth: "100vw",
        flexShrink: 0,
    },
    snackbar: {
        color: theme.palette.primary.main,
    },
    quote: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        padding: "1rem",
        color: theme.palette.text.hint,
    },
}))

interface AddConceptDrawerProps {
    closeForm: () => void
    formOpen: boolean
}

const AddConceptDrawer = (props: AddConceptDrawerProps) => {
    const [snackbar, setSnackbar] = useState(false)
    const [snackbarConceptId, setSnackbarConceptId] = useState("")
    const [longForm, goToLongForm] = useState(false)
    const history = useHistory()
    const classes = useStyles()
    const { t } = useAwaitTranslation("concepts")
    const handleClose = () => {
        setSnackbar(false)
        setSnackbarConceptId("")
    }
    const openSnackbar = (conceptId) => {
        setSnackbarConceptId(conceptId)
        setSnackbar(true)
    }
    const handleSnackbarClick = () => {
        handleClose()
        history.push(`/concept/${snackbarConceptId}/home`)
    }
    return (
        <div>
            <Drawer
                className={classes.drawer}
                anchor="right"
                open={props.formOpen}
                classes={{
                    paper: classes.drawer,
                }}
            >
                <ConceptForm
                    closeForm={() => props.closeForm()}
                    openSnackbar={openSnackbar}
                    goToLongForm={() => {
                        goToLongForm(true)
                    }}
                />
                <div className={classes.quote}>
                    <Quote />
                </div>
            </Drawer>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                message="IDEA SUBMITTED!"
                open={snackbar}
                onClose={handleClose}
                autoHideDuration={5000}
                action={
                    <Button
                        onClick={handleSnackbarClick}
                        className={classes.snackbar}
                    >
                        {t("checkItOut", "CHECK IT OUT")} <ChevronRightIcon />
                    </Button>
                }
            />

            {longForm && (
                <LongIdeaForm
                    onClose={() => {
                        goToLongForm(false)
                        props.closeForm()
                    }}
                    openSnackbar={openSnackbar}
                />
            )}
        </div>
    )
}

export default AddConceptDrawer
