import { makeStyles, Theme, withStyles } from "@material-ui/core/styles"

export const useGlobalStyles = makeStyles((theme: Theme) => ({
    labelStylesIcon: {
        fontSize: "1em",
        verticalAlign: "middle",
    },
    count: {
        fontSize: "1rem",
        marginLeft: theme.spacing(0.5),
    },
    smallLabelStylesIcon: {
        opacity: "0.6",
        fontSize: "1em",
        verticalAlign: "middle",
    },
    smallCount: {
        opacity: "0.6",
        fontSize: "0.75rem",
        marginLeft: theme.spacing(0.75),
    },
    smallMenuIcon: {
        minWidth: theme.spacing(4),
    },
    smallMenuListItemText: {
        fontSize: theme.typography.body2.fontSize,
    },
}))

export const GlobalCss = withStyles((theme: Theme) => ({
    "@global": {
        ".MuiIconButton-root": {
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(1),
        },
        ".MuiAutocomplete-popupIndicator": {
            padding: theme.spacing(0.25),
        },
        ".MuiIconButton-sizeSmall": {
            padding: theme.spacing(0.25),
        },
    },
}))(() => null)
