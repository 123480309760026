import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import { useMutation } from "@apollo/client"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import { getConceptTypeIcon } from "../../util/ConceptTypeStyles"
import Dialog from "@material-ui/core/Dialog"
import { IconButton } from "@material-ui/core"
import Link from "@material-ui/core/Link"
import InputLabel from "@material-ui/core/InputLabel"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import FormControl from "@material-ui/core/FormControl"
import ConceptListHub from "./ConceptListHub"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Select from "@material-ui/core/Select"
import { useAuth } from "../../providers/AuthProvider"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import { Loading } from "../Loading"
import { UPDATE_CHECKLIST, DELETE_CHECKLIST } from "../../graphql/Checklist"
import {
    UpdateChecklistMutation,
    UpdateChecklistMutationVariables,
    DeleteChecklistMutation,
    DeleteChecklistMutationVariables,
} from "../../graphql/__generated__/Checklist"
import { Link as LinkRouter } from "react-router-dom"
import MenuItem from "@material-ui/core/MenuItem"
import { Checklist, Concept, ItemPrefixType } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import ClearIcon from "@material-ui/icons/Clear"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 0,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "80vh",
    },
    textField: {
        paddingLeft: theme.spacing(1),
        fontSize: theme.typography.body2.fontSize,
    },
    listbox: {
        overflow: "auto",
    },
    listTitle: {
        fontSize: theme.typography.body1.fontSize,
    },
    listType: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
    },
    summary: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
    },
    avatar: {
        width: "1em",
        height: "1em",
    },
    clone: {
        zIndex: 100,
        backgroundColor: theme.palette.background.paper,
    },
    sortableList: {
        borderTop: "0.5px solid " + theme.palette.divider,
        borderBottom: "0.5px solid " + theme.palette.divider,
    },
    tabs: {
        borderBottom: "1px solid " + theme.palette.divider,
    },
    scroller: {
        overflowY: "hidden",
    },
    linkMenu: {
        padding: theme.spacing(1.5),
    },
    modalTitle: {
        fontSize: theme.typography.h6.fontSize,
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",

        [theme.breakpoints.down("sm")]: {
            fontSize: theme.typography.body1.fontSize,
        },
        width: "100%",
    },
}))

interface ConceptListDialogProps {
    item: Concept
    editPermission: boolean
    onClose: () => void
}
export default function ConceptListDialog(props: ConceptListDialogProps) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t } = useAwaitTranslation("widgets")
    const TypeIcon = getConceptTypeIcon(props.item.rootCategory?.name)
    return (
        <Dialog
            open={true}
            fullWidth
            maxWidth="lg"
            onBackdropClick={() => props.onClose()}
        >
            <Box className={classes.root}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={mobile ? "column" : "row"}
                >
                    <Box className={classes.modalTitle}>
                        <Box
                            display="flex"
                            component="span"
                            alignItems="center"
                            mr={1}
                        >
                            <TypeIcon />
                        </Box>
                        <Box
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                        >
                            <Link
                                color="inherit"
                                to={`/concept/${props.item.id}/home`}
                                component={LinkRouter}
                            >
                                {props.item.title}
                            </Link>
                        </Box>
                        <ChevronRightIcon
                            style={{ margin: theme.spacing(0.5) }}
                        />
                        <Box width="fit-content" whiteSpace="nowrap">
                            Concept Lists
                        </Box>
                    </Box>

                    <IconButton
                        style={{ position: "absolute", right: 0, top: 0 }}
                        onClick={() => props.onClose()}
                    >
                        <ClearIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                >
                    <ConceptListHub item={props.item} />
                </Box>

                <Divider />
            </Box>
        </Dialog>
    )
}

interface ListOptionsProps {
    onClose: () => void
    item: Checklist
}
const ListOptionsDialog = (props: ListOptionsProps) => {
    const { currentUser } = useAuth()
    const [value, setValue] = useState("")
    const [description, setDescription] = useState("")
    const [updateChecklist] = useMutation<
        UpdateChecklistMutation,
        UpdateChecklistMutationVariables
    >(UPDATE_CHECKLIST)
    const [deleteChecklist] = useMutation<
        DeleteChecklistMutation,
        DeleteChecklistMutationVariables
    >(DELETE_CHECKLIST, {
        update(cache) {
            cache.identify(props.item)
        },
    })
    const [prefix, setPrefix] = useState<ItemPrefixType>(ItemPrefixType.NONE)
    const [saving, setSaving] = useState(false)
    const [confirm, setConfirm] = useState(false)
    useEffect(() => {
        setValue(props.item.title)
        setDescription(props.item.description)
        setPrefix(props.item.itemPrefixType ?? ItemPrefixType.NONE)
    }, [props.item.title, props.item.description, props.item.itemPrefixType])
    const handleSave = async () => {
        setSaving(true)
        const response = await updateChecklist({
            variables: {
                userId: currentUser.userId,
                checklist: {
                    id: props.item.id,
                    title: value,
                    description: description,
                    itemPrefixType: prefix,
                },
            },
        })
        if (response) {
            props.onClose()
        }
    }
    const handleDeletion = async () => {
        setSaving(true)
        const response = await deleteChecklist({
            variables: { id: props.item.id },
        })
        if (response) {
            props.onClose()
        }
    }
    const { t } = useAwaitTranslation("widgets")
    return (
        <Dialog open={true} fullWidth>
            {saving ? (
                <Box margin={4}>
                    <Loading hideQuote={true} size={75} />
                </Box>
            ) : confirm ? (
                <>
                    <DialogContent>
                        {t(
                            "confirmListDelete",
                            "Are you sure you want to delete this list?"
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setConfirm(false)
                            }}
                        >
                            {t("no", "No")}
                        </Button>
                        <Button
                            onClick={handleDeletion}
                            variant="contained"
                            color="primary"
                        >
                            {t("yes", "Yes")}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogTitle>
                        {t("listOptions", "List Options")}
                    </DialogTitle>
                    <DialogContent>
                        <Box p={2}>
                            <TextField
                                label={t("title", "Title")}
                                fullWidth
                                onChange={(event) => {
                                    setValue(event.target.value)
                                }}
                                value={value}
                            />
                        </Box>
                        <Box p={2}>
                            <TextField
                                label={t("description", "Description")}
                                fullWidth
                                onChange={(event) => {
                                    setDescription(event.target.value)
                                }}
                                value={description}
                            />
                        </Box>
                        <Box p={2}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    {t("prefixType", "Prefix Type")}
                                </InputLabel>
                                <Select
                                    label={t("prefixType", "Prefix Type")}
                                    fullWidth
                                    value={prefix}
                                    onChange={(
                                        event: React.ChangeEvent<{
                                            value: ItemPrefixType
                                        }>
                                    ) => {
                                        setPrefix(event.target.value)
                                    }}
                                >
                                    <MenuItem value="NONE">
                                        {t("none", "None")}
                                    </MenuItem>
                                    <MenuItem value={"CHECKBOX"}>
                                        {t("checkboxes", "Checkboxes")}
                                    </MenuItem>
                                    {/* <MenuItem value={"BULLET"}>
                                            Bullet Points
                                        </MenuItem> */}
                                    <MenuItem value={"NUMBER"}>
                                        {t("numbered", "Numbered")}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            padding={1}
                        >
                            <Button
                                style={{ color: "red" }}
                                variant="outlined"
                                onClick={() => {
                                    setConfirm(true)
                                }}
                            >
                                {t("delete", "Delete")}
                            </Button>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={() => {
                                        props.onClose()
                                    }}
                                >
                                    {t("discard", "Discard")}
                                </Button>
                                <Button
                                    disabled={
                                        (props.item.title === value ||
                                            value.length === 0) &&
                                        (props.item.description ===
                                            description ||
                                            description.length === 0) &&
                                        props.item.itemPrefixType === prefix
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    {t("save", "Save")}
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}
