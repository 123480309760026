import React, { useState } from "react"
import {
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Tooltip,
    Typography,
    TextField,
    CircularProgress,
} from "@material-ui/core"
import { Criteria } from "../../__generated__/types"
import { useMutation, useQuery, useApolloClient } from "@apollo/client"
import {
    DELETE_CRITERIA,
    CREATE_CRITERIA,
    DEFAULT_CRITERIA,
} from "../criteria/graphql"
import {
    DeleteCriteriaMutation,
    DeleteCriteriaMutationVariables,
    CreateCriteriaMutation,
    CreateCriteriaMutationVariables,
    DefaultCriteriaQuery,
    DefaultCriteriaQueryVariables,
} from "../criteria/__generated__/graphql"
import { ADD_EXERCISE_CRITERIA } from "./graphql"
import {
    AddExerciseCriteriaMutation,
    AddExerciseCriteriaMutationVariables,
} from "./__generated__/graphql"
import ClearIcon from "@material-ui/icons/Clear"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Autocomplete } from "@material-ui/lab"
import { CriteriaType } from "../criteria/types"
import AddCircleIcon from "@material-ui/icons/AddCircle"
const useStyles = makeStyles((theme: Theme) => ({
    popper: {
        zIndex: 1500,
    },
    list: {
        overflowY: "auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: 0,
        paddingLeft: theme.spacing(1),
        flexGrow: 1,
    },
}))

interface Props {
    criteria: Criteria[]
    exerciseId: string
}

export default function ExerciseSetupCriteriaManager(props: Props) {
    const classes = useStyles()
    const { data, loading } = useQuery<
        DefaultCriteriaQuery,
        DefaultCriteriaQueryVariables
    >(DEFAULT_CRITERIA)
    const [value, setValue] = useState("")
    const [addCriteria] = useMutation<
        AddExerciseCriteriaMutation,
        AddExerciseCriteriaMutationVariables
    >(ADD_EXERCISE_CRITERIA)
    const [deleteCriteria] = useMutation<
        DeleteCriteriaMutation,
        DeleteCriteriaMutationVariables
    >(DELETE_CRITERIA)
    const [createCriteria] = useMutation<
        CreateCriteriaMutation,
        CreateCriteriaMutationVariables
    >(CREATE_CRITERIA)
    const [saving, setSaving] = useState(false)
    const client = useApolloClient()
    const handleDeletion = (criteria: Criteria) => {
        deleteCriteria({
            variables: {
                id: criteria.id,
            },
        })
        client.cache.evict({
            id: client.cache.identify(criteria),
        })
    }
    const handleCriteriaAddition = async (name: string) => {
        setSaving(true)
        const {
            data: { CreateCriteria: NewCriteria },
        } = await createCriteria({
            variables: {
                name: name,
                isDefault: false,
                criteriaType: CriteriaType.Integer,
            },
        })
        await addCriteria({
            variables: {
                criteriaId: NewCriteria.id,
                exerciseId: props.exerciseId,
            },
        })
        setValue("")
        setSaving(false)
    }
    const currentCriteriaNames = props.criteria?.map((x) => x.name) ?? []
    return (
        <>
            <Box
                pt={2}
                display="flex"
                flexDirection="column"
                height="100%"
                p={1}
            >
                <Box pt={2}>
                    <Autocomplete
                        options={
                            data?.Criteria?.filter(
                                (x) => x.criteriaType === CriteriaType.Integer
                            ) ?? []
                        }
                        size="small"
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason === "reset") {
                                setValue("")
                                return
                            }
                        }}
                        value={value}
                        onChange={(
                            _: React.ChangeEvent<{}>,
                            newValue: Criteria,
                            reason
                        ) => {
                            if (reason === "select-option") {
                                handleCriteriaAddition(newValue.name)
                            }
                        }}
                        openOnFocus
                        groupBy={(option) => option.category?.name}
                        loading={loading}
                        getOptionDisabled={(option) =>
                            currentCriteriaNames.includes(option.name)
                        }
                        getOptionLabel={(option) => option.name ?? ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Type to add criteria..."
                                style={{ marginBottom: 10 }}
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment:
                                        loading || saving ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : (
                                            <React.Fragment>
                                                <AddCircleIcon fontSize="small" />
                                            </React.Fragment>
                                        ),
                                }}
                            />
                        )}
                    />
                </Box>
                <List className={classes.list}>
                    {props.criteria?.length > 0 ? (
                        props.criteria?.map((criteria, index) => {
                            return (
                                <ListItem key={criteria.name}>
                                    <ListItemText primary={criteria.name} />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                handleDeletion(criteria)
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })
                    ) : (
                        <Box p={1}>
                            <Tooltip title="Add criteria">
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {" "}
                                    No criteria selected...
                                </Typography>
                            </Tooltip>
                        </Box>
                    )}
                </List>
            </Box>
        </>
    )
}
