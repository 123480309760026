import { useEffect, useState, useCallback } from "react"
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ReferenceLine,
    ReferenceDot,
} from "recharts"
import { useQuery, useMutation } from "@apollo/client"
import { SizeMe } from "react-sizeme"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import { PRODUCT_CHART } from "./graphql"
import StarIcon from "@material-ui/icons/Star"
import BeenhereIcon from "@material-ui/icons/Beenhere"
import { useHistory } from "react-router-dom"
import { Avatar } from "@material-ui/core"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { CurveData } from "./line-data/ProductChartData"
import { useParams } from "react-router-dom"
import {
    CREATE_CRITERIA,
    CREATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_CRITERIA,
    UPDATE_CRITERIA_SCORE,
    ADD_CONCEPT_CRITERIA,
    ADD_CRITERIA_SCORE_USER,
} from "../criteria/graphql"
import {
    CreateCriteriaMutation,
    CreateCriteriaScoreMutation,
    CreateCriteriaMutationVariables,
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    CreateCriteriaScoreMutationVariables,
    AddCriteriaConceptMutation,
    AddCriteriaConceptMutationVariables,
    AddCriteriaScoreUserMutation,
    AddCriteriaScoreUserMutationVariables,
    AddCriteriaScoreCriteriaMutation,
    AddCriteriaScoreCriteriaMutationVariables,
} from "../criteria/__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import { Concept, _ConceptFilter } from "../../__generated__/types"
import { CriteriaType } from "../criteria/types"
import { DotProps } from "recharts"
import { Loading } from "../Loading"
import {
    ProductChartQuery,
    ProductChartQueryVariables,
} from "./__generated__/graphql"
const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    flex: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
        padding: theme.spacing(1),
    },
    chartContainer: {
        height: "90%",
    },
    yAxis: {
        writingMode: "vertical-lr",
        textOrientation: "sideways",
        transform: "rotate(180deg)",
        display: "flex",
        justifyContent: "center",
        width: "20px",
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    clickable: {
        cursor: "crosshair !important",
    },
    timeContainer: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        textAlign: "center",
        marginBottom: "5px",
    },
}))

interface ProductChartProps {
    filter?: _ConceptFilter
    concepts?: Concept[]
    locked?: boolean
}
type PositionObject = {
    x: number
    y: number
    id: string
}
type TooltipObject = {
    title: string
    primary: boolean
    position: number
    imageUrl: string
}
export default function ProductChart(props: ProductChartProps) {
    const { conceptId } = useParams()
    const { currentUser } = useAuth()
    const { data, loading, refetch } = useQuery<
        ProductChartQuery,
        ProductChartQueryVariables
    >(PRODUCT_CHART, {
        variables: {
            conceptFilter: props.filter,
            conceptId: conceptId,
        },
    })
    const [saving, setSaving] = useState(false)
    const [createCriteria] = useMutation<
        CreateCriteriaMutation,
        CreateCriteriaMutationVariables
    >(CREATE_CRITERIA)
    const [createScore] = useMutation<
        CreateCriteriaScoreMutation,
        CreateCriteriaScoreMutationVariables
    >(CREATE_CRITERIA_SCORE)
    const [addScore] = useMutation<
        AddCriteriaScoreCriteriaMutation,
        AddCriteriaScoreCriteriaMutationVariables
    >(ADD_CRITERIA_SCORE_CRITERIA)
    const [updateScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const [addCriteria] = useMutation<
        AddCriteriaConceptMutation,
        AddCriteriaConceptMutationVariables
    >(ADD_CONCEPT_CRITERIA)
    const [addUser] = useMutation<
        AddCriteriaScoreUserMutation,
        AddCriteriaScoreUserMutationVariables
    >(ADD_CRITERIA_SCORE_USER)
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyles()
    const createNewCriteria = useCallback(async () => {
        const {
            data: { CreateCriteria: NewCriteria },
        } = await createCriteria({
            variables: {
                name: "Product Chart Position",
                criteriaType: CriteriaType.Chart,
                isDefault: false,
            },
        })
        await addCriteria({
            variables: {
                criteriaId: NewCriteria.id,
                conceptId: conceptId,
            },
        })
        await refetch()
    }, [addCriteria, conceptId, createCriteria, refetch])
    const { t } = useAwaitTranslation("widgets")
    const solutions: Concept[] =
        props.concepts ??
        data?.secondaryConcepts?.filter((x) => x.type === "Solution") ??
        []
    const primaryConcept =
        solutions.filter((x) => x.id === conceptId)?.[0] ??
        data?.primaryConcept?.[0] ??
        null
    const primaryConceptCriteria =
        primaryConcept?.criteria?.filter(
            (criteria) => criteria.name === "Product Chart Position"
        )?.[0] ?? null
    const primaryConceptScore =
        primaryConceptCriteria?.scores?.filter(
            (score) => score.user?.userId === currentUser?.userId
        )?.[0] ?? null

    useEffect(() => {
        if (!!data && !!primaryConcept && !primaryConceptCriteria) {
            createNewCriteria()
        }
    }, [primaryConcept, primaryConceptCriteria, createNewCriteria, data])
    let salesObject: PositionObject | null
    let profitObject: PositionObject | null
    let salesArray: PositionObject[] = []
    let profitArray: PositionObject[] = []
    if (!!primaryConcept && !!primaryConceptScore?.value) {
        salesObject = {
            x: primaryConceptScore.value,
            y: 0,
            id: primaryConcept.id,
        }
        profitObject = {
            x: primaryConceptScore.value,
            y: 0,
            id: primaryConcept.id,
        }
        CurveData.forEach((chartPoint) => {
            chartPoint.dataPoints = {}
            if (chartPoint.x === salesObject.x) {
                salesObject.y = chartPoint.Sales
                profitObject.y = chartPoint.Profit
                let toolTipObject: TooltipObject = {
                    title: primaryConcept.title,
                    primary: true,
                    position: chartPoint.x,
                    imageUrl: primaryConcept.imageUrl,
                }
                chartPoint.dataPoints[salesObject.id] = toolTipObject
            }
        })
    }
    solutions
        ?.filter(
            (x: Concept) =>
                !!x.criteria?.filter(
                    (criteria) => criteria.name === "Product Chart Position"
                )?.[0]
        )
        .forEach((item) => {
            const score = item.criteria?.filter(
                (criteria) => criteria.name === "Product Chart Position"
            )?.[0]?.scores[0]
            if (!!score?.value) {
                let salesObject: PositionObject = {
                    x: score.value,
                    y: 0,
                    id: item.id,
                }
                let profitObject: PositionObject = {
                    x: score.value,
                    y: 0,
                    id: item.id,
                }
                CurveData.forEach((chartPoint) => {
                    if (score.value === chartPoint.x) {
                        salesObject.y = chartPoint.Sales
                        profitObject.y = chartPoint.Profit
                        let toolTipObject: TooltipObject = {
                            title: item.title,
                            primary: false,
                            position: chartPoint.x,
                            imageUrl: item.imageUrl,
                        }

                        chartPoint.dataPoints[salesObject.id] = toolTipObject
                    }
                })
                salesArray.push(salesObject)
                profitArray.push(profitObject)
            }
        })
    const handleClick = async (data: { activeLabel: number }) => {
        if (!!primaryConcept && !!data?.activeLabel && !props.locked) {
            setSaving(true)
            if (!!primaryConceptCriteria && !primaryConceptScore) {
                const {
                    data: { CreateCriteriaScore: NewScore },
                } = await createScore({
                    variables: {
                        value: data.activeLabel,
                    },
                })
                await Promise.all([
                    addScore({
                        variables: {
                            criteriaId: primaryConceptCriteria.id,
                            criteriaScoreId: NewScore.id,
                        },
                    }),
                    addUser({
                        variables: {
                            userId: currentUser?.userId,
                            criteriaScoreId: NewScore.id,
                        },
                    }),
                ])
            } else if (!!primaryConceptCriteria && !!primaryConceptScore) {
                await updateScore({
                    variables: {
                        id: primaryConceptScore.id,
                        value: data.activeLabel,
                    },
                })
            }

            setSaving(false)
        }
    }
    if (loading || (!!primaryConcept && !primaryConceptCriteria)) {
        return <Loading size={75} hideQuote={true} />
    }
    return (
        <div
            className={classes.flex}
            style={{
                cursor: saving ? "wait" : "default",
                pointerEvents: saving ? "none" : "auto",
            }}
        >
            <SizeMe monitorHeight monitorWidth>
                {({ size }) => (
                    <div className={classes.chartContainer}>
                        <Box display="flex">
                            <div className={classes.yAxis}>
                                {t("money", "Money")} ($)
                            </div>
                            <div>
                                <LineChart
                                    width={size.width - 30}
                                    height={size.height}
                                    className={
                                        !!primaryConcept && !props.locked
                                            ? classes.clickable
                                            : ""
                                    }
                                    data={CurveData}
                                    onClick={handleClick}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <XAxis
                                        tick={<XaxisTick size={size} />}
                                        ticks={[
                                            0,
                                            10,
                                            20,
                                            30,
                                            40,
                                            50,
                                            60,
                                            70,
                                            80,
                                            90,
                                            100,
                                        ]}
                                        type="number"
                                        // dot={false}
                                        dataKey="x"
                                        interval="preserveEnd"
                                    />
                                    <YAxis
                                        tick={<YaxisTick size={size} />}
                                        ticks={[-980, 0, 3500]}
                                        type="number"
                                        label={{
                                            angle: -90,
                                            position: "insideLeft",
                                        }}
                                    />
                                    <Tooltip
                                        content={
                                            <CustomTooltip
                                                palette={theme.palette}
                                            />
                                        }
                                    />
                                    <Legend verticalAlign="top" height={15} />
                                    <ReferenceLine
                                        stroke="rgb(102, 102, 102)"
                                        y={0}
                                    />
                                    <Line
                                        dot={false}
                                        type="monotone"
                                        dataKey="Profit"
                                        stroke={theme.palette.primary.dark}
                                        fill={theme.palette.primary.dark}
                                    />
                                    <Line
                                        dot={false}
                                        type="monotone"
                                        dataKey="Sales"
                                        stroke={theme.palette.secondary.main}
                                        fill={theme.palette.secondary.main}
                                    />
                                    {salesArray?.map((item, idx) => {
                                        const key = idx.toString() + "sales"
                                        return (
                                            <ReferenceDot
                                                key={key}
                                                fill={
                                                    theme.palette.secondary.main
                                                }
                                                style={{
                                                    cursor: props.locked
                                                        ? "pointer"
                                                        : "default",
                                                }}
                                                onClick={(e: DotProps) => {
                                                    if (props.locked) {
                                                        history.push(
                                                            `/concept/${item.id}/home`
                                                        )
                                                    }
                                                }}
                                                r={5}
                                                xAxisId={0}
                                                x={item.x}
                                                y={item.y}
                                            />
                                        )
                                    })}
                                    {profitArray?.map((item, idx) => {
                                        const key = idx.toString() + "profit"
                                        return (
                                            <ReferenceDot
                                                key={key}
                                                fill={
                                                    theme.palette.secondary.main
                                                }
                                                style={{
                                                    cursor: props.locked
                                                        ? "pointer"
                                                        : "default",
                                                }}
                                                onClick={(e: DotProps) => {
                                                    if (props.locked) {
                                                        history.push(
                                                            `/concept/${item.id}/home`
                                                        )
                                                    }
                                                }}
                                                r={5}
                                                xAxisId={0}
                                                x={item.x}
                                                y={item.y}
                                            />
                                        )
                                    })}
                                    {!!primaryConceptScore?.value && (
                                        <ReferenceDot
                                            r={5}
                                            isFront={true}
                                            xAxisId={0}
                                            x={salesObject.x}
                                            y={salesObject.y}
                                            fill={theme.palette.primary.main}
                                        />
                                    )}
                                    {!!primaryConceptScore?.value && (
                                        <ReferenceDot
                                            r={5}
                                            isFront={true}
                                            xAxisId={0}
                                            x={profitObject.x}
                                            y={profitObject.y}
                                            fill={theme.palette.primary.main}
                                        />
                                    )}
                                </LineChart>
                            </div>
                        </Box>
                    </div>
                )}
            </SizeMe>
            <div className={classes.timeContainer}>{t("time", "Time")}</div>
        </div>
    )
}

const CustomTooltip = (props: any) => {
    const { active, payload, label, palette } = props
    const { t } = useAwaitTranslation("widgets")
    const data = payload[0]?.payload?.dataPoints
    if (active && payload) {
        if (data && Object.keys(data).length > 0) {
            let topLabel = ""
            const xValue = payload[0].payload.x
            if (xValue < 20) {
                topLabel = t("productDevelopment", "Product Development")
            } else if (xValue >= 20 && xValue <= 40) {
                topLabel = t("introduction", "Introduction")
            } else if (xValue >= 40 && xValue <= 60) {
                topLabel = t("growth", "Growth")
            } else if (xValue >= 60 && xValue <= 80) {
                topLabel = t("maturity", "Maturity")
            } else {
                topLabel = t("decline", "Decline")
            }
            return (
                <Card style={{ padding: "12px", maxWidth: "350px" }}>
                    <Box padding={0.5}>{topLabel}</Box>
                    <Box padding={0.5}>
                        <Divider />
                    </Box>

                    {Object.keys(data).map((dataPoint, idx) => {
                        return (
                            <Box
                                alignItems="center"
                                key={idx}
                                display="flex"
                                margin="5px"
                                marginRight="0px"
                                padding="8px"
                                paddingLeft="0px"
                            >
                                <Avatar
                                    variant="rounded"
                                    src={data[dataPoint].imageUrl}
                                    style={{ marginRight: "5px" }}
                                >
                                    {data[dataPoint].primary ? (
                                        <StarIcon />
                                    ) : (
                                        <BeenhereIcon />
                                    )}
                                </Avatar>
                                <Box>{data[dataPoint].title}</Box>
                            </Box>
                        )
                    })}
                </Card>
            )
        } else {
            return null
        }
    }

    return null
}

const XaxisTick = (props) => {
    const { t } = useAwaitTranslation("widgets")
    const { x, y, stroke, payload, value, size } = props

    const tickObject = {
        0: "",
        10: t("productDevelopment", "Product Development"),
        20: "",
        30: t("introduction", "Introduction"),
        40: "",
        50: t("growth", "Growth"),
        60: "",
        70: t("maturity", "Maturity"),
        80: "",
        90: t("decline", "Decline"),
        100: "",
    }
    const hideableTicks = [
        t("introduction", "Introduction"),
        t("maturity", "Maturity"),
    ]
    let content
    if (
        size.width < 750 &&
        hideableTicks.indexOf(tickObject[payload.value]) !== -1
    ) {
        content = ""
    } else {
        content = tickObject[payload.value]
    }
    return (
        <foreignObject x={x - 20} y={y - 5} width="85" height="150">
            <div style={{ fontSize: size.width < 650 ? "8px" : "10px" }}>
                {content}
            </div>
        </foreignObject>
    )
}

const YaxisTick = (props) => {
    const { t } = useAwaitTranslation("widgets")
    const { x, y, stroke, payload, value, size } = props

    return (
        <foreignObject x={x - 50} y={y - 5} width="85" height="150">
            <div style={{ fontSize: size.width < 650 ? "8px" : "10px" }}>
                {payload.value === -980 ? (
                    <>
                        <p>{t("losses", "Losses")}/</p>
                        <p>{t("investment", "Investment")}</p>
                    </>
                ) : payload.value === 0 ? (
                    <div style={{ textAlign: "center" }}>0</div>
                ) : payload.value === 3500 ? (
                    t("salesProfit", "Sales / Profit")
                ) : (
                    ""
                )}
            </div>
        </foreignObject>
    )
}
