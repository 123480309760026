export function toCapitalizedWords(name) {
    var words = name.match(/[A-Za-z][a-z]*/g) || []

    return words.map(capitalize).join(" ")
}

export function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1)
}

export function reorderArray(
    list: Array<any>,
    startIndex: number,
    endIndex: number
) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}
