import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import { useMutation } from "@apollo/client"
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../providers/AuthProvider"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Checkbox from "@material-ui/core/Checkbox"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import LinkIcon from "@material-ui/icons/Link"
import DeleteIcon from "@material-ui/icons/Delete"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    DELETE_CHECKLIST_ITEM,
    UPDATE_CHECKLIST_ITEM,
    ADD_CHECKLIST_ITEM_CONCEPT,
    REMOVE_CHECKLIST_ITEM_CONCEPT,
} from "../../graphql/Checklist"
import {
    DeleteChecklistItemMutation,
    DeleteChecklistItemMutationVariables,
    UpdateChecklistItemMutation,
    UpdateChecklistItemMutationVariables,
    AddChecklistItemConceptMutation,
    AddChecklistItemConceptMutationVariables,
    RemoveChecklistItemConceptMutation,
    RemoveChecklistItemConceptMutationVariables,
} from "../../graphql/__generated__/Checklist"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import Typography from "@material-ui/core/Typography"
import { getConceptTypeIcon } from "../../util/ConceptTypeStyles"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import LinkConcept from "../LinkConcept"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import { ChecklistItem, ItemPrefixType } from "../../__generated__/types"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "&:hover": {
            backgroundColor: theme.palette.divider,
        },
    },
    textField: {
        fontSize: theme.typography.body2.fontSize,
    },
    avatar: {
        width: "2em",
        height: "2em",
        margin: theme.spacing(1),
    },
    menuItem: {
        fontSize: theme.typography.body2.fontSize,
    },
    menuIcon: {
        fontSize: "1em",
        marginRight: theme.spacing(1),
    },
    summary: {
        fontSize: theme.typography.body2.fontSize,
        // // color: theme.palette.text.secondary,
    },
    conceptContainer: {
        cursor: "pointer !important",
    },
    linkMenu: {
        padding: theme.spacing(1.5),
        minWidth: "12em",
    },
    typeAvatar: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
    },
    lineItemProps: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    clickableContainer: {
        width: "100%",
        padding: theme.spacing(1),
        cursor: "pointer",
    },
}))
interface ChecklistItemProps {
    item: ChecklistItem
    index: number
    editPermission: boolean
    dragHandleProps?: DraggableProvidedDragHandleProps
    clone?: boolean
    move?: Function
    removeConcept?: Function
    showComplete?: boolean
    prefix: ItemPrefixType | null
    innerRef?: any
    onNextInput?: Function
    draggable?: boolean
}
const initialState = {
    mouseX: null,
    mouseY: null,
}

export const CheckListItem = (props: ChecklistItemProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const { currentUser } = useAuth()
    const [editing, setEditing] = useState(false as boolean)
    const { t } = useAwaitTranslation("checklist")
    const [saveLine] = useMutation<
        UpdateChecklistItemMutation,
        UpdateChecklistItemMutationVariables
    >(UPDATE_CHECKLIST_ITEM)
    const [deleteLine] = useMutation<
        DeleteChecklistItemMutation,
        DeleteChecklistItemMutationVariables
    >(DELETE_CHECKLIST_ITEM, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.item),
            })
        },
    })
    const [addConcept] = useMutation<
        AddChecklistItemConceptMutation,
        AddChecklistItemConceptMutationVariables
    >(ADD_CHECKLIST_ITEM_CONCEPT, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.item),
            })
        },
    })
    const [removeConcept] = useMutation<
        RemoveChecklistItemConceptMutation,
        RemoveChecklistItemConceptMutationVariables
    >(REMOVE_CHECKLIST_ITEM_CONCEPT)
    const [checked, setChecked] = React.useState(true)
    const [value, setValue] = useState("")
    const [open, setOpen] = useState(false)
    const [linkAnchor, setLinkAnchor] = React.useState<null | HTMLElement>(null)
    const [contextPosition, setContextPosition] = React.useState<{
        mouseX: null | number
        mouseY: null | number
    }>(initialState)
    const handleContextClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        setContextPosition({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        })
    }
    const handleContextClose = () => {
        setContextPosition(initialState)
    }
    const handleConceptAddition = (concept) => {
        setLinkAnchor(null)
        addConcept({
            variables: {
                fromId: props.item?.id,
                toId: concept?.id,
            },
        })
    }
    const handleConceptRemoval = () => {
        handleContextClose()
        removeConcept({
            variables: {
                fromId: props.item?.id,
                toId: props.item?.concept?.id,
            },
        })
    }

    useEffect(() => {
        setValue(props.item.title)
        setChecked(props.item.checked)
    }, [props.item])

    const handleSave = () => {
        if (value !== props.item.title) {
            saveLine({
                variables: {
                    userId: currentUser.userId,
                    checklistItem: {
                        id: props.item.id,
                        title: value,
                    },
                },
            })
            setEditing(false)
        } else {
            setEditing(false)
        }
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        saveLine({
            variables: {
                userId: currentUser.userId,
                checklistItem: {
                    id: props.item.id,
                    checked: event.target.checked,
                },
            },
        })
    }
    const handleDeletion = () => {
        deleteLine({ variables: { id: props.item.id } })
        setOpen(false)
    }
    const conceptMenuOptions = [
        <MenuItem
            className={classes.menuItem}
            onClick={() => {
                history.push(`/concept/${props.item.concept?.id}/home`)
            }}
        >
            <ExitToAppIcon className={classes.menuIcon} />{" "}
            {t("goToConceptDetails", "Go to Concept Details Page")}
        </MenuItem>,
        <MenuItem onClick={handleConceptRemoval} className={classes.menuItem}>
            <RemoveCircleIcon className={classes.menuIcon} />{" "}
            {t("removeConcept", "Remove Concept")}
        </MenuItem>,
    ]
    const TypeIcon =
        props.item.concept && getConceptTypeIcon(props.item.concept.type)
    if (props.showComplete === false && props.item.checked) {
        return null
    }
    return (
        <Box
            style={{
                backgroundColor: props.clone
                    ? theme.palette.divider
                    : "default",
            }}
            display="flex"
            width="100%"
            onContextMenu={handleContextClick}
            className={classes.root}
            key={props.item.id}
        >
            <Box flexGrow={1}>
                <Box display="flex" alignItems="center">
                    {props.prefix === "BULLET" ? (
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            p={2}
                            pr={1}
                            pl={1}
                        >
                            <FiberManualRecordIcon
                                style={{ width: "0.35em", height: "0.35em" }}
                            />
                        </Box>
                    ) : props.prefix === "NUMBER" ? (
                        <Box display="flex" alignItems="flex-start" p={1}>
                            {props.index + 1}.
                        </Box>
                    ) : props.prefix === "CHECKBOX" ? (
                        <Checkbox
                            checked={checked}
                            color="primary"
                            disabled={!props.editPermission}
                            onChange={handleChange}
                            inputProps={{
                                "aria-label": "primary checkbox",
                            }}
                            style={{
                                alignItems: "flex-start",
                                height: "fit-content",
                            }}
                        />
                    ) : null}

                    {editing ? (
                        <Box padding={1} width="100%" display="flex">
                            <TextField
                                onBlur={handleSave}
                                id={props.index + "-line"}
                                style={{ width: "100%" }}
                                value={value}
                                onChange={(event) => {
                                    setValue(event.target.value)
                                }}
                                multiline
                                onFocus={(e) =>
                                    e.currentTarget.setSelectionRange(
                                        e.currentTarget.value.length,
                                        e.currentTarget.value.length
                                    )
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault()
                                        handleSave()
                                    } else if (e.key === "Tab") {
                                        e.preventDefault()
                                        if (props.onNextInput) {
                                            props.onNextInput()
                                        }
                                    }
                                }}
                                variant="standard"
                                autoFocus
                                InputProps={{
                                    classes: {
                                        input: classes.textField,
                                    },
                                }}
                            />
                        </Box>
                    ) : (
                        <div
                            className={classes.clickableContainer}
                            ref={props.innerRef && props.innerRef}
                            onClick={() => {
                                if (props.editPermission) {
                                    setEditing(true)
                                }
                            }}
                        >
                            {props.item.title}
                        </div>
                    )}
                </Box>
            </Box>
            <Box className={classes.lineItemProps}>
                {props.item.concept ? (
                    <>
                        <Tooltip
                            title={
                                <Box>
                                    <Box
                                        p={0.5}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <TypeIcon
                                            style={{
                                                width: "1.5em",
                                                height: "1.5em",
                                            }}
                                        />
                                        <Box paddingLeft={1} flexGrow={1}>
                                            <Typography variant="body2">
                                                {props.item?.concept?.title}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box p={0.5} className={classes.summary}>
                                        <Typography variant="body2">
                                            {props.item?.concept?.summary}
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                        >
                            <Box className={classes.conceptContainer}>
                                {props.item.concept.imageUrl ? (
                                    <Avatar
                                        className={classes.avatar}
                                        variant="rounded"
                                        src={props.item?.concept?.imageUrl}
                                    />
                                ) : (
                                    <Avatar
                                        className={
                                            classes.avatar +
                                            " " +
                                            classes.typeAvatar
                                        }
                                        variant="rounded"
                                    >
                                        <TypeIcon />
                                    </Avatar>
                                )}
                            </Box>
                        </Tooltip>
                    </>
                ) : (
                    <>
                        <Tooltip
                            title={t(
                                "addAttachedConcept",
                                "Add attached concept"
                            )}
                        >
                            <IconButton
                                className={classes.avatar}
                                onClick={(event) => {
                                    setLinkAnchor(event.currentTarget)
                                }}
                            >
                                <LinkIcon />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            id="simple-menu"
                            anchorEl={linkAnchor}
                            keepMounted
                            open={Boolean(linkAnchor)}
                            onClose={() => {
                                setLinkAnchor(null)
                            }}
                            classes={{
                                list: classes.linkMenu,
                            }}
                        >
                            <LinkConcept
                                concepts={[]}
                                linkFunction={handleConceptAddition}
                            />
                        </Menu>
                    </>
                )}
                <Box>
                    <Tooltip
                        title={
                            props.item.createdBy.firstName +
                            " " +
                            props.item.createdBy.lastName
                        }
                    >
                        {props.item.createdBy.imageUrl ? (
                            <Avatar
                                src={props.item.createdBy.imageUrl}
                                className={classes.avatar}
                            />
                        ) : (
                            <Avatar className={classes.avatar}>
                                {props.item.createdBy.firstName[0] +
                                    props.item.createdBy.lastName[0]}
                            </Avatar>
                        )}
                    </Tooltip>
                </Box>
            </Box>
            <Menu
                keepMounted
                open={contextPosition.mouseY !== null}
                onClose={handleContextClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextPosition.mouseY !== null &&
                    contextPosition.mouseX !== null
                        ? {
                              top: contextPosition.mouseY,
                              left: contextPosition.mouseX,
                          }
                        : undefined
                }
            >
                <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                        props.move(props.index, 0)
                        handleContextClose()
                    }}
                >
                    <ArrowUpwardIcon className={classes.menuIcon} />{" "}
                    {t("moveToTop", "Move to top")}
                </MenuItem>
                {props.item.concept && conceptMenuOptions}
                <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                        //setOpen(true)
                        handleDeletion()
                        handleContextClose()
                    }}
                >
                    <DeleteIcon className={classes.menuIcon} />{" "}
                    {t("deleteLineItem", "Delete Line Item")}
                </MenuItem>
            </Menu>
            {open && (
                <Dialog
                    open={open}
                    onBackdropClick={() => {
                        setOpen(false)
                    }}
                >
                    <DialogTitle>
                        {t("checklistItemOptions", "Checklist Item Options")}
                    </DialogTitle>
                    <DialogContent>
                        <Box display="flex" justifyContent="center" padding={1}>
                            <Button
                                style={{ color: "red" }}
                                variant="outlined"
                                onClick={handleDeletion}
                            >
                                {t("removeLineItem", "Remove Line Item")}
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    )
}
