import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { Loading } from "./Loading"
import {
    Box,
    Divider,
    Avatar,
    Typography,
    useMediaQuery,
    IconButton,
    Tooltip,
    Link,
    Dialog,
    DialogActions,
    Button,
} from "@material-ui/core"
import { makeStyles, useTheme, Theme, fade } from "@material-ui/core/styles"
import { useGlobalStyles } from "../styles/globalStyles"
import TreeView from "@material-ui/lab/TreeView"
import LinkIcon from "@material-ui/icons/Link"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import EditIcon from "@material-ui/icons/Edit"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import dayjs from "dayjs"
import Popover from "@material-ui/core/Popover"
import { getConceptTypeIcon } from "../util/ConceptTypeStyles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import RichTextEditor from "./RichTextEditor"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ClearIcon from "@material-ui/icons/Clear"
import DatePickerPopper from "./DatePickerPopper"
import SyncAltIcon from "@material-ui/icons/SyncAlt"
import DetailedConnectionPreviewDialog from "./DetailedConnectionPreviewDialog"
import LikeToggle from "./liking/LikeToggle"
import AlertSubscriptionToggle from "./alert-subscriptions/AlertSubscriptionToggle"
import WorkspaceToggle from "./workspace/WorkspaceToggle"
import { Link as LinkRouter } from "react-router-dom"
import { ROADMAP_BY_ID } from "../graphql/Checklist"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import ConceptList from "./concept-list/ConceptList"
import ListIcon from "@material-ui/icons/List"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "100%",
    },
    label: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: theme.typography.body2.fontSize,
    },
    bar: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
    },
    subBar: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.main,
    },
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(1),
        width: "20em",
    },
    treeItem: {
        backgroundColor: "transparent !important",
    },
    focusedTreeItem: {
        backgroundColor: fade(theme.palette.primary.main, 0.08),
    },
    clickableLabel: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    previewAvatar: {
        [theme.breakpoints.down("sm")]: {
            minWidth: "75px",
            minHeight: "75px",
        },
        minWidth: "100px",
        minHeight: "100px",
        margin: theme.spacing(1),
    },
    group: {
        marginLeft: 0 + " !important",
    },
    subLabel: {
        fontSize: 12,
        color: theme.palette.text.hint,
    },
    iconContainer: {
        width: "2em",
        height: "2em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(0, 1),
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        borderRadius: "100%",
    },
    emptyIconContainer: {
        width: "2em",
        cursor: "default",
        margin: theme.spacing(0, 1),
    },
    axisLabel: {
        fontSize: theme.typography.body2.fontSize,
        textAlign: "center",
    },
    noConcepts: {
        width: "100%",
        padding: theme.spacing(2),
        fontSize: theme.typography.h6.fontSize,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
}))

const organizeDates = (objects, field) => {
    return objects
        .filter((x) => {
            return !!x[field]?.formatted
        })
        .map((x) => {
            return new Date(x[field].formatted)
        })
        .sort((a, b) => {
            return a - b
        })
}

interface RoadmapProps {
    listId: string
}
export default function Roadmap(props: RoadmapProps) {
    const { data, loading, error } = useQuery(ROADMAP_BY_ID, {
        variables: {
            id: props.listId,
        },
    })
    const [focusedId, setFocusedId] = useState(null)
    const [listOpen, setListOpen] = useState(false)
    const classes = useStyles()
    const barHeight = 40
    const [showToday, setShowToday] = useState(true)
    if (loading) {
        return <Loading size={75} hideQuote={true} />
    }
    if (error) {
        console.log(error.message)
        return <Box>{error.message}</Box>
    }
    const list = data?.Checklist?.[0]
    const concepts = list?.items
        .filter((x) => !!x.concept)
        .map((x) => {
            return x.concept
        })
        ?.sort((a, b) => {
            let dateA = new Date(a?.startDate?.formatted)
            let dateB = new Date(b?.startDate?.formatted)
            return dateA.getTime() - dateB.getTime()
        })
    let allConcepts = []
    concepts?.forEach((x) => {
        let concepts = [
            x,
            ...x?.children
                .filter((x) => !!x.startDate.formatted && !!x.endDate.formatted)
                .sort((a, b) => {
                    let dateA = new Date(a.startDate?.formatted)
                    let dateB = new Date(b.startDate?.formatted)
                    return dateA.getTime() - dateB.getTime()
                }),
        ]
        allConcepts = [...allConcepts, ...concepts]
    })
    const startDates = organizeDates(allConcepts, "startDate")
    const endDates = organizeDates(allConcepts, "endDate")
    const oneYearAgo = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
    )
    const oneYearFromToday = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
    )
    const min = startDates[0] ?? oneYearAgo
    const max = endDates[endDates.length - 1] ?? oneYearFromToday
    let focusedIndex = 0
    if (focusedId) {
        allConcepts.forEach((item, index) => {
            if (item.id === focusedId) {
                focusedIndex = index
            }
        })
    }
    return (
        <Box className={classes.root}>
            <Box display="flex" overflow="hidden" height="100%">
                {list && !!concepts ? (
                    concepts.length > 0 ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            overflow="hidden"
                            width="100%"
                        >
                            <Box p={0.5}>
                                <Tooltip title="Manage list">
                                    <IconButton
                                        size="small"
                                        onClick={() => setListOpen(true)}
                                    >
                                        <ListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Divider />
                            <Box overflow="auto">
                                {concepts.map((x, idx) => {
                                    return (
                                        <Box key={idx} width="100%">
                                            <TreeView
                                                defaultCollapseIcon={
                                                    <ExpandMoreIcon />
                                                }
                                                defaultExpandIcon={
                                                    <ChevronRightIcon />
                                                }
                                            >
                                                <GanttBar
                                                    height={barHeight}
                                                    item={x}
                                                    onBarClicked={(item) =>
                                                        setFocusedId(item)
                                                    }
                                                    focusedId={focusedId}
                                                    min={min}
                                                    max={max}
                                                    depth={0}
                                                    showToday={showToday}
                                                />
                                            </TreeView>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <BottomAxis
                                min={min.getTime()}
                                max={max.getTime()}
                                setShowToday={() => setShowToday(!showToday)}
                                showToday={showToday}
                            />
                        </Box>
                    ) : (
                        <Box
                            className={classes.noConcepts}
                            onClick={() => setListOpen(true)}
                        >
                            <Box>No concepts in this list...</Box>
                        </Box>
                    )
                ) : (
                    <Box
                        p={3}
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <Box>The selected list was deleted...</Box>
                    </Box>
                )}
                {focusedId && concepts?.length > 0 && (
                    <>
                        <Divider orientation="vertical" />
                        <FocusedItem
                            item={
                                allConcepts.filter((x) => x.id === focusedId)[0]
                            }
                            navForward={() => {
                                if (allConcepts[focusedIndex + 1]) {
                                    setFocusedId(
                                        allConcepts[focusedIndex + 1].id
                                    )
                                } else {
                                    setFocusedId(allConcepts[0].id)
                                }
                            }}
                            navBackward={() => {
                                if (allConcepts[focusedIndex - 1]) {
                                    setFocusedId(
                                        allConcepts[focusedIndex - 1].id
                                    )
                                } else {
                                    setFocusedId(
                                        allConcepts[allConcepts.length - 1].id
                                    )
                                }
                            }}
                            clearFocused={() => setFocusedId(null)}
                        />
                    </>
                )}
            </Box>
            {listOpen && !!list && (
                <Dialog
                    onMouseDown={(e) => e.stopPropagation()}
                    open={true}
                    fullWidth
                    maxWidth="lg"
                    style={{ height: "80vh" }}
                >
                    <ConceptList
                        checklist={list}
                        editPermission={true}
                        draggable={false}
                    />
                    <Divider />
                    <DialogActions>
                        <Button onClick={() => setListOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    )
}

interface FocusedItemProps {
    item: any
    clearFocused: () => void
    navForward: () => void
    navBackward: () => void
}
const FocusedItem = (props: FocusedItemProps) => {
    const theme = useTheme()
    const iconStyles = useGlobalStyles()
    const classes = useStyles()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [previewOpen, setPreviewOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const open = Boolean(anchorEl)
    if (props.item) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                width="50%"
                key={props.item.id}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" alignItems="center" pt={0.5} pb={0.5}>
                        <Box ml={1.5}>
                            <AlertSubscriptionToggle
                                item={props.item}
                                size="small"
                            />
                        </Box>
                        <Box ml={1.5}>
                            <WorkspaceToggle item={props.item} size="small" />
                        </Box>
                        <Box ml={1.5}>
                            <LikeToggle item={props.item} size="small" />
                        </Box>

                        <Box ml={1.5}>
                            <IconButton
                                size="small"
                                onClick={() => setPreviewOpen(true)}
                            >
                                <SyncAltIcon />
                                <Box
                                    component="span"
                                    className={iconStyles.count}
                                >
                                    {props.item?.connections?.length}
                                </Box>
                            </IconButton>
                        </Box>
                        <Box ml={1.5}>
                            <Tooltip title="View details page">
                                <Link
                                    color="inherit"
                                    to={`/concept/${props.item.id}/home`}
                                    component={LinkRouter}
                                >
                                    <IconButton size="small">
                                        <ExitToAppIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box mr={1.5}>
                        <IconButton
                            size="small"
                            onClick={() => props.clearFocused()}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <Box display="flex" alignItems="baseline">
                    <Box ml={0.5}>
                        <IconButton
                            size="small"
                            onClick={() => props.navBackward()}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        padding={1}
                        pt={1}
                        flexDirection={mobile ? "column-reverse" : "row"}
                        flexGrow={1}
                    >
                        <Box flexGrow={1}>
                            {!!props.item.rootCategory && (
                                <Box style={{ color: theme.palette.text.hint }}>
                                    <Typography variant="body2">
                                        {props.item.rootCategory.name}{" "}
                                        {!!props.item.category &&
                                            !props.item.category?.isRoot &&
                                            " / " + props.item.category?.name}
                                    </Typography>
                                </Box>
                            )}
                            <Box>
                                <Typography variant="body1">
                                    {props.item.title}
                                </Typography>
                            </Box>
                            <Box
                                style={{ color: theme.palette.text.secondary }}
                            >
                                <Typography variant="body2">
                                    {props.item.summary}
                                </Typography>
                            </Box>
                        </Box>
                        {props.item.imageUrl && (
                            <Avatar
                                src={props.item.imageUrl}
                                variant="rounded"
                                className={classes.previewAvatar}
                            />
                        )}
                    </Box>
                    <Box mr={0.5}>
                        <IconButton
                            size="small"
                            onClick={() => props.navForward()}
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </Box>
                </Box>
                {props.item.webLink && (
                    <Box p={1} pl={2}>
                        <Box color={theme.palette.text.hint} fontSize={12}>
                            Source:
                        </Box>
                        <Link
                            href={props.item.webLink.url}
                            target="_blank"
                            rel="noopener"
                            style={{ display: "flex" }}
                        >
                            <LinkIcon style={{ marginRight: ".5em" }} />{" "}
                            {props.item.webLink.title}
                        </Link>
                    </Box>
                )}
                <Divider />

                <Box overflow="auto" p={1.5} flexGrow={1}>
                    <RichTextEditor
                        initialValue={props.item.description}
                        isReadOnly={true}
                        editorId={props.item.id}
                    />
                </Box>

                <Box>
                    <Divider />

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        p={1}
                        pb={0}
                        alignItems="flex-start"
                    >
                        <Box p={0.5}>
                            <Box className={classes.subLabel}>Start Date</Box>

                            <Box>
                                {props.item.startDate?.formatted &&
                                    dayjs(
                                        props.item.startDate.formatted
                                    ).format("MM-YY")}
                            </Box>
                        </Box>
                        <Box p={0.5}>
                            <Box className={classes.subLabel}>Duration</Box>
                            <Box>
                                {props.item.startDate?.formatted &&
                                    props.item.endDate?.formatted &&
                                    dayjs(props.item.endDate.formatted).diff(
                                        props.item.startDate.formatted,
                                        "month"
                                    ) + "  Months"}
                            </Box>
                        </Box>
                        <Box p={0.5}>
                            <Box className={classes.subLabel}>End Date</Box>
                            <Box>
                                {props.item.endDate?.formatted &&
                                    dayjs(props.item.endDate.formatted).format(
                                        "MM-YY"
                                    )}
                            </Box>
                        </Box>
                        <Box p={0.5}>
                            <IconButton onClick={handleClick}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                {open && (
                    <DatePickerPopper
                        onClose={() => setAnchorEl(null)}
                        item={props.item}
                        anchorEl={anchorEl}
                    />
                )}
                {previewOpen && (
                    <DetailedConnectionPreviewDialog
                        item={props.item}
                        editPermission={true}
                        onClose={() => {
                            setPreviewOpen(false)
                        }}
                    />
                )}
            </Box>
        )
    } else {
        return null
    }
}

interface BottomAxisProps {
    min: number
    max: number
    setShowToday: () => void
    showToday: boolean
}
const BottomAxis = (props: BottomAxisProps) => {
    const classes = useStyles()
    let integer = 6
    const interval = Math.round((props.max - props.min) / integer)
    let dates = []

    for (let i = 0; i < integer; i++) {
        let date = new Date(props.min + interval * i)
        dates.push(date)
    }
    const theme = useTheme()

    return (
        <TreeView // did treeview for consistency in styling and lining up, could not get it correct w/o
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            <TreeItem
                classes={{
                    label: classes.treeItem,
                    group: classes.group,
                    iconContainer: classes.emptyIconContainer,
                }}
                key={"bottomAxis"}
                nodeId={"bottomAxis"}
                label={
                    <Box display="flex" pb={2} style={{ cursor: "default" }}>
                        <Box width="15em" flexShrink={0} display="flex">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.showToday}
                                        onChange={() => props.setShowToday()}
                                    />
                                }
                                label="Show Today"
                            />
                        </Box>
                        <Box width="100%">
                            <Box width="100%" display="flex" overflow="auto">
                                {dates.length > 0 &&
                                    dates.map((x, idx) => {
                                        return (
                                            <Box
                                                width={100 / integer + "%"}
                                                key={idx}
                                            >
                                                <Box width="fit-content">
                                                    <Box
                                                        height="0.5em"
                                                        display="flex"
                                                        justifyContent="center"
                                                    >
                                                        <Divider orientation="vertical" />
                                                    </Box>
                                                    <Box
                                                        className={
                                                            classes.axisLabel
                                                        }
                                                    >
                                                        {dayjs(x).format(
                                                            "MM/YY"
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                mt={4}
                                color={theme.palette.text.hint}
                            >
                                <Box whiteSpace="nowrap" mr={1}>
                                    {dayjs(dates[0]).fromNow()}
                                </Box>
                                <Box width="100%">
                                    <Divider />
                                </Box>
                                <Box whiteSpace="nowrap" ml={1} mr={3}>
                                    {dayjs(dates[dates.length - 1]).fromNow()}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }
            />
        </TreeView>
    )
}

interface GanttBarProps {
    item: any
    height: number
    min: Date
    max: Date
    depth: number
    showToday: boolean
    onBarClicked: (item: any) => void
    focusedId: string
}
const GanttBar = (props: GanttBarProps) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handlePopoverOpen = (
        event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const TypeIcon = getConceptTypeIcon(props.item.rootCategory?.name)
    const itemStart = new Date(props.item.startDate.formatted)
    const itemEnd = new Date(props.item.endDate.formatted)
    const theme = useTheme()
    const duration = props.max?.getTime() - props.min?.getTime()
    const children = props.item.children
        ? [...props.item.children]
              .filter((x) => {
                  return !!x.startDate.formatted && !!x.endDate.formatted
              })
              .sort((a, b) => {
                  const dateA = new Date(a.startDate.formatted).getTime()
                  const dateB = new Date(b.startDate.formatted).getTime()
                  return dateA - dateB
              })
        : []
    const leftWidth =
        ((itemStart.getTime() - props.min.getTime()) / duration) * 100
    const middleWidth =
        ((itemEnd.getTime() - itemStart.getTime()) / duration) * 100
    const rightWidth =
        ((props.max.getTime() - itemEnd.getTime()) / duration) * 100
    const today = new Date().getTime()
    const todayOffset = ((today - props.min.getTime()) / duration) * 100
    const focused = props.focusedId === props.item.id
    return (
        <>
            <TreeItem
                classes={{
                    label: focused ? classes.focusedTreeItem : classes.treeItem,
                    group: classes.group,
                    iconContainer:
                        children?.length > 0
                            ? classes.iconContainer
                            : classes.emptyIconContainer,
                }}
                onLabelClick={(e) => {
                    e.preventDefault()
                    props.onBarClicked(props.item.id)
                }}
                key={props.item.id}
                nodeId={props.item.id}
                label={
                    <>
                        <Box
                            display="flex"
                            className={classes.clickableLabel}
                            p={1}
                        >
                            <Box
                                height={props.height}
                                width="15em"
                                overflow="hidden"
                                mr={2}
                                flexShrink={0}
                                pl={props.depth * 2}
                            >
                                {props.item.rootCategory && (
                                    <Box className={classes.subLabel}>
                                        {props.item.rootCategory.name}{" "}
                                        {!!props.item.category &&
                                            !props.item.category?.isRoot &&
                                            " / " + props.item.category?.name}
                                    </Box>
                                )}
                                <Box className={classes.label}>
                                    {props.item.title}
                                </Box>
                            </Box>

                            <Box
                                height={props.height}
                                display="flex"
                                width="100%"
                                position="relative"
                            >
                                {props.showToday && (
                                    <Divider
                                        orientation="vertical"
                                        style={{
                                            position: "absolute",
                                            left: todayOffset + "%",
                                            height:
                                                props.height + theme.spacing(2),
                                            marginTop: -theme.spacing(1),
                                            backgroundColor:
                                                theme.palette.text.primary,
                                        }}
                                    />
                                )}
                                <Box width={leftWidth + "%"} />

                                <Box
                                    className={
                                        props.depth === 0
                                            ? classes.bar
                                            : classes.subBar
                                    }
                                    width={middleWidth + "%"}
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                />

                                <Box width={rightWidth + "%"} />
                            </Box>
                        </Box>
                        <Box pl={props.depth * 2}>
                            <Divider />
                        </Box>
                    </>
                }
            >
                {Array.isArray(children)
                    ? children.map((node, index) => (
                          <GanttBar
                              key={index}
                              item={node}
                              depth={props.depth + 1}
                              height={props.height}
                              min={props.min}
                              max={props.max}
                              showToday={props.showToday}
                              onBarClicked={props.onBarClicked}
                              focusedId={props.focusedId}
                          />
                      ))
                    : null}
            </TreeItem>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box>
                    <Box display="flex" alignItems="center">
                        <Box m={0.5}>
                            {props.item.imageUrl ? (
                                <Avatar
                                    variant="rounded"
                                    src={props.item.imageUrl}
                                />
                            ) : (
                                <Avatar
                                    style={{
                                        color: theme.palette.text.primary,
                                        backgroundColor:
                                            theme.palette.primary.main,
                                    }}
                                    variant="rounded"
                                >
                                    <TypeIcon />
                                </Avatar>
                            )}
                        </Box>
                        <Box m={0.5}>
                            {props.item.rootCategory && (
                                <Box className={classes.subLabel}>
                                    {props.item.rootCategory.name}{" "}
                                    {props.item.category &&
                                        " / " + props.item.category.name}
                                </Box>
                            )}
                            <Box whiteSpace="wrap">{props.item.title}</Box>
                        </Box>
                    </Box>
                    <Box fontSize={12} p={0.5}>
                        {props.item.summary}
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box p={0.5}>
                            <Box className={classes.subLabel}>Start Date</Box>

                            <Box>
                                {dayjs(props.item.startDate.formatted).format(
                                    "MM-YY"
                                )}
                            </Box>
                        </Box>
                        <Box p={0.5}>
                            <Box className={classes.subLabel}>Duration</Box>
                            <Box>
                                {dayjs(props.item.endDate.formatted).diff(
                                    props.item.startDate.formatted,
                                    "month"
                                )}{" "}
                                Months
                            </Box>
                        </Box>
                        <Box p={0.5}>
                            <Box className={classes.subLabel}>End Date</Box>
                            <Box>
                                {dayjs(props.item.endDate.formatted).format(
                                    "MM-YY"
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Popover>
        </>
    )
}
