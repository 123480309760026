import Box from "@material-ui/core/Box"
import { ExerciseFragment } from "./__generated__/graphql"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { Tab, Paper, Tabs } from "@material-ui/core"
import { ExerciseSubmission } from "../../__generated__/types"
import { useState, useEffect } from "react"
import ConceptExerciseCard from "./ConceptExerciseCard"
import { ExerciseConceptPreview } from "./ExerciseConceptRanking"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    list: {
        overflowY: "auto",
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    label: {
        color: theme.palette.text.hint,
    },
    conceptList: {
        overflowY: "auto",
        flexWrap: "wrap",
    },
    conceptListsContainer: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        flex: 2,
        margin: theme.spacing(1),
    },
    submissionNotesContainer: {
        flex: 1,
        margin: theme.spacing(1),
    },
    contentContainer: {
        display: "flex",
        overflow: "hidden",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            overflow: "auto",
        },
    },
    focusedSubmissionContainer: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        display: "flex",
        overflow: "auto",
        height: "100%",
    },
}))

interface UserRankingSubmissionResultsProps {
    submission: ExerciseSubmission
    exercise: ExerciseFragment
}
const UserRankingSubmissionResults = (
    props: UserRankingSubmissionResultsProps
) => {
    const classes = useStyles()
    const [rankedList, setRankedList] = useState<any[]>([])
    const [unrankedList, setUnrankedList] = useState<any[]>([])
    const [tab, setTab] = useState(0)
    const [focusedConcept, setFocusedConcept] = useState<any | null>(null)

    useEffect(() => {
        let ranked = []
        let unranked = []
        if (!!props.exercise.includedChecklist) {
            props.exercise.includedChecklist.items
                ?.map((item) => {
                    if (item.concept) {
                        return item.concept
                    } else {
                        return null
                    }
                })
                ?.forEach((concept) => {
                    if (
                        props.submission?.conceptIds.indexOf(concept.id) !== -1
                    ) {
                        ranked[
                            props.submission?.conceptIds.indexOf(concept.id)
                        ] = concept
                    } else {
                        unranked.push(concept)
                    }
                })
        } else if (!!props.exercise.includedConcepts) {
            props.exercise.includedConcepts?.forEach((item) => {
                if (props.submission?.conceptIds.indexOf(item.id) !== -1) {
                    ranked[
                        props.submission?.conceptIds.indexOf(item?.id)
                    ] = item
                } else {
                    unranked.push(item)
                }
            })
        }
        setUnrankedList(
            [...unranked].sort(function (a, b) {
                var itemA = a.title.toLowerCase()
                var itemB = b.title.toLowerCase()
                if (itemA < itemB) return -1
                if (itemA > itemB) return 1
                return 0
            })
        )
        setRankedList([...ranked].filter((c) => !!c))
    }, [props.submission, props.exercise, setRankedList, setUnrankedList])
    return (
        <>
            <Paper square>
                <Tabs
                    value={tab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: number
                    ) => {
                        setTab(newValue)
                    }}
                >
                    <Tab label="Ranked Concepts" />

                    <Tab label="Un-ranked Concepts" />
                </Tabs>
            </Paper>
            {tab === 0 && (
                <Box className={classes.conceptList}>
                    {rankedList.length > 0 &&
                        rankedList.map((item, idx) => {
                            return (
                                <Box p={1} key={idx}>
                                    <ConceptExerciseCard
                                        item={item}
                                        disableActions={true}
                                        onFocus={() => {
                                            setFocusedConcept(item)
                                        }}
                                        rank={idx + 1}
                                    />
                                </Box>
                            )
                        })}
                </Box>
            )}
            {tab === 1 && (
                <Box display="flex" className={classes.conceptList}>
                    {unrankedList.length > 0 &&
                        unrankedList.map((item, idx) => {
                            return (
                                <Box p={1} key={idx} width="50%">
                                    <ConceptExerciseCard
                                        item={item}
                                        disableActions={true}
                                        onFocus={() => {
                                            setFocusedConcept(item)
                                        }}
                                    />
                                </Box>
                            )
                        })}
                </Box>
            )}

            {focusedConcept && (
                <ExerciseConceptPreview
                    onClose={() => {
                        setFocusedConcept(null)
                    }}
                    concept={focusedConcept}
                />
            )}
        </>
    )
}

export default UserRankingSubmissionResults
