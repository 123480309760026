import React, { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { CriteriaType } from "./types"
import { toCapitalizedWords } from "../../util/fns"
import {
    DialogContent,
    Dialog,
    TextField,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    CircularProgress,
    DialogActions,
} from "@material-ui/core"

import { Category, Criteria } from "../../__generated__/types"
import {
    CREATE_CRITERIA,
    ADD_CATEGORY_DEFAULT_CRITERIA,
    UPDATE_CRITERIA,
    DELETE_CRITERIA,
} from "../criteria/graphql"
import {
    CreateCriteriaMutation,
    CreateCriteriaMutationVariables,
    UpdateCriteriaMutation,
    UpdateCriteriaMutationVariables,
    AddCategoryDefaultCriteriaMutation,
    AddCategoryDefaultCriteriaMutationVariables,
    DeleteCriteriaMutation,
    DeleteCriteriaMutationVariables,
} from "../criteria/__generated__/graphql"

interface DefaultCriteriaFormProps {
    criteria?: Criteria | null
    category?: Category
    onClose: () => void
    onSave: () => Promise<void>
}
const DefaultCriteriaForm = (props: DefaultCriteriaFormProps) => {
    const [createCriteria] = useMutation<
        CreateCriteriaMutation,
        CreateCriteriaMutationVariables
    >(CREATE_CRITERIA)
    const [updateCriteria] = useMutation<
        UpdateCriteriaMutation,
        UpdateCriteriaMutationVariables
    >(UPDATE_CRITERIA)
    const [addDefaultCriteria] = useMutation<
        AddCategoryDefaultCriteriaMutation,
        AddCategoryDefaultCriteriaMutationVariables
    >(ADD_CATEGORY_DEFAULT_CRITERIA)
    const [deleteCriteria] = useMutation<
        DeleteCriteriaMutation,
        DeleteCriteriaMutationVariables
    >(DELETE_CRITERIA, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.criteria),
            })
        },
    })
    const [values, setValues] = useState({
        name: "",
        type: CriteriaType.Integer,
    })
    const [saving, setSaving] = useState(false)
    const [confirm, setConfirm] = useState(false)
    useEffect(() => {
        if (!!props.criteria) {
            setValues({
                name: props.criteria.name,
                type: props.criteria.criteriaType as CriteriaType,
            })
        }
    }, [props.criteria])

    const handleSave = async () => {
        setSaving(true)
        if (!!props.criteria) {
            await updateCriteria({
                variables: {
                    id: props.criteria.id,
                    name: values.name,
                    criteriaType: values.type,
                },
            })
        } else {
            const {
                data: { CreateCriteria: NewCriteria },
            } = await createCriteria({
                variables: {
                    name: values.name,
                    criteriaType: values.type,
                    isDefault: true,
                },
            })
            await addDefaultCriteria({
                variables: {
                    categoryId: props.category.id,
                    criteriaId: NewCriteria.id,
                },
            })
        }
        setSaving(false)
        props.onSave()
    }

    const handleDeletion = () => {
        deleteCriteria({
            variables: {
                id: props.criteria?.id,
            },
        })
        props.onClose()
    }
    return (
        <Box p={1} width="100%">
            <Box p={1}>
                <TextField
                    value={values.name}
                    label="Name"
                    fullWidth
                    autoFocus
                    size="small"
                    required
                    variant="outlined"
                    onChange={(e) => {
                        setValues({ ...values, name: e.target.value })
                    }}
                />
            </Box>
            <Box p={1} mt={2}>
                <FormControl required fullWidth variant="outlined" size="small">
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={values.type}
                        onChange={(e) => {
                            setValues({
                                ...values,
                                type: e.target.value as CriteriaType,
                            })
                        }}
                        label="Type"
                    >
                        {Object.keys(CriteriaType).map((item, index) => {
                            return (
                                <MenuItem value={item} key={index}>
                                    {toCapitalizedWords(item)}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Box>
            <Box
                display="flex"
                justifyContent={props.criteria ? "space-between" : "flex-end"}
                alignItems="center"
            >
                {!!props.criteria && (
                    <Box p={1} mt={1}>
                        <Button
                            size="small"
                            style={{ color: "red" }}
                            variant="outlined"
                            onClick={() => setConfirm(true)}
                        >
                            Delete
                        </Button>
                    </Box>
                )}
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                    p={1}
                    mt={1}
                >
                    <Button size="small" onClick={() => props.onClose()}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={
                            saving ||
                            (props.criteria
                                ? props.criteria.name === values.name &&
                                  props.criteria.criteriaType === values.type
                                : values.name.length === 0)
                        }
                    >
                        {saving ? (
                            <CircularProgress disableShrink size={25} />
                        ) : (
                            "Save"
                        )}
                    </Button>
                </Box>
            </Box>
            {confirm && (
                <Dialog open={confirm}>
                    <DialogContent>
                        Are you sure you want to delete this criteria?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirm(false)}>No</Button>
                        <Button onClick={handleDeletion}>Yes</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    )
}

export default DefaultCriteriaForm
