import { useMutation } from "@apollo/client"
import { DELETE_DASHBOARD } from "./graphql"
import { useParams } from "react-router-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import React, { useEffect, useState } from "react"
import {
    Box,
    ListItem,
    ListItemText,
    ListItemIcon,
    Menu,
    Tooltip,
    MenuItem,
    Typography,
    Snackbar,
} from "@material-ui/core"
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert"
import { Concept, DashboardView } from "../../__generated__/types"
import { UPDATE_CONCEPT } from "../../graphql/mutations"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../../graphql/__generated__/mutations"
import { useAuth } from "../../providers/AuthProvider"
import { makeStyles, fade, useTheme } from "@material-ui/core/styles"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import { useHistory } from "react-router-dom"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import { reorderArray } from "../../util/fns"
import DashboardManagerForm from "./DashboardManagerForm"
import {
    DeleteDashboardViewMutation,
    DeleteDashboardViewMutationVariables,
} from "./__generated__/graphql"
import { useGlobalStyles } from "../../styles/globalStyles"
import SaveIcon from "@material-ui/icons/Save"
import DashboardTemplateForm from "./DashboardTemplateForm"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
const useStyles = makeStyles((theme) => ({
    listItem: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        padding: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
        borderBottom: "1px solid " + theme.palette.divider,
    },
    selected: {
        backgroundColor: fade(theme.palette.primary.main, 0.08),
        padding: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
        borderBottom: "1px solid " + theme.palette.divider,
        borderLeft: "5px solid " + theme.palette.primary.main,
    },
    menuItem: {
        fontSize: theme.typography.body2.fontSize,
    },
    menuIcon: {
        fontSize: "1em",
        marginRight: theme.spacing(1),
    },
}))

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

type DashboardListType = {
    dashboardIds: string[]
    dashboards: DashboardView[]
}

interface SortableDashboardListProps {
    selectedDashboard: DashboardView
    dashboardList: DashboardListType
    concept: Concept
}

const DashboardManagerList = (props: SortableDashboardListProps) => {
    const { currentUser } = useAuth()
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: {
                    id: props.concept.id,
                },
            },
        ],
    })
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [
        dashboardList,
        setDashboardList,
    ] = useState<DashboardListType | null>(null)

    const setListData = (DashboardList) => {
        let orderedDashboards = []
        let unorderedDashboards = []
        let dashboardsByIds = {}
        const { dashboards, dashboardIds } = DashboardList
        const ids = dashboardIds || dashboards.map((c) => c.id)
        dashboards.forEach((c) => {
            dashboardsByIds[c.id] = c
            let dashboardIdx = ids.indexOf(c.id)
            if (dashboardIdx === -1) {
                unorderedDashboards.push(c)
            } else {
                orderedDashboards[ids.indexOf(c.id)] = c
            }
        })
        let allDashboards = [
            ...orderedDashboards,
            ...unorderedDashboards,
        ].filter((c) => !!c)
        setDashboardList({
            ...DashboardList,
            dashboards: allDashboards,
        })
    }
    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const dashboardArray = reorderArray(
            dashboardList.dashboards,
            result.source.index,
            result.destination.index
        )
        const newList = {
            dashboardIds: dashboardArray.map((c: any) => c.id),
        }

        setListData({
            ...dashboardList,
            ...newList,
        })
        updateConcept({
            variables: {
                userId: currentUser.userId,
                concept: {
                    id: props.concept.id,
                    dashboardIds: newList.dashboardIds,
                },
            },
        })
    }

    useEffect(() => {
        if (props.dashboardList) {
            setListData(props.dashboardList)
        }
    }, [props.dashboardList])

    return (
        dashboardList && (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={props.concept.id}>
                    {(provided) => (
                        <div
                            id={props.concept.id}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {dashboardList?.dashboards?.map(
                                (dashboard: DashboardView, index) => (
                                    <Draggable
                                        key={dashboard.id}
                                        draggableId={dashboard.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <Box key={dashboard.id}>
                                                    <DashboardManagerListItem
                                                        dashboard={dashboard}
                                                        dragHandleProps={
                                                            provided.dragHandleProps
                                                        }
                                                        isDragging={
                                                            snapshot.isDragging
                                                        }
                                                        index={index}
                                                    />
                                                </Box>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    )
}
export default DashboardManagerList

interface DashboardManagerListItemProps {
    dashboard: DashboardView
    dragHandleProps?: DraggableProvidedDragHandleProps
    isDragging?: boolean
    index: number
}
const initialState = {
    mouseX: null,
    mouseY: null,
}
const DashboardManagerListItem = (props: DashboardManagerListItemProps) => {
    const { dashboardId, conceptId, username, teamId } = useParams()
    const route = !!conceptId
        ? `/concept/${conceptId}`
        : !!teamId
        ? `/team/${teamId}`
        : `/user/${username}`
    const classes = useStyles()

    const theme = useTheme()
    const history = useHistory()
    const [formOpen, setFormOpen] = useState(false)
    const [templateFormOpen, setTemplateFormOpen] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const globalClasses = useGlobalStyles()
    const [contextPosition, setContextPosition] = useState<{
        mouseX: null | number
        mouseY: null | number
    }>(initialState)
    const [deleteDashboard] = useMutation<
        DeleteDashboardViewMutation,
        DeleteDashboardViewMutationVariables
    >(DELETE_DASHBOARD, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.dashboard),
            })
        },
    })
    const handleDeletion = () => {
        handleContextClose()
        deleteDashboard({
            variables: {
                id: props.dashboard.id,
            },
        })
        history.replace(`${route}/home`)
    }
    const handleSnackbarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return
        }
        setOpenSnackbar(false)
    }
    const handleContextClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        setContextPosition({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        })
    }
    const handleContextClose = () => {
        setContextPosition(initialState)
    }

    return (
        <Box display="flex" onContextMenu={handleContextClick}>
            <Tooltip title={props.dashboard.description}>
                <ListItem
                    style={{
                        border: props.isDragging
                            ? "1px solid " + theme.palette.divider
                            : "",
                    }}
                    className={
                        dashboardId === props.dashboard.id
                            ? classes.selected
                            : classes.listItem
                    }
                    onClick={() =>
                        history.replace(`${route}/${props.dashboard.id}`)
                    }
                >
                    <Box
                        p={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mr={1}
                    >
                        {props.index + 1}
                    </Box>

                    <ListItemText
                        primary={
                            <Typography variant="body2">
                                {props.dashboard.name}
                            </Typography>
                        }
                    />
                    <ListItemIcon
                        {...props.dragHandleProps}
                        className={globalClasses.smallMenuIcon}
                    >
                        <DragIndicatorIcon fontSize="small" />
                    </ListItemIcon>
                </ListItem>
            </Tooltip>
            <Menu
                disableScrollLock
                keepMounted
                open={contextPosition.mouseY !== null}
                onClose={handleContextClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextPosition.mouseY !== null &&
                    contextPosition.mouseX !== null
                        ? {
                              top: contextPosition.mouseY,
                              left: contextPosition.mouseX,
                          }
                        : undefined
                }
            >
                <MenuItem
                    component="li"
                    className={classes.menuItem}
                    onClick={() => {
                        setContextPosition(initialState)
                        setFormOpen(true)
                    }}
                    divider
                >
                    <ListItemIcon className={globalClasses.smallMenuIcon}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            className: globalClasses.smallMenuListItemText,
                        }}
                        primary="Edit Properties"
                    />
                </MenuItem>
                <MenuItem
                    component="li"
                    className={classes.menuItem}
                    onClick={handleDeletion}
                    divider
                >
                    <ListItemIcon className={globalClasses.smallMenuIcon}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            className: globalClasses.smallMenuListItemText,
                        }}
                        primary="Delete Dashboard"
                    />
                </MenuItem>
                <MenuItem
                    component="li"
                    className={classes.menuItem}
                    onClick={() => {
                        handleContextClose()
                        setTemplateFormOpen(true)
                    }}
                >
                    <ListItemIcon className={globalClasses.smallMenuIcon}>
                        <SaveIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            className: globalClasses.smallMenuListItemText,
                        }}
                        primary="Save as template"
                    />
                </MenuItem>
            </Menu>
            {formOpen && (
                <DashboardManagerForm
                    onClose={() => setFormOpen(false)}
                    dashboard={props.dashboard}
                />
            )}
            {templateFormOpen && (
                <DashboardTemplateForm
                    dashboard={props.dashboard}
                    onClose={() => setTemplateFormOpen(false)}
                    onTemplateCreation={() => setOpenSnackbar(true)}
                />
            )}
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={
                    <Box display="flex" alignItems="center">
                        <CheckCircleIcon />
                        <Box ml={1}>Dashboard template saved!</Box>
                    </Box>
                }
            />
        </Box>
    )
}
