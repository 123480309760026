import { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { User, Team, SystemRole } from "../../__generated__/types"
import {
    Dialog,
    DialogTitle,
    Box,
    IconButton,
    Tab,
    Tabs,
    Link,
    Divider,
    Chip,
    Tooltip,
    InputAdornment,
    TextField,
    CircularProgress,
    Avatar,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    DialogContent,
    Button,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useUserManagementTools from "./useUserManagementTools"
import { Link as LinkRouter } from "react-router-dom"
import { ALL_TEAM_QUERY } from "../teams/graphql"
import {
    AllTeamQuery,
    AllTeamQueryVariables,
} from "../teams/__generated__/graphql"
import { Loading } from "../Loading"
import ClearIcon from "@material-ui/icons/Clear"
import SendIcon from "@material-ui/icons/Send"
import SearchIcon from "@material-ui/icons/Search"
import dayjs from "dayjs"
import { useLazyQuery } from "@apollo/client"
import DeleteIcon from "@material-ui/icons/Delete"

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: 12,
        color: theme.palette.text.hint,
    },
    property: {
        padding: theme.spacing(1),
    },
    autoComplete: {
        minWidth: "8em",
    },
    sendIconContainer: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingLeft: theme.spacing(1),
        },
    },
    listItemActionContainer: {
        flexBasis: "30%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    tooltipListItem: {
        fontSize: theme.typography.body2.fontSize,
    },
    additionForm: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        width: "100%",
    },
    tableContainer: {
        overflowY: "auto",
    },
    listItemBody: {
        display: "flex",
        flexBasis: "70%",
        overflow: "hidden",
        alignItems: "center",
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}))

interface UserModalProps {
    user: User
    open: boolean
    onClose: () => void
}
const UserModal = (props: UserModalProps) => {
    const classes = useStyles()
    const { user } = useUserManagementTools(props.user?.userId)
    const [value, setValue] = useState(0)
    const theme = useTheme()
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Dialog
            fullWidth
            open={props.open}
            onBackdropClick={() => props.onClose()}
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    {
                        <Avatar
                            src={props.user.imageUrl}
                            style={{
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.primary.main,
                            }}
                        >
                            <Box>
                                {props.user.firstName?.[0]}
                                {props.user.lastName?.[0]}
                            </Box>
                        </Avatar>
                    }
                    <Box ml={1}>
                        {props.user.firstName} {props.user.lastName}
                    </Box>
                </Box>
                <IconButton
                    style={{ position: "absolute", right: 0, top: 0 }}
                    onClick={() => props.onClose()}
                >
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <Box>
                {!user ? (
                    <Loading size={75} hideQuote={true} padding={3} />
                ) : (
                    <Box>
                        <Divider />
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Information" />

                            <Tab label="Teams" />
                            <Tab label="Settings" />
                        </Tabs>
                        <Divider />
                        <Box height={400} overflow="hidden" display="flex">
                            {value === 0 && (
                                <Box p={1} width="100%" overflow="auto">
                                    <Box className={classes.property}>
                                        <Box className={classes.label}>
                                            Email
                                        </Box>

                                        <Box>{user.email}</Box>
                                    </Box>
                                    <Box className={classes.property}>
                                        <Box className={classes.label}>
                                            Username
                                        </Box>
                                        <Box>{user.username}</Box>
                                    </Box>
                                    <Box className={classes.property}>
                                        <Box className={classes.label}>
                                            Phone Number
                                        </Box>
                                        <Box>{user.phoneNumber}</Box>
                                    </Box>
                                    <Box className={classes.property}>
                                        <Box className={classes.label}>Bio</Box>
                                        <Box>{user.bio}</Box>
                                    </Box>
                                    <Box className={classes.property}>
                                        <Box className={classes.label}>
                                            Last Login
                                        </Box>

                                        <Box>
                                            {!!user.lastLogin.formatted
                                                ? dayjs(
                                                      user.lastLogin?.formatted
                                                  ).fromNow()
                                                : "Yet to login"}
                                        </Box>
                                    </Box>
                                    <Box className={classes.property}>
                                        <Box className={classes.label}>
                                            Concepts Created
                                        </Box>
                                        <Box>
                                            {user.conceptsCreated?.length ?? 0}
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            {value === 1 && <UserTeamsList user={user} />}
                            {value === 2 && (
                                <UserSettings
                                    user={user}
                                    onDeletion={() => {
                                        props.onClose()
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </Dialog>
    )
}

interface UserSettingsProps {
    user: User
    onDeletion: () => void
}
const UserSettings = (props: UserSettingsProps) => {
    const {
        onUpdateUser,
        user,
        initialized,
        refetch,
        onDeleteUser,
    } = useUserManagementTools(props.user.userId)

    const [confirm, setConfirm] = useState(false)
    const handleDeletion = () => {
        onDeleteUser()
        props.onDeletion()
    }
    const [saving, setSaving] = useState(false)
    const handleUpdate = async (val: SystemRole) => {
        if (val !== user?.role) {
            setSaving(true)
            await onUpdateUser({
                userId: props.user.userId,
                role: val,
            })
            await refetch()
            setSaving(false)
        }
    }
    if (initialized) {
        return (
            <Box width="100%">
                <Box
                    p={1}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <IconButton onClick={() => setConfirm(true)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Box p={2} mt={1}>
                    <Box>
                        <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel>Role</InputLabel>
                            <Select
                                value={user.role ?? SystemRole.GUEST}
                                label="Role"
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    handleUpdate(
                                        event.target.value as SystemRole
                                    )
                                }}
                                disabled={saving}
                            >
                                <MenuItem value={SystemRole.GUEST}>
                                    Guest
                                </MenuItem>
                                <MenuItem value={SystemRole.MEMBER}>
                                    Member
                                </MenuItem>
                                <MenuItem value={SystemRole.ADMIN}>
                                    Admin
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                {confirm && (
                    <Dialog open={true}>
                        <DialogContent>
                            Are you sure you want to delete this user?
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => setConfirm(false)}>
                                No
                            </Button>
                            <Button onClick={handleDeletion}>Yes</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Box>
        )
    } else {
        return null
    }
}

interface UserTeamsListProps {
    user: User
}
const UserTeamsList = (props: UserTeamsListProps) => {
    const classes = useStyles()

    const { onAddTeams, user, initialized } = useUserManagementTools(
        props.user.userId
    )
    const currentTeamIds = user?.teams
        ? [...user.teams.map((team) => team.teamId)]
        : []
    const [teams, setTeams] = useState<Team[]>([])
    const [saving, setSaving] = useState(false)
    const [loadTeams, { data, loading, called }] = useLazyQuery<
        AllTeamQuery,
        AllTeamQueryVariables
    >(ALL_TEAM_QUERY)
    const handleSave = async () => {
        setSaving(true)
        await onAddTeams(teams.map((item) => item.teamId))
        setTeams([])
        setSaving(false)
    }
    const theme = useTheme()
    return (
        initialized && (
            <Box
                width="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
            >
                <Box p={1}>
                    <Box className={classes.additionForm}>
                        <Box p={2} flexGrow={1}>
                            <Autocomplete
                                options={
                                    data?.teams
                                        ? data?.teams.filter((x) => {
                                              return (
                                                  currentTeamIds.indexOf(
                                                      x.teamId
                                                  ) === -1
                                              )
                                          })
                                        : []
                                }
                                value={teams}
                                multiple
                                onFocus={() => {
                                    if (!called) {
                                        loadTeams()
                                    }
                                }}
                                renderTags={(tagValue, getTagProps) => {
                                    return tagValue.map((option, index) => (
                                        <Chip
                                            {...getTagProps({ index })}
                                            avatar={
                                                option.imageUrl ? (
                                                    <Avatar
                                                        src={option.imageUrl}
                                                    />
                                                ) : (
                                                    <Avatar>
                                                        {option.name[0]}
                                                    </Avatar>
                                                )
                                            }
                                            label={option.name}
                                        />
                                    ))
                                }}
                                disabled={saving}
                                loading={loading}
                                size="small"
                                onChange={(event, newValue: Team[]) => {
                                    setTeams(newValue)
                                }}
                                getOptionLabel={(option) => {
                                    return option.name
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={"Team"}
                                        placeholder="Select Team"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: loading ? (
                                                <>
                                                    <InputAdornment position="start">
                                                        <CircularProgress
                                                            disableShrink
                                                            size={25}
                                                        />
                                                    </InputAdornment>
                                                    {
                                                        params.InputProps
                                                            .startAdornment
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                    {
                                                        params.InputProps
                                                            .startAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Box className={classes.sendIconContainer}>
                            <IconButton
                                color="primary"
                                disabled={teams.length === 0}
                                onClick={handleSave}
                            >
                                {saving ? (
                                    <CircularProgress size={25} />
                                ) : (
                                    <SendIcon />
                                )}
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box className={classes.tableContainer}>
                    <List>
                        {user?.teams?.map((item, index) => {
                            const role =
                                user.leads.filter(
                                    (x) => x.teamId === item.teamId
                                )?.length > 0
                                    ? "Lead"
                                    : user.admin.filter(
                                          (x) => x.teamId === item.teamId
                                      )?.length > 0
                                    ? "Admin"
                                    : ""

                            return (
                                <ListItem key={item.teamId}>
                                    <Box className={classes.listItemBody}>
                                        <ListItemAvatar>
                                            <Avatar
                                                src={item.imageUrl}
                                                style={{
                                                    color:
                                                        theme.palette.text
                                                            .primary,
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .main,
                                                }}
                                            >
                                                {" "}
                                                {[item.name?.[0]]}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Link
                                                    className={classes.ellipsis}
                                                    color="inherit"
                                                    to={`/team/${item.teamId}/home`}
                                                    component={LinkRouter}
                                                >
                                                    {item.name}
                                                </Link>
                                            }
                                            secondary={
                                                <Tooltip
                                                    title={
                                                        <Box>
                                                            {item.members.map(
                                                                (x, idx) => {
                                                                    return (
                                                                        <Box
                                                                            key={
                                                                                x.userId
                                                                            }
                                                                            className={
                                                                                classes.tooltipListItem
                                                                            }
                                                                            padding={
                                                                                0.5
                                                                            }
                                                                        >
                                                                            {x.lastName +
                                                                                ", " +
                                                                                x.firstName}
                                                                        </Box>
                                                                    )
                                                                }
                                                            )}
                                                        </Box>
                                                    }
                                                >
                                                    <Box
                                                        className={
                                                            classes.ellipsis
                                                        }
                                                    >
                                                        {item.members.length +
                                                            " members"}
                                                    </Box>
                                                </Tooltip>
                                            }
                                        />
                                    </Box>
                                    <Box
                                        className={
                                            classes.listItemActionContainer
                                        }
                                    >
                                        {role}
                                    </Box>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            </Box>
        )
    )
}

export default UserModal
