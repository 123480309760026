import React from "react"
import PropTypes from "prop-types"
import { useAuth } from "../../providers/AuthProvider"
import { gql, useQuery } from "@apollo/client"
import {
    ThemeProvider as MuiThemeProvider,
    createMuiTheme as createLegacyModeTheme,
    unstable_createMuiStrictModeTheme as createStrictModeTheme,
} from "@material-ui/core/styles"
// import { useSelector } from 'react-redux';
import {
    enUS,
    zhCN,
    faIR,
    ruRU,
    ptBR,
    esES,
    frFR,
    deDE,
    jaJP,
} from "@material-ui/core/locale"
import { blueGrey } from "@material-ui/core/colors"
import { defaultTheme } from "../../styles/defaultTheme"
// import { getCookie } from 'docs/src/modules/utils/helpers';
// import useLazyCSS from 'docs/src/modules/utils/useLazyCSS';

const languageMap = {
    en: enUS,
    zh: zhCN,
    fa: faIR,
    ru: ruRU,
    pt: ptBR,
    es: esES,
    fr: frFR,
    de: deDE,
    ja: jaJP,
}
const USER_GLOBAL_THEMES_QUERY = gql`
    query UserGlobalThemesQuery($userId: ID) {
        User(userId: $userId) {
            userId
            activeLightTheme {
                themeId
                title
                description
                darkMode
                primary
                secondary
                primaryHue
                secondaryHue
                primaryShade
                secondaryShade
                background
            }
            activeDarkTheme {
                themeId
                title
                description
                darkMode
                primary
                secondary
                primaryHue
                secondaryHue
                primaryShade
                secondaryShade
                background
            }
            activeThemeType
        }
    }
`
export const themeColor = blueGrey[700]

const themeInitialOptions = {
    dense: false,
    direction: "ltr",
    paletteColors: {},
    spacing: 8, // spacing unit
}

const highDensity = {
    props: {
        MuiButton: {
            size: "small",
        },
        MuiFilledInput: {
            margin: "dense",
        },
        MuiFormControl: {
            margin: "dense",
        },
        MuiFormHelperText: {
            margin: "dense",
        },
        MuiIconButton: {
            size: "small",
        },
        MuiInputBase: {
            margin: "dense",
        },
        MuiInputLabel: {
            margin: "dense",
        },
        MuiListItem: {
            dense: true,
        },
        MuiOutlinedInput: {
            margin: "dense",
        },
        MuiFab: {
            size: "small",
        },
        MuiTable: {
            size: "small",
        },
        MuiTextField: {
            margin: "dense",
        },
        MuiToolbar: {
            variant: "dense",
        },
    },
    overrides: {
        MuiIconButton: {
            sizeSmall: {
                // minimal touch target hit spacing
                marginLeft: 4,
                marginRight: 4,
                padding: 12,
            },
        },
    },
}

export const DispatchContext = React.createContext(() => {
    throw new Error("Forgot to wrap component in `ThemeProvider`")
})

if (process.env.NODE_ENV !== "production") {
    DispatchContext.displayName = "ThemeDispatchContext"
}

const useEnhancedEffect =
    typeof window === "undefined" ? React.useEffect : React.useLayoutEffect

let createMuiTheme
if (process.env.REACT_MODE === "legacy") {
    createMuiTheme = createLegacyModeTheme
} else {
    createMuiTheme = createStrictModeTheme
}

export function ThemeProvider(props) {
    const { children, prefersDarkMode } = props
    const { currentUser } = useAuth()
    const { data, loading, error } = useQuery(USER_GLOBAL_THEMES_QUERY, {
        variables: {
            userId: currentUser?.userId,
        },
    })
    const [activeTheme, setActiveTheme] = React.useState(defaultTheme)
    const [themeOptions, dispatch] = React.useReducer((state, action) => {
        switch (action.type) {
            case "SET_SPACING":
                return {
                    ...state,
                    spacing: action.payload,
                }
            case "INCREASE_SPACING": {
                return {
                    ...state,
                    spacing: state.spacing + 1,
                }
            }
            case "DECREASE_SPACING": {
                return {
                    ...state,
                    spacing: state.spacing - 1,
                }
            }
            case "SET_DENSE":
                return {
                    ...state,
                    dense: action.payload,
                }
            case "RESET_DENSITY":
                return {
                    ...state,
                    dense: themeInitialOptions.dense,
                    spacing: themeInitialOptions.spacing,
                }
            case "RESET_COLORS":
                return {
                    ...state,
                    paletteColors: themeInitialOptions.paletteColors,
                }
            case "CHANGE":
                return {
                    ...state,
                    paletteType:
                        action.payload.paletteType || state.paletteType,
                    direction: action.payload.direction || state.direction,
                    paletteColors:
                        action.payload.paletteColors || state.paletteColors,
                }
            default:
                throw new Error(`Unrecognized type ${action.type}`)
        }
    }, themeInitialOptions)
    const preferredType = prefersDarkMode ? "dark" : "light"
    const userLanguage = "en" // useSelector((state) => state.options.userLanguage);
    const [type, setType] = React.useState(preferredType || "light")
    const {
        dense,
        direction,
        paletteColors,
        paletteType = type,
        spacing,
    } = themeOptions

    React.useEffect(() => {
        if (data) {
            if (data.User[0]?.activeThemeType) {
                setType(data.User[0]?.activeThemeType.toLowerCase())
            }
            let theme = {}
            if (data.User[0]?.activeLightTheme) {
                theme.LIGHT = data.User[0]?.activeLightTheme
            } else {
                theme.LIGHT = defaultTheme.LIGHT
            }
            if (data.User[0]?.activeDarkTheme) {
                theme.DARK = data.User[0]?.activeDarkTheme
            } else {
                theme.DARK = defaultTheme.DARK
            }
            setActiveTheme(theme)
        }
    }, [data])
    React.useEffect(() => {
        if (process.browser) {
            const nextPaletteColors = JSON.parse(
                localStorage.getItem("paletteColors") || "null"
            )
            const nextPaletteType = localStorage.getItem("paletteType")
            dispatch({
                type: "CHANGE",
                payload: {
                    paletteColors: nextPaletteColors,
                    paletteType: nextPaletteType,
                },
            })
        }
    }, [])

    // persist paletteType
    React.useEffect(() => {
        document.cookie = `paletteType=${paletteType};path=/;max-age=31536000`
    }, [paletteType])

    useEnhancedEffect(() => {
        document.body.dir = direction
    }, [direction])

    const theme = React.useMemo(() => {
        const nextTheme = createMuiTheme(
            /// next theme equates to current theme now since i am setting palettype and then changing colors via waiting for promise to return when i update users ActiveThemeType
            {
                direction,
                nprogress: {
                    color: paletteType === "light" ? "#000" : "#fff",
                },
                palette: {
                    primary: {
                        main:
                            paletteType === "light"
                                ? activeTheme.LIGHT.primary
                                : activeTheme.DARK.primary,
                        //main: activeTheme.primary,
                    },
                    secondary: {
                        main:
                            paletteType === "light"
                                ? activeTheme.LIGHT.secondary
                                : activeTheme.DARK.secondary,
                    },
                    type: paletteType,
                    background: {
                        default:
                            paletteType === "light" ? "#e8eaed" : "#303030",
                    },
                    ...paletteColors,
                },
                // v5 migration
                props: {
                    MuiBadge: {
                        overlap: "rectangular",
                    },
                    MuiAvatar: {
                        variant: "circle",
                    },
                },
                spacing,
            },
            dense ? highDensity : null,
            languageMap[userLanguage]
        )

        nextTheme.palette.background.level2 =
            paletteType === "light" ? nextTheme.palette.grey[100] : "#303030"

        nextTheme.palette.background.level1 =
            paletteType === "light" ? "#e8eaed" : nextTheme.palette.grey[900]

        return nextTheme
    }, [
        dense,
        direction,
        paletteColors,
        paletteType,
        spacing,
        userLanguage,
        activeTheme.LIGHT.primary,
        activeTheme.DARK.primary,
        activeTheme.LIGHT.secondary,
        activeTheme.DARK.secondary,
    ])

    React.useEffect(() => {
        // Expose the theme as a global variable so people can play with it.
        if (process.browser) {
            window.theme = theme
        }
    }, [theme])

    return (
        <MuiThemeProvider theme={theme}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </MuiThemeProvider>
    )
}

ThemeProvider.propTypes = {
    children: PropTypes.node,
}

/**
 * @returns {(nextOptions: Partial<typeof themeInitialOptions>) => void}
 */
export function useChangeTheme() {
    const dispatch = React.useContext(DispatchContext)
    return React.useCallback(
        (options) => dispatch({ type: "CHANGE", payload: options }),
        [dispatch]
    )
}
