import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { CONCEPT_BY_ID } from "../../../../graphql/queries"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../../../graphql/__generated__/queries"
import { useApolloClient } from "@apollo/client"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Widget } from "../../useWidgets"
import useWidget from "../../useWidget"
import { WidgetTypeName, WidgetTypeSchemaName } from "../../useWidgetTypes"
import { toCapitalizedWords } from "../../../../util/fns"
import Filters from "../../../filters/Filters"
import { makeStyles } from "@material-ui/core/styles"
import {
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Box,
} from "@material-ui/core"
import { useParams } from "react-router-dom"
import DoneIcon from "@material-ui/icons/Done"
import { FilterItem } from "../../../filters/util/types"
import useFilters from "../../../filters/util/useFilters"
import { useWidgetTemplates } from "../../useWidgetTemplates"
import { WidgetType, Criteria } from "../../../../__generated__/types"
import CriteriaSelector from "../../../criteria/CriteriaSelector"
import { useWidgets } from "../../useWidgets"
import { Loading } from "../../../Loading"
const useStyles = makeStyles((theme) => ({
    filtersContainer: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid " + theme.palette.divider,
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
}))
type Props = {
    widget?: Widget
    widgetType?: WidgetType
    onClose: () => void
    open: boolean
}
/// if a prop for widget is sent, the configuration of the widget is being edited
/// if a prop for widget type is sent, the widget is yet to be created
export default function WidgetConfigModal(props: Props) {
    const classes = useStyles()
    const { conceptId, dashboardId } = useParams()
    const [isSaving, setIsSaving] = React.useState(false)
    const [savingTemplate, setSavingTemplate] = useState(false)
    const [templateSaved, setTemplateSaved] = useState(false)
    const { defaultValue, defaultOptions } = useFilters()
    const client = useApolloClient()
    const concept = client.readQuery<ConceptQuery, ConceptQueryVariables>({
        query: CONCEPT_BY_ID,
        variables: {
            id: conceptId,
        },
    })?.Concept?.[0]
    const { widgetTypes, onAddNewWidget } = useWidgets(
        dashboardId !== "home" ? dashboardId : concept?.homeDashboard?.id
    )
    const {
        widget,
        config: originalConfig,
        isInitialized,
        onEditConfig,
        value,
    } = useWidget(props.widget?.id ?? null)
    const widgetType =
        props.widgetType ??
        widgetTypes?.find((wt) => wt.name === props.widget.type)
    const { onTemplateCreation } = useWidgetTemplates(widgetType?.id)
    const [config, setConfig] = useState(originalConfig)
    useEffect(() => {
        if (!!props.widget && originalConfig) {
            setConfig(originalConfig)
            setTemplateSaved(false)
        } else if (!!props.widgetType && props.widgetType.defaultConfig) {
            setConfig(JSON.parse(props.widgetType.defaultConfig ?? "{}"))
        }
    }, [originalConfig, props.widget, props.widgetType])

    const schema = widgetType?.schema?.properties ?? {}

    const onChangeProperty = (property: string, value: unknown) => {
        setTemplateSaved(false)
        setConfig((config) => {
            return {
                ...config,
                [property]: value,
            }
        })
    }
    const onSave = async () => {
        setIsSaving(true)
        if (schema.conceptId && conceptId) {
            config["conceptId"] = conceptId
        }
        if (!!props.widget) {
            await onEditConfig(config)
            setIsSaving(false)
            props.onClose()
        } else {
            await onAddNewWidget(
                widgetType?.name as WidgetTypeName,
                JSON.stringify(config),
                JSON.stringify({})
            )
            setIsSaving(false)
            props.onClose()
        }
    }
    const handleTemplateSave = async () => {
        setSavingTemplate(true)
        await onTemplateCreation(config, value)
        setSavingTemplate(false)
        setTemplateSaved(true)
    }

    return (
        <Dialog
            onMouseDown={(e) => e.stopPropagation()}
            open={props.open}
            onClose={() => props.onClose()}
            fullWidth
            aria-labelledby="modal-dialog-widget-configuration"
        >
            <DialogTitle id="modal-dialog-widget-configuration">
                {widgetType &&
                    toCapitalizedWords(widgetType?.name as WidgetTypeName)}{" "}
                {widget?.isTemplate ? "Template" : "Widget"} Configuration
            </DialogTitle>
            <DialogContent dividers>
                {isInitialized && config ? (
                    <Grid container direction="column" spacing={3}>
                        {Object.keys(schema).map((field: string, index) => {
                            const fieldObject = schema[field]

                            if (fieldObject.options?.hide) {
                                return null
                            }

                            if (fieldObject.type === "filters") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <Box
                                            className={classes.filtersContainer}
                                        >
                                            <Filters
                                                filters={
                                                    config?.[field]?.length >=
                                                    defaultValue?.length
                                                        ? config?.[field]
                                                        : defaultValue
                                                }
                                                setFilters={(
                                                    filters: FilterItem[]
                                                ) => {
                                                    onChangeProperty(
                                                        field,
                                                        filters
                                                    )
                                                }}
                                                filterOptions={defaultOptions}
                                            />
                                        </Box>
                                    </Grid>
                                )
                            }
                            if (fieldObject.type === "criteriaMultiple") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <CriteriaSelector
                                            onChange={(
                                                value: Pick<
                                                    Criteria,
                                                    "name" | "criteriaType"
                                                >[]
                                            ) => {
                                                onChangeProperty(field, value)
                                            }}
                                            value={config?.[field] ?? []}
                                        />
                                    </Grid>
                                )
                            }

                            if (
                                fieldObject.type === "string" &&
                                !fieldObject.enum
                            ) {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <TextField
                                            id={`${field}-text-field`}
                                            label={toCapitalizedWords(field)}
                                            variant="outlined"
                                            value={config?.[field] ?? ""}
                                            onChange={(e) =>
                                                onChangeProperty(
                                                    field,
                                                    e.currentTarget.value
                                                )
                                            }
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                )
                            }
                            if (
                                fieldObject.type === "string" &&
                                fieldObject.enum
                            ) {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                        >
                                            <InputLabel id={`${field}-label`}>
                                                {toCapitalizedWords(field)}
                                            </InputLabel>
                                            <Select
                                                labelId={`${field}-label`}
                                                id={`${field}-select`}
                                                value={config[field] ?? ""}
                                                label={toCapitalizedWords(
                                                    field
                                                )}
                                                MenuProps={{
                                                    disablePortal: true,
                                                }}
                                                name={field}
                                                onChange={(e) =>
                                                    onChangeProperty(
                                                        field,
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {fieldObject.enum?.map(
                                                    (value) => (
                                                        <MenuItem
                                                            value={value}
                                                            key={value}
                                                        >
                                                            {toCapitalizedWords(
                                                                value
                                                            )}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )
                            }
                            if (fieldObject.type === "boolean") {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={
                                                        config
                                                            ? config[field]
                                                            : false
                                                    }
                                                    onChange={(e, checked) =>
                                                        // @ts-expect-error
                                                        console.log(checked) ||
                                                        onChangeProperty(
                                                            field,
                                                            checked
                                                        )
                                                    }
                                                    name={field}
                                                />
                                            }
                                            labelPlacement="end"
                                            label={toCapitalizedWords(field)}
                                        />
                                    </Grid>
                                )
                            }
                            return <div key={index}>Unknown</div>
                        })}
                    </Grid>
                ) : (
                    <Loading padding={3} hideQuote={true} size={50} />
                )}
            </DialogContent>
            <DialogActions
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {!!schema?.options?.template &&
                    !widget?.isTemplate &&
                    widget?.type?.schema !==
                        WidgetTypeSchemaName.ExerciseSchema && (
                        <div className={classes.wrapper}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={handleTemplateSave}
                                disabled={savingTemplate}
                                style={{
                                    pointerEvents: templateSaved
                                        ? "none"
                                        : "auto",
                                }}
                            >
                                {!templateSaved ? (
                                    "Save Template"
                                ) : (
                                    <Box display="flex">
                                        <Box mr={0.5}>Saved</Box>
                                        <DoneIcon />
                                    </Box>
                                )}
                            </Button>
                            {savingTemplate && (
                                <CircularProgress
                                    size={25}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </div>
                    )}
                <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                    <Button onClick={() => props.onClose()}>Cancel</Button>
                    <Button
                        onClick={onSave}
                        color="primary"
                        variant="contained"
                        disabled={isSaving}
                    >
                        {isSaving ? (
                            <CircularProgress disableShrink={true} size={25} />
                        ) : (
                            "Save"
                        )}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
