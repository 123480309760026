import React from "react"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import { WidgetTypeComponent } from "../useWidgetTypes"
import useWidget from "../useWidget"
import { Variant } from "@material-ui/core/styles/createTypography"
import WidgetEditToolbar from "./common/WidgetEditToolbar"
import DragHandleIcon from "@material-ui/icons/DragHandle"
import DetailsField from "../../DetailsField"
import { useReactiveVar } from "@apollo/client"
import { permissionsVar } from "../../../providers/GlobalState"

type WidgetConfig = {
    label?: string
    size: "small" | "medium" | "large"
    showDivider?: boolean
}

const variantToSize: { [key in WidgetConfig["size"]]: Variant } = {
    small: "subtitle2",
    medium: "body1",
    large: "h6",
}

const HeadingWidget: WidgetTypeComponent = (props) => {
    const { config, onEditConfig } = useWidget<WidgetConfig>(props.widget.id)
    const label = config?.label ?? "No Label"
    const showDivider = config?.showDivider || false
    const editable = useReactiveVar(permissionsVar)
    const handleEdit = (val: string) => {
        onEditConfig({
            ...config,
            label: val,
        })
    }
    return (
        <Box display="flex" width="100%" flexDirection="column">
            <Box
                display="flex"
                justifyContent="space-between"
                color="hint"
                alignItems="center"
                flexWrap="wrap"
            >
                <Box padding={1} display="flex" alignItems="center">
                    {props.editing && (
                        <Box
                            display="flex"
                            flexGrow={0}
                            className="widget-drag-handle"
                            style={{ cursor: "move", paddingRight: "8px" }}
                        >
                            <DragHandleIcon />
                        </Box>
                    )}

                    <DetailsField
                        value={label}
                        handleEdit={handleEdit}
                        editable={editable}
                        grow={true}
                        className={
                            variantToSize[config?.size || "medium"] as string
                        }
                        maxLength={50}
                    />
                </Box>

                <Box flexGrow={1}>{showDivider && <Divider />}</Box>
                {props.editing && (
                    <Box display="flex" ml={1}>
                        <WidgetEditToolbar widget={props.widget} />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
HeadingWidget.defaultLayout = {
    w: 12,
    h: 1,
    minH: 1,
}

export default HeadingWidget
