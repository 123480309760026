import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Snackbar from "@material-ui/core/Snackbar"
import ShareIcon from "@material-ui/icons/Share"
import { IconButton, Box, Tooltip } from "@material-ui/core"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { Concept } from "../__generated__/types"
const useStyles = makeStyles((theme) => ({
    snackbar: {
        display: "flex",
        alignItems: "center",
    },
    snackbarIcon: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(1),
    },
}))

interface ShareConceptToggleProps {
    item: Concept
}

export default function ShareConceptToggle(props: ShareConceptToggleProps) {
    const [snackbar, setSnackbar] = useState(false)
    const classes = useStyles()
    const handleShare = () => {
        const input = document.createElement("input")
        input.value = window.location.href
        input.id = "url-input"
        document.body.appendChild(input)
        input.select()
        document.execCommand("copy")
        setSnackbar(true)
        document.body.removeChild(input)
    }
    const { t } = useAwaitTranslation("ideas")
    return (
        <>
            <Tooltip title="Share concept">
                <IconButton onClick={handleShare}>
                    <ShareIcon />
                </IconButton>
            </Tooltip>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                message={
                    <div className={classes.snackbar}>
                        <Box className={classes.snackbarIcon}>
                            <CheckCircleIcon />
                        </Box>
                        <Box>
                            {t(
                                "conceptLinkCopied",
                                "Concept link copied to clipboard!"
                            )}
                        </Box>
                    </div>
                }
                open={snackbar}
                onClose={() => {
                    setSnackbar(false)
                }}
                autoHideDuration={3000}
            />
        </>
    )
}
