import { Box, Paper, Typography } from "@material-ui/core"
import { WidgetTypeComponent } from "../useWidgetTypes"

const UnknownWidget: WidgetTypeComponent = (props) => {
    return (
        <Box
            component={Paper}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
        >
            <Typography>Unknown Widget Type</Typography>
        </Box>
    )
}

export default UnknownWidget
