import { Box, Paper } from "@material-ui/core"
import { _ConceptFilter, _ConceptOrdering } from "../../../__generated__/types"
import { useEffect, useState } from "react"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import { useParams } from "react-router-dom"
import { FilterItem } from "../../filters/util/types"
import { getFilterObject } from "../../filters/util/getFilterObject"
import WidgetFilterToggle from "./common/WidgetFilterToggle"
import useFilters from "../../filters/util/useFilters"
import { Criteria } from "../../../__generated__/types"
import { useQuery } from "@apollo/client"
import { CONCEPT_WITH_CRITERIA } from "../../criteria/graphql"
import {
    ConceptWithCriteriaQuery,
    ConceptWithCriteriaQueryVariables,
} from "../../criteria/__generated__/graphql"
import BubbleChart from "../../charts/BubbleChart"
import { Loading } from "../../Loading"
type WidgetConfig = {
    conceptId: string
    label: string
    orderBy: _ConceptOrdering
    filters?: FilterItem[]
    source: string
    criteria: Pick<Criteria, "name" | "criteriaType">[]
}

const BubbleChartWidget: WidgetTypeComponent = (props) => {
    const [filters, setFilters] = useState<FilterItem[]>([])
    const {
        defaultValue,
        defaultOptions,
        isInitialized: filtersInitialized,
    } = useFilters()
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const { conceptId } = useParams()
    useEffect(() => {
        if (!!filtersInitialized) {
            setFilters(defaultValue)
        }
    }, [defaultValue, filtersInitialized])
    const names = config?.criteria?.map((x) => x.name) ?? []
    const configuredFilters =
        config?.filters?.map((filter) => {
            return {
                ...filter,
            }
        }) ?? []
    let filterObject: _ConceptFilter = {
        ...getFilterObject(configuredFilters),
        ...getFilterObject(filters),
    }
    if (config?.source === "connections") {
        filterObject = {
            ...filterObject,
            connections_some: {
                id: conceptId,
            },
        }
    }

    const { data, error } = useQuery<
        ConceptWithCriteriaQuery,
        ConceptWithCriteriaQueryVariables
    >(CONCEPT_WITH_CRITERIA, {
        variables: {
            conceptFilter: filterObject,
            criteriaFilter: {
                name_in: names,
            },
        },
        skip: names.length === 0,
    })

    const concepts = data?.Concept ?? []
    const configuredFields =
        config?.filters?.map((filter) => filter.field) ?? []
    const filterOptions = defaultOptions?.filter(
        (filter) => !configuredFields.includes(filter.field)
    )
    const bubbleChartData = concepts.map((item) => {
        return {
            item: item,
            values:
                config?.criteria?.map((criteria) => {
                    return {
                        criteriaName: criteria.name,
                        value:
                            item?.criteria?.filter(
                                (x) => x.name === criteria.name
                            )?.[0]?.scores?.[0]?.value ?? (0 as number),
                    }
                }) ?? [],
        }
    })
    let actions = [
        <WidgetFilterToggle
            key={`${conceptId}_filter`}
            filters={filters.filter(
                (filter) => !configuredFields.includes(filter.field)
            )}
            isDialog={props.isDialog}
            setFilters={(filters: FilterItem[]) => setFilters(filters)}
            filterOptions={filterOptions}
        />,
    ]
    if (error) {
        return <Box>{error.message}</Box>
    }
    return (
        <Box
            component={Paper}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
        >
            <WidgetHeader
                label={config?.label}
                actions={actions}
                widget={props.widget}
                editing={props.editing}
            />
            {isInitialized && filterObject ? (
                <Box key={props.widget.id} overflow="hidden" height="100%">
                    {names?.length > 0 ? (
                        <BubbleChart
                            data={bubbleChartData}
                            axisOptions={names}
                        />
                    ) : (
                        <Box p={2} textAlign="center">
                            No criteria selected...
                        </Box>
                    )}
                </Box>
            ) : (
                <Loading size={75} hideQuote={true} />
            )}
        </Box>
    )
}

export default BubbleChartWidget
