import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import { useMutation, useQuery } from "@apollo/client"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import ConceptList from "./ConceptList"
import Tabs from "@material-ui/core/Tabs"
import { Loading } from "../Loading"
import { CHECKLIST_FILTERED, DELETE_CHECKLIST } from "../../graphql/Checklist"
import {
    ChecklistFilteredQuery,
    ChecklistFilteredQueryVariables,
    DeleteChecklistMutation,
    DeleteChecklistMutationVariables,
} from "../../graphql/__generated__/Checklist"
import { Concept } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    IconButton,
    Divider,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    CircularProgress,
} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import DeleteIcon from "@material-ui/icons/Delete"
import { useGlobalStyles } from "../../styles/globalStyles"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100%",
        padding: 0,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
    },
    textField: {
        paddingLeft: theme.spacing(1),
        fontSize: theme.typography.body2.fontSize,
    },
    listbox: {
        overflow: "auto",
    },
    listTitle: {
        fontSize: theme.typography.body1.fontSize,
    },
    listType: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
    },
    summary: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
    },
    avatar: {
        width: "1em",
        height: "1em",
    },
    clone: {
        zIndex: 100,
        backgroundColor: theme.palette.background.paper,
    },
    sortableList: {
        borderTop: "0.5px solid " + theme.palette.divider,
        borderBottom: "0.5px solid " + theme.palette.divider,
    },
    scroller: {
        overflowY: "hidden",
    },
    linkMenu: {
        padding: theme.spacing(1.5),
    },
}))

interface CheckListHubProps {
    item: Concept
}
export default function CheckListHub(props: CheckListHubProps) {
    const classes = useStyles()
    const globalClasses = useGlobalStyles()
    const { data, loading, error, refetch } = useQuery<
        ChecklistFilteredQuery,
        ChecklistFilteredQueryVariables
    >(CHECKLIST_FILTERED, {
        variables: {
            filter: {
                concept: {
                    id: props.item.id,
                },
                conceptBased: true,
            },
            conceptId: props.item.id,
        },
        fetchPolicy: "cache-and-network",
    })
    const [tab, setTab] = useState(0)
    const [lists, setLists] = useState(null)
    const currentList = lists?.[tab] ?? lists?.[lists.length - 1] ?? null
    const [deleteList, { loading: deletingList }] = useMutation<
        DeleteChecklistMutation,
        DeleteChecklistMutationVariables
    >(DELETE_CHECKLIST, {
        update(cache) {
            if (!!currentList) {
                cache.evict({
                    id: cache.identify(currentList),
                })
            }
        },
    })
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const [confirm, setConfirm] = useState(false)
    const open = Boolean(anchorEl)
    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const { t } = useAwaitTranslation("widgets")
    useEffect(() => {
        if (data) {
            let orderedItems = []
            let unorderedItems = []
            let itemsById = {}
            const items = data.Checklist
            const itemIds = data.Concept[0].checklistIds
            if (itemIds) {
                const ids = itemIds
                items.forEach((c) => {
                    itemsById[c.id] = c
                    let itemIdx = ids.indexOf(c.id)
                    if (itemIdx === -1) {
                        unorderedItems.push(c)
                    } else {
                        orderedItems[ids.indexOf(c.id)] = c
                    }
                })
            } else {
                unorderedItems = [...items]
            }
            let allItems = [...orderedItems, ...unorderedItems].filter(
                (c) => !!c
            )
            setLists(allItems)
        }
    }, [data])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
    }
    const handleDeletion = async () => {
        await deleteList({
            variables: {
                id: currentList?.id,
            },
        })
        await refetch()
        setConfirm(false)
    }
    if (error) {
        return (
            <Box>
                {t(
                    "listRetriveError",
                    "We were not able to retrieve these lists..."
                )}
            </Box>
        )
    }
    return (
        <>
            <Box className={classes.root}>
                {loading ? (
                    <Loading hideQuote={true} size={75} />
                ) : (
                    <>
                        {lists && (
                            <Tabs
                                value={
                                    tab !== lists.length
                                        ? tab
                                        : lists.length - 1
                                }
                                onChange={handleChange}
                                variant="scrollable"
                                textColor="primary"
                                indicatorColor="primary"
                                classes={{
                                    scroller: classes.scroller,
                                }}
                            >
                                {lists.map((item, idx) => (
                                    <Tab
                                        key={item.id + "-tab"}
                                        label={
                                            <Box fontSize={"0.875rem"}>
                                                {item.title}
                                            </Box>
                                        }
                                    />
                                ))}
                            </Tabs>
                        )}
                        {!!currentList && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                key={currentList?.checklistId}
                            >
                                <Divider />
                                <Box display="flex" justifyContent="flex-end">
                                    <IconButton onClick={handleButtonClick}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>
                                <Divider />
                                <ConceptList
                                    editPermission={true}
                                    checklist={currentList}
                                    draggable={false}
                                />
                            </Box>
                        )}
                    </>
                )}
            </Box>

            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null)
                        setConfirm(true)
                    }}
                >
                    <ListItemIcon className={globalClasses.smallMenuIcon}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            className: globalClasses.smallMenuListItemText,
                        }}
                        primary={"Delete list"}
                    />
                </MenuItem>
            </Menu>
            {!!confirm && (
                <Dialog open={true}>
                    <DialogContent>
                        Are you sure you want to delete this list?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setConfirm(false)}
                            disabled={loading || deletingList}
                        >
                            No
                        </Button>
                        <Button
                            onClick={handleDeletion}
                            disabled={loading || deletingList}
                        >
                            {loading || deletingList ? (
                                <CircularProgress
                                    size={25}
                                    disableShrink={true}
                                />
                            ) : (
                                "Yes"
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}
