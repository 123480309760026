import {
    createPlateOptions,
    ELEMENT_BLOCKQUOTE,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_LI,
    ELEMENT_OL,
    ELEMENT_PARAGRAPH,
    ELEMENT_UL,
    TNode,
} from "@udecode/plate"

export type SlateValue = TNode[]

const options = createPlateOptions()

function parseJson(str: string): unknown {
    try {
        return JSON.parse(str)
    } catch {
        return null
    }
}

export const createElement = (
    text = "",
    {
        type = ELEMENT_PARAGRAPH,
        mark,
    }: {
        type?: string
        mark?: string
    } = {}
) => {
    const leaf = { text }
    if (mark) {
        leaf[mark] = true
    }

    return {
        type,
        children: [leaf],
    }
}

function convertTypeToCompatibleType(type: string) {
    switch (type) {
        case "block-quote":
            return ELEMENT_BLOCKQUOTE
        case "bulleted-list":
            return ELEMENT_UL
        case "heading-one":
            return ELEMENT_H1
        case "heading-two":
            return ELEMENT_H2
        case "list-item":
            return ELEMENT_LI
        case "numbered-list":
            return ELEMENT_OL
        case "paragraph":
            return ELEMENT_PARAGRAPH
        default:
            return type
    }
}

// Due to us using slate.js before slate-plugins
// some data was stored in the database with the types that aren't compatible with slate-plugins
// such as "paragraph" instead of "p"
// to fix that, we iterate over each element and convert to the new format
// Eventually, we want to run a script in the backend to avoid doing this here
function makeDataCompatible(value: SlateValue) {
    if (!value) return [createElement()]

    return value.map((node) => {
        let result = node

        if (result.type) result.type = convertTypeToCompatibleType(node.type)
        if (result.children) {
            if (!result.type) result.type = ELEMENT_PARAGRAPH
            result.children = makeDataCompatible(node.children)
        }

        return result
    })
}

function isValidSlateValue(a: unknown): a is SlateValue {
    return Array.isArray(a)
        ? Boolean((a as SlateValue)[0]?.children)
        : Boolean((a as SlateValue[0])?.children)
}

export const convertValues = (inputValue: string): SlateValue | null => {
    let unknownValue = parseJson(inputValue)

    if (!unknownValue) return [createElement()]

    let value = isValidSlateValue(unknownValue) ? unknownValue : null

    return makeDataCompatible(value)
}
