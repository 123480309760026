import { Notification_Type } from "../../../__generated__/types"

export const getEnumLabel = (type: Notification_Type) => {
    switch (type) {
        case Notification_Type.CONCEPT_LIKED:
            return "Concept Liked"
        case Notification_Type.CONCEPT_UPDATED:
            return "Concept Updated"
        case Notification_Type.COMMENT_ADDED:
            return "Comment Added"
        case Notification_Type.COMMENT_REPLY_ADDED:
            return "Comment Reply Added"
    }
}
