import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Tooltip,
    Typography,
    useMediaQuery,
    Avatar,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import {
    CREATE_EXERCISE,
    GET_EXERCISE_TYPES,
    USER_EXERCISES_QUERY,
    ADD_EXERCISE_CREATED_BY,
} from "./graphql"
import {
    CreateExerciseMutation,
    CreateExerciseMutationVariables,
    ExerciseFragment,
    GetExerciseTypesQuery,
    GetExerciseTypesQueryVariables,
    GetUserExercisesQuery,
    GetUserExercisesQueryVariables,
    AddExerciseCreatedByMutation,
    AddExerciseCreatedByMutationVariables,
} from "./__generated__/graphql"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import { useAuth } from "../../providers/AuthProvider"
import ExerciseSetup from "./ExerciseSetup"
import AddIcon from "@material-ui/icons/Add"
import { Loading } from "../Loading"
import SettingsIcon from "@material-ui/icons/Settings"
import { ExerciseStatus } from "../../__generated__/types"

import ExerciseContainer from "./ExerciseContainer"
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
const useStyles = makeStyles((theme: Theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    cardContent: {
        flexGrow: 1,
    },
    flex: {
        display: "flex",
        padding: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
}))
// todo Implement "ExerciseTypeForm" in which a user should be able to enter a title, description, instructions, upload an image, and select a type - types listed below with synopsis of intention of type. This action should be done from this component ExerciseGallery
// Ranking (rank lists of provided concepts)
// Voting (boolean swipe right or left functionality)
// Scoring (to start off, offer scale of 1-10 for each user )
// Generating (users would be prompted to create a given amount of concepts within a time limit)

export const ExerciseGallery = () => {
    const { currentUser } = useAuth()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [exerciseSetupId, setExerciseSetupId] = useState<string | null>(null)
    const [currentExerciseId, setCurrentExerciseId] = useState<string | null>(
        null
    )
    const [results, viewResults] = useState(false)
    const [isCreating, setIsCreating] = useState(false)
    const { t } = useAwaitTranslation("exercises")
    const [createExercise] = useMutation<
        CreateExerciseMutation,
        CreateExerciseMutationVariables
    >(CREATE_EXERCISE)
    const [addCreatedBy] = useMutation<
        AddExerciseCreatedByMutation,
        AddExerciseCreatedByMutationVariables
    >(ADD_EXERCISE_CREATED_BY)
    const { loading: loadingUserExercises, data: dataUserExercises } = useQuery<
        GetUserExercisesQuery,
        GetUserExercisesQueryVariables
    >(USER_EXERCISES_QUERY, {
        variables: {
            userId: currentUser.userId,
        },
    })
    const { loading: loadingExerciseTypes, data: dataExerciseTypes } = useQuery<
        GetExerciseTypesQuery,
        GetExerciseTypesQueryVariables
    >(GET_EXERCISE_TYPES)

    const handleCreation = async () => {
        setIsCreating(true)
        const { data } = await createExercise({
            variables: {
                title:
                    currentUser?.username + `'s ${t("exercise", "Exercise")}`,
                description: "",
                duration: 0,
                status: ExerciseStatus.DRAFT,
            },
        })
        await addCreatedBy({
            variables: {
                fromId: data.CreateExercise.id,
                toId: currentUser.userId,
            },
        })

        setExerciseSetupId(data.CreateExercise.id)
        setIsCreating(false)
    }

    const exercises = dataUserExercises?.User[0]?.exercises ?? []
    const exerciseIds = []

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
            >
                <Typography variant="h4">
                    {t("exercises", "Exercises")}
                </Typography>
                {mobile ? (
                    <IconButton
                        onClick={() => {
                            handleCreation()
                        }}
                        color="primary"
                        disabled={isCreating}
                        style={{ float: "right", margin: "1em 0" }}
                    >
                        <AddIcon />
                    </IconButton>
                ) : (
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={isCreating}
                        style={{ float: "right", margin: "1em 0" }}
                        onClick={() => {
                            handleCreation()
                        }}
                    >
                        {t("createNewExercise", "Create New Exercise")}
                    </Button>
                )}
            </Box>
            {loadingUserExercises ? (
                <Loading size={150} />
            ) : (
                <Box display="flex" flexDirection="column">
                    {exercises.map((exercise) => {
                        if (exerciseIds.includes(exercise.id)) {
                            return null
                        } else {
                            exerciseIds.push(exercise.id)
                        }

                        return (
                            <ExerciseItem
                                key={exercise.id}
                                exercise={exercise}
                                onSetupOpen={() =>
                                    setExerciseSetupId(exercise.id)
                                }
                                onSelectionOpen={() =>
                                    setCurrentExerciseId(exercise.id)
                                }
                                onViewResults={() => {
                                    viewResults(true)
                                }}
                            />
                        )
                    })}
                </Box>
            )}
            {exerciseSetupId &&
                !!!loadingExerciseTypes &&
                !!dataExerciseTypes?.ExerciseType && (
                    <ExerciseSetup
                        exerciseId={exerciseSetupId}
                        onClose={() => setExerciseSetupId(null)}
                    />
                )}
            {currentExerciseId && (
                <ExerciseContainer
                    exerciseId={currentExerciseId}
                    onClose={() => {
                        viewResults(false)
                        setCurrentExerciseId(null)
                    }}
                    viewResults={() => viewResults(true)}
                    results={results}
                />
            )}
        </Box>
    )
}

const ExerciseItem = (props: {
    exercise: ExerciseFragment
    onSetupOpen: () => void
    onSelectionOpen: () => void
    onViewResults: () => void
}) => {
    const classes = useStyles()
    const item = props.exercise
    const image = item?.imageUrl || item?.type?.imageUrl
    return (
        <Card style={{ margin: "0.5em", flexGrow: 1 }}>
            <Box className={classes.flex}>
                {image && (
                    <Avatar
                        style={{
                            height: "10em",
                            width: "10em",
                            margin: "auto",
                        }}
                        src={image}
                        variant="rounded"
                        title={props.exercise?.title}
                    />
                )}
                <Box display="flex" flexDirection="column" flexGrow={1}>
                    <CardHeader
                        style={{
                            width: "100%",
                        }}
                        title={item.title}
                        subheader={item.type?.title}
                    />
                    <CardContent>
                        <Typography variant="body1">
                            {props.exercise?.description}
                        </Typography>
                    </CardContent>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignSelf="flex-end"
                >
                    <Tooltip title="View exercise setup">
                        <IconButton
                            onClick={() => {
                                props.onSetupOpen()
                            }}
                        >
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View past results">
                        <span>
                            <IconButton
                                onClick={() => {
                                    props.onViewResults()
                                    props.onSelectionOpen()
                                }}
                            >
                                <AssignmentTurnedInIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Complete exercise">
                        <span>
                            <IconButton
                                onClick={() => {
                                    props.onSelectionOpen()
                                }}
                            >
                                <PlayCircleFilledWhiteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
        </Card>
    )
}
