import { gql, useQuery } from "@apollo/client"
import { useMentionPlugin } from "@udecode/plate"
import { useEffect, useMemo } from "react"
import {
    FuzzySearchConceptQuery,
    FuzzySearchConceptQueryVariables,
} from "./__generated__/useConceptLink"

const FUZZY_SEARCH_CONCEPT = gql`
    query FuzzySearchConcept($searchString: String) {
        fuzzyConceptSearch(searchString: $searchString) {
            abstractedId: id
            title
            value: title
            conceptType: type
            imageUrl
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            summary
        }
    }
`

export const ELEMENT_CONCEPT = "concept"

const useConceptLink = () => {
    const { data, refetch } = useQuery<
        FuzzySearchConceptQuery,
        FuzzySearchConceptQueryVariables
    >(FUZZY_SEARCH_CONCEPT, {
        variables: {
            // We need an initial search string for when the component is empty (first search)
            searchString: "a",
        },
    })

    const result = useMentionPlugin({
        trigger: "[[",
        maxSuggestions: 10,
        mentionableSearchPattern: "\\S*",
        pluginKey: ELEMENT_CONCEPT,
        mentionables: data?.fuzzyConceptSearch ?? [],
    })

    useEffect(() => {
        let searchString = result.searchValue || "a"
        // Instead of updating the search string directly in the hook using state
        // we refetch the query with different to force "data" to stay the same even when fetching new data
        // since it uses the variable set on the hook, and not the one refetched
        refetch({ searchString })
    }, [result.searchValue, refetch])
    return useMemo(() => {
        const { getMentionSelectProps, ...others } = result

        return { ...others, getConceptSelectProps: getMentionSelectProps }
    }, [result])
}

export default useConceptLink
