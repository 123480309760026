import { Box, Paper } from "@material-ui/core"
import { _ConceptFilter, _ConceptOrdering } from "../../../__generated__/types"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import ConceptInfiniteScroll from "../../ConceptInfiniteScroll"
import { useParams } from "react-router-dom"
import { FilterItem } from "../../filters/util/types"
import { getFilterObject } from "../../filters/util/getFilterObject"
import { useState } from "react"
import useFilters from "../../filters/util/useFilters"
import WidgetFilterToggle from "./common/WidgetFilterToggle"
import ConceptGallery from "../../ConceptGallery"

type WidgetConfig = {
    label: string
    orderBy: _ConceptOrdering
    filters?: FilterItem[]
    source: string
    view: string
}

const ConceptFeedWidget: WidgetTypeComponent = (props) => {
    const { conceptId } = useParams()
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const [filters, setFilters] = useState<FilterItem[]>([])
    const {
        defaultValue,
        defaultOptions,
        isInitialized: filtersInitialized,
    } = useFilters()
    const configuredFilters =
        config?.filters?.map((filter) => {
            return {
                ...filter,
            }
        }) ?? []

    let filterObject: _ConceptFilter = {
        ...getFilterObject(configuredFilters),
        ...getFilterObject(filters),
        isPublic: true,
    }
    if (config?.source === "connections") {
        filterObject = {
            ...filterObject,
            connections_some: {
                id: conceptId,
            },
        }
    }

    const configuredFields =
        config?.filters?.map((filter) => filter.field) ?? []
    const filterOptions = defaultOptions.filter(
        (filter) => !configuredFields.includes(filter.field)
    )
    let actions = [
        <WidgetFilterToggle
            size="medium"
            key="filter"
            isDialog={props.isDialog}
            filters={filters.filter(
                (filter) => !configuredFields.includes(filter.field)
            )}
            setFilters={(filters: FilterItem[]) => setFilters(filters)}
            filterOptions={filterOptions}
        />,
    ]

    return (
        <Box
            component={Paper}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            pb={2}
            key={props.widget.id}
        >
            <WidgetHeader
                label={config?.label}
                actions={actions}
                widget={props.widget}
                editing={props.editing}
            />
            {isInitialized && filterObject && (
                <Box key={props.widget.id} overflow="hidden" flexGrow={1}>
                    {config?.view === "gallery" ? (
                        <ConceptGallery
                            editPermission={true}
                            conceptId={conceptId}
                            filter={filterObject}
                            orderBy={
                                config?.orderBy ??
                                ("createdAt_desc" as _ConceptOrdering)
                            }
                        />
                    ) : (
                        <ConceptInfiniteScroll
                            editPermission={true}
                            conceptId={conceptId}
                            filter={filterObject}
                            orderBy={
                                config?.orderBy ??
                                ("createdAt_desc" as _ConceptOrdering)
                            }
                        />
                    )}
                </Box>
            )}
        </Box>
    )
}

export default ConceptFeedWidget
