import React from "react"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import FaceIcon from "@material-ui/icons/Face"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import LanguageIcon from "@material-ui/icons/Translate"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FeedbackIcon from "@material-ui/icons/Feedback"
import {
    Avatar,
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Switch,
} from "@material-ui/core"
import { getDarkModeIcon } from "../util/DarkModeIcon"
import { useTranslation } from "react-i18next"
import SettingsIcon from "@material-ui/icons/Settings"
import GroupIcon from "@material-ui/icons/Group"
import { SystemRole } from "../__generated__/types"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            zIndex: theme.zIndex.appBar + 1,
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        avatar: {
            border: `solid 1px ${theme.palette.secondary.main}`,
        },
        darkModeToggle: {
            display: "flex",
            justifyContent: "flex-start",
        },
        ellipsis: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        imageFallback: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
        },
    })
)

export default function HeaderAvatarMenu({
    history,
    currentUser,
    handleLogout,
    handleDarkModeToggle,
    languagePickerVisible,
    setLanguagePickerVisible,
    theme,
}) {
    const classes = useStyles()

    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLButtonElement>(null)

    const { t } = useTranslation("settings")
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }
        setOpen(false)
    }

    const handleNavigation = (path: string) => {
        setOpen(false)
        history.push(path)
    }
    const openUsersnap = () => {
        try {
            if (window["Usersnap"]) {
                window["Usersnap"].open()
            } else {
                console.log("Usersnap not available")
            }
        } catch {
            console.log("Usersnap not available")
        }
    }
    const DarkModeIcon = getDarkModeIcon(theme.palette.type)
    const imageFallback: string =
        (currentUser?.firstName &&
            currentUser?.lastName &&
            currentUser.firstName[0] + currentUser.lastName[0]) ??
        ""

    return (
        <div className={classes.root}>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                {currentUser?.imageUrl ? (
                    <Avatar
                        alt={currentUser?.username}
                        src={currentUser?.imageUrl}
                        className={classes.avatar}
                    />
                ) : (
                    <Avatar className={classes.imageFallback}>
                        {imageFallback}
                    </Avatar>
                )}
            </IconButton>
            {open && (
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                            }}
                        >
                            <Paper elevation={12} style={{ maxWidth: "350px" }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="avatar-menu-list">
                                        <MenuItem
                                            onClick={() =>
                                                handleNavigation(
                                                    `/user/${currentUser.username}/home`
                                                )
                                            }
                                        >
                                            <ListItemIcon>
                                                <FaceIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {t("profile", "Profile")}
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                handleNavigation(`/teams`)
                                            }
                                        >
                                            <ListItemIcon>
                                                <GroupIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                My Teams
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                handleNavigation(`/myaccount`)
                                            }
                                        >
                                            <ListItemIcon>
                                                <AccountBoxIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {t("myAccount", "My Account")}
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            className={classes.darkModeToggle}
                                            onClick={handleDarkModeToggle}
                                        >
                                            <ListItemIcon>
                                                {DarkModeIcon}
                                            </ListItemIcon>
                                            <ListItemText primary="Dark Mode" />
                                            <Box ml={3}>
                                                <Switch
                                                    checked={
                                                        theme.palette.type ===
                                                        "dark"
                                                    }
                                                    value={
                                                        theme.palette.type ===
                                                        "dark"
                                                    }
                                                    onChange={
                                                        handleDarkModeToggle
                                                    }
                                                    name="prefersDarkMode"
                                                    inputProps={{
                                                        "aria-label":
                                                            "Dark Mode?",
                                                    }}
                                                />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem
                                            className={classes.darkModeToggle}
                                            onClick={() =>
                                                setLanguagePickerVisible(
                                                    (isVisible) => {
                                                        return !isVisible
                                                    }
                                                )
                                            }
                                        >
                                            <ListItemIcon>
                                                {<LanguageIcon />}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={t(
                                                    "showLanguage",
                                                    "Show Language Picker"
                                                )}
                                            />
                                            <Box ml={3}>
                                                <Switch
                                                    checked={
                                                        languagePickerVisible
                                                    }
                                                    value={
                                                        languagePickerVisible
                                                    }
                                                    name="languagePickerVisible"
                                                    inputProps={{
                                                        "aria-label": t(
                                                            "showLanguagePicker",
                                                            "Show Language Picker?"
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        </MenuItem>

                                        <MenuItem
                                            key="feedback"
                                            onClick={() => openUsersnap()}
                                        >
                                            <ListItemIcon>
                                                <FeedbackIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Share Feedback" />
                                        </MenuItem>
                                        {currentUser?.role ===
                                            SystemRole.ADMIN && (
                                            <MenuItem
                                                onClick={() =>
                                                    handleNavigation(
                                                        `/settings`
                                                    )
                                                }
                                            >
                                                <ListItemIcon>
                                                    <SettingsIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Settings" />
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            onClick={() => handleLogout()}
                                        >
                                            <ListItemIcon>
                                                <ExitToAppIcon
                                                    style={{
                                                        transform:
                                                            "rotate(180deg)",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Logout" />
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </div>
    )
}
