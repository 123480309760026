import React, { useRef } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { useLazyQuery } from "@apollo/client"
import { getConceptTypeIcon } from "../util/ConceptTypeStyles"
import { Box, IconButton } from "@material-ui/core"
import { useHotkeys } from "react-hotkeys-hook"
import SearchIcon from "@material-ui/icons/Search"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import { useHistory } from "react-router-dom"
import { SEARCH_CONCEPTS } from "../graphql/conceptListQueries"
import { useAuth } from "../providers/AuthProvider"
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    searchBar: {
        width: 0,
        overflow: "hidden",
        // marginRight: 5,
        flexGrow: 0,
    },
    autoFocusedSearchBar: {
        flexGrow: 1,
        width: "100%",
    },
    searchBarFocused: {
        flexGrow: 1,
    },
    popper: {
        zIndex: 1500,
    },
    listbox: {
        overflow: "auto",
    },
}))

interface ConceptInterface {
    id: string
    __typename: string
    title: string
    type: string
    summary: string
    description: string
}

interface SearchInterface {
    autoFocus?: boolean
    setOpen?: Function
}
export default function Search(props: SearchInterface) {
    const classes = useStyles()
    const [value, setValue] = React.useState<ConceptInterface | null>(null)
    const [inputValue, setInputValue] = React.useState("")
    const [options, setOptions] = React.useState<ConceptInterface[]>([])
    const [loadResults, { loading, error, data }] = useLazyQuery(
        SEARCH_CONCEPTS
    )
    const { currentUser } = useAuth()
    const history = useHistory()
    const ref: any = useRef()
    useHotkeys("s,u", setFocus)
    useHotkeys("ctrl+s,command+s,ctrl+u,command+u", setFocus, {
        enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    })

    function setFocus(event) {
        event.preventDefault()
        if (ref && ref.current) {
            ref.current.focus()
            return false
        } else {
            // console.log(event, ref)
        }
    }
    React.useEffect(() => {
        let active = true
        if (active) {
            if (!inputValue || inputValue === "") {
                setOptions(value ? [value] : [])
                return undefined
            }
            loadResults({ variables: { searchString: inputValue } })
            if (data && active) {
                let newOptions = [] as ConceptInterface[]
                if (value) {
                    newOptions = [value]
                }

                if (data) {
                    newOptions = [
                        ...newOptions,
                        ...data.fuzzyConceptSearch,
                    ]?.filter(
                        (x) =>
                            x.createdBy?.userId === currentUser?.userId ||
                            !!x.isPublic
                    )
                }
                if (active) {
                    setOptions(newOptions)
                }
            }
        }
        return () => {
            active = false
        }
    }, [value, inputValue, loadResults, data, currentUser])

    return (
        <Box className={classes.root}>
            <IconButton onClick={(ev) => setFocus(ev)}>
                <SearchIcon />
            </IconButton>
            <Autocomplete
                id="concept-search"
                classes={{
                    focused: classes.searchBarFocused,
                    popper: classes.popper,
                    listbox: classes.listbox,
                }}
                className={
                    props.autoFocus
                        ? classes.autoFocusedSearchBar
                        : classes.searchBar
                }
                size="small"
                getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.title
                }
                filterOptions={(x) => x}
                options={options}
                loading={loading}
                // openOnFocus={true}
                noOptionsText="No results"
                // includeInputInList
                forcePopupIcon={false}
                selectOnFocus
                filterSelectedOptions
                // groupBy={(option)=>option.type}
                value={value}
                blurOnSelect={true}
                clearOnBlur={true}
                onChange={(event: any, newValue: any, reason: any) => {
                    if (reason === "select-option") {
                        if (props.setOpen) {
                            props.setOpen(false)
                        }
                        history.push(`/concept/${newValue.id}/home`)
                        setOptions(options)
                        setValue(null)
                    } else {
                        setOptions(newValue ? [newValue, ...options] : options)
                        setValue(newValue)
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                renderInput={(params) => (
                    <TextField
                        inputRef={ref}
                        {...params}
                        variant="standard"
                        fullWidth
                        placeholder="Search"
                        autoFocus={props.autoFocus}
                    />
                )}
                renderOption={(option) => {
                    const matches = match(option.title, inputValue)
                    const parts = parse(option.title, matches)
                    const TypeIcon = getConceptTypeIcon(
                        option.rootCategory?.name
                    )
                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                {TypeIcon ? (
                                    <TypeIcon className={classes.icon} />
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight
                                                ? 800
                                                : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {option.summary}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }}
            />
        </Box>
    )
}
