import {
    ListItem,
    makeStyles,
    Typography,
    ListItemSecondaryAction,
    IconButton,
    Box,
    ListItemText,
    ListItemIcon,
    Snackbar,
    Tooltip,
} from "@material-ui/core"
import { useMutation } from "@apollo/client"
import React, { useState } from "react"
import { Link as LinkRouter } from "react-router-dom"
import { Notification, Notification_Type } from "../../__generated__/types"
import { DELETE_NOTIFICATION } from "./graphql"
import {
    UserNotificationsQuery,
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables,
} from "./__generated__/graphql"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import DeleteIcon from "@material-ui/icons/Delete"
import { REMOVE_USER_WATCHED_CONCEPT } from "../alert-subscriptions/graphql"
import {
    RemoveUserWatchedConceptsMutation,
    RemoveUserWatchedConceptsMutationVariables,
} from "../alert-subscriptions/__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff"
import dayjs from "dayjs"
import { useGlobalStyles } from "../../styles/globalStyles"
const useStyles = makeStyles((theme) => ({
    paper: {
        transformOrigin: "top right",
    },
    list: {
        width: theme.spacing(50),
        maxHeight: theme.spacing(60),
        overflow: "auto",
        padding: 0,
    },
    listItem: {
        display: "flex",
        flexDirection: "column",
    },
    loading: {
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(1, 0),
    },
    divider: {
        margin: 0,
    },
    count: {
        height: "1em",
        width: "1em",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
    },
}))

function getNotificationMessage(
    notification: UserNotificationsQuery["User"][0]["notifications"][0]
) {
    let user =
        notification.actionBy?.firstName + " " + notification.actionBy?.lastName
    if (!!user) {
        switch (notification.type) {
            case Notification_Type.CONCEPT_LIKED:
                return `Liked by ${user}`
            case Notification_Type.CONCEPT_UPDATED:
                return `Updated by ${user}`
            case Notification_Type.COMMENT_ADDED:
                return `New comment from ${user}`
            case Notification_Type.COMMENT_REPLY_ADDED:
                return `New reply from ${user}`
        }
    } else {
        return null
    }
}

interface NotificationItemProps {
    notification: Notification
    viewed: boolean
    onClose: () => void
}
export default function NotificationItem(props: NotificationItemProps) {
    const { currentUser } = useAuth()
    const globalClasses = useGlobalStyles()
    const classes = useStyles()
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [removeSubscription] = useMutation<
        RemoveUserWatchedConceptsMutation,
        RemoveUserWatchedConceptsMutationVariables
    >(REMOVE_USER_WATCHED_CONCEPT)
    const [deleteNotification] = useMutation<
        DeleteNotificationMutation,
        DeleteNotificationMutationVariables
    >(DELETE_NOTIFICATION, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.notification),
            })
        },
    })
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleSubscriptionRemoval = async () => {
        handleClose()
        await removeSubscription({
            variables: {
                fromId: currentUser.userId,
                toId: props.notification.concept?.id,
            },
        }).catch((e) => {
            console.log(e)
        })
        setSnackbarOpen(true)
    }
    const handleDeletion = () => {
        handleClose()
        deleteNotification({
            variables: {
                id: props.notification.id,
            },
        })
    }
    const handleSnackbarClose = (
        event: React.SyntheticEvent | React.MouseEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return
        }

        setSnackbarOpen(false)
    }
    const [hovered, setHovered] = useState(false)
    return (
        <Box
            key={props.notification.id}
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <ListItem
                component={LinkRouter}
                to={`/concept/${props.notification.concept?.id}/home`}
                button
                alignItems="flex-start"
                className={classes.listItem}
                onClick={() => props.onClose()}
            >
                <Typography
                    color="primary"
                    component="b"
                    gutterBottom
                    variant="body2"
                    style={{
                        flexGrow: 1,
                    }}
                >
                    {props.notification.concept?.title}
                </Typography>

                <Typography gutterBottom>
                    {getNotificationMessage(props.notification)}
                </Typography>

                {props.notification.createdAt && (
                    <Tooltip
                        title={new Date(
                            props.notification.createdAt.formatted
                        ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                        })}
                    >
                        <Typography variant="caption" color="textSecondary">
                            {dayjs(
                                props.notification.createdAt?.formatted
                            ).fromNow()}
                        </Typography>
                    </Tooltip>
                )}
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={handleClick}
                        style={{
                            visibility: hovered ? "visible" : "hidden",
                        }}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    component="li"
                    divider
                    onClick={handleSubscriptionRemoval}
                >
                    <ListItemIcon className={globalClasses.smallMenuIcon}>
                        <NotificationsOffIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            className: globalClasses.smallMenuListItemText,
                        }}
                        primary="Unsubscribe"
                    />
                </MenuItem>
                <MenuItem component="li" divider onClick={handleDeletion}>
                    <ListItemIcon className={globalClasses.smallMenuIcon}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            className: globalClasses.smallMenuListItemText,
                        }}
                        primary="Delete"
                    />
                </MenuItem>
            </Menu>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={
                    "You will no longer receive notifications for " +
                    props.notification.concept?.title
                }
            />
        </Box>
    )
}
