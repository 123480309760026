import React, { useState, useEffect } from "react"
import { Box, Typography } from "@material-ui/core"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { makeStyles } from "@material-ui/core/styles"

import DatePickerPopper from "./DatePickerPopper"
import { Concept } from "../__generated__/types"
const useStyles = makeStyles((theme) => ({
    paperPopper: {
        padding: theme.spacing(1),
    },
    valueContainer: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
    },
    clickable: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
        },
        cursor: "pointer",
    },
}))
var months: string[] = []
months[0] = "January"
months[1] = "February"
months[2] = "March"
months[3] = "April"
months[4] = "May"
months[5] = "June"
months[6] = "July"
months[7] = "August"
months[8] = "September"
months[9] = "October"
months[10] = "November"
months[11] = "December"

interface ConceptDatesProps {
    concept: Concept
    editPermission: boolean
}
export default function ConceptDates(props: ConceptDatesProps) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [startValue, setStartValue] = useState<Date>(new Date())
    const [endValue, setEndValue] = useState<Date>(new Date())
    useEffect(() => {
        if (props.concept.startDate.formatted) {
            setStartValue(new Date(props.concept.startDate.formatted))
        }
        if (props.concept.endDate.formatted) {
            setEndValue(new Date(props.concept.endDate.formatted))
        }
    }, [props.concept])
    const startMonth = months[startValue.getMonth()]
    const startYear = startValue.getFullYear()
    const endMonth = months[endValue.getMonth()]
    const endYear = endValue.getFullYear()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const open = Boolean(anchorEl)
    return (
        <>
            <Box
                className={props.editPermission ? classes.clickable : ""}
                p={0.5}
                onClick={handleClick}
            >
                <Box className={classes.valueContainer}>
                    <Typography variant="body1">
                        {startMonth} {startYear}
                    </Typography>
                    <ArrowRightAltIcon />
                    <Typography variant="body1">
                        {endMonth} {endYear}
                    </Typography>
                </Box>
            </Box>
            {open && (
                <DatePickerPopper
                    onClose={() => setAnchorEl(null)}
                    item={props.concept}
                    anchorEl={anchorEl}
                />
            )}
        </>
    )
}
