import { gql } from "@apollo/client"

export const MY_CONCEPTS_QUERY = gql`
    query MyConceptsQuery($filter: _ConceptFilter, $offset: Int, $first: Int) {
        Concept(
            orderBy: createdAt_desc
            filter: $filter
            first: $first
            offset: $offset
        ) {
            id
            title
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            summary
            description
            isPublic
            imageUrl
            usesDashboard {
                id
                name
                layout
            }
            createdAt {
                formatted
            }
            likedBy {
                userId
                username
            }
            tags(orderBy: name_asc) {
                name
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            connections {
                id
                title
                summary
                imageUrl
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
            }
        }
    }
`
export const IDEAS_FEED_QUERY = gql`
    query IdeasFeed($offset: Int, $first: Int, $filter: _ConceptFilter) {
        Concept(
            orderBy: createdAt_desc
            first: $first
            offset: $offset
            filter: $filter
            isPublic: true
        ) {
            id
            title
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            summary
            description
            isPublic
            imageUrl
            commentCount
            usesDashboard {
                id
                name
                layout
            }
            createdAt {
                formatted
            }
            likedBy {
                userId
                username
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            connections {
                id
                title
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                imageUrl
            }
            tags(orderBy: name_asc) {
                name
            }
        }
    }
`

export const CONCEPT_BY_ID = gql`
    query Concept($id: ID) {
        Concept(id: $id) {
            id
            title
            summary
            type
            description
            isPublic
            isPublicAccessType
            imageUrl
            commentCount
            category {
                id
                name
                isRoot
                parent {
                    id
                    name
                    isRoot
                }
            }
            rootCategory {
                id
                name
                isRoot
            }
            startDate {
                formatted
            }
            endDate {
                formatted
            }
            usesDashboard {
                id
                name
                layout
                description
            }
            homeDashboard {
                id
                name
                layout
                description
            }
            dashboardIds
            likedBy {
                userId
                username
            }
            tags(orderBy: name_asc) {
                name
            }
            createdAt {
                formatted
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            webLink {
                id
                url
                title
                icon
                provider
            }
            connections {
                id
                title
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                imageUrl
            }
            industries {
                industryId
                name
            }
            addedUsers {
                type
                User {
                    userId
                    firstName
                    lastName
                    imageUrl
                }
            }
            addedTeams {
                type
                Team {
                    teamId
                    name
                    imageUrl
                    members {
                        userId
                    }
                }
            }
        }
    }
`
export const ALL_CONCEPT_CONNECTIONS = gql`
    query BasicConceptConnections($id: ID) {
        Concept(id: $id) {
            id
            connections {
                id
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
            }
        }
    }
`

export const CONNECTION_QUERY = gql`
    query ConceptConnections(
        $offset: Int
        $first: Int
        $connectionsFilter: _ConceptFilter
    ) {
        Concept(
            filter: $connectionsFilter
            first: $first
            offset: $offset
            orderBy: title_asc
        ) {
            id
            title
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            summary
            imageUrl
        }
    }
`

export const PERSONA_LIST_QUERY = gql`
    query ConceptPersonas($id: ID!, $connectionsFilter: _ConceptFilter) {
        Concept(id: $id) {
            id
            title
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            connections(filter: $connectionsFilter, orderBy: [title_desc]) {
                id
                title
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                summary
                imageUrl
            }
        }
    }
`
export const CONCEPT_CHILDREN = gql`
    query ConceptChildren($id: ID) {
        Concept(filter: { parent: { id: $id } }, orderBy: startDate_asc) {
            id
            title

            summary
            description
            isPublic
            imageUrl
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            createdAt {
                formatted
            }
            startDate {
                formatted
            }
            endDate {
                formatted
            }
            imageUrl
            createdBy {
                userId
                firstName
                lastName
                imageUrl
                username
            }
            likedBy {
                userId
            }
            connections {
                id
            }
            parent {
                id
                title
            }
            webLink {
                id
                url
                title
                icon
                provider
            }
            children {
                id
                title

                summary
                description
                isPublic
                imageUrl
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                createdAt {
                    formatted
                }
                connections {
                    id
                }
                startDate {
                    formatted
                }
                endDate {
                    formatted
                }
                imageUrl
                createdBy {
                    userId
                    firstName
                    lastName
                    imageUrl
                    username
                }
                likedBy {
                    userId
                }
                parent {
                    id
                    title
                }
                webLink {
                    id
                    url
                    title
                    icon
                    provider
                }
            }
        }
    }
`

export const CONCEPT_CHILDREN_BY_IDS = gql`
    query ConceptChildrenByIds($conceptFilter: _ConceptFilter) {
        Concept(filter: $conceptFilter) {
            id
            title

            summary
            description
            isPublic
            imageUrl
            createdAt {
                formatted
            }
            startDate {
                formatted
            }
            endDate {
                formatted
            }
            imageUrl
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            createdBy {
                userId
                firstName
                lastName
                imageUrl
                username
            }
            likedBy {
                userId
            }
            connections {
                id
            }
            children {
                id
                title
                summary
                imageUrl
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
            }
            connections {
                id
            }
            parent {
                id
                title

                summary
                imageUrl
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
            }
        }
    }
`

export const CONNECTION_WIDGET_QUERY = gql`
    query ConnectionWidgetQuery(
        $orderBy: [_ConceptOrdering]
        $offset: Int
        $first: Int
        $connectionsFilter: _ConceptFilter
    ) {
        Concept(
            orderBy: $orderBy
            filter: $connectionsFilter
            first: $first
            offset: $offset
        ) {
            id
            title
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            summary
            description
            isPublic
            imageUrl
            commentCount
            usesDashboard {
                id
                name
                layout
            }
            createdAt {
                formatted
            }
            likedBy {
                userId
                username
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            connections {
                id
                title
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                imageUrl
            }
            tags(orderBy: name_asc) {
                name
            }
        }
    }
`

export const BASE_CONCEPT_FILTERED = gql`
    query BaseConceptFiltered(
        $orderBy: [_ConceptOrdering]
        $filter: _ConceptFilter
    ) {
        Concept(orderBy: $orderBy, filter: $filter) {
            id
            title
            summary
            imageUrl
            type
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            createdAt {
                formatted
            }
        }
    }
`
