import { gql } from "@apollo/client"
import { CRITERIA_FRAGMENT, CRITERIA_SCORE_FRAGMENT } from "../criteria/graphql"
export const EXERCISE_FRAGMENT = gql`
    fragment Exercise on Exercise {
        id
        title
        description
        instructions
        timerType
        imageUrl
        duration
        status
        isPublic
        createdAt {
            formatted
        }
        createdBy {
            userId
        }
        type {
            id
            title
            instructions
            imageUrl
            category
        }
        facilitators {
            userId
            firstName
            lastName
            imageUrl
        }
        invitedTeams {
            teamId
            name
            imageUrl
        }
        participants {
            userId
            username
            firstName
            lastName
            imageUrl
        }
        submissions {
            id
            notes
            conceptIds
            imageUrl
            status
            exercise {
                id
                title
                description
            }
            user {
                userId
                firstName
                lastName
                username
                imageUrl
            }
            criteriaScores {
                ...CriteriaScore
            }
        }
        criteria {
            ...Criteria
        }
        includedChecklist {
            id
            title
            concept {
                id
                title
            }
            type {
                id
                conceptBased
            }
            items {
                id
                concept {
                    id
                    __typename
                    title
                    type
                    summary
                    description
                    isPublic
                    imageUrl
                    commentCount
                    usesDashboard {
                        id
                        name
                        layout
                    }
                    createdAt {
                        formatted
                    }
                    category {
                        id
                        name
                        isRoot
                    }
                    rootCategory {
                        id
                        name
                        isRoot
                    }
                    likedBy {
                        userId
                        username
                    }
                    createdBy {
                        userId
                        username
                        firstName
                        lastName
                        imageUrl
                    }
                    connections {
                        id
                        title
                        type
                        imageUrl
                    }
                    tags(orderBy: name_asc) {
                        name
                    }
                }
            }
        }
        includedConcepts {
            id
            __typename
            title
            type
            summary
            description
            isPublic
            imageUrl
            commentCount
            usesDashboard {
                id
                name
                layout
            }
            createdAt {
                formatted
            }
            category {
                id
                name
                isRoot
            }
            rootCategory {
                id
                name
                isRoot
            }
            likedBy {
                userId
                username
            }
            createdBy {
                userId
                username
                firstName
                lastName
                imageUrl
            }
            connections {
                id
                title
                type
                imageUrl
            }
            tags(orderBy: name_asc) {
                name
            }
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
    ${CRITERIA_FRAGMENT}
`

export const EXERCISE_SUBMISSION_FRAGMENT = gql`
    fragment ExerciseSubmission on ExerciseSubmission {
        id
        notes
        conceptIds
        imageUrl
        status
        exercise {
            ...Exercise
        }
        user {
            userId
            firstName
            lastName
            username
        }
        criteriaScores {
            ...CriteriaScore
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
    ${EXERCISE_FRAGMENT}
`

const GET_EXERCISE_TYPES = gql`
    query getExerciseTypes {
        ExerciseType {
            id
            title
            description
            instructions
            imageUrl
            category
            exercises {
                ...Exercise
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`

const CREATE_EXERCISE_TYPE = gql`
    mutation CreateExerciseType(
        $title: String
        $description: String
        $instructions: String
        $imageUrl: String
        $category: ExerciseCategory
    ) {
        CreateExerciseType(
            title: $title
            description: $description
            instructions: $instructions
            imageUrl: $imageUrl
            category: $category
        ) {
            id
            title
            instructions
            description
            imageUrl
            category
        }
    }
`
const UPDATE_EXERCISE_TYPE = gql`
    mutation UpdateExerciseType(
        $id: ID!
        $title: String
        $description: String
        $instructions: String
        $imageUrl: String
        $category: ExerciseCategory
    ) {
        UpdateExerciseType(
            id: $id
            title: $title
            description: $description
            instructions: $instructions
            imageUrl: $imageUrl
            category: $category
        ) {
            id
            title
            instructions
            description
            imageUrl
            category
        }
    }
`

const GET_USER_CREATED_EXERCISES = gql`
    query getExercises {
        Exercise {
            ...Exercise
        }
    }
    ${EXERCISE_FRAGMENT}
`
const CREATE_EXERCISE = gql`
    mutation CreateExercise(
        $title: String
        $description: String
        $duration: Int
        $status: ExerciseStatus
    ) {
        CreateExercise(
            title: $title
            description: $description
            duration: $duration
            status: $status
        ) {
            ...Exercise
        }
    }
    ${EXERCISE_FRAGMENT}
`

const UPDATE_EXERCISE = gql`
    mutation UpdateExercise(
        $id: ID!
        $title: String
        $description: String
        $duration: Int
        $isPublic: Boolean
    ) {
        UpdateExercise(
            id: $id
            title: $title
            description: $description
            duration: $duration
            isPublic: $isPublic
        ) {
            ...Exercise
        }
    }
    ${EXERCISE_FRAGMENT}
`

const ADD_EXERCISE_PARTICIPANT = gql`
    mutation AddParticipant($fromId: ID!, $toId: ID!) {
        AddExerciseParticipants(from: { id: $fromId }, to: { userId: $toId }) {
            from {
                ...Exercise
            }
            to {
                userId
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`
const ADD_EXERCISE_CREATED_BY = gql`
    mutation AddExerciseCreatedBy($fromId: ID!, $toId: ID!) {
        AddExerciseCreatedBy(from: { id: $fromId }, to: { userId: $toId }) {
            from {
                ...Exercise
            }
            to {
                userId
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`

const REMOVE_EXERCISE_PARTICIPANT = gql`
    mutation RemoveParticipant($fromId: ID!, $toId: ID!) {
        RemoveExerciseParticipants(
            from: { id: $fromId }
            to: { userId: $toId }
        ) {
            from {
                ...Exercise
            }
            to {
                userId
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`

const ADD_EXERCISE_FACILITATOR = gql`
    mutation AddFacilitator($fromId: ID!, $toId: ID!) {
        AddExerciseFacilitators(from: { id: $fromId }, to: { userId: $toId }) {
            from {
                ...Exercise
            }
            to {
                userId
            }
        }
    }

    ${EXERCISE_FRAGMENT}
`

const REMOVE_EXERCISE_FACILITATOR = gql`
    mutation RemoveFacilitator($fromId: ID!, $toId: ID!) {
        RemoveExerciseFacilitators(
            from: { id: $fromId }
            to: { userId: $toId }
        ) {
            from {
                ...Exercise
            }
            to {
                userId
            }
        }
    }

    ${EXERCISE_FRAGMENT}
`

const ADD_EXERCISE_TEAM = gql`
    mutation AddTeam($fromId: ID!, $toId: ID!) {
        AddExerciseInvitedTeams(from: { id: $fromId }, to: { teamId: $toId }) {
            from {
                ...Exercise
            }
            to {
                teamId
            }
        }
    }

    ${EXERCISE_FRAGMENT}
`

const REMOVE_EXERCISE_TEAM = gql`
    mutation RemoveTeam($fromId: ID!, $toId: ID!) {
        RemoveExerciseInvitedTeams(
            from: { id: $fromId }
            to: { teamId: $toId }
        ) {
            from {
                ...Exercise
            }
            to {
                teamId
            }
        }
    }

    ${EXERCISE_FRAGMENT}
`

const ADD_EXERCISE_TYPE = gql`
    mutation AddExerciseType($fromId: ID!, $toId: ID!) {
        AddExerciseType(from: { id: $fromId }, to: { id: $toId }) {
            from {
                ...Exercise
            }
            to {
                id
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`

const DELETE_EXERCISE = gql`
    mutation DeleteExercise($id: ID!) {
        DeleteExercise(id: $id) {
            id
            title
        }
    }
`
const DELETE_EXERCISE_TYPE = gql`
    mutation DeleteExerciseType($id: ID!) {
        DeleteExerciseType(id: $id) {
            id
        }
    }
`
const DELETE_EXERCISE_SUBMISSION = gql`
    mutation DeleteExerciseSubmission($id: ID!) {
        DeleteExerciseSubmission(id: $id) {
            id
        }
    }
`
const USER_EXERCISES_QUERY = gql`
    query getUserExercises($userId: ID!) {
        User(userId: $userId) {
            userId
            exercises {
                ...Exercise
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`
const USER_EXERCISES_GROUPED_QUERY = gql`
    query getUserGroupedExercises($userId: ID!) {
        participant_in: Exercise(
            filter: { participants_some: { userId: $userId } }
        ) {
            ...Exercise
        }

        facilitating_in: Exercise(
            filter: { facilitators_some: { userId: $userId } }
        ) {
            ...Exercise
        }

        created_by: Exercise(filter: { createdBy: { userId: $userId } }) {
            ...Exercise
        }
    }
    ${EXERCISE_FRAGMENT}
`

const GET_EXERCISE_BY_ID = gql`
    query getExerciseById($exerciseId: ID!) {
        Exercise(id: $exerciseId) {
            ...Exercise
        }
    }
    ${EXERCISE_FRAGMENT}
`

const ADD_EXERCISE_CHECKLIST = gql`
    mutation AddChecklistToExercise($exerciseId: ID!, $checklistId: ID!) {
        AddExerciseIncludedChecklist(
            from: { id: $exerciseId }
            to: { id: $checklistId }
        ) {
            from {
                ...Exercise
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`

const REMOVE_EXERCISE_CHECKLIST = gql`
    mutation RemoveChecklistToExercise($exerciseId: ID!, $checklistId: ID!) {
        RemoveExerciseIncludedChecklist(
            from: { id: $exerciseId }
            to: { id: $checklistId }
        ) {
            from {
                ...Exercise
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`

const CREATE_EXERCISE_SUBMISSION = gql`
    mutation CreateExerciseSubmission(
        $notes: String
        $conceptIds: [ID!]
        $status: ExerciseStatus
    ) {
        CreateExerciseSubmission(
            notes: $notes
            conceptIds: $conceptIds
            status: $status
        ) {
            id
            notes
            status
            conceptIds
            imageUrl
        }
    }
`
const UPDATE_EXERCISE_SUBMISSION = gql`
    mutation UpdateExerciseSubmission(
        $id: ID!
        $notes: String
        $conceptIds: [ID!]
        $status: ExerciseStatus
    ) {
        UpdateExerciseSubmission(
            id: $id
            notes: $notes
            conceptIds: $conceptIds
            status: $status
        ) {
            id
            notes
            status
            conceptIds
            imageUrl
        }
    }
`

const ADD_EXERCISE_SUBMISSION_EXERCISE = gql`
    mutation AddExerciseSubmissionExercise(
        $submissionId: ID!
        $exerciseId: ID!
    ) {
        AddExerciseSubmissionExercise(
            from: { id: $submissionId }
            to: { id: $exerciseId }
        ) {
            from {
                id
                notes
                conceptIds
                imageUrl
                exercise {
                    ...Exercise
                }
            }
            to {
                ...Exercise
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`
const ADD_EXERCISE_SUBMISSION_USER = gql`
    mutation AddExerciseSubmissionUser($submissionId: ID!, $userId: ID!) {
        AddExerciseSubmissionUser(
            from: { id: $submissionId }
            to: { userId: $userId }
        ) {
            from {
                id
                notes
                conceptIds
                imageUrl
                exercise {
                    ...Exercise
                }
            }
            to {
                userId
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`

const ADD_EXERCISE_INCLUDED_CONCEPT = gql`
    mutation AddExerciseIncludedConcept($exerciseId: ID!, $conceptId: ID!) {
        AddExerciseIncludedConcepts(
            from: { id: $exerciseId }
            to: { id: $conceptId }
        ) {
            from {
                ...Exercise
            }
            to {
                id
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`
const REMOVE_EXERCISE_INCLUDED_CONCEPT = gql`
    mutation RemoveExerciseIncludedConcept($exerciseId: ID!, $conceptId: ID!) {
        RemoveExerciseIncludedConcepts(
            from: { id: $exerciseId }
            to: { id: $conceptId }
        ) {
            from {
                ...Exercise
            }
            to {
                id
            }
        }
    }
    ${EXERCISE_FRAGMENT}
`
const GET_EXERCISE_BY_PARENT_CONCEPT = gql`
    query ExerciseByParentConcept($parentId: ID!) {
        Exercise(filter: { parentConcept: { id: $parentId } }) {
            ...Exercise
        }
    }

    ${EXERCISE_FRAGMENT}
`
const ADD_EXERCISE_PARENT_CONCEPT = gql`
    mutation AddExerciseParentConcept($conceptId: ID!, $exerciseId: ID!) {
        AddExerciseParentConcept(
            from: { id: $exerciseId }
            to: { id: $conceptId }
        ) {
            from {
                id
            }
            to {
                id
            }
        }
    }
`

const ADD_EXERCISE_CRITERIA = gql`
    mutation AddExerciseCriteria($exerciseId: ID!, $criteriaId: ID!) {
        AddExerciseCriteria(
            from: { id: $exerciseId }
            to: { id: $criteriaId }
        ) {
            from {
                ...Exercise
            }
            to {
                ...Criteria
            }
        }
    }
    ${EXERCISE_FRAGMENT}
    ${CRITERIA_FRAGMENT}
`
const REMOVE_EXERCISE_CRITERIA = gql`
    mutation RemoveExerciseCriteria($exerciseId: ID!, $criteriaId: ID!) {
        RemoveExerciseCriteria(
            from: { id: $exerciseId }
            to: { id: $criteriaId }
        ) {
            from {
                ...Exercise
            }
            to {
                ...Criteria
            }
        }
    }
    ${EXERCISE_FRAGMENT}
    ${CRITERIA_FRAGMENT}
`
export {
    ADD_EXERCISE_CHECKLIST,
    REMOVE_EXERCISE_CHECKLIST,
    CREATE_EXERCISE,
    UPDATE_EXERCISE_SUBMISSION,
    GET_EXERCISE_BY_ID,
    CREATE_EXERCISE_TYPE,
    UPDATE_EXERCISE_TYPE,
    GET_EXERCISE_TYPES,
    ADD_EXERCISE_FACILITATOR,
    ADD_EXERCISE_PARTICIPANT,
    REMOVE_EXERCISE_FACILITATOR,
    REMOVE_EXERCISE_PARTICIPANT,
    GET_USER_CREATED_EXERCISES,
    ADD_EXERCISE_TYPE,
    DELETE_EXERCISE,
    UPDATE_EXERCISE,
    USER_EXERCISES_QUERY,
    USER_EXERCISES_GROUPED_QUERY,
    ADD_EXERCISE_CREATED_BY,
    ADD_EXERCISE_TEAM,
    REMOVE_EXERCISE_TEAM,
    ADD_EXERCISE_SUBMISSION_EXERCISE,
    ADD_EXERCISE_SUBMISSION_USER,
    CREATE_EXERCISE_SUBMISSION,
    DELETE_EXERCISE_TYPE,
    DELETE_EXERCISE_SUBMISSION,
    GET_EXERCISE_BY_PARENT_CONCEPT,
    ADD_EXERCISE_PARENT_CONCEPT,
    ADD_EXERCISE_INCLUDED_CONCEPT,
    REMOVE_EXERCISE_INCLUDED_CONCEPT,
    ADD_EXERCISE_CRITERIA,
    REMOVE_EXERCISE_CRITERIA,
}
