import { useEffect, useCallback, useState, useRef } from "react"
import { useMutation, useQuery, useReactiveVar } from "@apollo/client"
import {
    Box,
    Paper,
    Grow,
    IconButton,
    Tooltip,
    CircularProgress,
    Button,
} from "@material-ui/core"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import ConceptList from "../../concept-list/ConceptList"
import { useParams } from "react-router-dom"
import { useAuth } from "../../../providers/AuthProvider"
import {
    CREATE_CONCEPT_CHECKLIST,
    UPDATE_CHECKLIST,
    CHECKLIST_BY_ID,
    ADD_CHECKLIST_ITEM_CONCEPT,
    CREATE_CHECKLIST_ITEM,
} from "../../../graphql/Checklist"
import {
    CreateConceptChecklistMutation,
    CreateConceptChecklistMutationVariables,
    CreateChecklistItemMutation,
    CreateChecklistItemMutationVariables,
    AddChecklistItemConceptMutation,
    AddChecklistItemConceptMutationVariables,
    UpdateChecklistMutation,
    UpdateChecklistMutationVariables,
    ChecklistByIdQuery,
    ChecklistByIdQueryVariables,
} from "../../../graphql/__generated__/Checklist"
import {
    Checklist,
    ItemPrefixType,
    Concept,
} from "../../../__generated__/types"
import SearchIcon from "@material-ui/icons/Search"
import LinkConcept from "../../LinkConcept"
import useAwaitTranslation from "../../../i18n/useAwaitTranslation"
import ClearIcon from "@material-ui/icons/Clear"
import AddIcon from "@material-ui/icons/Add"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import Divider from "@material-ui/core/Divider"
import { permissionsVar } from "../../../providers/GlobalState"

type WidgetConfig = {
    conceptListId: string
    label: string
    itemPrefixType?: string
    description: string
}

const ConceptListWidget: WidgetTypeComponent = (props) => {
    const { conceptId } = useParams()
    const { currentUser } = useAuth()

    const { config, isInitialized, onEditConfig } = useWidget<WidgetConfig>(
        props.widget.id
    )
    const [draggable, setDraggable] = useState(false)
    const onEditConfigRef = useRef(onEditConfig)
    onEditConfigRef.current = onEditConfig
    const editPermission = useReactiveVar(permissionsVar)
    const [add, setAdd] = useState(false)
    const { data, refetch, loading } = useQuery<
        ChecklistByIdQuery,
        ChecklistByIdQueryVariables
    >(CHECKLIST_BY_ID, {
        variables: {
            id: config?.conceptListId,
        },
        skip: !isInitialized || config?.conceptListId?.length === 0,
    })

    const list = data?.Checklist?.[0] ?? null

    const [createList, { loading: creatingList }] = useMutation<
        CreateConceptChecklistMutation,
        CreateConceptChecklistMutationVariables
    >(CREATE_CONCEPT_CHECKLIST)
    const [updateList] = useMutation<
        UpdateChecklistMutation,
        UpdateChecklistMutationVariables
    >(UPDATE_CHECKLIST)

    const createNewList = useCallback(async () => {
        const {
            data: { CreateChecklist: NewChecklist },
        } = await createList({
            variables: {
                userId: currentUser.userId,
                conceptId: conceptId,
                checklist: {
                    title: config.label ?? "New List",
                    isOrdered: true,
                    conceptBased: true,
                    itemPrefixType:
                        (config.itemPrefixType?.toUpperCase() as ItemPrefixType) ??
                        ItemPrefixType.NONE,
                    description: config?.description ?? "",
                },
            },
        })

        await onEditConfigRef.current({
            ...config,
            conceptListId: NewChecklist.id,
        })
    }, [currentUser, conceptId, createList, config])

    useEffect(() => {
        if (!!isInitialized) {
            if (config.conceptListId.length < 1) {
                createNewList()
            }
        }
    }, [isInitialized, createNewList, config])

    const updateCurrentList = useCallback(
        async (object: Checklist) => {
            await updateList({
                variables: {
                    userId: currentUser?.userId,
                    checklist: {
                        id: object.id,
                        title: object.title,
                        itemPrefixType: object.itemPrefixType,
                        description: object.description,
                    },
                },
            })

            await refetch()
        },
        [currentUser?.userId, updateList, refetch]
    )
    useEffect(() => {
        const listObject = {
            title: config?.label ?? "",
            itemPrefixType:
                config?.itemPrefixType?.toUpperCase() ?? ItemPrefixType.NONE,
            description: config?.description ?? "",
        }
        if (config?.conceptListId?.length > 0 && !!list) {
            let object = {
                id: config?.conceptListId,
            }
            Object.keys(listObject).map((field) => {
                if (listObject[field] !== list[field]) {
                    object[field] = listObject[field]
                }
                return object
            })
            if (Object.keys(object).length > 1) {
                updateCurrentList(object)
            }
        }
    }, [config, list, updateCurrentList])

    let actions = []
    if (!!list && editPermission) {
        actions = [
            <Tooltip title="Add concept" key={`Add_Concept_${props.widget.id}`}>
                <IconButton
                    onClick={() => {
                        setAdd((prev) => !prev)
                    }}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip>,
            !draggable ? (
                <Tooltip
                    title="Enable sorting"
                    key={`Enable_Sorting_${props.widget.id}`}
                >
                    <IconButton onClick={() => setDraggable(true)}>
                        <LockIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip
                    title="Disable sorting"
                    key={`Disable_Sorting_${props.widget.id}`}
                >
                    <IconButton onClick={() => setDraggable(false)}>
                        <LockOpenIcon />
                    </IconButton>
                </Tooltip>
            ),
        ]
    }
    return (
        <Box
            component={Paper}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            pb={2}
            key={props.widget.id}
        >
            <WidgetHeader
                label={config?.label}
                actions={actions}
                widget={props.widget}
                editing={props.editing}
            />
            {!!data && isInitialized && !list ? (
                <Box
                    p={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Box mb={2}>This list has been deleted...</Box>
                    <Button
                        variant="outlined"
                        disabled={creatingList || loading}
                        onClick={() => {
                            createNewList()
                        }}
                    >
                        {(creatingList || loading) && (
                            <CircularProgress
                                size={25}
                                disableShrink
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                    margin: "auto",
                                }}
                            />
                        )}
                        Create New
                    </Button>
                </Box>
            ) : (
                isInitialized &&
                !!list && (
                    <>
                        <Grow in={!!add} mountOnEnter unmountOnExit>
                            <Box>
                                <NewLineItem
                                    checklist={list}
                                    onClose={() => setAdd(false)}
                                />
                                <Divider />
                            </Box>
                        </Grow>

                        <ConceptList
                            checklist={list}
                            editPermission={editPermission}
                            draggable={draggable}
                        />
                    </>
                )
            )}
        </Box>
    )
}

interface NewLineItemProps {
    checklist: Checklist
    onClose: () => void
}
const NewLineItem = (props: NewLineItemProps) => {
    const { currentUser } = useAuth()
    const [saveItem] = useMutation<
        CreateChecklistItemMutation,
        CreateChecklistItemMutationVariables
    >(CREATE_CHECKLIST_ITEM)
    const [addConcept] = useMutation<
        AddChecklistItemConceptMutation,
        AddChecklistItemConceptMutationVariables
    >(ADD_CHECKLIST_ITEM_CONCEPT)

    const submitNewItem = async (concept: Concept) => {
        const {
            data: { CreateChecklistItem: NewItem },
        } = await saveItem({
            variables: {
                checklistId: props.checklist.id,
                title: null,
                checked: false,
                userId: currentUser.userId,
            },
        })

        if (!!NewItem?.id && !!concept) {
            await addConcept({
                variables: {
                    fromId: NewItem.id,
                    toId: concept.id,
                },
            })
        }
    }

    const { t } = useAwaitTranslation("widgets")

    return (
        <Box
            padding={2}
            width="100%"
            display="flex"
            alignItems="center"
            mt={1}
            mb={1}
        >
            <LinkConcept
                autoFocus={true}
                inputAdornment={<SearchIcon />}
                placeholder={t("searchConcepts", "Type to search concepts...")}
                concepts={props.checklist?.items
                    ?.map((item) => item.concept)
                    ?.filter((x) => !!x)}
                linkFunction={(concept: Concept) => {
                    submitNewItem(concept)
                }}
            />
            <IconButton
                size="small"
                onClick={() => props.onClose()}
                style={{ flexShrink: 0 }}
            >
                <ClearIcon />
            </IconButton>
        </Box>
    )
}

export default ConceptListWidget
