import React, { useState, useEffect } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Box, ClickAwayListener, Typography } from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { useMutation } from "@apollo/client"
import { useReactiveVar } from "@apollo/client"
import { permissionsVar } from "../../providers/GlobalState"
import CategoryTreeSelector from "./CategoryTreeSelector"
import { ADD_CONCEPT_CATEGORY, REMOVE_CONCEPT_CATEGORY } from "./graphql"
import {
    AddConceptCategoryMutation,
    AddConceptCategoryMutationVariables,
    RemoveConceptCategoryMutation,
    RemoveConceptCategoryMutationVariables,
} from "./__generated__/graphql"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import { UPDATE_CONCEPT } from "../../graphql/mutations"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../../graphql/__generated__/mutations"
import { getConceptTypeIcon } from "../../util/ConceptTypeStyles"
import { Concept } from "../../__generated__/types"
import { useAuth } from "../../providers/AuthProvider"
const useStyles = makeStyles((theme) => ({
    clickable: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
        },
        cursor: "pointer",
    },
}))

interface CategoryTreeSelectorWrapperProps {
    concept: Concept
}
const CategoryTreeSelectorWrapper = (
    props: CategoryTreeSelectorWrapperProps
) => {
    const [editing, setEditing] = useState(false)
    const { currentUser } = useAuth()
    const theme = useTheme()
    const editPermission = useReactiveVar(permissionsVar)
    const [addCategory] = useMutation<
        AddConceptCategoryMutation,
        AddConceptCategoryMutationVariables
    >(ADD_CONCEPT_CATEGORY, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: props.concept.id },
            },
        ],
        awaitRefetchQueries: true,
    })
    const [removeCategory] = useMutation<
        RemoveConceptCategoryMutation,
        RemoveConceptCategoryMutationVariables
    >(REMOVE_CONCEPT_CATEGORY)
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT)
    const [saving, setSaving] = useState(false)
    const [value, setValue] = useState(null)
    const classes = useStyles()
    const handleChange = async (newValue) => {
        if (newValue.id !== props.concept?.category?.id) {
            setValue(newValue)
            setSaving(true)
            if (!!props.concept?.category?.id) {
                await removeCategory({
                    variables: {
                        id: props.concept.id,
                        categoryId: props.concept.category?.id,
                    },
                })
            }

            updateConcept({
                variables: {
                    userId: currentUser.userId,
                    concept: {
                        id: props.concept.id,
                        type: newValue.isRoot
                            ? newValue.name
                            : newValue.parent?.name,
                    },
                },
            })

            await addCategory({
                variables: {
                    id: props.concept.id,
                    categoryId: newValue.id,
                },
            })
            setEditing(false)
            setSaving(false)
        }
    }

    useEffect(() => {
        if (props.concept.category) {
            setValue(props.concept.category)
        }
    }, [props.concept.category])
    const TypeIcon = getConceptTypeIcon(props.concept?.rootCategory?.name)
    return (
        <ClickAwayListener
            onClickAway={() => {
                if (!saving) {
                    setEditing(false)
                }
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                flexGrow={1}
                width="100%"
                height="100%"
            >
                {editing ? (
                    <Box mr={1} width="100%" mt={1} mb={1}>
                        <CategoryTreeSelector
                            value={value}
                            setValue={(value) => {
                                if (!saving) {
                                    handleChange(value)
                                }
                            }}
                            size="small"
                            saving={saving}
                            zIndex={theme.zIndex.appBar - 2}
                        />
                    </Box>
                ) : (
                    <Box
                        onClick={() => {
                            if (editPermission) {
                                setEditing(true)
                            }
                        }}
                        className={editPermission ? classes.clickable : ""}
                        height="100%"
                        display="flex"
                        width="100%"
                        alignItems="center"
                        pl={0.5}
                    >
                        <TypeIcon />
                        <Box ml={0.5}>
                            <Typography variant="body1">
                                {props.concept?.rootCategory?.name}
                            </Typography>
                        </Box>
                        {props.concept?.category?.id !==
                            props.concept?.rootCategory?.id && (
                            <>
                                <ChevronRightIcon />
                                <Typography variant="body1">
                                    {props.concept?.category?.name}
                                </Typography>
                            </>
                        )}
                    </Box>
                )}
            </Box>
        </ClickAwayListener>
    )
}

export default CategoryTreeSelectorWrapper
