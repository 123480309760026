import React, { useContext, useState, useEffect } from "react"
import { useMutation, useApolloClient } from "@apollo/client"
import { useHistory } from "react-router-dom"
import firebase from "firebase/app"
import { auth } from "../util/firebase"
import {
    MERGE_USER,
    UPDATE_USER,
    ADD_USER_HOME_CONCEPT,
} from "../components/users/graphql"
import { CREATE_USER_WORKSPACE } from "../graphql/conceptListQueries"
import { useTranslation } from "react-i18next"
import { CREATE_CONCEPT } from "../graphql/mutations"
const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

const provider = new firebase.auth.GoogleAuthProvider()

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [isSignedIn, setIsSignedIn] = useState(false)
    const [loading, setLoading] = useState(true)
    const [newUserSignUp, setNewUserSignUp] = useState(false)
    const [loginUser] = useMutation(UPDATE_USER)
    const [signupUser] = useMutation(MERGE_USER)
    const [createWorkspace] = useMutation(CREATE_USER_WORKSPACE)
    const [createPersonalConcept] = useMutation(CREATE_CONCEPT)
    const [addHomeConcept] = useMutation(ADD_USER_HOME_CONCEPT)
    const history = useHistory()
    const { i18n } = useTranslation()
    const client = useApolloClient()
    const signup = async ({ email, password, userData }) => {
        let uid = currentUser?.uid
        if (!uid) {
            let response = await auth.createUserWithEmailAndPassword(
                email,
                password
            )
            uid = response.user.uid
        }
        userData.userId = uid
        // const {
        //     data: { User: UserCheck },
        // } = await client.query({
        //     query: USER_FILTERED,
        //     variables: {
        //         filter: {
        //             email: userData?.email,
        //         },
        //     },
        // })
        // console.log(UserCheck, "+======= test")
        // if (UserCheck?.length > 0) {
        //     userData.userId = UserCheck[0].userId
        // }
        await signupUser({ variables: userData })
        await createWorkspace({
            variables: {
                userId: userData.userId,
            },
        })
        const {
            data: { CreateConcept: NewConcept },
        } = await createPersonalConcept({
            variables: {
                concept: {
                    title: `${userData?.username}'s Personal Concept`,
                    isPublic: false,
                    isPublicAccessType: "VIEWER",
                    type: "User Concept",
                },
                userId: userData.userId,
                tags: [],
            },
        })
        await addHomeConcept({
            variables: {
                conceptId: NewConcept.id,
                userId: userData.userId,
            },
        })
        setNewUserSignUp(true)
        return currentUser
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function loginGoogle() {
        return auth.signInWithRedirect(provider)
    }

    function logout() {
        setIsSignedIn(false)
        setCurrentUser(null)
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setCurrentUser(user)
                const lastSignIn = new Date(user.metadata.lastSignInTime)
                const created = new Date(user.metadata.creationTime)

                loginUser({
                    variables: {
                        userId: user.uid,
                        email: user.email,
                        lastLogin: {
                            year: lastSignIn.getFullYear(),
                            month: lastSignIn.getMonth() + 1,
                            day: lastSignIn.getDate(),
                        },
                        createdDate: {
                            year: created.getFullYear(),
                            month: created.getMonth() + 1,
                            day: created.getDate(),
                        },
                    },
                })
                    .then((userResult) => {
                        const userData = userResult.data.UpdateUser
                        if (!userData && user?.uid) {
                            history.push("/signup")
                            return
                        }
                        setCurrentUser({ ...user, ...userData })
                        setIsSignedIn(true)
                        if (userData.language) {
                            i18n.changeLanguage(userData.language)
                        }
                        if (newUserSignUp) {
                            setNewUserSignUp(false)
                            history.push("/")
                        }
                    })
                    .catch((error) => {
                        throw error
                    })
                    .finally(() => {
                        setLoading(false)
                    })
                return
            }
            setLoading(false)
        })

        return unsubscribe
    }, [history, loginUser, newUserSignUp, i18n])

    const value = {
        currentUser,
        login,
        loginGoogle,
        isSignedIn,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
