import { Box, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { useState } from "react"
import ConceptPicker from "../ConceptPicker"
import SearchIcon from "@material-ui/icons/Search"
import { Concept } from "../../__generated__/types"
import { useTheme } from "@material-ui/core"
interface ConceptWidgetOptionsProps {
    onAddNewWidget: (conceptId: string, view: string) => void
    selectedType: any
}
const ConceptWidgetOptions = (props: ConceptWidgetOptionsProps) => {
    const theme = useTheme()
    const selectedTypeConfig = JSON.parse(
        props.selectedType?.defaultConfig ?? "{}"
    )
    const [view, setView] = useState(selectedTypeConfig?.view ?? "Card")

    return (
        <>
            <Box p={2}>
                <Autocomplete
                    disableClearable
                    openOnFocus
                    size="small"
                    onChange={(event, newValue) => {
                        setView(newValue)
                    }}
                    style={{
                        backgroundColor: theme.palette.background.paper,
                    }}
                    value={view}
                    options={props.selectedType?.schema?.properties?.view?.enum}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="View"
                        />
                    )}
                />
            </Box>
            <Box
                p={2}
                display="flex"
                overflow="hidden"
                pt={0}
                height="100%"
                pb={1}
            >
                <ConceptPicker
                    linkFunction={(concept: Concept) => {
                        props.onAddNewWidget(concept.id, view)
                    }}
                    variant="outlined"
                    inputAdornment={<SearchIcon />}
                />
            </Box>
        </>
    )
}

export default ConceptWidgetOptions
