import React, { useEffect, useCallback, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useQuery, useMutation } from "@apollo/client"
import {
    Typography,
    Avatar,
    Box,
    Divider,
    IconButton,
    Toolbar,
    useMediaQuery,
    useTheme,
    Tooltip,
    SwipeableDrawer,
    Tab,
    Tabs,
} from "@material-ui/core"
import { USER_PROFILE, ADD_USER_HOME_CONCEPT } from "./graphql"
import {
    UserProfileQuery,
    UserProfileQueryVariables,
    AddUserHomeConceptMutation,
    AddUserHomeConceptMutationVariables,
} from "./__generated__/graphql"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import { useAuth } from "../../providers/AuthProvider"
import SettingsIcon from "@material-ui/icons/Settings"
import { useHistory } from "react-router-dom"
import { Loading } from "../Loading"
import { useParams } from "react-router-dom"
import { SizeMe } from "react-sizeme"
import { CREATE_CONCEPT } from "../../graphql/mutations"
import {
    CreateConceptCompleteMutation,
    CreateConceptCompleteMutationVariables,
} from "../../graphql/__generated__/mutations"
import { AccessType } from "../../__generated__/types"
import DashboardManager from "../dashboard/DashboardManager"
import ConceptVirtualList from "../ConceptVirtualList"
import Dashboard from "../dashboard/Dashboard"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { WidgetStore } from "../dashboard/WidgetStore"
import { permissionsVar } from "../../providers/GlobalState"
const drawerWidth = 260
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.default,
    },

    headerContainer: {
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
        position: "fixed",
        zIndex: theme.zIndex.appBar - 1,
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        marginTop: theme.spacing(6) - 1,
        borderTop: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("sm")]: {
            right: 0,
            left: 0,
        },
    },
    drawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        flexShrink: 0,
    },
    closedDrawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: 0,
        flexShrink: 0,
    },
    header: {
        display: "flex",
        flexDirection: "column",
        zIndex: theme.zIndex.appBar - 1,
    },
    avatar: {
        height: 100,
        width: 100,
        [theme.breakpoints.down("sm")]: {
            height: 75,
            width: 75,
        },
        margin: theme.spacing(0, 1),
    },
    imageFallback: {
        height: 100,
        width: 100,
        fontSize: "3rem",
        margin: theme.spacing(0, 1),

        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },
    listContainer: {
        marginTop: 225,
        [theme.breakpoints.down("sm")]: {
            marginTop: 200,
        },
        padding: theme.spacing(1),
    },
    toolbar: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        display: "flex",
    },
    toolbarContainer: {
        position: "fixed",
        width: "100%",
        overflow: "hidden",
        zIndex: theme.zIndex.appBar - 1,
        height: theme.spacing(6),
        borderBottom: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("sm")]: {
            right: 0,
            left: 0,
        },
        display: "flex",
    },
}))

export default function UserPublicProfile(props) {
    const classes = useStyles()
    const { username, dashboardId } = useParams()
    const [editing, setEditing] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(true)
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const history = useHistory()
    const [tab, setTab] = useState(0)
    const { currentUser } = useAuth()
    const [createPersonalConcept] = useMutation<
        CreateConceptCompleteMutation,
        CreateConceptCompleteMutationVariables
    >(CREATE_CONCEPT)
    const [addHomeConcept] = useMutation<
        AddUserHomeConceptMutation,
        AddUserHomeConceptMutationVariables
    >(ADD_USER_HOME_CONCEPT)
    const { data, loading, error, refetch } = useQuery<
        UserProfileQuery,
        UserProfileQueryVariables
    >(USER_PROFILE, {
        variables: {
            username: username,
        },
    })
    useEffect(() => {
        if (mobile) {
            setSidebarOpen(false)
        }
    }, [mobile])
    useEffect(() => {
        if (currentUser?.userId === data?.user[0]?.userId) {
            permissionsVar(true)
        } else {
            permissionsVar(false)
        }
    }, [currentUser, data])
    const user = data?.user[0] ?? null
    const concept = user?.homeConcept ?? null
    const dashboard =
        dashboardId !== "home"
            ? concept?.usesDashboard?.filter((x) => x.id === dashboardId)[0]
            : null

    const createNewPersonalConcept = useCallback(async () => {
        const {
            data: { CreateConcept: NewConcept },
        } = await createPersonalConcept({
            variables: {
                concept: {
                    title: `${username}'s Personal Concept`,
                    isPublic: false,
                    isPublicAccessType: AccessType.VIEWER,
                    type: "User Concept",
                },
                userId: currentUser.userId,
                tags: [],
            },
        })
        await addHomeConcept({
            variables: {
                userId: currentUser.userId,
                conceptId: NewConcept.id,
            },
        })
        await refetch()
    }, [
        createPersonalConcept,
        username,
        refetch,
        currentUser?.userId,
        addHomeConcept,
    ])

    useEffect(() => {
        if (!!data && !!user && !concept) {
            createNewPersonalConcept()
        }
    }, [createNewPersonalConcept, data, user, concept])

    if (loading || !concept || !user) {
        return <Loading size={150} />
    }
    if (error) {
        return <div>Could not retrieve user...</div>
    }

    const handleSettings = () => {
        history.push(`/myaccount`)
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
    }
    const ownAccount = currentUser.userId === data.user?.[0].userId
    const imageFallback: string =
        (user?.firstName &&
            user?.lastName &&
            user?.firstName[0] + user?.lastName[0]) ??
        ""
    return (
        <Box display="flex" style={{ overflowX: "hidden" }}>
            <SizeMe monitorWidth monitorHeight>
                {({ size }) => (
                    <>
                        <Box className={classes.root}>
                            <Box className={classes.toolbarContainer}>
                                <Box
                                    className={classes.toolbar}
                                    width={!!mobile ? "100vw" : size.width}
                                    justifyContent={
                                        ownAccount
                                            ? "space-between"
                                            : "flex-end"
                                    }
                                >
                                    {ownAccount && (
                                        <IconButton onClick={handleSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    )}
                                    <Box display="flex">
                                        {dashboardId !== "home" && (
                                            <>
                                                <WidgetStore
                                                    dashboardId={dashboardId}
                                                    enableEditing={() =>
                                                        setEditing(true)
                                                    }
                                                />
                                                <Tooltip
                                                    title={
                                                        props.editing
                                                            ? "Lock Editing"
                                                            : "Unlock Editing"
                                                    }
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            setEditing(!editing)
                                                        }
                                                        style={{
                                                            padding: "8px",
                                                        }}
                                                    >
                                                        {editing ? (
                                                            <LockOpenIcon />
                                                        ) : (
                                                            <LockIcon />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )}
                                        <IconButton
                                            onClick={() =>
                                                setSidebarOpen(!sidebarOpen)
                                            }
                                        >
                                            {sidebarOpen ? (
                                                <ChevronRightIcon />
                                            ) : (
                                                <ChevronLeftIcon />
                                            )}
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Divider />
                            </Box>
                            {!dashboard ? (
                                <>
                                    <Box className={classes.headerContainer}>
                                        <Box
                                            className={classes.header}
                                            width={
                                                !!mobile ? "100vw" : size.width
                                            }
                                        >
                                            <Box
                                                display="flex"
                                                flexGrow={1}
                                                p={1}
                                            >
                                                <Box p={1}>
                                                    <Avatar
                                                        className={
                                                            classes.avatar
                                                        }
                                                        src={user?.imageUrl}
                                                    >
                                                        {imageFallback}
                                                    </Avatar>
                                                </Box>

                                                <Box p={1} flexGrow={1}>
                                                    <Typography
                                                        variant={
                                                            mobile ? "h6" : "h4"
                                                        }
                                                    >
                                                        {user?.firstName}{" "}
                                                        {user?.lastName}
                                                    </Typography>

                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                    >
                                                        {user?.bio}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Divider />
                                            <Box key={size.width}>
                                                <Tabs
                                                    value={tab}
                                                    onChange={handleChange}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    centered
                                                >
                                                    <Tab label="Submissions" />
                                                    <Tab label="Likes" />
                                                </Tabs>
                                            </Box>
                                            <Divider />
                                        </Box>
                                    </Box>
                                    <Box
                                        className={classes.listContainer}
                                        key={tab}
                                        width={size.width}
                                    >
                                        {tab === 0 && (
                                            <ConceptVirtualList
                                                filter={{
                                                    createdBy: {
                                                        username: username,
                                                    },
                                                    isPublic: true,
                                                }}
                                                key={
                                                    JSON.stringify(tab) +
                                                    JSON.stringify({
                                                        createdBy: {
                                                            username: username,
                                                        },
                                                        isPublic: true,
                                                    })
                                                }
                                            />
                                        )}
                                        {tab === 1 && (
                                            <ConceptVirtualList
                                                filter={{
                                                    likedBy_some: {
                                                        username: username,
                                                    },
                                                    isPublic: true,
                                                }}
                                                key={
                                                    JSON.stringify(tab) +
                                                    JSON.stringify({
                                                        likedBy_some: {
                                                            username: username,
                                                        },
                                                        isPublic: true,
                                                    })
                                                }
                                            />
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <Box mt={6}>
                                    <Dashboard
                                        key={dashboard.id + concept.id}
                                        dashboardId={dashboard.id}
                                        width={size.width ?? 25}
                                        editing={editing}
                                    />
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </SizeMe>

            <SwipeableDrawer
                open={sidebarOpen}
                anchor="right"
                variant={mobile ? "temporary" : "persistent"}
                className={sidebarOpen ? classes.drawer : classes.closedDrawer}
                onOpen={() => setSidebarOpen(true)}
                onClose={() => setSidebarOpen(false)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {!mobile && <Toolbar />}
                <DashboardManager
                    concept={concept}
                    editing={editing}
                    toggleEditing={() => setEditing(!editing)}
                    refetch={refetch}
                />
            </SwipeableDrawer>
        </Box>
    )
}
