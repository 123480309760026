import React, { useState } from "react"
import {
    Avatar,
    CardActionArea,
    CardActions,
    useMediaQuery,
} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Link from "@material-ui/core/Link"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import SyncAltIcon from "@material-ui/icons/SyncAlt"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import DetailedConnectionPreviewDialog from "./DetailedConnectionPreviewDialog"
import LikeToggle from "./liking/LikeToggle"
import TagList from "./tags/TagList"
import WorkspaceToggle from "./workspace/WorkspaceToggle"
import { useGlobalStyles } from "../styles/globalStyles"
import IconButton from "@material-ui/core/IconButton"
import CommentIcon from "@material-ui/icons/Comment"
import { Concept } from "../__generated__/types"
const useRowStyles = makeStyles(
    ({ shape, spacing, palette, breakpoints, typography }) => ({
        card: {
            borderRadius: shape.borderRadius,
            height: "100%",
            display: "flex",
            flexDirection: "row",
            overflowY: "auto",
        },
        mainCardContent: {
            width: "100%",
        },
        cardActionArea: {
            padding: spacing(1.5),
        },
        cardActions: {
            justifyContent: "space-between",
            display: "flex",
            padding: `${spacing(1)}px 0 0 0`,
            width: "100%",
            flexGrow: 1,
            flexWrap: "wrap",
        },
        media: {
            cursor: "pointer",
            minWidth: "150px",
            minHeight: "150px",
            flexShrink: 0,
            borderRadius: shape.borderRadius,
            [breakpoints.down("sm")]: {
                margin: spacing(1.5, 0),
            },
            marginLeft: "auto",
            margin: spacing(1, 0),
        },

        content: {
            flexGrow: 1,
            padding: 0,
            display: "flex",
            flexDirection: "column",
            paddingBottom: "0 !important",
        },
        heading: {
            fontSize: typography.h6.fontSize,
            fontWeight: typography.fontWeightMedium,
            letterSpacing: typography.h6.letterSpacing,
            marginBottom: spacing(0.5),
            marginTop: spacing(0.5),
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
        },
        subtitle: {
            fontSize: 12,
            color: palette.text.secondary,
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
        },
        summary: {
            fontSize: typography.body2.fontSize,
            color: palette.text.secondary,
            flexGrow: 1,
            marginTop: "0.5em",
        },
        typeContainer: {
            color: palette.text.secondary,
        },
        avatar: {
            height: spacing(3),
            width: spacing(3),
            margin: spacing(0, 0.75),
            marginLeft: 0,
        },
        connectionsButton: {
            minWidth: "48px",
        },
        titleBox: {
            display: "flex",
            justifyContent: "space-between",
        },
        flexContainer: {
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
            [breakpoints.down("sm")]: {
                flexDirection: "column",
            },
        },
        noWrapTextMobile: {
            [breakpoints.down("sm")]: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
        },
        contextMenuItem: {
            fontSize: typography.body2.fontSize,
        },
        contextMenuIcon: {
            fontSize: "1em",
            marginRight: spacing(1),
        },
    })
)
const useGridStyles = makeStyles(
    ({ shape, spacing, palette, breakpoints, typography }) => ({
        card: {
            borderRadius: shape.borderRadius,
            height: "100%",
            display: "flex",
            flexDirection: "row",
            overflowY: "auto",
        },
        mainCardContent: {
            width: "100%",
            display: "flex",
        },
        cardActionArea: {
            padding: spacing(1.5),
            display: "flex",
            flexDirection: "column",
        },
        cardActions: {
            justifyContent: "space-between",
            display: "flex",
            padding: `${spacing(1)}px 0 0 0`,
            width: "100%",
            flexGrow: 0,
            flexWrap: "wrap",
        },
        media: {
            cursor: "pointer",
            width: "100%",
            minWidth: "150px",
            minHeight: "150px",
            flexShrink: 0,
            borderRadius: shape.borderRadius,
            marginLeft: "auto",
            margin: spacing(1, 0),
        },
        content: {
            flexGrow: 1,
            padding: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingBottom: "0 !important",
        },
        heading: {
            fontSize: typography.h6.fontSize,
            fontWeight: typography.fontWeightMedium,
            letterSpacing: typography.h6.letterSpacing,
            marginBottom: spacing(0.5),
            marginTop: spacing(0.5),
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
        },
        subtitle: {
            fontSize: 12,
            color: palette.text.secondary,
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
        },
        summary: {
            fontSize: typography.body2.fontSize,
            color: palette.text.secondary,
            flexGrow: 1,
            marginTop: "0.5em",
        },
        typeContainer: {
            color: palette.text.secondary,
        },
        avatar: {
            height: spacing(3),
            width: spacing(3),
            margin: spacing(0, 0.75),
            marginLeft: 0,
        },
        titleBox: {
            display: "flex",
            justifyContent: "space-between",
        },
        flexContainer: {
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
            flexDirection: "column",
        },
        noWrapTextMobile: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        contextMenuItem: {
            fontSize: typography.body2.fontSize,
        },
        contextMenuIcon: {
            fontSize: "1em",
            marginRight: spacing(1),
        },
    })
)
interface ConceptCardProps {
    item: Concept
    hideActions?: boolean
    grid?: boolean
    disableClick?: boolean
    viewComments?: () => void
}
// Leaving any for item since we are more than likely changing the type interface anyway, and it is throwing a type error for 'product'

const ConceptCard = (props: ConceptCardProps) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [connectionPreviewOpen, setConnectionPreviewOpen] = useState(false)
    const history = useHistory()
    const rowClasses = useRowStyles()
    const gridClasses = useGridStyles()
    const iconStyles = useGlobalStyles()
    const classes = props.grid ? gridClasses : rowClasses
    const handlePreviewClick = (event) => {
        event.stopPropagation()
        setConnectionPreviewOpen(true)
    }
    const handleUserClick = (event) => {
        event.stopPropagation()
        history.push(`/user/${props.item.createdBy?.username}/home`)
    }
    const makeAvatarWrap = props.grid || mobile
    return (
        <Card className={classes.card} elevation={0}>
            <Box className={classes.mainCardContent}>
                <CardActionArea
                    component="div"
                    className={classes.cardActionArea}
                    onClick={() =>
                        history.push(`/concept/${props.item.id}/home`)
                    }
                >
                    <CardContent
                        className={classes.content}
                        style={!props.item.imageUrl ? { paddingTop: 0 } : {}}
                    >
                        <Box className={classes.flexContainer}>
                            <Box
                                paddingRight={props.grid ? 0 : 2}
                                display="flex"
                                flexDirection="column"
                                flexGrow={1}
                            >
                                {!!props.item.rootCategory && (
                                    <Box className={classes.typeContainer}>
                                        {props.item.rootCategory.name}{" "}
                                        {!!props.item.category &&
                                            !props.item.category?.isRoot &&
                                            " / " + props.item.category?.name}
                                    </Box>
                                )}
                                <Box className={classes.titleBox}>
                                    <Box className={classes.heading}>
                                        {props.item.title}
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    width="100%"
                                    flexDirection="column"
                                    flexGrow={1}
                                >
                                    {props.item.summary && (
                                        <p className={classes.summary}>
                                            {props.item.summary}
                                        </p>
                                    )}
                                </Box>
                                {props.item?.tags?.length > 0 && (
                                    <Box
                                        mb={1}
                                        style={{ filter: "opacity(0.8)" }}
                                    >
                                        <TagList concept={props.item} />
                                    </Box>
                                )}
                            </Box>
                            {!!props.item.imageUrl && (
                                <CardMedia
                                    className={classes.media}
                                    image={props.item.imageUrl}
                                />
                            )}
                        </Box>
                    </CardContent>
                    {!props.hideActions && (
                        <CardActions className={classes.cardActions}>
                            <Box className={classes.subtitle}>
                                <Box
                                    display="flex"
                                    flexWrap="wrap"
                                    alignItems="center"
                                    onClick={handleUserClick}
                                >
                                    <Avatar
                                        className={classes.avatar}
                                        alt={props.item.createdBy?.firstName}
                                        src={props.item.createdBy?.imageUrl}
                                    />
                                    <Box
                                        display="flex"
                                        flexDirection={
                                            makeAvatarWrap ? "column" : "row"
                                        }
                                    >
                                        <Link
                                            style={{
                                                cursor: "pointer",
                                                marginRight: "5px",
                                            }}
                                        >
                                            {props.item.createdBy?.firstName}{" "}
                                            {props.item.createdBy?.lastName}
                                        </Link>
                                        <span
                                            style={{
                                                fontSize: makeAvatarWrap
                                                    ? "0.8em"
                                                    : "inherit",
                                            }}
                                        >
                                            {!makeAvatarWrap && "shared"}
                                            {" " +
                                                dayjs(
                                                    props.item.createdAt
                                                        ?.formatted
                                                ).fromNow()}
                                        </span>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                marginLeft="auto !important"
                                justifyContent="flex-end"
                            >
                                {!!props.viewComments && (
                                    <Box mr={1}>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                props.viewComments()
                                            }}
                                            size="small"
                                        >
                                            <CommentIcon
                                                className={
                                                    iconStyles.smallLabelStylesIcon
                                                }
                                            />
                                            <span
                                                className={
                                                    iconStyles.smallCount
                                                }
                                            >
                                                {props.item.commentCount ?? 0}
                                            </span>
                                        </IconButton>
                                    </Box>
                                )}
                                <Box mr={1}>
                                    <Tooltip
                                        title={
                                            props.item.connections?.length !== 1
                                                ? "Preview " +
                                                  props.item.connections
                                                      ?.length +
                                                  " Connections"
                                                : "Preview " +
                                                  props.item.connections
                                                      ?.length +
                                                  " Connection"
                                        }
                                    >
                                        <IconButton
                                            onClick={handlePreviewClick}
                                            disableRipple
                                            size="small"
                                        >
                                            <SyncAltIcon
                                                className={
                                                    iconStyles.smallLabelStylesIcon
                                                }
                                            />
                                            <span
                                                className={
                                                    iconStyles.smallCount
                                                }
                                            >
                                                {props.item.connections?.length}
                                            </span>
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                <Box mr={1.5}>
                                    <WorkspaceToggle
                                        location="Concept Card"
                                        item={props.item}
                                        disableRipple={true}
                                        size="small"
                                    />
                                </Box>
                                <Box>
                                    <LikeToggle
                                        item={props.item}
                                        disableRipple={true}
                                        size="small"
                                        location="Concept Card"
                                    />
                                </Box>
                            </Box>
                        </CardActions>
                    )}
                </CardActionArea>
            </Box>
            {connectionPreviewOpen && (
                <DetailedConnectionPreviewDialog
                    item={props.item}
                    onClose={() => setConnectionPreviewOpen(false)}
                    editPermission={true}
                />
            )}
        </Card>
    )
}
export default React.memo(ConceptCard)
