import React, { useState } from "react"
import RichTextEditor from "../RichTextEditor"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import { useMutation, useQuery } from "@apollo/client"
import SingularComment from "./Comment"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { COMMENTS_BY_CONCEPT, CREATE_COMMENT } from "./graphql"
import {
    CreateCommentMutation,
    CreateCommentMutationVariables,
    CommentQuery,
    CommentQueryVariables,
} from "./__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import { Loading } from "../Loading"
import { Comment } from "../../__generated__/types"
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        overflow: "auto",
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        flexGrow: 1,
    },
    inputContainer: {
        marginTop: theme.spacing(3),
    },
    commentsContainer: {
        overflow: "auto",
        borderRadius: theme.shape.borderRadius,
        padding: 0,
    },
    header: {
        fontSize: 12,
        color: theme.palette.text.hint,
        minHeight: "24px",
        display: "flex",
        alignItems: "center",
    },
}))

interface CommentsContainerProps {
    conceptId: string
}

const PAGE_SIZE = 5
const initialText = JSON.stringify([{ type: "p", children: [{ text: "" }] }])
export default function CommentsContainer(props: CommentsContainerProps) {
    const classes = useStyles()
    const { conceptId } = props
    const { currentUser } = useAuth()

    const [fetching, setFetching] = useState(false)
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [saving, setSaving] = useState(false)
    const { t } = useAwaitTranslation("comments")
    const { loading, error, data, fetchMore } = useQuery<
        CommentQuery,
        CommentQueryVariables
    >(COMMENTS_BY_CONCEPT, {
        variables: {
            id: conceptId,
            first: limit,
            offset: 0,
        },
    })
    const [createComment] = useMutation<
        CreateCommentMutation,
        CreateCommentMutationVariables
    >(CREATE_COMMENT, {
        refetchQueries: [
            {
                query: COMMENTS_BY_CONCEPT,
                variables: {
                    id: conceptId,
                    first: data ? data.Comment.length + 1 : 10,
                    offset: 0,
                },
            },
        ],
    })

    const [textValue, setTextValue] = useState(initialText)

    if (loading) {
        return <Loading size={75} hideQuote={true} />
    } else if (error) {
        console.log(error.message)
    }
    const comments = data?.Comment ?? null
    let displayCount = 0
    let displayButton = true
    let commentCount =
        comments?.length > 0 ? comments?.[0]?.concept?.commentCount : 0
    let difference = commentCount > 0 ? commentCount - comments.length : 0
    if (difference >= PAGE_SIZE) {
        displayCount = PAGE_SIZE
    } else if (difference < PAGE_SIZE && difference !== 0) {
        displayCount = difference
    } else {
        displayButton = false
    }
    const submit = async () => {
        setSaving(true)
        await createComment({
            variables: {
                body: textValue,
                userId: currentUser.userId,
                conceptId: conceptId,
            },
        })
        setSaving(false)
    }
    const handleFetch = async () => {
        if (fetchMore) {
            setFetching(true)
            const result = await fetchMore({
                variables: {
                    offset: comments?.length,
                },
            })

            setLimit(comments?.length + result?.data?.Comment?.length)
            setFetching(false)
        }
    }
    let changed = initialText !== textValue
    const jsonValue = JSON.parse(textValue ?? "[]")
    if (
        jsonValue.length === 1 &&
        jsonValue[0]?.type === "p" &&
        jsonValue[0]?.children?.[0]?.text?.length === 0
    ) {
        changed = false
    }
    return (
        <Box className={classes.root} id="comments">
            <Box className={classes.header}>
                {t("comments", "Comments")} ({commentCount})
            </Box>
            {comments && comments?.length > 0 && (
                <Box className={classes.commentsContainer}>
                    {comments.map((comment: Comment, idx: number) => {
                        return (
                            <Box key={comment.commentId}>
                                <SingularComment
                                    liked={
                                        comment.likedBy.filter(
                                            (e) =>
                                                e.userId === currentUser.userId
                                        ).length > 0
                                    }
                                    comment={comment}
                                    conceptId={conceptId}
                                />
                            </Box>
                        )
                    })}
                    {displayButton && (
                        <Box display="flex" justifyContent="center">
                            <Button onClick={handleFetch} disabled={fetching}>
                                {fetching ? (
                                    <CircularProgress size={25} disableShrink />
                                ) : (
                                    <>
                                        {t("view", "View")} {displayCount}{" "}
                                        {t("moreComments", "more comments...")}
                                    </>
                                )}
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
            <Box className={classes.inputContainer}>
                <Box display="flex" alignItems="center">
                    <Box mr={1}>
                        <Avatar src={currentUser?.imageUrl} />
                    </Box>
                    <RichTextEditor
                        editorId={`New_Comment_${conceptId}_${
                            data ? data.Comment.length + 1 : 1
                        }`}
                        placeholderText={t(
                            "writeAComment",
                            "Write a comment..."
                        )}
                        isReadOnly={false}
                        baseFunctionality={true}
                        onChange={setTextValue}
                        alwaysEditing={true}
                    />
                </Box>

                <Box display="flex" justifyContent="flex-end" marginTop={1}>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={submit}
                        disabled={saving || !textValue || !changed}
                    >
                        {saving ? (
                            <CircularProgress size={25} disableShrink />
                        ) : (
                            t("submit", "Submit")
                        )}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
