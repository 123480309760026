import React, { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import { Autocomplete } from "@material-ui/lab"
import TextField from "@material-ui/core/TextField"
import { InputAdornment, CircularProgress } from "@material-ui/core"
import {
    GET_CATEGORIES,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    ADD_CATEGORY_PARENT,
    ROOT_CATEGORY_QUERY,
} from "./graphql"
import {
    AddCategoryParentMutation,
    AddCategoryParentMutationVariables,
    CreateCategoryMutation,
    CreateCategoryMutationVariables,
    DeleteCategoryMutation,
    DeleteCategoryMutationVariables,
    UpdateCategoryMutation,
    UpdateCategoryMutationVariables,
} from "./__generated__/graphql"
import { Category } from "../../__generated__/types"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

interface CategoryFormProps {
    onClose: () => void
    item?: Category
    parent?: Category
}
const CategoryForm = (props: CategoryFormProps) => {
    const [rootCategory, setRootCategory] = useState<Category | null>(null)
    const { data, loading } = useQuery(ROOT_CATEGORY_QUERY)
    const [addParent] = useMutation<
        AddCategoryParentMutation,
        AddCategoryParentMutationVariables
    >(ADD_CATEGORY_PARENT)
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [saving, setSaving] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const { t } = useAwaitTranslation("categories")
    const [createCategory] = useMutation<
        CreateCategoryMutation,
        CreateCategoryMutationVariables
    >(CREATE_CATEGORY, {
        refetchQueries: [
            {
                query: GET_CATEGORIES,
            },
        ],
    })
    const [updateCategory] = useMutation<
        UpdateCategoryMutation,
        UpdateCategoryMutationVariables
    >(UPDATE_CATEGORY, {
        refetchQueries: [
            {
                query: GET_CATEGORIES,
            },
        ],
    })
    const [deleteCategory] = useMutation<
        DeleteCategoryMutation,
        DeleteCategoryMutationVariables
    >(DELETE_CATEGORY, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.item),
            })
        },
    })
    useEffect(() => {
        if (props.item) {
            setName(props.item.name)
            setDescription(props.item.description)
        }
    }, [props.item])
    const handleDeletion = async () => {
        setConfirm(false)
        setSaving(true)
        await deleteCategory({
            variables: {
                id: props.item.id,
            },
        })
        props.onClose()
    }
    const handleSave = async () => {
        setSaving(true)
        if (props.item) {
            await updateCategory({
                variables: {
                    id: props.item.id,
                    name: name,
                    description: description,
                },
            })
        } else {
            const result = await createCategory({
                variables: {
                    name: name,
                    description: description,
                },
            })
            await addParent({
                variables: {
                    parentId: rootCategory.id,
                    childId: result.data.CreateCategory.id,
                },
            })
        }
        props.onClose()
    }

    return (
        <Dialog open={true} fullWidth>
            {confirm ? (
                <>
                    <DialogContent>
                        Are you sure you want to delete
                        {props.item.name}?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setConfirm(false)
                            }}
                            disabled={saving}
                        >
                            {t("no", "No")}
                        </Button>
                        <Button
                            disabled={saving}
                            variant="contained"
                            color="primary"
                            onClick={handleDeletion}
                        >
                            {saving ? (
                                <CircularProgress size={25} disableShrink />
                            ) : (
                                t("yes", "Yes")
                            )}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogTitle>
                        <Box>{t("categoryForm", "Category Form")}</Box>
                    </DialogTitle>
                    <DialogContent>
                        {!props.item && (
                            <Box p={2}>
                                <Autocomplete
                                    loading={loading}
                                    options={data?.Category ?? []}
                                    openOnFocus
                                    noOptionsText="No root categories that match.."
                                    value={rootCategory}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(
                                        event: any,
                                        newValue: Category
                                    ) => {
                                        setRootCategory(newValue)
                                    }}
                                    getOptionSelected={(option) =>
                                        option.id === rootCategory?.id
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            autoFocus
                                            variant="standard"
                                            label="Root Category"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: loading && (
                                                    <>
                                                        <InputAdornment position="end">
                                                            <CircularProgress
                                                                disableShrink
                                                                size={25}
                                                            />
                                                        </InputAdornment>
                                                        {
                                                            params.InputProps
                                                                .startAdornment
                                                        }
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                        <Box p={2}>
                            <TextField
                                required
                                fullWidth
                                value={name}
                                label={t("name", "Name")}
                                onChange={(event) =>
                                    setName(event.target?.value)
                                }
                            />
                        </Box>
                        <Box p={2}>
                            <TextField
                                fullWidth
                                value={description}
                                label={t("description", "Description")}
                                onChange={(event) =>
                                    setDescription(event.target?.value)
                                }
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions
                        style={{
                            justifyContent: props.item
                                ? "space-between"
                                : "flex-end",
                        }}
                    >
                        {props.item && (
                            <Button
                                onClick={() => {
                                    setConfirm(true)
                                }}
                                variant="outlined"
                                style={{ color: "red", float: "left" }}
                                disabled={saving}
                            >
                                Delete
                            </Button>
                        )}
                        <Box>
                            <Button
                                onClick={() => {
                                    props.onClose()
                                }}
                                disabled={saving}
                            >
                                {t("discard", "Discard")}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={
                                    saving ||
                                    !(name && name.length > 0) ||
                                    (!props.item && !rootCategory)
                                }
                                onClick={handleSave}
                            >
                                {saving ? (
                                    <CircularProgress size={25} disableShrink />
                                ) : (
                                    t("save", "Save")
                                )}
                            </Button>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}

export default CategoryForm
