import { gql } from "@apollo/client"

export const NOTIFICATIONS_QUERY = gql`
    query UserNotifications($userId: ID!) {
        User(userId: $userId) {
            lastCheckedNotifications {
                formatted
            }
            userId
            notifications(
                filter: {
                    actionBy_not: { userId: $userId }
                    concept_not: { id: null }
                }
                first: 30
            ) {
                id
                message
                concept {
                    id
                    title
                }
                createdAt {
                    formatted
                }
                actionBy {
                    userId
                    username
                    firstName
                    lastName
                }
                type
            }
        }
    }
`
export const ALL_USER_NOTIFICATIONS_QUERY = gql`
    query AllUserNotifications(
        $first: Int
        $offset: Int
        $filter: _NotificationFilter
    ) {
        Notification(
            filter: $filter
            first: $first
            offset: $offset
            orderBy: createdAt_desc
        ) {
            id
            message
            concept {
                id
                title
            }
            createdAt {
                formatted
            }
            actionBy {
                userId
                username
                firstName
                lastName
            }
            type
        }
    }
`

export const UPDATE_NOTIFICATIONS_TIMESTAMP = gql`
    mutation UserNotificationsUpdate(
        $userId: ID!
        $lastCheckedNotifications: _Neo4jDateTimeInput
    ) {
        UpdateUser(
            userId: $userId
            lastCheckedNotifications: $lastCheckedNotifications
        ) {
            userId
            lastCheckedNotifications {
                formatted
            }
        }
    }
`
export const DELETE_NOTIFICATION = gql`
    mutation DeleteNotification($id: ID!) {
        DeleteNotification(id: $id) {
            id
        }
    }
`
