import i18n from "i18next"
import Backend from "i18next-locize-backend"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import LastUsed from "locize-lastused"
import { locizePlugin } from "locize"

const isProduction = process.env.NODE_ENV === "production"

const locizeOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
    apiKey: isProduction ? "" : process.env.REACT_APP_LOCIZE_APIKEY,
    referenceLng: "en",
}

if (!isProduction) {
    // locize-lastused
    // sets a timestamp of last access on every translation segment on locize
    // -> safely remove the ones not being touched for weeks/months
    // https://github.com/locize/locize-lastused
    i18n.use(LastUsed)
}

i18n
    // i18next-locize-backend
    // loads translations from your project, saves new keys to it (saveMissing: true)
    // https://github.com/locize/i18next-locize-backend
    .use(Backend)
    // locize-editor
    // InContext Editor of locize
    .use(locizePlugin)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: !isProduction,
        fallbackLng: "en",
        saveMissing: !isProduction,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            wait: true,
            useSuspense: false,
        },

        backend: locizeOptions,
        locizeLastUsed: locizeOptions,
    })

export default i18n
