import { Concept, User, AccessType } from "../__generated__/types"

export const getEditingPermission = (
    concept: Concept | null,
    user: User | null
) => {
    let permission =
        concept?.addedUsers?.some(
            (item) =>
                item.User.userId === user.userId &&
                (item.type === AccessType.EDITOR ||
                    item.type === AccessType.OWNER)
        ) ?? false
    if (!permission) {
        permission =
            concept?.addedTeams?.some((item) =>
                item.Team.members.some(
                    (member) =>
                        member.userId === user.userId &&
                        (item.type === AccessType.EDITOR ||
                            item.type === AccessType.OWNER)
                )
            ) ?? false
    }
    return permission
}

export const getOwnerPermission = (concept: Concept, user: User) => {
    let permission =
        concept?.addedUsers?.some(
            (item) =>
                item.User?.userId === user.userId &&
                item.type === AccessType.OWNER
        ) ?? false
    if (!permission) {
        permission =
            concept?.addedTeams?.some((item) =>
                item.Team?.members.some(
                    (member) =>
                        member.userId === user.userId &&
                        item.type === AccessType.OWNER
                )
            ) ?? false
    }
    return permission
}
