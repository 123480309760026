import { useMutation } from "@apollo/client"
import {
    Box,
    IconButton,
    CircularProgress,
    FormControl,
    DialogTitle,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    Typography,
    useMediaQuery,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import React, { useState } from "react"
import { ADD_CONCEPT_WEB_LINK, CREATE_CONCEPT } from "../graphql/mutations"

import { GRANT_USER_ACCESS } from "./permissions/graphql"
import {
    GrantUserAccessMutation,
    GrantUserAccessMutationVariables,
} from "./permissions/__generated__/graphql"
import {
    CreateConceptCompleteMutation,
    CreateConceptCompleteMutationVariables,
    AddConceptWebLinkMutation,
    AddConceptWebLinkMutationVariables,
} from "../graphql/__generated__/mutations"
import { useAuth } from "../providers/AuthProvider"
import { ConceptFieldSettings } from "../util/SystemSettings"
import FetchLink from "./FetchLink"
import TagInput from "./tags/TagInput"
import RichTextEditor from "./RichTextEditor"
import { ADD_CONCEPT_CATEGORY } from "./categories/graphql"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { AccessType, Category, Link } from "../__generated__/types"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import { Loading } from "./Loading"
import {
    AddConceptCategoryMutation,
    AddConceptCategoryMutationVariables,
} from "./categories/__generated__/graphql"
import CategoryTreeSelector from "./categories/CategoryTreeSelector"
import ImageUploadDialog from "./images/ImageUploadDialog"
import ClearIcon from "@material-ui/icons/Clear"
import EditIcon from "@material-ui/icons/Edit"
const toTitleCase = (string) => {
    if (typeof string === "string") {
        return string.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
    } else {
        return string
    }
}

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(1.5),
        paddingTop: 0,
        minWidth: "300px",
        width: "100%",
        maxWidth: "100vw",
        height: "100%",
    },
    titleContainer: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        },
    },
    fieldContainer: {
        margin: theme.spacing(2),
    },
    richTextEditor: {
        margin: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        minHeight: "40vh",
        overflow: "hidden",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        padding: theme.spacing(0.5),
    },
    imageArea: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid",
        borderColor: theme.palette.divider,
        textAlign: "center",
    },

    imageContainer: {
        width: "50%",
        margin: "auto",
    },
}))

type LanguageDataObject = {
    title: string
    summary: string
    description: string
    imageUrl: string | null
}
interface LongConceptFormProps {
    onClose: () => void
    openSnackbar: (string: string) => void
}
export default function IdeaForm(props: LongConceptFormProps) {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { currentUser } = useAuth()
    const [submitting, setSubmitting] = useState(false)
    const [languages, setLanguages] = useState<string[]>(["English"])
    const [createFromUrl, setCreateFromUrl] = useState(false)
    const [category, setCategory] = useState<Category>(null)
    const [imageOpen, setImageOpen] = useState(false)
    const [addCategory] = useMutation<
        AddConceptCategoryMutation,
        AddConceptCategoryMutationVariables
    >(ADD_CONCEPT_CATEGORY)
    const [tags, setTags] = useState<Array<string>>([])
    const [linkItem, setLinkItem] = useState(null)
    const [linkLoading, setLinkLoading] = useState(false)
    const [idea, setIdea] = useState({
        type: "Idea",
        isPublic: false,
        isPublicAccessType: AccessType.NONE,
    })
    const [english, setEnglish] = useState<LanguageDataObject>({
        title: "",
        summary: "",
        description: "",
        imageUrl: null,
    })
    const [german, setGerman] = useState<LanguageDataObject>({
        title: "",
        summary: "",
        description: "",
        imageUrl: null,
    })
    const [spanish, setSpanish] = useState<LanguageDataObject>({
        title: "",
        summary: "",
        description: "",
        imageUrl: null,
    })
    const [grantUserAccess] = useMutation<
        GrantUserAccessMutation,
        GrantUserAccessMutationVariables
    >(GRANT_USER_ACCESS)
    const [createConcept] = useMutation<
        CreateConceptCompleteMutation,
        CreateConceptCompleteMutationVariables
    >(CREATE_CONCEPT, {
        awaitRefetchQueries: true,
        refetchQueries: ["IdeasFeed"],
    })
    const [addWebLink] = useMutation<
        AddConceptWebLinkMutation,
        AddConceptWebLinkMutationVariables
    >(ADD_CONCEPT_WEB_LINK)
    const handleSubmission = async () => {
        setSubmitting(true)
        const {
            data: { CreateConcept: NewConcept },
        } = await createConcept({
            variables: {
                userId: currentUser?.userId,
                concept: {
                    ...idea,
                    ...english,
                    type: category?.isRoot
                        ? category.name
                        : category.parent.name,
                },
                tags: tags,
            },
        })
        if (NewConcept) {
            if (linkItem) {
                addWebLink({
                    variables: {
                        from: { id: NewConcept?.id },
                        to: { id: linkItem?.id },
                    },
                })
            }
            if (category) {
                addCategory({
                    variables: {
                        id: NewConcept?.id,
                        categoryId: category.id,
                    },
                })
            }
            await grantUserAccess({
                variables: {
                    conceptId: NewConcept.id,
                    userId: currentUser.userId,
                    accessType: AccessType.OWNER,
                },
            })

            if (!!props.openSnackbar) props.openSnackbar(NewConcept.id)
            props.onClose()
        }
    }
    const handleLink = (linkData: Link) => {
        setLinkItem(linkData)
        let formItem = {
            title: linkData.title,
            summary:
                linkData.type && linkData.provider
                    ? `${toTitleCase(linkData.type)} from ${toTitleCase(
                          linkData.provider
                      )}`
                    : null,
            description: linkData.description
                ? JSON.stringify([
                      {
                          type: "paragraph",
                          children: [{ text: linkData.description }],
                      },
                  ])
                : null,
            imageUrl: linkData.image,
        }
        setEnglish({ ...english, ...formItem })
        setLinkLoading(false)
    }

    return (
        <Dialog open={true} fullScreen>
            {submitting ? (
                <Loading size={150} />
            ) : (
                <>
                    <DialogTitle>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexDirection={mobile ? "column" : "row"}
                        >
                            <Box
                                mb={mobile ? 2 : 0}
                                className={classes.titleContainer}
                            >
                                <Typography variant="h5">
                                    Concept Form
                                </Typography>
                                {mobile && (
                                    <IconButton
                                        onClick={() => {
                                            props.onClose()
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Box
                                    display="flex"
                                    mr={2}
                                    width={mobile ? "100%" : "auto"}
                                >
                                    <Autocomplete
                                        size="small"
                                        style={{ marginRight: 10 }}
                                        getOptionLabel={(option: string) =>
                                            option
                                        }
                                        fullWidth={mobile}
                                        multiple
                                        onChange={(event, newValue) => {
                                            setLanguages(newValue)
                                        }}
                                        value={languages}
                                        options={[
                                            "English",
                                            "German",
                                            "Spanish",
                                        ]}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                value={languages}
                                                variant="outlined"
                                                label="Languages"
                                                placeholder="Search Languages"
                                            />
                                        )}
                                    />
                                    <Button
                                        variant="outlined"
                                        onClick={() => setCreateFromUrl(true)}
                                    >
                                        Create from URL
                                    </Button>
                                </Box>
                                {!mobile && (
                                    <IconButton
                                        onClick={() => {
                                            props.onClose()
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box className={classes.formContainer}>
                            {createFromUrl && (
                                <Box className={classes.fieldContainer}>
                                    <Box display="flex" alignItems="center">
                                        <FetchLink
                                            setLoading={setLinkLoading}
                                            handleLink={(link: Link) => {
                                                handleLink(link)
                                            }}
                                            autoFocus={true}
                                        />
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setCreateFromUrl(false)
                                                setLinkItem(null)
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                    {linkLoading && (
                                        <Box
                                            m={1}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CircularProgress
                                                disableShrink
                                                size="1em"
                                            />{" "}
                                            Fetching Link Details...
                                        </Box>
                                    )}
                                </Box>
                            )}

                            <Box className={classes.fieldContainer}>
                                <FormControl
                                    variant="outlined"
                                    required
                                    fullWidth
                                >
                                    <CategoryTreeSelector
                                        value={category}
                                        setValue={setCategory}
                                    />
                                </FormControl>
                            </Box>

                            <Box
                                display="flex"
                                mt={5}
                                mb={5}
                                flexDirection={mobile ? "column" : "row"}
                            >
                                {languages.length > 0 &&
                                    languages.map((item, idx) => {
                                        const setIdea =
                                            item === "English"
                                                ? setEnglish
                                                : item === "German"
                                                ? setGerman
                                                : item === "Spanish"
                                                ? setSpanish
                                                : null
                                        const idea =
                                            item === "English"
                                                ? english
                                                : item === "German"
                                                ? german
                                                : item === "Spanish"
                                                ? spanish
                                                : null
                                        return (
                                            <Box key={item} width="100%">
                                                <StringInput
                                                    setIdea={setIdea}
                                                    idea={idea}
                                                    language={item}
                                                />
                                            </Box>
                                        )
                                    })}
                            </Box>
                            <Box className={classes.imageContainer}>
                                {!!english.imageUrl && (
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setImageOpen(true)
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setEnglish({
                                                    ...english,
                                                    imageUrl: null,
                                                })
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                <Box
                                    className={classes.imageArea}
                                    p={mobile ? 10 : 20}
                                    style={{
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundImage: `url(${english.imageUrl})`,
                                        cursor: !!english.imageUrl
                                            ? "default"
                                            : "pointer",
                                    }}
                                    onClick={() => {
                                        if (!english.imageUrl) {
                                            setImageOpen(true)
                                        }
                                    }}
                                    position="relative"
                                >
                                    {!english.imageUrl?.length &&
                                        "Click to add image"}
                                </Box>
                            </Box>
                            <Box className={classes.fieldContainer}>
                                <TagInput
                                    setTags={setTags}
                                    disabled={false}
                                    clickable={false}
                                />
                            </Box>
                            <Box className={classes.fieldContainer}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={idea.isPublic}
                                            name="Public"
                                            onChange={(e) => {
                                                setIdea({
                                                    ...idea,
                                                    isPublic: e.target.checked,
                                                    isPublicAccessType: e.target
                                                        .checked
                                                        ? AccessType.VIEWER
                                                        : AccessType.NONE,
                                                })
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Public"
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={english.title?.length === 0}
                            variant="contained"
                            onClick={handleSubmission}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </>
            )}
            {!!imageOpen && (
                <ImageUploadDialog
                    onClose={() => setImageOpen(false)}
                    editPermission={true}
                    onImageUploaded={(imageUrl: string) => {
                        setEnglish({
                            ...english,
                            imageUrl: imageUrl,
                        })
                        setImageOpen(false)
                    }}
                />
            )}
        </Dialog>
    )
}

interface StringInputProps {
    setIdea: (data: LanguageDataObject) => void
    language: string
    idea: LanguageDataObject
}
const StringInput = (props: StringInputProps) => {
    const { setIdea, idea, language } = props
    const classes = useStyles()
    return (
        <>
            <Box>
                <Box mr={2} ml={2}>
                    <Typography variant="h6">{language}</Typography>
                </Box>
                <Box className={classes.fieldContainer}>
                    <TextField
                        inputProps={{
                            maxLength: ConceptFieldSettings.Title.maxLength,
                        }}
                        onChange={(e) => {
                            setIdea({ ...idea, title: e.target.value })
                        }}
                        fullWidth
                        variant="outlined"
                        name="title"
                        value={idea.title}
                        label="Title"
                        required
                    />
                </Box>
                <Box className={classes.fieldContainer}>
                    <TextField
                        multiline
                        fullWidth
                        rows={3}
                        onChange={(e) => {
                            setIdea({ ...idea, summary: e.target.value })
                        }}
                        inputProps={{
                            maxLength: ConceptFieldSettings.Summary.maxLength,
                        }}
                        value={idea.summary}
                        variant="outlined"
                        name="summary"
                        label="Summary (Tagline)"
                    />
                </Box>
                <Box className={classes.richTextEditor}>
                    <Box className={classes.label}>Body</Box>
                    <RichTextEditor
                        editorId={props.language}
                        isReadOnly={false}
                        alwaysEditing={true}
                        onChange={(val) => {
                            setIdea({ ...idea, description: val })
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}
