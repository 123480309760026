import { Dialog } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { TextField } from "mui-rff"
import React, { useState } from "react"
import { Form } from "react-final-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { useAuth } from "../providers/AuthProvider"

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    progress: {
        position: "absolute",
    },
    errorMessage: {
        color: theme.palette.error.main,
    },
}))

export default function SignupPage() {
    const classes = useStyles()

    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const { signup, currentUser, logout } = useAuth()
    const history = useHistory()

    const submitData = async (values) => {
        setLoading(true)
        const response = await signup({
            email: values.email || currentUser.email,
            password: values.password || currentUser.refreshToken,
            userData: {
                ...values,
                imageUrl: currentUser?.photoURL ?? null,
            },
        }).catch((error) => {
            setLoading(false)
            setErrors([error.message])
        })
        console.log(response, "====")
    }

    const { t } = useTranslation("login")
    const needEmailPassword = !Boolean(currentUser?.displayName)
    const initialValues = currentUser?.displayName
        ? {
              firstName: currentUser?.displayName?.split(" ")[0],
              lastName: currentUser?.displayName?.split(" ")[1],
              phoneNumber: currentUser?.phoneNumber,
          }
        : null
    return (
        <Dialog fullScreen open style={{ zIndex: 99999 }}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("signUp", "Sign up")}
                    </Typography>
                    <Form
                        initialValues={initialValues}
                        onSubmit={(values) => {
                            submitData(values)
                        }}
                        validate={(values) => {
                            const errors = {}
                            if (!values.username) {
                                errors.username = t("required", "Required")
                            }
                            if (!values.firstName) {
                                errors.firstName = t("required", "Required")
                            }
                            if (!values.lastName) {
                                errors.lastName = t("required", "Required")
                            }
                            if (needEmailPassword) {
                                if (!values.password) {
                                    errors.password = t("required", "Required")
                                }
                                if (!values.email) {
                                    errors.email = t("required", "Required")
                                }
                                if (!values.confirmPassword) {
                                    errors.confirmPassword = t(
                                        "required",
                                        "Required"
                                    )
                                } else if (
                                    values.confirmPassword !== values.password
                                ) {
                                    errors.confirmPassword = t(
                                        "pwnotmatch",
                                        "Must match"
                                    )
                                }
                            }

                            return errors
                        }}
                        render={({ handleSubmit, values }) => (
                            <form className={classes.form} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label={t("firstName", "First Name")}
                                            name="firstName"
                                            autoComplete="firstName"
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label={t("lastName", "Last Name")}
                                            name="lastName"
                                            autoComplete="lastName"
                                            disabled={loading}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="username"
                                            label={t("userName", "User Name")}
                                            name="username"
                                            autoComplete="username"
                                            disabled={loading}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="phoneNumber"
                                            label={t(
                                                "phoneNumber",
                                                "Phone Number"
                                            )}
                                            name="phoneNumber"
                                            autoComplete="phoneNumber"
                                            pattern="[7-9]{1}[0-9]{9}"
                                            disabled={loading}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            label={t("email", "Email Address")}
                                            value={
                                                currentUser
                                                    ? currentUser.email
                                                    : undefined
                                            }
                                            disabled={
                                                !needEmailPassword || loading
                                            }
                                            name="email"
                                            autoComplete="email"
                                        />
                                    </Grid>
                                    {needEmailPassword && (
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    label={t(
                                                        "password",
                                                        "Password"
                                                    )}
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    disabled={loading}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="confirmPassword"
                                                    label={t(
                                                        "password_confirm",
                                                        "Confirm Password"
                                                    )}
                                                    type="password"
                                                    id="confirmPassword"
                                                    autoComplete="current-password"
                                                    disabled={loading}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {t("signUp", "Sign up")}
                                    {loading && (
                                        <CircularProgress
                                            disableShrink
                                            size={30}
                                            className={classes.progress}
                                        />
                                    )}
                                </Button>
                                <Grid container justify="flex-end">
                                    {errors.length > 0 &&
                                        errors.map((error) => (
                                            <Grid
                                                item
                                                className={classes.errorMessage}
                                            >
                                                {error}
                                            </Grid>
                                        ))}
                                    {needEmailPassword ? (
                                        <Grid item>
                                            <Link
                                                href="login"
                                                variant="body2"
                                                disabled={loading}
                                            >
                                                {t(
                                                    "signInMessage",
                                                    "Already have an account? Sign in"
                                                )}
                                            </Link>
                                        </Grid>
                                    ) : (
                                        <Grid item>
                                            <Button
                                                onClick={() =>
                                                    logout() &&
                                                    history.push("/login")
                                                }
                                            >
                                                Logout
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </form>
                        )}
                    />
                </div>
            </Container>
        </Dialog>
    )
}
