import { useState } from "react"
import { useTheme } from "@material-ui/core/styles"
import {
    Box,
    Divider,
    Typography,
    List,
    ListItem,
    LinearProgress,
    Tab,
    Tabs,
    Paper,
} from "@material-ui/core"

import { Exercise, ExerciseSubmission } from "../../__generated__/types"

import ConceptListItem from "../ConceptListItem"
import MobileStepper from "@material-ui/core/MobileStepper"
import Button from "@material-ui/core/Button"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import ConceptRadar from "../charts/ConceptRadar"
import BubbleChart from "../charts/BubbleChart"
interface UserScoringSubmissionResultsProps {
    exercise: Exercise
    submission: ExerciseSubmission
}
const UserScoringSubmissionResults = (
    props: UserScoringSubmissionResultsProps
) => {
    const theme = useTheme()
    const [tab, setTab] = useState(0)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
    }
    const [activeStep, setActiveStep] = useState(0)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    const concepts =
        props.exercise?.includedChecklist?.items?.map((item) => {
            if (item.concept) {
                return item.concept
            } else {
                return null
            }
        }) ??
        props.exercise.includedConcepts ??
        []
    const focusedConcept = concepts[activeStep]
    const focusedScores = props.submission?.criteriaScores?.filter(
        (score) => score.scoredConcept.id === focusedConcept.id
    )
    const bubbleChartData = concepts.map((concept) => {
        let object = {
            item: concept,
            values: [],
        }
        props.submission.criteriaScores.forEach((score) => {
            if (score.scoredConcept.id === concept.id) {
                object.values.push({
                    criteriaName: score.criteria?.name,
                    value: score.value,
                })
            }
        })
        return object
    })
    const radarData =
        focusedScores?.map((item) => {
            return {
                criteriaName: item.criteria?.name,
                values: [
                    {
                        id: item.scoredConcept?.id,
                        label: item.scoredConcept?.title,
                        value: item.value,
                    },
                ],
            }
        }) ?? []
    return (
        <Box
            overflow="hidden"
            flexDirection="column"
            display="flex"
            height="100%"
        >
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                overflow="hidden"
                height="100%"
            >
                <Paper square>
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        centered
                        indicatorColor="secondary"
                    >
                        <Tab label="List View" />
                        <Tab label="Radar View" />
                        <Tab label="Bubble Chart View" />
                    </Tabs>
                </Paper>

                {tab !== 2 && (
                    <>
                        <Divider />
                        <MobileStepper
                            variant="dots"
                            steps={concepts?.length}
                            position="static"
                            style={{
                                backgroundColor:
                                    //@ts-ignore
                                    theme.palette.background.level2,
                            }}
                            activeStep={activeStep}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={
                                        activeStep === concepts?.length - 1
                                    }
                                >
                                    Next
                                    {theme.direction === "rtl" ? (
                                        <KeyboardArrowLeft />
                                    ) : (
                                        <KeyboardArrowRight />
                                    )}
                                </Button>
                            }
                            backButton={
                                <Button
                                    size="small"
                                    onClick={handleBack}
                                    disabled={activeStep === 0}
                                >
                                    {theme.direction === "rtl" ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Back
                                </Button>
                            }
                        />
                        <Divider />
                        <ConceptListItem
                            item={focusedConcept}
                            disableClick={true}
                            showSummary={true}
                        />
                    </>
                )}
                {tab === 0 && (
                    <>
                        <List style={{ overflowY: "auto" }}>
                            {focusedScores?.map((score, index) => {
                                return (
                                    <ListItem divider key={score.id}>
                                        <Box
                                            key={index}
                                            display="flex"
                                            flexDirection="column"
                                            width="100%"
                                        >
                                            <Box p={1}>
                                                <Box p={0.5}>
                                                    <Typography variant="body1">
                                                        {score.criteria?.name}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <Box p={0.5} flexGrow={1}>
                                                        <LinearProgress
                                                            value={score.value}
                                                            variant="determinate"
                                                        />
                                                    </Box>
                                                    <Box p={0.5}>
                                                        {score.value}
                                                    </Box>
                                                </Box>

                                                <Box p={0.5}>
                                                    <Typography
                                                        variant="body2"
                                                        color={"textSecondary"}
                                                    >
                                                        Notes
                                                    </Typography>
                                                </Box>
                                                <Box p={0.5}>{score.notes}</Box>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </>
                )}
                {tab === 1 && (
                    <Box flexGrow={1} width="100%" height={500}>
                        <ConceptRadar data={radarData} />
                    </Box>
                )}
                {tab === 2 && (
                    <Box flexGrow={1} width="100%" height={500}>
                        <BubbleChart
                            axisOptions={
                                focusedScores?.map((score, index) => {
                                    return score.criteria?.name
                                }) ?? []
                            }
                            data={bubbleChartData}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default UserScoringSubmissionResults
