import { useCallback, useMemo } from "react"
import {
    DefaultNamespace,
    Namespace,
    useTranslation,
    UseTranslationOptions,
} from "react-i18next"

export default function useAwaitTranslation<
    N extends Namespace = DefaultNamespace
>(ns?: N, options?: UseTranslationOptions) {
    const { t: originalT, ready, i18n } = useTranslation(ns, options)

    const t = useCallback(
        (
            key: Parameters<typeof originalT>[0],
            df: Parameters<typeof originalT>[1]
        ) => {
            if (ready) return originalT(key, df)
            else return df
        },
        [ready, originalT]
    )

    return useMemo(() => ({ i18n, ready, t }), [i18n, ready, t])
}
