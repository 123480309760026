import React from "react"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import { createStyles, Theme, makeStyles, fade } from "@material-ui/core/styles"
import WidgetEditToolbar from "./WidgetEditToolbar"
import { Widget } from "../../useWidgets"
import DragHandleIcon from "@material-ui/icons/DragHandle"
import {
    expandWidgetVar,
    permissionsVar,
} from "../../../../providers/GlobalState"
import ZoomInIcon from "@material-ui/icons/ZoomIn"
import { IconButton } from "@material-ui/core"
import { useReactiveVar } from "@apollo/client"
import DetailsField from "../../../DetailsField"
import useWidget from "../../useWidget"
import { SizeMe } from "react-sizeme"
import { WidgetTypeName } from "../../useWidgetTypes"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            color: theme.palette.text.primary,
            background: fade(theme.palette.secondary.main, 0.15),
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            width: "100%",
            alignItems: "center",
            flexWrap: "wrap",
        },
        small: {
            fontSize: 12,
        },
        actions: {
            marginLeft: "auto",
            color: theme.palette.text.hint,
        },
    })
)
interface WidgetHeaderProps {
    label: string | null
    actions?: React.ReactNode[]
    hideDivider?: boolean
    widget: Widget
    editing: boolean
    lockLabel?: boolean
}
const WidgetHeader = (props: WidgetHeaderProps) => {
    const classes = useStyles()
    const expandedWidgetId = useReactiveVar(expandWidgetVar)
    const editPermission = useReactiveVar(permissionsVar)
    const { onEditConfig, isInitialized, config, widget } = useWidget(
        props.widget.id
    )

    const handleSave = (val: string) => {
        onEditConfig({
            ...config,
            label: val,
        })
    }
    return (
        !!isInitialized && (
            <SizeMe monitorWidth monitorHeight>
                {({ size }) => (
                    <Box className={classes.root}>
                        <Box className={classes.header}>
                            {props.editing && (
                                <Box
                                    display="flex"
                                    flexGrow={0}
                                    className="widget-drag-handle"
                                    style={{
                                        cursor: "move",
                                        paddingLeft: "8px",
                                    }}
                                >
                                    <DragHandleIcon />
                                </Box>
                            )}
                            <Box pl={0.5} overflow="hidden" flexGrow={1}>
                                <DetailsField
                                    value={props.label ?? ""}
                                    handleEdit={handleSave}
                                    editable={
                                        !!editPermission &&
                                        widget.type.name !==
                                            WidgetTypeName.CriteriaField
                                    }
                                    className={"body2"}
                                    maxLength={50}
                                    parentWidth={size.width - 15}
                                    grow={true}
                                />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                className={classes.actions}
                            >
                                {props.actions && props.actions}
                                {props.editing && (
                                    <WidgetEditToolbar widget={props.widget} />
                                )}
                            </Box>
                            {expandedWidgetId !== props.widget.id && (
                                <IconButton
                                    style={{ padding: "8px" }}
                                    onClick={() =>
                                        expandWidgetVar(props.widget.id)
                                    }
                                >
                                    <ZoomInIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>

                        {!props.hideDivider && <Divider />}
                    </Box>
                )}
            </SizeMe>
        )
    )
}

export default WidgetHeader
