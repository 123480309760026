import React, { useState } from "react"
import { Button } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import {
    Dialog,
    DialogContent,
    DialogActions,
    Menu,
    MenuItem,
    Box,
    Tooltip,
    ListItemText,
    ListItemIcon,
} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { useHistory } from "react-router-dom"
import DatePickerPopper from "./DatePickerPopper"
import ConceptListDialog from "./concept-list/ConceptListDialog"
import { QRToggle } from "./QRToggle"
import ImageUploadDialog from "./images/ImageUploadDialog"
import { useGlobalStyles } from "../styles/globalStyles"
import DeleteIcon from "@material-ui/icons/Delete"
import CropFreeIcon from "@material-ui/icons/CropFree"
import ListIcon from "@material-ui/icons/List"
import { Concept } from "../__generated__/types"
import GroupIcon from "@material-ui/icons/Group"
import ConceptPermissions from "./permissions/ConceptPermissions"
import { DELETE_CONCEPT } from "../graphql/mutations"
import {
    DeleteConceptMutation,
    DeleteConceptMutationVariables,
} from "../graphql/__generated__/mutations"
import { useMutation } from "@apollo/client"
interface ConceptOptionsProps {
    item: Concept
    ownerPermission: boolean
    editPermission: boolean
}

export default function ConceptOptions(props: ConceptOptionsProps) {
    const [confirm, openConfirm] = useState(false)
    const globalClasses = useGlobalStyles()
    const [deleteConcept] = useMutation<
        DeleteConceptMutation,
        DeleteConceptMutationVariables
    >(DELETE_CONCEPT, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.item),
            })
        },
    })
    const [openPermissions, setOpenPermissions] = useState(false)
    const [openConceptLists, setOpenConceptLists] = useState(false)
    const [openQR, setOpenQR] = useState(false)
    const [openImage, setOpenImage] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [
        datesAnchorEl,
        setDatesAnchorEl,
    ] = React.useState<null | HTMLElement>(null)
    const history = useHistory()
    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const open = Boolean(anchorEl)
    const { t } = useAwaitTranslation("widgets")
    const handleDelete = () => {
        history.push(`/feed`)
        deleteConcept({
            variables: {
                conceptId: props.item.id,
            },
        })
    }

    return (
        <>
            <Tooltip title="Concept options">
                <IconButton onClick={handleButtonClick}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>

            {open && (
                <Menu
                    disableScrollLock
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setAnchorEl(null)
                    }}
                >
                    <MenuItem
                        component="li"
                        onClick={() => {
                            setAnchorEl(null)
                            setOpenPermissions(true)
                        }}
                        divider
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <GroupIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary="View Permissions"
                        />
                    </MenuItem>

                    <MenuItem
                        component="li"
                        onClick={() => {
                            setAnchorEl(null)
                            setOpenQR(true)
                        }}
                        divider
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <CropFreeIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary="View QR Code"
                        />
                    </MenuItem>

                    <MenuItem
                        component="li"
                        divider
                        onClick={() => {
                            setAnchorEl(null)
                            setOpenConceptLists(true)
                        }}
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <ListIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary="All Concept Lists"
                        />
                    </MenuItem>
                    {props.ownerPermission && (
                        <Box>
                            <MenuItem onClick={() => openConfirm(true)}>
                                <ListItemIcon
                                    className={globalClasses.smallMenuIcon}
                                >
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        className:
                                            globalClasses.smallMenuListItemText,
                                    }}
                                    primary="Delete Concept"
                                />
                            </MenuItem>
                        </Box>
                    )}
                </Menu>
            )}
            {confirm && (
                <Dialog open={confirm}>
                    <DialogContent>
                        {t(
                            "confirmDelete",
                            "Are you sure you want to delete this?"
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                openConfirm(false)
                            }}
                        >
                            {t("no", "No")}
                        </Button>
                        <Button onClick={handleDelete}>
                            {t("yes", "Yes")}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <DatePickerPopper
                anchorEl={datesAnchorEl}
                onClose={() => setDatesAnchorEl(null)}
                item={props.item}
            />
            {openConceptLists && (
                <ConceptListDialog
                    item={props.item}
                    onClose={() => setOpenConceptLists(false)}
                    editPermission={props.editPermission}
                />
            )}
            {openQR && <QRToggle onClose={() => setOpenQR(false)} />}
            {openImage && (
                <ImageUploadDialog
                    item={props.item}
                    onClose={() => setOpenImage(false)}
                    editPermission={props.editPermission}
                />
            )}
            {openPermissions && (
                <ConceptPermissions
                    item={props.item}
                    onClose={() => setOpenPermissions(false)}
                />
            )}
        </>
    )
}
