import React from "react"
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Legend,
} from "recharts"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import { SizeMe } from "react-sizeme"
import Box from "@material-ui/core/Box"

type RadarDataValue = {
    id: string
    label: string
    value: number
}
const useStyles = makeStyles((theme) => ({
    radarChart: {
        "& svg": {
            overflow: "overlay !important",
        },
    },
}))

export type RadarDataObject = {
    criteriaName: string
    values: RadarDataValue[]
}

interface ConceptRadarProps {
    data: RadarDataObject[]
    editing?: boolean
}
export default function ConceptRadarChart(props: ConceptRadarProps) {
    const theme = useTheme()
    const classes = useStyles()
    const formattedData = props.data?.map((item) => {
        let object = {
            fullMark: 100,
            subject: item.criteriaName,
        }
        item.values?.map((val) => {
            object[val.id] = val.value
            return object
        })
        return object
    })
    const radarObjects = props.data?.[0]?.values
    const sizeAdjustment = props.editing ? 25 : 0
    return (
        <Box height="100%">
            <Box
                display="flex"
                width="100%"
                height="100%"
                overflow="hidden"
                alignItems="center"
            >
                <Box width="100%" overflow="hidden" height="100%">
                    <SizeMe monitorHeight monitorWidth>
                        {({ size }) => (
                            <div
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    overflow: "hidden",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <RadarChart
                                    outerRadius={size.height / 4}
                                    width={size.width}
                                    height={size.height}
                                    data={formattedData}
                                    className={classes.radarChart}
                                >
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="subject" />
                                    <PolarRadiusAxis domain={[0, 100]} />
                                    {radarObjects?.map((item, index) => {
                                        return (
                                            <Radar
                                                key={item.id}
                                                style={{
                                                    margin: "auto",
                                                }}
                                                name={item.label}
                                                dataKey={item.id}
                                                stroke={
                                                    index === 0
                                                        ? theme.palette.primary
                                                              .main
                                                        : theme.palette
                                                              .secondary.main
                                                }
                                                fill={
                                                    index === 0
                                                        ? theme.palette.primary
                                                              .main
                                                        : theme.palette
                                                              .secondary.main
                                                }
                                                fillOpacity={0.6}
                                            />
                                        )
                                    })}

                                    <Legend />
                                </RadarChart>
                            </div>
                        )}
                    </SizeMe>
                </Box>
            </Box>
        </Box>
    )
}
