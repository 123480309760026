import { Box, Paper } from "@material-ui/core"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import { useQuery } from "@apollo/client"
import { CONCEPT_BY_ID } from "../../../graphql/queries"
import ConceptCard from "../../ConceptCard"
import ConceptDetails from "../../ConceptDetails"
type WidgetConfig = {
    label: string
    conceptId: string
    view: string
}

const ConceptWidget: WidgetTypeComponent = (props) => {
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const { data, loading } = useQuery(CONCEPT_BY_ID, {
        variables: {
            id: config?.conceptId,
        },
        skip: !isInitialized || !config?.conceptId,
    })
    const concept = data?.Concept?.[0]
    return (
        <Box
            component={Paper}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            pb={2}
            key={props.widget.id}
            overflow={"auto"}
        >
            <WidgetHeader
                label={config?.label}
                actions={[]}
                widget={props.widget}
                editing={props.editing}
            />
            {!!concept &&
                (config.view === "Card" ? (
                    <ConceptCard item={concept} />
                ) : (
                    <ConceptDetails concept={concept} />
                ))}
        </Box>
    )
}

export default ConceptWidget
