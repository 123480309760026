import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { useQuery } from "@apollo/client"
import Chip from "@material-ui/core/Chip"
import { ALL_USER_QUERY } from "../users/graphql"
import Typography from "@material-ui/core/Typography"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

import {
    AllUsersQuery,
    AllUsersQueryVariables,
} from "../users/__generated__/graphql"

import { User } from "../../__generated__/types"

import { FilterProps } from "./util/types"
import { getNewState } from "./util/getNewState"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        height: "100%",
    },
    listbox: {
        overflow: "auto",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    popper: {
        zIndex: theme.zIndex.modal + 2,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },
    header: {
        fontSize: theme.typography.h6.fontSize,
        padding: theme.spacing(1),
    },
    betweenFiltersText: {
        color: theme.palette.text.hint,
    },
}))

export default function UserFilter(props: FilterProps) {
    const classes = useStyles()
    const [value, setValue] = useState<User[]>([])
    const { data, loading } = useQuery<AllUsersQuery, AllUsersQueryVariables>(
        ALL_USER_QUERY
    )
    const { t } = useAwaitTranslation("global")

    useEffect(() => {
        if (data?.users && props.item) {
            setValue(
                data?.users?.filter(
                    (x: User) => props.item.value.indexOf(x.userId) !== -1
                )
            )
        }
    }, [data, props.item])
    return (
        <Autocomplete
            options={data?.users ?? []}
            multiple
            classes={{
                popper: classes.popper,
                listbox: classes.listbox,
            }}
            getOptionLabel={(option) =>
                option.lastName + ", " + option.firstName
            }
            value={value}
            size="small"
            loading={loading}
            loadingText={t("loadingUsers", "Loading users...")}
            noOptionsText={t("noUsersMatch", "No users that match...")}
            onChange={(event: any, newValue: User[], reason: string) => {
                setValue(newValue)
                const newState = getNewState(
                    props.item,
                    newValue,
                    props.filters,
                    "userId"
                )
                if (newState) {
                    props.setFilters(newState)
                }
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={
                            <Typography
                                variant="body2"
                                style={{ whiteSpace: "normal" }}
                            >
                                {option.lastName + ", " + option.firstName}
                            </Typography>
                        }
                        {...getTagProps({ index })}
                        style={{ height: "100%" }}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t("users", "Users ")}
                    placeholder={t("searchUsers", "Search Users")}
                />
            )}
        />
    )
}
