import { useQuery } from "@apollo/client"
import { GET_EXERCISE_BY_ID } from "./graphql"
import {
    GetExerciseByIdQuery,
    GetExerciseByIdQueryVariables,
} from "./__generated__/graphql"
import { useTheme } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import { Loading } from "../Loading"
import ExerciseConceptRanking from "./ExerciseConceptRanking"
import ExerciseSubmissionsResults from "./ExerciseSubmissionResults"
import { ExerciseCategory } from "../../__generated__/types"
import ExerciseConceptScoring from "./scoring/ExerciseConceptScoring"
interface ExerciseContainerProps {
    exerciseId: string
    onClose: () => void
    results: boolean
    viewResults: () => void
}
export default function ExerciseContainer(props: ExerciseContainerProps) {
    const theme = useTheme()
    const { data, loading } = useQuery<
        GetExerciseByIdQuery,
        GetExerciseByIdQueryVariables
    >(GET_EXERCISE_BY_ID, {
        variables: {
            exerciseId: props.exerciseId,
        },
    })
    const exercise = data?.Exercise?.[0] ?? null
    return (
        <Dialog
            open={true}
            fullScreen
            PaperProps={{
                style: { backgroundColor: theme.palette.background.default },
            }}
        >
            {loading && <Loading size={150} />}
            {exercise?.type?.category === ExerciseCategory.RANKING &&
                (props.results ? (
                    <ExerciseSubmissionsResults
                        exercise={exercise}
                        onClose={() => {
                            props.onClose()
                        }}
                    />
                ) : (
                    <ExerciseConceptRanking
                        exercise={exercise}
                        onClose={() => {
                            props.onClose()
                        }}
                        viewResults={props.viewResults}
                    />
                ))}

            {exercise?.type?.category === ExerciseCategory.SCORING &&
                (props.results ? (
                    <ExerciseSubmissionsResults
                        exercise={exercise}
                        onClose={() => {
                            props.onClose()
                        }}
                    />
                ) : (
                    <ExerciseConceptScoring
                        exercise={exercise}
                        onClose={() => props.onClose()}
                        viewResults={props.viewResults}
                        submissionId={"99936354-cf09-44de-b160-e11bffddb6a3"}
                    />
                ))}
        </Dialog>
    )
}
