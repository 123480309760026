import { gql } from "@apollo/client"

export const USER_QUERY = gql`
    query MyAccount($userId: ID) {
        User(filter: { userId: $userId }) {
            userId
            firstName
            lastName
            email
            bio
            role
            phoneNumber
            imageUrl
            username
            country
            lastLogin {
                formatted
            }
            createdDate {
                formatted
            }
            numConceptsCreated
        }
    }
`
export const USER_FILTERED = gql`
    query UserFiltered($filter: _UserFilter) {
        User(filter: $filter) {
            userId
            username
            email
            firstName
            lastName
        }
    }
`
export const FULL_USER_QUERY = gql`
    query FullUsers {
        User(orderBy: lastName_asc) {
            userId
            email
            firstName
            lastName
            username
            role
            email
            imageUrl
            conceptsCreated {
                id
            }
            lastLogin {
                formatted
            }
        }
    }
`
export const USER_MANAGEMENT_LINE_ITEM = gql`
    query UserManagementLineItem($userId: ID!) {
        User(userId: $userId) {
            userId
            firstName
            lastName
            username
            email
            phoneNumber
            country
            imageUrl
            bio
            role
            conceptsCreated {
                id
            }
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }
            teams {
                teamId
                name
                imageUrl
                members {
                    userId
                    firstName
                    lastName
                }
            }
            admin {
                teamId
                name
                imageUrl
            }
            leads {
                teamId
                name
                imageUrl
            }
        }
    }
`
export const ALL_USER_QUERY = gql`
    query AllUsers {
        users: User(orderBy: lastName_asc) {
            userId
            email
            firstName
            lastName
            username
            imageUrl
        }
    }
`
export const ADD_USER_HOME_CONCEPT = gql`
    mutation AddUserHomeConcept($userId: ID!, $conceptId: ID!) {
        AddUserHomeConcept(from: { userId: $userId }, to: { id: $conceptId }) {
            from {
                userId
            }
            to {
                id
            }
        }
    }
`

export const DELETE_USER = gql`
    mutation DeleteUser($userId: ID!) {
        DeleteUser(userId: $userId) {
            userId
        }
    }
`

export const USER_PROFILE = gql`
    query UserProfile($username: String) {
        user: User(filter: { username: $username }) {
            userId
            firstName
            lastName
            username
            email
            phoneNumber
            country
            imageUrl
            role
            bio
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }
            homeConcept {
                id
                title
                summary
                type
                description
                isPublic
                isPublicAccessType
                imageUrl
                commentCount
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
                startDate {
                    formatted
                }
                endDate {
                    formatted
                }
                usesDashboard {
                    id
                    name
                    layout
                    description
                }
                dashboardIds
                likedBy {
                    userId
                    username
                }
                tags(orderBy: name_asc) {
                    name
                }
                createdAt {
                    formatted
                }
                createdBy {
                    userId
                    username
                    firstName
                    lastName
                    imageUrl
                }
                webLink {
                    id
                    url
                    title
                    icon
                    provider
                }
                connections {
                    id
                    title
                    category {
                        id
                        name
                        isRoot
                    }
                    rootCategory {
                        id
                        name
                        isRoot
                    }
                    imageUrl
                }
                industries {
                    industryId
                    name
                }
                addedUsers {
                    type
                    User {
                        userId
                    }
                }
                addedTeams {
                    type
                    Team {
                        teamId
                        members {
                            userId
                        }
                    }
                }
            }
        }
    }
`

export const UPDATE_USER = gql`
    mutation updateUser(
        $userId: ID!
        $firstName: String
        $lastName: String
        $email: String
        $phoneNumber: String
        $country: String
        $role: SystemRole
        $username: String
        $language: LanguageString
        $imageUrl: String
        $token: String
        $lastLogin: _Neo4jDateTimeInput
        $createdDate: _Neo4jDateTimeInput
        $activeThemeType: ThemeType
    ) {
        UpdateUser(
            userId: $userId
            firstName: $firstName
            lastName: $lastName
            email: $email
            phoneNumber: $phoneNumber
            country: $country
            username: $username
            role: $role
            language: $language
            imageUrl: $imageUrl
            token: $token
            lastLogin: $lastLogin
            createdDate: $createdDate
            activeThemeType: $activeThemeType
        ) {
            userId
            firstName
            lastName
            email
            phoneNumber
            country
            role
            username
            language
            imageUrl
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }
            activeThemeType
            homeConcept {
                id
                title
            }
            conceptLists {
                id
                title
                type
                conceptIds
                concepts {
                    id
                    title
                    type
                    summary
                    imageUrl
                    category {
                        id
                        name
                    }
                    children {
                        id
                        title
                        type
                        imageUrl
                        category {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const CREATE_USER = gql`
    mutation CreateUser(
        $firstName: String
        $lastName: String
        $email: String
        $phoneNumber: String
        $country: String
        $role: SystemRole
        $username: String
        $language: LanguageString
        $imageUrl: String
        $token: String
        $lastLogin: _Neo4jDateTimeInput
        $createdDate: _Neo4jDateTimeInput
    ) {
        CreateUser(
            firstName: $firstName
            lastName: $lastName
            email: $email
            phoneNumber: $phoneNumber
            country: $country
            username: $username
            role: $role
            language: $language
            imageUrl: $imageUrl
            token: $token
            lastLogin: $lastLogin
            createdDate: $createdDate
        ) {
            userId
            firstName
            lastName
            email
            phoneNumber
            country
            role
            username
            language
            imageUrl
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }
            homeConcept {
                id
                title
            }
            conceptLists {
                id
                title
                type
                concepts {
                    id
                    title
                    type
                    imageUrl
                }
            }
        }
    }
`

export const MERGE_USER = gql`
    mutation mergeUser(
        $userId: ID!
        $firstName: String
        $lastName: String
        $email: String
        $phoneNumber: String
        $country: String
        $role: SystemRole
        $username: String
        $language: LanguageString
        $imageUrl: String
        $token: String
        $lastLogin: _Neo4jDateTimeInput
        $createdDate: _Neo4jDateTimeInput
    ) {
        MergeUser(
            userId: $userId
            firstName: $firstName
            lastName: $lastName
            email: $email
            phoneNumber: $phoneNumber
            country: $country
            username: $username
            role: $role
            language: $language
            imageUrl: $imageUrl
            token: $token
            lastLogin: $lastLogin
            createdDate: $createdDate
        ) {
            userId
            firstName
            lastName
            email
            phoneNumber
            country
            role
            username
            language
            imageUrl
            createdDate {
                formatted
            }
            lastLogin {
                formatted
            }
            homeConcept {
                id
                title
            }
            conceptLists {
                id
                title
                type
                concepts {
                    id
                    title
                    type
                    imageUrl
                }
            }
        }
    }
`
