import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_PARAGRAPH,
    ELEMENT_OL,
    ELEMENT_UL,
    withPlaceholders,
} from "@udecode/plate"

export const withStyledPlaceHolders = (components: any) =>
    withPlaceholders(components, [
        {
            key: ELEMENT_PARAGRAPH,
            placeholder: "",
            hideOnBlur: true,
        },
        {
            key: ELEMENT_H1,
            placeholder: "Heading 1",
            hideOnBlur: true,
        },
        {
            key: ELEMENT_H2,
            placeholder: "Heading 2",
            hideOnBlur: true,
        },
        {
            key: ELEMENT_H3,
            placeholder: "Heading 3",
            hideOnBlur: true,
        },
        {
            key: ELEMENT_OL,
            placeholder: "List",
            hideOnBlur: true,
        },
        {
            key: ELEMENT_UL,
            placeholder: "List",
            hideOnBlur: true,
        },
    ])
