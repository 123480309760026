export const ConceptTypes = [
    "Feature",
    "Idea",
    "Observation",
    "Persona",
    "Problem",
    "Resource",
    "Requirement",
    "Solution",
    "Trend",
]
