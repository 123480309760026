import React, { useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import AddIcon from "@material-ui/icons/Add"
import ClearIcon from "@material-ui/icons/Clear"
import Tooltip from "@material-ui/core/Tooltip"
import { IconButton } from "@material-ui/core"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

import { FilterItem } from "./util/types"
import TypeFilter from "./TypeFilter"
import CategoryFilter from "./CategoryFilter"
import IndustryFilter from "./IndustryFilter"
import UserFilter from "./UserFilter"
import TagsFilter from "./TagsFilter"
import ConceptFilter from "./ConceptFilter"
import NotificationTypeFilter from "./NotificationTypeFilter"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        height: "100%",
    },
    listbox: {
        overflowY: "auto",
        overflowX: "hidden",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    popper: {
        zIndex: theme.zIndex.modal + 2,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },
    header: {
        fontSize: theme.typography.h6.fontSize,
        padding: theme.spacing(1),
    },
    betweenFiltersText: {
        color: theme.palette.text.hint,
    },
}))

const getComponent = (item) => {
    if (item.type === "Category") {
        return CategoryFilter
    } else if (item.type === "User") {
        return UserFilter
    } else if (item.type === "Industry") {
        return IndustryFilter
    } else if (item.type === "Type") {
        return TypeFilter
    } else if (item.type === "Tags") {
        return TagsFilter
    } else if (item.type === "Concept") {
        return ConceptFilter
    } else if (item.type === "NotificationType") {
        return NotificationTypeFilter
    }
}
interface FilterContainerProps {
    setFilters: (filters: FilterItem[]) => void
    filters: FilterItem[]
    filterOptions: FilterItem[]
    hideHeading?: boolean
}
export default function Filters(props: FilterContainerProps) {
    const { filters, setFilters } = props
    const classes = useStyles()
    const [currentSelection, setCurrentSelection] = useState(null)
    const [adding, setAdding] = useState(false)

    const { t } = useAwaitTranslation("global")

    const handleFilterAddition = (value) => {
        if (
            filters.filter(
                (item) =>
                    item.secondLevelFilter === value.secondLevelFilter &&
                    item.firstLevelFilter === value.firstLevelFilter
            ).length === 0
        ) {
            setFilters([...filters, ...[value]])
        }
        setAdding(false)
        setCurrentSelection(null)
    }
    const handleFilterRemoval = (filter: FilterItem) => {
        const newState = filters.filter((item: FilterItem) => {
            return (
                item.secondLevelFilter !== filter.secondLevelFilter ||
                item.firstLevelFilter !== filter.firstLevelFilter
            )
        })
        setFilters(newState)
    }

    return (
        <Box
            padding={1}
            display="flex"
            width="100%"
            flexDirection="column"
            maxHeight="600px"
        >
            {!props.hideHeading && (
                <Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box className={classes.header}>
                        {t("activeFilters", "Active Filters")}
                    </Box>
                </Box>
            )}

            <Box padding={1}>
                <Divider />
            </Box>
            {filters.length > 0 && (
                <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.listbox}
                >
                    {filters.map((filterItem, idx) => {
                        const Component = getComponent(filterItem)
                        return (
                            <Box key={idx}>
                                <Box display="flex">
                                    <Box width="100%" p={1}>
                                        <Component
                                            item={filterItem}
                                            setFilters={(filters) =>
                                                setFilters(filters)
                                            }
                                            filters={filters}
                                        />
                                    </Box>
                                    {!filterItem.locked && (
                                        <Box display="flex" alignItems="center">
                                            <Tooltip title="Remove filter">
                                                <IconButton
                                                    onClick={() => {
                                                        handleFilterRemoval(
                                                            filterItem
                                                        )
                                                    }}
                                                    size="small"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    )}
                                </Box>
                                {idx !== filters.length - 1 && (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        className={classes.betweenFiltersText}
                                    >
                                        {t("and", "And")}
                                    </Box>
                                )}
                            </Box>
                        )
                    })}
                </Box>
            )}
            {!adding ? (
                <Tooltip title={t("addFilter", "Add filter")}>
                    <Box
                        display="flex"
                        width="100%"
                        justifyItems="space-between"
                        pt="0.25rem"
                    >
                        <Button
                            style={{ width: "100%", padding: "0.75rem" }}
                            onClick={() => {
                                setAdding(true)
                            }}
                        >
                            <AddIcon />
                            {t("addFilterField", "Add filter field")}
                        </Button>
                    </Box>
                </Tooltip>
            ) : (
                <>
                    <Box
                        display="flex"
                        justifyContent="center"
                        className={classes.betweenFiltersText}
                    >
                        {t("and", "And")}
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Box padding={1} width="100%">
                            <Autocomplete
                                openOnFocus
                                size="small"
                                options={props.filterOptions?.filter(
                                    (x) => !x.locked
                                )}
                                getOptionLabel={(option) => option.field}
                                onChange={(event, newValue, reason) => {
                                    handleFilterAddition(newValue)
                                }}
                                getOptionDisabled={(option) => {
                                    return (
                                        props.filters?.filter(
                                            (x) =>
                                                x.firstLevelFilter ===
                                                    option.firstLevelFilter &&
                                                x.secondLevelFilter ===
                                                    option.secondLevelFilter
                                        )?.length > 0
                                    )
                                }}
                                classes={{
                                    popper: classes.popper,
                                    listbox: classes.listbox,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={"Field"}
                                        autoFocus
                                        placeholder={t(
                                            "selectFilterField",
                                            "Select Filter Field"
                                        )}
                                    />
                                )}
                            />
                        </Box>

                        <Tooltip title="Close">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setAdding(false)
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </>
            )}
        </Box>
    )
}
