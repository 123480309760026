import { useState, useEffect, useCallback } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import {
    Box,
    Divider,
    useMediaQuery,
    IconButton,
    Tooltip,
    Typography,
    Paper,
    LinearProgress,
    Dialog,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import { useMutation } from "@apollo/client"
import { Exercise, ExerciseStatus } from "../../../__generated__/types"
import {
    CREATE_EXERCISE_SUBMISSION,
    ADD_EXERCISE_SUBMISSION_EXERCISE,
    DELETE_EXERCISE_SUBMISSION,
    ADD_EXERCISE_SUBMISSION_USER,
} from "../graphql"
import {
    AddExerciseSubmissionExerciseMutation,
    AddExerciseSubmissionExerciseMutationVariables,
    AddExerciseSubmissionUserMutation,
    AddExerciseSubmissionUserMutationVariables,
    CreateExerciseSubmissionMutation,
    CreateExerciseSubmissionMutationVariables,
    DeleteExerciseSubmissionMutation,
    DeleteExerciseSubmissionMutationVariables,
} from "../__generated__/graphql"

import InformationModal from "../InformationModal"
import InfoIcon from "@material-ui/icons/Info"
import DoneAllIcon from "@material-ui/icons/DoneAll"
import { CountdownTimer } from "../Timer"
import ClearIcon from "@material-ui/icons/Clear"
import ConceptListItem from "../../ConceptListItem"
import { Loading } from "../../Loading"
import { useAuth } from "../../../providers/AuthProvider"
import ListIcon from "@material-ui/icons/List"
import FocusedConcept from "./FocusedConcept"
import SubmissionReview from "./SubmissionReview"
const useStyles = makeStyles((theme: Theme) => ({
    label: {
        color: theme.palette.text.hint,
        fontSize: 12,
    },
    list: {
        overflowY: "auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: 0,
        paddingLeft: theme.spacing(1),
    },

    section: {
        height: "100%",
    },

    root: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
    },
    conceptContainer: {
        width: "25%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            margin: 0,
        },
        margin: theme.spacing(2),
    },
    focusedConceptContainer: {
        width: "75%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            margin: 0,
        },
        margin: theme.spacing(2),
    },
    toolbar: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    countdownTimer: {
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(0, 0.5),
        },
    },
}))

interface ExerciseConceptScoringProps {
    exercise: Exercise
    viewResults: () => void
    submissionId?: string
    onClose: () => void
}
function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}
export default function ExerciseConceptScoring(
    props: ExerciseConceptScoringProps
) {
    const theme = useTheme()

    const { currentUser } = useAuth()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [createSubmission] = useMutation<
        CreateExerciseSubmissionMutation,
        CreateExerciseSubmissionMutationVariables
    >(CREATE_EXERCISE_SUBMISSION)
    const [addSubmission] = useMutation<
        AddExerciseSubmissionExerciseMutation,
        AddExerciseSubmissionExerciseMutationVariables
    >(ADD_EXERCISE_SUBMISSION_EXERCISE)
    const [addUser] = useMutation<
        AddExerciseSubmissionUserMutation,
        AddExerciseSubmissionUserMutationVariables
    >(ADD_EXERCISE_SUBMISSION_USER)
    const [listOpen, setListOpen] = useState(false)
    const [position, setPosition] = useState(0)
    const [deleteSubmission] = useMutation<
        DeleteExerciseSubmissionMutation,
        DeleteExerciseSubmissionMutationVariables
    >(DELETE_EXERCISE_SUBMISSION)
    const [submissionId, setSubmissionId] = useState(null)

    const [openInfoModal, setOpenInfoModal] = useState(false)
    const [finished, setFinished] = useState(false)

    const classes = useStyles()
    const concepts =
        props.exercise?.includedChecklist?.items?.map((item) => {
            if (item.concept) {
                return item.concept
            } else {
                return null
            }
        }) ??
        props.exercise.includedConcepts ??
        []
    const createNewSubmission = useCallback(async () => {
        const {
            data: { CreateExerciseSubmission: NewSubmission },
        } = await createSubmission({
            variables: {
                notes: "",
                status: ExerciseStatus.IN_PROGRESS,
            },
        })
        await addSubmission({
            variables: {
                exerciseId: props.exercise.id,
                submissionId: NewSubmission.id,
            },
        })
        addUser({
            variables: {
                submissionId: NewSubmission.id,
                userId: currentUser?.userId,
            },
        })
        setSubmissionId(NewSubmission.id)
    }, [
        props.exercise.id,
        createSubmission,
        addSubmission,
        addUser,
        currentUser,
    ])
    useEffect(() => {
        if (!props.submissionId) {
            createNewSubmission()
        } else {
            setSubmissionId(props.submissionId)
        }
    }, [props.submissionId, createNewSubmission])
    const submission = props.exercise?.submissions?.filter(
        (submission) => submission?.id === submissionId
    )?.[0]
    if (!submission) {
        return <Loading size={150} />
    }

    const focusedConcept = concepts?.[position] ?? null
    const listItems = (
        <Box
            className={classes.conceptContainer}
            component={mobile ? Box : Paper}
        >
            {concepts.map((concept, index) => {
                const length = submission?.criteriaScores?.filter(
                    (criteriaScore) =>
                        criteriaScore.scoredConcept?.id === concept.id &&
                        criteriaScore.notes?.length > 0 &&
                        criteriaScore.value > 0
                )?.length

                return (
                    <Box key={index}>
                        <ConceptListItem
                            item={concept}
                            onClick={() => {
                                if (mobile) {
                                    setListOpen(false)
                                }
                                setPosition(index)
                            }}
                            selected={index === position}
                            hideDivider={true}
                        />
                        <Box p={1} pb={0}>
                            <LinearProgressWithLabel
                                value={
                                    (length / props.exercise?.criteria.length) *
                                    100
                                }
                            />
                        </Box>
                        <Divider />
                    </Box>
                )
            })}
        </Box>
    )
    return (
        !!props.exercise &&
        !!submission && (
            <>
                <Box className={classes.root}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        p={1}
                        className={classes.toolbar}
                    >
                        {!!mobile && (
                            <>
                                <IconButton onClick={() => setListOpen(true)}>
                                    <ListIcon />
                                </IconButton>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    style={{ marginRight: theme.spacing(1) }}
                                />
                            </>
                        )}
                        {props.exercise.duration && (
                            <Box className={classes.countdownTimer}>
                                <CountdownTimer
                                    duration={props.exercise.duration}
                                />
                            </Box>
                        )}

                        <Divider orientation="vertical" flexItem />

                        <Tooltip title="View exercise information">
                            <IconButton onClick={() => setOpenInfoModal(true)}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Finish exercise">
                            <IconButton
                                onClick={() => {
                                    setFinished(true)
                                }}
                            >
                                <DoneAllIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Quit exercise">
                            <IconButton
                                onClick={() => {
                                    deleteSubmission({
                                        variables: {
                                            id: submissionId,
                                        },
                                    })
                                    props.onClose()
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    <Divider />
                    <Box display="flex" height="100%">
                        {!mobile && listItems}
                        <Box className={classes.focusedConceptContainer}>
                            {focusedConcept && (
                                <FocusedConcept
                                    conceptId={focusedConcept.id}
                                    exercise={props.exercise}
                                    submission={submission}
                                    position={position}
                                    moveBack={() =>
                                        setPosition(
                                            (prevActivePosition) =>
                                                prevActivePosition - 1
                                        )
                                    }
                                    moveForward={() =>
                                        setPosition(
                                            (prevActivePosition) =>
                                                prevActivePosition + 1
                                        )
                                    }
                                    conceptsLength={concepts?.length}
                                    key={focusedConcept.id}
                                    currentProgress={
                                        submission.criteriaScores?.filter(
                                            (criteriaScore) =>
                                                criteriaScore.scoredConcept
                                                    ?.id ===
                                                    focusedConcept.id &&
                                                !!criteriaScore.value
                                        )?.length
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                </Box>

                {openInfoModal && (
                    <InformationModal
                        exercise={props.exercise}
                        onClose={() => setOpenInfoModal(false)}
                    />
                )}
                {listOpen && (
                    <Dialog open={listOpen} fullScreen>
                        <DialogTitle>
                            Selected Concepts
                            <IconButton
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                }}
                                onClick={() => setListOpen(false)}
                            >
                                <ClearIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>{listItems}</DialogContent>
                    </Dialog>
                )}
                {finished && (
                    <SubmissionReview
                        submission={submission}
                        exercise={props.exercise}
                        viewResults={() => props.viewResults()}
                        closeModal={() => setFinished(false)}
                    />
                )}
            </>
        )
    )
}
