import { useQuery } from "@apollo/client"
import {
    Popper,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Box,
    ClickAwayListener,
    TextField,
    useTheme,
    InputAdornment,
    CircularProgress,
} from "@material-ui/core"
import { ROOT_CATEGORY_QUERY } from "./graphql"
import {
    RootCategoriesQuery,
    RootCategoriesQueryVariables,
} from "./__generated__/graphql"
import React from "react"
import { getConceptTypeIcon } from "../../util/ConceptTypeStyles"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import { Category, SystemRole } from "../../__generated__/types"
import { useAuth } from "../../providers/AuthProvider"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            fontSize: 13,
            position: "relative",
        },
        button: {
            width: "100%",
            padding: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },

        popper: {
            boxShadow: theme.shadows[10],
            borderRadius: 3,
            zIndex: 1800,
            fontSize: 13,
            backgroundColor: theme.palette.background.paper,
        },
        header: {
            borderBottom: "1px solid #e1e4e8",
            padding: "8px 10px",
            fontWeight: 600,
        },
        paper: {
            boxShadow: "none",
            margin: 0,
            color: "#586069",
            fontSize: 13,
        },

        popperDisablePortal: {
            position: "relative",
        },

        treeRoot: {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            padding: theme.spacing(1, 3),
        },
        treeContainer: {
            flexGrow: 1,
            overflowY: "auto",
            padding: theme.spacing(0, 1),
        },
        treeItem: {
            backgroundColor: "transparent !important",
            padding: 0,
        },
        iconContainer: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },

            borderRadius: theme.shape.borderRadius,
            display: "flex",
            alignItems: "center",
            margin: 0,
            width: "18px",
        },
        inactiveIconContainer: {
            cursor: "default",
            margin: 0,
            width: "18px",
        },
        content: {
            alignItems: "stretch",
        },
        listContainer: {
            maxHeight: 400,
            overflowY: "auto",
        },
    })
)

interface CategoryTreeSelectorProps {
    value: Category
    setValue: (category: Category) => void
    size?: "small" | "medium"
    saving?: boolean
    zIndex?: number
}
export default function CategoryTreeSelector(props: CategoryTreeSelectorProps) {
    const { value, setValue } = props
    const { data: categoryData, loading } = useQuery<
        RootCategoriesQuery,
        RootCategoriesQueryVariables
    >(ROOT_CATEGORY_QUERY)
    const ref = React.useRef(null)
    const { currentUser } = useAuth()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    let options = categoryData?.Category ?? []
    if (currentUser?.role !== SystemRole.ADMIN) {
        options = categoryData?.Category?.filter((x) => !x.isManaged)
    }
    const theme = useTheme()
    const classes = useStyles()
    React.useEffect(() => {
        if (!!categoryData && !value) {
            setValue(
                categoryData?.Category?.filter((op) => op.name === "Idea")?.[0]
            )
        }
    }, [categoryData, setValue, value])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!props.saving) {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = (event: React.ChangeEvent<{}>) => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = "category-selector"
    const TypeIcon = getConceptTypeIcon(
        value?.isRoot ? value?.name : value?.parent?.name
    )
    const position = ref?.current?.getBoundingClientRect() ?? null
    return (
        <div className={classes.root} ref={ref}>
            <TextField
                aria-describedby={id}
                onClick={handleClick}
                value={value?.name ?? ""}
                variant="outlined"
                size={props.size ?? "medium"}
                label={
                    <Box display="flex" alignItems="center">
                        <Box>Type</Box>
                        <ChevronRightIcon fontSize="small" />
                        <Box>Category</Box>
                    </Box>
                }
                disabled={!!props.saving}
                fullWidth
                InputProps={{
                    startAdornment: loading ? (
                        <InputAdornment position="start">
                            <CircularProgress size={25} disableShrink />
                        </InputAdornment>
                    ) : (
                        <InputAdornment position="start">
                            <TypeIcon fontSize="small" />
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    readOnly: true,
                    style: { cursor: "pointer" },
                }}
            />

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popper}
                style={{
                    top: position?.top ?? 0,
                    right: position?.right ?? 0,
                    left: position?.left ?? 0,
                    width: position?.width ?? 0,
                    zIndex: props.zIndex ?? theme.zIndex.modal + 1,
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Box className={classes.listContainer}>
                        {options.map((option, index) => {
                            return (
                                <TreeView
                                    key={option.id}
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    defaultExpanded={
                                        !value?.isRoot
                                            ? options
                                                  .map((op) => {
                                                      if (
                                                          op.id === value?.id ||
                                                          op.children.some(
                                                              (child) =>
                                                                  child.id ===
                                                                  value?.id
                                                          )
                                                      ) {
                                                          return op.id
                                                      } else {
                                                          return null
                                                      }
                                                  })
                                                  .filter((x) => !!x)
                                            : []
                                    }
                                >
                                    <TreeItemContainer
                                        node={option}
                                        setValue={(value: Category) => {
                                            setValue(value)
                                            setAnchorEl(null)
                                        }}
                                        value={value}
                                    />
                                </TreeView>
                            )
                        })}
                    </Box>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

interface TreeItemContainerProps {
    node: Category
    setValue: (value: Category) => void
    value: Category | null
}
const TreeItemContainer = (props: TreeItemContainerProps) => {
    const { node, setValue, value } = props
    const classes = useStyles()
    const TypeIcon = getConceptTypeIcon(node.name) ?? ""
    return (
        <Box key={node.id}>
            <TreeItem
                classes={{
                    label: classes.treeItem,
                    iconContainer:
                        node.children?.length > 0
                            ? classes.iconContainer
                            : classes.inactiveIconContainer,
                    content: classes.content,
                }}
                key={node.id}
                nodeId={node.id}
                onLabelClick={(e) => {
                    setValue(node)
                    e.preventDefault()
                }}
                label={
                    <>
                        <ListItem dense button selected={node.id === value?.id}>
                            <ListItemText primary={node.name} />
                            {node.isRoot && (
                                <ListItemIcon>
                                    <TypeIcon fontSize="small" />
                                </ListItemIcon>
                            )}
                        </ListItem>

                        <Divider />
                    </>
                }
            >
                {Array.isArray(node.children)
                    ? node.children?.map((node) => (
                          <TreeItemContainer
                              key={node.id}
                              node={node}
                              setValue={setValue}
                              value={value}
                          />
                      ))
                    : null}
            </TreeItem>
        </Box>
    )
}
