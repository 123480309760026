import React, { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Box, Button } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import { useAuth } from "../providers/AuthProvider"
import { useHotkeys } from "react-hotkeys-hook"
import { useHistory } from "react-router-dom"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    useMediaQuery,
    Typography,
    Grid,
    Divider,
} from "@material-ui/core"
import ImageUploadDialog from "./images/ImageUploadDialog"
import WorkspaceToggle from "./workspace/WorkspaceToggle"
import LikeToggle from "./liking/LikeToggle"
import SyncAltIcon from "@material-ui/icons/SyncAlt"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import AlertSubscriptionToggle from "./alert-subscriptions/AlertSubscriptionToggle"
import { useGlobalStyles } from "../styles/globalStyles"
import ShareConceptToggle from "./ShareConceptToggle"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import ConceptPermissions from "./permissions/ConceptPermissions"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { Concept, DashboardView } from "../__generated__/types"
import DetailedConnectionPreviewDialog from "./DetailedConnectionPreviewDialog"
import ConceptOptions from "./ConceptOptions"
import { getOwnerPermission } from "../util/GetPermissions"
import { WidgetStore } from "./dashboard/WidgetStore"
import { useReactiveVar } from "@apollo/client"
import { permissionsVar } from "../providers/GlobalState"
const useStyles = makeStyles((theme) => ({
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    optionsDialog: {
        textAlign: "center",
    },
    subText: {
        color: theme.palette.text.hint,
        fontSize: 12,
    },
    breadCrumbTitle: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
        borderRadius: theme.shape.borderRadius,
    },
}))

interface ConceptDetailsToolbarProps {
    item: Concept
    sidebar: boolean
    toggleSidebar: () => void
    toggleEditing: () => void
    selectedDashboard: DashboardView | null
    preview: boolean
    editing: boolean
}

const ConceptDetailsToolbar = (props: ConceptDetailsToolbarProps) => {
    const classes = useStyles()
    const item = props.item
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("xs"))
    const { currentUser } = useAuth()
    const history = useHistory()
    const globalStyles = useGlobalStyles()
    const [options, openOptions] = useState(false)
    const [confirm, openConfirm] = useState(false)
    const [open, setOpen] = useState(false)
    const [connectionPreviewOpen, setConnectionPreviewOpen] = useState(false)
    const [openPermissions, setOpenPermissions] = useState(false)
    const editPermission = useReactiveVar(permissionsVar)
    useHotkeys("l, k", (ev) => handleHotkey(ev))
    useHotkeys(
        "ctrl+l,command+l,ctrl+k,command+k",
        (event) => handleHotkey(event),
        { enableOnTags: ["INPUT", "SELECT", "TEXTAREA"] }
    )
    const handleHotkey = (event: KeyboardEvent) => {
        event.preventDefault()
        setConnectionPreviewOpen(true)
    }
    const { t } = useAwaitTranslation("widgets")

    const ownerPermissions = getOwnerPermission(props.item, currentUser)

    const handleDelete = () => {
        openConfirm(false)
    }

    return (
        <>
            <Box
                style={{
                    //@ts-ignore
                    backgroundColor: theme.palette.background.level2,
                }}
            >
                {currentUser?.userId && (
                    <Grid
                        container
                        justify="space-between"
                        style={{ minHeight: theme.spacing(6) }}
                    >
                        {!mobile && !props.preview && (
                            <Grid item sm={2} style={{ display: "flex" }}>
                                <IconButton onClick={() => history.goBack()}>
                                    <ChevronLeftIcon />{" "}
                                    <Typography variant="caption">
                                        Back
                                    </Typography>
                                </IconButton>
                            </Grid>
                        )}
                        <Grid item sm={8} xs={10}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                height="100%"
                            >
                                <Tooltip title="View all connections">
                                    <IconButton
                                        onClick={() =>
                                            setConnectionPreviewOpen(true)
                                        }
                                    >
                                        <SyncAltIcon
                                            className={
                                                globalStyles.labelStylesIcon
                                            }
                                        />
                                        <span className={globalStyles.count}>
                                            {item.connections.length}
                                        </span>
                                    </IconButton>
                                </Tooltip>
                                <AlertSubscriptionToggle item={props.item} />
                                <WorkspaceToggle
                                    location={"IdeaHeader"}
                                    item={props.item}
                                    size="medium"
                                />
                                <LikeToggle
                                    location={"IdeaHeader"}
                                    item={props.item}
                                    size="medium"
                                />
                                <ShareConceptToggle item={props.item} />

                                <ConceptOptions
                                    item={props.item}
                                    ownerPermission={ownerPermissions}
                                    editPermission={editPermission}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box
                                height="100%"
                                display="flex"
                                justifyContent="flex-end"
                            >
                                {props.selectedDashboard &&
                                    !mobile &&
                                    !!editPermission && (
                                        <>
                                            <WidgetStore
                                                dashboardId={
                                                    props.selectedDashboard?.id
                                                }
                                                enableEditing={() => {
                                                    if (!props.editing) {
                                                        props.toggleEditing()
                                                    }
                                                }}
                                            />
                                            <Tooltip
                                                title={
                                                    props.editing
                                                        ? "Lock Editing"
                                                        : "Unlock Editing"
                                                }
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        props.toggleEditing()
                                                    }
                                                    style={{ padding: "8px" }}
                                                >
                                                    {props.editing ? (
                                                        <LockOpenIcon />
                                                    ) : (
                                                        <LockIcon />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                {!props.preview && (
                                    <IconButton
                                        onClick={() => props.toggleSidebar()}
                                    >
                                        {props.sidebar ? (
                                            <ChevronRightIcon />
                                        ) : (
                                            <ChevronLeftIcon />
                                        )}
                                    </IconButton>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                )}
                <Divider />
            </Box>

            {connectionPreviewOpen && (
                <DetailedConnectionPreviewDialog
                    item={item}
                    editPermission={editPermission}
                    onClose={() => {
                        setConnectionPreviewOpen(false)
                    }}
                />
            )}
            {openPermissions && (
                <ConceptPermissions
                    item={props.item}
                    onClose={() => {
                        setOpenPermissions(false)
                    }}
                />
            )}
            {open && (
                <ImageUploadDialog
                    item={props.item}
                    onClose={() => {
                        setOpen(false)
                    }}
                    editPermission={editPermission}
                />
            )}
            <Dialog open={options}>
                <DialogTitle>{t("options", "Options")}</DialogTitle>
                <DialogContent className={classes.optionsDialog}>
                    <Button
                        style={{ color: "red" }}
                        onClick={() => {
                            openOptions(false)
                            openConfirm(true)
                        }}
                        variant="outlined"
                    >
                        {t("delete", "Delete")} {props.item.rootCategory?.name}
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            openOptions(false)
                        }}
                    >
                        {t("close", "Close")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirm}>
                <DialogContent>
                    {t(
                        "confirmDelete",
                        "Are you sure you want to delete this?"
                    )}{" "}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            openConfirm(false)
                            openOptions(true)
                        }}
                    >
                        {t("no", "No")}
                    </Button>
                    <Button onClick={handleDelete}>{t("yes", "Yes")}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConceptDetailsToolbar
