import { useTheme } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
interface ExerciseContainerProps {
    children: React.ReactElement[]
}
export default function ExerciseContainer(props: ExerciseContainerProps) {
    const theme = useTheme()

    return (
        <Dialog
            open={true}
            fullScreen
            PaperProps={{
                style: { backgroundColor: theme.palette.background.default },
            }}
        >
            {props.children}
        </Dialog>
    )
}
