import { _ConceptFilter } from "../../../__generated__/types"
import { FilterItem } from "./types"
export const getFilterObject = (filters: FilterItem[]) => {
    let filterObject: _ConceptFilter = {}
    if (filters && filters.length > 0) {
        filters.filter((filter) => {
            if (filter.value && filter.value.length !== 0) {
                if (
                    filter.firstLevelFilter &&
                    filter.firstLevelFilter?.length > 0
                ) {
                    filterObject[filter.firstLevelFilter] = {}
                    filterObject[filter.firstLevelFilter][
                        filter.secondLevelFilter
                    ] = filter.value
                } else {
                    filterObject[filter.secondLevelFilter] = filter.value
                }
            }
            return filterObject
        })
    }

    return filterObject
}
