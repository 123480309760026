import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import LocalSeeIcon from "@material-ui/icons/LocalSee"
import ReportProblemIcon from "@material-ui/icons/ReportProblem"
import ImageIcon from "@material-ui/icons/Image"
import BeenhereIcon from "@material-ui/icons/Beenhere"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver"
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt"
import BallotIcon from "@material-ui/icons/Ballot"

const CONCEPT_TYPE_STYLES = {
    Problem: { icon: ReportProblemIcon },
    Observation: { icon: LocalSeeIcon },
    Solution: { icon: BeenhereIcon },
    Trend: { icon: TrendingUpIcon },
    Persona: { icon: AssignmentIndIcon },
    Idea: { icon: EmojiObjectsIcon },
    Resource: { icon: MenuBookIcon },
    Requirement: { icon: RecordVoiceOverIcon },
    Feature: { icon: ViewQuiltIcon },
    UserInterview: { icon: BallotIcon },
    undefined: { icon: ImageIcon },
}

export const getConceptTypeIcon = (type) => {
    const style = CONCEPT_TYPE_STYLES[type]
    if (style && style.icon) {
        return style.icon
    }
    return EmojiObjectsIcon
}

export const getConceptTypeImage = (type) => {
    const style = CONCEPT_TYPE_STYLES[type]
    if (style && style.icon) {
        return style.icon
    }
    return ImageIcon
}

export default CONCEPT_TYPE_STYLES
