import { Button, IconButton, Menu, MenuItem } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import LanguageIcon from "@material-ui/icons/Translate"
import React, { useEffect, useRef, useState } from "react"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { UPDATE_USER } from "./users/graphql"
import {
    UpdateUserMutation,
    UpdateUserMutationVariables,
} from "./users/__generated__/graphql"
import { useMutation } from "@apollo/client"
import { LanguageString, User } from "../__generated__/types"

interface LanguagePickerProps {
    mobile?: boolean
    user?: User
}
export default function LanguagePicker(props: LanguagePickerProps) {
    const [openLanguage, setOpenLanguage] = useState(false)
    const { i18n } = useAwaitTranslation()
    const languageMenu = useRef<HTMLButtonElement>(null)
    const [langs, setLangs] = useState<
        { [key in string]: { nativeName: string } }
    >({ en: { nativeName: "English" } })

    useEffect(() => {
        i18n.services.backendConnector.backend.getLanguages(
            (_: any, ret: typeof langs) => {
                if (ret) {
                    setLangs(ret)
                }
            }
        )
    }, [i18n])

    const [updateUserLanguage] = useMutation<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >(UPDATE_USER)

    const handleLanguageChange = (code: LanguageString) => {
        i18n.changeLanguage(code) && setOpenLanguage(false)
        updateUserLanguage({
            variables: {
                userId: props.user.userId,
                language: code,
            },
        })
    }
    if (!!!langs) {
        return null
    }
    return (
        <>
            {props.mobile ? (
                <IconButton
                    ref={languageMenu}
                    onClick={() => setOpenLanguage((t) => !t)}
                >
                    <LanguageIcon />
                </IconButton>
            ) : (
                <Button
                    ref={languageMenu}
                    onClick={() => setOpenLanguage((t) => !t)}
                >
                    <LanguageIcon />
                    <span style={{ marginLeft: 10 }}>
                        {langs && langs[i18n.language]?.nativeName}
                    </span>
                    <ExpandMoreIcon fontSize="small" />
                </Button>
            )}
            <Menu
                anchorEl={languageMenu.current}
                open={Boolean(openLanguage)}
                onClose={() => setOpenLanguage(false)}
            >
                {Object.entries(langs).map(([code, config]) => (
                    <MenuItem
                        key={code}
                        selected={i18n.language === code}
                        onClick={() =>
                            handleLanguageChange(code as LanguageString)
                        }
                        lang={code}
                    >
                        {config.nativeName}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
