import React, { SyntheticEvent, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import {
    Toolbar,
    useTheme,
    Divider,
    Hidden,
    Box,
    SwipeableDrawer,
} from "@material-ui/core"
import Workspace from "./workspace/Workspace"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useHistory } from "react-router-dom"
import Logo from "./Logo"
import { Resizable } from "react-resizable"
import { navWidthVar } from "../providers/GlobalState"
const useStyles = makeStyles((theme) => ({
    drawer: {
        maxWidth: "100vw",
        flexShrink: 0,
        zIndex: theme.zIndex.appBar - 1,
        overflow: "hidden",
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    closedDrawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: 0,
        flexShrink: 0,
    },
    selected: {
        borderRight: "solid 5px",
        color: theme.palette.primary.main,
    },
    selectedColor: {
        color: theme.palette.primary.main,
    },
    logoToolbar: {
        backgroundColor: theme.palette.background.paper,
    },
    handle: {
        position: "absolute",
        right: 0,
        top: 0,
        height: "100vh",
        cursor: "ew-resize",
        width: "1em",
    },
}))
type ResizeHandleAxis = "e"

type ResizeCallbackData = {
    node: HTMLElement
    size: { width: number; height: number }
    handle: ResizeHandleAxis
}
interface NavDrawerProps {
    drawerOpen: boolean
    toggleDrawer: (state: boolean) => void
}

const NavDrawer = (props: NavDrawerProps) => {
    const classes = useStyles()
    const { t } = useAwaitTranslation("nav")
    const theme = useTheme()
    const [width, setWidth] = useState(300)
    const history = useHistory()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const onResize = (event, { element, size, handle }) => {
        if (!mobile) {
            setWidth(size.width)
        }
    }

    return (
        <div>
            <SwipeableDrawer
                anchor="left"
                className={
                    props.drawerOpen ? classes.drawer : classes.closedDrawer
                }
                onOpen={() => props.toggleDrawer(true)}
                onClose={() => props.toggleDrawer(false)}
                style={{
                    width: props.drawerOpen ? width : 0,
                }}
                variant={mobile ? "temporary" : "persistent"}
                open={props.drawerOpen}
                classes={{
                    paper: classes.drawer,
                }}
                PaperProps={{
                    style: {
                        width: props.drawerOpen ? width : 0,
                    },
                }}
            >
                <Resizable
                    height={Infinity}
                    width={width}
                    handle={<Box className={classes.handle} />}
                    onResize={onResize}
                    onResizeStop={(
                        e: SyntheticEvent,
                        data: ResizeCallbackData
                    ) => {
                        navWidthVar(data.size.width)
                    }}
                    minConstraints={[300, 100]}
                    maxConstraints={[650, Infinity]}
                >
                    <>
                        {!mobile && <Toolbar />}
                        <Hidden mdUp>
                            <Toolbar className={classes.logoToolbar}>
                                <Box
                                    onClick={() => {
                                        history.push(`/feed`)
                                        props.toggleDrawer(false)
                                    }}
                                >
                                    <Logo />
                                </Box>
                            </Toolbar>
                            <Divider />
                        </Hidden>
                        <Workspace
                            closeDrawer={() => props.toggleDrawer(false)}
                        />
                    </>
                </Resizable>
            </SwipeableDrawer>
        </div>
    )
}

export default NavDrawer
