import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    CircularProgress,
    TextField,
    Box,
} from "@material-ui/core"
import { Loading } from "../Loading"
import { DashboardView } from "../../__generated__/types"
import { useState, useEffect } from "react"
import { useDashboardTemplates } from "./useDashboardTemplates"

interface DashboardTemplateFormProps {
    onClose: () => void
    dashboard: DashboardView
    onTemplateCreation: () => void
}
export default function DashboardTemplateForm(
    props: DashboardTemplateFormProps
) {
    const {
        onCreateDashboardTemplate,
        isTemplatesInitialized,
        isWidgetsInitialized,
    } = useDashboardTemplates(props.dashboard.id)
    const [dashboard, setDashboard] = useState({
        name: "",
        description: "",
    })
    const [saving, setSaving] = useState(false)
    const handleSave = async () => {
        setSaving(true)
        await onCreateDashboardTemplate(
            dashboard.name,
            dashboard.description,
            JSON.parse(props.dashboard.layout)
        )
        props.onTemplateCreation()
        props.onClose()
    }
    useEffect(() => {
        setDashboard({
            name: props.dashboard.name ?? "",
            description: props.dashboard.description ?? "",
        })
    }, [props.dashboard])
    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>Dashboard Template Form</DialogTitle>
            {!!isTemplatesInitialized && isWidgetsInitialized ? (
                <>
                    <DialogContent>
                        <Box p={2}>
                            <TextField
                                value={dashboard.name}
                                onChange={(e) => {
                                    setDashboard({
                                        ...dashboard,
                                        name: e.target.value,
                                    })
                                }}
                                fullWidth
                                variant="outlined"
                                label="Name"
                            />
                        </Box>
                        <Box p={2}>
                            <TextField
                                value={dashboard.description}
                                onChange={(e) => {
                                    setDashboard({
                                        ...dashboard,
                                        description: e.target.value,
                                    })
                                }}
                                variant="outlined"
                                fullWidth
                                label="Description"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => props.onClose()}
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSave}
                            disabled={saving || dashboard.name.length === 0}
                            variant="contained"
                            color="primary"
                        >
                            {saving ? (
                                <CircularProgress
                                    size={25}
                                    disableShrink
                                    color="secondary"
                                />
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <Loading size={75} hideQuote={true} padding={3} />
            )}
        </Dialog>
    )
}
