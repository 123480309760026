// Material UI components
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Dialog from "@material-ui/core/Dialog"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import withStyles from "@material-ui/core/styles/withStyles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import React, { useState, useEffect } from "react"

import { useHistory } from "react-router-dom"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { useAuth } from "../providers/AuthProvider"

const styles = (theme) => ({
    page: {
        width: "100vw",
        height: "100vh",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    customError: {
        color: "red",
        fontSize: "0.8rem",
        marginTop: 10,
    },
    progress: {
        position: "absolute",
    },
})

const Login = (props) => {
    const { classes } = props
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { t } = useAwaitTranslation("login")
    const { login, isSignedIn, loginGoogle } = useAuth()
    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors({})
        setLoading(true)
        await login(email, password).catch((e) => {
            setErrors({ general: e.message })
            //setErrors({ general: t("failedLogin", "Failed to log in") })
            setLoading(false)
        })
    }
    useEffect(() => {
        if (isSignedIn) {
            history.push("/")
        }
    }, [isSignedIn, history])

    return (
        <Dialog fullScreen open={true} style={{ zIndex: 99999 }}>
            <Container component="main" maxWidth="xs" className={classes.page}>
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("login", "Login")}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("email", "Email")}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            disabled={loading}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("password", "Password")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            disabled={loading}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                            disabled={loading || !email || !password}
                        >
                            {t("signIn", "Sign In")}
                            {loading && (
                                <CircularProgress
                                    disableShrink
                                    size={30}
                                    className={classes.progress}
                                />
                            )}
                        </Button>

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={loginGoogle}
                            disabled={loading}
                        >
                            {t("signIn", "Sign In")} with Google
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link
                                    href="signup"
                                    variant="body2"
                                    disabled={loading}
                                >
                                    {t(
                                        "signUpMessage",
                                        "Don't have an account? Sign Up"
                                    )}
                                </Link>
                            </Grid>
                        </Grid>
                        {errors.general && (
                            <Typography
                                variant="body2"
                                className={classes.customError}
                            >
                                {errors.general}
                            </Typography>
                        )}
                    </form>
                </div>
            </Container>
        </Dialog>
    )
}

export default withStyles(styles)(Login)
