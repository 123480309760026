import React from "react"
import { DragIndicator } from "@styled-icons/material/DragIndicator"
import Tippy from "@tippyjs/react"
import { Theme } from "@material-ui/core"
import {
    ELEMENT_BLOCKQUOTE,
    ELEMENT_CODE_BLOCK,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    ELEMENT_IMAGE,
    ELEMENT_LI,
    ELEMENT_LIC,
    ELEMENT_MEDIA_EMBED,
    ELEMENT_OL,
    ELEMENT_PARAGRAPH,
    ELEMENT_TABLE,
    ELEMENT_TODO_LI,
    ELEMENT_UL,
    grabberTooltipProps,
    withDraggables,
} from "@udecode/plate"
import IconButton from "@material-ui/core/IconButton"
export const withStyledDraggables = (components: any, theme: Theme) => {
    return withDraggables(components, [
        {
            keys: [
                ELEMENT_PARAGRAPH,
                ELEMENT_UL,
                ELEMENT_OL,
                ELEMENT_LI,
                ELEMENT_LIC,
            ],
            level: 0,
        },
        {
            keys: [
                ELEMENT_PARAGRAPH,
                ELEMENT_BLOCKQUOTE,
                ELEMENT_TODO_LI,
                ELEMENT_H1,
                ELEMENT_H2,
                ELEMENT_H3,
                ELEMENT_H4,
                ELEMENT_H5,
                ELEMENT_H6,
                ELEMENT_IMAGE,
                ELEMENT_OL,
                ELEMENT_UL,
                ELEMENT_TABLE,
                ELEMENT_MEDIA_EMBED,
                ELEMENT_CODE_BLOCK,
            ],
            onRenderDragHandle: ({ element, ...otherProps }) => (
                <Tippy {...grabberTooltipProps}>
                    <IconButton size="small" style={{ cursor: "move" }}>
                        <span {...otherProps}>
                            <DragIndicator
                                style={{
                                    width: 18,
                                    height: 18,
                                    color: theme.palette.primary.main,
                                }}
                            />
                        </span>
                    </IconButton>
                </Tippy>
            ),
        },
        {
            keys: [
                ELEMENT_PARAGRAPH,
                ELEMENT_BLOCKQUOTE,
                ELEMENT_TODO_LI,
                ELEMENT_H1,
                ELEMENT_H2,
                ELEMENT_H3,
                ELEMENT_H4,
                ELEMENT_H5,
                ELEMENT_H6,
                ELEMENT_IMAGE,
                ELEMENT_OL,
                ELEMENT_UL,
                ELEMENT_TABLE,
                ELEMENT_MEDIA_EMBED,
                ELEMENT_CODE_BLOCK,
            ],
            styles: {
                dropLine: {
                    backgroundColor: `${theme.palette.secondary.main} !important`,
                },
            },
        },
    ])
}
