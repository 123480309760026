import { Theme, useMediaQuery } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import React, { useCallback, useRef, useState } from "react"
import useMountedState from "../util/useMountedState"
import useVirtualWindow from "../util/useVirtualWindow"
import { Loading } from "./Loading"

type Props = {
    // Needs to be memoized
    onFetchMore: (offset: number) => Promise<boolean>

    startOffset: number
    size: number
    pageSize: number
    children: (index: number) => React.ReactNode
    overscan?: number
}

export default function VirtualInfiniteList(props: Props) {
    const isMounted = useMountedState()
    const pageSize = props.pageSize
    const size = props.size
    const [offset, setOffset] = useState<number>(props.startOffset)
    const [hasMore, setHasMore] = useState(true)

    const parentRef = React.useRef<HTMLElement>(null)
    // @ts-expect-error not sure how to type this...
    const windowRef = React.useRef<HTMLElement>(window)

    const isMobile = useMediaQuery<Theme>((theme) =>
        theme.breakpoints.down("sm")
    )

    const rowVirtualizer = useVirtualWindow({
        size: hasMore ? size + 1 : size,
        windowRef,
        estimateSize: useCallback(() => (isMobile ? 390 : 210), [isMobile]),
        overscan: props.overscan ?? pageSize,
        parentRef,
    })

    const lastFetchedOffset = useRef(props.startOffset - pageSize)

    const [lastItem] = [...rowVirtualizer.virtualItems].reverse()

    const onFetchMore = props.onFetchMore

    React.useEffect(() => {
        if (
            // if have any data that is above the previous page
            offset > size - pageSize &&
            // and the last item is visible
            lastItem?.index === size &&
            // and there is more
            hasMore &&
            // and we already past this limit
            offset > lastFetchedOffset.current
        ) {
            lastFetchedOffset.current = offset
            ;(async function () {
                const hasMore = await onFetchMore(offset + pageSize)

                if (!isMounted()) {
                    return
                }

                setOffset(offset + pageSize)

                setHasMore(hasMore)
            })()
        }
    }, [
        lastItem?.index,
        size,
        hasMore,
        rowVirtualizer.virtualItems,
        isMounted,
        pageSize,
        offset,
        onFetchMore,
    ])

    return (
        <Box
            // @ts-expect-error https://github.com/mui-org/material-ui/issues/17010
            ref={parentRef}
            position="relative"
            width="100%"
        >
            <Box display="flex" height={`${rowVirtualizer.totalSize}px`}>
                {rowVirtualizer.virtualItems.map((virtualRow) => {
                    const isLoaderRow = virtualRow.index > size - 1

                    return (
                        <Box
                            // @ts-expect-error https://github.com/mui-org/material-ui/issues/17010
                            ref={(el: HTMLElement) => virtualRow.measureRef(el)}
                            style={{
                                width: "100%",
                                top: 0,
                                left: 0,
                                position: "absolute",
                                transform: `translateY(${virtualRow.start}px)`,
                            }}
                            key={virtualRow.index}
                            padding={1}
                            paddingTop={"8px"}
                        >
                            {isLoaderRow ? (
                                <Loading size={40} />
                            ) : (
                                props.children(virtualRow.index)
                            )}
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}
