import { useEffect } from "react"
import React from "react"
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete"
import { makeStyles, TextField } from "@material-ui/core"
import { TAG_CONCEPT, REMOVE_TAG } from "./graphql"
import {
    TagConceptsMutation,
    TagConceptsMutationVariables,
    RemoveConceptTagsMutation,
    RemoveConceptTagsMutationVariables,
} from "./__generated__/graphql"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import { SEARCH_TAGS } from "./graphql"
import {
    SearchTagsQuery,
    SearchTagsQueryVariables,
} from "./__generated__/graphql"
import { useMutation, useQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    searchBar: {
        width: "250px !important",
    },
    label: {
        fontWeight: theme.typography.fontWeightMedium,
        "&::before": {
            content: '"#"',
        },
    },
    listbox: {
        overflow: "auto",
    },
}))

const filter = createFilterOptions()
interface Props {
    concept?: any
    disabled?: boolean
    setTags?: Function
    clickable?: boolean
    autoFocus?: boolean
}

const TagInput = (props: Props) => {
    const classes = useStyles()
    const history = useHistory()
    const [value, setValue] = React.useState([])
    const [inputValue, setInputValue] = React.useState("")
    const [options, setOptions] = React.useState([])
    const { data, refetch } = useQuery<
        SearchTagsQuery,
        SearchTagsQueryVariables
    >(SEARCH_TAGS, {
        variables: { term: "" },
    })
    const [addTag] = useMutation<
        TagConceptsMutation,
        TagConceptsMutationVariables
    >(TAG_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: props.concept?.id },
            },
        ],
    })
    const [removeTag] = useMutation<
        RemoveConceptTagsMutation,
        RemoveConceptTagsMutationVariables
    >(REMOVE_TAG, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: props.concept?.id },
            },
        ],
    })

    useEffect(() => {
        if (props.concept) {
            setValue(props.concept?.tags?.map((t) => t.name))
        }
    }, [setValue, props.concept])

    useEffect(() => {
        if (inputValue) {
            refetch({ term: inputValue })
        }
        if (data) {
            const tagArray = data.Tag.map((t) => t.name)
            setOptions(tagArray)
        }
    }, [value, inputValue, refetch, data])

    const getRemovedValue = (oldArray, newArray) => {
        return oldArray.filter((tag) => newArray.indexOf(tag) === -1)[0]
    }

    const defaultProps = {
        color: "primary",
        clickable: props.clickable,
        size: "small",
        onClick: (ev: any) => {
            if (props.clickable) {
                history.push(`/feed?tag=${ev.target.innerText}`)
            }
        },
        classes: {
            label: classes.label,
        },
    }
    const disabledProps = {
        ...{
            disabled: false,
            onDelete: null,
        },
        ...defaultProps,
    }
    return (
        <div style={{ width: "100%" }}>
            <Autocomplete
                multiple
                id="tag-input"
                size="small"
                style={{ minWidth: "12em" }}
                options={options}
                value={value}
                disableClearable
                ChipProps={props.disabled ? disabledProps : defaultProps}
                disabled={props.disabled}
                selectOnFocus
                classes={{
                    listbox: classes.listbox,
                }}
                handleHomeEndKeys
                freeSolo
                filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    return filtered
                }}
                renderOption={(option) => option.title || option}
                getOptionLabel={(option) =>
                    typeof option === "string"
                        ? option
                        : option.inputValue
                        ? option.inputValue
                        : option.title
                }
                onChange={(event: any, newValue: any, reason: any) => {
                    if (!props.concept) {
                        props.setTags(newValue)
                        setOptions(
                            newValue ? [...newValue, ...options] : options
                        )
                        setValue(newValue)
                    } else if (
                        reason === "create-option" ||
                        reason === "select-option"
                    ) {
                        if (
                            newValue[newValue.length - 1]?.inputValue ===
                            inputValue
                        ) {
                            addTag({
                                variables: {
                                    conceptId: props.concept?.id,
                                    tagName:
                                        newValue[newValue.length - 1]
                                            .inputValue,
                                },
                            })
                        } else if (!!newValue[newValue.length - 1]) {
                            addTag({
                                variables: {
                                    conceptId: props.concept?.id,
                                    tagName: newValue[newValue.length - 1],
                                },
                            })
                        }
                    } else if (reason === "remove-option") {
                        const removedTag = getRemovedValue(value, newValue)
                        setValue(newValue)
                        removeTag({
                            variables: {
                                conceptId: props.concept?.id,
                                tagName: removedTag,
                            },
                        })
                    } else {
                        setOptions(
                            newValue ? [...newValue, ...options] : options
                        )
                        setValue(newValue)
                    }
                }}
                onKeyDownCapture={(event) => {
                    if (event.key === " ") {
                        event.preventDefault()
                        let newArray = [...value]
                        if (inputValue.length > 0) {
                            newArray.push(inputValue)
                            setValue(newArray)
                            if (props.concept) {
                                addTag({
                                    variables: {
                                        conceptId: props.concept?.id,
                                        tagName: newArray[newArray.length - 1],
                                    },
                                })
                            } else {
                                props.setTags(newArray)
                                setOptions(
                                    newArray
                                        ? [...newArray, ...options]
                                        : options
                                )
                                setValue(newArray)
                            }
                        }
                    } else if (event.key === "#") {
                        event.preventDefault()
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Tags"
                        placeholder="Enter Tags"
                        autoFocus={!!props.autoFocus}
                        multiline
                    />
                )}
                includeInputInList={true}
            />
        </div>
    )
}

export default TagInput
