import { Box, Paper } from "@material-ui/core"
import RichTextEditor from "../../RichTextEditor"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import WidgetHeader from "./common/WidgetHeader"
import { toCapitalizedWords } from "../../../util/fns"
import { useReactiveVar } from "@apollo/client"
import { permissionsVar } from "../../../providers/GlobalState"
type WidgetConfig = {
    label?: string
    text?: string
}

const RichTextEditorWidget: WidgetTypeComponent = (props) => {
    const {
        value,
        config,
        onEditValue,
        isInitialized,
    } = useWidget<WidgetConfig>(props.widget.id)
    const text = value?.text || '[{"children":[{"text":""}]'
    const editPermission = useReactiveVar(permissionsVar)
    return (
        <Box
            component={Paper}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
        >
            <WidgetHeader
                label={config?.label ?? toCapitalizedWords(props.widget?.type)}
                widget={props.widget}
                editing={props.editing}
            />
            {isInitialized && (
                <RichTextEditor
                    editorId={
                        props.isDialog
                            ? "dialog-" + props.widget.id
                            : props.widget.id
                    }
                    isReadOnly={!editPermission}
                    onChange={(text) => {
                        onEditValue({
                            ...value,
                            text,
                        })
                    }}
                    initialValue={text}
                />
            )}
        </Box>
    )
}

export default RichTextEditorWidget
