import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import AddIcon from "@material-ui/icons/Add"
import { Loading } from "../Loading"
import {
    Divider,
    IconButton,
    Slide,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
} from "@material-ui/core"

import { Category } from "../../__generated__/types"
import { CRITERIA_FILTERED } from "../criteria/graphql"

import {
    CriteriaQuery,
    CriteriaQueryVariables,
} from "../criteria/__generated__/graphql"
import DefaultCriteriaForm from "../criteria/DefaultCriteriaForm"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            height: "100%",
            width: "100%",
        },

        row: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },
            cursor: "pointer",
        },
    })
)

interface CategoryCriteriaListProps {
    category: Category
}

const CategoryCriteriaList = (props: CategoryCriteriaListProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const { data, loading, refetch } = useQuery<
        CriteriaQuery,
        CriteriaQueryVariables
    >(CRITERIA_FILTERED, {
        variables: {
            filter: {
                isDefault: true,
                category: {
                    id: props.category.id,
                },
            },
        },
    })
    const [focusedCriteria, setFocusedCriteria] = useState(null)
    const [showForm, setShowForm] = useState(false)
    if (loading) {
        return <Loading size={75} hideQuote={true} />
    }
    const defaultCriteria = data?.Criteria ?? []
    return (
        <>
            <Box display="flex" height="100%">
                <Box display="flex" flexDirection="column" width="100%" p={3}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        visibility={showForm ? "hidden" : "visible"}
                    >
                        <IconButton onClick={() => setShowForm(true)}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <Box className={classes.container}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell size="small">Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {defaultCriteria?.map((item, index) => {
                                    return (
                                        <TableRow
                                            key={item.id}
                                            style={{
                                                backgroundColor:
                                                    focusedCriteria?.id ===
                                                    item.id
                                                        ? theme.palette.action
                                                              .selected
                                                        : "transparent",
                                            }}
                                            className={classes.row}
                                            onClick={() =>
                                                setFocusedCriteria(item)
                                            }
                                        >
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell size="small">
                                                {item.criteriaType}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                </Box>

                <Slide
                    in={!!showForm || !!focusedCriteria}
                    unmountOnExit
                    mountOnEnter
                    direction="left"
                >
                    <Box display="flex" width="100%" height="100%">
                        <Divider orientation="vertical" />
                        <Box width="100%" height="100%">
                            <Box p={2} pb={0}>
                                {!!focusedCriteria
                                    ? "Criteria Editor"
                                    : "New Criteria Form"}
                            </Box>
                            <Box display="flex" p={2}>
                                <DefaultCriteriaForm
                                    onClose={() => {
                                        setShowForm(false)
                                        setFocusedCriteria(null)
                                    }}
                                    onSave={async () => {
                                        await refetch()
                                        setShowForm(false)
                                        setFocusedCriteria(null)
                                    }}
                                    category={props.category}
                                    criteria={focusedCriteria}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Slide>
            </Box>
        </>
    )
}

export default CategoryCriteriaList
