import React, { ReactElement, useEffect, useState, useCallback } from "react"
import { useAuth } from "../../providers/AuthProvider"
import { useMutation, useQuery } from "@apollo/client"
import {
    Theme,
    Box,
    useMediaQuery,
    IconButton,
    Avatar,
    Divider,
    InputAdornment,
    Grow,
    Badge,
    Popper,
    Paper,
    ClickAwayListener,
    TextField,
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import SortableRecursiveList from "../SortableRecursiveList"
import { Autocomplete } from "@material-ui/lab"
import {
    ADD_CONCEPT_TO_LIST,
    CREATE_USER_WORKSPACE,
    GET_CONCEPT_LIST,
} from "../../graphql/conceptListQueries"
import useFilters from "../filters/util/useFilters"
import {
    CreateUserWorkspaceMutation,
    CreateUserWorkspaceMutationVariables,
    AddConceptInListsMutation,
    AddConceptInListsMutationVariables,
    GetConceptListCompleteQuery,
    GetConceptListCompleteQueryVariables,
} from "../../graphql/__generated__/conceptListQueries"
import LinkConcept from "../LinkConcept"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { ConceptList } from "../../__generated__/types"
import { WORKSPACE_QUERY } from "./graphql"
import {
    UserWorkspacesQuery,
    UserWorkspacesQueryVariables,
} from "./__generated__/graphql"
import AddBoxIcon from "@material-ui/icons/AddBox"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import Filters from "../filters/Filters"
import { getFilterObject } from "../filters/util/getFilterObject"
import { FilterItem } from "../filters/util/types"
import { ReactComponent as GlobalFilterIcon } from "../../styles/FilterIcon.svg"
import SearchIcon from "@material-ui/icons/Search"
import { getNumFiltersEnabled } from "../filters/util/getNumFiltersEnabled"
import EditIcon from "@material-ui/icons/Edit"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
    },
    listContainer: {
        overflowX: "hidden",
        overflowY: "auto",
        transition: "background-color 0.2s ease",
        flexGrow: 1,
        minHeight: 100,
        paddingLeft: theme.spacing(0.5),
        margin: theme.spacing(1.5, 0),
    },
    actionHover: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover + " !important",
        },
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(1),
        padding: theme.spacing(0.5),
        width: "100%",
        paddingBottom: 0,
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
        cursor: "pointer",
    },
    popper: {
        zIndex: theme.zIndex.drawer + 1,
        width: "25em",
    },
    mobilePopper: {
        width: "100%",
        zIndex: theme.zIndex.modal + 1,
    },
    header: {
        color: theme.palette.text.hint,
    },
}))

interface WorkspaceProps {
    closeDrawer?: () => void
}
type Option = {
    type: null | string
    title: string
    fallback: string
    id: string
    imageUrl: null | string
    route: string
}
export default function Workspace(props: WorkspaceProps): ReactElement {
    const classes = useStyles()
    const history = useHistory()
    const { defaultValue, isInitialized, defaultOptions } = useFilters()
    const { t } = useAwaitTranslation("workspace")
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const open = Boolean(anchorEl)
    const [searchString, setSearchString] = useState("")
    const [listId, setListId] = useState(null)
    const [filters, setFilters] = useState<FilterItem[]>([])
    useEffect(() => {
        if (!!isInitialized) {
            setFilters(defaultValue)
        }
    }, [isInitialized, defaultValue])
    const { currentUser } = useAuth()
    const [sortable, setSortable] = useState(false)
    const [add, setAdd] = useState(false)
    const [edit, setEdit] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const theme = useTheme()

    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    let filterObject = {
        ...getFilterObject(filters),
    }
    if (searchString.length > 0) {
        filterObject["title_contains"] = searchString
    }
    const [createWorkspace] = useMutation<
        CreateUserWorkspaceMutation,
        CreateUserWorkspaceMutationVariables
    >(CREATE_USER_WORKSPACE)
    const [addConcept] = useMutation<
        AddConceptInListsMutation,
        AddConceptInListsMutationVariables
    >(ADD_CONCEPT_TO_LIST)
    const { data: listData } = useQuery<
        GetConceptListCompleteQuery,
        GetConceptListCompleteQueryVariables
    >(GET_CONCEPT_LIST, {
        variables: {
            id: listId,
            conceptsFilter: filterObject,
        },
        skip: !listId,
        fetchPolicy: "cache-and-network",
    })
    const { data: userData, loading: loadingUserData } = useQuery<
        UserWorkspacesQuery,
        UserWorkspacesQueryVariables
    >(WORKSPACE_QUERY, {
        variables: {
            userId: currentUser?.userId,
        },
        skip: !currentUser,
    })
    const user = userData?.User[0]
    const createNewWorkspace = useCallback(async () => {
        let result = await createWorkspace({
            variables: {
                userId: currentUser?.userId,
            },
        })
        setListId(result.data.CreateUserWorkspace.id)
    }, [createWorkspace, currentUser])

    useEffect(() => {
        if (user) {
            const workspaceList = user.conceptLists?.[0]
            if (!listId && !workspaceList) {
                createNewWorkspace()
            } else if (!listId) {
                setListId(workspaceList.id)
            }
        }
    }, [user, createWorkspace, createNewWorkspace, listId])

    const handleConceptAddition = (concept) => {
        addConcept({
            variables: {
                from: { id: concept.id },
                to: { id: conceptList.id },
            },
        })
        setAdd(false)
    }

    if (!!!currentUser) {
        return null
    }
    const conceptList: ConceptList = listData?.ConceptList[0]
    let options: Option[] = [
        {
            type: "My Workspace",
            fallback: user?.firstName?.[0] + user?.lastName?.[0],
            title: user?.conceptLists?.[0]?.title,
            id: user?.conceptLists?.[0]?.id,
            imageUrl: user?.imageUrl,
            route: `/user/${user?.username}/home`,
        },
    ]
    if (!!user && !!user.teams) {
        options = [
            ...options,
            ...user?.teams?.map((team) => {
                return {
                    type: "Team Workspaces",
                    fallback: team.name?.[0],
                    title: team.conceptLists?.[0]?.title,
                    id: team.conceptLists?.[0]?.id,
                    imageUrl: team.imageUrl,
                    route: `/team/${team.teamId}/home`,
                }
            }),
        ]
    }

    const value = options.filter((option) => option.id === listId)?.[0]
    const filtersEnabled = getNumFiltersEnabled(filters) ?? 0
    return (
        !loadingUserData &&
        !!listId && (
            <div className={classes.root}>
                <Box p={1} pb={0.5} display="flex" alignItems="center">
                    <Avatar
                        variant="rounded"
                        src={value?.imageUrl}
                        className={classes.avatar}
                        onClick={() => {
                            history.push(value.route)
                            if (mobile) {
                                props.closeDrawer()
                            }
                        }}
                    >
                        {value?.fallback}
                    </Avatar>

                    <Box className={classes.actionHover}>
                        <Autocomplete
                            options={options}
                            size="small"
                            fullWidth
                            openOnFocus
                            value={value ?? null}
                            onChange={(
                                _: React.ChangeEvent<{}>,
                                newValue: Option
                            ) => {
                                setListId(newValue.id)
                            }}
                            disableClearable
                            groupBy={(option: Option) => option.type}
                            getOptionLabel={(option: Option) => option.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    }}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (edit) {
                                    setEdit(false)
                                    setShowSearch(false)
                                    setAdd(false)
                                } else {
                                    setEdit(true)
                                }
                            }}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
                <Grow in={edit} mountOnEnter unmountOnExit>
                    <Box>
                        <Divider />
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <IconButton onClick={() => setSortable(!sortable)}>
                                {sortable ? <LockOpenIcon /> : <LockIcon />}
                            </IconButton>
                            <Box display="flex" alignItems="center">
                                <IconButton
                                    onClick={() => {
                                        setShowSearch(!showSearch)
                                        if (add) {
                                            setAdd(false)
                                        }
                                    }}
                                >
                                    <SearchIcon
                                        color={
                                            showSearch ? "secondary" : "inherit"
                                        }
                                    />
                                </IconButton>
                                <IconButton onClick={handleFilterClick}>
                                    <Badge
                                        color="secondary"
                                        badgeContent={filtersEnabled}
                                        overlap="circle"
                                    >
                                        <GlobalFilterIcon
                                            style={{
                                                width: "0.9em",
                                                height: "0.9em",
                                                fill: "currentColor",
                                            }}
                                        />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        setAdd(!add)
                                        if (showSearch) {
                                            setShowSearch(false)
                                            setSearchString("")
                                        }
                                    }}
                                >
                                    <AddBoxIcon
                                        color={add ? "secondary" : "inherit"}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Grow>
                <Grow in={add || showSearch} mountOnEnter unmountOnExit>
                    <Box width="100%">
                        <Divider />
                        <Box p={2}>
                            {add ? (
                                <LinkConcept
                                    linkFunction={handleConceptAddition}
                                    concepts={conceptList?.concepts}
                                    placeholder="Type to add concepts..."
                                    inputAdornment={
                                        <AddBoxIcon fontSize="small" />
                                    }
                                    autoFocus
                                />
                            ) : (
                                <TextField
                                    value={searchString}
                                    onChange={(e) => {
                                        setSearchString(e.target.value)
                                    }}
                                    autoFocus
                                    fullWidth
                                    placeholder="Type to search workspace..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Grow>

                <Divider />
                {conceptList && (
                    <Box className={classes.listContainer}>
                        <SortableRecursiveList
                            closeDrawer={props.closeDrawer}
                            list={conceptList}
                            editable={sortable}
                            conceptFilter={filterObject}
                        />
                    </Box>
                )}

                <Popper
                    className={mobile ? classes.mobilePopper : classes.popper}
                    open={open}
                    anchorEl={anchorEl}
                >
                    {({ TransitionProps }) => (
                        <ClickAwayListener
                            onClickAway={() => setAnchorEl(null)}
                        >
                            <Paper elevation={6}>
                                <Filters
                                    setFilters={(filters: FilterItem[]) =>
                                        setFilters(filters)
                                    }
                                    filters={filters}
                                    filterOptions={defaultOptions}
                                />
                            </Paper>
                        </ClickAwayListener>
                    )}
                </Popper>
            </div>
        )
    )
}
