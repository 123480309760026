import React, { useState, useEffect, Suspense } from "react"
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Login from "./pages/login"
import signup from "./pages/signup"
import Workspace from "./components/workspace/Workspace"
import Toolkit from "./components/Toolkit"
import ConceptFeed from "./components/ConceptFeed"
import AppHeader from "./components/AppHeader"
import { ThemeProvider } from "./components/themes/ThemeContext"
import withStyles from "@material-ui/core/styles/withStyles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { Container, useTheme } from "@material-ui/core"
import PublicProfile from "./components/users/UserPublicProfile"
import Teams from "./components/teams/Teams"
import PrivateRoute from "./util/PrivateRoute"
import UserAccountSettings from "./components/users/UserAccountSettings"
import { AuthProvider } from "./providers/AuthProvider"
import TeamProfile from "./components/teams/TeamProfile"
import GlobalSettings from "./components/GlobalSettings"
import { useHotkeys } from "react-hotkeys-hook"

import Usersnap from "./components/Usersnap"
import { ExerciseGallery } from "./components/exercises/ExerciseGallery"
import { defaultTheme } from "./styles/defaultTheme"
import { Loading } from "./components/Loading"
import ConceptDetails from "./components/ConceptDetails"
import AddConceptDrawer from "./components/AddConceptDrawer"
import { globalFiltersVar } from "./providers/GlobalState"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { GlobalCss } from "./styles/globalStyles"
import NavDrawer from "./components/NavDrawer"
import useFilters from "./components/filters/util/useFilters"
const drawerWidth = 240

const styles = (theme) => ({
    root: {
        display: "flex",
        minHeight: "100%",
    },
    appBar: {
        // zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        borderRightColor: theme.palette.divider,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    avatar: {
        height: 110,
        width: 100,
        flexShrink: 0,
        flexGrow: 0,
        marginTop: 20,
    },
    uiProgess: {
        position: "fixed",
        zIndex: "1000",
        height: "31px",
        width: "31px",
        left: "45%",
        top: "35%",
    },
    MuiFab: {
        position: "absolute",
        right: "1rem",
        bottom: "1rem",
    },
    toolbar: {
        minHeight: 64,
    },
    MainContainer: {
        minHeight: "calc(100vh - 64px)",
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            padding: 0,
        },
        flexGrow: 1,
        width: 0,
        margin: "0 auto",
        padding: 0,
    },
})
dayjs.extend(relativeTime)
const App = (props) => {
    const muiTheme = useTheme()
    const mobile = useMediaQuery(muiTheme.breakpoints.down("sm"))
    const prefersDarkMode = false
    const [theme, setTheme] = useState()
    const [drawerOpen, toggleDrawer] = useState(mobile ? false : true)
    const [navDrawerOpen, setNavDrawerOpen] = useState(true)
    const [conceptFormOpen, setConceptFormOpen] = useState(false)
    const { defaultValue, isInitialized } = useFilters()
    const { classes } = props
    useHotkeys("a,i", (ev) => {
        ev.preventDefault()
        setConceptFormOpen((conceptFormOpen) => !conceptFormOpen)
    })
    useHotkeys(
        "command+o,ctrl+o",
        (ev) => {
            ev.preventDefault()
            setConceptFormOpen((conceptFormOpen) => !conceptFormOpen)
        },
        {
            enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
        }
    )
    useHotkeys("command+b,ctrl+b", (ev) => {
        ev.preventDefault()
        if (window["Usersnap"]) {
            window["Usersnap"].open()
        }
    })
    const toggleIdeaForm = (props) => {
        if (props?.open || props === true) {
            setConceptFormOpen(true)
        } else if (props?.close) {
            setConceptFormOpen(false)
        } else {
            setConceptFormOpen(!conceptFormOpen)
        }
    }
    const defaultInitialTheme = React.useMemo(() => {
        const themeType = prefersDarkMode ? "dark" : "light"
        return createMuiTheme({
            palette: {
                primary: {
                    main: defaultTheme[themeType.toUpperCase()].primary,
                },
                secondary: {
                    main: defaultTheme[themeType.toUpperCase()].secondary,
                },
                type: themeType,
            },
        })
    }, [prefersDarkMode])

    useEffect(() => {
        if (mobile) {
            setNavDrawerOpen(false)
        }
    }, [mobile])
    useEffect(() => {
        if (!!isInitialized) {
            console.log("RUNNING")
            globalFiltersVar(defaultValue)
        }
    }, [defaultValue, isInitialized])
    return (
        <HelmetProvider>
            <Suspense fallback={<Loading />}>
                <Router>
                    <AuthProvider>
                        <ThemeProvider
                            prefersDarkMode={prefersDarkMode}
                            theme={defaultInitialTheme}
                        >
                            <Usersnap />
                            <div className={classes.root}>
                                <CssBaseline />
                                <GlobalCss />
                                <AppHeader
                                    toggleMenu={() => toggleDrawer(!drawerOpen)}
                                    showFormDrawer={() =>
                                        setConceptFormOpen(true)
                                    }
                                    showWorkspaceDrawer={() =>
                                        setNavDrawerOpen(true)
                                    }
                                    toggleWorkspaceDrawer={() =>
                                        setNavDrawerOpen(!navDrawerOpen)
                                    }
                                />

                                <NavDrawer
                                    toggleDrawer={setNavDrawerOpen}
                                    drawerOpen={navDrawerOpen}
                                />
                                <Container
                                    maxWidth={false}
                                    className={classes.MainContainer}
                                >
                                    <div className={classes.toolbar} />
                                    <Suspense fallback={<Loading />}>
                                        <Switch>
                                            <PrivateRoute
                                                exact
                                                path="/feed"
                                                component={ConceptFeed}
                                            />

                                            <PrivateRoute
                                                exact
                                                path="/workspace"
                                                component={Workspace}
                                            />
                                            <PrivateRoute
                                                exact
                                                path="/toolkit"
                                                component={Toolkit}
                                            />
                                            <PrivateRoute
                                                exact
                                                path="/teams"
                                                component={Teams}
                                            />
                                            <PrivateRoute
                                                exact
                                                path="/team/:teamId/:dashboardId"
                                                component={TeamProfile}
                                            />
                                            <PrivateRoute
                                                exact
                                                path="/exercises"
                                                component={ExerciseGallery}
                                            />
                                            <PrivateRoute
                                                exact
                                                path="/concept/:conceptId/:dashboardId"
                                                component={ConceptDetails}
                                            />
                                            <PrivateRoute
                                                exact
                                                path="/user/:username/:dashboardId"
                                                component={PublicProfile}
                                            />
                                            <PrivateRoute
                                                exact
                                                path="/myaccount"
                                                component={UserAccountSettings}
                                            />
                                            <PrivateRoute
                                                exact
                                                path="/settings"
                                                component={GlobalSettings}
                                            />
                                            <Route
                                                path="/login"
                                                component={Login}
                                            />
                                            <Route
                                                path="/signup"
                                                component={signup}
                                            />
                                            <Route
                                                path="/"
                                                render={() => (
                                                    <Redirect to="/feed" />
                                                )}
                                            />
                                        </Switch>
                                    </Suspense>
                                </Container>

                                <AddConceptDrawer
                                    closeForm={() => setConceptFormOpen(false)}
                                    formOpen={conceptFormOpen}
                                />
                            </div>
                        </ThemeProvider>
                    </AuthProvider>
                </Router>
            </Suspense>
        </HelmetProvider>
    )
}

export default withStyles(styles)(App)
