import React, { ReactElement } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { TextField } from "@material-ui/core"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { gql, useMutation } from "@apollo/client"
import { Link } from "../__generated__/types"

const ADD_LINK = gql`
    mutation addLink($url: String) {
        addLink(url: $url) {
            id
            url
            title
            description
            image
            icon
            keywords
            language
            type
            provider
        }
    }
`
const DELETE_LINK = gql`
    mutation DeleteLink($id: ID!) {
        DeleteLink(id: $id) {
            id
            url
            title
            description
            image
            icon
            keywords
            language
            type
            provider
        }
    }
`
const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
    },
}))

interface Props {
    handleLink: (link: Link) => void
    setLoading: (loading: boolean) => void
    autoFocus?: boolean
}

export default function FetchLink({
    handleLink,
    setLoading,

    autoFocus,
}: Props): ReactElement {
    const [addLink] = useMutation(ADD_LINK)
    const handleInput = async (ev) => {
        if (ev.target.value.length > 0) {
            setLoading(true)
            const url = ev.target.value
            addLink({ variables: { url } })
                .then((res) => {
                    const linkItem = res.data?.addLink

                    if (linkItem) {
                        handleLink(linkItem)
                    }
                })
                .catch((e) => console.log(e))
        } else {
            setLoading(false)
        }
    }
    const { t } = useAwaitTranslation("fetchLink")
    return (
        <TextField
            variant="outlined"
            fullWidth
            id="link"
            autoFocus={!!autoFocus ?? false}
            name="link"
            label={t("webLink", "Web Link (URL)")}
            onChange={handleInput}
        />
    )
}
