import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Loading } from "../Loading"
import {
    Paper,
    Divider,
    ListItem,
    ListItemText,
    ListItemIcon,
} from "@material-ui/core"
import { ROOT_CATEGORY_QUERY } from "./graphql"
import {
    RootCategoriesQuery,
    RootCategoriesQueryVariables,
} from "./__generated__/graphql"
import { Category } from "../../__generated__/types"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import CategoryForm from "./CategoryForm"
import { getConceptTypeIcon } from "../../util/ConceptTypeStyles"
import CategoryManagerModal from "./CategoryManagerModal"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            padding: theme.spacing(1, 3),
            height: "100%",
        },
        treeContainer: {
            flexGrow: 1,
            overflowY: "auto",
            padding: theme.spacing(0, 1),
            width: "100%",
            margin: theme.spacing(1),
        },
        treeItem: {
            backgroundColor: "transparent !important",
            padding: 0,
        },
        iconContainer: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover + " !important",
            },

            borderRadius: theme.shape.borderRadius,
            display: "flex",
            alignItems: "center",
            margin: 0,
            width: "18px",
        },
        inactiveIconContainer: {
            cursor: "default",
            margin: 0,
            width: "18px",
        },
        content: {
            alignItems: "stretch",
        },

        paper: {
            display: "flex",
            overflow: " hidden",
            height: "100%",
        },
    })
)

export default function CategoryManager() {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t } = useAwaitTranslation("categories")
    const { data: categoryData, error, loading } = useQuery<
        RootCategoriesQuery,
        RootCategoriesQueryVariables
    >(ROOT_CATEGORY_QUERY)
    const [focusedCategory, setFocusedCategory] = useState(null)
    const [open, setOpen] = useState(false)
    if (error) return <Box>There has been an error</Box>
    if (loading) return <Loading size={150} />

    const roots = categoryData?.Category ?? []
    return (
        <Box className={classes.root}>
            <Box
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
            >
                <Button
                    key="add-cat"
                    onClick={() => {
                        setOpen(true)
                    }}
                    variant="contained"
                    color="primary"
                    style={{ whiteSpace: "nowrap" }}
                >
                    {t("addCategory", "Add Category")}
                </Button>
            </Box>
            <Paper className={classes.paper} variant="outlined">
                <Box className={classes.treeContainer}>
                    {roots?.map((item, idx) => {
                        return (
                            <TreeView
                                key={idx}
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                            >
                                <TreeItemContainer
                                    node={item}
                                    value={focusedCategory}
                                    setValue={(value: Category) =>
                                        setFocusedCategory(value)
                                    }
                                />
                            </TreeView>
                        )
                    })}
                </Box>
            </Paper>
            {open && (
                <CategoryForm
                    onClose={() => {
                        setOpen(false)
                    }}
                />
            )}
        </Box>
    )
}

interface TreeItemContainerProps {
    node: Category
    setValue: (value: Category) => void
    value: Category | null
}
const TreeItemContainer = (props: TreeItemContainerProps) => {
    const { node, setValue, value } = props
    const classes = useStyles()
    const [focused, setFocused] = useState(false)
    const TypeIcon = getConceptTypeIcon(node.name) ?? ""
    return (
        <Box key={node.id}>
            <TreeItem
                classes={{
                    label: classes.treeItem,
                    iconContainer:
                        node.children?.length > 0
                            ? classes.iconContainer
                            : classes.inactiveIconContainer,
                    content: classes.content,
                }}
                key={node.id}
                nodeId={node.id}
                onLabelClick={(e) => {
                    e.preventDefault()
                    setFocused(true)
                }}
                label={
                    <>
                        <ListItem button selected={node.id === value?.id}>
                            {node.isRoot && (
                                <ListItemIcon>
                                    <TypeIcon />
                                </ListItemIcon>
                            )}
                            <ListItemText
                                primary={node.name}
                                secondary={node.description}
                            />
                        </ListItem>

                        <Divider />
                    </>
                }
            >
                {Array.isArray(node.children)
                    ? node.children.map((node) => (
                          <TreeItemContainer
                              node={node}
                              key={node.id}
                              setValue={setValue}
                              value={value}
                          />
                      ))
                    : null}
            </TreeItem>
            {focused && (
                <CategoryManagerModal
                    category={node}
                    onClose={() => {
                        setFocused(false)
                    }}
                />
            )}
        </Box>
    )
}
