import React, { useState, useCallback, useEffect } from "react"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
    fade,
} from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Divider from "@material-ui/core/Divider"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import { getConceptTypeIcon } from "../util/ConceptTypeStyles"

import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TextField from "@material-ui/core/TextField"
import dayjs from "dayjs"
import LinkIcon from "@material-ui/icons/Link"
import {
    Box,
    Dialog,
    Typography,
    Grow,
    IconButton,
    Link,
    Tooltip,
    useMediaQuery,
    ListItemSecondaryAction,
    InputAdornment,
    Popper,
    Paper,
    ClickAwayListener,
} from "@material-ui/core"
import Filters from "./filters/Filters"
import { FilterItem } from "./filters/util/types"
import { useQuery } from "@apollo/client"
import InfiniteScroll from "react-infinite-scroller"
import CircularProgress from "@material-ui/core/CircularProgress"
import SearchIcon from "@material-ui/icons/Search"
import { CONNECTION_QUERY, CONCEPT_BY_ID } from "../graphql/queries"
import { Loading } from "./Loading"
import {
    ConceptConnectionsQuery,
    ConceptConnectionsQueryVariables,
} from "../graphql/__generated__/queries"
import { Concept } from "../__generated__/types"
import ClearIcon from "@material-ui/icons/Clear"
import RichTextEditor from "./RichTextEditor"
import { Link as LinkRouter } from "react-router-dom"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import LikeToggle from "./liking/LikeToggle"
import AlertSubscriptionToggle from "./alert-subscriptions/AlertSubscriptionToggle"
import WorkspaceToggle from "./workspace/WorkspaceToggle"
import LinkConcept from "./LinkConcept"
import { useMutation } from "@apollo/client"
import { DISCONNECT_CONCEPTS, CONNECT_CONCEPTS } from "../graphql/mutations"
import {
    AddConceptConnectionsMutation,
    AddConceptConnectionsMutationVariables,
    RemoveConceptConnectionsMutation,
    RemoveConceptConnectionsMutationVariables,
} from "../graphql/__generated__/mutations"
import { getFilterObject } from "./filters/util/getFilterObject"
import { ReactComponent as GlobalFilterIcon } from "../styles/FilterIcon.svg"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Badge from "@material-ui/core/Badge"
import useFilters from "./filters/util/useFilters"
import CloseIcon from "@material-ui/icons/Close"
import AddBoxIcon from "@material-ui/icons/AddBox"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            overflow: "auto",
        },
        inline: {
            display: "inline",
        },
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
        },
        searchContainer: {
            marginTop: theme.spacing(1),
            fontSize: "12px",
        },
        linkCount: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.secondary,
        },
        toolIcon: {
            height: "0.8em",
            width: "0.8em",
        },
        loaderContainer: {
            textAlign: "center",
        },
        greyed: {
            color: theme.palette.text.hint,
            fontSize: 12,
        },
        focusedConcept: {
            backgroundColor: fade(theme.palette.primary.main, 0.08),
        },
        previewAvatar: {
            [theme.breakpoints.down("sm")]: {
                minWidth: "100px",
                minHeight: "100px",
            },
            minWidth: "125px",
            minHeight: "125px",
        },
        avatar: {
            height: theme.spacing(3),
            width: theme.spacing(3),
            margin: theme.spacing(0, 0.75),
            marginLeft: 0,
        },
        createdBy: {
            fontSize: theme.typography.body2.fontSize,
            [theme.breakpoints.down("sm")]: {
                fontSize: theme.typography.caption.fontSize,
            },
        },
        title: {
            fontSize: theme.typography.body1.fontSize,
            [theme.breakpoints.down("sm")]: {
                fontSize: theme.typography.body2.fontSize,
            },
            margin: theme.spacing(0.25, 0),
        },
        subText: {
            fontSize: theme.typography.body2.fontSize,
            [theme.breakpoints.down("sm")]: {
                fontSize: theme.typography.caption.fontSize,
            },
            color: theme.palette.text.hint,
        },
        modalTitle: {
            fontSize: theme.typography.h6.fontSize,
            padding: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            [theme.breakpoints.down("sm")]: {
                fontSize: theme.typography.body1.fontSize,
            },
        },
        toggleButtonGroup: {
            [theme.breakpoints.down("sm")]: {
                display: "flex",
                "& > button": {
                    flexGrow: 1,
                },
            },
        },
        popper: {
            zIndex: 1500,
            width: "30em",
        },
        mobilePopper: {
            width: "100%",
            right: 0,
            left: 0,
            zIndex: 1500,
        },
    })
)

interface DetailedConnectionPreviewDialogProps {
    item: Concept
    editPermission: boolean
    onClose: () => void
}

export default function DetailedConnectionPreviewDialog(
    props: DetailedConnectionPreviewDialogProps
) {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const { defaultValue, isInitialized, defaultOptions } = useFilters()
    const [filters, setFilters] = useState<FilterItem[]>([])
    useEffect(() => {
        if (!!isInitialized) {
            setFilters(defaultValue)
        }
    }, [defaultValue, isInitialized])
    const open = Boolean(anchorEl)
    const [searchString, setSearchString] = useState("")
    const [focusedId, setFocusedId] = useState(null)
    const [add, setAdd] = useState(true)
    const [search, setSearch] = useState(false)
    const [loadMore, setLoadMore] = useState(true)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const [limit, setLimit] = useState(15)

    let filterObject = {
        connections_some: { id: props.item?.id },
        ...getFilterObject(filters),
        isPublic: true,
    }
    if (searchString.length > 0) {
        filterObject["title_contains"] = searchString
    }
    const { loading, error, data, fetchMore, refetch } = useQuery<
        ConceptConnectionsQuery,
        ConceptConnectionsQueryVariables
    >(CONNECTION_QUERY, {
        variables: {
            offset: 0,
            first: limit,
            connectionsFilter: filterObject,
        },
    })
    const [connectConcepts] = useMutation<
        AddConceptConnectionsMutation,
        AddConceptConnectionsMutationVariables
    >(CONNECT_CONCEPTS, {
        refetchQueries: [
            {
                query: CONNECTION_QUERY,
                variables: {
                    offset: 0,
                    first: limit,
                    connectionsFilter: filterObject,
                },
            },
        ],
    })
    const [disconnectConcepts] = useMutation<
        RemoveConceptConnectionsMutation,
        RemoveConceptConnectionsMutationVariables
    >(DISCONNECT_CONCEPTS)
    const handleConceptAddition = (concept) => {
        connectConcepts({
            variables: {
                fromId: props.item.id,
                toId: concept.id,
            },
        })
    }
    const handleRemoval = async (conceptId) => {
        await disconnectConcepts({
            variables: {
                fromId: props.item.id,
                toId: conceptId,
            },
        })
        refetch()
    }
    const onLoadMore = useCallback(
        async (page) => {
            if (!fetchMore) return
            const result = await fetchMore({
                variables: {
                    offset: page * 15,
                },
            })
            setLimit(page * 15 + 15)
            setLoadMore(!(result.data.Concept.length < page * 15))
        },
        [fetchMore]
    )

    const listData = data?.Concept ?? []
    const TypeIcon = getConceptTypeIcon(props.item.rootCategory?.name)
    const numFiltersEnabled = () =>
        filters.filter((x) => {
            return x.value && x.value.length > 0
        }).length
    return (
        <Dialog
            key={props.item.id}
            open={true}
            fullWidth={!mobile}
            fullScreen={mobile}
            maxWidth={focusedId ? "lg" : "sm"}
            onBackdropClick={() => props.onClose()}
        >
            <Box
                minHeight={mobile ? "auto" : "80vh"}
                overflow="hidden"
                display="flex"
                flexDirection="column"
                height="100%"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box className={classes.modalTitle}>
                        <Box
                            display="flex"
                            component="span"
                            alignItems="center"
                            mr={1}
                        >
                            <TypeIcon />
                        </Box>
                        <Box
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                        >
                            <Link
                                color="inherit"
                                to={`/concept/${props.item.id}/home`}
                                component={LinkRouter}
                            >
                                {props.item.title}
                            </Link>
                        </Box>
                        <ChevronRightIcon
                            style={{ margin: theme.spacing(0.5) }}
                        />
                        <Box width="fit-content" whiteSpace="nowrap">
                            Connections
                        </Box>
                    </Box>
                    <Box>
                        <IconButton onClick={() => props.onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <Box display="flex" overflow="hidden" flexGrow={1}>
                    {(!mobile || (mobile && !focusedId)) && (
                        <Box display="flex" flexDirection="column" width="100%">
                            <Box display="flex" justifyContent="flex-start">
                                <Tooltip title="Search connections">
                                    <IconButton
                                        onClick={() => {
                                            setSearch(!search)
                                            if (add) {
                                                setAdd(false)
                                            }
                                        }}
                                    >
                                        <SearchIcon
                                            color={
                                                search ? "secondary" : "inherit"
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Filter connections">
                                    <IconButton onClick={handleClick}>
                                        <Badge
                                            badgeContent={numFiltersEnabled()}
                                            color="secondary"
                                            overlap="rectangle"
                                        >
                                            <GlobalFilterIcon
                                                style={{
                                                    width: "0.9em",
                                                    height: "0.9em",
                                                    fill: "currentColor",
                                                }}
                                            />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Add connections">
                                    <IconButton
                                        onClick={() => {
                                            setAdd(!add)
                                            if (search) {
                                                setSearch(false)
                                                setSearchString("")
                                            }
                                        }}
                                    >
                                        <AddBoxIcon
                                            color={
                                                add ? "secondary" : "inherit"
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box>
                                <Grow
                                    in={add || search}
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    <Box>
                                        <Divider />
                                        {add ? (
                                            <Box
                                                p={2}
                                                width="100%"
                                                alignSelf="center"
                                            >
                                                <LinkConcept
                                                    item={props.item}
                                                    concepts={
                                                        props.item.connections
                                                    }
                                                    filterOut={[
                                                        "Requirement",
                                                        "Feature",
                                                    ]}
                                                    linkFunction={
                                                        handleConceptAddition
                                                    }
                                                    autoFocus={true}
                                                />
                                            </Box>
                                        ) : (
                                            <Box
                                                p={2}
                                                width="100%"
                                                alignSelf="center"
                                            >
                                                <TextField
                                                    value={searchString}
                                                    fullWidth
                                                    autoFocus
                                                    onChange={(e) => {
                                                        setLoadMore(true)
                                                        setLimit(15)
                                                        setSearchString(
                                                            e.target.value
                                                        )
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: searchString.length >
                                                            0 && (
                                                            <InputAdornment position="start">
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => {
                                                                        setLoadMore(
                                                                            true
                                                                        )
                                                                        setLimit(
                                                                            15
                                                                        )
                                                                        setSearchString(
                                                                            ""
                                                                        )
                                                                    }}
                                                                >
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    placeholder="Search connection by title..."
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Grow>
                            </Box>
                            <Divider />
                            {loading ? (
                                <Loading
                                    size={75}
                                    hideQuote={true}
                                    padding={3}
                                />
                            ) : (
                                <List className={classes.root} disablePadding>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={onLoadMore}
                                        hasMore={loadMore}
                                        loader={
                                            <div
                                                key={0}
                                                className={
                                                    classes.loaderContainer
                                                }
                                            >
                                                <CircularProgress
                                                    disableShrink
                                                    size={40}
                                                />
                                            </div>
                                        }
                                        useWindow={false}
                                    >
                                        {listData.map((item, idx) => {
                                            return (
                                                <Box key={item.id}>
                                                    <ConnectionItem
                                                        connectedItem={item}
                                                        setFocused={() =>
                                                            setFocusedId(
                                                                item.id
                                                            )
                                                        }
                                                        focused={
                                                            item.id ===
                                                            focusedId
                                                        }
                                                        editable={true}
                                                    />
                                                </Box>
                                            )
                                        })}
                                    </InfiniteScroll>
                                </List>
                            )}
                        </Box>
                    )}
                    <Divider orientation="vertical" flexItem />
                    {!!focusedId && (
                        <FocusedConnection
                            id={focusedId}
                            onClose={() => setFocusedId(null)}
                            removeConnection={() => {
                                handleRemoval(focusedId)
                                setFocusedId(null)
                            }}
                        />
                    )}
                </Box>
            </Box>
            <Divider />

            <Popper
                className={mobile ? classes.mobilePopper : classes.popper}
                open={open}
                anchorEl={anchorEl}
                disablePortal
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Paper elevation={6}>
                            <Filters
                                setFilters={(filters: FilterItem[]) =>
                                    setFilters(filters)
                                }
                                filters={filters}
                                filterOptions={defaultOptions}
                            />
                        </Paper>
                    </ClickAwayListener>
                )}
            </Popper>
        </Dialog>
    )
}

interface FocusedConnectionProps {
    id: string
    onClose: () => void
    removeConnection: () => void
}
const FocusedConnection = (props: FocusedConnectionProps) => {
    const classes = useStyles()
    const { id, onClose } = props
    const theme = useTheme()
    const { data, loading } = useQuery(CONCEPT_BY_ID, {
        variables: {
            id: id,
        },
    })
    const item = data?.Concept[0]
    if (loading || !item) {
        return <Loading size={75} hideQuote={true} padding={3} />
    }

    const TypeIcon = getConceptTypeIcon(item?.rootCategory?.name)
    return (
        <Box width="100%" display="flex" flexDirection="column" key={item.id}>
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Tooltip title="Close">
                        <IconButton onClick={() => onClose()}>
                            <ArrowLeftIcon />
                        </IconButton>
                    </Tooltip>

                    <AlertSubscriptionToggle item={item} />
                    <WorkspaceToggle item={item} size="medium" />
                    <LikeToggle item={item} size="medium" />
                    <Tooltip title="View details page">
                        <Link
                            color="inherit"
                            to={`/concept/${item.id}/home`}
                            component={LinkRouter}
                        >
                            <IconButton>
                                <ExitToAppIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
                <Tooltip title="Remove connection">
                    <IconButton onClick={() => props.removeConnection()}>
                        <RemoveCircleIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Divider />
            <Box display="flex" p={2} pt={1}>
                <Box p={0.5} flexGrow="1" mr={1}>
                    {item.rootCategory && (
                        <Box className={classes.subText}>
                            {item.rootCategory.name}{" "}
                            {!!item.category &&
                                !item.category?.isRoot &&
                                " / " + item.category.name}
                        </Box>
                    )}
                    <Box className={classes.title}>{item.title}</Box>
                    <Box className={classes.subText}>{item.summary}</Box>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        mt={1}
                        className={classes.createdBy}
                    >
                        <Avatar
                            className={classes.avatar}
                            alt={item.createdBy?.firstName}
                            src={item.createdBy?.imageUrl}
                        />
                        <Box display="flex" flexDirection={"row"}>
                            <Link
                                style={{
                                    cursor: "pointer",
                                    marginRight: "5px",
                                }}
                                to={`/user/${item.createdBy?.username}/home`}
                                component={LinkRouter}
                            >
                                {item.createdBy?.firstName}{" "}
                                {item.createdBy?.lastName}
                            </Link>
                            <span
                                style={{
                                    fontSize: "inherit",
                                }}
                            >
                                {"shared " +
                                    dayjs(item.createdAt?.formatted).fromNow()}
                            </span>
                        </Box>
                    </Box>
                </Box>
                {item.imageUrl ? (
                    <Avatar
                        className={classes.previewAvatar}
                        variant="rounded"
                        src={item.imageUrl}
                    />
                ) : (
                    <Avatar
                        className={classes.previewAvatar}
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.text.primary,
                        }}
                        variant="rounded"
                    >
                        <TypeIcon style={{ width: "2em", height: "2em" }} />
                    </Avatar>
                )}
            </Box>

            {item.webLink && (
                <Box p={1} pl={2}>
                    <Box color={theme.palette.text.hint} fontSize={12}>
                        Source:
                    </Box>
                    <Link
                        href={item.webLink.url}
                        target="_blank"
                        rel="noopener"
                        style={{ display: "flex" }}
                    >
                        <LinkIcon style={{ marginRight: ".5em" }} />{" "}
                        {item.webLink.title}
                    </Link>
                </Box>
            )}
            <Box overflow="auto" p={1.5} flexGrow={1}>
                <RichTextEditor
                    initialValue={item.description}
                    isReadOnly={true}
                    editorId={item.id}
                />
            </Box>
        </Box>
    )
}

const ConnectionItem = (props: {
    connectedItem: Concept
    setFocused: () => void
    focused: boolean
    editable: boolean
}) => {
    const { connectedItem, focused, setFocused, editable } = props
    const theme = useTheme()
    const classes = useStyles()
    const [hovered, setHovered] = useState(false)
    const TypeIcon = getConceptTypeIcon(connectedItem.rootCategory?.name)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    return (
        <div
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <ListItem
                component={LinkRouter}
                to={`/concept/${connectedItem.id}/home`}
                button
                alignItems="flex-start"
                style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                }}
                selected={focused}
            >
                <ListItemAvatar
                    style={{
                        margin: "auto",
                    }}
                >
                    {connectedItem.imageUrl ? (
                        <Avatar
                            variant="rounded"
                            src={connectedItem.imageUrl}
                        />
                    ) : (
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.text.primary,
                            }}
                            variant="rounded"
                        >
                            <TypeIcon />
                        </Avatar>
                    )}
                </ListItemAvatar>
                <ListItemText>
                    {connectedItem.rootCategory && (
                        <Box className={classes.greyed}>
                            {connectedItem.rootCategory.name}{" "}
                            {!connectedItem.category?.isRoot &&
                                " / " + connectedItem.category.name}
                        </Box>
                    )}
                    <Box>
                        <Typography variant="body2">
                            {connectedItem.title}
                        </Typography>
                    </Box>
                    <Box className={classes.greyed}>
                        {connectedItem.summary}
                    </Box>
                </ListItemText>
                {editable && (
                    <ListItemSecondaryAction>
                        <IconButton
                            size="small"
                            style={{
                                visibility: hovered ? "visible" : "hidden",
                            }}
                            onClick={() => setFocused()}
                        >
                            <MoreVertIcon fontSize="small" />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Divider variant="inset" component="li" />
        </div>
    )
}
