import {
    Badge,
    ListItem,
    makeStyles,
    Typography,
    Box,
    Tooltip,
} from "@material-ui/core"
import React from "react"
import { Link as LinkRouter } from "react-router-dom"
import { Notification, Notification_Type } from "../../__generated__/types"
import { UserNotificationsQuery } from "./__generated__/graphql"
import dayjs from "dayjs"
const useStyles = makeStyles((theme) => ({
    paper: {
        transformOrigin: "top right",
    },
    list: {
        width: theme.spacing(50),
        maxHeight: theme.spacing(60),
        overflow: "auto",
        padding: 0,
    },
    listItem: {
        display: "flex",
        flexDirection: "column",
    },
    loading: {
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(1, 0),
    },
    divider: {
        margin: 0,
    },
    count: {
        height: "1em",
        width: "1em",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
    },
}))

function getNotificationMessage(
    notification: UserNotificationsQuery["User"][0]["notifications"][0]
) {
    let user =
        notification.actionBy?.firstName + " " + notification.actionBy?.lastName
    if (!!user) {
        switch (notification.type) {
            case Notification_Type.CONCEPT_LIKED:
                return `Liked by ${user}`
            case Notification_Type.CONCEPT_UPDATED:
                return `Updated by ${user}`
            case Notification_Type.COMMENT_ADDED:
                return `New comment from ${user}`
            case Notification_Type.COMMENT_REPLY_ADDED:
                return `New reply from ${user}`
        }
    } else {
        return null
    }
}

interface NotificationGroupItemProps {
    notificationGroup: Array<Notification>
    viewed: boolean
    onClose: () => void
}
export default function NotificationGroupItem(
    props: NotificationGroupItemProps
) {
    const classes = useStyles()

    return (
        <ListItem
            key={props.notificationGroup?.[0]?.id}
            component={LinkRouter}
            to={`/concept/${props.notificationGroup?.[0]?.concept?.id}/home`}
            button
            alignItems="flex-start"
            className={classes.listItem}
            style={{
                backdropFilter: props.viewed ? "invert(0.075)" : "none",
            }}
            onClick={() => props.onClose()}
        >
            <Box display="flex" width="100%">
                <Typography
                    color="primary"
                    component="b"
                    gutterBottom
                    variant="body2"
                    style={{
                        flexGrow: 1,
                    }}
                >
                    {props.notificationGroup?.[0]?.concept?.title}
                </Typography>
                {props.notificationGroup?.length > 1 && (
                    <Badge
                        color="primary"
                        badgeContent={props.notificationGroup?.length}
                    >
                        <span
                            style={{
                                width: "1em",
                            }}
                        />
                    </Badge>
                )}
            </Box>

            <Typography gutterBottom>
                {getNotificationMessage(props.notificationGroup?.[0])}
            </Typography>

            {props.notificationGroup?.[0].createdAt && (
                <Tooltip
                    title={new Date(
                        props.notificationGroup[0].createdAt.formatted
                    ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                    })}
                >
                    <Typography variant="caption" color="textSecondary">
                        {dayjs(
                            props.notificationGroup[0].createdAt?.formatted
                        ).fromNow()}
                    </Typography>
                </Tooltip>
            )}
        </ListItem>
    )
}
