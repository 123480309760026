import React from "react"
import { useTheme } from "@material-ui/core/styles"
import { QRCode } from "react-qrcode-logo"
import { SizeMe } from "react-sizeme"
import Dialog from "@material-ui/core/Dialog"
import Box from "@material-ui/core/Box"

interface QRToggleProps {
    onClose: () => void
}
export const QRToggle = (props: QRToggleProps) => {
    const theme = useTheme()
    return (
        <Dialog
            onClose={() => {
                props.onClose()
            }}
            open={true}
            fullWidth
        >
            <Box
                style={{
                    transform: "rotate(180deg)",
                }}
                p={1}
            >
                <SizeMe>
                    {({ size }) => (
                        <Box display="flex" justifyContent="center">
                            <QRCode
                                fgColor={theme.palette.text.hint}
                                bgColor={theme.palette.background.paper}
                                qrStyle="dots"
                                value={window.location.href}
                                size={size.width - 30}
                            />
                        </Box>
                    )}
                </SizeMe>
            </Box>
        </Dialog>
    )
}
