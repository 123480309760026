import {
    IconButton,
    Dialog,
    DialogActions,
    Button,
    DialogContent,
    Slide,
} from "@material-ui/core"
import React, { useState } from "react"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import SettingsIcon from "@material-ui/icons/Settings"
import useWidget from "../../useWidget"
import { Widget } from "../../useWidgets"
import WidgetConfigModal from "./WidgetConfigModal"
import { TransitionProps } from "@material-ui/core/transitions"
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})
type Props = {
    widget: Widget
}

export default function WidgetEditToolbar(props: Props) {
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [configOpen, setConfigOpen] = useState(false)
    const { onDeleteWidget } = useWidget(props.widget.id)

    return (
        <>
            <IconButton
                aria-label="settings widget"
                onClick={() => setConfigOpen(true)}
            >
                <SettingsIcon fontSize="small" />
            </IconButton>
            <WidgetConfigModal
                widget={props.widget}
                open={configOpen}
                onClose={() => setConfigOpen(false)}
            />
            <IconButton
                aria-label="delete widget"
                onClick={() => setConfirmOpen(true)}
            >
                <DeleteForeverIcon fontSize="small" />
            </IconButton>
            <Dialog open={confirmOpen} TransitionComponent={Transition}>
                <DialogContent>
                    Are you sure you want to delete this widget?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)}>No</Button>
                    <Button
                        onClick={() => {
                            setConfirmOpen(false)
                            onDeleteWidget()
                        }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
