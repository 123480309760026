import { useState, useEffect } from "react"

import {
    Box,
    TextField,
    InputAdornment,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    List,
    Divider,
} from "@material-ui/core"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import { useParams } from "react-router-dom"
import { useApolloClient, useQuery } from "@apollo/client"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../graphql/__generated__/queries"
import { Concept, Criteria } from "../../__generated__/types"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ClearIcon from "@material-ui/icons/Clear"
import AddBoxIcon from "@material-ui/icons/AddBox"
import { CriteriaType } from "./types"
import { CRITERIA_FILTERED } from "./graphql"
import { CriteriaQuery, CriteriaQueryVariables } from "./__generated__/graphql"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            margin: theme.spacing(1),
        },
        primary: {
            fontSize: theme.typography.body2.fontSize,
        },
        criteriaSelectorContainer: {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,

            height: "400px",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
        },
        criteriaList: {
            overflowY: "auto",
        },
        criteriaListItem: {
            "&:hover": {
                backgroundColor: theme.palette.action.hover,
            },
        },
        header: {
            fontSize: theme.typography.h6.fontSize,
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    })
)

interface CriteriaSelectorProps {
    onChange: (value: Pick<Criteria, "name" | "criteriaType">[]) => void
    value: Pick<Criteria, "name" | "criteriaType">[]
    concept?: Concept
}

type Option = {
    name?: string
    group?: string
    inputValue?: string
}
const CriteriaSelector = (props: CriteriaSelectorProps) => {
    const { conceptId } = useParams()
    const { onChange } = props
    const client = useApolloClient()
    const concept =
        props.concept ??
        client.readQuery<ConceptQuery, ConceptQueryVariables>({
            query: CONCEPT_BY_ID,
            variables: {
                id: conceptId,
            },
        })?.Concept?.[0]
    const classes = useStyles()
    const categoryIds =
        !!concept?.category && concept?.category.id !== concept.rootCategory?.id
            ? [concept?.category?.id, concept.rootCategory?.id].filter(
                  (x) => !!x
              )
            : [concept.category?.id].filter((x) => !!x)
    const primaryCategory = concept?.category ?? concept?.rootCategory ?? null
    const { data } = useQuery<CriteriaQuery, CriteriaQueryVariables>(
        CRITERIA_FILTERED,
        {
            variables: {
                filter: {
                    isDefault: true,
                    category: {
                        id_in: categoryIds,
                    },
                    criteriaType: CriteriaType.Integer,
                },
            },
            skip: categoryIds.length === 0,
        }
    )
    let options: Option[] =
        data?.Criteria?.map((item, index) => {
            return {
                name: item.name,
                group:
                    item.isDefault && !!item.category
                        ? `Default ${item.category?.name} Criteria`
                        : null,
            }
        }) ?? []
    const [val, setVal] = useState(null)
    const [initiallyMounted, setInitiallyMounted] = useState(false)
    const [inputValue, setInputValue] = useState("")
    const value = props.value?.map((item) => item.name) ?? []
    useEffect(() => {
        if (
            !!data?.Criteria &&
            !initiallyMounted &&
            props.value?.length === 0
        ) {
            setInitiallyMounted(true)
            onChange(
                data?.Criteria?.map((item) => {
                    if (item.category?.id === primaryCategory.id) {
                        return {
                            name: item.name,
                            criteriaType: CriteriaType.Integer,
                        }
                    } else {
                        return null
                    }
                })?.filter((x) => !!x) ?? []
            )
        }
    }, [
        data?.Criteria,
        primaryCategory,
        onChange,
        initiallyMounted,
        props.value?.length,
    ])
    return (
        <Box className={classes.criteriaSelectorContainer}>
            <Box p={1.5}>
                <Box className={classes.header}>Selected Criteria</Box>
                <Box width="100%">
                    <Autocomplete
                        fullWidth
                        size="small"
                        includeInputInList
                        onChange={(event, newValue: any, reason) => {
                            if (reason === "select-option") {
                                setInputValue("")
                                setVal(null)
                                onChange([
                                    ...props.value,
                                    {
                                        name:
                                            newValue.inputValue?.trim() ??
                                            newValue.name?.trim(),
                                        criteriaType: CriteriaType.Integer,
                                    },
                                ])
                            } else if (reason === "create-option") {
                                setInputValue("")
                                setVal(null)
                                onChange([
                                    ...props.value,
                                    {
                                        name: newValue?.trim(),
                                        criteriaType: CriteriaType.Integer,
                                    },
                                ])
                            }
                        }}
                        filterOptions={(options, params) => {
                            if (
                                params.inputValue !== "" &&
                                !value.includes(params.inputValue?.trim())
                            ) {
                                options.unshift({
                                    inputValue: params.inputValue,
                                    name: `Add "${params.inputValue}"`,
                                    group: null,
                                })
                            }

                            return options
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        getOptionLabel={(option) =>
                            typeof option === "string"
                                ? option
                                : option.inputValue
                                ? option.inputValue
                                : option.name
                        }
                        renderOption={(option) => option.name}
                        freeSolo
                        options={options}
                        getOptionDisabled={(option) =>
                            value.includes(option.name)
                        }
                        groupBy={(item) => item.group}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue)
                        }}
                        inputValue={inputValue}
                        value={val}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Add Criteria"
                                variant="outlined"
                                placeholder="Type to add criteria"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            <InputAdornment position="start">
                                                <AddBoxIcon fontSize="small" />
                                            </InputAdornment>
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                    endAdornment: inputValue?.length > 0 && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    setInputValue("")
                                                }
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Divider />
            <List className={classes.criteriaList} disablePadding>
                {value?.sort()?.map((item, index) => {
                    const defaultCheck =
                        options?.filter((def) => {
                            return def.name === item
                        })?.length === 0
                    return (
                        <ListItem
                            key={index}
                            className={classes.criteriaListItem}
                        >
                            <ListItemText
                                primary={item}
                                secondary={defaultCheck ? "Custom" : "Default"}
                            />
                            <ListItemSecondaryAction
                                onClick={() => {
                                    onChange(
                                        props.value?.filter(
                                            (criteria) => criteria.name !== item
                                        )
                                    )
                                }}
                            >
                                <IconButton size="small">
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}

export default CriteriaSelector
