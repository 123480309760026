import { gql } from "@apollo/client"

export const ADD_USER_COMMENT_LIKE = gql`
    mutation ADD_USER_COMMENT_LIKE($userId: ID!, $commentId: ID!) {
        AddCommentLikedBy(
            from: { userId: $userId }
            to: { commentId: $commentId }
        ) {
            from {
                userId
            }
            to {
                commentId
                likedBy {
                    userId
                }
            }
        }
    }
`

export const REMOVE_USER_COMMENT_LIKE = gql`
    mutation REMOVE_USER_COMMENT_LIKE($userId: ID!, $commentId: ID!) {
        RemoveCommentLikedBy(
            from: { userId: $userId }
            to: { commentId: $commentId }
        ) {
            from {
                userId
            }
            to {
                commentId
                likedBy {
                    userId
                }
            }
        }
    }
`

export const CREATE_COMMENT_REPLY = gql`
    mutation CreateCommentReply($parentId: ID!, $body: String, $userId: ID) {
        CreateCommentReply(parentId: $parentId, body: $body, userId: $userId) {
            body
            commentId
            createdAt {
                formatted
            }
            likedBy {
                userId
            }
            user {
                userId
                firstName
                lastName
            }
            replyTo {
                commentId
            }
        }
    }
`

export const CREATE_COMMENT = gql`
    mutation CreateComment($body: String, $userId: ID, $conceptId: ID) {
        CreateComment(body: $body, userId: $userId, conceptId: $conceptId) {
            commentId
            body
        }
    }
`

export const COMMENTS_BY_CONCEPT = gql`
    query Comment($id: ID!, $first: Int, $offset: Int) {
        Comment(
            filter: { concept: { id: $id } }
            orderBy: createdAt_asc
            offset: $offset
            first: $first
        ) {
            commentId
            body
            createdAt {
                formatted
            }
            concept {
                id
                commentCount
            }
            replyTo {
                commentId
            }
            user {
                userId
                firstName
                lastName
                imageUrl
                username
            }
            replies {
                commentId
                body
                createdAt {
                    formatted
                }
                replyTo {
                    commentId
                }
                concept {
                    id
                }
                user {
                    userId
                    firstName
                    lastName
                    imageUrl
                    username
                }
                likedBy {
                    userId
                }
                concept {
                    id
                }
            }
            likedBy {
                userId
            }
        }
    }
`
