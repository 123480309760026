import { useQuery } from "@apollo/client"
import { CircularProgress, Dialog, TextField } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { useState } from "react"
import { ALL_TEAM_QUERY } from "../teams/graphql"
import { ExerciseFragment } from "./__generated__/graphql"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    AllTeamQuery,
    AllTeamQueryVariables,
} from "../teams/__generated__/graphql"

interface ExerciseSetupTeamPickerProps {
    exercise: ExerciseFragment
    selectedTeamIds: Array<string>
    onDone: (teams: Array<string>) => Promise<void>
    onCancel: () => void
}

const ExerciseSetupTeamPicker = (props: ExerciseSetupTeamPickerProps) => {
    const { data } = useQuery<AllTeamQuery, AllTeamQueryVariables>(
        ALL_TEAM_QUERY
    )

    const [searchText, setSearchText] = useState("")
    const [isLoading, setLoading] = useState(false)

    const [selectedOptions, setSelectedOptions] = useState(
        props.selectedTeamIds
    )
    const { t } = useAwaitTranslation("exercises")

    const handleDone = async () => {
        setLoading(true)
        await props.onDone(selectedOptions)
    }

    const onClickTeam = (teamId: string) => () => {
        setSelectedOptions((teamIds) => {
            const isTeamIncluded = teamIds.includes(teamId)
            if (isTeamIncluded) return teamIds.filter((u) => u !== teamId)
            return [...teamIds, teamId]
        })
    }
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            open={true}
            fullWidth
            PaperProps={{
                style: { height: "100%" },
            }}
        >
            <DialogTitle id="confirmation-dialog-title">
                <TextField
                    label={t("search", "Search...")}
                    variant="outlined"
                    fullWidth
                    value={searchText}
                    onChange={(e) => setSearchText(e.currentTarget.value)}
                />
            </DialogTitle>
            <DialogContent dividers style={{ height: "100%" }}>
                <List dense style={{ height: "100%" }}>
                    {(data?.teams ?? []).map((value) => {
                        const labelId = `checkbox-list-secondary-label-${value}`
                        const searchTerm = value.name.toLowerCase()

                        const isVisible =
                            searchText.length < 1 ||
                            searchTerm.includes(searchText.toLowerCase())

                        if (!isVisible) return null
                        return (
                            <ListItem
                                key={value.teamId}
                                button
                                onClick={onClickTeam(value.teamId)}
                            >
                                <ListItemAvatar>
                                    <Avatar src={value.imageUrl} />
                                </ListItemAvatar>
                                <ListItemText
                                    id={labelId}
                                    primary={value.name}
                                />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        checked={selectedOptions.includes(
                                            value.teamId
                                        )}
                                        onChange={onClickTeam(value.teamId)}
                                        inputProps={{
                                            "aria-labelledby": labelId,
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    onClick={props.onCancel}
                    color="primary"
                    disabled={isLoading}
                >
                    {t("cancel", "Cancel")}
                </Button>
                <Button
                    onClick={handleDone}
                    color="secondary"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <CircularProgress size={20} color="secondary" />
                    ) : (
                        "Done"
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ExerciseSetupTeamPicker
