import { Box, Paper } from "@material-ui/core"
import { useEffect, useState, useCallback } from "react"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import {
    CREATE_EXERCISE,
    ADD_EXERCISE_TYPE,
    ADD_EXERCISE_PARENT_CONCEPT,
    GET_EXERCISE_TYPES,
} from "../../exercises/graphql"
import {
    GetExerciseTypesQuery,
    GetExerciseTypesQueryVariables,
    CreateExerciseMutation,
    CreateExerciseMutationVariables,
    AddExerciseTypeMutation,
    AddExerciseTypeMutationVariables,
    AddExerciseParentConceptMutation,
    AddExerciseParentConceptMutationVariables,
} from "../../exercises/__generated__/graphql"
import { useMutation, useQuery } from "@apollo/client"
import ExerciseCard from "../../exercises/ExerciseCard"
import { ExerciseStatus, ExerciseType } from "../../../__generated__/types"
import { useParams } from "react-router-dom"
import { EXERCISE_CATEGORIES, WidgetTypeName } from "../useWidgetTypes"

type WidgetConfig = {
    label: string
    exerciseId: string
}

const ExerciseWidget: WidgetTypeComponent = (props) => {
    const {
        config,
        isInitialized,
        onEditConfig,
        widget,
    } = useWidget<WidgetConfig>(props.widget.id)
    const [createExercise] = useMutation<
        CreateExerciseMutation,
        CreateExerciseMutationVariables
    >(CREATE_EXERCISE)
    const { conceptId } = useParams()
    const { data: typesData } = useQuery<
        GetExerciseTypesQuery,
        GetExerciseTypesQueryVariables
    >(GET_EXERCISE_TYPES)
    const [addExerciseType] = useMutation<
        AddExerciseTypeMutation,
        AddExerciseTypeMutationVariables
    >(ADD_EXERCISE_TYPE)
    const [addExerciseParent] = useMutation<
        AddExerciseParentConceptMutation,
        AddExerciseParentConceptMutationVariables
    >(ADD_EXERCISE_PARENT_CONCEPT)
    const [createdNewExercise, setCreatedNewExercise] = useState(false)
    const exerciseTypes = typesData?.ExerciseType ?? null
    const createNewExercise = useCallback(async () => {
        const {
            data: { CreateExercise: NewExercise },
        } = await createExercise({
            variables: {
                title: config?.label,
                description: "",
                duration: 10,
                status: ExerciseStatus.DRAFT,
            },
        })
        await addExerciseParent({
            variables: {
                conceptId: conceptId,
                exerciseId: NewExercise.id,
            },
        })
        const type: ExerciseType = exerciseTypes.filter(
            (item) =>
                item.category ===
                EXERCISE_CATEGORIES[WidgetTypeName[widget?.type?.name]]
        )?.[0]
        if (!!type) {
            await addExerciseType({
                variables: {
                    fromId: NewExercise.id,
                    toId: type.id,
                },
            })
        }
        await onEditConfig({
            ...config,
            exerciseId: NewExercise.id,
        })
    }, [
        createExercise,
        config,
        conceptId,
        onEditConfig,
        addExerciseParent,
        addExerciseType,
        exerciseTypes,
        widget?.type?.name,
    ])
    useEffect(() => {
        if (
            !!isInitialized &&
            config?.exerciseId?.length === 0 &&
            !createdNewExercise &&
            !!exerciseTypes
        ) {
            setCreatedNewExercise(true)
            createNewExercise()
        }
    }, [
        config?.exerciseId,
        createdNewExercise,
        isInitialized,
        exerciseTypes,
        createNewExercise,
    ])

    return (
        <>
            <Box
                component={Paper}
                display="flex"
                height="100%"
                width="100%"
                flexDirection="column"
                pb={2}
                key={props.widget.id}
            >
                <WidgetHeader
                    label={config?.label}
                    actions={[]}
                    widget={props.widget}
                    editing={props.editing}
                />
                {isInitialized && config?.exerciseId?.length !== 0 && (
                    <Box key={props.widget.id} overflow="hidden" flexGrow={1}>
                        <ExerciseCard exerciseId={config?.exerciseId} />
                    </Box>
                )}
            </Box>
        </>
    )
}

export default ExerciseWidget
