import { useState, useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useLazyQuery, useMutation } from "@apollo/client"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import SearchIcon from "@material-ui/icons/Search"
import InputAdornment from "@material-ui/core/InputAdornment"
import Button from "@material-ui/core/Button"
import { ADD_TEAM_MEMBER } from "./graphql"
import {
    AddTeamMemberMutation,
    AddTeamMemberMutationVariables,
} from "./__generated__/graphql"
import { ALL_USER_QUERY } from "../users/graphql"
import { Team, User } from "../../__generated__/types"
import { Loading } from "../Loading"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    AllUsersQuery,
    AllUsersQueryVariables,
} from "../users/__generated__/graphql"

interface TeamMemberFormProps {
    team: Team
    onClose: () => void
}
export default function TeamMemberForm(props: TeamMemberFormProps) {
    const [loadUsers, { data, loading, called }] = useLazyQuery<
        AllUsersQuery,
        AllUsersQueryVariables
    >(ALL_USER_QUERY)
    const [newMember, setNewMember] = useState(null)
    const [options, setOptions] = useState(null)
    const [saving, setSaving] = useState(false)
    const { t } = useAwaitTranslation("teams")
    const [addTeamMember] = useMutation<
        AddTeamMemberMutation,
        AddTeamMemberMutationVariables
    >(ADD_TEAM_MEMBER)
    useEffect(() => {
        if (data && data.users) {
            const memberChecks: String[] = props.team.members.map(
                (x: User) => x.userId
            )
            let memberOptions: User[] = []
            data.users.forEach((x: User) => {
                if (memberChecks.indexOf(x.userId) === -1) {
                    memberOptions.push(x)
                }
            })
            setOptions(memberOptions)
        }
    }, [data, setOptions, props.team])
    const handleSave = async () => {
        setSaving(true)
        await addTeamMember({
            variables: {
                fromId: newMember.userId,
                toId: props.team.teamId,
            },
        })
        props.onClose()
    }

    return (
        <Dialog open={true} fullWidth>
            {saving ? (
                <Loading size={50} hideQuote={true} padding={3} />
            ) : (
                <>
                    <DialogTitle>
                        {t("addTeamMember", "Add Team Member")}
                    </DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            id="multiple-limit-tags"
                            options={options ?? []}
                            value={newMember}
                            onFocus={() => {
                                if (!called) {
                                    loadUsers()
                                }
                            }}
                            disableClearable
                            loading={loading}
                            onChange={(event, newValue) => {
                                setNewMember(newValue)
                            }}
                            getOptionLabel={(option) => {
                                return option.lastName + ", " + option.firstName
                            }}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    {...params}
                                    autoFocus
                                    variant="standard"
                                    label={"Team Member"}
                                    placeholder="Select User"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: loading ? (
                                            <>
                                                <InputAdornment position="start">
                                                    <CircularProgress
                                                        disableShrink
                                                        size={25}
                                                    />
                                                </InputAdornment>
                                                {
                                                    params.InputProps
                                                        .startAdornment
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                                {
                                                    params.InputProps
                                                        .startAdornment
                                                }
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                props.onClose()
                            }}
                        >
                            {t("discard", "Discard")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!newMember}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}
