export const ConceptFieldSettings = {
    Title: {
        maxLength: 75,
    },
    Summary: {
        maxLength: 200,
    },
}

export const ExerciseFieldSettings = {
    title: {
        maxLength: 75,
    },
    description: {
        maxLength: 300,
    },
}
export const ExerciseTypeFieldSettings = {
    title: {
        maxLength: 75,
    },
    description: {
        maxLength: 300,
    },
    instructions: {
        maxLength: 300,
    },
}
