import { useTimer } from "react-timer-hook"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline"
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline"
import ReplayIcon from "@material-ui/icons/Replay"
import Tooltip from "@material-ui/core/Tooltip"
import { useTheme, Theme } from "@material-ui/core/styles"

interface CountdownTimer {
    duration: number
}
export function CountdownTimer(props: CountdownTimer) {
    const time = new Date()
    time.setSeconds(time.getSeconds() + props.duration * 60)
    return (
        <Box>
            <UseTime expiryTimestamp={time} duration={props.duration} />
        </Box>
    )
}
export function UseTime({ expiryTimestamp, duration }) {
    const theme: Theme = useTheme()
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp,
        onExpire: () => console.warn("onExpire called"),
    })
    const adjustedSeconds =
        seconds.toString().length === 1
            ? String("0" + seconds)
            : String(seconds)
    return (
        <Box
            style={{
                color: theme.palette.text.secondary,
            }}
            display="flex"
            alignItems="center"
        >
            <Box textAlign="center" fontSize="2em">
                <span>{minutes}</span>:<span>{adjustedSeconds}</span>
            </Box>
            <Box display="flex">
                {isRunning ? (
                    <>
                        <Tooltip title="Pause timer">
                            <IconButton onClick={pause}>
                                <PauseCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Restart timer">
                            <IconButton
                                onClick={() => {
                                    const time = new Date()
                                    restart(
                                        Number(
                                            time.setSeconds(
                                                time.getSeconds() +
                                                    duration * 60
                                            )
                                        )
                                    )
                                }}
                            >
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : (
                    <>
                        <Tooltip title="Start">
                            <IconButton onClick={resume}>
                                <PlayCircleOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Restart timer">
                            <IconButton
                                onClick={() => {
                                    const time = new Date()
                                    restart(
                                        Number(
                                            time.setSeconds(
                                                time.getSeconds() +
                                                    duration * 60
                                            )
                                        )
                                    )
                                }}
                            >
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </Box>
        </Box>
    )
}
