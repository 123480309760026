import React, { useState } from "react"
import {
    Paper,
    ClickAwayListener,
    Popper,
    Typography,
    Tooltip,
    Box,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    Fade,
    IconButton,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    MobileStepper,
} from "@material-ui/core"
import { ApolloQueryResult } from "@apollo/client"
import BookmarksIcon from "@material-ui/icons/Bookmarks"
import { makeStyles } from "@material-ui/core/styles"
import { useDashboardTemplates } from "./useDashboardTemplates"
import { DashboardConceptByIdQuery } from "./__generated__/graphql"
import { DashboardView } from "../../__generated__/types"
import Dashboard from "./Dashboard"
import AddIcon from "@material-ui/icons/Add"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import { useGlobalStyles } from "../../styles/globalStyles"
import { useParams } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },
    paper: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        maxWidth: "700px",
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        width: "700px",
    },
    tempContainer: {
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(1),
        overflowY: "hidden",
        overflowX: "auto",
        flexWrap: "nowrap",
        height: 250,
        margin: "auto",
    },
    tempRoot: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: 0,
        margin: 0,
        backgroundColor: theme.palette.background.paper,
        fontSize: theme.typography.body2.fontSize,
        width: 150,
        justifyContent: "center",
        height: "100%",
    },
    stepper: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    templateName: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",

        fontSize: theme.typography.caption.fontSize,
        paddingBottom: theme.spacing(0.5),
        color: theme.palette.text.secondary,
    },
    button: {
        padding: theme.spacing(1),
    },
}))
interface Props {
    refetch?: () => Promise<ApolloQueryResult<DashboardConceptByIdQuery>>
}
export const DashboardStore = (props: Props) => {
    const classes = useStyles()
    const globalClasses = useGlobalStyles()
    const { conceptId } = useParams()
    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const [creatingDashboard, setCreatingDashboard] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [activeStep, setActiveStep] = useState(0)
    const {
        isTemplatesInitialized,
        dashboardTemplates,
        onApplyDashboardTemplate,
        onDeleteDashboardTemplate,
    } = useDashboardTemplates()
    const handleTemplateUse = async (dashboard: DashboardView) => {
        await onApplyDashboardTemplate(dashboard, conceptId)
        await props.refetch()
        setCreatingDashboard(false)
    }
    const open = Boolean(anchorEl)
    const currentTemplates =
        dashboardTemplates?.filter((temp, index) => {
            if (activeStep === 0) {
                return index < 4
            } else {
                return (
                    index + 1 > activeStep * 4 &&
                    index + 1 < (activeStep + 1) * 4
                )
            }
        }) ?? []

    return (
        isTemplatesInitialized && (
            <>
                <Tooltip title="View available dashboard templates">
                    <ListItem
                        button
                        onClick={handleButtonClick}
                        divider
                        className={classes.button}
                    >
                        <ListItemText primary={"Templates"} />
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <BookmarksIcon fontSize="small" />
                        </ListItemIcon>
                    </ListItem>
                </Tooltip>
                <Popper
                    placement="left-end"
                    anchorEl={anchorEl}
                    open={open}
                    transition
                    className={classes.popper}
                >
                    {({ TransitionProps }) => (
                        <ClickAwayListener
                            onClickAway={() => setAnchorEl(null)}
                        >
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.paper} elevation={8}>
                                    <Typography variant="body1">
                                        Dashboard Template Options
                                    </Typography>
                                    <Divider />
                                    <Box className={classes.tempContainer}>
                                        {currentTemplates.map(
                                            (temp: DashboardView) => {
                                                return (
                                                    <DashboardPreview
                                                        key={temp.id}
                                                        dashboard={temp}
                                                        handleTemplateUse={() => {
                                                            setCreatingDashboard(
                                                                true
                                                            )
                                                            handleTemplateUse(
                                                                temp
                                                            )
                                                        }}
                                                        creatingDashboard={
                                                            creatingDashboard
                                                        }
                                                        handleTemplateDeletion={() => {
                                                            onDeleteDashboardTemplate(
                                                                temp
                                                            )
                                                        }}
                                                    />
                                                )
                                            }
                                        )}
                                    </Box>
                                    <MobileStepper
                                        variant="dots"
                                        steps={Math.ceil(
                                            dashboardTemplates?.length / 4
                                        )}
                                        className={classes.stepper}
                                        position="static"
                                        activeStep={activeStep}
                                        nextButton={
                                            <Button
                                                size="small"
                                                onClick={() =>
                                                    setActiveStep(
                                                        activeStep + 1
                                                    )
                                                }
                                                disabled={
                                                    dashboardTemplates.length /
                                                        4 <=
                                                    activeStep + 1
                                                }
                                            >
                                                Next
                                                <KeyboardArrowRightIcon />
                                            </Button>
                                        }
                                        backButton={
                                            <Button
                                                size="small"
                                                onClick={() =>
                                                    setActiveStep(
                                                        activeStep - 1
                                                    )
                                                }
                                                disabled={activeStep === 0}
                                            >
                                                <KeyboardArrowLeftIcon />
                                                Back
                                            </Button>
                                        }
                                    />
                                </Paper>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            </>
        )
    )
}

interface DashboardPreviewProps {
    dashboard: DashboardView
    handleTemplateUse: () => void
    handleTemplateDeletion: () => void
    creatingDashboard: boolean
}

const DashboardPreview = (props: DashboardPreviewProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [hovered, setHovered] = useState(false)
    return (
        <Box
            width={150}
            display="flex"
            flexDirection="column"
            height="100%"
            m={1}
            onMouseOver={(e) => setHovered(true)}
            onMouseLeave={(e) => setHovered(false)}
        >
            <Box
                minHeight={theme.spacing(4.5)}
                display="flex"
                alignItems="flex-end"
            >
                <Tooltip title={props.dashboard.name}>
                    <Box className={classes.templateName} flexGrow={1}>
                        {props.dashboard.name}
                    </Box>
                </Tooltip>
                <Fade in={hovered} unmountOnExit mountOnEnter>
                    <Box display="flex" justifyContent="flex-end">
                        <Tooltip title="Delete template">
                            <IconButton
                                size="small"
                                onClick={() => setConfirmOpen(true)}
                            >
                                <DeleteForeverIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Use template">
                            <IconButton
                                onClick={(e) => {
                                    props.handleTemplateUse()
                                }}
                                size="small"
                                disabled={!!props.creatingDashboard}
                            >
                                {props.creatingDashboard ? (
                                    <CircularProgress size={20} disableShrink />
                                ) : (
                                    <AddIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Fade>
            </Box>

            <Box
                key={props.dashboard.id}
                className={classes.tempRoot}
                style={{
                    overflowY: hovered ? "auto" : "hidden",
                    overflowX: "hidden",
                    backgroundColor: hovered
                        ? //@ts-ignore
                          theme.palette.action.hover
                        : theme.palette.background.paper,
                    border: hovered
                        ? `1px solid ${theme.palette.primary.main}`
                        : `1px solid ${theme.palette.divider}`,
                }}
            >
                <Dashboard
                    dashboardId={props.dashboard.id}
                    width={150}
                    scaledDown={true}
                />
            </Box>

            <Dialog open={confirmOpen}>
                <DialogContent>
                    Are you sure you want to delete this dashboard template?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)}>No</Button>
                    <Button
                        onClick={() => {
                            props.handleTemplateDeletion()
                            setConfirmOpen(false)
                        }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
