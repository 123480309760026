import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@material-ui/core"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import { useParams } from "react-router-dom"
import { DEFAULT_CRITERIA_BY_CATEGORY } from "../criteria/graphql"
import {
    DefaultCriteriaByCategoryQuery,
    DefaultCriteriaByCategoryQueryVariables,
} from "../criteria/__generated__/graphql"
import { CONCEPT_BY_ID } from "../../graphql/queries"
import { useQuery, useApolloClient } from "@apollo/client"
import { Criteria } from "../../__generated__/types"
import { CriteriaType } from "../criteria/types"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../../graphql/__generated__/queries"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            margin: theme.spacing(1),
        },
        primary: {
            fontSize: theme.typography.body2.fontSize,
        },
    })
)

interface CriteriaWidgetOptionsProps {
    onAddNewWidget: (
        criteria: Pick<Criteria, "name" | "criteriaType">,
        label: string
    ) => void
}
export default function CriteriaWidgetOptions(
    props: CriteriaWidgetOptionsProps
) {
    const { conceptId } = useParams()
    const classes = useStyles()
    const client = useApolloClient()
    const cachedConcept = client.readQuery<ConceptQuery, ConceptQueryVariables>(
        {
            query: CONCEPT_BY_ID,
            variables: {
                id: conceptId,
            },
        }
    )?.Concept?.[0]
    const { data: CriteriaData } = useQuery<
        DefaultCriteriaByCategoryQuery,
        DefaultCriteriaByCategoryQueryVariables
    >(DEFAULT_CRITERIA_BY_CATEGORY, {
        variables: {
            categoryId: cachedConcept?.rootCategory?.id,
        },
        skip: !cachedConcept,
    })

    const richTextOptions =
        CriteriaData?.Criteria?.filter(
            (x) => x.criteriaType === CriteriaType.RichTextEditor
        ) ?? []
    return (
        <Box p={1}>
            <Box ml={1.5} mt={1} mb={-0.5}>
                <Typography variant="body2" color="textSecondary">
                    Default {cachedConcept?.rootCategory?.name} Fields
                </Typography>
            </Box>
            <List className={classes.list} disablePadding>
                {richTextOptions?.map((criteria, index) => {
                    return (
                        <ListItem
                            key={criteria.id}
                            button
                            onClick={() =>
                                props.onAddNewWidget(
                                    {
                                        name: criteria.name,
                                        criteriaType: criteria.criteriaType,
                                    },
                                    criteria.name
                                )
                            }
                        >
                            <ListItemText
                                primary={criteria.name}
                                primaryTypographyProps={{
                                    className: classes.primary,
                                }}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}
