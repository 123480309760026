import { makeVar, useQuery, useReactiveVar } from "@apollo/client"
import {
    InputLabel,
    MenuItem,
    Select,
    Typography,
    useMediaQuery,
    Paper,
    Hidden,
    Toolbar,
    Drawer,
} from "@material-ui/core"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ViewListIcon from "@material-ui/icons/ViewList"
import ViewModuleIcon from "@material-ui/icons/ViewModule"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import React, { useCallback, useEffect, useRef, useState } from "react"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { IDEAS_FEED_QUERY } from "../graphql/queries"
import {
    IdeasFeedQuery,
    IdeasFeedQueryVariables,
} from "../graphql/__generated__/queries"
import { globalFiltersVar } from "../providers/GlobalState"
import ConceptCard from "./ConceptCard"
import { Loading } from "./Loading"
import VirtualInfiniteList from "./VirtualInfiniteList"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import Divider from "@material-ui/core/Divider"
import { getFilterObject } from "./filters/util/getFilterObject"
import FeedCommentsContainer from "./commenting/FeedCommentsContainer"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import Filters from "./filters/Filters"
import { FilterItem } from "./filters/util/types"
import clsx from "clsx"
import { ReactComponent as GlobalFilterIcon } from "../styles/FilterIcon.svg"
import useFilters from "./filters/util/useFilters"
const drawerWidth = 350
const closedDrawerWidth = 32
const useStyles = makeStyles((theme) => ({
    rootBox: {
        alignItems: "flex-start",
        [theme.breakpoints.up("sm")]: {
            display: "flex",
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: theme.zIndex.appBar - 2,
    },
    drawerPaper: {
        width: drawerWidth,

        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    sidebarContent: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",

        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),

        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        flexGrow: 1,
    },
    sidebarHeader: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        display: "flex",
    },
    grey: {
        color: theme.palette.text.secondary,
        fontSize: "12px",
    },
    loading: {
        width: "100%",
        textAlign: "center",
        marginTop: theme.spacing(8),
    },
    toggleButton: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid",
        borderColor: theme.palette.divider,
        zIndex: theme.zIndex.appBar,
        position: "fixed",
    },
    toggleContainer: {
        position: "sticky",
        top: "64px",
        display: "flex",
        justifyContent: "center",
        zIndex: theme.zIndex.appBar - 1,
    },
    divider: {
        position: "fixed",
        top: 0,
        bottom: 0,
    },
    chevronContainer: {
        top: theme.spacing(10.5),
        position: "absolute",
        left: -theme.spacing(2),
    },
    swipeableDrawerClosed: {
        width: 0,
        zIndex: theme.zIndex.appBar - 2,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    swipeableDrawerOpen: {
        width: drawerWidth,
    },
    drawerClose: {
        overflowX: "hidden",

        width: closedDrawerWidth,

        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toggleAreaContainer: {
        minWidth: theme.spacing(4),
        borderRight: `1px solid ${theme.palette.divider}`,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
    },
}))

const PAGE_SIZE = 10
const LIMIT_VAR = makeVar(PAGE_SIZE)

export default function IdeasFeed() {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { defaultOptions, isInitialized } = useFilters()
    const currentState = useReactiveVar(globalFiltersVar)
    const [localFilterState, setLocalFilterState] = React.useState({})
    const { t } = useAwaitTranslation("ideas")
    const [itemsPerRow, setItemsPerRow] = useState(3)
    const [columnSelectValue, setColumnSelectValue] = useState(3)
    const [showSidebar, setShowSidebar] = useState(false)
    const [commentConceptId, setCommentConceptId] = useState(null)
    const limit = useReactiveVar(LIMIT_VAR)
    const { loading, error, data, fetchMore, refetch } = useQuery<
        IdeasFeedQuery,
        IdeasFeedQueryVariables
    >(IDEAS_FEED_QUERY, {
        variables: {
            filter: localFilterState,
            offset: 0,
            first: limit + PAGE_SIZE,
        },
    })
    useEffect(() => {
        if (mobile) {
            setItemsPerRow(1)
            setColumnSelectValue(1)
            setShowSidebar(false)
        }
    }, [mobile])
    const feedData = data?.Concept ?? []
    const size = Math.ceil(feedData.length / itemsPerRow)

    const onFetchMore = useCallback(
        async (offset) => {
            LIMIT_VAR(offset)
            if (!fetchMore) return
            const result: { data: IdeasFeedQuery } = await fetchMore({
                variables: {
                    offset,
                },
            })

            return result.data.Concept.length >= PAGE_SIZE
        },
        [fetchMore]
    )

    let isFetchingMore = useRef(false)
    let [showPullToRefreshIndicator, setShowPullToRefreshIndicator] = useState(
        false
    )
    useEffect(() => {
        let _startY = 0

        const onTouchStart = (e: WindowEventMap["touchstart"]) => {
            _startY = e.touches[0].pageY
        }
        const onTouchMove = (e: WindowEventMap["touchmove"]) => {
            const y = e.touches[0].pageY
            // Activate pull-to-refresh when at the top of the container
            // and user is scrolling up.
            if (
                document.scrollingElement.scrollTop === 0 &&
                // add 100 offset
                y > _startY + 100 &&
                !isFetchingMore.current
            ) {
                setShowPullToRefreshIndicator(true)
                isFetchingMore.current = true
                refetch({
                    offset: 0,
                }).then(() => {
                    setShowPullToRefreshIndicator(false)
                    isFetchingMore.current = false
                })
            }
        }

        document.body.style["overscroll-behavior"] = "none"
        window.addEventListener("touchmove", onTouchMove, { passive: true })
        window.addEventListener("touchstart", onTouchStart, { passive: true })

        return () => {
            document.body.style["overscroll-behavior"] = "auto"
            window.removeEventListener("touchmove", onTouchMove)
            window.removeEventListener("touchstart", onTouchMove)
        }
    }, [refetch])

    useEffect(() => {
        let filterObject = getFilterObject(currentState)
        if (JSON.stringify(localFilterState) !== JSON.stringify(filterObject)) {
            setCommentConceptId(null)
            LIMIT_VAR(PAGE_SIZE)
            setLocalFilterState(filterObject)
        }
    }, [limit, refetch, data, currentState, localFilterState])

    if (error) return <p>Error: {JSON.stringify(error)}</p>

    const alignment = itemsPerRow === 1 ? "column" : "grid"
    const handleAlignment = (_, val) => {
        if (val === "column") {
            setItemsPerRow(1)
        } else if (val === "grid") {
            setItemsPerRow(columnSelectValue)
        }
    }
    const sidebarContents = (
        <Box className={classes.sidebarContent}>
            {commentConceptId ? (
                <FeedCommentsContainer
                    concept={
                        feedData.filter((x) => x.id === commentConceptId)[0]
                    }
                    closeComments={() => {
                        setCommentConceptId(null)
                        if (mobile) {
                            setShowSidebar(false)
                        }
                    }}
                />
            ) : (
                <>
                    <Hidden smDown>
                        <Box
                            mb={1}
                            display="flex"
                            justifyContent="space-between"
                            p={1}
                            pr={2.5}
                            pl={2.5}
                        >
                            <ToggleButtonGroup
                                value={alignment}
                                onChange={handleAlignment}
                                exclusive
                                size="small"
                            >
                                <ToggleButton value="column">
                                    <ViewListIcon
                                        style={{
                                            transform: "rotate(180deg)",
                                        }}
                                    />
                                </ToggleButton>
                                <ToggleButton value="grid">
                                    <ViewModuleIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Box display="flex">
                                <InputLabel
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: "8px",
                                        textAlign: "center",
                                    }}
                                    id="column-select-label"
                                >
                                    {t("column", "Column")} <br />
                                    {t("count", "Count")}
                                </InputLabel>
                                <Select
                                    labelId="column-select-label"
                                    variant="outlined"
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "0.5em",
                                            paddingBottom: "0.5em",
                                        },
                                    }}
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                    onChange={(ev) => {
                                        let val = ev.target.value as number
                                        setItemsPerRow(val)
                                        if (val > 1) {
                                            setColumnSelectValue(val)
                                        }
                                    }}
                                    value={itemsPerRow}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                        <Divider />
                    </Hidden>
                    <Box flexGrow={"1"}>
                        <Filters
                            filterOptions={defaultOptions}
                            filters={currentState}
                            setFilters={(filters: FilterItem[]) => {
                                globalFiltersVar(filters)
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    )
    const currentWidthToSubtract = showSidebar ? drawerWidth : closedDrawerWidth
    return (
        <Box className={classes.rootBox}>
            <Hidden smUp>
                <Box
                    position="sticky"
                    top={64}
                    width="100vw"
                    style={{
                        backgroundColor: theme.palette.secondary.main,
                    }}
                    zIndex={theme.zIndex.appBar - 1}
                    left={0}
                    right={0}
                >
                    <Paper
                        style={{
                            borderRadius: "0px",
                            padding: theme.spacing(1),
                            display: "flex",
                            justifyContent: "flex-end",
                            //@ts-ignore
                            backgroundColor: theme.palette.background.level2,
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                setShowSidebar(!showSidebar)
                            }}
                            style={{ padding: "0px" }}
                        >
                            {showSidebar ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </Paper>
                </Box>
            </Hidden>

            {loading ? (
                <div className={classes.loading}>
                    <Loading size={150} />
                </div>
            ) : feedData.length > 0 ? (
                <Box
                    pt={1}
                    pl={1.5}
                    pr={1.5}
                    style={{
                        width: mobile
                            ? "100%"
                            : "calc(100% - " + currentWidthToSubtract + "px)",
                    }}
                >
                    <Box width="100%">
                        {showPullToRefreshIndicator && (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress color="secondary" />
                            </Box>
                        )}
                        <VirtualInfiniteList
                            key={JSON.stringify(localFilterState)}
                            onFetchMore={onFetchMore}
                            startOffset={limit}
                            overscan={Math.ceil(10 / itemsPerRow)}
                            pageSize={PAGE_SIZE}
                            size={size}
                        >
                            {(itemIndex) => (
                                <Box
                                    display="grid"
                                    gridTemplateColumns={`repeat(${itemsPerRow}, 1fr)`}
                                    gridGap="15px"
                                >
                                    {new Array(itemsPerRow)
                                        .fill(1)
                                        .map((_, index) => {
                                            const item =
                                                feedData[
                                                    itemIndex * itemsPerRow +
                                                        index
                                                ]
                                            return item ? (
                                                <ConceptCard
                                                    key={item.id}
                                                    item={item}
                                                    grid={itemsPerRow > 1}
                                                    viewComments={() => {
                                                        setCommentConceptId(
                                                            item.id
                                                        )
                                                        if (!showSidebar) {
                                                            setShowSidebar(true)
                                                        }
                                                    }}
                                                />
                                            ) : null
                                        })}
                                </Box>
                            )}
                        </VirtualInfiniteList>
                    </Box>
                </Box>
            ) : (
                currentState.length > 0 && (
                    <Box
                        padding={4}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <Typography variant="h6">
                            {t(
                                "adjustFiltersToView",
                                "Adjust filters to view concepts"
                            )}
                            ...
                        </Typography>
                    </Box>
                )
            )}
            {mobile ? (
                <SwipeableDrawer
                    open={showSidebar}
                    variant="temporary"
                    anchor="right"
                    onOpen={() => setShowSidebar(true)}
                    onClose={() => setShowSidebar(false)}
                    className={
                        showSidebar
                            ? classes.swipeableDrawerOpen
                            : classes.swipeableDrawerClosed
                    }
                    classes={{
                        paper: showSidebar ? classes.drawerPaper : "",
                    }}
                >
                    {sidebarContents}
                </SwipeableDrawer>
            ) : (
                <Drawer
                    anchor="right"
                    variant="persistent"
                    open={!mobile}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: showSidebar,
                        [classes.drawerClose]: !showSidebar,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: showSidebar,
                            [classes.drawerClose]: !showSidebar,
                        }),
                    }}
                    PaperProps={{
                        onClick: () => {
                            if (!showSidebar) {
                                setShowSidebar(true)
                            }
                        },
                    }}
                >
                    <Toolbar className={classes.toolbar} />
                    <Box className={classes.chevronContainer}>
                        <IconButton
                            className={classes.toggleButton}
                            onClick={() => {
                                setShowSidebar((prev) => !prev)
                            }}
                            style={{
                                padding: theme.spacing(1),
                                borderRadius: "50%",
                                backgroundColor: theme.palette.background.paper,
                            }}
                        >
                            <GlobalFilterIcon
                                style={{
                                    width: "0.75em",
                                    height: "0.75em",
                                    fill: theme.palette.primary.main,
                                }}
                            />
                        </IconButton>
                    </Box>
                    <Box display="flex" height="100%">
                        <Box
                            className={classes.toggleAreaContainer}
                            onClick={() => {
                                setShowSidebar((prev) => !prev)
                            }}
                        ></Box>
                        {showSidebar ? sidebarContents : null}
                    </Box>
                </Drawer>
            )}
        </Box>
    )
}
