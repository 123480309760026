import { useState, useEffect } from "react"
import { CATEGORIES_FILTERED } from "../../categories/graphql"
import { useQuery } from "@apollo/client"
import { FilterItem } from "./types"
const baseConceptFilterOptions: FilterItem[] = [
    {
        field: "Type",
        firstLevelFilter: "",
        secondLevelFilter: "type_in",
        value: [],
        locked: false,
        type: "Type",
    },
    {
        field: "Category",
        firstLevelFilter: "category",
        secondLevelFilter: "id_in",
        value: [],
        locked: true,
        type: "Category",
    },
    {
        field: "Industry",
        firstLevelFilter: "industries_some",
        secondLevelFilter: "industryId_in",
        value: [],
        locked: true,
        type: "Industry",
    },
    {
        field: "User",
        firstLevelFilter: "createdBy",
        secondLevelFilter: "userId_in",
        value: [],
        locked: false,
        type: "User",
    },

    {
        field: "Tags",
        firstLevelFilter: "tags_some",
        secondLevelFilter: "name_in",
        value: [],
        locked: false,
        type: "Tags",
    },
]
const baseConceptFilterDefaults: FilterItem[] = [
    {
        field: "Category",
        firstLevelFilter: "category",
        secondLevelFilter: "id_in",
        value: [],
        locked: true,
        type: "Category",
    },

    {
        field: "Industry",
        firstLevelFilter: "industries_some",
        secondLevelFilter: "industryId_in",
        value: [],
        locked: true,
        type: "Industry",
    },
]

export default function useFilters() {
    const { data } = useQuery(CATEGORIES_FILTERED, {
        variables: {
            filter: {
                isFilterOption: true,
            },
        },
    })

    const [defaultValue, setDefaultValue] = useState([])
    const [defaultOptions, setDefaultOptions] = useState([])
    const [isInitialized, setIsInitialized] = useState(false)
    useEffect(() => {
        if (!!data) {
            const defaultCategoryFilters =
                data?.Category?.map((item) => {
                    return {
                        field: item.name,
                        firstLevelFilter: "connections_some",
                        secondLevelFilter: "id_in",
                        value: [],
                        locked: true,
                        optionsFilter: {
                            category: {
                                id: item.id,
                            },
                        },
                        type: "Concept",
                    }
                }) ?? []
            setDefaultValue([
                ...defaultCategoryFilters,
                ...baseConceptFilterDefaults,
            ])
            setDefaultOptions([
                ...defaultCategoryFilters,
                ...baseConceptFilterOptions,
            ])
            setIsInitialized(true)
        }
    }, [data])
    return {
        defaultValue,
        defaultOptions,
        isInitialized,
    }
}
