import Brightness4Icon from "@material-ui/icons/Brightness4"
import Brightness7Icon from "@material-ui/icons/Brightness7"

export const getDarkModeIcon = (val) => {
    if (val === "light") {
        return <Brightness7Icon />
    } else if (val === "dark") {
        return <Brightness4Icon />
    } else return <Brightness4Icon />
}
