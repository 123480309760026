import { useState, useEffect, useCallback } from "react"
import { useQuery, useMutation, useApolloClient } from "@apollo/client"
import { USER_MANAGEMENT_LINE_ITEM } from "./graphql"
import {
    UserManagementLineItemQuery,
    UserManagementLineItemQueryVariables,
} from "./__generated__/graphql"
import {
    ADD_USER_HOME_CONCEPT,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
} from "../../components/users/graphql"
import {
    AddUserHomeConceptMutation,
    AddUserHomeConceptMutationVariables,
    CreateUserMutationVariables,
    CreateUserMutation,
    UpdateUserMutation,
    UpdateUserMutationVariables,
} from "../../components/users/__generated__/graphql"
import { CREATE_USER_WORKSPACE } from "../../graphql/conceptListQueries"
import {
    CreateUserWorkspaceMutation,
    CreateUserWorkspaceMutationVariables,
} from "../../graphql/__generated__/conceptListQueries"
import { CREATE_CONCEPT } from "../../graphql/mutations"
import {
    CreateConceptCompleteMutation,
    CreateConceptCompleteMutationVariables,
} from "../../graphql/__generated__/mutations"
import { AccessType } from "../../__generated__/types"
import { USER_FILTERED } from "./graphql"
import { ADD_TEAM_ADMIN, ADD_TEAM_MEMBER } from "../teams/graphql"
import {
    AddTeamAdminMutation,
    AddTeamAdminMutationVariables,
    AddTeamMemberMutation,
    AddTeamMemberMutationVariables,
} from "../teams/__generated__/graphql"
export default function useUserManagementTools(userId?: string) {
    const { data, refetch } = useQuery<
        UserManagementLineItemQuery,
        UserManagementLineItemQueryVariables
    >(USER_MANAGEMENT_LINE_ITEM, {
        variables: {
            userId: userId,
        },
        skip: !userId,
    })
    const client = useApolloClient()
    const [createPersonalConcept] = useMutation<
        CreateConceptCompleteMutation,
        CreateConceptCompleteMutationVariables
    >(CREATE_CONCEPT)
    const [addHomeConcept] = useMutation<
        AddUserHomeConceptMutation,
        AddUserHomeConceptMutationVariables
    >(ADD_USER_HOME_CONCEPT)
    const [createWorkspace] = useMutation<
        CreateUserWorkspaceMutation,
        CreateUserWorkspaceMutationVariables
    >(CREATE_USER_WORKSPACE)
    const [createUser] = useMutation<
        CreateUserMutation,
        CreateUserMutationVariables
    >(CREATE_USER)
    const [deleteUser] = useMutation(DELETE_USER)
    const [updateUser] = useMutation<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >(UPDATE_USER)
    const [addAdmin] = useMutation<
        AddTeamAdminMutation,
        AddTeamAdminMutationVariables
    >(ADD_TEAM_ADMIN)
    const [addMember] = useMutation<
        AddTeamMemberMutation,
        AddTeamMemberMutationVariables
    >(ADD_TEAM_MEMBER)
    const [initialized, setInitialized] = useState(false)
    const [errors, setErrors] = useState([])
    const userData = data?.User?.[0] ?? null
    const [user, setUser] = useState(null)
    useEffect(() => {
        if (userData) {
            setUser(userData)
            setInitialized(true)
        }
    }, [userData, data])

    const onUpdateUser = useCallback(
        async (userData) => {
            await updateUser({
                variables: {
                    ...userData,
                },
            })
        },
        [updateUser]
    )
    const onDeleteUser = async () => {
        deleteUser({
            variables: {
                userId: user.userId,
            },
        })
        if (user) {
            client.cache.evict({
                id: client.cache.identify(user),
            })
        }
    }
    const onAddTeams = useCallback(
        async (teamIds: string[]) => {
            await Promise.all([
                ...teamIds.map((teamId) =>
                    addMember({
                        variables: {
                            fromId: userId,
                            toId: teamId,
                        },
                    })
                ),
            ])
            await refetch()
        },
        [addMember, userId, refetch]
    )
    const onCreateUser = async (userData) => {
        const {
            data: { User: UserCheck },
        } = await client.query({
            query: USER_FILTERED,
            variables: {
                filter: {
                    email: userData?.email,
                },
            },
        })
        if (!!UserCheck) {
            if (UserCheck.length > 0) {
                setErrors([
                    "There has already been an account created for this email",
                ])
                return null
            } else {
                const {
                    data: { CreateUser: User },
                } = await createUser({ variables: userData })

                await createWorkspace({
                    variables: {
                        userId: User.userId,
                    },
                })
                const {
                    data: { CreateConcept: NewConcept },
                } = await createPersonalConcept({
                    variables: {
                        concept: {
                            title: `${User.username}'s Personal Concept`,
                            isPublic: false,
                            isPublicAccessType: AccessType.VIEWER,
                            type: "User Concept",
                        },
                        userId: User.userId,
                        tags: [],
                    },
                })
                await addHomeConcept({
                    variables: {
                        conceptId: NewConcept.id,
                        userId: User.userId,
                    },
                })
                return User
            }
        } else {
            setErrors([
                ...errors,
                "We are having issues validating the email address.",
            ])
            return
        }
    }
    return {
        initialized,
        user,
        onCreateUser,
        onUpdateUser,
        onAddTeams,
        errors,
        refetch,
        onDeleteUser,
    }
}
