import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
    Avatar,
    Box,
    Grid,
    Divider,
    Link,
    Tooltip,
    Hidden,
} from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import DetailsField from "./DetailsField"
import ImageUploadDialog from "./images/ImageUploadDialog"
import IndustryList from "./industries/IndustryList"
import { ConceptFieldSettings } from "../util/SystemSettings"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { UPDATE_CONCEPT } from "../graphql/mutations"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../graphql/__generated__/mutations"
import { CONCEPT_BY_ID } from "../graphql/queries"
import { useMutation, useReactiveVar } from "@apollo/client"
import ConceptPermissions from "./permissions/ConceptPermissions"
import { AccessType, Concept } from "../__generated__/types"
import LinkIcon from "@material-ui/icons/Link"
import { useAuth } from "../providers/AuthProvider"
import TagToggle from "./tags/TagToggle"
import { permissionsVar } from "../providers/GlobalState"
import ConceptDescription from "./ConceptDescription"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import PeopleIcon from "@material-ui/icons/People"
import PersonIcon from "@material-ui/icons/Person"
import ConceptDetailsHeaderOptions from "./ConceptDetailsHeaderOptions"
import ConceptDates from "./ConceptDates"
import CategoryTreeSelectorWrapper from "./categories/CategoryTreeSelectorWrapper"
const useStyles = makeStyles((theme) => ({
    cardBody: {
        padding: theme.spacing(0, 2),
    },
    pictureContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(1.5, 0),
    },

    authorContainer: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.text.secondary,
        height: "100%",
        marginTop: theme.spacing(1),
        fontSize: "12px",
    },
    titleContainer: {
        [theme.breakpoints.down("md")]: {
            margin: "auto",
            marginTop: "1em",
        },
    },
    largeMedia: {
        minWidth: "300px",
        maxWidth: "75%",
        minHeight: "300px",
        height: "300px",
        flexShrink: 0,
        flexGrow: 1,
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(1),
    },
    smallMedia: {
        minWidth: "150px",
        maxWidth: "75%",
        minHeight: "150px",
        height: "150px",
        flexShrink: 0,
        flexGrow: 1,
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(1),
    },
    cardContent: {
        width: "100%",
    },
    avatar: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        marginRight: "7.5px",
    },
    bottomRow: {
        display: "flex",
        minHeight: 75,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            minHeight: 150,
        },
    },
    topBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            margin: theme.spacing(1, 0),
        },
        minHeight: theme.spacing(6),
    },
    conceptDates: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(1),
        },
        flexShrink: 0,
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    permissions: {
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    smallAvatar: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
    },
    ownerContainer: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        cursor: "pointer",
    },
}))

interface ConceptHeaderProps {
    item: Concept
    preview?: boolean
}

export default function ConceptDetailsHeader(props: ConceptHeaderProps) {
    const classes = useStyles()
    const { currentUser } = useAuth()
    const item = props.item
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: item.id },
            },
        ],
    })
    const editPermission = useReactiveVar(permissionsVar)
    const [open, setOpen] = useState(false)
    const [openPermissions, setOpenPermissions] = useState(false)
    const { t } = useAwaitTranslation("widgets")

    const handleEdit = (field: string, value: string) => {
        let valObject = {
            userId: currentUser.userId,
            concept: {
                id: props.item.id,
            },
        }
        valObject.concept[field] = value
        if (props.item[field] !== value) {
            updateConcept({
                variables: {
                    ...valObject,
                },
            })
        }
    }
    let ownerUsers =
        item.addedUsers
            .map((item) => {
                if (item.type === AccessType.OWNER) {
                    return item.User
                } else return null
            })
            ?.filter((x) => !!x) ?? []
    let ownerTeams =
        item.addedTeams
            .map((item) => {
                if (item.type === AccessType.OWNER) {
                    return item.Team
                } else return null
            })
            ?.filter((x) => !!x) ?? []

    return (
        <>
            <Card
                className={classes.cardBody}
                elevation={!!props.preview ? 0 : 1}
            >
                <Box display="flex">
                    <Box key={props.item.id} className={classes.topBar}>
                        <CategoryTreeSelectorWrapper concept={props.item} />
                        {props.item.startDate?.formatted &&
                            props.item.endDate?.formatted && (
                                <>
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        className={classes.divider}
                                    />
                                    <Box className={classes.conceptDates}>
                                        <ConceptDates
                                            concept={props.item}
                                            editPermission={editPermission}
                                        />
                                    </Box>
                                </>
                            )}

                        <Hidden xsDown>
                            <>
                                {(ownerUsers.length > 0 ||
                                    ownerTeams.length > 0) && (
                                    <>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            className={classes.divider}
                                        />
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            className={classes.ownerContainer}
                                            p={1}
                                            onClick={() => {
                                                setOpenPermissions(true)
                                            }}
                                        >
                                            {ownerUsers.length > 0 && (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    pr={1}
                                                    pl={1}
                                                >
                                                    <Tooltip title="Users with owner permissions">
                                                        <Box
                                                            p={0.5}
                                                            pr={1}
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            <PersonIcon />
                                                        </Box>
                                                    </Tooltip>
                                                    <AvatarGroup max={3}>
                                                        {ownerUsers.map(
                                                            (user) => {
                                                                return (
                                                                    <Tooltip
                                                                        title={`${user.firstName} ${user.lastName}`}
                                                                        key={
                                                                            user.userId
                                                                        }
                                                                    >
                                                                        <Avatar
                                                                            className={
                                                                                classes.smallAvatar
                                                                            }
                                                                            src={
                                                                                user.imageUrl
                                                                            }
                                                                            alt={`${user.firstName} ${user.lastName}`}
                                                                        >
                                                                            {
                                                                                user
                                                                                    .firstName?.[0]
                                                                            }
                                                                            {
                                                                                user
                                                                                    .lastName?.[0]
                                                                            }
                                                                        </Avatar>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        )}
                                                    </AvatarGroup>
                                                </Box>
                                            )}
                                            {ownerTeams.length > 0 && (
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    pr={1}
                                                    pl={1}
                                                >
                                                    <Tooltip title="Teams with owner permissions">
                                                        <Box
                                                            p={0.5}
                                                            pr={1}
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            <PeopleIcon />
                                                        </Box>
                                                    </Tooltip>
                                                    <AvatarGroup max={3}>
                                                        {ownerTeams.map(
                                                            (team) => {
                                                                return (
                                                                    <Tooltip
                                                                        title={
                                                                            team.name
                                                                        }
                                                                    >
                                                                        <Avatar
                                                                            className={
                                                                                classes.smallAvatar
                                                                            }
                                                                            src={
                                                                                team.imageUrl
                                                                            }
                                                                            alt={
                                                                                team.name
                                                                            }
                                                                        >
                                                                            {
                                                                                team
                                                                                    .name?.[0]
                                                                            }
                                                                        </Avatar>
                                                                    </Tooltip>
                                                                )
                                                            }
                                                        )}
                                                    </AvatarGroup>
                                                </Box>
                                            )}
                                        </Box>
                                    </>
                                )}
                                {editPermission && (
                                    <>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            className={classes.divider}
                                        />
                                        <ConceptDetailsHeaderOptions
                                            item={props.item}
                                            ownerPermission={true}
                                            editPermission={true}
                                        />
                                    </>
                                )}
                            </>
                        </Hidden>
                    </Box>
                </Box>
                <Divider />
                <Grid container alignItems="center" direction="row">
                    <Grid item xs={12} lg={!!props.item.imageUrl ? 7 : 12}>
                        <CardContent className={classes.cardContent}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                height="100%"
                                flexDirection="column"
                            >
                                <Box pl={0.5}>
                                    <Box className={classes.titleContainer}>
                                        <DetailsField
                                            editable={editPermission}
                                            label={t("title", "Title")}
                                            className="h6"
                                            handleEdit={(value) => {
                                                handleEdit("title", value)
                                            }}
                                            value={props.item.title}
                                            maxLength={
                                                ConceptFieldSettings.Title
                                                    .maxLength
                                            }
                                            multiline={true}
                                        />
                                    </Box>

                                    <Box mt={1}>
                                        <DetailsField
                                            editable={editPermission}
                                            label={t("summary", "Summary")}
                                            multiline={true}
                                            className="body1"
                                            handleEdit={(value) => {
                                                handleEdit("summary", value)
                                            }}
                                            value={props.item.summary}
                                            maxLength={
                                                ConceptFieldSettings.Summary
                                                    .maxLength
                                            }
                                        />
                                    </Box>

                                    {props.item.webLink && (
                                        <Box mt={1} display="flex">
                                            <Link
                                                href={props.item.webLink.url}
                                                target="_blank"
                                                rel="noopener"
                                                style={{ display: "flex" }}
                                            >
                                                <LinkIcon
                                                    style={{
                                                        marginRight: ".5em",
                                                    }}
                                                />{" "}
                                                {props.item.webLink?.title ??
                                                    "Link to Source"}
                                            </Link>
                                        </Box>
                                    )}
                                </Box>
                                {!!props.item.description && (
                                    <Box width="100%">
                                        <ConceptDescription item={item} />
                                    </Box>
                                )}
                            </Box>
                        </CardContent>
                    </Grid>
                    {item?.imageUrl && (
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            className={classes.pictureContainer}
                        >
                            <CardMedia
                                className={
                                    !!item.description
                                        ? classes.largeMedia
                                        : classes.smallMedia
                                }
                                style={
                                    !props.item.imageUrl
                                        ? {
                                              minWidth: 0,
                                              flexGrow: 0,
                                              minHeight: 0,
                                              height: 100,
                                              cursor: editPermission
                                                  ? "pointer"
                                                  : "default",
                                          }
                                        : {
                                              cursor: "pointer",
                                          }
                                }
                                onClick={() => setOpen(true)}
                                image={props.item.imageUrl}
                            />
                        </Grid>
                    )}
                </Grid>

                <Divider />
                <Box className={classes.bottomRow}>
                    <Box
                        flexBasis="50%"
                        display="flex"
                        justifyContent="flex-start"
                        flexShrink={0}
                        flexGrow={1}
                    >
                        <TagToggle
                            concept={item}
                            editPermission={editPermission}
                        />
                    </Box>
                    <Hidden smDown>
                        <Divider
                            orientation="vertical"
                            flexItem
                            className={classes.divider}
                        />
                    </Hidden>
                    <Box
                        flexBasis="50%"
                        display="flex"
                        justifyContent="flex-start"
                        flexShrink={0}
                        flexGrow={1}
                    >
                        <IndustryList
                            concept={item}
                            editPermission={editPermission}
                        />
                    </Box>
                </Box>
            </Card>

            {openPermissions && (
                <ConceptPermissions
                    item={props.item}
                    onClose={() => {
                        setOpenPermissions(false)
                    }}
                />
            )}
            {open && (
                <ImageUploadDialog
                    item={props.item}
                    onClose={() => {
                        setOpen(false)
                    }}
                    editPermission={editPermission}
                />
            )}
        </>
    )
}
