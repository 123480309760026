import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import {
    Box,
    List,
    Typography,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    IconButton,
    DialogTitle,
    DialogContent,
    useMediaQuery,
} from "@material-ui/core"
import { Exercise } from "../../__generated__/types"
import { parseEnum } from "../../util/EnumValues"
import Dialog from "@material-ui/core/Dialog"
import CloseIcon from "@material-ui/icons/Close"
const useStyles = makeStyles((theme: Theme) => ({
    label: {
        color: theme.palette.text.hint,
        fontSize: 12,
    },
    list: {
        overflowY: "auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: 0,
        paddingLeft: theme.spacing(1),
    },

    section: {
        height: "100%",
    },
}))

interface InformationModalProps {
    exercise: Exercise
    onClose: () => void
}
const InformationModal = (props: InformationModalProps) => {
    const { exercise } = props
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    return (
        <Dialog
            open={true}
            onBackdropClick={props.onClose}
            fullWidth={!mobile}
            fullScreen={mobile}
        >
            <DialogTitle>
                Exercise Information
                <IconButton
                    style={{ position: "absolute", right: 0, top: 0 }}
                    onClick={() => props.onClose()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box p={1.5} flexBasis="25%" overflow="auto">
                    <Box className={classes.section}>
                        <Box p={1}>
                            <Box className={classes.label}>Title</Box>
                            <Typography variant="body1">
                                {exercise.title ?? ""}
                            </Typography>
                        </Box>
                        <Box p={1}>
                            <Box className={classes.label}>Status</Box>
                            <Typography variant="body1">
                                {exercise.status
                                    ? parseEnum(exercise.status)
                                    : "Not Started"}
                            </Typography>
                        </Box>
                        <Box p={1}>
                            <Box className={classes.label}>Description</Box>
                            <Typography variant="body1">
                                {exercise.description ?? ""}
                            </Typography>
                        </Box>
                        <Box p={1}>
                            <Box className={classes.label}>Instructions</Box>
                            <Typography variant="body1">
                                {exercise.type?.instructions ?? ""}
                            </Typography>
                        </Box>
                        <Box p={1}>
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                Facilitators
                            </Typography>
                            <List className={classes.list}>
                                {exercise.facilitators.length < 1 && (
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        style={{
                                            padding: "6px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        No facilitators selected...
                                    </Typography>
                                )}
                                {exercise.facilitators.map((item) => {
                                    return (
                                        <Box key={item.userId}>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        src={item.imageUrl}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        item.lastName +
                                                        ", " +
                                                        item.firstName
                                                    }
                                                />
                                            </ListItem>
                                        </Box>
                                    )
                                })}
                            </List>
                        </Box>
                        <Box p={1}>
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                Participants
                            </Typography>
                            <List className={classes.list}>
                                {exercise.participants.length < 1 && (
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        style={{
                                            padding: "6px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        No participants selected...
                                    </Typography>
                                )}
                                {exercise.participants.map((item) => {
                                    return (
                                        <Box key={item.userId}>
                                            <ListItem disableGutters>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        src={item.imageUrl}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        item.lastName +
                                                        ", " +
                                                        item.firstName
                                                    }
                                                />
                                            </ListItem>
                                        </Box>
                                    )
                                })}
                            </List>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default InformationModal
