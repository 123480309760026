import React, { useState } from "react"
import {
    Box,
    CardContent,
    CardActions,
    Tooltip,
    Typography,
    CardMedia,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { useQuery } from "@apollo/client"
import { GET_EXERCISE_BY_ID } from "./graphql"
import {
    GetExerciseByIdQuery,
    GetExerciseByIdQueryVariables,
} from "./__generated__/graphql"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting"
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite"
import { Loading } from "../Loading"
import ExerciseSubmissionResults from "./ExerciseSubmissionResults"
import ExerciseSetup from "./ExerciseSetup"
import ExerciseConceptRanking from "./ExerciseConceptRanking"
import ExerciseConceptScoring from "./scoring/ExerciseConceptScoring"
import ExerciseDialogWrapper from "./ExerciseDialogWrapper"
import { useGlobalStyles } from "../../styles/globalStyles"
import { ExerciseCategory, ExerciseStatus } from "../../__generated__/types"
const useStyles = makeStyles((theme: Theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    cardContent: {
        flexGrow: 1,
    },
    flex: {
        display: "flex",
        padding: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    media: {
        width: "100%",
        height: "100%",
    },
}))

const ExerciseCard = (props: { exerciseId: string }) => {
    const classes = useStyles()
    const { data, loading } = useQuery<
        GetExerciseByIdQuery,
        GetExerciseByIdQueryVariables
    >(GET_EXERCISE_BY_ID, {
        variables: {
            exerciseId: props.exerciseId,
        },
    })
    const globalClasses = useGlobalStyles()
    const [setupOpen, setSetupOpen] = useState(false)
    const [resultsOpen, setResultsOpen] = useState(false)
    const [exerciseOpen, setExerciseOpen] = useState(false)

    const exercise = data?.Exercise?.[0] ?? null
    const image = exercise?.imageUrl || exercise?.type?.imageUrl
    if (loading) {
        return <Loading size={75} hideQuote={true} />
    }
    if (data?.Exercise.length === 0) {
        return (
            <Box p={2} textAlign="center">
                The selected exercise was deleted...
            </Box>
        )
    }
    return (
        <Box display="flex" flexDirection="column" height="100%">
            {image && (
                <CardMedia
                    image={image}
                    title="text"
                    className={classes.media}
                />
            )}
            <Box flexGrow={"1"}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {exercise?.title}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                    >
                        {exercise?.type?.title}
                    </Typography>
                    <Typography variant="body1">
                        {exercise?.description}
                    </Typography>
                </CardContent>
            </Box>

            <CardActions>
                <Tooltip title="View exercise setup">
                    <IconButton
                        onClick={() => {
                            setSetupOpen(true)
                        }}
                    >
                        <PermDataSettingIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="View past results">
                    <IconButton
                        onClick={() => {
                            setResultsOpen(true)
                        }}
                    >
                        <AssignmentTurnedInIcon
                            className={globalClasses.labelStylesIcon}
                        />
                        <span className={globalClasses.count}>
                            {
                                exercise?.submissions?.filter(
                                    (sub) =>
                                        sub.status === ExerciseStatus.COMPLETE
                                )?.length
                            }
                        </span>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Complete exercise">
                    <IconButton
                        style={{
                            marginLeft: "auto",
                        }}
                        onClick={() => {
                            setExerciseOpen(true)
                        }}
                    >
                        <PlayCircleFilledWhiteIcon color="primary" />
                    </IconButton>
                </Tooltip>
            </CardActions>
            {(exerciseOpen || resultsOpen) && (
                <ExerciseDialogWrapper>
                    {exerciseOpen &&
                        exercise.type.category === ExerciseCategory.RANKING && (
                            <ExerciseConceptRanking
                                onClose={() => setExerciseOpen(false)}
                                exercise={exercise}
                                viewResults={() => {
                                    setExerciseOpen(false)
                                    setResultsOpen(true)
                                }}
                            />
                        )}
                    {exerciseOpen &&
                        exercise.type.category === ExerciseCategory.SCORING && (
                            <ExerciseConceptScoring
                                onClose={() => setExerciseOpen(false)}
                                exercise={exercise}
                                viewResults={() => {
                                    setExerciseOpen(false)
                                    setResultsOpen(true)
                                }}
                            />
                        )}
                    {resultsOpen && (
                        <ExerciseSubmissionResults
                            exercise={exercise}
                            onClose={() => setResultsOpen(false)}
                        />
                    )}
                </ExerciseDialogWrapper>
            )}
            {setupOpen && (
                <ExerciseSetup
                    onClose={() => setSetupOpen(false)}
                    exerciseId={exercise?.id}
                />
            )}
        </Box>
    )
}

export default ExerciseCard
