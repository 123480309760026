import React from "react"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import { ListItemIcon, ListItemText } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { Concept } from "../../__generated__/types"
import { useGlobalStyles } from "../../styles/globalStyles"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
const useUnrankedStyles = makeStyles(
    ({ shape, spacing, palette, breakpoints, typography }) => ({
        card: {
            borderRadius: shape.borderRadius,
            height: "100%",
            display: "flex",
            flexDirection: "row",
            overflowY: "auto",
        },
        mainCardContent: {
            width: "100%",
            display: "flex",
        },
        action: {
            position: "absolute",
            right: 0,
            top: 0,
        },
        cardActionArea: {
            padding: spacing(1.5),
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
        },
        cardActions: {
            justifyContent: "space-between",
            display: "flex",
            padding: `${spacing(1)}px 0 0 0`,
            width: "100%",
            flexGrow: 0,
            flexWrap: "wrap",
        },
        media: {
            width: "100%",
            [breakpoints.down("sm")]: {
                minWidth: "100px",
                minHeight: "100px",
            },
            minWidth: "150px",
            minHeight: "150px",
            flexShrink: 0,
            borderRadius: shape.borderRadius,
            marginLeft: "auto",
            margin: spacing(1, 0),
        },
        content: {
            flexGrow: 1,
            padding: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingBottom: "0 !important",
        },
        heading: {
            [breakpoints.down("sm")]: {
                fontSize: typography.body1.fontSize,
                letterSpacing: typography.body1.letterSpacing,
            },
            [breakpoints.up("sm")]: {
                fontSize: typography.h6.fontSize,
                letterSpacing: typography.h6.letterSpacing,
            },
            fontWeight: typography.fontWeightMedium,
            display: "flex",
            alignItems: "center",
            margin: spacing(0.5, 0),
        },
        subtitle: {
            fontSize: 12,
            color: palette.text.hint,
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
        },
        summary: {
            fontSize: typography.body2.fontSize,
            color: palette.text.secondary,
            flexGrow: 1,
            marginTop: "0.5em",
        },
        typeContainer: {
            color: palette.text.hint,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        typeIcon: {
            color: palette.text.hint,
            width: "0.75em",
            marginRight: spacing(0.5),
        },
        avatar: {
            height: spacing(3),
            width: spacing(3),
            margin: spacing(0, 0.75),
            marginLeft: 0,
        },
        flexContainer: {
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
        },
        noWrapTextMobile: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        contextMenuItem: {
            fontSize: typography.body2.fontSize,
        },
        contextMenuIcon: {
            fontSize: "1em",
            marginRight: spacing(1),
        },
    })
)
const useSortableStyles = makeStyles(
    ({ shape, spacing, palette, breakpoints, typography }) => ({
        card: {
            borderRadius: shape.borderRadius,
            height: "100%",
            display: "flex",
            flexDirection: "row",
            overflowY: "auto",
        },
        mainCardContent: {
            width: "100%",
            display: "flex",
        },
        action: {
            position: "absolute",
            right: 0,
            top: 0,
            height: "100%",
        },
        cardActionArea: {
            padding: spacing(1.5),
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
        },
        cardActions: {
            justifyContent: "space-between",
            display: "flex",
            padding: `${spacing(1)}px 0 0 0`,
            width: "100%",
            flexGrow: 0,
            flexWrap: "wrap",
        },
        media: {
            width: "75px",
            height: "75px",
            flexShrink: 0,
            borderRadius: shape.borderRadius,
            margin: spacing(1),
            marginRight: spacing(3),
        },
        content: {
            flexGrow: 1,
            padding: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingBottom: "0 !important",
            alignItems: "center",
        },
        heading: {
            fontSize: typography.body1.fontSize,
            fontWeight: typography.fontWeightMedium,
            letterSpacing: typography.body1.letterSpacing,
            display: "flex",
            alignItems: "center",
            margin: spacing(0.5, 0),
        },
        subtitle: {
            fontSize: 12,
            color: palette.text.hint,
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
        },
        summary: {
            fontSize: typography.body2.fontSize,
            color: palette.text.secondary,
            flexGrow: 1,
            margin: 0,
        },
        typeContainer: {
            color: palette.text.hint,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        typeIcon: {
            color: palette.text.hint,
            width: "0.75em",
            marginRight: spacing(0.5),
        },
        avatar: {
            height: spacing(3),
            width: spacing(3),
            margin: spacing(0, 0.75),
            marginLeft: 0,
        },
        flexContainer: {
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
            alignItems: "center",
        },
        noWrapTextMobile: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        contextMenuItem: {
            fontSize: typography.body2.fontSize,
        },
        contextMenuIcon: {
            fontSize: "1em",
            marginRight: spacing(1),
        },
    })
)
interface ConceptExerciseCardProps {
    item: Concept
    rank?: number
    disableActions?: boolean
    disablePreview?: boolean
    onFocus?: () => void
    moveToTop?: () => void
    moveToBottom?: () => void
    remove?: () => void
}

const ConceptExerciseCard = (props: ConceptExerciseCardProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const gridClasses = useUnrankedStyles()
    const sortableClasses = useSortableStyles()
    const globalClasses = useGlobalStyles()
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const ranked = Boolean(!!props.rank)
    const classes = ranked ? sortableClasses : gridClasses
    return (
        <Card className={classes.card} elevation={0}>
            {ranked && (
                <Box padding={1.5} style={{ backdropFilter: "contrast(0.9)" }}>
                    {ranked && props.rank}.
                </Box>
            )}
            <Box className={classes.mainCardContent}>
                <Box className={classes.cardActionArea}>
                    <CardContent
                        className={classes.content}
                        style={!props.item.imageUrl ? { paddingTop: 0 } : {}}
                    >
                        <Box
                            className={classes.flexContainer}
                            flexDirection={ranked ? "row" : "column"}
                        >
                            <Box
                                paddingRight={0}
                                display="flex"
                                flexDirection="column"
                                flexGrow={1}
                            >
                                <Box className={classes.typeContainer}>
                                    {!!props.item.rootCategory && (
                                        <>
                                            {props.item.rootCategory.name}{" "}
                                            {!!props.item.category &&
                                                !props.item.category?.isRoot &&
                                                " / " +
                                                    props.item.category?.name}
                                        </>
                                    )}
                                    {!props.disableActions && (
                                        <>
                                            <IconButton
                                                className={classes.action}
                                                size="small"
                                                onClick={handleClick}
                                            >
                                                {ranked ? (
                                                    <MoreVertIcon />
                                                ) : (
                                                    <AddIcon />
                                                )}
                                            </IconButton>

                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem
                                                    component="li"
                                                    onClick={() => {
                                                        props.moveToTop()
                                                        handleClose()
                                                    }}
                                                    divider
                                                >
                                                    <ListItemIcon
                                                        className={
                                                            globalClasses.smallMenuIcon
                                                        }
                                                    >
                                                        <ArrowUpwardIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primaryTypographyProps={{
                                                            className:
                                                                globalClasses.smallMenuListItemText,
                                                        }}
                                                        primary={
                                                            ranked
                                                                ? "Move to top"
                                                                : "Add to top"
                                                        }
                                                    />
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        props.moveToBottom()
                                                        handleClose()
                                                    }}
                                                    divider={ranked}
                                                    component="li"
                                                >
                                                    <ListItemIcon
                                                        className={
                                                            globalClasses.smallMenuIcon
                                                        }
                                                    >
                                                        <ArrowDownwardIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primaryTypographyProps={{
                                                            className:
                                                                globalClasses.smallMenuListItemText,
                                                        }}
                                                        primary={
                                                            ranked
                                                                ? "Move to bottom"
                                                                : "Add to bottom"
                                                        }
                                                    />
                                                </MenuItem>
                                                {ranked && (
                                                    <MenuItem
                                                        component="li"
                                                        onClick={() => {
                                                            props.remove()
                                                            handleClose()
                                                        }}
                                                    >
                                                        <ListItemIcon
                                                            className={
                                                                globalClasses.smallMenuIcon
                                                            }
                                                        >
                                                            <RemoveCircleIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primaryTypographyProps={{
                                                                className:
                                                                    globalClasses.smallMenuListItemText,
                                                            }}
                                                            primary="Remove"
                                                        />
                                                    </MenuItem>
                                                )}
                                            </Menu>
                                        </>
                                    )}
                                </Box>
                                <Box
                                    className={classes.flexContainer}
                                    flexDirection={ranked ? "row" : "column"}
                                >
                                    <Box>
                                        <Box
                                            className={classes.heading}
                                            style={{
                                                cursor: props.disablePreview
                                                    ? "default"
                                                    : "pointer",
                                            }}
                                            onClick={() => {
                                                if (
                                                    props.onFocus &&
                                                    !props.disablePreview
                                                ) {
                                                    props.onFocus()
                                                }
                                            }}
                                        >
                                            {props.item.title}
                                        </Box>
                                        <Box
                                            display="flex"
                                            width="100%"
                                            flexDirection="column"
                                            flexGrow={1}
                                        >
                                            {props.item.summary && (
                                                <p className={classes.summary}>
                                                    {props.item.summary}
                                                </p>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {props.item.imageUrl && (
                            <CardMedia
                                className={classes.media}
                                image={props.item.imageUrl}
                            />
                        )}
                    </CardContent>
                </Box>
            </Box>
        </Card>
    )
}
export default React.memo(ConceptExerciseCard)
