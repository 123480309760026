import { FilterItem } from "./types"

export const getNumFiltersEnabled = (filters: FilterItem[]) => {
    if (filters) {
        return filters.filter((x) => {
            return x.value && x.value.length > 0
        }).length
    } else {
        return 0
    }
}
