import React, { useState, useEffect, useCallback } from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import { Box, Grid, IconButton } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import { CONNECTION_WIDGET_QUERY } from "../graphql/queries"
import {
    ConnectionWidgetQueryQuery,
    ConnectionWidgetQueryQueryVariables,
} from "../graphql/__generated__/queries"
import { Loading } from "./Loading"
import { _ConceptFilter, _ConceptOrdering } from "../__generated__/types"
import ConceptCard from "./ConceptCard"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import Fade from "@material-ui/core/Fade"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            overflow: "auto",
        },
        inline: {
            display: "inline",
        },
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
        searchContainer: {
            marginTop: theme.spacing(1),
            fontSize: "12px",
        },
        linkCount: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.secondary,
        },
        toolIcon: {
            height: "0.8em",
            width: "0.8em",
        },
        loaderContainer: {
            textAlign: "center",
        },
        greyed: {
            fontSize: 12,
            color: theme.palette.text.hint,
        },
        conceptCard: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            height: "100%",
        },
        conceptCardContainer: {
            padding: theme.spacing(0, 1),
            marginTop: theme.spacing(1),
            height: "100%",

            overflowY: "auto",
        },
    })
)

interface ConceptGalleryProps {
    conceptId: string
    editPermission: boolean
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
}

const PAGE_SIZE = 3
export default function ConceptGallery(props: ConceptGalleryProps) {
    const classes = useStyles()

    const [filter, setFilter] = useState<_ConceptFilter>({})
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [offset, setOffset] = useState(0)
    const { loading, error, data, fetchMore } = useQuery<
        ConnectionWidgetQueryQuery,
        ConnectionWidgetQueryQueryVariables
    >(CONNECTION_WIDGET_QUERY, {
        variables: {
            offset: 0,
            first: limit,
            connectionsFilter: filter,
            orderBy: [props.orderBy],
        },
    })
    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            setOffset(0)
        }
    }, [props.filter, filter])
    const listData = data?.Concept ?? []

    const handleNext = async () => {
        setOffset((prev) => prev + PAGE_SIZE)

        await onLoadMore()
    }
    const handleBack = () => {
        setOffset((prev) => prev - PAGE_SIZE)
    }
    const onLoadMore = useCallback(async () => {
        if (!fetchMore) return
        await fetchMore({
            variables: {
                offset: listData.length,
            },
        })

        setLimit(listData.length + PAGE_SIZE)
    }, [fetchMore, listData.length])
    if (loading) {
        return <Loading size={50} hideQuote={true} />
    } else if (error) {
        return <div>{error.message}</div>
    }
    return (
        <div className={classes.container}>
            <Box display="flex" height="100%">
                <IconButton onClick={handleBack} disabled={offset === 0}>
                    <ChevronLeftIcon />
                </IconButton>
                <Grid
                    container
                    spacing={2}
                    className={classes.conceptCardContainer}
                >
                    {listData.map((item, index) => {
                        if (index >= offset && index < offset + PAGE_SIZE) {
                            return (
                                <Fade in={true} key={item.id + "_fade"}>
                                    <Grid item xs={4} key={item.id}>
                                        <Box className={classes.conceptCard}>
                                            <ConceptCard
                                                item={item}
                                                grid={true}
                                            />
                                        </Box>
                                    </Grid>
                                </Fade>
                            )
                        } else {
                            return null
                        }
                    })}
                </Grid>
                <IconButton
                    onClick={handleNext}
                    disabled={listData.length < offset + PAGE_SIZE}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>
        </div>
    )
}
