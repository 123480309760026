import { useEffect, useState } from "react"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import {
    Box,
    List,
    Typography,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    DialogActions,
    Button,
    Divider,
    DialogTitle,
    DialogContent,
    TextField,
    useMediaQuery,
    IconButton,
    Slide,
    Tooltip,
    CircularProgress,
} from "@material-ui/core"
import { useAuth } from "../../providers/AuthProvider"
import { Exercise, Concept, ExerciseStatus } from "../../__generated__/types"
import { parseEnum } from "../../util/EnumValues"
import ConceptExerciseCard from "./ConceptExerciseCard"
import Dialog from "@material-ui/core/Dialog"
import RichTextEditor from "../RichTextEditor"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {
    ADD_EXERCISE_SUBMISSION_EXERCISE,
    ADD_EXERCISE_SUBMISSION_USER,
    CREATE_EXERCISE_SUBMISSION,
} from "./graphql"
import {
    CreateExerciseSubmissionMutation,
    CreateExerciseSubmissionMutationVariables,
    AddExerciseSubmissionExerciseMutation,
    AddExerciseSubmissionExerciseMutationVariables,
    AddExerciseSubmissionUserMutation,
    AddExerciseSubmissionUserMutationVariables,
} from "./__generated__/graphql"
import { useMutation } from "@apollo/client"
import InfoIcon from "@material-ui/icons/Info"
import DoneAllIcon from "@material-ui/icons/DoneAll"
import { CountdownTimer } from "./Timer"
import ClearIcon from "@material-ui/icons/Clear"
import InformationModal from "./InformationModal"

const useStyles = makeStyles((theme: Theme) => ({
    label: {
        color: theme.palette.text.hint,
        fontSize: 12,
    },
    root: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
    },
    list: {
        overflowY: "auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: 0,
        paddingLeft: theme.spacing(1),
    },
    exerciseContainer: {
        display: "flex",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
        },
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
        overflow: "hidden",
    },
    section: {
        height: "100%",
    },
    unrankedConceptsContainer: {
        [theme.breakpoints.down("sm")]: {
            overflowY: "hidden",
            overflowX: "auto",
        },
        [theme.breakpoints.up("sm")]: {
            overflowX: "hidden",
            overflowY: "auto",
            flexWrap: "wrap",
        },
        display: "flex",
    },
    rankedListContainer: {
        [theme.breakpoints.down("sm")]: {
            overflow: "hidden",
        },
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    previewAvatar: {
        [theme.breakpoints.down("sm")]: {
            minWidth: "150px",
            minHeight: "150px",
        },
        minWidth: "200px",
        minHeight: "200px",
        margin: theme.spacing(1),
    },
    sortableContainer: {
        overflowY: "auto",
    },
    toolbar: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
    },
}))

interface ExerciseConceptRankingProps {
    exercise: Exercise
    viewResults: () => void
    onClose: () => void
}
interface RankedExerciseList {
    concepts: any[]
    conceptIds: string[]
}
export default function ExerciseConceptRanking(
    props: ExerciseConceptRankingProps
) {
    const { exercise } = props
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [openInfoModal, setOpenInfoModal] = useState(false)
    const [focusedConcept, setFocusedConcept] = useState(null)
    const [reviewOpen, setReviewOpen] = useState(false)
    const classes = useStyles()
    const [slideOpen, setSlideOpen] = useState(true)
    const [locked, setLocked] = useState(false)
    const [rankedList, setRankedList] = useState<RankedExerciseList>({
        concepts: [],
        conceptIds: [],
    })
    const [unrankedConcepts, setUnrankedConcepts] = useState<Concept[]>([])
    useEffect(() => {
        if (exercise) {
            if (!!exercise.includedChecklist?.items) {
                let concepts = [
                    ...exercise.includedChecklist.items?.map((item) => {
                        if (item.concept) {
                            return item.concept
                        } else {
                            return null
                        }
                    }),
                ].filter((x) => {
                    return rankedList.conceptIds.indexOf(x.id) === -1
                })
                concepts.sort(function (a, b) {
                    var itemA = a.title.toLowerCase()
                    var itemB = b.title.toLowerCase()
                    if (itemA < itemB) return -1
                    if (itemA > itemB) return 1
                    return 0
                })
                setUnrankedConcepts(concepts)
            } else if (exercise.includedConcepts) {
                let concepts = [...exercise.includedConcepts].filter((x) => {
                    return rankedList.conceptIds.indexOf(x.id) === -1
                })
                concepts.sort(function (a, b) {
                    var itemA = a.title.toLowerCase()
                    var itemB = b.title.toLowerCase()
                    if (itemA < itemB) return -1
                    if (itemA > itemB) return 1
                    return 0
                })
                setUnrankedConcepts(concepts)
            }
        }
    }, [exercise, rankedList.conceptIds, setRankedList])
    useEffect(() => {
        if (!slideOpen && !mobile) {
            setSlideOpen(true)
        }
    }, [mobile, slideOpen]) // put in here solely for demo purpose lolssssss
    return (
        !!props.exercise && (
            <Box className={classes.root}>
                <Box>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        p={1}
                        className={classes.toolbar}
                    >
                        {props.exercise.duration && (
                            <CountdownTimer
                                duration={props.exercise.duration}
                            />
                        )}

                        <Divider orientation="vertical" flexItem />

                        <Tooltip title="View exercise information">
                            <IconButton onClick={() => setOpenInfoModal(true)}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Finish exercise">
                            <IconButton
                                onClick={() => {
                                    setReviewOpen(true)
                                }}
                            >
                                <DoneAllIcon />
                            </IconButton>
                        </Tooltip>
                        <Box height="100%">
                            <Divider orientation="vertical" />
                        </Box>

                        <Tooltip title="Quit exercise">
                            <IconButton
                                onClick={() => {
                                    props.onClose()
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Divider />
                <Box className={classes.exerciseContainer}>
                    <Box
                        flexBasis={slideOpen && !mobile ? "50%" : "0%"}
                        display="flex"
                        flexDirection="column"
                    >
                        {mobile && (
                            <Button
                                onClick={() => {
                                    setSlideOpen(!slideOpen)
                                }}
                                size="small"
                            >
                                {slideOpen ? "Hide" : "Show"} Unranked
                            </Button>
                        )}
                        {!!unrankedConcepts && (
                            <Slide in={slideOpen} direction="up" unmountOnExit>
                                <Box
                                    className={
                                        classes.unrankedConceptsContainer
                                    }
                                >
                                    {unrankedConcepts.map((concept, idx) => {
                                        return (
                                            <Box
                                                key={idx}
                                                p={1.5}
                                                width={mobile ? "100%" : "50%"}
                                                minWidth={"300px"}
                                            >
                                                <ConceptExerciseCard
                                                    onFocus={() => {
                                                        setFocusedConcept(
                                                            concept
                                                        )
                                                    }}
                                                    moveToTop={() => {
                                                        let list: RankedExerciseList = {
                                                            concepts: [
                                                                concept,
                                                                ...rankedList.concepts,
                                                            ],
                                                            conceptIds: [
                                                                concept.id,
                                                                ...rankedList.conceptIds,
                                                            ],
                                                        }

                                                        setRankedList(list)
                                                    }}
                                                    moveToBottom={() => {
                                                        let list: RankedExerciseList = {
                                                            concepts: [
                                                                ...rankedList.concepts,
                                                                concept,
                                                            ],
                                                            conceptIds: [
                                                                ...rankedList.conceptIds,
                                                                concept.id,
                                                            ],
                                                        }

                                                        setRankedList(list)
                                                    }}
                                                    item={concept}
                                                />
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Slide>
                        )}
                    </Box>
                    <Divider orientation={mobile ? "horizontal" : "vertical"} />
                    <Box
                        className={classes.rankedListContainer}
                        flexBasis={
                            !slideOpen && mobile
                                ? "100%"
                                : mobile
                                ? "50%"
                                : "25%"
                        }
                    >
                        <Box className={classes.sortableContainer}>
                            <SortableRankedList
                                list={rankedList}
                                onFocus={setFocusedConcept}
                                setList={setRankedList}
                                exerciseId={exercise?.id}
                                locked={locked}
                            />
                        </Box>
                    </Box>
                    {focusedConcept && (
                        <ExerciseConceptPreview
                            onClose={() => setFocusedConcept(null)}
                            concept={focusedConcept}
                        />
                    )}
                    {openInfoModal && (
                        <InformationModal
                            exercise={props.exercise}
                            onClose={() => setOpenInfoModal(false)}
                        />
                    )}
                    {reviewOpen && (
                        <SubmissionReview
                            onClose={() => {
                                setReviewOpen(false)
                            }}
                            list={rankedList}
                            exerciseId={exercise.id}
                            viewResults={props.viewResults}
                        />
                    )}
                </Box>
            </Box>
        )
    )
}

interface InfoSectionProps {
    exercise: Exercise
}
export const InfoSection = (props: InfoSectionProps) => {
    const { exercise } = props
    const classes = useStyles()
    return (
        <>
            <Box p={1.5} flexBasis="25%" overflow="auto">
                <Box className={classes.section}>
                    <Box p={1}>
                        <Box className={classes.label}>Title</Box>
                        <Typography variant="body1">
                            {exercise.title ?? ""}
                        </Typography>
                    </Box>
                    <Box p={1}>
                        <Box className={classes.label}>Status</Box>
                        <Typography variant="body1">
                            {exercise.status
                                ? parseEnum(exercise.status)
                                : "Not Started"}
                        </Typography>
                    </Box>
                    <Box p={1}>
                        <Box className={classes.label}>Description</Box>
                        <Typography variant="body1">
                            {exercise.description ?? ""}
                        </Typography>
                    </Box>
                    <Box p={1}>
                        <Box className={classes.label}>Instructions</Box>
                        <Typography variant="body1">
                            {exercise.type?.instructions ?? ""}
                        </Typography>
                    </Box>
                    <Box p={1}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Facilitators
                        </Typography>
                        <List className={classes.list}>
                            {exercise.facilitators.length < 1 && (
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    style={{
                                        padding: "6px",
                                        cursor: "pointer",
                                    }}
                                >
                                    No facilitators selected...
                                </Typography>
                            )}
                            {exercise.facilitators.map((item) => {
                                return (
                                    <Box key={item.userId}>
                                        <ListItem disableGutters>
                                            <ListItemAvatar>
                                                <Avatar src={item.imageUrl} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    item.lastName +
                                                    ", " +
                                                    item.firstName
                                                }
                                            />
                                        </ListItem>
                                    </Box>
                                )
                            })}
                        </List>
                    </Box>
                    <Box p={1}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Participants
                        </Typography>
                        <List className={classes.list}>
                            {exercise.participants.length < 1 && (
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    style={{
                                        padding: "6px",
                                        cursor: "pointer",
                                    }}
                                >
                                    No participants selected...
                                </Typography>
                            )}
                            {exercise.participants.map((item) => {
                                return (
                                    <Box key={item.userId}>
                                        <ListItem disableGutters>
                                            <ListItemAvatar>
                                                <Avatar src={item.imageUrl} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    item.lastName +
                                                    ", " +
                                                    item.firstName
                                                }
                                            />
                                        </ListItem>
                                    </Box>
                                )
                            })}
                        </List>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export interface ExerciseConceptPreviewProps {
    onClose: () => void
    concept: Concept
}
export const ExerciseConceptPreview = (props: ExerciseConceptPreviewProps) => {
    const theme: Theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const classes = useStyles()
    return (
        <Dialog
            open={true}
            fullWidth={!mobile}
            fullScreen={mobile}
            PaperProps={{
                style: {
                    height: "100%",
                },
            }}
            onBackdropClick={() => props.onClose()}
        >
            <Box
                display="flex"
                alignItems="center"
                padding={2}
                flexDirection={mobile ? "column-reverse" : "row"}
            >
                <Box>
                    <Box style={{ color: theme.palette.text.hint }}>
                        <Typography variant={mobile ? "body2" : "body1"}>
                            {props.concept.type}{" "}
                            {props.concept.category &&
                                "/ " + props.concept.category.name}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant={mobile ? "body1" : "h6"}>
                            {props.concept.title}
                        </Typography>
                    </Box>
                    <Box style={{ color: theme.palette.text.secondary }}>
                        <Typography variant={mobile ? "body2" : "body1"}>
                            {props.concept.summary}
                        </Typography>
                    </Box>
                </Box>
                {props.concept.imageUrl && (
                    <Avatar
                        src={props.concept.imageUrl}
                        variant="rounded"
                        className={classes.previewAvatar}
                    />
                )}
            </Box>

            <RichTextEditor
                initialValue={props.concept.description}
                isReadOnly={true}
            />

            <DialogActions>
                <Button
                    onClick={() => {
                        props.onClose()
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface SortableRankedListProps {
    list: RankedExerciseList
    onFocus: (concept: any) => void
    setList: (list: RankedExerciseList) => void
    exerciseId: string
    locked: boolean
}

const SortableRankedList = (props: SortableRankedListProps) => {
    const theme = useTheme()
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }
    const setListData = (ConceptList) => {
        let orderedConcepts = []
        let unorderedConcepts = []
        let conceptsById = {}
        const { concepts, conceptIds } = ConceptList
        const ids = conceptIds || concepts.map((c) => c.id)
        concepts.forEach((c) => {
            conceptsById[c.id] = c
            let conceptIdx = ids.indexOf(c.id)
            if (conceptIdx === -1) {
                unorderedConcepts.push(c)
            } else {
                orderedConcepts[ids.indexOf(c.id)] = c
            }
        })
        let allConcepts = [...orderedConcepts, ...unorderedConcepts].filter(
            (c) => !!c
        )
        props.setList({
            ...ConceptList,
            concepts: allConcepts,
        })
    }
    const removeConcept = (sourceIndex, draggableId) => {
        let position = sourceIndex
        let newArray = Array.from(props.list.concepts)
        newArray.splice(position, 1)
        const newList = {
            conceptIds: newArray.map((c) => c.id),
            concepts: newArray,
        }
        setListData({
            ...props.list,
            ...newList,
        })
    }

    const move = (source, destination) => {
        if (
            source !== destination &&
            Math.sign(destination) !== -1 &&
            props.list.concepts.length - destination >= 1
        ) {
            const conceptArray = reorder(
                props.list.concepts,
                source,
                destination
            )
            setListData({
                ...props.list,
                conceptIds: conceptArray.map((c: any) => c.id),
            })
        }
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return removeConcept(result.source.index, result.draggableId)
        }
        const conceptArray = reorder(
            props.list.concepts,
            result.source.index,
            result.destination.index
        )
        setListData({
            ...props.list,
            conceptIds: conceptArray.map((c: any) => c.id),
        })
    }
    return (
        <>
            {props.list && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={props.exerciseId}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {props.list?.concepts?.map((concept, index) => (
                                    <Draggable
                                        key={concept.id}
                                        isDragDisabled={props.locked}
                                        draggableId={concept.id}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Box p={1}>
                                                    <ConceptExerciseCard
                                                        item={concept}
                                                        onFocus={() => {
                                                            props.onFocus(
                                                                concept
                                                            )
                                                        }}
                                                        moveToTop={() => {
                                                            move(index, 0)
                                                        }}
                                                        moveToBottom={() => {
                                                            move(
                                                                index,
                                                                props.list
                                                                    .concepts
                                                                    .length - 1
                                                            )
                                                        }}
                                                        remove={() => {
                                                            removeConcept(
                                                                index,
                                                                concept.id
                                                            )
                                                        }}
                                                        rank={index + 1}
                                                    />
                                                </Box>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </>
    )
}

interface SubmissionReviewProps {
    onClose: () => void
    list: RankedExerciseList
    exerciseId: string
    viewResults: () => void
}
const SubmissionReview = (props: SubmissionReviewProps) => {
    const [saving, setSaving] = useState(false)
    const { currentUser } = useAuth()
    const [createSubmission] = useMutation<
        CreateExerciseSubmissionMutation,
        CreateExerciseSubmissionMutationVariables
    >(CREATE_EXERCISE_SUBMISSION)
    const [addUserToSubmission] = useMutation<
        AddExerciseSubmissionUserMutation,
        AddExerciseSubmissionUserMutationVariables
    >(ADD_EXERCISE_SUBMISSION_USER)
    const [addExerciseToSubmission] = useMutation<
        AddExerciseSubmissionExerciseMutation,
        AddExerciseSubmissionExerciseMutationVariables
    >(ADD_EXERCISE_SUBMISSION_EXERCISE)
    const [notes, setNotes] = useState("")
    const handleSaving = async () => {
        setSaving(true)
        const submissionCreationData = await createSubmission({
            variables: {
                notes: notes,
                conceptIds: props.list.conceptIds,
                status: ExerciseStatus.COMPLETE,
            },
        })
        const addExerciseData = await addExerciseToSubmission({
            variables: {
                submissionId:
                    submissionCreationData.data.CreateExerciseSubmission.id,
                exerciseId: props.exerciseId,
            },
        })
        const addUserData = await addUserToSubmission({
            variables: {
                userId: currentUser.userId,
                submissionId:
                    submissionCreationData.data.CreateExerciseSubmission.id,
            },
        })
        const final = Promise.all([
            submissionCreationData,
            addExerciseData,
            addUserData,
        ])
        if (final) {
            props.viewResults()
        }
    }
    return (
        <Dialog open={true} fullWidth>
            <DialogTitle>Exercise Review</DialogTitle>
            <DialogContent dividers style={{ padding: 0 }}>
                {props.list?.concepts?.map((item, idx) => {
                    return (
                        <Box key={idx}>
                            <ConceptExerciseCard
                                item={item}
                                rank={idx + 1}
                                disableActions={true}
                                disablePreview={true}
                            />
                            <Divider />
                        </Box>
                    )
                })}
            </DialogContent>
            <Box p={2}>
                <Box p={2}>
                    <TextField
                        fullWidth
                        value={notes}
                        multiline
                        variant="outlined"
                        rows={4}
                        onChange={(e) => {
                            setNotes(e.target.value)
                        }}
                        label="Submission Notes"
                    />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                    <Button onClick={() => props.onClose()} disabled={saving}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaving}
                        disabled={saving}
                    >
                        {saving ? (
                            <CircularProgress size={25} disableShrink />
                        ) : (
                            "Submit"
                        )}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}
