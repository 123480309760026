import {
    AutoformatRule,
    ELEMENT_BLOCKQUOTE,
    ELEMENT_CODE_BLOCK,
    ELEMENT_DEFAULT,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    getPlatePluginType,
    insertEmptyCodeBlock,
    SPEditor,
} from "@udecode/plate"
import { preFormat } from "./autoformatUtils"

export const autoformatBlocks: AutoformatRule[] = [
    {
        mode: "block",
        type: ELEMENT_H1,
        match: "# ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H2,
        match: "## ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H3,
        match: "### ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H4,
        match: "#### ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H5,
        match: "##### ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_H6,
        match: "###### ",
        preFormat,
    },
    {
        mode: "block",
        type: ELEMENT_BLOCKQUOTE,
        match: "> ",
        preFormat,
    },
    // nested blockquote
    // {
    //  mode: 'block',
    //  type: ELEMENT_BLOCKQUOTE,
    //  match: '> >',
    //  preFormat,
    // },
    {
        mode: "block",
        type: ELEMENT_CODE_BLOCK,
        match: "```",
        triggerAtBlockStart: false,
        preFormat,
        format: (editor) => {
            insertEmptyCodeBlock(editor as SPEditor, {
                defaultType: getPlatePluginType(
                    editor as SPEditor,
                    ELEMENT_DEFAULT
                ),
                insertNodesOptions: { select: true },
            })
        },
    },
]
