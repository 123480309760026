import React, { useState, useCallback, useRef } from "react"
import debounce from "p-debounce"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Dialog, Slide, IconButton, Divider } from "@material-ui/core"
import RichTextEditor from "./RichTextEditor"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { UPDATE_CONCEPT } from "../graphql/mutations"
import {
    UpdateConceptMutation,
    UpdateConceptMutationVariables,
} from "../graphql/__generated__/mutations"
import { CONCEPT_BY_ID } from "../graphql/queries"
import { useMutation, useReactiveVar } from "@apollo/client"
import { useAuth } from "../providers/AuthProvider"
import { Concept } from "../__generated__/types"
import { permissionsVar } from "../providers/GlobalState"
import { TransitionProps } from "@material-ui/core/transitions"
import ConceptListItem from "./ConceptListItem"
import ClearIcon from "@material-ui/icons/Clear"
const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        height: "100%",

        overflow: "hidden",
        position: "relative",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
        },
        cursor: "pointer",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    blurOut: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        textAlign: "center",
        margin: 0,
        padding: "30px 0px",
        backgroundImage: `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`,
    },
    rteContainer: {
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        overflow: "hidden",
        height: "100%",
    },
    clear: {
        position: "absolute",
        right: 0,
        top: 0,
    },
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        maxHeight: 350,
        marginTop: theme.spacing(0.5),
    },
}))
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})
interface ConceptDescriptionProps {
    item: Concept
    modal?: boolean
    onClose?: () => void
}
const ConceptDescription = (props: ConceptDescriptionProps) => {
    const { currentUser } = useAuth()
    const classes = useStyles()
    const editPermission = useReactiveVar(permissionsVar)
    const [focused, setFocused] = useState(false)
    const [updateConcept] = useMutation<
        UpdateConceptMutation,
        UpdateConceptMutationVariables
    >(UPDATE_CONCEPT, {
        refetchQueries: [
            {
                query: CONCEPT_BY_ID,
                variables: { id: props.item.id },
            },
        ],
    })
    const ref = useRef(null)
    const { t } = useAwaitTranslation("widgets")
    const onEditValue = debounce(
        useCallback(
            async (value: string) => {
                await updateConcept({
                    variables: {
                        userId: currentUser.userId,
                        concept: {
                            id: props.item.id,
                            description: value,
                        },
                    },
                })
            },
            [updateConcept, currentUser?.userId, props.item.id]
        ),
        1000
    )
    const height = ref?.current?.clientHeight ?? 0

    return (
        <div className={classes.root} ref={ref}>
            <Box
                className={classes.container}
                onClick={() => {
                    setFocused(true)
                }}
            >
                <RichTextEditor
                    editorId={`Idea_Description_${
                        props.item.id
                    }_${JSON.stringify(props.modal)}`}
                    onChange={(text: string) => {
                        onEditValue(text)
                    }}
                    initialValue={props.item.description}
                    isReadOnly={true}
                    disableEffects={true}
                />
                {height > 300 && <Box className={classes.blurOut} />}
            </Box>
            <Dialog
                open={focused || !!props.modal}
                TransitionComponent={Transition}
                fullScreen
                //to maintain editor state
                keepMounted
            >
                <Box display="flex" flexDirection="column" overflow="hidden">
                    <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <ConceptListItem
                            item={props.item}
                            disableClick={true}
                            showSummary={true}
                            hideDivider
                        />
                        <IconButton
                            onClick={() => {
                                if (!!props.modal) {
                                    props.onClose()
                                } else {
                                    setFocused(false)
                                }
                            }}
                            className={classes.clear}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <Divider />
                    <Box className={classes.rteContainer}>
                        <RichTextEditor
                            editorId={`Idea_Description_${
                                props.item.id
                            }_${JSON.stringify(props.modal)}`}
                            onChange={(text: string) => {
                                onEditValue(text)
                            }}
                            alwaysEditing={!!editPermission}
                            initialValue={props.item.description}
                            isReadOnly={!editPermission}
                            disableEffects={true}
                        />
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}

export default ConceptDescription
