import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles, Theme } from "@material-ui/core/styles"
import {
    CREATE_EXERCISE_TYPE,
    GET_EXERCISE_TYPES,
    UPDATE_EXERCISE_TYPE,
    DELETE_EXERCISE_TYPE,
} from "./graphql"
import {
    GetExerciseTypesQuery,
    GetExerciseTypesQueryVariables,
    CreateExerciseTypeMutation,
    CreateExerciseTypeMutationVariables,
    UpdateExerciseTypeMutation,
    UpdateExerciseTypeMutationVariables,
    DeleteExerciseTypeMutation,
    DeleteExerciseTypeMutationVariables,
} from "./__generated__/graphql"
import { Loading } from "../Loading"
import SettingsIcon from "@material-ui/icons/Settings"
import { ExerciseCategory, ExerciseType } from "../../__generated__/types"
import { ExerciseTypeFieldSettings } from "../../util/SystemSettings"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        padding: theme.spacing(1),
    },
    card: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    cardContent: {
        flexGrow: 1,
    },
}))

export default function ExerciseManager() {
    const classes = useStyles()
    const [selectedType, setSelectedType] = useState<ExerciseType>(null)
    const [openTypeForm, setOpenTypeForm] = useState(false)
    const { loading, data } = useQuery<
        GetExerciseTypesQuery,
        GetExerciseTypesQueryVariables
    >(GET_EXERCISE_TYPES)

    const types: ExerciseType[] = data?.ExerciseType ?? []
    if (loading) return <Loading size={150} />

    return (
        <Box className={classes.root}>
            <Box
                display="flex"
                justifyContent="flex-end"
                padding={1.5}
                paddingTop={2}
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpenTypeForm(true)
                    }}
                >
                    Create New
                </Button>
            </Box>
            <Box display="flex" flexWrap="wrap" overflow="auto">
                {types.map((exerciseType) => (
                    <Box m={1.5} key={exerciseType.id} flex="1 1 0px">
                        <Card className={classes.card}>
                            {exerciseType.imageUrl && (
                                <CardMedia
                                    style={{ minHeight: "15em" }}
                                    image={exerciseType?.imageUrl}
                                />
                            )}
                            <CardHeader
                                title={exerciseType.title}
                                subheader={exerciseType?.category}
                            />
                            <CardContent className={classes.cardContent}>
                                {exerciseType?.description}
                            </CardContent>
                            <CardActions>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    flexGrow={1}
                                >
                                    <IconButton
                                        onClick={() => {
                                            setSelectedType(exerciseType)
                                            setOpenTypeForm(true)
                                        }}
                                    >
                                        <SettingsIcon />
                                    </IconButton>
                                </Box>
                            </CardActions>
                        </Card>
                    </Box>
                ))}
                {openTypeForm && (
                    <ExerciseTypeForm
                        item={selectedType}
                        onClose={() => {
                            setSelectedType(null)
                            setOpenTypeForm(false)
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

interface ExerciseTypeFormProps {
    item?: ExerciseType
    onClose: () => void
}
export const ExerciseTypeForm = (props: ExerciseTypeFormProps) => {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [instructions, setInstructions] = useState("")
    const [confirm, setConfirm] = useState(false)
    const [category, setCategory] = useState<ExerciseCategory>(
        ExerciseCategory.RANKING
    )
    const [imageUrl, setImageUrl] = useState("")
    const [deleteExerciseType] = useMutation<
        DeleteExerciseTypeMutation,
        DeleteExerciseTypeMutationVariables
    >(DELETE_EXERCISE_TYPE, {
        update(cache) {
            cache.evict({
                id: cache.identify(props.item),
            })
        },
    })
    const [createExerciseType] = useMutation<
        CreateExerciseTypeMutation,
        CreateExerciseTypeMutationVariables
    >(CREATE_EXERCISE_TYPE, {
        refetchQueries: [
            {
                query: GET_EXERCISE_TYPES,
            },
        ],
    })
    const { t } = useAwaitTranslation("exercises")
    const [updateExerciseType] = useMutation<
        UpdateExerciseTypeMutation,
        UpdateExerciseTypeMutationVariables
    >(UPDATE_EXERCISE_TYPE)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if (props.item) {
            setTitle(props.item.title)
            setDescription(props.item.description)
            setImageUrl(props.item.imageUrl)
            setInstructions(props.item.instructions)
            setCategory(props.item.category)
        }
    }, [props.item])
    const handleDeletion = async () => {
        setConfirm(false)
        setSaving(true)
        await deleteExerciseType({
            variables: {
                id: props.item.id,
            },
        })
        props.onClose()
    }
    const handleSave = async () => {
        setSaving(true)
        const data = {
            title,
            description,
            instructions,
            category,
            imageUrl,
        }
        if (props.item) {
            await updateExerciseType({
                variables: { ...data, id: props.item.id },
            })
        } else {
            await createExerciseType({ variables: data })
        }
        setSaving(false)
        props.onClose()
    }

    return (
        <Dialog open={true} fullWidth>
            {confirm ? (
                <>
                    <DialogContent>
                        Are you sure you want to delete {props.item.title}?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setConfirm(false)
                            }}
                        >
                            {t("no", "No")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDeletion}
                        >
                            {t("yes", "Yes")}
                        </Button>
                    </DialogActions>
                </>
            ) : saving ? (
                <Loading size={50} hideQuote={true} padding={3} />
            ) : (
                <>
                    <DialogTitle>
                        <Box display="flex" flexDirection="column">
                            {t("exerciseTypeForm", "Exercise Type Form")}
                            {imageUrl && (
                                <Box
                                    p={20}
                                    style={{
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundImage: `url(${imageUrl})`,
                                    }}
                                ></Box>
                            )}
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box p={1}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    {t("category", "Category")}
                                </InputLabel>
                                <Select
                                    value={category}
                                    onChange={(
                                        event: React.ChangeEvent<{
                                            value: ExerciseCategory
                                        }>
                                    ) => {
                                        setCategory(event.target?.value)
                                    }}
                                >
                                    <MenuItem value={ExerciseCategory.RANKING}>
                                        {t("ranking", "Ranking")}
                                    </MenuItem>
                                    <MenuItem value={ExerciseCategory.VOTING}>
                                        {t("voting", "Voting")}
                                    </MenuItem>
                                    <MenuItem value={ExerciseCategory.SCORING}>
                                        {t("scoring", "Scoring")}
                                    </MenuItem>
                                    <MenuItem
                                        value={ExerciseCategory.GENERATING}
                                    >
                                        {t("generating", "Generating")}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box p={1}>
                            <TextField
                                style={{ width: "100%" }}
                                value={title}
                                inputProps={{
                                    maxLength:
                                        ExerciseTypeFieldSettings["title"]
                                            .maxLength,
                                }}
                                label={t("title", "Title")}
                                onChange={(event) => {
                                    setTitle(event.target.value)
                                }}
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                style={{ width: "100%" }}
                                value={description}
                                multiline
                                label={t("description", "Description")}
                                inputProps={{
                                    maxLength:
                                        ExerciseTypeFieldSettings["description"]
                                            .maxLength,
                                }}
                                onChange={(event) => {
                                    setDescription(event.target.value)
                                }}
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                style={{ width: "100%" }}
                                value={instructions}
                                label={t("instructions", "Instructions")}
                                multiline
                                inputProps={{
                                    maxLength:
                                        ExerciseTypeFieldSettings[
                                            "instructions"
                                        ].maxLength,
                                }}
                                onChange={(event) => {
                                    setInstructions(event.target.value)
                                }}
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                style={{ width: "100%" }}
                                value={imageUrl}
                                label={t("imageUrl", "Image URL")}
                                onChange={(event) => {
                                    setImageUrl(event.target.value)
                                }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions
                        style={{
                            display: "flex",
                            justifyContent: props.item
                                ? "space-between"
                                : "flex-end",
                        }}
                    >
                        {props.item && (
                            <Button
                                onClick={() => {
                                    setConfirm(true)
                                }}
                                variant="outlined"
                                style={{ color: "red" }}
                            >
                                Delete
                            </Button>
                        )}
                        <Box>
                            <Button
                                onClick={() => {
                                    setTitle("")
                                    setDescription("")
                                    props.onClose()
                                }}
                            >
                                {t("discard", "Discard")}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSave}
                                disabled={!title || title.length === 0}
                            >
                                {t("save", "Save")}
                            </Button>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}
