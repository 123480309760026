import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { useQuery } from "@apollo/client"
import Chip from "@material-ui/core/Chip"
import { CATEGORY_QUERY } from "../categories/graphql"
import Typography from "@material-ui/core/Typography"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

import {
    CategoryQuery,
    CategoryQueryVariables,
} from "../categories/__generated__/graphql"
import { Category } from "../../__generated__/types"

import { FilterProps } from "./util/types"
import { getNewState } from "./util/getNewState"

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        height: "100%",
    },
    listbox: {
        overflow: "auto",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    popper: {
        zIndex: theme.zIndex.modal + 2,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },
    header: {
        fontSize: theme.typography.h6.fontSize,
        padding: theme.spacing(1),
    },
    betweenFiltersText: {
        color: theme.palette.text.hint,
    },
}))

export default function CategoryFilter(props: FilterProps) {
    const classes = useStyles()

    const [value, setValue] = useState<Category[]>([])
    const capitalize = (s: string) => {
        if (typeof s !== "string") return ""
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const { t } = useAwaitTranslation("global")
    const { data, error, loading } = useQuery<
        CategoryQuery,
        CategoryQueryVariables
    >(CATEGORY_QUERY)

    useEffect(() => {
        if (data?.Category && props.item) {
            setValue(
                data?.Category?.filter(
                    (x: Category) => props.item.value.indexOf(x.id) !== -1
                )
            )
        }
    }, [data, props.item])
    const typeFilters = props.filters.find((f) => f.field === "Type")?.value

    return (
        <Autocomplete
            options={
                !!data
                    ? [
                          ...data?.Category?.filter((op) => !op.isRoot),
                      ]?.sort((a, b) =>
                          b.parent?.name.localeCompare(a.parent?.name)
                      )
                    : []
            }
            multiple
            classes={{
                popper: classes.popper,
                listbox: classes.listbox,
            }}
            getOptionLabel={(option) =>
                t(
                    `categoryFilters.${option.name}` as const,
                    capitalize(option.name)
                ) as string
            }
            filterOptions={(options) =>
                options.filter((o) =>
                    typeFilters?.length > 0
                        ? typeFilters.includes(o.parent?.name)
                        : true
                )
            }
            value={value}
            size="small"
            loading={loading}
            loadingText={t("loadingCategories", "Loading Categories ...")}
            noOptionsText={"No categories for selected type..."}
            groupBy={(option: Category) => option.parent?.name}
            onChange={(event: any, newValue: Category[], reason: string) => {
                setValue(newValue)
                const newState = getNewState(
                    props.item,
                    newValue,
                    props.filters,
                    "id"
                )
                if (newState) {
                    props.setFilters(newState)
                }
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={
                            <Typography
                                variant="body2"
                                style={{ whiteSpace: "normal" }}
                            >
                                {option.name}
                            </Typography>
                        }
                        {...getTagProps({ index })}
                        style={{ height: "100%" }}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t("categories", "Categories ")}
                    placeholder="Search Categories"
                />
            )}
        />
    )
}
