import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import FavoriteIcon from "@material-ui/icons/Favorite"

const CONCEPT_LIKED_STYLES = {
    true: { icon: FavoriteIcon },
    false: { icon: FavoriteBorderIcon },
}

export const getConceptLikedIcon = (liked) => {
    const style = CONCEPT_LIKED_STYLES[liked]
    if (style && style.icon) {
        return style.icon
    }
    return ""
}

export const getConceptLikedImage = (liked) => {
    const style = CONCEPT_LIKED_STYLES[liked]
    if (style && style.icon) {
        return style.icon
    }
}

export default CONCEPT_LIKED_STYLES
