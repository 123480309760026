import React, { useEffect, useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, TextField } from "@material-ui/core"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useAwaitTranslation from "../i18n/useAwaitTranslation"

const useStyles = makeStyles((theme) => ({
    body1: {
        fontSize: theme.typography.body1.fontSize,
        lineHeight: 1.5,
        minHeight: "18.5px",
    },
    body2: {
        fontSize: theme.typography.body2.fontSize,
    },
    h6: {
        fontSize: theme.typography.h6.fontSize,
        lineHeight: 1.5,
    },
    subtitle2: {
        fontSize: theme.typography.subtitle2.fontSize,
    },
    clickable: {
        height: "100%",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            borderRadius: "4px",
        },
    },
}))

interface DetailsFieldProps {
    value: string
    editable: boolean
    handleEdit: (newValue: string) => void
    grow?: boolean
    label?: string
    options?: string[]
    className?: string
    multiline?: boolean
    parentWidth?: number
    maxLength?: number
}

export default function DetailsField(props: DetailsFieldProps) {
    const classes = useStyles()
    const ref = useRef(null)
    const spanRef = useRef(null)
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState(props.value)
    const { t } = useAwaitTranslation("detailsField")
    const [width, setWidth] = useState(null)

    useEffect(() => {
        const newWidth = spanRef.current?.offsetWidth + 50
        if (!!props.grow && !!newWidth) {
            if (!!props.parentWidth) {
                if (newWidth < props.parentWidth) {
                    //50 for helper text and a little spacing
                    setWidth(spanRef.current.offsetWidth + 50)
                } else {
                    setWidth(props.parentWidth)
                }
            } else {
                setWidth(spanRef.current.offsetWidth + 50)
            }
        }
    }, [spanRef, props.grow, value, editing, props.parentWidth])
    useEffect(() => {
        setValue(props.value)
    }, [setValue, props.value])
    useEffect(() => {
        if (ref && editing) {
            ref.current.focus()
        }
    }, [ref, editing])
    const handleChange = (newValue) => {
        props.handleEdit(newValue)
        setEditing(false)
    }
    let input
    if (!props.options) {
        input = (
            <Box overflow="none">
                <TextField
                    InputProps={{
                        classes: {
                            input: classes[props.className],
                        },
                        style: {
                            marginRight: "10px",
                        },
                    }}
                    inputProps={{
                        maxLength: props.maxLength,
                    }}
                    value={value}
                    label={props.label ?? ""}
                    multiline={props.multiline}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleChange(value)
                        }
                    }}
                    helperText={`${value?.length ?? 0}/${props.maxLength}`}
                    inputRef={ref}
                    onFocus={() => {
                        if (ref && ref.current && editing) {
                            ref.current.setSelectionRange(
                                ref.current.value.length,
                                ref.current.value.length
                            )
                        }
                    }}
                    fullWidth
                    variant="standard"
                    onChange={(event) => {
                        setValue(event.target.value)
                    }}
                    style={{
                        width: props.grow ? width + "px" : "100%",
                        minWidth: "5em",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                    }}
                    onBlur={(event) => handleChange(value)}
                />
            </Box>
        )
    } else {
        input = (
            <ClickAwayListener onClickAway={(event) => handleChange(value)}>
                <Autocomplete
                    style={{ minWidth: "8em" }}
                    options={props.options}
                    getOptionLabel={(option) =>
                        t(`detailsField.${option}` as const, option) as string
                    }
                    value={value}
                    onChange={(event, newValue: string | null) => {
                        setValue(newValue)
                        handleChange(newValue)
                    }}
                    disableClearable
                    openOnFocus
                    autoSelect
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            label={props.label}
                            variant="standard"
                        />
                    )}
                />
            </ClickAwayListener>
        )
    }
    return (
        <Box
            onClick={(e) => {
                if (props.editable) {
                    setEditing(true)
                }
            }}
        >
            {editing ? (
                <Box p={0.5}>
                    {!props.grow ? input : !Number.isNaN(width) ? input : null}
                    {props.grow && (
                        <span
                            id="width-tracker"
                            className={props.className}
                            ref={spanRef}
                            style={{
                                position: "absolute",
                                left: "-100%",
                                zIndex: -10000,
                                visibility: "hidden",
                            }}
                        >
                            {value}
                        </span>
                    )}
                </Box>
            ) : (
                <Box
                    p={0.5}
                    className={props.editable ? classes.clickable : ""}
                >
                    <Box className={classes[props.className]}>{value}</Box>
                </Box>
            )}
        </Box>
    )
}
