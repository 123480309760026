import { gql } from "@apollo/client"

const CREATE_THEME = gql`
    mutation CreateTheme(
        $title: String
        $description: String
        $darkMode: Boolean
        $primary: String
        $secondary: String
        $primaryHue: String
        $secondaryHue: String
        $primaryShade: Int
        $secondaryShade: Int
    ) {
        CreateDesignTheme(
            title: $title
            description: $description
            darkMode: $darkMode
            primary: $primary
            secondary: $secondary
            primaryHue: $primaryHue
            secondaryHue: $secondaryHue
            primaryShade: $primaryShade
            secondaryShade: $secondaryShade
        ) {
            themeId
            title
            description
            darkMode
            primary
            secondary
            primaryHue
            secondaryHue
            primaryShade
            secondaryShade
        }
    }
`
const ADD_DESIGN_THEME_CREATED_BY = gql`
    mutation addThemeCreatedBy($fromId: ID!, $toId: ID!) {
        AddDesignThemeCreatedBy(
            from: { userId: $fromId }
            to: { themeId: $toId }
        ) {
            from {
                userId
                createdThemes {
                    themeId
                    title
                    description
                    darkMode
                    primary
                    secondary
                    primaryHue
                    secondaryHue
                    primaryShade
                    secondaryShade
                }
            }
            to {
                themeId
                createdBy {
                    userId
                }
            }
        }
    }
`
const ADD_USER_ACTIVE_LIGHT_THEME = gql`
    mutation addActiveLightTheme($fromId: ID!, $toId: ID!) {
        AddUserActiveLightTheme(
            from: { userId: $fromId }
            to: { themeId: $toId }
        ) {
            from {
                userId
                createdThemes {
                    themeId
                }
                activeLightTheme {
                    themeId
                    title
                    description
                    darkMode
                    primary
                    secondary
                    primaryHue
                    secondaryHue
                    primaryShade
                    secondaryShade
                }
            }
            to {
                themeId
                createdBy {
                    userId
                }
            }
        }
    }
`
const REMOVE_USER_ACTIVE_LIGHT_THEME = gql`
    mutation removeActiveLightTheme($fromId: ID!, $toId: ID!) {
        RemoveUserActiveLightTheme(
            from: { userId: $fromId }
            to: { themeId: $toId }
        ) {
            from {
                userId
                createdThemes {
                    themeId
                }
                activeLightTheme {
                    themeId
                    title
                    description
                    darkMode
                    primary
                    secondary
                    primaryHue
                    secondaryHue
                    primaryShade
                    secondaryShade
                }
            }
            to {
                themeId
                createdBy {
                    userId
                }
            }
        }
    }
`
const DELETE_DESIGN_THEME = gql`
    mutation deleteDesignTheme($themeId: ID!) {
        DeleteDesignTheme(themeId: $themeId) {
            themeId
        }
    }
`
const UPDATE_DESIGN_THEME = gql`
    mutation updateDesignTheme(
        $themeId: ID!
        $title: String
        $description: String
        $darkMode: Boolean
        $primary: String
        $secondary: String
    ) {
        UpdateDesignTheme(
            themeId: $themeId
            title: $title
            description: $description
            darkMode: $darkMode
            primary: $primary
            secondary: $secondary
        ) {
            themeId
            title
            description
            darkMode
            primary
            secondary
        }
    }
`

const ADD_USER_ACTIVE_DARK_THEME = gql`
    mutation addActiveDarkTheme($fromId: ID!, $toId: ID!) {
        AddUserActiveDarkTheme(
            from: { userId: $fromId }
            to: { themeId: $toId }
        ) {
            from {
                userId
                createdThemes {
                    themeId
                }
                activeDarkTheme {
                    themeId
                    title
                    description
                    darkMode
                    primary
                    secondary
                    primaryHue
                    secondaryHue
                    primaryShade
                    secondaryShade
                }
            }
            to {
                themeId
                createdBy {
                    userId
                }
            }
        }
    }
`
const REMOVE_USER_ACTIVE_DARK_THEME = gql`
    mutation removeActiveDarkTheme($fromId: ID!, $toId: ID!) {
        RemoveUserActiveDarkTheme(
            from: { userId: $fromId }
            to: { themeId: $toId }
        ) {
            from {
                userId
                createdThemes {
                    themeId
                }
                activeDarkTheme {
                    themeId
                    title
                    description
                    darkMode
                    primary
                    secondary
                    primaryHue
                    secondaryHue
                    primaryShade
                    secondaryShade
                }
            }
            to {
                themeId
                createdBy {
                    userId
                }
            }
        }
    }
`
const USER_THEMES_QUERY = gql`
    query UserThemesQuery($userId: ID) {
        User(userId: $userId) {
            userId
            activeLightTheme {
                themeId
                title
                description
                darkMode
                primary
                secondary
                primaryHue
                secondaryHue
                primaryShade
                secondaryShade
            }
            activeDarkTheme {
                themeId
                title
                description
                darkMode
                primary
                secondary
                primaryHue
                secondaryHue
                primaryShade
                secondaryShade
            }
            createdThemes {
                themeId
                title
                description
                darkMode
                primary
                secondary
                primaryHue
                secondaryHue
                primaryShade
                secondaryShade
            }
            activeThemeType
        }
    }
`
export {
    CREATE_THEME,
    ADD_DESIGN_THEME_CREATED_BY,
    ADD_USER_ACTIVE_LIGHT_THEME,
    REMOVE_USER_ACTIVE_LIGHT_THEME,
    ADD_USER_ACTIVE_DARK_THEME,
    REMOVE_USER_ACTIVE_DARK_THEME,
    USER_THEMES_QUERY,
    DELETE_DESIGN_THEME,
    UPDATE_DESIGN_THEME,
}
