import { useMutation, useQuery, useApolloClient } from "@apollo/client"
import { useCallback, useEffect, useState } from "react"
import {
    ASSIGN_DASHBOARD_WIDGET_TO_DASHBOARD,
    ASSIGN_DASHBOARD_WIDGET_TO_TYPE,
    CREATE_DASHBOARD_WIDGET,
    GET_DASHBOARD_WIDGETS_BY_ID,
    CREATE_DASHBOARD,
    UPDATE_DASHBOARD_LAYOUT,
    ALL_DASHBOARD_TEMPLATES,
    CONNECT_DASHBOARD_TO_CONCEPT,
    DELETE_DASHBOARD,
    DELETE_DASHBOARD_WIDGET,
} from "./graphql"
import {
    AddDashboardWidgetToTypeMutation,
    AddDashboardWidgetToTypeMutationVariables,
    AssignDashboardWidgetToDashboardMutation,
    AssignDashboardWidgetToDashboardMutationVariables,
    CreateDashboardWidgetMutation,
    CreateDashboardWidgetMutationVariables,
    GetDashboardWidgetsQuery,
    GetDashboardWidgetsQueryVariables,
    ConnectDashboardToConceptMutation,
    ConnectDashboardToConceptMutationVariables,
} from "./__generated__/graphql"
import { useWidgetTypes, WidgetTypeName } from "./useWidgetTypes"
import { Layout } from "react-grid-layout"
import { DashboardView } from "../../__generated__/types"

export type Widget = {
    id: string
    name: string
    type: WidgetTypeName
}

const nonTemplateFields = ["conceptListId", "conceptId", "exerciseId"]
export function useDashboardTemplates(dashboardId?: string) {
    // LOAD ALL WIDGET TYPES
    const {
        widgetTypes,
        isInitialized: isWidgetTypeInitialized,
    } = useWidgetTypes()
    const { data: templatesData, refetch: refetchTemplates } = useQuery(
        ALL_DASHBOARD_TEMPLATES
    )
    // LOAD ALL WIDGETS FOR DASHBOARD
    const { data: widgetsData } = useQuery<
        GetDashboardWidgetsQuery,
        GetDashboardWidgetsQueryVariables
    >(GET_DASHBOARD_WIDGETS_BY_ID, {
        variables: {
            dashboardId,
        },
        skip: !dashboardId,
    })
    const dashboardTemplates = templatesData?.DashboardView
    const [widgets, setWidgets] = useState([])
    const [isWidgetsInitialized, setWidgetsInitialized] = useState(false)
    const client = useApolloClient()
    const widgetsList = widgetsData?.Widget
    useEffect(() => {
        if (widgetsList) {
            setWidgets(
                widgetsList.map((widget) => ({
                    ...widget,
                    id: widget.id,
                    name: widget.name,
                    type: (widget.type?.name ??
                        WidgetTypeName.Unknown) as WidgetTypeName,
                }))
            )
            setWidgetsInitialized(true)
        }
    }, [widgetsList])

    // API CALLS FOR WIDGETS
    const [addNewWidget] = useMutation<
        CreateDashboardWidgetMutation,
        CreateDashboardWidgetMutationVariables
    >(CREATE_DASHBOARD_WIDGET)
    const [assignWidgetToType] = useMutation<
        AddDashboardWidgetToTypeMutation,
        AddDashboardWidgetToTypeMutationVariables
    >(ASSIGN_DASHBOARD_WIDGET_TO_TYPE)
    const [assignWidgetToDashboard] = useMutation<
        AssignDashboardWidgetToDashboardMutation,
        AssignDashboardWidgetToDashboardMutationVariables
    >(ASSIGN_DASHBOARD_WIDGET_TO_DASHBOARD)
    const [connectDashboardToConcept] = useMutation<
        ConnectDashboardToConceptMutation,
        ConnectDashboardToConceptMutationVariables
    >(CONNECT_DASHBOARD_TO_CONCEPT)
    const [createDashboard] = useMutation(CREATE_DASHBOARD)
    const [updateDashboard] = useMutation(UPDATE_DASHBOARD_LAYOUT)
    const [deleteDashboard] = useMutation(DELETE_DASHBOARD)
    const [deleteWidget] = useMutation(DELETE_DASHBOARD_WIDGET)
    const onAddNewWidget = useCallback(
        async (
            widgetTypeName: WidgetTypeName,
            config: string,
            value: string,
            templateDashboardId: string
        ) => {
            let validConfig = {}
            const parsedConfig = JSON.parse(config)

            Object.keys(parsedConfig).map((field) => {
                if (!nonTemplateFields.includes(field)) {
                    validConfig[field] = parsedConfig[field]
                } else {
                    validConfig[field] = ""
                }
                return validConfig
            })

            const widgetType = widgetTypes.find(
                (w) => w.name === widgetTypeName
            )
            if (!widgetType)
                return Promise.reject(
                    `No widget type found for type ${widgetTypeName}`
                )

            const {
                data: { CreateWidget: newWidget },
            } = await addNewWidget({
                variables: {
                    config: JSON.stringify(validConfig),
                    value: value,
                    isTemplate: false,
                },
            })
            await assignWidgetToType({
                variables: {
                    widgetId: newWidget.id,
                    widgetTypeId: widgetType.id,
                },
            })
            await assignWidgetToDashboard({
                variables: {
                    widgetId: newWidget.id,
                    dashboardId: templateDashboardId,
                },
            })

            return newWidget
        },
        [addNewWidget, assignWidgetToDashboard, assignWidgetToType, widgetTypes]
    )
    const onApplyDashboardTemplate = async (
        dashboardView: DashboardView,
        conceptId: string
    ) => {
        let newLayout: Layout[] = []
        const {
            data: { CreateDashboardView: NewDashboard },
        } = await createDashboard({
            variables: {
                name: dashboardView.name,
                description: dashboardView.description,
                isTemplate: false,
            },
        })
        const currentLayout = JSON.parse(dashboardView.layout)
        await Promise.all([
            ...currentLayout?.map((layoutItem, index) => {
                const widget = dashboardView.widgets?.filter(
                    (widget) => widget.id === layoutItem.i
                )?.[0]
                if (widget) {
                    return onAddNewWidget(
                        widget.type?.name as WidgetTypeName,
                        widget.config,
                        widget.value,
                        NewDashboard.id
                    ).then(
                        (newWidget) =>
                            (newLayout[index] = {
                                ...layoutItem,
                                i: newWidget.id,
                            })
                    )
                }
            }),
        ])

        await Promise.all([
            updateDashboard({
                variables: {
                    id: NewDashboard.id,
                    layout: JSON.stringify(newLayout),
                },
            }),

            connectDashboardToConcept({
                variables: {
                    dashboardId: NewDashboard.id,
                    conceptId: conceptId,
                },
            }),
        ])
        return NewDashboard
    }
    const onDeleteDashboardTemplate = async (dashboard: DashboardView) => {
        dashboard?.widgets.map((widget) => {
            client.cache.evict({
                id: client.cache.identify(widget),
            })
            deleteWidget({
                variables: {
                    id: widget.id,
                },
            })
        })
        await deleteDashboard({
            variables: {
                id: dashboard.id,
            },
        })
        client.cache.evict({
            id: client.cache.identify(dashboard),
        })
        refetchTemplates()
    }
    const onCreateDashboardTemplate = async (
        name: string,
        description: string,
        currentLayout: Layout[]
    ) => {
        let newLayout: Layout[] = []
        const {
            data: { CreateDashboardView: NewDashboard },
        } = await createDashboard({
            variables: {
                name,
                description,
                isTemplate: true,
            },
        })

        await Promise.all([
            ...currentLayout?.map((layoutItem, index) => {
                const widget = widgetsList.filter(
                    (widget) => widget.id === layoutItem.i
                )?.[0]
                if (widget) {
                    return onAddNewWidget(
                        widget.type?.name as WidgetTypeName,
                        widget.config,
                        widget.value,
                        NewDashboard.id
                    ).then(
                        (newWidget) =>
                            (newLayout[index] = {
                                ...layoutItem,
                                i: newWidget.id,
                            })
                    )
                }
            }),
        ])

        await updateDashboard({
            variables: {
                id: NewDashboard.id,
                layout: JSON.stringify(newLayout),
            },
        })
        await refetchTemplates()
        return NewDashboard
    }

    return {
        isTemplatesInitialized: !!dashboardTemplates,
        isWidgetsInitialized: isWidgetsInitialized && isWidgetTypeInitialized,
        widgets,
        widgetTypes,
        dashboardTemplates,

        // Methods
        onAddNewWidget,
        onCreateDashboardTemplate,
        onApplyDashboardTemplate,
        onDeleteDashboardTemplate,
        refetchTemplates,
    }
}
