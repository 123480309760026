import React, { useEffect, useState } from "react"
import { makeStyles, Theme } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { useQuery } from "@apollo/client"
import Chip from "@material-ui/core/Chip"
import { ALL_INDUSTRY_QUERY } from "../industries/graphql"
import Typography from "@material-ui/core/Typography"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import {
    IndustryQuery,
    IndustryQueryVariables,
} from "../industries/__generated__/graphql"

import { Industry } from "../../__generated__/types"

import { FilterProps } from "./util/types"
import { getNewState } from "./util/getNewState"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1),
        height: "100%",
    },
    listbox: {
        overflow: "auto",
    },
    label: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(0.5),
    },
    popper: {
        zIndex: theme.zIndex.modal + 2,
    },
    input: {
        zIndex: theme.zIndex.modal + 3,
    },
    header: {
        fontSize: theme.typography.h6.fontSize,
        padding: theme.spacing(1),
    },
    betweenFiltersText: {
        color: theme.palette.text.hint,
    },
}))

export default function IndustryFilter(props: FilterProps) {
    const classes = useStyles()

    const [value, setValue] = useState<Industry[]>([])
    const { data, loading } = useQuery<IndustryQuery, IndustryQueryVariables>(
        ALL_INDUSTRY_QUERY
    )

    useEffect(() => {
        if (props.item && data?.Industry) {
            setValue(
                data?.Industry?.filter((x: Industry) =>
                    props.item.value.includes(x.industryId)
                )
            )
        }
    }, [props.item, data])
    const { t } = useAwaitTranslation("global")
    return (
        <Autocomplete
            options={data?.Industry ?? []}
            multiple
            classes={{
                popper: classes.popper,
                listbox: classes.listbox,
                inputRoot: classes.input,
            }}
            getOptionLabel={(option) => option.name}
            value={value}
            size="small"
            loading={loading}
            loadingText={t("loadingIndustries", "Loading industries ...")}
            noOptionsText={t("noMatch", "No industries that match ...")}
            onChange={(event: any, newValue: Industry[], reason: string) => {
                setValue(newValue)
                const newState = getNewState(
                    props.item,
                    newValue,
                    props.filters,
                    "industryId"
                )
                if (newState) {
                    props.setFilters(newState)
                }
            }}
            disablePortal
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={
                            <Typography
                                variant="body2"
                                style={{ whiteSpace: "normal" }}
                            >
                                {option.name}
                            </Typography>
                        }
                        {...getTagProps({ index })}
                        style={{ height: "100%" }}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t("industries", "Industries")}
                    placeholder={t("searchIndustries", "Search Industries")}
                />
            )}
        />
    )
}
