import { gql } from "@apollo/client"

const DASHBOARD_WIDGET_TYPE_FRAGMENT = gql`
    fragment DashboardWidgetType on WidgetType {
        id
        name
        inputConfig
        schema
        defaultConfig
        defaultValue
        component
    }
`

const DASHBOARD_WIDGET_FRAGMENT = gql`
    fragment DashboardWidget on Widget {
        id
        name
        config
        isTemplate
        type {
            ...DashboardWidgetType
        }
        value
    }
    ${DASHBOARD_WIDGET_TYPE_FRAGMENT}
`

const DASHBOARD_FRAGMENT = gql`
    fragment Dashboard on DashboardView {
        id
        name
        description
        type
        layout
        isTemplate
    }
`
const DASHBOARD_TEMPLATE_FRAGMENT = gql`
    fragment DashboardTemplate on DashboardView {
        id
        name
        description
        type
        layout
        isTemplate
        widgets {
            ...DashboardWidget
        }
    }
    ${DASHBOARD_WIDGET_FRAGMENT}
`
export const GET_DASHBOARD_BY_ID = gql`
    query DashboardById($id: ID!) {
        DashboardView(id: $id) {
            ...Dashboard
            id
        }
    }
    ${DASHBOARD_FRAGMENT}
`
export const ALL_DASHBOARD_TEMPLATES = gql`
    query AllDashboardTemplates {
        DashboardView(isTemplate: true) {
            ...DashboardTemplate
        }
    }
    ${DASHBOARD_TEMPLATE_FRAGMENT}
`
export const DASHBOARD_BY_CONCEPT_ID = gql`
    query DashboardConceptById($conceptId: ID!) {
        DashboardView(filter: { usedByConcepts: { id: $conceptId } }) {
            ...Dashboard
        }
    }
    ${DASHBOARD_FRAGMENT}
`

export const UPDATE_DASHBOARD_LAYOUT = gql`
    mutation UpdateDashboardLayout($id: ID!, $layout: String!) {
        UpdateDashboardView(id: $id, layout: $layout) {
            ...Dashboard
        }
    }

    ${DASHBOARD_FRAGMENT}
`

export const CREATE_DASHBOARD = gql`
    mutation CreateDashboard(
        $name: String!
        $description: String
        $isTemplate: Boolean
    ) {
        CreateDashboardView(
            name: $name
            description: $description
            layout: "[]"
            isTemplate: $isTemplate
        ) {
            ...Dashboard
        }
    }

    ${DASHBOARD_FRAGMENT}
`
export const UPDATE_DASHBOARD_PROPERTIES = gql`
    mutation UpdateDashboardProperties(
        $id: ID!
        $name: String!
        $description: String
    ) {
        UpdateDashboardView(id: $id, name: $name, description: $description) {
            ...Dashboard
        }
    }

    ${DASHBOARD_FRAGMENT}
`
export const DELETE_DASHBOARD = gql`
    mutation DeleteDashboardView($id: ID!) {
        DeleteDashboardView(id: $id) {
            id
        }
    }
`

export const CONNECT_DASHBOARD_TO_CONCEPT = gql`
    mutation ConnectDashboardToConcept($conceptId: ID!, $dashboardId: ID!) {
        AddDashboardViewUsedByConcepts(
            from: { id: $conceptId }
            to: { id: $dashboardId }
        ) {
            from {
                id
            }
            to {
                ...Dashboard
            }
        }
    }

    ${DASHBOARD_FRAGMENT}
`

export const GET_WIDGET_TYPES = gql`
    query GetAllWidgetTypes {
        WidgetType(orderBy: name_asc) {
            ...DashboardWidgetType
        }
    }
    ${DASHBOARD_WIDGET_TYPE_FRAGMENT}
`

export const GET_DASHBOARD_WIDGETS_BY_ID = gql`
    query GetDashboardWidgets($dashboardId: ID!) {
        Widget(filter: { dashboards: { id: $dashboardId } }) {
            ...DashboardWidget
        }
    }
    ${DASHBOARD_WIDGET_FRAGMENT}
`
export const GET_WIDGET_TEMPLATES = gql`
    query GetWidgetTemplates {
        Widget(isTemplate: true) {
            ...DashboardWidget
        }
    }
    ${DASHBOARD_WIDGET_FRAGMENT}
`
export const GET_WIDGET_TEMPLATES_BY_TYPE = gql`
    query GetWidgetTemplatesByType($typeId: ID!) {
        Widget(isTemplate: true, filter: { type: { id: $typeId } }) {
            ...DashboardWidget
        }
    }
    ${DASHBOARD_WIDGET_FRAGMENT}
`
export const CREATE_DASHBOARD_WIDGET = gql`
    mutation CreateDashboardWidget(
        $config: String
        $value: String
        $isTemplate: Boolean
    ) {
        CreateWidget(config: $config, value: $value, isTemplate: $isTemplate) {
            ...DashboardWidget
        }
    }
    ${DASHBOARD_WIDGET_FRAGMENT}
`
export const ASSIGN_DASHBOARD_WIDGET_TO_DASHBOARD = gql`
    mutation AssignDashboardWidgetToDashboard(
        $dashboardId: ID!
        $widgetId: ID!
    ) {
        AddDashboardViewWidgets(
            from: { id: $dashboardId }
            to: { id: $widgetId }
        ) {
            from {
                ...Dashboard
            }
            to {
                ...DashboardWidget
            }
        }
    }
    ${DASHBOARD_FRAGMENT}
    ${DASHBOARD_WIDGET_FRAGMENT}
`

export const ASSIGN_DASHBOARD_WIDGET_TO_TYPE = gql`
    mutation AddDashboardWidgetToType($widgetId: ID!, $widgetTypeId: ID!) {
        AddWidgetTypeWidgets(
            to: { id: $widgetTypeId }
            from: { id: $widgetId }
        ) {
            from {
                ...DashboardWidget
            }
        }
    }
    ${DASHBOARD_WIDGET_FRAGMENT}
`

export const GET_DASHBOARD_WIDGET_BY_ID = gql`
    query GetDashboardWidgetById($id: ID!) {
        Widget(id: $id) {
            ...DashboardWidget
        }
    }
    ${DASHBOARD_WIDGET_FRAGMENT}
`

export const UPDATE_DASHBOARD_WIDGET = gql`
    mutation UpdateDashboardWidget($id: ID!, $config: String, $value: String) {
        UpdateWidget(id: $id, config: $config, value: $value) {
            ...DashboardWidget
        }
    }

    ${DASHBOARD_WIDGET_FRAGMENT}
`

export const DELETE_DASHBOARD_WIDGET = gql`
    mutation DeleteDashboardWidget($id: ID!) {
        DeleteWidget(id: $id) {
            ...DashboardWidget
        }
    }
    ${DASHBOARD_WIDGET_FRAGMENT}
`

export const UPDATE_WIDGET_TYPE = gql`
    mutation UpdateWidgetType(
        $id: ID!
        $name: String
        $schema: String
        $defaultValue: String
        $defaultConfig: String
        $component: String
    ) {
        UpdateWidgetType(
            id: $id
            name: $name
            schema: $schema
            defaultValue: $defaultValue
            defaultConfig: $defaultConfig
            component: $component
        ) {
            ...DashboardWidgetType
        }
    }
    ${DASHBOARD_WIDGET_TYPE_FRAGMENT}
`
export const CREATE_WIDGET_TYPE = gql`
    mutation CreateWidgetType(
        $name: String
        $schema: String
        $defaultValue: String
        $defaultConfig: String
        $component: String
    ) {
        CreateWidgetType(
            name: $name
            schema: $schema
            defaultValue: $defaultValue
            defaultConfig: $defaultConfig
            component: $component
        ) {
            ...DashboardWidgetType
        }
    }
    ${DASHBOARD_WIDGET_TYPE_FRAGMENT}
`

export const ADD_CONCEPT_HOME_DASHBOARD = gql`
    mutation AddConceptHomeDashboard($conceptId: ID!, $dashboardId: ID!) {
        AddConceptHomeDashboard(
            from: { id: $conceptId }
            to: { id: $dashboardId }
        ) {
            from {
                id
            }
            to {
                ...Dashboard
            }
        }
    }

    ${DASHBOARD_FRAGMENT}
`
export const REMOVE_CONCEPT_HOME_DASHBOARD = gql`
    mutation RemoveConceptHomeDashboard($conceptId: ID!, $dashboardId: ID!) {
        RemoveConceptHomeDashboard(
            from: { id: $conceptId }
            to: { id: $dashboardId }
        ) {
            from {
                id
            }
            to {
                ...Dashboard
            }
        }
    }

    ${DASHBOARD_FRAGMENT}
`
