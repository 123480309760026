import React, { useState, useEffect, useCallback } from "react"
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles"
import { useQuery, useMutation, useReactiveVar } from "@apollo/client"
import {
    useMediaQuery,
    Typography,
    Avatar,
    Box,
    Tooltip,
    Tab,
    Tabs,
    Toolbar,
    IconButton,
    Link,
    Divider,
    SwipeableDrawer,
} from "@material-ui/core"
import SettingsIcon from "@material-ui/icons/Settings"
import { useHistory } from "react-router-dom"
import ImageIcon from "@material-ui/icons/Image"
import TeamSettings from "./TeamSettings"
import AddTeamMemberForm from "./TeamMemberForm"
import { TEAM_PROFILE_QUERY, ADD_TEAM_HOME_CONCEPT } from "./graphql"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { CREATE_CONCEPT } from "../../graphql/mutations"
import {
    CreateConceptCompleteMutation,
    CreateConceptCompleteMutationVariables,
} from "../../graphql/__generated__/mutations"
import { Loading } from "../Loading"
import { Team, AccessType } from "../../__generated__/types"
import {
    TeamProfileQuery,
    TeamProfileQueryVariables,
    AddTeamHomeConceptMutation,
    AddTeamHomeConceptMutationVariables,
} from "./__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import { SizeMe } from "react-sizeme"
import { useParams } from "react-router-dom"
import DashboardManager from "../dashboard/DashboardManager"
import Dashboard from "../dashboard/Dashboard"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { WidgetStore } from "../dashboard/WidgetStore"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import TeamMemberTable from "./TeamMemberTable"
import { permissionsVar } from "../../providers/GlobalState"
const drawerWidth = 260

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 100,
        width: 100,
        margin: "auto",
    },
    contentContainer: {
        padding: theme.spacing(2),
        height: "100%",
        overflow: "hidden",
    },
    buttonIcon: {
        [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(1),
        },
    },
    root: {
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    userAvatar: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        margin: theme.spacing(0, 0.75),
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.default,
    },

    headerContainer: {
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
        zIndex: theme.zIndex.appBar - 1,
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,

        flexShrink: 0,
    },
    drawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        flexShrink: 0,
    },
    closedDrawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: 0,
        flexShrink: 0,
    },
    header: {
        display: "flex",
        flexDirection: "column",
        zIndex: theme.zIndex.appBar - 1,
    },
    toolbar: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        display: "flex",
    },
    toolbarContainer: {
        position: "fixed",
        width: "100%",
        overflow: "hidden",
        zIndex: theme.zIndex.appBar - 1,
        height: theme.spacing(6),
        borderBottom: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down("sm")]: {
            right: 0,
            left: 0,
        },
        display: "flex",
    },
}))

export default function TeamProfile(props) {
    const classes = useStyles()
    const theme: Theme = useTheme()
    const { currentUser } = useAuth()
    const { t } = useAwaitTranslation()
    const [editing, setEditing] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(true)
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { teamId, dashboardId } = useParams()
    const history = useHistory()
    const [tab, setTab] = useState(0)
    const { data, loading, error, refetch } = useQuery<
        TeamProfileQuery,
        TeamProfileQueryVariables
    >(TEAM_PROFILE_QUERY, {
        variables: { teamId: teamId },
    })
    const [createTeamConcept] = useMutation<
        CreateConceptCompleteMutation,
        CreateConceptCompleteMutationVariables
    >(CREATE_CONCEPT)
    const [addTeamConcept] = useMutation<
        AddTeamHomeConceptMutation,
        AddTeamHomeConceptMutationVariables
    >(ADD_TEAM_HOME_CONCEPT)
    const [settings, showSettings] = useState(false)
    const [addMember, setAddMember] = useState(false)
    const team: Team = data?.Team[0] ?? null
    const concept = team?.homeConcept ?? null
    const dashboard =
        dashboardId !== "home"
            ? concept?.usesDashboard?.filter((x) => x.id === dashboardId)[0]
            : null
    useEffect(() => {
        if (mobile) {
            setSidebarOpen(false)
        }
    }, [mobile])
    const permissions = useReactiveVar(permissionsVar)
    const createNewTeamConcept = useCallback(async () => {
        const {
            data: { CreateConcept: NewConcept },
        } = await createTeamConcept({
            variables: {
                concept: {
                    title: `${team?.name} Team Concept`,
                    isPublic: false,
                    isPublicAccessType: AccessType.VIEWER,
                    type: "Team Concept",
                },
                userId: currentUser.userId,
                tags: [],
            },
        })
        await addTeamConcept({
            variables: {
                teamId: team.teamId,
                conceptId: NewConcept.id,
            },
        })
        await refetch()
    }, [createTeamConcept, team, refetch, currentUser?.userId, addTeamConcept])

    useEffect(() => {
        if (!!data && !!team && !concept) {
            createNewTeamConcept()
        }
    }, [createNewTeamConcept, data, team, concept])
    useEffect(() => {
        if (!!team) {
            permissionsVar(
                team?.admin?.filter(
                    (user) => user.userId === currentUser?.userId
                )?.length > 0 || team.leader?.userId === currentUser?.userId
            )
        }
    }, [team, currentUser?.userId])
    if (loading || !concept || !team) {
        return <Loading size={150} />
    } else if (error) {
        return <Box>Could not retrieve team...</Box>
    }

    return (
        <Box display="flex" overflow="hidden">
            <SizeMe monitorWidth monitorHeight>
                {({ size }) => (
                    <>
                        <Box
                            className={classes.root}
                            style={{
                                height:
                                    dashboardId === "home"
                                        ? "calc(100vh - 64px)"
                                        : "auto",
                            }}
                        >
                            <Box className={classes.toolbarContainer}>
                                <Box
                                    className={classes.toolbar}
                                    width={!!mobile ? "100vw" : size.width}
                                    justifyContent={
                                        permissions
                                            ? "space-between"
                                            : "flex-end"
                                    }
                                >
                                    {permissions && (
                                        <Tooltip title="Team Settings">
                                            <IconButton
                                                onClick={() => {
                                                    showSettings(true)
                                                }}
                                            >
                                                <SettingsIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Box display="flex">
                                        {dashboardId !== "home" && permissions && (
                                            <>
                                                <WidgetStore
                                                    dashboardId={dashboardId}
                                                    enableEditing={() =>
                                                        setEditing(true)
                                                    }
                                                />
                                                <Tooltip
                                                    title={
                                                        props.editing
                                                            ? "Lock Editing"
                                                            : "Unlock Editing"
                                                    }
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            setEditing(!editing)
                                                        }
                                                        style={{
                                                            padding: "8px",
                                                        }}
                                                    >
                                                        {editing ? (
                                                            <LockOpenIcon />
                                                        ) : (
                                                            <LockIcon />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )}

                                        <IconButton
                                            onClick={() =>
                                                setSidebarOpen(!sidebarOpen)
                                            }
                                        >
                                            {sidebarOpen ? (
                                                <ChevronRightIcon />
                                            ) : (
                                                <ChevronLeftIcon />
                                            )}
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Divider />
                            </Box>
                            {!dashboard ? (
                                <>
                                    <Box
                                        height={theme.spacing(6)}
                                        width="100%"
                                        flexShrink={0}
                                    />

                                    <Box className={classes.headerContainer}>
                                        <Box
                                            className={classes.header}
                                            width={size.width}
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection={
                                                    mobile ? "column" : "row"
                                                }
                                                flexGrow={1}
                                                p={1}
                                            >
                                                <Box p={1}>
                                                    <Avatar
                                                        className={
                                                            classes.avatar
                                                        }
                                                        alt={team?.name}
                                                        src={team?.imageUrl}
                                                    >
                                                        <ImageIcon />
                                                    </Avatar>
                                                </Box>
                                                <Box
                                                    p={1}
                                                    flexGrow={1}
                                                    alignItems="center"
                                                >
                                                    <Box
                                                        marginTop={
                                                            mobile ? 2 : 0
                                                        }
                                                    >
                                                        <Box flexGrow={1}>
                                                            <Typography
                                                                gutterBottom
                                                                variant={
                                                                    mobile
                                                                        ? "h5"
                                                                        : "h4"
                                                                }
                                                            >
                                                                {team?.name}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    {team?.description && (
                                                        <Box>
                                                            <Typography variant="body1">
                                                                {
                                                                    team.description
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    <Box
                                                        display="flex"
                                                        marginTop={2}
                                                    >
                                                        {t(
                                                            "teamLead",
                                                            "Team Lead"
                                                        )}
                                                        :
                                                        <Avatar
                                                            className={
                                                                classes.userAvatar
                                                            }
                                                            alt={
                                                                team?.leader
                                                                    ?.firstName
                                                            }
                                                            src={
                                                                team?.leader
                                                                    ?.imageUrl
                                                            }
                                                        />
                                                        <Link
                                                            style={{
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                            onClick={() =>
                                                                history.push(
                                                                    `/user/${team.leader?.username}/home`
                                                                )
                                                            }
                                                        >
                                                            {
                                                                team?.leader
                                                                    ?.firstName
                                                            }{" "}
                                                            {
                                                                team?.leader
                                                                    ?.lastName
                                                            }
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Divider />
                                            <Box key={size.width}>
                                                <Tabs
                                                    value={tab}
                                                    onChange={(
                                                        event: React.ChangeEvent<{}>,
                                                        newValue: number
                                                    ) => {
                                                        setTab(newValue)
                                                    }}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    centered
                                                >
                                                    <Tab
                                                        label={t(
                                                            "teamMembers",
                                                            "Team Members"
                                                        )}
                                                    />
                                                </Tabs>
                                                <Divider />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className={classes.contentContainer}>
                                        {tab === 0 && (
                                            <TeamMemberTable team={team} />
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <Box mt={6}>
                                    <Dashboard
                                        key={dashboard.id + concept.id}
                                        dashboardId={dashboard.id}
                                        width={size.width ?? 25}
                                        editing={editing}
                                    />
                                </Box>
                            )}
                        </Box>
                    </>
                )}
            </SizeMe>
            <SwipeableDrawer
                open={sidebarOpen}
                anchor="right"
                variant={mobile ? "temporary" : "persistent"}
                className={sidebarOpen ? classes.drawer : classes.closedDrawer}
                onOpen={() => setSidebarOpen(true)}
                onClose={() => setSidebarOpen(false)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {!mobile && <Toolbar />}
                <DashboardManager
                    concept={concept}
                    editing={editing}
                    toggleEditing={() => setEditing(!editing)}
                    refetch={refetch}
                />
            </SwipeableDrawer>
            {settings && (
                <TeamSettings
                    team={team}
                    onClose={() => {
                        showSettings(false)
                    }}
                    onSave={async () => {
                        await refetch()
                    }}
                />
            )}
            {addMember && (
                <AddTeamMemberForm
                    team={team}
                    onClose={() => {
                        setAddMember(false)
                    }}
                />
            )}
        </Box>
    )
}
