import {
    ListItemIcon,
    useTheme,
    IconButton,
    ClickAwayListener,
    Grow,
    Paper,
    Tooltip,
    fade,
} from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React, { useState } from "react"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import { getConceptTypeIcon } from "../util/ConceptTypeStyles"
import { Concept } from "../__generated__/types"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import Menu from "@material-ui/core/Menu"
import SearchIcon from "@material-ui/icons/Search"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { useGlobalStyles } from "../styles/globalStyles"
import { Link as LinkRouter } from "react-router-dom"
import LinkConcept from "./LinkConcept"
import AddIcon from "@material-ui/icons/Add"
import { ADD_CONCEPT_CHILD, REMOVE_CONCEPT_CHILD } from "./workspace/graphql"
import {
    AddConceptChildMutation,
    AddConceptChildMutationVariables,
    RemoveConceptChildMutation,
    RemoveConceptChildMutationVariables,
} from "./workspace/__generated__/graphql"
import { useMutation } from "@apollo/client"
import { useLocation } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
    draggingRoot: {
        //@ts-ignore

        backgroundColor: theme.palette.background.level2,
        border: `0.5px solid ${theme.palette.divider}`,
    },
    selected: {
        backgroundColor: fade(theme.palette.primary.main, 0.08),
    },
    subIcon: {
        height: "0.65em",
        width: "0.65em",
    },
    greyedText: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    type: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    contextMenuItem: {
        fontSize: theme.typography.body2.fontSize,
    },
    contextMenuIcon: {
        fontSize: "1em",
        marginRight: theme.spacing(1),
    },
    popper: {
        zIndex: theme.zIndex.drawer + 1,
    },
    title: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}))
interface ConceptWorkspaceItemProps {
    item: Concept
    dragHandleProps?: DraggableProvidedDragHandleProps
    isDragging?: boolean
    sortable: boolean
    move?: (index: number, destination: number) => void
    index?: number
    depth?: number
    removeConcept?: (index: number, id: string) => void
}

export default function ConceptWorkspaceItem(props: ConceptWorkspaceItemProps) {
    const {
        item,
        isDragging,
        sortable,
        removeConcept,
        move,
        index,
        depth,
    } = props
    const refetchQueries =
        depth === 0 || depth === 1 ? ["GetConceptListComplete"] : []
    const { pathname } = useLocation()

    const globalClasses = useGlobalStyles()
    const [hovered, setHovered] = useState(false)
    const classes = useStyles()
    const theme = useTheme()
    const [addChild] = useMutation<
        AddConceptChildMutation,
        AddConceptChildMutationVariables
    >(ADD_CONCEPT_CHILD, {
        refetchQueries: refetchQueries,
    })
    const [removeChild] = useMutation<
        RemoveConceptChildMutation,
        RemoveConceptChildMutationVariables
    >(REMOVE_CONCEPT_CHILD, {
        refetchQueries: refetchQueries,
    })

    const TypeIcon = getConceptTypeIcon(item.rootCategory?.name)
    const { t } = useAwaitTranslation("concepts")
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(
        null
    )
    const open = Boolean(anchorEl)
    const popperOpen = Boolean(popperAnchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(null)
    }
    const handlePopperClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setPopperAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const handlePopperClose = (event) => {
        event.stopPropagation()
        setPopperAnchorEl(null)
        if (hovered) {
            setHovered(false)
        }
    }
    const handleChildAddition = (childId: string) => {
        setPopperAnchorEl(null)
        setHovered(false)
        addChild({
            variables: {
                parentId: item.id,
                childId: childId,
            },
        })
    }
    const handleChildRemoval = () => {
        setPopperAnchorEl(null)
        setHovered(false)
        removeChild({
            variables: {
                parentId: item.parent?.id,
                childId: item.id,
            },
        })
    }

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => {
                if (!popperOpen) {
                    setHovered(false)
                }
            }}
            className={
                isDragging
                    ? classes.draggingRoot
                    : pathname?.includes(item.id)
                    ? classes.selected
                    : ""
            }
        >
            <ListItem
                component={LinkRouter}
                to={`/concept/${item.id}/home`}
                button
                dense
            >
                {sortable && (
                    <ListItemIcon
                        {...props.dragHandleProps}
                        style={{ minWidth: "40px" }}
                    >
                        <DragIndicatorIcon fontSize="small" />
                    </ListItemIcon>
                )}
                <ListItemAvatar
                    style={{ display: "flex", minWidth: theme.spacing(5.5) }}
                >
                    <Avatar
                        variant="rounded"
                        src={item.imageUrl}
                        style={{
                            width: theme.spacing(4),
                            height: theme.spacing(4),
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.primary.main,
                        }}
                    >
                        <TypeIcon />
                    </Avatar>
                </ListItemAvatar>
                <Box flexGrow={1} className={classes.type}>
                    {!!item.rootCategory && (
                        <Box className={`${classes.greyedText}`}>
                            {props.item.rootCategory?.name}{" "}
                            {!!props.item.category?.name &&
                                !props.item.category?.isRoot &&
                                " / " + props.item.category?.name}
                        </Box>
                    )}
                    <Box>
                        <Typography variant="body2" className={classes.title}>
                            {item.title}
                        </Typography>
                    </Box>
                </Box>
                {hovered && (
                    <Box display="flex">
                        <Tooltip title="More options">
                            <IconButton
                                size="small"
                                onClick={handleClick}
                                disableRipple={true}
                                style={{
                                    visibility: hovered ? "visible" : "hidden",
                                }}
                            >
                                <MoreVertIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Quickly add a nested concept">
                            <IconButton
                                size="small"
                                onClick={handlePopperClick}
                                disableRipple={true}
                                style={{
                                    visibility: hovered ? "visible" : "hidden",
                                }}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </ListItem>
            <Menu
                id={props.item.id}
                open={open}
                anchorEl={anchorEl}
                keepMounted
                disableScrollLock
                onClose={handleClose}
            >
                {depth === 0 && (
                    <MenuItem
                        key={"Move Up"}
                        component="li"
                        divider
                        onClick={(event) => {
                            event.stopPropagation()
                            move(index, 0)
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemIcon className={globalClasses.smallMenuIcon}>
                            <ArrowUpwardIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                className: globalClasses.smallMenuListItemText,
                            }}
                            primary={"Move to top"}
                        />
                    </MenuItem>
                )}

                <MenuItem
                    key={"Remove"}
                    component="li"
                    onClick={() => {
                        if (depth === 0) {
                            removeConcept(index, item.id)
                        } else {
                            handleChildRemoval()
                        }

                        setAnchorEl(null)
                    }}
                >
                    <ListItemIcon className={globalClasses.smallMenuIcon}>
                        <RemoveCircleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            className: globalClasses.smallMenuListItemText,
                        }}
                        primary={"Remove"}
                    />
                </MenuItem>
            </Menu>
            <Popper
                open={popperOpen}
                anchorEl={popperAnchorEl}
                transition
                className={classes.popper}
                style={{ visibility: hovered ? "visible" : "hidden" }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                        }}
                    >
                        <Paper style={{ minWidth: "12em" }}>
                            <ClickAwayListener onClickAway={handlePopperClose}>
                                <Box p={1}>
                                    <LinkConcept
                                        linkFunction={(item: Concept) =>
                                            handleChildAddition(item.id)
                                        }
                                        variant="outlined"
                                        inputAdornment={<SearchIcon />}
                                        autoFocus={true}
                                        placeholder="Type to add a nested concept..."
                                    />
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}
