import { useQuery } from "@apollo/client"
import { Box, Theme, Typography } from "@material-ui/core"
import { makeStyles, fade, useTheme } from "@material-ui/core/styles"
import React, { useCallback, useState } from "react"
import { MY_CONCEPTS_QUERY } from "../graphql/queries"
import { ConceptTypes } from "../graphql/types"

import { GET_CATEGORIES } from "./categories/graphql"
import { useAuth } from "../providers/AuthProvider"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import {
    CategoryQuery,
    CategoryQueryVariables,
} from "./categories/__generated__/graphql"
import ViewListIcon from "@material-ui/icons/ViewList"
import { Category, ConceptType, _ConceptFilter } from "../__generated__/types"
import {
    Divider,
    IconButton,
    MenuItem,
    InputLabel,
    Select,
    Tooltip,
    Slide,
    Paper,
    useMediaQuery,
} from "@material-ui/core"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import { getConceptTypeIcon } from "../util/ConceptTypeStyles"
import VirtualInfiniteList from "./VirtualInfiniteList"
import ConceptCard from "./ConceptCard"
import { Loading } from "./Loading"
import ClearIcon from "@material-ui/icons/Clear"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import ViewModuleIcon from "@material-ui/icons/ViewModule"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import {
    MyConceptsQueryQuery,
    MyConceptsQueryQueryVariables,
} from "../graphql/__generated__/queries"
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.hint,
    },
    treeContainer: {
        display: "flex",
        flexDirection: "column",
        position: "sticky",
        height: `calc(100vh - 64px - ${theme.spacing(4)}px)`,
        top: theme.spacing(10),
        margin: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            minWidth: "400px",
            maxWidth: "400px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    treeItem: {
        backgroundColor: "transparent !important",
        "&:hover": {
            backgroundColor: theme.palette.action.hover + " !important",
        },
    },
    selectedTreeItem: {
        backgroundColor: fade(theme.palette.primary.main, 0.08) + " !important",
    },
    treeIconContainer: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.action.hover + " !important",
        },
        borderRadius: "100%",
        alignItems: "center",
        height: "30px",
        width: "30px",
    },
    emptyTreeIconContainer: {
        cursor: "default",
        height: "30px",
        width: "30px",
    },
    fixedSelectedNodeContainer: {
        position: "sticky",
        zIndex: theme.zIndex.appBar - 1,
        width: "100%",
        backgroundColor: theme.palette.background.default,
        top: 64,
    },
    selectedNodeContainer: {
        padding: theme.spacing(1),
        display: "flex",
        alignItems: "center",
    },
    toggleButton: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid",
        borderColor: theme.palette.divider,
        position: "sticky",
    },
    toggleContainer: {
        display: "flex",
        flexDirection: "column",
        position: "sticky",
        top: "64px",
        height: "calc(100vh - 64px)",
    },
    treePaper: {
        overflowY: "auto",
        padding: theme.spacing(1),
    },
}))
interface TopLevelBranch {
    id: ConceptType
    name: ConceptType
    type: boolean
    children: Category[]
}
export const ConceptIcon = ({ type }) => {
    const TypeIcon = getConceptTypeIcon(type)
    return <TypeIcon />
}
export default function Toolkit() {
    const classes = useStyles()
    const [limit, setLimit] = useState(5)
    const { currentUser } = useAuth()
    const [selectedNode, setSelectedNode] = useState(null)
    const [itemsPerRow, setItemsPerRow] = useState(1)
    const [columnSelectValue, setColumnSelectValue] = useState(3)
    const [showSidebar, setShowSidebar] = useState(true)
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    let filter = { createdBy: { userId: currentUser.userId } }
    if (selectedNode) {
        if (selectedNode.type) {
            filter["type"] = selectedNode.name
        } else {
            filter["category"] = { id: selectedNode.id }
        }
    }
    const { t } = useAwaitTranslation("ideas")
    const {
        loading: loadingCategories,
        data: categoryData,
        error: categoryError,
    } = useQuery<CategoryQuery, CategoryQueryVariables>(GET_CATEGORIES)

    if (loadingCategories) {
        return <Loading size={150} />
    }
    const categories = categoryData?.Category ?? []
    const roots = categories?.filter((i) => i.parent === null)
    const alignment = itemsPerRow === 1 ? "column" : "grid"
    const handleAlignment = (_, val) => {
        if (val === "column") {
            setItemsPerRow(1)
        } else if (val === "grid") {
            setItemsPerRow(columnSelectValue)
        }
    }
    const sortedData: Array<TopLevelBranch> = ConceptTypes.map(
        (type: ConceptType) => ({
            id: type,
            name: type,
            children: roots.filter((cat) => cat.conceptType === type),
            type: true,
        })
    )
    return (
        <div className={classes.root}>
            <Slide
                in={!mobile ? true : !showSidebar}
                direction="right"
                unmountOnExit
                mountOnEnter
            >
                <Box width="100%">
                    {selectedNode && (
                        <Box className={classes.fixedSelectedNodeContainer}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box className={classes.selectedNodeContainer}>
                                    <ConceptIcon
                                        type={
                                            selectedNode.type
                                                ? selectedNode.name
                                                : selectedNode.conceptType
                                        }
                                    />

                                    <Box p={1} ml={0.5}>
                                        {selectedNode.type ? (
                                            <Typography variant="h6">
                                                {selectedNode.name}
                                            </Typography>
                                        ) : (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Typography variant="h6">
                                                    {selectedNode.conceptType}
                                                </Typography>
                                                <ChevronRightIcon />
                                                <Typography variant="h6">
                                                    {selectedNode.name}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Box mr={2}>
                                    <IconButton
                                        onClick={() => {
                                            setSelectedNode(null)
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Divider />
                        </Box>
                    )}
                    <Box>
                        <ToolkitFeed
                            setLimit={(newLimit: number) => {
                                setLimit(newLimit)
                            }}
                            filter={filter}
                            limit={limit}
                            itemsPerRow={itemsPerRow}
                        />
                    </Box>
                </Box>
            </Slide>

            <Box className={classes.toggleContainer}>
                <Box height="1em" display="flex" justifyContent="center">
                    <Divider orientation="vertical" />
                </Box>
                <Tooltip title="Toggle sidebar">
                    <IconButton
                        className={classes.toggleButton}
                        onClick={() => setShowSidebar(!showSidebar)}
                        style={{ padding: "0px" }}
                    >
                        {!showSidebar ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </Tooltip>
                <Box height="100%" display="flex" justifyContent="center">
                    <Divider orientation="vertical" />
                </Box>
            </Box>
            {showSidebar && (
                <Slide
                    direction="left"
                    in={showSidebar}
                    timeout={{ enter: 300, exit: 1000 }}
                >
                    <Box className={classes.treeContainer}>
                        {!mobile && (
                            <Box
                                mb={1}
                                display="flex"
                                justifyContent="space-between"
                                p={1}
                            >
                                <ToggleButtonGroup
                                    value={alignment}
                                    onChange={handleAlignment}
                                    exclusive
                                    size="small"
                                >
                                    <ToggleButton value="column">
                                        <ViewListIcon
                                            style={{
                                                transform: "rotate(180deg)",
                                            }}
                                        />
                                    </ToggleButton>
                                    <ToggleButton value="grid">
                                        <ViewModuleIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <Box display="flex">
                                    <InputLabel
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginRight: "8px",
                                            textAlign: "center",
                                        }}
                                        id="column-select-label"
                                    >
                                        {t("column", "Column")} <br />
                                        {t("count", "Count")}
                                    </InputLabel>
                                    <Select
                                        labelId="column-select-label"
                                        variant="outlined"
                                        SelectDisplayProps={{
                                            style: {
                                                paddingTop: "0.5em",
                                                paddingBottom: "0.5em",
                                            },
                                        }}
                                        onChange={(ev) => {
                                            let val = ev.target.value as number
                                            setItemsPerRow(val)
                                            if (val > 1) {
                                                setColumnSelectValue(val)
                                            }
                                        }}
                                        value={itemsPerRow}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                        )}
                        <Divider />
                        <Paper className={classes.treePaper}>
                            <RecursiveTreeView
                                data={sortedData}
                                onNodeSelection={(node) => {
                                    setSelectedNode(node)
                                    setLimit(0)
                                }}
                                selectedNode={selectedNode}
                            />
                        </Paper>
                    </Box>
                </Slide>
            )}
        </div>
    )
}

interface ToolkitFeedProps {
    filter: _ConceptFilter
    limit: number
    setLimit: (limit: number) => void
    itemsPerRow: number
}
const ToolkitFeed = (props: ToolkitFeedProps) => {
    const { filter, limit, setLimit, itemsPerRow } = props
    const { loading, error, data, fetchMore } = useQuery<
        MyConceptsQueryQuery,
        MyConceptsQueryQueryVariables
    >(MY_CONCEPTS_QUERY, {
        variables: {
            filter: filter,
            offset: 0,
            first: limit,
        },
    })

    const { t } = useAwaitTranslation("concepts")
    const onFetchMore = useCallback(
        async (offset) => {
            if (!fetchMore) return
            const result = await fetchMore({
                variables: {
                    offset: offset,
                },
            })

            setLimit(offset)

            // @ts-expect-error it should have been typed
            return result.data?.Concept.length >= 5
        },
        [fetchMore, setLimit]
    )
    let allConcepts = data?.Concept ?? []
    return (
        <Box width="100%" key={JSON.stringify(filter)}>
            <VirtualInfiniteList
                onFetchMore={onFetchMore}
                pageSize={5}
                size={allConcepts.length}
                startOffset={limit}
                key={JSON.stringify(filter)}
            >
                {(itemIndex) => (
                    <Box
                        display="grid"
                        gridTemplateColumns={`repeat(${itemsPerRow}, 1fr)`}
                        gridGap="15px"
                    >
                        {new Array(itemsPerRow).fill(1).map((_, index) => {
                            const item =
                                allConcepts[itemIndex * itemsPerRow + index]
                            return item ? (
                                <ConceptCard
                                    key={item.id}
                                    item={item}
                                    grid={itemsPerRow > 1}
                                />
                            ) : null
                        })}
                    </Box>
                )}
            </VirtualInfiniteList>
            {allConcepts.length === 0 && !loading && (
                <Box
                    display="flex"
                    alignItems="center"
                    p={2}
                    justifyContent="center"
                    width="100%"
                >
                    <Typography variant="body1">
                        {t("noResults", "No results")}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

interface RecursiveTreeViewProps {
    data: TopLevelBranch[]
    onNodeSelection: (node: TopLevelBranch | Category | any) => void
    selectedNode: TopLevelBranch | Category | any
}
const RecursiveTreeView = (props: RecursiveTreeViewProps) => {
    const { data, onNodeSelection, selectedNode } = props
    return (
        <TreeView
            defaultExpanded={ConceptTypes}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {data.map((x) => {
                return (
                    <TreeItemContainer
                        node={x}
                        onNodeSelection={onNodeSelection}
                        selectedNode={selectedNode}
                    />
                )
            })}
        </TreeView>
    )
}

const TreeItemContainer = (props: {
    node: TopLevelBranch | Category | any // need to figure out the typing of this
    onNodeSelection: (node: TopLevelBranch | Category | any) => void
    selectedNode: TopLevelBranch | Category | any
}) => {
    const { node, onNodeSelection, selectedNode } = props
    const classes = useStyles()
    return (
        <Box key={node.id}>
            <TreeItem
                key={node.id}
                nodeId={node.id}
                onLabelClick={(event) => {
                    event.preventDefault()
                    onNodeSelection(node)
                }}
                classes={{
                    label:
                        selectedNode?.id === node.id
                            ? classes.selectedTreeItem
                            : classes.treeItem,
                    iconContainer:
                        node.children.length > 0
                            ? classes.treeIconContainer
                            : classes.emptyTreeIconContainer,
                }}
                label={
                    <>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box display="flex" alignItems="center">
                                {node.type && <ConceptIcon type={node.name} />}
                                <Box p={1}>
                                    <Box p={0.25}>{node.name}</Box>
                                    {!!node.description && (
                                        <Box
                                            p={0.25}
                                            className={classes.description}
                                        >
                                            {node.description}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </>
                }
            >
                {Array.isArray(node.children)
                    ? node.children.map((node) => (
                          <TreeItemContainer
                              node={node}
                              onNodeSelection={onNodeSelection}
                              selectedNode={selectedNode}
                          />
                      ))
                    : null}
            </TreeItem>
        </Box>
    )
}
