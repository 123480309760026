import React, { useEffect, useState } from "react"
import LibraryAddIcon from "@material-ui/icons/LibraryAdd"
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck"
import IconButton from "@material-ui/core/IconButton"
import { useApolloClient, gql, useMutation } from "@apollo/client"
import { useTheme } from "@material-ui/core/styles"
import {
    ADD_CONCEPT_TO_LIST,
    REMOVE_CONCEPT_FROM_LIST,
    CONCEPT_LIST_UPDATE,
} from "../../graphql/conceptListQueries"
import {
    AddConceptInListsMutation,
    AddConceptInListsMutationVariables,
    RemoveConceptInListsMutation,
    RemoveConceptInListsMutationVariables,
    UpdateConceptListMutation,
    UpdateConceptListMutationVariables,
} from "../../graphql/__generated__/conceptListQueries"
import Tooltip from "@material-ui/core/Tooltip"
import { useAuth } from "../../providers/AuthProvider"
import { useGlobalStyles } from "../../styles/globalStyles"
import { Concept } from "../../__generated__/types"

interface WorkspaceToggleProps {
    item: Concept
    disableRipple?: boolean
    size: "medium" | "small"
    location?: string
}

export default function WorkspaceToggle(props: WorkspaceToggleProps) {
    const theme = useTheme()
    const client = useApolloClient()
    const iconStyles = useGlobalStyles()
    const { currentUser } = useAuth()
    const [inUserWorkspace, setInUserWorkspace] = useState(false)
    const [userWorkspaceId, setUserWorkspaceId] = useState("")
    const [addToWorkspace] = useMutation<
        AddConceptInListsMutation,
        AddConceptInListsMutationVariables
    >(ADD_CONCEPT_TO_LIST)
    const [removeFromWorkspace] = useMutation<
        RemoveConceptInListsMutation,
        RemoveConceptInListsMutationVariables
    >(REMOVE_CONCEPT_FROM_LIST)
    const [listUpdate] = useMutation<
        UpdateConceptListMutation,
        UpdateConceptListMutationVariables
    >(CONCEPT_LIST_UPDATE)

    useEffect(() => {
        if (
            currentUser &&
            currentUser.conceptLists &&
            currentUser.conceptLists.length > 0
        ) {
            const userList = currentUser.conceptLists.filter(
                (c) => c.type === "USER_WORKSPACE"
            )?.[0]
            if (userList && userList.concepts) {
                setUserWorkspaceId(userList.id)
            }
            const list = client.readFragment({
                id: client.cache.identify(userList),
                fragment: gql`
                    fragment Workspace on ConceptList {
                        id
                        concepts {
                            id
                        }
                    }
                `,
            })
            if (list) {
                setInUserWorkspace(
                    list.concepts.filter((c) => c.id === props.item.id).length >
                        0
                )
            }
        }
    }, [currentUser, client, setInUserWorkspace, props.item.id])

    const handleAddToWorkspace = async (ev) => {
        ev.stopPropagation()
        if (inUserWorkspace) {
            setInUserWorkspace(false)
            const result = await removeFromWorkspace({
                variables: {
                    from: { id: props.item.id },
                    to: { id: userWorkspaceId },
                },
            })
            if (result.data && result.data.RemoveConceptInLists) {
                let conceptList = result.data?.RemoveConceptInLists?.to
                let linkedConceptIds = conceptList.concepts.map((c) => c.id)
                let newIds = conceptList.conceptIds.filter(
                    (cid) => linkedConceptIds.indexOf(cid) !== -1
                )

                listUpdate({
                    variables: {
                        id: conceptList.id,
                        conceptIds: newIds,
                    },
                })
            }
        } else {
            setInUserWorkspace(true)
            const result = await addToWorkspace({
                variables: {
                    from: { id: props.item.id },
                    to: { id: userWorkspaceId },
                },
            })
            if (result.data && result.data.AddConceptInLists) {
                let conceptList = result.data?.AddConceptInLists?.to
                let newIds = conceptList.conceptIds
                    ? [...conceptList.conceptIds]
                    : []
                conceptList.concepts.forEach((c) => {
                    if (newIds.indexOf(c.id) === -1) {
                        newIds.push(c.id)
                    }
                    return c.id
                })

                listUpdate({
                    variables: {
                        id: conceptList.id,
                        conceptIds: newIds,
                    },
                })
            }
        }
    }

    return (
        <Tooltip
            key={props.item.id}
            title={
                inUserWorkspace
                    ? "Remove from your workspace"
                    : "Add to your workspace"
            }
        >
            <IconButton
                onClick={handleAddToWorkspace}
                disableRipple={props.disableRipple ? true : false}
                size={props.size}
                // dont like this, but since its in between 2 iconButtons with counts on the ConceptCard, it has to have it or it will look funny - definitely a better way to do this
            >
                {inUserWorkspace ? (
                    <LibraryAddCheckIcon
                        className={
                            props.location === "Concept Card"
                                ? iconStyles.smallLabelStylesIcon
                                : props.size !== "small"
                                ? iconStyles.labelStylesIcon
                                : ""
                        }
                        style={{ color: theme.palette.primary.main }}
                    />
                ) : (
                    <LibraryAddIcon
                        className={
                            props.location === "Concept Card"
                                ? iconStyles.smallLabelStylesIcon
                                : props.size !== "small"
                                ? iconStyles.labelStylesIcon
                                : ""
                        }
                    />
                )}
            </IconButton>
        </Tooltip>
    )
}
