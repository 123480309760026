import { JSONSchema4Type } from "json-schema"
import { ItemPrefixType } from "../../../../__generated__/types"

export const ConceptFeedSchema: JSONSchema4Type = {
    options: {
        template: true,
    },
    required: ["label", "orderBy"],
    properties: {
        label: {
            type: "string",
        },
        orderBy: {
            type: "string",
            enum: [
                "createdAt_desc",
                "createdAt_asc",
                "title_asc",
                "title_desc",
            ],
        },
        view: {
            type: "string",
            enum: ["list", "gallery"],
        },
        source: {
            type: "string",
            enum: ["allConcepts", "connections"],
        },
        filters: {
            type: "filters",
        },
    },
}
export const HeadingSchema: JSONSchema4Type = {
    required: ["label", "size"],
    options: {
        template: true,
    },
    properties: {
        label: {
            type: "string",
        },
        size: {
            type: "string",
            enum: ["small", "medium", "large"],
        },
        showDivider: {
            type: "boolean",
        },
    },
}
export const ConceptListSchema: JSONSchema4Type = {
    options: {
        template: false,
    },
    required: ["label", "conceptListId"],
    properties: {
        label: {
            type: "string",
        },
        conceptListId: {
            type: "conceptListSingle",
            options: {
                hide: true,
            },
        },
        description: {
            type: "string",
        },
        itemPrefixType: {
            type: "string",
            enum: [
                ItemPrefixType.CHECKBOX.toLowerCase(),
                ItemPrefixType.NUMBER.toLowerCase(),
                ItemPrefixType.NONE.toLowerCase(),
            ],
        },
    },
}
export const ConceptSchema: JSONSchema4Type = {
    options: {
        template: true,
    },
    required: [],
    properties: {
        label: {
            type: "string",
        },
        view: {
            type: "string",
            enum: ["Card", "Full Preview"],
        },
        conceptId: {
            type: "conceptSingle",
            options: {
                hide: true,
            },
        },
    },
}
export const ChartSchema: JSONSchema4Type = {
    options: {
        template: true,
    },
    required: ["label"],
    properties: {
        label: {
            type: "string",
        },
        source: {
            type: "string",
            enum: ["allConcepts", "connections"],
        },
        filters: {
            type: "filters",
        },
    },
}

export const BubbleChartSchema: JSONSchema4Type = {
    options: {
        template: true,
    },
    required: ["source", "label"],
    properties: {
        label: {
            type: "string",
        },
        source: {
            type: "string",
            enum: ["allConcepts", "connections"],
        },
        filters: {
            type: "filters",
        },
        criteria: {
            type: "criteriaMultiple",
        },
    },
}

export const RichTextEditorSchema: JSONSchema4Type = {
    options: {
        template: true,
    },
    required: ["label"],
    properties: {
        label: {
            type: "string",
        },
    },
}

export const ExerciseSchema: JSONSchema4Type = {
    options: {
        template: false,
    },
    required: [],
    properties: {
        label: {
            type: "string",
        },
        exerciseId: {
            options: {
                hide: true,
            },
            type: "exerciseSingle",
        },
    },
}

export const CriteriaFieldSchema: JSONSchema4Type = {
    options: {
        template: false,
    },
    required: [],
    properties: {
        label: {
            type: "string",
            options: {
                hide: true,
                locked: true,
            },
        },
        criteria: {
            type: "criteriaSingle",
            options: {
                hide: true,
            },
        },
        conceptId: {
            type: "conceptSingle",
            options: {
                hide: true,
            },
        },
    },
}
export const CriteriaRadarSchema: JSONSchema4Type = {
    options: {
        template: false,
    },
    required: [],
    properties: {
        label: {
            type: "string",
        },
        criteria: {
            type: "criteriaMultiple",
        },
        conceptIds: {
            type: "conceptMultiple",
            options: {
                hide: true,
            },
        },
    },
}

export const UnknownSchema: JSONSchema4Type = {
    required: [],
    properties: {},
}
