import { useEffect, useCallback } from "react"
import { Box, Paper } from "@material-ui/core"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import { CRITERIA_FILTERED } from "../../criteria/graphql"
import { useQuery, useMutation, useReactiveVar } from "@apollo/client"
import { permissionsVar } from "../../../providers/GlobalState"
import { useParams } from "react-router-dom"
import { Criteria } from "../../../__generated__/types"
import {
    CREATE_CRITERIA,
    ADD_CONCEPT_CRITERIA,
    CREATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_CRITERIA,
    UPDATE_CRITERIA_SCORE,
} from "../../criteria/graphql"
import {
    CreateCriteriaMutation,
    CreateCriteriaMutationVariables,
    CreateCriteriaScoreMutation,
    CreateCriteriaScoreMutationVariables,
    AddCriteriaScoreCriteriaMutation,
    AddCriteriaScoreCriteriaMutationVariables,
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    AddCriteriaConceptMutation,
    AddCriteriaConceptMutationVariables,
} from "../../criteria/__generated__/graphql"
import {
    CriteriaQuery,
    CriteriaQueryVariables,
} from "../../criteria/__generated__/graphql"
import debounce from "p-debounce"
import RichTextEditor from "../../RichTextEditor"
import { CriteriaType } from "../../criteria/types"
type WidgetConfig = {
    label: string
    criteria: Pick<Criteria, "name" | "criteriaType">
    conceptId?: string
}

const CriteriaFieldWidget: WidgetTypeComponent = (props) => {
    const { conceptId } = useParams()
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const [createCriteria] = useMutation<
        CreateCriteriaMutation,
        CreateCriteriaMutationVariables
    >(CREATE_CRITERIA)
    const [addToConcept] = useMutation<
        AddCriteriaConceptMutation,
        AddCriteriaConceptMutationVariables
    >(ADD_CONCEPT_CRITERIA)
    const [createCriteriaScore] = useMutation<
        CreateCriteriaScoreMutation,
        CreateCriteriaScoreMutationVariables
    >(CREATE_CRITERIA_SCORE)
    const [addCriteriaScore] = useMutation<
        AddCriteriaScoreCriteriaMutation,
        AddCriteriaScoreCriteriaMutationVariables
    >(ADD_CRITERIA_SCORE_CRITERIA)
    const [updateScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const editPermission = useReactiveVar(permissionsVar)
    const { data, refetch } = useQuery<CriteriaQuery, CriteriaQueryVariables>(
        CRITERIA_FILTERED,
        {
            variables: {
                filter: {
                    name: config?.criteria?.name,
                    concept: {
                        id: config?.conceptId ?? conceptId,
                    },
                },
            },
            skip: !config?.criteria,
        }
    )
    const criteria = data?.Criteria?.[0] ?? null
    const score = criteria?.scores?.[0] ?? null
    const createNewCriteria = useCallback(async () => {
        const {
            data: { CreateCriteria: NewCriteria },
        } = await createCriteria({
            variables: {
                ...config?.criteria,
                isDefault: false,
            },
        })
        const {
            data: { CreateCriteriaScore: NewCriteriaScore },
        } = await createCriteriaScore({
            variables: {
                value: 0,
                notes: "",
            },
        })
        await Promise.all([
            addToConcept({
                variables: {
                    criteriaId: NewCriteria.id,
                    conceptId: conceptId,
                },
            }),
            addCriteriaScore({
                variables: {
                    criteriaId: NewCriteria.id,
                    criteriaScoreId: NewCriteriaScore.id,
                },
            }),
        ])
        await refetch()
    }, [
        config?.criteria,
        addToConcept,
        createCriteriaScore,
        createCriteria,
        addCriteriaScore,
        conceptId,
        refetch,
    ])
    useEffect(() => {
        if (!!data && !criteria) {
            createNewCriteria()
        }
    }, [data, createNewCriteria, criteria])
    const onEditValue = debounce(
        useCallback(
            async (value: string) => {
                await updateScore({
                    variables: {
                        id: score?.id,
                        notes: value,
                    },
                })
            },
            [updateScore, score]
        ),
        1000
    )
    return (
        <Box
            component={Paper}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            key={props.widget.id}
        >
            <WidgetHeader
                label={config?.label}
                actions={[]}
                widget={props.widget}
                editing={props.editing}
            />
            {!!isInitialized &&
            !!criteria &&
            criteria.criteriaType === CriteriaType.RichTextEditor ? (
                <RichTextEditor
                    editorId={score?.id + props.widget.id}
                    initialValue={score?.notes || '[{"children":[{"text":""}]'}
                    onChange={(value: string) => {
                        onEditValue(value)
                    }}
                    isReadOnly={!editPermission}
                />
            ) : null}
        </Box>
    )
}

export default CriteriaFieldWidget
