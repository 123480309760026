import Avatar from "@material-ui/core/Avatar"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { User, SystemRole } from "../../__generated__/types"
import { Link, Box } from "@material-ui/core"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { Link as LinkRouter } from "react-router-dom"
import dayjs from "dayjs"
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        padding: theme.spacing(1),
        height: "100%",
    },
    avatar: {
        minWidth: theme.spacing(4),
        minHeight: theme.spacing(4),
        margin: "auto",
    },
    name: {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: theme.typography.h6.letterSpacing,
        marginBottom: 0,
        marginTop: 0,
        display: "flex",
        justifyContent: "space-between",
    },
    label: {
        color: theme.palette.text.hint,
        fontSize: "0.8em",
        marginBottom: theme.spacing(0.5),
    },
    prop: {
        padding: theme.spacing(1),
    },
    iconContainer: {
        position: "relative",
        width: "100%",
    },
    input: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        borderRadius: theme.shape.borderRadius,
        paddingLeft: theme.spacing(0.5),
    },
    actionHover: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        borderRadius: theme.shape.borderRadius,
        paddingLeft: theme.spacing(0.5),
    },
    tableRow: {
        cursor: "pointer",
    },
}))

interface UserManagerLineItemProps {
    user: User
    focused: boolean
    onFocus: () => void
}

export default function UserManagerLineItem(props: UserManagerLineItemProps) {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <>
            <TableRow
                hover
                className={classes.tableRow}
                onClick={() => props.onFocus()}
            >
                <TableCell size="small">
                    <Avatar
                        src={props.user.imageUrl}
                        style={{
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.primary.main,
                        }}
                        className={classes.avatar}
                    >
                        <Box>
                            {props.user.firstName?.[0]}
                            {props.user.lastName?.[0]}
                        </Box>
                    </Avatar>
                </TableCell>
                <TableCell size="small">
                    <Link
                        component={LinkRouter}
                        to={`/user/${props.user?.username}/home`}
                    >
                        {`${props.user.lastName}, ${props.user.firstName}`}
                    </Link>
                </TableCell>

                <TableCell>
                    <Link href={`mailto: ${props.user.email}`}>
                        {props.user.email}
                    </Link>
                </TableCell>
                <TableCell size="small">
                    {props.user.role ?? SystemRole.GUEST}
                </TableCell>
                <TableCell size="small">
                    {!!props.user.lastLogin.formatted
                        ? dayjs(props.user.lastLogin?.formatted).fromNow()
                        : "Yet to login"}
                </TableCell>
            </TableRow>
        </>
    )
}
