import React, { useRef } from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Grid from "@material-ui/core/Grid"
import { useMediaQuery } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useLazyQuery } from "@apollo/client"
import { getConceptTypeIcon } from "../util/ConceptTypeStyles"
import { InputAdornment } from "@material-ui/core"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import LinkIcon from "@material-ui/icons/Link"
import { SEARCH_CONCEPTS } from "../graphql/conceptListQueries"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import Dialog from "@material-ui/core/Dialog"
import ConceptForm from "./ConceptForm"
import { Concept } from "../__generated__/types"
const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    searchBar: {
        width: "250px !important",
    },
    inputBox: {
        boxSizing: "border-box",
    },
    listbox: {
        overflow: "auto",
    },
}))

export interface ConceptInterface {
    id?: string
    __typename?: string
    title: string
    type?: string
    summary?: string
    description?: string
    inputValue?: string
}

interface LinkRelatedProps {
    linkFunction: (item: Concept) => void
    concepts?: Concept[]
    type?: string
    item?: Concept
    filterOut?: any[]
    autoFocus?: boolean
    inputAdornment?: JSX.Element
    placeholder?: string
    variant?: "filled" | "standard" | "outlined"
}

export default function LinkRelated(props: LinkRelatedProps) {
    const classes = useStyles()
    const [value, setValue] = React.useState<ConceptInterface | null>(null)
    const [inputValue, setInputValue] = React.useState("")
    const [open, setOpen] = React.useState(false)
    const [newConcept, setNewConcept] = React.useState<ConceptInterface | null>(
        null
    )
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const [options, setOptions] = React.useState<ConceptInterface[]>([])
    const [loadResults, { loading, error, data }] = useLazyQuery(
        SEARCH_CONCEPTS
    )
    const ref: any = useRef()
    const { t } = useAwaitTranslation("concepts")

    React.useEffect(() => {
        let active = true
        if (active) {
            if (!inputValue || inputValue === "") {
                setOptions(value ? [value] : [])
                return undefined
            }
            loadResults({ variables: { searchString: inputValue } })
            if (data) {
                const filteredData = props.type
                    ? data.fuzzyConceptSearch.filter((x) => {
                          return x.type === props.type
                      })
                    : props.filterOut
                    ? data.fuzzyConceptSearch.filter((x) => {
                          return props.filterOut.indexOf(x.type) === -1
                      })
                    : data.fuzzyConceptSearch
                let newOptions = [] as ConceptInterface[]
                if (value) {
                    newOptions = [value]
                }

                if (data) {
                    newOptions = [...newOptions, ...filteredData]?.filter(
                        (x) => !!x.isPublic
                    )
                }
                setOptions(newOptions)
            }
        }
        return () => {
            active = false
        }
    }, [value, inputValue, loadResults, data, props.type, props.filterOut])
    const selectConcept = (newValue) => {
        if (newValue?.id !== props.item?.id && !!props.linkFunction) {
            props.linkFunction(newValue)
        }
        setOptions(options)
        setValue(null)
    }
    const AdornmentIcon = props.inputAdornment ?? <LinkIcon />
    return (
        <div style={{ display: "inline-flex", flexGrow: 1, width: "100%" }}>
            <Autocomplete
                style={{ flexGrow: 1 }}
                classes={{
                    focused: classes.searchBar,
                    listbox: classes.listbox,
                }}
                size="small"
                getOptionLabel={(option) =>
                    typeof option === "string"
                        ? option
                        : option.inputValue
                        ? option.inputValue
                        : option.title
                }
                filterOptions={(options, params) => {
                    if (params.inputValue !== "") {
                        options.push({
                            inputValue: params.inputValue,
                            title: `${t("create", "Create")} "${
                                params.inputValue
                            }"`,
                        })
                    }
                    return options
                }}
                options={options}
                loading={loading}
                openOnFocus={true}
                noOptionsText={t("noResults", "No results")}
                includeInputInList
                forcePopupIcon={false}
                selectOnFocus
                handleHomeEndKeys
                freeSolo
                filterSelectedOptions
                value={value}
                blurOnSelect={true}
                clearOnBlur={true}
                getOptionDisabled={(option) =>
                    props.concepts?.filter((x) => x.id === option.id)?.length >
                    0
                }
                onChange={(event: any, newValue: any, reason: any) => {
                    if (typeof newValue === "string") {
                        setOpen(true)
                        setNewConcept({
                            title: newValue,
                            type: props.type,
                        })
                    } else if (newValue && newValue.inputValue) {
                        setOpen(true)
                        setNewConcept({
                            title: newValue.inputValue,
                            type: props.type,
                        })
                    } else if (
                        reason === "select-option" &&
                        typeof newValue !== "string"
                    ) {
                        selectConcept(newValue)
                    } else {
                        setOptions(newValue ? [newValue, ...options] : options)
                        setValue(newValue)
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                renderInput={(params) => (
                    <TextField
                        autoFocus={!props.autoFocus ? props.autoFocus : true}
                        inputRef={ref}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start">
                                        {AdornmentIcon}
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                        }}
                        variant={props.variant ?? "standard"}
                        fullWidth
                        placeholder={
                            props.placeholder ??
                            `${t("typeToLink", "Type to Link a")} ${
                                props.type || t("concept", "Concept")
                            }...`
                        }
                    />
                )}
                renderOption={(option) => {
                    const matches = match(option.title, inputValue)
                    const parts = parse(option.title, matches)
                    const TypeIcon = getConceptTypeIcon(
                        option.rootCategory?.name
                    )
                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                {TypeIcon && (
                                    <TypeIcon className={classes.icon} />
                                )}
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight
                                                ? 800
                                                : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {option.summary}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }}
            />
            {open && (
                <Dialog
                    open={true}
                    fullScreen={fullScreen}
                    fullWidth={!fullScreen}
                    disableBackdropClick
                >
                    <ConceptForm
                        concept={newConcept}
                        closeForm={() => setOpen(false)}
                        creationCallback={(concept: Concept) =>
                            selectConcept(concept)
                        }
                    />
                </Dialog>
            )}
        </div>
    )
}
