import { TypedTypePolicies } from "./__generated__/apollo_cache_config_types"
import generatedIntrospection from "./__generated__/apollo_cache_config_fragment"

const TYPE_POLICIES: TypedTypePolicies = {
    Query: {
        fields: {
            Concept: {
                // Tell apollo-cache that we should separate cache
                // if any of the variables for the query are different,
                // except offset and first as they are used for pagination
                // @see https://www.apollographql.com/docs/react/pagination/key-args/
                keyArgs: (args) =>
                    Object.keys(args).filter(
                        (arg) => arg !== "offset" && arg !== "first"
                    ),
                merge(existing, incoming, { args }) {
                    const merged = existing ? existing.slice(0) : []
                    // Insert the incoming elements in the right places, according to args.
                    // This code avoids the problem that if we have enough data for 10 pages
                    // but the user is requesting the second page and we don't have
                    // then this will insert it at the right place
                    if (
                        typeof args.offset !== "undefined" &&
                        typeof args.first !== "undefined"
                    ) {
                        const end =
                            args.offset + Math.min(args.first, incoming.length)
                        for (let i = args.offset; i < end; ++i) {
                            merged[i] = incoming[i - args.offset]
                        }
                    } else {
                        merged.push(...incoming)
                    }

                    return merged
                },
                // Returning false on read indicates that we need to do a network call
                read(existing, { args }) {
                    // If we read the field before any data has been written to the
                    // cache, existing will return undefined and we return false
                    // which correctly indicates that the field is missing.
                    if (!existing) return false

                    // If offset and first are given,
                    // we need to slice our cache to only return what was requested
                    // e.g: if we have 10 items on cache due to a previous query
                    //      but only need the first 5
                    // @see https://www.apollographql.com/docs/react/pagination/core-api/#paginated-read-functions
                    if (
                        typeof args.offset !== "undefined" &&
                        typeof args.first !== "undefined"
                    ) {
                        const page = existing.slice(
                            args.offset,
                            args.offset + args.first
                        )

                        // If we ask for a page outside the bounds of the existing array,
                        // page.length will be 0, and we should return false instead of
                        // the empty array.
                        return page.length > 0 ? page : false
                    }

                    return existing
                },
            },
            Comment: {
                // Tell apollo-cache that we should separate cache
                // if any of the variables for the query are different,
                // except offset and first as they are used for pagination
                // @see https://www.apollographql.com/docs/react/pagination/key-args/
                keyArgs: (args) =>
                    Object.keys(args).filter(
                        (arg) => arg !== "offset" && arg !== "first"
                    ),
                merge(existing, incoming, { args }) {
                    const merged = existing ? existing.slice(0) : []
                    // Insert the incoming elements in the right places, according to args.
                    // This code avoids the problem that if we have enough data for 10 pages
                    // but the user is requesting the second page and we don't have
                    // then this will insert it at the right place
                    if (
                        typeof args.offset !== "undefined" &&
                        typeof args.first !== "undefined"
                    ) {
                        const end =
                            args.offset + Math.min(args.first, incoming.length)
                        for (let i = args.offset; i < end; ++i) {
                            merged[i] = incoming[i - args.offset]
                        }
                    } else {
                        merged.push(...incoming)
                    }

                    return merged
                },
                // Returning false on read indicates that we need to do a network call
                read(existing, { args }) {
                    // If we read the field before any data has been written to the
                    // cache, existing will return undefined and we return false
                    // which correctly indicates that the field is missing.
                    if (!existing) return false

                    // If offset and first are given,
                    // we need to slice our cache to only return what was requested
                    // e.g: if we have 10 items on cache due to a previous query
                    //      but only need the first 5
                    // @see https://www.apollographql.com/docs/react/pagination/core-api/#paginated-read-functions
                    if (
                        typeof args.offset !== "undefined" &&
                        typeof args.first !== "undefined"
                    ) {
                        const page = existing.slice(
                            args.offset,
                            args.offset + args.first
                        )

                        // If we ask for a page outside the bounds of the existing array,
                        // page.length will be 0, and we should return false instead of
                        // the empty array.
                        return page.length > 0 ? page : false
                    }

                    return existing
                },
            },
            Notification: {
                // Tell apollo-cache that we should separate cache
                // if any of the variables for the query are different,
                // except offset and first as they are used for pagination
                // @see https://www.apollographql.com/docs/react/pagination/key-args/
                keyArgs: (args) =>
                    Object.keys(args).filter(
                        (arg) => arg !== "offset" && arg !== "first"
                    ),
                merge(existing, incoming, { args }) {
                    const merged = existing ? existing.slice(0) : []
                    // Insert the incoming elements in the right places, according to args.
                    // This code avoids the problem that if we have enough data for 10 pages
                    // but the user is requesting the second page and we don't have
                    // then this will insert it at the right place
                    if (
                        typeof args.offset !== "undefined" &&
                        typeof args.first !== "undefined"
                    ) {
                        const end =
                            args.offset + Math.min(args.first, incoming.length)
                        for (let i = args.offset; i < end; ++i) {
                            merged[i] = incoming[i - args.offset]
                        }
                    } else {
                        merged.push(...incoming)
                    }

                    return merged
                },
                // Returning false on read indicates that we need to do a network call
                read(existing, { args }) {
                    // If we read the field before any data has been written to the
                    // cache, existing will return undefined and we return false
                    // which correctly indicates that the field is missing.
                    if (!existing) return false

                    // If offset and first are given,
                    // we need to slice our cache to only return what was requested
                    // e.g: if we have 10 items on cache due to a previous query
                    //      but only need the first 5
                    // @see https://www.apollographql.com/docs/react/pagination/core-api/#paginated-read-functions
                    if (
                        typeof args.offset !== "undefined" &&
                        typeof args.first !== "undefined"
                    ) {
                        const page = existing.slice(
                            args.offset,
                            args.offset + args.first
                        )

                        // If we ask for a page outside the bounds of the existing array,
                        // page.length will be 0, and we should return false instead of
                        // the empty array.
                        return page.length > 0 ? page : false
                    }

                    return existing
                },
            },
        },
    },
    Concept: {
        keyFields: ["id"],
        fields: {
            children: {
                merge: false,
            },
        },
    },
    ConceptList: {
        keyFields: ["id"],
        fields: {
            concepts: {
                merge: false,
                keyArgs: false,
            },
            conceptIds: {
                merge: false,
            },
        },
    },
    ConceptBoard: {
        keyFields: ["id"],
    },
    Link: {
        keyFields: ["id"],
    },
    User: {
        keyFields: ["userId"],
        fields: {
            watchedConcepts: {
                merge: false,
            },
            conceptLists: {
                merge: false,
            },
        },
    },
    Comment: {
        keyFields: ["commentId"],
        fields: {
            likedBy: {
                merge: false,
            },
        },
    },
    Team: {
        keyFields: ["teamId"],
    },
    Tag: {
        keyFields: ["name"],
    },
    Industry: {
        keyFields: ["industryId"],
    },
    DesignTheme: {
        keyFields: ["themeId"],
    },
    Notification: {
        keyFields: ["id"],
    },
    Checklist: {
        keyFields: ["id"],
    },
    ChecklistItem: {
        keyFields: ["id"],
    },
    ChecklistType: {
        keyFields: ["id"],
    },
    Category: {
        keyFields: ["id"],
    },
    DashboardView: {
        keyFields: ["id"],
    },
    Widget: {
        keyFields: ["id"],
    },
    WidgetType: {
        keyFields: ["id"],
    },
    Criteria: {
        keyFields: ["id"],
    },
    CriteriaScore: {
        keyFields: ["id"],
    },
    Exercise: {
        keyFields: ["id"],
    },
}
const APOLLO_CACHE_CONFIG = {
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: TYPE_POLICIES,
}

export default APOLLO_CACHE_CONFIG
