import React, { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import {
    Typography,
    DialogContent,
    Dialog,
    Tabs,
    Tab,
    TextField,
    DialogTitle,
    IconButton,
} from "@material-ui/core"
import { UPDATE_CATEGORY } from "./graphql"
import {
    UpdateCategoryMutation,
    UpdateCategoryMutationVariables,
} from "./__generated__/graphql"
import { Category } from "../../__generated__/types"
import EditIcon from "@material-ui/icons/Edit"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"

import CategoryCriteriaList from "./CategoryCriteriaList"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            fontSize: 12,
            color: theme.palette.text.hint,
            mb: theme.spacing(1),
        },
        container: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            height: "100%",
            width: "100%",
        },
        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
    })
)

interface CategoryManagerModalProps {
    category: Category
    onClose: () => void
}
const CategoryManagerModal = (props: CategoryManagerModalProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const [editing, setEditing] = useState(false)
    const [saving, setSaving] = useState(false)
    const [generalInfo, setGeneralInfo] = useState(null)
    const [updateCategory] = useMutation<
        UpdateCategoryMutation,
        UpdateCategoryMutationVariables
    >(UPDATE_CATEGORY)
    const [tab, setTab] = useState(0)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
    }
    useEffect(() => {
        setGeneralInfo({
            name: props.category?.name,
            description: props.category.description,
        })
    }, [props.category])

    const handleSaving = async () => {
        setSaving(true)

        await updateCategory({
            variables: {
                id: props.category?.id,
                name: generalInfo.name,
                description: generalInfo?.description,
            },
        })
        setEditing(false)
        setSaving(false)
    }

    const dataChanged =
        props.category?.name !== generalInfo?.name ||
        props.category?.description !== generalInfo?.description
    return (
        <Dialog
            open={true}
            onBackdropClick={() => {
                props.onClose()
            }}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                Category Manager - {props.category?.name}
                <IconButton
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => {
                        props.onClose()
                    }}
                >
                    <ClearIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers style={{ padding: 0, height: 500 }}>
                <Box display="flex" width="100%" height="100%">
                    <Box display="flex" flexShrink={0}>
                        <Tabs
                            className={classes.tabs}
                            orientation="vertical"
                            value={tab}
                            onChange={handleChange}
                        >
                            <Tab label="General Information" />
                            <Tab label="Criteria" />
                        </Tabs>
                    </Box>

                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        height="100%"
                    >
                        {tab === 0 && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                height="100%"
                                m={3}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Box>
                                        {editing ? (
                                            <Box display="flex">
                                                {!!dataChanged && (
                                                    <IconButton
                                                        onClick={handleSaving}
                                                        disabled={!!saving}
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                )}
                                                <IconButton
                                                    disabled={!!saving}
                                                    onClick={() => {
                                                        setGeneralInfo({
                                                            name:
                                                                props.category
                                                                    .name,
                                                            description:
                                                                props.category
                                                                    .description,
                                                        })
                                                        setEditing(false)
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </Box>
                                        ) : (
                                            <IconButton
                                                onClick={() => setEditing(true)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                    </Box>
                                </Box>

                                <Box className={classes.container} p={2}>
                                    {editing ? (
                                        <>
                                            <Box p={1}>
                                                <TextField
                                                    name="Name"
                                                    fullWidth
                                                    autoFocus
                                                    disabled={!!saving}
                                                    value={generalInfo.name}
                                                    onChange={(e) => {
                                                        setGeneralInfo({
                                                            ...generalInfo,
                                                            name:
                                                                e.target.value,
                                                        })
                                                    }}
                                                    label="Name"
                                                />
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    name="Name"
                                                    value={
                                                        generalInfo.description
                                                    }
                                                    fullWidth
                                                    disabled={!!saving}
                                                    multiline
                                                    onChange={(e) => {
                                                        setGeneralInfo({
                                                            ...generalInfo,
                                                            description:
                                                                e.target.value,
                                                        })
                                                    }}
                                                    label="Description"
                                                />
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box p={1}>
                                                <Box className={classes.label}>
                                                    Name
                                                </Box>
                                                <Typography variant="body1">
                                                    {props.category.name}
                                                </Typography>
                                            </Box>
                                            <Box p={1}>
                                                <Box className={classes.label}>
                                                    Description
                                                </Box>
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        minHeight: theme.spacing(
                                                            3
                                                        ),
                                                    }}
                                                >
                                                    {props.category.description}
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        )}
                        {tab === 1 && (
                            <CategoryCriteriaList category={props.category} />
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default CategoryManagerModal
