import React from "react"
import { makeStyles, Link, Chip } from "@material-ui/core"
import { useReactiveVar } from "@apollo/client"
import { globalFiltersVar } from "../../providers/GlobalState"
import { useHistory } from "react-router-dom"
import { Concept } from "../../__generated__/types"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
    },
    tag: {
        cursor: "pointer",
        fontWeight: theme.typography.fontWeightMedium,
        marginRight: theme.spacing(1),
        "&::before": {
            content: '"#"',
        },
    },
    chip: {
        backgroundColor: theme.palette.primary.main,
    },
}))

interface Props {
    concept?: Concept
    chip?: boolean
}

const TagList = (props: Props) => {
    const classes = useStyles()
    const history = useHistory()
    const [tags, setTags] = React.useState([])
    const globalFilters = useReactiveVar(globalFiltersVar)
    React.useEffect(() => {
        if (props.concept) {
            setTags(props.concept?.tags?.map((t) => t.name))
        }
    }, [setTags, props.concept])
    const handleClick = (tag) => {
        let localFilters = [...globalFilters]
        let current = false
        localFilters.forEach((item) => {
            if (
                item.field === "Tags" &&
                item.secondLevelFilter === "name_in" &&
                item.firstLevelFilter === "tags_some"
            ) {
                current = true
                if (item.value.indexOf(tag) === -1) {
                    item.value.push(tag)
                }
            }
        })
        if (!current) {
            let object = {
                firstLevelFilter: "tags_some",
                secondLevelFilter: "name_in",
                field: "Tags",
                value: [tag],
            }
            localFilters.push(object)
        }
        globalFiltersVar(localFilters)
        history.push(`/feed`)
    }
    return (
        <div className={classes.root}>
            {tags.map((tag, idx) => {
                if (!!props.chip) {
                    return (
                        <Chip
                            key={`${tag}-${props.concept?.id}`}
                            onClick={(event) => {
                                event.stopPropagation()
                                handleClick(tag)
                            }}
                            color="primary"
                            label={
                                <span
                                    style={{ whiteSpace: "normal" }}
                                    className={classes.tag}
                                >
                                    {tag}
                                </span>
                            }
                            style={{
                                margin: "2px",
                            }}
                            size="small"
                        />
                    )
                } else {
                    return (
                        <Link
                            key={`${tag}-${props.concept?.id}`}
                            onClick={(event) => {
                                event.stopPropagation()
                                handleClick(tag)
                            }}
                            className={classes.tag}
                        >
                            {tag}
                        </Link>
                    )
                }
            })}
        </div>
    )
}

export default TagList
