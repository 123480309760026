import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { createUploadLink } from "apollo-upload-client"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./i18n/config"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import APOLLO_CACHE_CONFIG from "./util/ApolloCacheConfig"
import { auth } from "./util/firebase"

const httpLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
})
const authLink = setContext(async (_, { headers }) => {
    let token = ""
    if (auth.currentUser) {
        try {
            token = await auth.currentUser.getIdToken()
        } catch (err) {
            console.log(err)
        }
    }
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            uid: auth.currentUser?.uid,
        },
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(APOLLO_CACHE_CONFIG),
})
//https://www.apollographql.com/docs/react/caching/cache-configuration/

// Although using StrictMode is beneficial to us,
// there is a bug on Apollo that only occur during development
// which slows down the entire application. Once that it is fixed,
// we can re-enable StrictMode to ensure our application is ready for Async React
// @see https://github.com/apollographql/apollo-client/issues/8011
// ReactDOM.render(
//     <React.StrictMode>
//         <ApolloProvider client={client}>
//             <App />
//         </ApolloProvider>
//     </React.StrictMode>,
//     document.getElementById("root")
// )

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register()
