import React, { useEffect, useState } from "react"
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive"
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff"
import IconButton from "@material-ui/core/IconButton"
import { useMutation, useQuery } from "@apollo/client"
import { useTheme } from "@material-ui/core/styles"
import {
    ADD_USER_WATCHED_CONCEPT,
    REMOVE_USER_WATCHED_CONCEPT,
    USER_WATCHED_CONCEPT_CHECK,
} from "./graphql"
import {
    AddUserWatchedConceptsMutation,
    AddUserWatchedConceptsMutationVariables,
    RemoveUserWatchedConceptsMutation,
    RemoveUserWatchedConceptsMutationVariables,
    UserWatchConceptCheckQuery,
    UserWatchConceptCheckQueryVariables,
} from "./__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import Tooltip from "@material-ui/core/Tooltip"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"

interface AlertSubscriptionToggleProps {
    item: any
    size?: "small" | "medium"
}
export default function AlertSubscriptionToggle(
    props: AlertSubscriptionToggleProps
) {
    const theme = useTheme()
    const { currentUser } = useAuth()
    const [watched, setWatched] = useState(false)
    const [addSubscription] = useMutation<
        AddUserWatchedConceptsMutation,
        AddUserWatchedConceptsMutationVariables
    >(ADD_USER_WATCHED_CONCEPT)
    const [removeSubscription] = useMutation<
        RemoveUserWatchedConceptsMutation,
        RemoveUserWatchedConceptsMutationVariables
    >(REMOVE_USER_WATCHED_CONCEPT)
    const { data, refetch } = useQuery<
        UserWatchConceptCheckQuery,
        UserWatchConceptCheckQueryVariables
    >(USER_WATCHED_CONCEPT_CHECK, {
        variables: {
            id: props.item.id,
            userId: currentUser.userId,
        },
    })
    // for some reason, watchedConcepts is not being cached by the user entity
    const watchedCheck =
        data?.User?.[0]?.watchedConcepts?.filter((x) => x.id === props.item.id)
            ?.length > 0
    useEffect(() => {
        if (data && watchedCheck) {
            setWatched(watchedCheck)
        }
    }, [data, watchedCheck])

    const handleSubscriptionAdd = async (ev) => {
        if (watched) {
            setWatched(false)
            await removeSubscription({
                variables: {
                    fromId: currentUser.userId,
                    toId: props.item.id,
                },
            })
        } else {
            setWatched(true)
            await addSubscription({
                variables: {
                    fromId: currentUser.userId,
                    toId: props.item.id,
                },
            })
        }
        refetch()
    }
    const { t } = useAwaitTranslation("subscriptions")

    return (
        <Tooltip
            key={props.item.id}
            title={
                watched
                    ? t(
                          "unsubscribeMessage",
                          "Unsubscribe (stop receiving update notifications)"
                      )
                    : t(
                          "subscribeMessage",
                          "Subscribe (receive update notifications)"
                      )
            }
        >
            <IconButton
                onClick={handleSubscriptionAdd}
                size={props.size ?? "medium"}
            >
                {watched ? (
                    <NotificationsActiveIcon
                        style={{ color: theme.palette.primary.main }}
                    />
                ) : (
                    <NotificationsOffIcon />
                )}
            </IconButton>
        </Tooltip>
    )
}
