import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { useAuth } from "../../providers/AuthProvider"
import Box from "@material-ui/core/Box"
import { Divider } from "@material-ui/core"
import { Paper, Toolbar, Hidden, IconButton } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import AddIcon from "@material-ui/icons/Add"
import { Team } from "../../__generated__/types"
import TeamMemberLineItem from "./TeamMemberLineItem"
import TeamMemberForm from "./TeamMemberForm"
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            padding: 0,
        },
        height: "100%",
    },
    teamList: {
        flexGrow: 1,
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
    },
    flexCell: {
        display: "flex",
        alignItems: "center",
        height: "100%",
    },
    tableContainer: {
        flexGrow: 1,
    },
    tableHeader: {
        //@ts-ignore
        backgroundColor: `${theme.palette.background.level2} !important`,
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
}))

type Order = "asc" | "desc"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

interface TeamMemberTableProps {
    team: Team
}

export default function TeamMemberTable(props: TeamMemberTableProps) {
    const classes = useStyles()
    const [memberFormOpen, setMemberFormOpen] = useState(false)
    const { currentUser } = useAuth()
    const editable =
        props.team.admin.filter((x) => {
            return x.userId === currentUser?.userId
        }).length > 0 || props.team.leader?.userId === currentUser?.userId
    const [order, setOrder] = useState<Order>("asc")
    const [orderBy, setOrderBy] = useState("lastName")

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const sortableMembers = props.team?.members
        ? [...props.team.members]?.map((item) => {
              let object = {}
              Object.keys(item).filter((field) => {
                  if (
                      (typeof field === "string" ||
                          typeof field === "number") &&
                      field !== "__typename"
                  ) {
                      object[field] = item[field]
                  }
                  return object
              })
              return {
                  ...object,

                  role:
                      props.team.admin.filter((x) => {
                          return x.userId === item.userId
                      }).length > 0
                          ? "Admin"
                          : "Editor",
              }
          })
        : []
    return (
        <Box className={classes.root}>
            <Paper className={classes.paper} variant="outlined">
                {!!editable && (
                    <>
                        <Toolbar>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                width="100%"
                            >
                                <Hidden xsDown>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setMemberFormOpen(true)}
                                    >
                                        Add Member
                                    </Button>
                                </Hidden>
                                <Hidden smUp>
                                    <IconButton
                                        onClick={() => setMemberFormOpen(true)}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Hidden>
                            </Box>
                        </Toolbar>
                        <Divider />
                    </>
                )}

                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    id="avatar"
                                    size="small"
                                    className={classes.tableHeader}
                                    style={{ width: "50px" }}
                                />

                                <TableCell
                                    className={classes.tableHeader}
                                    sortDirection={
                                        orderBy === "lastName" ? order : false
                                    }
                                    size="small"
                                >
                                    <TableSortLabel
                                        active={orderBy === "lastName"}
                                        direction={
                                            orderBy === "lastName"
                                                ? order
                                                : "asc"
                                        }
                                        onClick={() =>
                                            handleRequestSort("lastName")
                                        }
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell
                                    className={classes.tableHeader}
                                    sortDirection={
                                        orderBy === "email" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy === "email"}
                                        direction={
                                            orderBy === "email" ? order : "asc"
                                        }
                                        onClick={() =>
                                            handleRequestSort("email")
                                        }
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    sortDirection={
                                        orderBy === "role" ? order : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy === "role"}
                                        direction={
                                            orderBy === "role" ? order : "asc"
                                        }
                                        onClick={() =>
                                            handleRequestSort("role")
                                        }
                                    >
                                        Role
                                    </TableSortLabel>
                                </TableCell>
                                {!!editable && (
                                    <TableCell
                                        className={classes.tableHeader}
                                        size="small"
                                    />
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!sortableMembers &&
                                stableSort(
                                    sortableMembers,
                                    getComparator(order, orderBy)
                                )?.map((member, idx) => {
                                    const user = props.team.members.filter(
                                        (x) => x.userId === member.userId
                                    )?.[0]
                                    if (!!user) {
                                        return (
                                            <TeamMemberLineItem
                                                key={member.userId}
                                                user={user}
                                                team={props.team}
                                            />
                                        )
                                    } else return null
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {memberFormOpen && (
                    <TeamMemberForm
                        onClose={() => {
                            setMemberFormOpen(false)
                        }}
                        team={props.team}
                    />
                )}
            </Paper>
        </Box>
    )
}
