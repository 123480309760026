import React, { useRef } from "react"
import TextField from "@material-ui/core/TextField"
import { useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useLazyQuery } from "@apollo/client"
import {
    List,
    ListItemIcon,
    ListItemText,
    ListItem,
    IconButton,
    Box,
} from "@material-ui/core"
import { InputAdornment } from "@material-ui/core"
import LinkIcon from "@material-ui/icons/Link"
import { SEARCH_CONCEPTS } from "../graphql/conceptListQueries"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import { Concept } from "../__generated__/types"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import { getConceptTypeIcon } from "../util/ConceptTypeStyles"
import { useGlobalStyles } from "../styles/globalStyles"
import ClearIcon from "@material-ui/icons/Clear"
const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    searchBar: {
        width: "250px !important",
    },
    inputBox: {
        boxSizing: "border-box",
    },
    listbox: {
        overflow: "auto",
    },
    list: {
        overflow: "auto",
        border: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(1),
        height: "100%",
        borderRadius: theme.shape.borderRadius,

        backgroundColor: theme.palette.background.paper,
    },
    rootInput: {
        backgroundColor: theme.palette.background.paper,
    },
}))

interface ConceptPickerProps {
    linkFunction: (item: Concept) => void
    concepts?: Concept[]
    type?: string
    item?: Concept
    filterOut?: Concept[]
    autoFocus?: boolean
    inputAdornment?: JSX.Element
    placeholder?: string
    variant?: "filled" | "standard" | "outlined"
}

export default function ConceptPicker(props: ConceptPickerProps) {
    const globalClasses = useGlobalStyles()
    const classes = useStyles()
    const [value, setValue] = React.useState<Concept | null>(null)
    const [inputValue, setInputValue] = React.useState(" ")
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const [options, setOptions] = React.useState<Concept[]>([])
    const [loadResults, { loading, error, data }] = useLazyQuery(
        SEARCH_CONCEPTS
    )
    const ref: any = useRef()
    const { t } = useAwaitTranslation("concepts")
    let idChecks = []
    props.concepts?.forEach((item) => {
        idChecks.push(item.id)
    })
    React.useEffect(() => {
        let active = true
        if (active) {
            if (!inputValue || inputValue === "") {
                setOptions(value ? [value] : [])
                return undefined
            }
            loadResults({ variables: { searchString: inputValue } })
            if (data) {
                const filteredData = props.type
                    ? data.fuzzyConceptSearch.filter((x) => {
                          return x.type === props.type
                      })
                    : props.filterOut
                    ? data.fuzzyConceptSearch.filter((x) => {
                          return props.filterOut.indexOf(x.type) === -1
                      })
                    : data.fuzzyConceptSearch
                let newOptions: Concept[] = []
                if (value) {
                    newOptions = [value]
                }

                if (data) {
                    newOptions = [...newOptions, ...filteredData]
                }
                setOptions(newOptions)
            }
        }
        return () => {
            active = false
        }
    }, [value, inputValue, loadResults, data, props.type, props.filterOut])

    const AdornmentIcon = props.inputAdornment ?? <LinkIcon />

    return (
        <Box
            display="flex"
            flexDirection="column"
            overflow="hidden"
            width="100%"
        >
            <TextField
                autoFocus
                inputRef={ref}
                value={inputValue}
                size="small"
                className={classes.rootInput}
                onChange={(e) => {
                    setInputValue(e.target.value)
                }}
                placeholder="Type to search concepts..."
                InputProps={{
                    startAdornment: (
                        <>
                            <InputAdornment position="start">
                                {AdornmentIcon}
                            </InputAdornment>
                        </>
                    ),
                    endAdornment: inputValue?.length > 0 && (
                        <InputAdornment position="end">
                            <IconButton
                                size="small"
                                onClick={() => setInputValue("")}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {},
                }}
                variant={props.variant ?? "standard"}
                fullWidth
            />
            <List className={classes.list}>
                {options?.map((option, index) => {
                    const matches = match(option.title, inputValue)
                    const parts = parse(option.title, matches)
                    const TypeIcon = getConceptTypeIcon(
                        option.rootCategory?.name
                    )
                    return (
                        <ListItem
                            button
                            divider
                            key={option.id}
                            onClick={() => props.linkFunction(option)}
                        >
                            <ListItemIcon
                                className={globalClasses.smallMenuIcon}
                            >
                                {TypeIcon && (
                                    <TypeIcon
                                        className={classes.icon}
                                        fontSize="small"
                                    />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    className:
                                        globalClasses.smallMenuListItemText,
                                }}
                                primary={parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight
                                                ? 800
                                                : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}
