import React from "react"
import { Helmet } from "react-helmet-async"
import { useAuth } from "../providers/AuthProvider"

export default function Usersnap() {
    const { currentUser } = useAuth()

    if (
        typeof window !== "undefined" &&
        window.document &&
        currentUser &&
        currentUser.email
    ) {
        return (
            <Helmet>
                {currentUser && (
                    <script type="text/javascript">
                        {`
                            window.onUsersnapCXLoad = function(api) {
                                api.init({
                                        button: {
                                            position: "rightBottom",
                                            isHidden: true
                                        }
                                    });

                                api.on('open', function(event) {
                                    event.api.setValue('visitor','${currentUser.email}')
                                });
                                window.Usersnap = api;
                            }
                            var script = document.createElement('script');
                            script.async = 1;
                            script.src = 'https://widget.usersnap.com/load/87d7d864-26f2-4653-8d13-13e44c5f0f90?onload=onUsersnapCXLoad';
                            document.getElementsByTagName('head')[0].appendChild(script);
                        `}
                    </script>
                )}
            </Helmet>
        )
    } else {
        return null
    }
}
