import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import IndustryManager from "./industries/IndustryManager"
import CategoryManager from "./categories/CategoryManager"
import ThemeManager from "./themes/ThemeManager"
import ExerciseManager from "./exercises/ExerciseManager"
import useAwaitTranslation from "../i18n/useAwaitTranslation"
import UserManager from "./users/UserManager"
import { useAuth } from "../providers/AuthProvider"
import { SystemRole } from "../__generated__/types"
import { useHistory } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
    },
    level2: {
        //@ts-ignore
        backgroundColor: theme.palette.background.level2,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}))
export default function GlobalSettings(props) {
    const classes = useStyles()
    const [tab, setTab] = useState(0)
    const { currentUser } = useAuth()
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue)
    }
    const history = useHistory()
    React.useEffect(() => {
        if (
            !!currentUser &&
            !!history &&
            currentUser?.role !== SystemRole.ADMIN
        ) {
            history.push("/")
        }
    }, [currentUser, history])
    const { t } = useAwaitTranslation("global")
    if (currentUser?.role === SystemRole.ADMIN) {
        return (
            <Box className={classes.root}>
                <Box className={classes.level2}>
                    <Tabs
                        value={tab}
                        onChange={handleChange}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label={"Users"} />
                        <Tab label={t("categories", "Categories")} />
                        <Tab label={t("industries", "Industries")} />

                        <Tab label={t("themes", "Themes")} />
                        <Tab label={t("exerciseTypes", "Exercise Types")} />
                    </Tabs>
                </Box>
                {tab === 0 && <UserManager />}
                {tab === 1 && <CategoryManager />}
                {tab === 2 && <IndustryManager />}
                {tab === 3 && <ThemeManager />}
                {tab === 4 && <ExerciseManager />}
            </Box>
        )
    } else return null
}
