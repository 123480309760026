import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Box,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation, useQuery } from "@apollo/client"
import {
    CHECKLIST_FILTERED,
    CREATE_CONCEPT_CHECKLIST,
} from "../../graphql/Checklist"
import {
    CreateConceptChecklistMutation,
    CreateConceptChecklistMutationVariables,
    ChecklistFilteredQuery,
    ChecklistFilteredQueryVariables,
} from "../../graphql/__generated__/Checklist"
import { Loading } from "../Loading"
import { WidgetType, ChecklistInput } from "../../__generated__/types"
import { useParams } from "react-router-dom"
import { useAuth } from "../../providers/AuthProvider"
const useStyles = makeStyles((theme) => ({
    listContainer: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid " + theme.palette.divider,
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    createdListContainer: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid " + theme.palette.divider,
        margin: theme.spacing(1),
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))
interface ConceptListWidgetOptionsProps {
    onAddNewWidget: (conceptListId: string) => void
    selectedType: WidgetType
}
const ConceptListWidgetOptions = (props: ConceptListWidgetOptionsProps) => {
    const { conceptId } = useParams()
    const { currentUser } = useAuth()
    const classes = useStyles()
    const { data, loading, refetch: refetchLists } = useQuery<
        ChecklistFilteredQuery,
        ChecklistFilteredQueryVariables
    >(CHECKLIST_FILTERED, {
        variables: {
            filter: {
                concept: {
                    id: conceptId,
                },
                conceptBased: true,
            },
            conceptId: conceptId,
        },
        fetchPolicy: "cache-first",
    })
    const [createList] = useMutation<
        CreateConceptChecklistMutation,
        CreateConceptChecklistMutationVariables
    >(CREATE_CONCEPT_CHECKLIST)
    const selectedTypeConfig = JSON.parse(
        props.selectedType?.defaultConfig ?? "{}"
    )
    const handleNewList = async () => {
        const {
            data: { CreateChecklist: NewChecklist },
        } = await createList({
            variables: {
                conceptId: conceptId,
                userId: currentUser.userId,
                checklist: {
                    title: selectedTypeConfig.label ?? "New Checklist",
                    description: "",
                    isOrdered: true,
                    conceptBased: true,
                } as ChecklistInput,
            },
        })
        props.onAddNewWidget(NewChecklist.id)
        refetchLists()
    }
    const currentLists = data?.Checklist ?? []
    if (loading) {
        return <Loading size={75} hideQuote={true} />
    }
    return (
        <>
            <List disablePadding className={classes.listContainer}>
                <ListItem button onClick={handleNewList}>
                    <ListItemText
                        primary={
                            <Typography variant="body2">
                                Create New List
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
            <Box ml={1.5} mt={1} mb={-0.5}>
                <Typography variant="body2" color="textSecondary">
                    Created Lists
                </Typography>
            </Box>
            <List disablePadding className={classes.createdListContainer}>
                {currentLists?.length > 0 ? (
                    currentLists.map((list, index) => {
                        return (
                            <ListItem
                                key={index}
                                button
                                onClick={() => {
                                    props.onAddNewWidget(list.id)
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="body2">
                                            {list.title}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        )
                    })
                ) : (
                    <Box p={1} textAlign="center">
                        <Typography variant="body2" color="textSecondary">
                            No lists created yet..
                        </Typography>
                    </Box>
                )}
            </List>
        </>
    )
}

export default ConceptListWidgetOptions
