import { gql } from "@apollo/client"

export const ADD_USER_WATCHED_CONCEPT = gql`
    mutation AddUserWatchedConcepts($fromId: ID!, $toId: ID!) {
        AddUserWatchedConcepts(from: { userId: $fromId }, to: { id: $toId }) {
            from {
                userId
                watchedConcepts {
                    id
                }
            }
            to {
                id
                watchedBy {
                    userId
                }
            }
        }
    }
`
export const REMOVE_USER_WATCHED_CONCEPT = gql`
    mutation RemoveUserWatchedConcepts($fromId: ID!, $toId: ID!) {
        RemoveUserWatchedConcepts(
            from: { userId: $fromId }
            to: { id: $toId }
        ) {
            from {
                userId
                watchedConcepts {
                    id
                }
            }
            to {
                id
                watchedBy {
                    userId
                }
            }
        }
    }
`

export const USER_WATCHED_CONCEPT_CHECK = gql`
    query UserWatchConceptCheck($id: ID!, $userId: ID!) {
        User(userId: $userId) {
            userId
            watchedConcepts(filter: { id: $id }) {
                id
            }
        }
    }
`
