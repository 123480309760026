import React, { useState } from "react"
import { Link as LinkRouter } from "react-router-dom"
import { Link, Popper, Box, Avatar, Paper } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) => ({
    popper: {
        zIndex: theme.zIndex.tooltip,
        maxWidth: "30em",
    },
    secondary: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body2.fontSize,
    },
    title: {
        fontSize: theme.typography.body1.fontSize,
    },
    link: {
        fontSize: theme.typography.body2.fontSize,
    },
}))
interface ConceptMentionProps {
    concept: any
}
export default function ConceptMention(props: ConceptMentionProps) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }
    const handleMouseExit = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null)
    }
    return (
        <>
            <Link
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseExit}
                color="primary"
                component={LinkRouter}
                to={`/concept/${props.concept.abstractedId}/home`}
                className={classes.link}
            >
                {props.concept.title}
            </Link>
            <Popper
                anchorEl={anchorEl}
                open={open}
                placement="bottom"
                transition={true}
                className={classes.popper}
            >
                <Paper elevation={6}>
                    <Box p={1} display="flex" alignItems="center">
                        {props.concept.imageUrl && (
                            <Avatar
                                src={props.concept.imageUrl}
                                alt={props.concept.title}
                                variant="rounded"
                            />
                        )}
                        <Box ml={1}>
                            {!!props.concept.rootCategory && (
                                <Box className={classes.secondary}>
                                    {props.concept.rootCategory.name}{" "}
                                    {!props.concept.category?.isRoot &&
                                        " / " + props.concept.category.name}
                                </Box>
                            )}
                            <Box className={classes.title} mt={0.25} mb={0.25}>
                                {props.concept.title}
                            </Box>
                            <Box className={classes.secondary}>
                                {props.concept.summary}
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Popper>
        </>
    )
}
