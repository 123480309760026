import React from "react"
import {
    ListItem,
    ListItemText,
    Typography,
    List,
    Box,
} from "@material-ui/core"
import {
    GET_EXERCISE_BY_PARENT_CONCEPT,
    CREATE_EXERCISE,
    ADD_EXERCISE_TYPE,
    ADD_EXERCISE_PARENT_CONCEPT,
    GET_EXERCISE_TYPES,
} from "../exercises/graphql"
import {
    GetExerciseTypesQuery,
    GetExerciseTypesQueryVariables,
    CreateExerciseMutation,
    CreateExerciseMutationVariables,
    AddExerciseTypeMutation,
    AddExerciseTypeMutationVariables,
    ExerciseByParentConceptQuery,
    ExerciseByParentConceptQueryVariables,
    AddExerciseParentConceptMutation,
    AddExerciseParentConceptMutationVariables,
} from "../exercises/__generated__/graphql"
import { useMutation, useQuery } from "@apollo/client"
import { makeStyles } from "@material-ui/core/styles"
import { WidgetType, WidgetTypeName } from "./useWidgetTypes"
import { useParams } from "react-router-dom"
import { ExerciseStatus, ExerciseType } from "../../__generated__/types"
import { Loading } from "../Loading"
import { EXERCISE_CATEGORIES } from "./useWidgetTypes"

const useStyles = makeStyles((theme) => ({
    listContainer: {
        borderRadius: theme.shape.borderRadius,
        border: "1px solid " + theme.palette.divider,
        margin: theme.spacing(1),

        backgroundColor: theme.palette.background.paper,
    },
}))

interface ExerciseWidgetOptionsProps {
    onAddNewWidget: (exerciseId: string) => void
    selectedType: WidgetType
}
const ExerciseWidgetOptions = (props: ExerciseWidgetOptionsProps) => {
    const classes = useStyles()
    const { conceptId } = useParams()
    const { data: typesData, loading: loadingTypes } = useQuery<
        GetExerciseTypesQuery,
        GetExerciseTypesQueryVariables
    >(GET_EXERCISE_TYPES)
    const { data: exerciseData, loading: loadingExercises } = useQuery<
        ExerciseByParentConceptQuery,
        ExerciseByParentConceptQueryVariables
    >(GET_EXERCISE_BY_PARENT_CONCEPT, {
        variables: {
            parentId: conceptId,
        },
        fetchPolicy: "cache-and-network",
    })
    const [createExercise] = useMutation<
        CreateExerciseMutation,
        CreateExerciseMutationVariables
    >(CREATE_EXERCISE)
    const [addExerciseType] = useMutation<
        AddExerciseTypeMutation,
        AddExerciseTypeMutationVariables
    >(ADD_EXERCISE_TYPE)
    const [addExerciseParent] = useMutation<
        AddExerciseParentConceptMutation,
        AddExerciseParentConceptMutationVariables
    >(ADD_EXERCISE_PARENT_CONCEPT)
    const exerciseTypes = typesData?.ExerciseType
    const config = JSON.parse(props.selectedType?.defaultConfig ?? "{}")
    const handleNewExercise = async () => {
        const {
            data: { CreateExercise: NewExercise },
        } = await createExercise({
            variables: {
                title: config?.label,
                description: "",
                duration: 10,
                status: ExerciseStatus.DRAFT,
            },
        })

        await addExerciseParent({
            variables: {
                conceptId: conceptId,
                exerciseId: NewExercise.id,
            },
        })
        const type: ExerciseType = exerciseTypes.filter(
            (item) =>
                item.category ===
                EXERCISE_CATEGORIES[WidgetTypeName[props.selectedType.name]]
        )?.[0]
        if (type) {
            await addExerciseType({
                variables: {
                    fromId: NewExercise.id,
                    toId: type.id,
                },
            })
        }
        props.onAddNewWidget(NewExercise.id)
    }
    if (loadingExercises || loadingTypes) {
        return <Loading size={75} hideQuote={true} />
    }
    const exercises =
        exerciseData?.Exercise.filter(
            (item) =>
                item.type?.category ===
                EXERCISE_CATEGORIES[WidgetTypeName[props.selectedType.name]]
        ) ?? []
    return (
        <>
            <List disablePadding className={classes.listContainer}>
                <ListItem button onClick={handleNewExercise}>
                    <ListItemText
                        primary={
                            <Typography variant="body2">
                                Create New Exercise
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
            <Box ml={1.5} mt={1} mb={-0.5}>
                <Typography variant="body2" color="textSecondary">
                    Created Exercises
                </Typography>
            </Box>
            <List disablePadding className={classes.listContainer}>
                {exercises?.length > 0 ? (
                    exercises.map((exercise, index) => {
                        return (
                            <ListItem
                                button
                                onClick={() => {
                                    props.onAddNewWidget(exercise.id)
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="body2">
                                            {exercise.title}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        )
                    })
                ) : (
                    <Box p={1} textAlign="center">
                        <Typography variant="body2" color="textSecondary">
                            No exercises created yet..
                        </Typography>
                    </Box>
                )}
            </List>
        </>
    )
}

export default ExerciseWidgetOptions
