import { Box, Paper, IconButton } from "@material-ui/core"
import { _ConceptFilter, _ConceptOrdering } from "../../../__generated__/types"
import WidgetHeader from "./common/WidgetHeader"
import useWidget from "../useWidget"
import { WidgetTypeComponent } from "../useWidgetTypes"
import WidgetFilterToggle from "./common/WidgetFilterToggle"
import { useParams } from "react-router-dom"
import { FilterItem } from "../../filters/util/types"
import { getFilterObject } from "../../filters/util/getFilterObject"
import { useEffect, useState } from "react"
import ProductChart from "../../charts/ProductChart"
import useFilters from "../../filters/util/useFilters"
import { useApolloClient } from "@apollo/client"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import { CONCEPT_BY_ID } from "../../../graphql/queries"
type WidgetConfig = {
    conceptId: string
    label: string
    orderBy: _ConceptOrdering
    filters?: FilterItem[]
    source: string
}

const ProductChartWidget: WidgetTypeComponent = (props) => {
    const [locked, setLocked] = useState(true)
    const [filters, setFilters] = useState<FilterItem[]>([])
    const { conceptId } = useParams()
    const client = useApolloClient()
    const cachedConceptData = client.readQuery({
        query: CONCEPT_BY_ID,
        variables: {
            id: conceptId,
        },
    })
    const concept = cachedConceptData?.Concept?.[0] ?? null
    const { config, isInitialized } = useWidget<WidgetConfig>(props.widget.id)
    const {
        defaultValue,
        defaultOptions,
        isInitialized: filtersInitialized,
    } = useFilters()
    useEffect(() => {
        if (!!isInitialized) {
            setFilters(defaultValue)
        }
    }, [isInitialized, defaultValue])
    const configuredFilters =
        config?.filters?.map((filter) => {
            return {
                ...filter,
            }
        }) ?? []
    let filterObject: _ConceptFilter = {
        ...getFilterObject(configuredFilters),
        ...getFilterObject(filters),
    }
    if (config?.source === "connections") {
        filterObject = {
            ...filterObject,
            connections_some: {
                id: conceptId,
            },
        }
    }

    const configuredFields =
        config?.filters?.map((filter) => filter.field) ?? []
    const filterOptions = defaultOptions?.filter(
        (filter) => !configuredFields.includes(filter.field)
    )
    let actions = [
        <WidgetFilterToggle
            size="medium"
            key={`${conceptId}_filter`}
            isDialog={props.isDialog}
            filters={filters.filter(
                (filter) => !configuredFields.includes(filter.field)
            )}
            setFilters={(filters: FilterItem[]) => setFilters(filters)}
            filterOptions={filterOptions}
        />,
    ]
    if (concept?.rootCategory?.name === "Solution") {
        actions.unshift(
            <IconButton
                onClick={() => setLocked(!locked)}
                style={{ padding: "8px" }}
                key={`${conceptId}_unlock`}
            >
                {locked ? (
                    <LockIcon fontSize="small" />
                ) : (
                    <LockOpenIcon fontSize="small" />
                )}
            </IconButton>
        )
    }

    return (
        <Box
            component={Paper}
            display="flex"
            height="100%"
            width="100%"
            flexDirection="column"
            pb={2}
            key={props.widget.id}
        >
            <WidgetHeader
                label={config?.label}
                actions={actions}
                widget={props.widget}
                editing={props.editing}
            />
            {isInitialized && filterObject && (
                <Box key={props.widget.id} overflow="hidden" flexGrow={1}>
                    <ProductChart filter={filterObject} locked={locked} />
                </Box>
            )}
        </Box>
    )
}

export default ProductChartWidget
