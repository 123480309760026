import { gql } from "@apollo/client"
export const CRITERIA_FRAGMENT = gql`
    fragment Criteria on Criteria {
        id
        name
        isDefault
        criteriaType
        category {
            id
            name
        }
        concept {
            id
        }
        scores {
            id
            value
            notes
            user {
                userId
            }
            criteria {
                id
                name
            }
        }
    }
`
export const CRITERIA_SCORE_FRAGMENT = gql`
    fragment CriteriaScore on CriteriaScore {
        id
        value
        notes
        criteria {
            ...Criteria
        }
        user {
            userId
            firstName
            lastName
            username
        }
        scoredConcept {
            id
            title
        }
    }

    ${CRITERIA_FRAGMENT}
`
export const ALL_DEFAULT_CRITERIA_BY_CATEGORY = gql`
    query AllDefaultCriteriaByCategory {
        Category(isRoot: true) {
            id
            name
            defaultCriteria {
                ...Criteria
            }
        }
    }
    ${CRITERIA_FRAGMENT}
`
export const DEFAULT_CRITERIA = gql`
    query DefaultCriteria {
        Criteria(isDefault: true) {
            ...Criteria
        }
    }
    ${CRITERIA_FRAGMENT}
`

export const DEFAULT_CRITERIA_BY_CATEGORY = gql`
    query DefaultCriteriaByCategory($categoryId: ID!) {
        Criteria(isDefault: true, filter: { category: { id: $categoryId } }) {
            ...Criteria
        }
    }
    ${CRITERIA_FRAGMENT}
`

export const CRITERIA_BY_CONCEPT = gql`
    query CriteriaByConcept($conceptId: ID!) {
        Criteria(filter: { concept: { id: $conceptId } }) {
            ...Criteria
        }
    }
    ${CRITERIA_FRAGMENT}
`
export const CRITERIA_FILTERED = gql`
    query Criteria($filter: _CriteriaFilter) {
        Criteria(filter: $filter, orderBy: name_asc) {
            ...Criteria
        }
    }

    ${CRITERIA_FRAGMENT}
`

export const CONCEPT_WITH_CRITERIA = gql`
    query ConceptWithCriteria(
        $conceptFilter: _ConceptFilter
        $criteriaFilter: _CriteriaFilter
    ) {
        Concept(filter: $conceptFilter) {
            id
            title
            type
            imageUrl
            rootCategory {
                id
                name
            }
            category {
                id
                name
                isRoot
            }
            addedUsers {
                User {
                    userId
                }
                type
            }
            addedTeams {
                Team {
                    teamId
                }
                type
            }
            criteria(filter: $criteriaFilter) {
                ...Criteria
            }
        }
    }
    ${CRITERIA_FRAGMENT}
`
/// MUTATIONS
export const CREATE_CRITERIA = gql`
    mutation CreateCriteria(
        $name: String
        $isDefault: Boolean
        $criteriaType: String
    ) {
        CreateCriteria(
            name: $name
            isDefault: $isDefault
            criteriaType: $criteriaType
        ) {
            ...Criteria
        }
    }
    ${CRITERIA_FRAGMENT}
`
export const UPDATE_CRITERIA = gql`
    mutation UpdateCriteria(
        $id: ID!
        $name: String
        $isDefault: Boolean
        $criteriaType: String
    ) {
        UpdateCriteria(
            id: $id
            name: $name
            isDefault: $isDefault
            criteriaType: $criteriaType
        ) {
            ...Criteria
        }
    }
    ${CRITERIA_FRAGMENT}
`

export const ADD_CATEGORY_DEFAULT_CRITERIA = gql`
    mutation AddCategoryDefaultCriteria($categoryId: ID!, $criteriaId: ID!) {
        AddCategoryDefaultCriteria(
            from: { id: $categoryId }
            to: { id: $criteriaId }
        ) {
            from {
                id
                name
                defaultCriteria {
                    ...Criteria
                }
            }
            to {
                ...Criteria
            }
        }
    }
    ${CRITERIA_FRAGMENT}
`

export const ADD_CONCEPT_CRITERIA = gql`
    mutation AddCriteriaConcept($criteriaId: ID!, $conceptId: ID!) {
        AddCriteriaConcept(from: { id: $conceptId }, to: { id: $criteriaId }) {
            from {
                id
                criteria {
                    ...Criteria
                }
            }
            to {
                ...Criteria
            }
        }
    }
    ${CRITERIA_FRAGMENT}
`

export const DELETE_CRITERIA = gql`
    mutation DeleteCriteria($id: ID!) {
        DeleteCriteria(id: $id) {
            id
        }
    }
`

export const CREATE_CRITERIA_SCORE = gql`
    mutation CreateCriteriaScore($value: Float, $notes: String) {
        CreateCriteriaScore(value: $value, notes: $notes) {
            ...CriteriaScore
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
`

export const UPDATE_CRITERIA_SCORE = gql`
    mutation UpdateCriteriaScore($id: ID!, $value: Float, $notes: String) {
        UpdateCriteriaScore(id: $id, value: $value, notes: $notes) {
            ...CriteriaScore
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
`

export const DELETE_CRITERIA_SCORE = gql`
    mutation DeleteCriteriaScore($id: ID!) {
        DeleteCriteriaScore(id: $id) {
            id
        }
    }
`

export const ADD_CRITERIA_SCORE_USER = gql`
    mutation AddCriteriaScoreUser($criteriaScoreId: ID!, $userId: ID!) {
        AddCriteriaScoreUser(
            from: { id: $criteriaScoreId }
            to: { userId: $userId }
        ) {
            from {
                ...CriteriaScore
            }
            to {
                userId
                firstName
                lastName
                username
            }
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
`
export const ADD_CRITERIA_SCORE_CRITERIA = gql`
    mutation AddCriteriaScoreCriteria($criteriaScoreId: ID!, $criteriaId: ID!) {
        AddCriteriaScoreCriteria(
            from: { id: $criteriaScoreId }
            to: { id: $criteriaId }
        ) {
            from {
                ...CriteriaScore
            }
            to {
                ...Criteria
            }
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
    ${CRITERIA_FRAGMENT}
`
export const ADD_CRITERIA_SCORE_CONCEPT = gql`
    mutation AddCriteriaScoreConcept($conceptId: ID!, $criteriaScoreId: ID!) {
        AddCriteriaScoreScoredConcept(
            from: { id: $criteriaScoreId }
            to: { id: $conceptId }
        ) {
            from {
                ...CriteriaScore
            }
            to {
                id
            }
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
`

export const ADD_CRITERIA_SCORE_EXERCISE_SUBMISSION = gql`
    mutation AddCriteriaScoreExerciseSubmission(
        $submissionId: ID!
        $criteriaScoreId: ID!
    ) {
        AddCriteriaScoreExerciseSubmission(
            from: { id: $submissionId }
            to: { id: $criteriaScoreId }
        ) {
            from {
                id
                criteriaScores {
                    ...CriteriaScore
                }
            }
            to {
                ...CriteriaScore
            }
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
`

export const FOCUSED_EXERCISE_CRITERIA_SCORE = gql`
    query FocusedExerciseCriteriaScore(
        $submissionId: ID!
        $conceptId: ID!
        $criteriaId: ID!
    ) {
        CriteriaScore(
            filter: {
                AND: [
                    { exerciseSubmission: { id: $submissionId } }
                    { scoredConcept: { id: $conceptId } }
                    { criteria: { id: $criteriaId } }
                ]
            }
        ) {
            ...CriteriaScore
        }
    }
    ${CRITERIA_SCORE_FRAGMENT}
`
