import gql from "graphql-tag"

export const ADD_USER_LIKE = gql`
    mutation ADD_USER_LIKE($userId: ID!, $id: ID!) {
        AddUserLiked(userId: $userId, conceptId: $id) {
            userId
            notifications {
                id
            }
        }
    }
`
export const REMOVE_USER_LIKE = gql`
    mutation REMOVE_USER_LIKE($userId: ID!, $id: ID!) {
        RemoveUserLiked(from: { userId: $userId }, to: { id: $id }) {
            from {
                userId
                firstName
            }
            to {
                title
                id
                likedBy {
                    userId
                }
                likeCount
            }
        }
    }
`
