import { useMutation, useQuery } from "@apollo/client"
import {
    Badge,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    List,
    makeStyles,
    Paper,
    Popper,
    Box,
    Typography,
    Button,
} from "@material-ui/core"
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone"
import NotificationsIcon from "@material-ui/icons/Notifications"
import React, { useEffect, useRef } from "react"
import { useAuth } from "../../providers/AuthProvider"
import { Notification } from "../../__generated__/types"
import { NOTIFICATIONS_QUERY, UPDATE_NOTIFICATIONS_TIMESTAMP } from "./graphql"
import {
    UserNotificationsQuery,
    UserNotificationsQueryVariables,
    UserNotificationsUpdateMutation,
    UserNotificationsUpdateMutationVariables,
} from "./__generated__/graphql"
import NotificationsModal from "./NotificationsModal"
import NotificationGroupItem from "./NotificationGroupItem"

const useStyles = makeStyles((theme) => ({
    paper: {
        transformOrigin: "top right",
    },
    list: {
        width: theme.spacing(50),
        maxHeight: theme.spacing(60),
        overflow: "auto",
        padding: 0,
    },
    listItem: {
        display: "flex",
        flexDirection: "column",
    },
    loading: {
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(1, 0),
    },
    divider: {
        margin: 0,
    },
    count: {
        height: "1em",
        width: "1em",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
    },
}))

function groupNotifications(notifications: Array<Notification>) {
    let object = {}
    notifications.forEach((item: Notification) => {
        const uniqueString: string =
            item.actionBy?.username + item.type + item.concept?.id
        if (!object[uniqueString]) {
            object[uniqueString] = [item]
        } else {
            object[uniqueString].push(item)
        }
    })
    return object
}
function HeaderNotifications() {
    const classes = useStyles()
    const [fullOpen, setFullOpen] = React.useState(false)
    const { currentUser } = useAuth()
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const { data } = useQuery<
        UserNotificationsQuery,
        UserNotificationsQueryVariables
    >(NOTIFICATIONS_QUERY, {
        variables: {
            userId: currentUser?.uid,
        },
        pollInterval: 10000, // every 10 seconds
    })

    const [updateNotifications] = useMutation<
        UserNotificationsUpdateMutation,
        UserNotificationsUpdateMutationVariables
    >(UPDATE_NOTIFICATIONS_TIMESTAMP, {
        refetchQueries: ["UserNotifications"],
    })
    const lastSeenNotifications =
        data?.User?.[0]?.lastCheckedNotifications?.formatted ?? Date.now()
    const wasOpen = useRef(open)
    useEffect(() => {
        if (!open && wasOpen.current) {
            updateNotifications({
                variables: {
                    userId: currentUser.uid,
                    lastCheckedNotifications: {
                        year: new Date().getUTCFullYear(),
                        month: new Date().getUTCMonth() + 1,
                        day: new Date().getUTCDate(),
                        hour: new Date().getUTCHours(),
                        minute: new Date().getUTCMinutes(),
                        second: new Date().getUTCSeconds(),
                    },
                },
            })
        }

        wasOpen.current = open
    }, [currentUser?.uid, open, updateNotifications])
    let messageList = data?.User?.[0]?.notifications ?? []
    const groupedList = groupNotifications(messageList)
    const notificationsNumber = messageList.filter(
        (message) =>
            new Date(message.createdAt.formatted).getTime() >
            new Date(lastSeenNotifications).getTime()
    ).length

    return (
        <>
            <IconButton
                aria-label="notifications"
                onClick={handleToggle}
                ref={anchorRef}
            >
                <Badge
                    badgeContent={notificationsNumber}
                    overlap="circle"
                    color="primary"
                >
                    {notificationsNumber > 0 ? (
                        <NotificationsIcon />
                    ) : (
                        <NotificationsNoneIcon />
                    )}
                </Badge>
            </IconButton>
            <Popper
                id="notifications-popup"
                anchorEl={anchorRef.current}
                open={open}
                placement="bottom-end"
                transition
                disablePortal
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener
                        onClickAway={() => {
                            setOpen(false)
                        }}
                    >
                        <Grow in={open} {...TransitionProps}>
                            <Paper elevation={6} className={classes.paper}>
                                <Box p={1}>
                                    <Typography variant="h6">
                                        Latest Notifications
                                    </Typography>
                                </Box>
                                <Divider />
                                <List className={classes.list}>
                                    {messageList && groupedList ? (
                                        Object.keys(groupedList).map(
                                            (group, index) =>
                                                index < 10 ? (
                                                    <React.Fragment key={group}>
                                                        <NotificationGroupItem
                                                            notificationGroup={
                                                                groupedList[
                                                                    group
                                                                ]
                                                            }
                                                            viewed={
                                                                new Date(
                                                                    groupedList[
                                                                        group
                                                                    ][0].createdAt.formatted
                                                                ).getTime() >
                                                                new Date(
                                                                    lastSeenNotifications
                                                                ).getTime()
                                                            }
                                                            onClose={() =>
                                                                setOpen(false)
                                                            }
                                                        />
                                                        {index <
                                                            Object.keys(
                                                                groupedList
                                                            ).length -
                                                                1 &&
                                                            index !== 9 && (
                                                                <Divider
                                                                    className={
                                                                        classes.divider
                                                                    }
                                                                />
                                                            )}
                                                    </React.Fragment>
                                                ) : null
                                        )
                                    ) : (
                                        <div className={classes.loading}>
                                            <CircularProgress size={32} />
                                        </div>
                                    )}
                                </List>
                                <Divider className={classes.divider} />
                                <Box>
                                    <Button
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            setFullOpen(true)
                                            setOpen(false)
                                        }}
                                    >
                                        View All
                                    </Button>
                                </Box>
                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
            {fullOpen && (
                <NotificationsModal
                    onClose={() => setFullOpen(false)}
                    userId={currentUser.userId}
                />
            )}
        </>
    )
}

export default HeaderNotifications
