import { useMutation, useQuery } from "@apollo/client"
import { useCallback, useEffect, useState } from "react"
import {
    ASSIGN_DASHBOARD_WIDGET_TO_DASHBOARD,
    ASSIGN_DASHBOARD_WIDGET_TO_TYPE,
    CREATE_DASHBOARD_WIDGET,
    GET_DASHBOARD_WIDGETS_BY_ID,
} from "./graphql"
import {
    AddDashboardWidgetToTypeMutation,
    AddDashboardWidgetToTypeMutationVariables,
    AssignDashboardWidgetToDashboardMutation,
    AssignDashboardWidgetToDashboardMutationVariables,
    CreateDashboardWidgetMutation,
    CreateDashboardWidgetMutationVariables,
    GetDashboardWidgetsQuery,
    GetDashboardWidgetsQueryVariables,
} from "./__generated__/graphql"
import { useWidgetTypes, WidgetTypeName } from "./useWidgetTypes"

export type Widget = {
    id: string
    name: string
    type: WidgetTypeName
}

export function useWidgets(dashboardId: string) {
    // LOAD ALL WIDGET TYPES
    const {
        widgetTypes,
        isInitialized: isWidgetTypeInitialized,
    } = useWidgetTypes()

    // LOAD ALL WIDGETS FOR DASHBOARD
    const { data: widgetsData, refetch: refetchWidgets } = useQuery<
        GetDashboardWidgetsQuery,
        GetDashboardWidgetsQueryVariables
    >(GET_DASHBOARD_WIDGETS_BY_ID, {
        variables: {
            dashboardId,
        },
        skip: !dashboardId,
    })

    const [widgets, setWidgets] = useState<Array<Widget>>([])
    const [isWidgetsInitialized, setWidgetsInitialized] = useState(false)
    const widgetsList = widgetsData?.Widget
    useEffect(() => {
        if (widgetsList) {
            setWidgets(
                widgetsList.map((widget) => ({
                    id: widget.id,
                    name: widget.name,
                    type: (widget.type?.name ??
                        WidgetTypeName.Unknown) as WidgetTypeName,
                }))
            )

            setWidgetsInitialized(true)
        }
    }, [widgetsList])
    // WIDGET METHODS

    // API CALLS FOR WIDGETS
    const [addNewWidget] = useMutation<
        CreateDashboardWidgetMutation,
        CreateDashboardWidgetMutationVariables
    >(CREATE_DASHBOARD_WIDGET)
    const [assignWidgetToType] = useMutation<
        AddDashboardWidgetToTypeMutation,
        AddDashboardWidgetToTypeMutationVariables
    >(ASSIGN_DASHBOARD_WIDGET_TO_TYPE)
    const [assignWidgetToDashboard] = useMutation<
        AssignDashboardWidgetToDashboardMutation,
        AssignDashboardWidgetToDashboardMutationVariables
    >(ASSIGN_DASHBOARD_WIDGET_TO_DASHBOARD)
    //
    const onAddNewWidget = useCallback(
        async (
            widgetTypeName: WidgetTypeName,
            config: string,
            value: string
        ) => {
            const widgetType = widgetTypes.find(
                (w) => w.name === widgetTypeName
            )
            if (!widgetType)
                return Promise.reject(
                    `No widget type found for type ${widgetTypeName}`
                )
            const {
                data: { CreateWidget: newWidget },
            } = await addNewWidget({
                variables: {
                    config: config,
                    value: value,
                    isTemplate: false,
                },
            })
            await assignWidgetToType({
                variables: {
                    widgetId: newWidget.id,
                    widgetTypeId: widgetType.id,
                },
            })

            await assignWidgetToDashboard({
                variables: {
                    widgetId: newWidget.id,
                    dashboardId: dashboardId,
                },
            })

            await refetchWidgets()

            return newWidget
        },
        [
            addNewWidget,
            refetchWidgets,
            assignWidgetToDashboard,
            assignWidgetToType,
            dashboardId,
            widgetTypes,
        ]
    )

    return {
        isInitialized: isWidgetTypeInitialized && isWidgetsInitialized,
        widgets,
        widgetTypes,

        // Methods
        onAddNewWidget,
    }
}
