import { gql } from "@apollo/client"

const ALL_INDUSTRY_QUERY = gql`
    query Industry {
        Industry(orderBy: name_asc) {
            industryId
            name
            marketSize
        }
    }
`
const UPDATE_INDUSTRY = gql`
    mutation UpdateIndustry($industryId: ID!, $name: String) {
        UpdateIndustry(industryId: $industryId, name: $name) {
            industryId
            name
        }
    }
`
const DELETE_INDUSTRY = gql`
    mutation DeleteIndustry($industryId: ID!) {
        DeleteIndustry(industryId: $industryId) {
            industryId
        }
    }
`
const CREATE_INDUSTRY = gql`
    mutation CreateIndustry($name: String) {
        CreateIndustry(name: $name) {
            industryId
            name
        }
    }
`
const ADD_CONCEPT_INDUSTRY = gql`
    mutation AddConceptIndustries($id: ID!, $industryId: ID!) {
        AddConceptIndustries(
            from: { id: $id }
            to: { industryId: $industryId }
        ) {
            from {
                id
                title
                industries {
                    industryId
                    name
                }
            }
            to {
                industryId
                name
            }
        }
    }
`
const REMOVE_CONCEPT_INDUSTRY = gql`
    mutation RemoveConceptIndustries($id: ID!, $industryId: ID!) {
        RemoveConceptIndustries(
            from: { id: $id }
            to: { industryId: $industryId }
        ) {
            from {
                id
                title
                industries {
                    industryId
                    name
                }
            }
            to {
                industryId
                name
            }
        }
    }
`

export {
    ALL_INDUSTRY_QUERY,
    UPDATE_INDUSTRY,
    DELETE_INDUSTRY,
    CREATE_INDUSTRY,
    ADD_CONCEPT_INDUSTRY,
    REMOVE_CONCEPT_INDUSTRY,
}
