import { useEffect, useState, useCallback } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { Avatar, Theme } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import Divider from "@material-ui/core/Divider"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Palette } from "@material-ui/core/styles/createPalette"
import { Typography } from "@material-ui/core/styles/createTypography"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import StarIcon from "@material-ui/icons/Star"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import { useHistory } from "react-router-dom"
import { SizeMe } from "react-sizeme"
import {
    Area,
    AreaChart,
    Line,
    LineChart,
    ReferenceDot,
    ReferenceLine,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts"
import { CurveData, AdoptionData } from "./line-data/HypeChartData"
import { HYPE_CHART } from "./graphql"
import useAwaitTranslation from "../../i18n/useAwaitTranslation"
import { Concept, _ConceptFilter } from "../../__generated__/types"
import { Loading } from "../Loading"
import { useParams } from "react-router-dom"
import {
    CREATE_CRITERIA,
    CREATE_CRITERIA_SCORE,
    ADD_CRITERIA_SCORE_CRITERIA,
    UPDATE_CRITERIA_SCORE,
    ADD_CONCEPT_CRITERIA,
    ADD_CRITERIA_SCORE_USER,
} from "../criteria/graphql"
import {
    CreateCriteriaMutation,
    CreateCriteriaScoreMutation,
    CreateCriteriaMutationVariables,
    UpdateCriteriaScoreMutation,
    UpdateCriteriaScoreMutationVariables,
    CreateCriteriaScoreMutationVariables,
    AddCriteriaConceptMutation,
    AddCriteriaConceptMutationVariables,
    AddCriteriaScoreUserMutation,
    AddCriteriaScoreUserMutationVariables,
    AddCriteriaScoreCriteriaMutation,
    AddCriteriaScoreCriteriaMutationVariables,
} from "../criteria/__generated__/graphql"
import { useAuth } from "../../providers/AuthProvider"
import { CriteriaType } from "../criteria/types"
import { DotProps } from "recharts"
import {
    HypeChartQuery,
    HypeChartQueryVariables,
} from "./__generated__/graphql"
const useStyles = makeStyles((theme: Theme) => ({
    flex: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
        padding: theme.spacing(1),
    },
    chartContainer: {
        height: "40%",
    },
    yAxis: {
        writingMode: "vertical-lr",
        textOrientation: "sideways",
        transform: "rotate(180deg)",
        display: "flex",
        justifyContent: "center",
        width: "20px",
        fontSize: 12,
        color: theme.palette.text.hint,
    },
    timeContainer: {
        fontSize: 12,
        color: theme.palette.text.hint,
        textAlign: "center",
        marginBottom: "5px",
    },
    clickable: {
        cursor: "crosshair !important",
    },
}))

interface HypeChartProps {
    filter?: _ConceptFilter
    concepts?: Concept[]
    locked?: boolean
}
type PositionObject = {
    x: number
    y: number
    id: string
}
type TooltipObject = {
    title: string
    primary: boolean
    position: number
    imageUrl: string
}
export default function HypeChart(props: HypeChartProps) {
    const { conceptId } = useParams()
    const { currentUser } = useAuth()
    const [createCriteria] = useMutation<
        CreateCriteriaMutation,
        CreateCriteriaMutationVariables
    >(CREATE_CRITERIA)
    const [createScore] = useMutation<
        CreateCriteriaScoreMutation,
        CreateCriteriaScoreMutationVariables
    >(CREATE_CRITERIA_SCORE)
    const [addScore] = useMutation<
        AddCriteriaScoreCriteriaMutation,
        AddCriteriaScoreCriteriaMutationVariables
    >(ADD_CRITERIA_SCORE_CRITERIA)
    const [updateScore] = useMutation<
        UpdateCriteriaScoreMutation,
        UpdateCriteriaScoreMutationVariables
    >(UPDATE_CRITERIA_SCORE)
    const [addCriteria] = useMutation<
        AddCriteriaConceptMutation,
        AddCriteriaConceptMutationVariables
    >(ADD_CONCEPT_CRITERIA)
    const [addUser] = useMutation<
        AddCriteriaScoreUserMutation,
        AddCriteriaScoreUserMutationVariables
    >(ADD_CRITERIA_SCORE_USER)
    const { data, loading, refetch } = useQuery<
        HypeChartQuery,
        HypeChartQueryVariables
    >(HYPE_CHART, {
        variables: {
            conceptFilter: props.filter,
            conceptId: conceptId,
        },
    })

    const [saving, setSaving] = useState(false)
    const theme = useTheme()
    const history = useHistory()
    const classes = useStyles()
    const createNewCriteria = useCallback(async () => {
        const {
            data: { CreateCriteria: NewCriteria },
        } = await createCriteria({
            variables: {
                name: "Hype Chart Position",
                criteriaType: CriteriaType.Chart,
                isDefault: false,
            },
        })
        await addCriteria({
            variables: {
                criteriaId: NewCriteria.id,
                conceptId: conceptId,
            },
        })
        await refetch()
    }, [addCriteria, conceptId, createCriteria, refetch])
    const { t } = useAwaitTranslation("trends")
    const trends: Concept[] =
        props.concepts ??
        data?.secondaryConcepts?.filter(
            (x) => x.rootCategory?.name === "Trend"
        ) ??
        []

    const primaryConcept =
        trends.filter((x) => x.id === conceptId)?.[0] ??
        data?.primaryConcept?.[0] ??
        null
    const primaryConceptCriteria =
        primaryConcept?.criteria?.filter(
            (criteria) => criteria.name === "Hype Chart Position"
        )?.[0] ?? null
    const primaryConceptScore =
        primaryConceptCriteria?.scores?.filter(
            (score) => score.user?.userId === currentUser?.userId
        )?.[0] ?? null

    useEffect(() => {
        if (!!data && !!primaryConcept && !primaryConceptCriteria) {
            createNewCriteria()
        }
    }, [primaryConcept, primaryConceptCriteria, createNewCriteria, data])

    let expectationsObject: PositionObject | null
    let adoptionObject: PositionObject | null
    let expectationsArray: PositionObject[] = []
    let adoptionArray: PositionObject[] = []
    if (!!primaryConcept && !!primaryConceptScore?.value) {
        expectationsObject = {
            id: primaryConcept.id,
            x: primaryConceptScore.value,
            y: 0,
        }
        adoptionObject = {
            id: primaryConcept.id,
            x: primaryConceptScore.value,
            y: 0,
        }
        CurveData.forEach((chartPoint) => {
            chartPoint.dataPoints = {}
            if (chartPoint.number === expectationsObject.x) {
                expectationsObject.y = chartPoint.hypeLine
                let toolTipObject: TooltipObject = {
                    title: primaryConcept.title,
                    imageUrl: primaryConcept.imageUrl,
                    primary: true,
                    position: chartPoint.number,
                }
                chartPoint.dataPoints[expectationsObject.id] = toolTipObject
            }
        })
        AdoptionData.forEach((chartPoint) => {
            if (chartPoint.number === adoptionObject.x) {
                adoptionObject.y = chartPoint.hypeLine
            }
        })
    }
    trends
        ?.filter(
            (x: Concept) =>
                !!x.criteria?.filter(
                    (criteria) => criteria.name === "Hype Chart Position"
                )?.[0]
        )
        .forEach((item) => {
            const score = item.criteria?.filter(
                (criteria) => criteria.name === "Hype Chart Position"
            )?.[0]?.scores[0]
            if (!!score?.value) {
                let expectationsObject: PositionObject = {
                    x: score.value,
                    y: 0,
                    id: item.id,
                }
                let adoptionObject: PositionObject = {
                    x: score.value,
                    y: 0,
                    id: item.id,
                }
                CurveData.forEach((chartPoint) => {
                    if (score.value === chartPoint.number) {
                        expectationsObject.y = chartPoint.hypeLine
                        let toolTipObject: TooltipObject = {
                            title: item.title,
                            imageUrl: item.imageUrl,
                            primary: false,
                            position: chartPoint.number,
                        }
                        chartPoint.dataPoints[
                            expectationsObject.id
                        ] = toolTipObject
                    }
                })
                expectationsArray.push(expectationsObject)
                AdoptionData.forEach((chartPoint) => {
                    if (score.value === chartPoint.number) {
                        adoptionObject.y = chartPoint.hypeLine
                    }
                })
                adoptionArray.push(adoptionObject)
            }
        })

    const handleClick = async (data: { activeLabel: number }) => {
        if (!!primaryConcept && !!data?.activeLabel && !props.locked) {
            setSaving(true)
            if (!!primaryConceptCriteria && !primaryConceptScore) {
                const {
                    data: { CreateCriteriaScore: NewScore },
                } = await createScore({
                    variables: {
                        value: data.activeLabel,
                    },
                })
                await Promise.all([
                    addScore({
                        variables: {
                            criteriaId: primaryConceptCriteria.id,
                            criteriaScoreId: NewScore.id,
                        },
                    }),
                    addUser({
                        variables: {
                            userId: currentUser?.userId,
                            criteriaScoreId: NewScore.id,
                        },
                    }),
                ])
            } else if (!!primaryConceptCriteria && !!primaryConceptScore) {
                await updateScore({
                    variables: {
                        id: primaryConceptScore.id,
                        value: data.activeLabel,
                    },
                })
            }

            setSaving(false)
        }
    }

    if (loading || (!!primaryConcept && !primaryConceptCriteria)) {
        return <Loading size={75} hideQuote={true} />
    }
    return (
        <div
            className={classes.flex}
            style={{
                cursor: saving ? "wait" : "default",
                pointerEvents: saving ? "none" : "auto",
            }}
        >
            <SizeMe monitorHeight monitorWidth>
                {({ size }) => (
                    <div className={classes.chartContainer}>
                        <Box display="flex">
                            <div className={classes.yAxis}>
                                {t("hypeChart.expectations", "Expectations")}
                            </div>
                            <div>
                                <LineChart
                                    width={size.width - 20}
                                    height={size.height}
                                    onClick={handleClick}
                                    className={
                                        !!primaryConcept && !props.locked
                                            ? classes.clickable
                                            : ""
                                    }
                                    data={CurveData}
                                    syncId="hype"
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <XAxis
                                        hide={true}
                                        orientation="top"
                                        xAxisId={0}
                                        type="number"
                                        dataKey="number"
                                        interval="preserveEnd"
                                        domain={[0, 100]}
                                    />
                                    <XAxis
                                        orientation="bottom"
                                        xAxisId={1}
                                        type="number"
                                        dataKey="number"
                                        ticks={[5, 20, 30, 45, 75]}
                                        tick={<ExpectationsTick size={size} />}
                                        interval="preserveEnd"
                                        domain={[0, 100]}
                                    />
                                    <YAxis
                                        tick={false}
                                        dataKey="hypeLine"
                                        domain={[0, 2000]}
                                    />
                                    <Tooltip
                                        wrapperStyle={{
                                            fill:
                                                theme.palette.background.paper,
                                            zIndex: theme.zIndex.tooltip,
                                        }}
                                        content={
                                            <CustomTooltip
                                                typography={theme.typography}
                                                palette={theme.palette}
                                            />
                                        }
                                    />
                                    <Line
                                        type="basis"
                                        dot={false}
                                        dataKey="hypeLine"
                                        stroke={theme.palette.primary.main}
                                        fill={theme.palette.primary.main}
                                    />
                                    {expectationsArray?.map((item, idx) => {
                                        const key =
                                            idx.toString() + "expectations"
                                        return (
                                            <ReferenceDot
                                                key={key}
                                                fill={
                                                    theme.palette.secondary.main
                                                }
                                                style={{
                                                    cursor: props.locked
                                                        ? "pointer"
                                                        : "default",
                                                }}
                                                onClick={(e: DotProps) => {
                                                    if (props.locked) {
                                                        history.push(
                                                            `/concept/${item.id}/home`
                                                        )
                                                    }
                                                }}
                                                r={5}
                                                xAxisId={0}
                                                x={item.x}
                                                y={item.y}
                                            />
                                        )
                                    })}
                                    {!!primaryConceptScore?.value && (
                                        <ReferenceDot
                                            r={5}
                                            isFront={true}
                                            xAxisId={0}
                                            x={expectationsObject.x}
                                            y={expectationsObject.y}
                                            fill={theme.palette.primary.main}
                                        />
                                    )}
                                </LineChart>
                            </div>
                        </Box>
                    </div>
                )}
            </SizeMe>
            <SizeMe monitorHeight monitorWidth>
                {({ size }) => (
                    <div className={classes.chartContainer}>
                        <Box display="flex">
                            <div className={classes.yAxis}>
                                {t("hypeChart.adoptionRate", "Adoption Rate")}
                            </div>
                            <div>
                                <AreaChart
                                    width={size.width - 20}
                                    height={size.height}
                                    data={AdoptionData}
                                    syncId="hype"
                                    className={
                                        !!primaryConcept && !props.locked
                                            ? classes.clickable
                                            : ""
                                    }
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <YAxis
                                        tick={false}
                                        dataKey="hypeLine"
                                        domain={[0, 4]}
                                    />
                                    <Tooltip
                                        wrapperStyle={{
                                            fill:
                                                theme.palette.background.paper,
                                            zIndex: theme.zIndex.tooltip,
                                        }}
                                        content={
                                            <CustomTooltip
                                                typography={theme.typography}
                                                palette={theme.palette}
                                            />
                                        }
                                    />
                                    <XAxis
                                        dataKey="number"
                                        ticks={[10, 30, 50, 70, 90]}
                                        domain={[0, 100]}
                                        tick={<AdoptionTick size={size} />}
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="hypeLine"
                                        stroke={theme.palette.primary.main}
                                        fill={theme.palette.primary.main}
                                    />

                                    {adoptionArray?.map((item, idx) => {
                                        const key = idx.toString() + "adoption"
                                        return (
                                            <ReferenceDot
                                                key={key}
                                                style={{
                                                    cursor: props.locked
                                                        ? "pointer"
                                                        : "default",
                                                }}
                                                onClick={(e: DotProps) => {
                                                    if (props.locked) {
                                                        history.push(
                                                            `/concept/${item.id}/home`
                                                        )
                                                    }
                                                }}
                                                fill={
                                                    theme.palette.secondary.main
                                                }
                                                r={5}
                                                xAxisId={0}
                                                x={item.x}
                                                y={item.y}
                                            />
                                        )
                                    })}
                                    <ReferenceLine
                                        strokeOpacity={0.5}
                                        stroke={theme.palette.text.hint}
                                        segment={[
                                            { x: 20, y: 0 },
                                            {
                                                x: 20,
                                                y: AdoptionData[20].hypeLine,
                                            },
                                        ]}
                                    />
                                    <ReferenceLine
                                        strokeOpacity={0.5}
                                        stroke={theme.palette.text.hint}
                                        segment={[
                                            { x: 40, y: 0 },
                                            {
                                                x: 40,
                                                y: AdoptionData[40].hypeLine,
                                            },
                                        ]}
                                    />
                                    <ReferenceLine
                                        strokeOpacity={0.5}
                                        stroke={theme.palette.text.hint}
                                        segment={[
                                            { x: 60, y: 0 },
                                            {
                                                x: 60,
                                                y: AdoptionData[60].hypeLine,
                                            },
                                        ]}
                                    />
                                    <ReferenceLine
                                        strokeOpacity={0.5}
                                        stroke={theme.palette.text.hint}
                                        segment={[
                                            { x: 80, y: 0 },
                                            {
                                                x: 80,
                                                y: AdoptionData[80].hypeLine,
                                            },
                                        ]}
                                    />
                                    <ReferenceLine
                                        strokeOpacity={0.5}
                                        stroke={theme.palette.text.hint}
                                        segment={[
                                            { x: 100, y: 0 },
                                            {
                                                x: 100,
                                                y: AdoptionData[100].hypeLine,
                                            },
                                        ]}
                                    />
                                    {!!primaryConceptScore?.value && (
                                        <ReferenceDot
                                            r={5}
                                            isFront={true}
                                            xAxisId={0}
                                            x={adoptionObject.x}
                                            y={adoptionObject.y}
                                            fill={theme.palette.primary.main}
                                        />
                                    )}
                                </AreaChart>
                            </div>
                        </Box>
                    </div>
                )}
            </SizeMe>
            <div className={classes.timeContainer}>
                {t("hypeChart.time", "Time")}
            </div>
        </div>
    )
}
const CustomTooltip = (props: {
    payload?: any
    typography?: Typography
    palette?: Palette
    active?: boolean
}) => {
    const { active, payload, typography, palette } = props
    const { t } = useAwaitTranslation("trends")
    const data = payload[0]?.payload?.dataPoints
    if (active && payload) {
        if (data && Object.keys(data).length > 0) {
            let topLabel = ""
            const xValue = payload[0].payload.number
            if (xValue < 17) {
                topLabel = t(
                    "hypeChart.innovationTrigger",
                    "Innovation Trigger"
                )
            } else if (xValue >= 17 && xValue <= 23) {
                topLabel = t(
                    "hypeChart.peakOfInflatedExpectations",
                    "Peak of Inflated Expectations"
                )
            } else if (xValue >= 24 && xValue <= 35) {
                topLabel = t(
                    "hypeChart.troughOfDisillusionment",
                    "Trough of Disillusionment"
                )
            } else if (xValue >= 36 && xValue <= 65) {
                topLabel = t(
                    "hypeChart.slopeOfEnlightment",
                    "Slope of Enlightenment"
                )
            } else {
                topLabel = t(
                    "hypeChart.plateauOfProductivity",
                    "Plateau of Productivity"
                )
            }

            let bottomLabel = ""
            if (xValue < 20) {
                bottomLabel = t("hypeChart.innovators", "Innovators")
            } else if (xValue > 20 && xValue < 40) {
                bottomLabel = t("hypeChart.earlyAdopters", "Early Adopters")
            } else if (xValue > 40 && xValue < 60) {
                bottomLabel = t("hypeChart.earlyMajority", "Early Majority")
            } else if (xValue > 60 && xValue < 80) {
                bottomLabel = t("hypeChart.lateMajority", "Late Majority")
            } else {
                bottomLabel = t("hypeChart.laggards", "Laggards")
            }
            return (
                <Card
                    style={{
                        padding: "12px",
                        maxWidth: "350px",
                    }}
                >
                    {Object.keys(data).map((dataPoint, idx) => (
                        <Box
                            key={idx}
                            display="flex"
                            alignItems="center"
                            fontSize={typography.body1.fontSize}
                            padding={0.5}
                        >
                            <Avatar
                                variant="rounded"
                                src={data[dataPoint].imageUrl}
                                style={{ marginRight: "5px" }}
                            >
                                {data[dataPoint].primary ? (
                                    <StarIcon />
                                ) : (
                                    <TrendingUpIcon />
                                )}
                            </Avatar>
                            {data[dataPoint].title}
                        </Box>
                    ))}
                    <Box padding={0.5}>
                        <Divider />
                    </Box>
                    <Box fontSize={typography.body2.fontSize}>
                        <Box padding={0.5} display="flex" alignItems="center">
                            {t("hypeChart.expectations", "Expectations")}{" "}
                            <ArrowRightAltIcon /> {topLabel}
                        </Box>
                        <Box padding={0.5} display="flex" alignItems="center">
                            {t("hypeChart.adoptionRate", "Adoption Rate")}{" "}
                            <ArrowRightAltIcon /> {bottomLabel}
                        </Box>
                    </Box>
                </Card>
            )
        } else {
            return null
        }
    } else {
        return null
    }
}

const ExpectationsTick = (props) => {
    const { x, y, payload, size } = props
    const { t } = useAwaitTranslation("trends")

    const tickObject = {
        5: t("hypeChart.innovationTrigger", "Innovation Trigger"),
        20: t(
            "hypeChart.peakOfInflatedExpectations",
            "Peak of Inflated Expectations"
        ),
        30: t("hypeChart.troughOfDisillusionment", "Trough of Disillusionment"),
        45: t("hypeChart.slopeOfEnlightment", "Slope of Enlightenment"),
        75: t("hypeChart.plateauOfProductivity", "Plateau of Productivity"),
    }
    const hideableTicks = [
        t(
            "hypeChart.peakOfInflatedExpectations",
            "Peak of Inflated Expectations"
        ),
        t("hypeChart.slopeOfEnlightment", "Slope of Enlightenment"),
    ]
    let content = ""
    if (
        size.width < 750 &&
        hideableTicks.indexOf(tickObject[payload.value]) !== -1
    ) {
        content = ""
    } else {
        content = tickObject[payload.value]
    }
    return (
        <foreignObject x={x - 15} y={y - 5} width="85" height="150">
            <div style={{ fontSize: size.width < 650 ? "8px" : "10px" }}>
                {content}
            </div>
        </foreignObject>
    )
}
const AdoptionTick = (props) => {
    const { x, y, payload, size } = props

    const { t } = useAwaitTranslation("trends")
    const tickObject = {
        0: "",
        10: t("hypeChart.innovators", "Innovators"),
        20: "",
        30: t("hypeChart.earlyAdopters", "Early Adopters"),
        40: "",
        50: t("hypeChart.earlyMajority", "Early Majority"),
        60: "",
        70: t("hypeChart.lateMajority", "Late Majority"),
        80: "",
        90: t("hypeChart.laggards", "Laggards"),
        100: "",
    }
    const hideableTicks = [
        t("hypeChart.earlyAdopters", "Early Adopters"),
        t("hypeChart.lateMajority", "Late Majority"),
    ]
    let content: string = ""
    if (
        size.width < 750 &&
        hideableTicks.indexOf(tickObject[payload.value]) !== -1
    ) {
        content = ""
    } else {
        content = tickObject[payload.value]
    }
    return (
        <foreignObject x={x - 20} y={y - 5} width="85" height="150">
            <div style={{ fontSize: size.width < 650 ? "8px" : "10px" }}>
                {content}
            </div>
        </foreignObject>
    )
}
