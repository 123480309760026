import React, { useState, useCallback, useEffect } from "react"
import {
    createStyles,
    Theme,
    makeStyles,
    useTheme,
} from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import LinkConcept from "./LinkConcept"
import { Box, IconButton } from "@material-ui/core"
import Slide from "@material-ui/core/Slide"
import AddIcon from "@material-ui/icons/Add"
import { useMutation, useQuery } from "@apollo/client"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import InfiniteScroll from "react-infinite-scroller"
import CircularProgress from "@material-ui/core/CircularProgress"
import Tooltip from "@material-ui/core/Tooltip"
import { useMediaQuery } from "@material-ui/core"
import { CONNECT_CONCEPTS } from "../graphql/mutations"
import {
    AddConceptConnectionsMutation,
    AddConceptConnectionsMutationVariables,
} from "../graphql/__generated__/mutations"
import { CONNECTION_WIDGET_QUERY } from "../graphql/queries"
import {
    ConnectionWidgetQueryQuery,
    ConnectionWidgetQueryQueryVariables,
} from "../graphql/__generated__/queries"
import { Loading } from "./Loading"
import { _ConceptFilter, _ConceptOrdering } from "../__generated__/types"
import ConceptListItem from "./ConceptListItem"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            overflow: "auto",
        },
        inline: {
            display: "inline",
        },
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
        searchContainer: {
            marginTop: theme.spacing(1),
            fontSize: "12px",
        },
        linkCount: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.secondary,
        },
        toolIcon: {
            height: "0.8em",
            width: "0.8em",
        },
        loaderContainer: {
            textAlign: "center",
        },
        greyed: {
            fontSize: 12,
            color: theme.palette.text.hint,
        },
    })
)

interface ConceptInfiniteScrollProps {
    conceptId: string
    editPermission: boolean
    filter: _ConceptFilter
    orderBy: _ConceptOrdering
}

const PAGE_SIZE = 10
export default function ConceptInfiniteScroll(
    props: ConceptInfiniteScrollProps
) {
    const classes = useStyles()
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [search, showSearch] = useState(false)
    const [edit, setEdit] = useState(null)
    const [loadMore, setLoadMore] = useState(true)
    const [limit, setLimit] = useState(PAGE_SIZE)
    const [filter, setFilter] = useState<_ConceptFilter>({})
    const [connectConcepts] = useMutation<
        AddConceptConnectionsMutation,
        AddConceptConnectionsMutationVariables
    >(CONNECT_CONCEPTS)
    const { loading, error, data, fetchMore, refetch } = useQuery<
        ConnectionWidgetQueryQuery,
        ConnectionWidgetQueryQueryVariables
    >(CONNECTION_WIDGET_QUERY, {
        variables: {
            offset: 0,
            first: limit,
            connectionsFilter: filter,
            orderBy: [props.orderBy],
        },
    })
    useEffect(() => {
        if (JSON.stringify(props.filter) !== JSON.stringify(filter)) {
            setFilter(props.filter)
            setLimit(5)
            setLoadMore(true)
        }
    }, [props.filter, filter])

    const handleClickAway = () => {
        if (search) {
            showSearch(false)
        }
        if (edit) {
            setEdit(false)
        }
    }
    const onLoadMore = useCallback(
        async (page) => {
            if (!fetchMore) return
            const result = await fetchMore({
                variables: {
                    offset: page * PAGE_SIZE,
                },
            })
            setLimit(page * PAGE_SIZE + PAGE_SIZE)
            setLoadMore(!(result.data.Concept.length < page * PAGE_SIZE))
        },
        [fetchMore]
    )
    if (loading) {
        return <Loading size={50} hideQuote={true} />
    } else if (error) {
        console.log(error)
        return <div>{error.message}</div>
    }

    const listData = data?.Concept ?? []
    const handleConceptAddition = async (concept) => {
        showSearch(false)
        await connectConcepts({
            variables: {
                fromId: props.conceptId,
                toId: concept.id,
            },
        })
        await refetch()
    }
    let actions = []
    if (props.editPermission) {
        actions.unshift(
            <Tooltip key="add-link" title={"Add linked concept"}>
                <IconButton onClick={() => showSearch(true)}>
                    <AddIcon className={classes.toolIcon} />
                    {!mobile && "Add"}
                </IconButton>
            </Tooltip>
        )
    }
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.container}>
                <Slide direction="right" in={search} mountOnEnter unmountOnExit>
                    <Box className={classes.searchContainer}>
                        <Box
                            display="flex"
                            justifyContent={
                                props.editPermission
                                    ? "space-between"
                                    : "flex-end"
                            }
                            alignItems="center"
                        >
                            {props.editPermission && (
                                <Box display="flex" width="100%">
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        flexGrow={1}
                                        padding="12px"
                                    >
                                        <LinkConcept
                                            concepts={[]}
                                            linkFunction={handleConceptAddition}
                                            autoFocus={true}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Slide>
                <List className={classes.root} disablePadding>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={onLoadMore}
                        hasMore={loadMore}
                        loader={
                            <div key={0} className={classes.loaderContainer}>
                                <CircularProgress disableShrink size={40} />
                            </div>
                        }
                        useWindow={false}
                    >
                        {listData.map((item, idx) => {
                            return (
                                <Box key={item.id}>
                                    <ConceptListItem
                                        item={item}
                                        showSummary={true}
                                    />
                                </Box>
                            )
                        })}
                    </InfiniteScroll>
                </List>
            </div>
        </ClickAwayListener>
    )
}
