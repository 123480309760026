import React, { useState, useEffect, useCallback } from "react"
import {
    Box,
    SwipeableDrawer,
    Toolbar,
    useMediaQuery,
    Tab,
    Tabs,
    Divider,
} from "@material-ui/core"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import ConceptDetailsHeader from "./ConceptDetailsHeader"
import { useQuery, useMutation } from "@apollo/client"
import { useAuth } from "../providers/AuthProvider"
import { useParams } from "react-router-dom"
import { CONCEPT_BY_ID } from "../graphql/queries"
import { ALL_DASHBOARD_TEMPLATES } from "./dashboard/graphql"
import {
    AllDashboardTemplatesQuery,
    AllDashboardTemplatesQueryVariables,
} from "./dashboard/__generated__/graphql"
import { Loading } from "./Loading"
import Dashboard from "./dashboard/Dashboard"
import {
    ConceptQuery,
    ConceptQueryVariables,
} from "../graphql/__generated__/queries"
import DashboardManager from "./dashboard/DashboardManager"
import ConceptDetailsToolbar from "./ConceptDetailsToolbar"
import { SizeMe } from "react-sizeme"
import CommentIcon from "@material-ui/icons/Comment"
import DashboardIcon from "@material-ui/icons/Dashboard"
import CommentsContainer from "./commenting/CommentsContainer"
import { Concept, DashboardView } from "../__generated__/types"
import { getEditingPermission } from "../util/GetPermissions"
import { permissionsVar } from "../providers/GlobalState"
import { CREATE_DASHBOARD } from "./dashboard/graphql"
import {
    CreateDashboardMutation,
    CreateDashboardMutationVariables,
} from "./dashboard/__generated__/graphql"
import { ADD_CONCEPT_HOME_DASHBOARD } from "./dashboard/graphql"
import {
    AddConceptHomeDashboardMutation,
    AddConceptHomeDashboardMutationVariables,
} from "./dashboard/__generated__/graphql"
import { useDashboardTemplates } from "./dashboard/useDashboardTemplates"
const drawerWidth = 260

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        overflow: "hidden",
        maxWidth: "100%",
        width: "100%",
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    rootPreview: {
        flexGrow: 1,
        overflow: "hidden",

        width: "100%",
    },
    drawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: drawerWidth,
        flexShrink: 0,
    },
    closedDrawer: {
        zIndex: theme.zIndex.appBar - 1,
        width: 0,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.background.default,
    },

    description: {
        height: "48vh",
    },
    topToolbar: {
        position: "fixed",
        zIndex: theme.zIndex.appBar - 1,
        backgroundColor: theme.palette.background.default,
        margin: theme.spacing(0, -1),
    },
    tab: {
        width: drawerWidth / 2,
        minWidth: drawerWidth / 2,
        padding: theme.spacing(0.5),
    },
}))
interface ConceptDetailsProps {
    concept?: Concept
}
const ConceptDetails = (props: ConceptDetailsProps) => {
    const { conceptId, dashboardId } = useParams()
    const { loading, error, data, refetch } = useQuery<
        ConceptQuery,
        ConceptQueryVariables
    >(CONCEPT_BY_ID, {
        variables: { id: conceptId },
        skip: !!props.concept,
    })
    const [createDashboard] = useMutation<
        CreateDashboardMutation,
        CreateDashboardMutationVariables
    >(CREATE_DASHBOARD)
    const [addHomeDashboard] = useMutation<
        AddConceptHomeDashboardMutation,
        AddConceptHomeDashboardMutationVariables
    >(ADD_CONCEPT_HOME_DASHBOARD)
    const { data: DashboardData } = useQuery<
        AllDashboardTemplatesQuery,
        AllDashboardTemplatesQueryVariables
    >(ALL_DASHBOARD_TEMPLATES)
    const theme = useTheme()
    const { onApplyDashboardTemplate } = useDashboardTemplates()
    const mobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [editing, setEditing] = useState(false)
    const [createdNew, setCreatedNew] = useState(false)
    const [tab, setTab] = React.useState(0)
    const item = props.concept ?? data?.Concept?.[0] ?? null
    const dashboardTemplates = DashboardData?.DashboardView ?? null
    const classes = useStyles()
    const { currentUser } = useAuth()
    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number
    ) => {
        setTab(newValue)
    }

    useEffect(() => {
        setCreatedNew(false)
    }, [conceptId, setCreatedNew])
    useEffect(() => {
        if (currentUser && item) {
            permissionsVar(getEditingPermission(item, currentUser))
        }
    })
    const createHomeDashboard = useCallback(async () => {
        const dbTemplate = dashboardTemplates.filter(
            (x: DashboardView) => x.type === item?.category?.name
        )?.[0]
        let newDashboardId = null
        if (!!dbTemplate) {
            const NewDashboard = await onApplyDashboardTemplate(
                dbTemplate,
                item.id
            )
            newDashboardId = NewDashboard.id
        } else {
            const {
                data: { CreateDashboardView: NewDashboard },
            } = await createDashboard({
                variables: {
                    name: `${item.title} - Home Dashboard`,
                    description: "",
                    isTemplate: false,
                },
            })
            newDashboardId = NewDashboard.id
        }
        if (!!newDashboardId) {
            await addHomeDashboard({
                variables: {
                    conceptId: item.id,
                    dashboardId: newDashboardId,
                },
            })
        }

        await refetch()
    }, [
        addHomeDashboard,
        item?.id,
        createDashboard,
        item?.title,
        refetch,
        dashboardTemplates,
        item?.category?.name,
        onApplyDashboardTemplate,
    ])

    useEffect(() => {
        if (
            !!item &&
            !item.homeDashboard &&
            !!dashboardTemplates &&
            !createdNew
        ) {
            createHomeDashboard()
            setCreatedNew(true)
        }
    }, [item, createHomeDashboard, dashboardTemplates, createdNew])
    if (loading || !item || !item.homeDashboard) {
        return <Loading size={150} />
    }
    if (error) {
        console.log(error.message)
        return <div>error!</div>
    }

    const dashboard =
        dashboardId !== "home"
            ? item.usesDashboard?.filter((x) => x.id === dashboardId)[0]
            : item.homeDashboard ?? null

    return (
        <Box
            display="flex"
            key={item.id + dashboardId}
            style={{ overflowX: "hidden" }}
        >
            <SizeMe monitorWidth monitorHeight>
                {({ size }) => (
                    <>
                        <div
                            className={
                                props.concept
                                    ? classes.rootPreview
                                    : classes.root
                            }
                        >
                            <Box
                                className={classes.topToolbar}
                                width={size.width + theme.spacing(2)}
                            >
                                <ConceptDetailsToolbar
                                    item={item}
                                    sidebar={sidebarOpen}
                                    toggleSidebar={() => {
                                        setSidebarOpen(!sidebarOpen)
                                    }}
                                    editing={editing}
                                    selectedDashboard={dashboard}
                                    preview={!!props.concept}
                                    toggleEditing={() => setEditing(!editing)}
                                />
                            </Box>

                            <Box
                                width={size.width + theme.spacing(2)}
                                height={theme.spacing(7)}
                            />

                            <Box display="flex" flexDirection="column">
                                {dashboardId === "home" && (
                                    <Box p={1.25}>
                                        <ConceptDetailsHeader
                                            item={item}
                                            preview={!!props.concept}
                                        />
                                    </Box>
                                )}
                                <Box>
                                    {!!dashboard && (
                                        <Dashboard
                                            key={dashboard.id + item.id}
                                            dashboardId={dashboard.id}
                                            width={size.width ?? 25}
                                            editing={editing}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </div>
                    </>
                )}
            </SizeMe>
            {!props.concept && (
                <SwipeableDrawer
                    open={sidebarOpen}
                    anchor="right"
                    variant={mobile ? "temporary" : "persistent"}
                    className={
                        sidebarOpen ? classes.drawer : classes.closedDrawer
                    }
                    onOpen={() => setSidebarOpen(true)}
                    onClose={() => setSidebarOpen(false)}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    {!mobile && <Toolbar />}
                    <Box
                        style={{
                            //@ts-ignore
                            backgroundColor: theme.palette.background.level2,
                        }}
                    >
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon tabs example"
                        >
                            <Tab
                                classes={{ root: classes.tab }}
                                icon={<DashboardIcon />}
                                aria-label="dashboard"
                            />
                            <Tab
                                classes={{ root: classes.tab }}
                                icon={<CommentIcon />}
                                aria-label="comments"
                            />
                        </Tabs>
                    </Box>
                    <Divider />
                    {tab === 0 && (
                        <DashboardManager
                            concept={item}
                            editing={editing}
                            toggleEditing={() => setEditing(!editing)}
                            refetch={refetch}
                        />
                    )}
                    {tab === 1 && <CommentsContainer conceptId={conceptId} />}
                </SwipeableDrawer>
            )}
        </Box>
    )
}

export default ConceptDetails
