import { gql } from "@apollo/client"
const GET_CATEGORIES = gql`
    query Category {
        Category {
            id
            name
            description
            conceptType
            isRoot
            parent {
                id
                name
                description
                conceptType
                isRoot
            }
            children {
                id
                name
                description
                isRoot
                conceptType
                parent {
                    id
                    name
                    description
                    conceptType
                }
                children {
                    id
                    name
                    description
                    isRoot
                    conceptType
                    parent {
                        id
                        isRoot
                        name
                        description
                        conceptType
                    }
                    children {
                        id
                        isRoot
                        name
                        description
                        conceptType
                        parent {
                            id
                            isRoot
                            name
                            description
                            conceptType
                        }
                    }
                }
            }
        }
    }
`
const CREATE_CATEGORY = gql`
    mutation CreateCategory(
        $name: String
        $description: String
        $conceptType: ConceptType
    ) {
        CreateCategory(
            name: $name
            description: $description
            conceptType: $conceptType
        ) {
            id
            name
            description
            conceptType
        }
    }
`
const UPDATE_CATEGORY = gql`
    mutation UpdateCategory($id: ID!, $name: String, $description: String) {
        UpdateCategory(id: $id, name: $name, description: $description) {
            id
            name
            description
            conceptType
            isRoot
        }
    }
`
const DELETE_CATEGORY = gql`
    mutation DeleteCategory($id: ID!) {
        DeleteCategory(id: $id) {
            id
        }
    }
`
const ADD_CATEGORY_PARENT = gql`
    mutation AddCategoryParent($parentId: ID!, $childId: ID!) {
        AddCategoryParent(from: { id: $childId }, to: { id: $parentId }) {
            from {
                id
                name
                description
                conceptType
                parent {
                    id
                    name
                    description
                    conceptType
                }
                children {
                    id
                    name
                    description
                    conceptType
                    parent {
                        id
                        name
                        description
                        conceptType
                    }
                }
            }
            to {
                id
                name
                description
                conceptType
                parent {
                    id
                    name
                    description
                    conceptType
                }
                children {
                    id
                    name
                    description
                    conceptType
                    parent {
                        id
                        name
                        description
                        conceptType
                    }
                }
            }
        }
    }
`
const CATEGORY_QUERY = gql`
    query CategoryByType {
        Category {
            id
            name
            isRoot
            parent {
                id
                name
                isRoot
            }
        }
    }
`
const CATEGORIES_FILTERED = gql`
    query CategoriesFiltered($filter: _CategoryFilter) {
        Category(filter: $filter) {
            id
            name
            isRoot
            parent {
                id
                name
                isRoot
            }
        }
    }
`
const ROOT_CATEGORY_QUERY = gql`
    query RootCategories {
        Category(orderBy: name_asc, isRoot: true) {
            id
            name
            isRoot
            description
            isManaged
            isFilterOption
            children(orderBy: name_asc) {
                id
                name
                description
                conceptType
                isManaged
                isFilterOption
                parent {
                    id
                    isManaged
                    isFilterOption
                    name
                    description
                    conceptType
                }
                children(orderBy: name_asc) {
                    id
                    name
                    description
                    conceptType
                    isManaged
                    isFilterOption
                    parent {
                        id
                        isManaged
                        isFilterOption
                        name
                        description
                        conceptType
                    }
                }
                children(orderBy: name_asc) {
                    id
                    name
                    description
                    conceptType
                    isManaged
                    isFilterOption
                    parent {
                        id
                        isManaged
                        isFilterOption
                        name
                        description
                        conceptType
                    }
                }
            }
        }
    }
`

const ADD_CONCEPT_CATEGORY = gql`
    mutation AddConceptCategory($id: ID!, $categoryId: ID!) {
        AddConceptCategory(from: { id: $id }, to: { id: $categoryId }) {
            from {
                id
                title
                category {
                    id
                    name
                    isRoot
                }
                rootCategory {
                    id
                    name
                    isRoot
                }
            }
            to {
                id
                name
            }
        }
    }
`
const REMOVE_CONCEPT_CATEGORY = gql`
    mutation RemoveConceptCategory($id: ID!, $categoryId: ID!) {
        RemoveConceptCategory(from: { id: $id }, to: { id: $categoryId }) {
            from {
                id
                title
                category {
                    id
                    name
                }
            }
            to {
                id
                name
            }
        }
    }
`
export {
    GET_CATEGORIES,
    CREATE_CATEGORY,
    CATEGORY_QUERY,
    REMOVE_CONCEPT_CATEGORY,
    ADD_CONCEPT_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    ADD_CATEGORY_PARENT,
    ROOT_CATEGORY_QUERY,
    CATEGORIES_FILTERED,
}
