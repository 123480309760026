import { useState } from "react"
import {
    Box,
    Divider,
    TextField,
    Dialog,
    CircularProgress,
    DialogTitle,
} from "@material-ui/core"
import UserScoringSubmissionResults from "../UserScoringSubmissionResults"
import { useMutation } from "@apollo/client"
import {
    Exercise,
    ExerciseSubmission,
    ExerciseStatus,
} from "../../../__generated__/types"
import { UPDATE_EXERCISE_SUBMISSION } from "../graphql"
import {
    UpdateExerciseSubmissionMutation,
    UpdateExerciseSubmissionMutationVariables,
} from "../__generated__/graphql"

import Button from "@material-ui/core/Button"

interface SubmissionReviewProps {
    exercise: Exercise
    submission: ExerciseSubmission
    closeModal: () => void
    viewResults: () => void
}
const SubmissionReview = (props: SubmissionReviewProps) => {
    const [saving, setSaving] = useState(false)
    const [updateSubmission] = useMutation<
        UpdateExerciseSubmissionMutation,
        UpdateExerciseSubmissionMutationVariables
    >(UPDATE_EXERCISE_SUBMISSION)
    const [submissionNotes, setSubmissionNotes] = useState("")

    const handleSubmission = async () => {
        setSaving(true)
        await updateSubmission({
            variables: {
                id: props.submission.id,
                notes: submissionNotes,
                status: ExerciseStatus.COMPLETE,
            },
        })
        props.viewResults()
    }

    return (
        <Dialog open={true} fullWidth maxWidth="md">
            <DialogTitle>Submission Review</DialogTitle>
            <Box overflow="hidden" flexDirection="column" display="flex">
                <UserScoringSubmissionResults
                    submission={props.submission}
                    exercise={props.exercise}
                />
                <Divider />
                <Box p={1}>
                    <Box p={0.5}>
                        <TextField
                            value={submissionNotes}
                            onChange={(e) => setSubmissionNotes(e.target.value)}
                            label="Submission Notes"
                            multiline
                            variant="outlined"
                            rows={2}
                            fullWidth
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-end" p={0.5}>
                        <Button
                            onClick={() => props.closeModal()}
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmission}
                            variant="contained"
                            color="primary"
                            disabled={saving}
                        >
                            {saving ? (
                                <CircularProgress
                                    size={25}
                                    disableShrink
                                    color="secondary"
                                />
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

export default SubmissionReview
